import { xorWith } from 'lodash';
import { NodeId } from '../../serverapi/api';
import { compareNodeIds } from '../../utils/nodeId.utils';

export const getCheckedApprovalIds = (
    checkedApprovalIds: NodeId[],
    changedApprovalIds: NodeId[],
): NodeId[] => {
    return xorWith(checkedApprovalIds, changedApprovalIds, compareNodeIds) as NodeId[];
};
