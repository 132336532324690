import React, { FC, useEffect, useMemo } from 'react';
import { Table, Button, Checkbox } from 'antd';
import theme from './ExternalSessions.scss';
import { useIntl } from 'react-intl';
import messages from './ExternalSessions.messages';
import { useDispatch, useSelector } from 'react-redux';
import { deleteExternalSessionRequest, getExternalSessionsRequest } from '../../../../actions/externalSession.actions';
import { ExternalSessionsSelectors } from '../../../../selectors/externalSessions.selector';
import licenseTypesMessages from '../../../../models/licenseTypes.messages';
import moment from 'moment';
import { Icon } from '../../../UIKit';
import icDelete from '../../../../resources/icons/delete.svg';
import { ExternalSession } from '../../../../serverapi/api';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type TExternalSessionsTableProps = {
    serverId: string;
    externalSessions: ExternalSession[];
    setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ExternalSessionsTable: FC<TExternalSessionsTableProps> = (props) => {
    const { serverId, externalSessions, setSelectedIds } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const loading = useSelector(ExternalSessionsSelectors.loading);

    useEffect(() => {
        dispatch(getExternalSessionsRequest(serverId));
    }, []);

    const onDelete = (externalSessionId: string) => () => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => dispatch(deleteExternalSessionRequest(externalSessionId, serverId)),
                title: intl.formatMessage(messages.deleteConfirmationTitle),
                question: intl.formatMessage(messages.deleteConfirmationQuestion, {
                    name: externalSessions.find((es) => es.id === externalSessionId)?.name || '',
                }),
                OKButtonText: intl.formatMessage(messages.deleteButtonText),
            }),
        );
    };

    const createHandleCheck = (id: string) => (e: CheckboxChangeEvent) => {
        const { checked } = e.target;

        if (checked) {
            setSelectedIds((prevState: string[]) => [...prevState, id]);
        } else {
            setSelectedIds((prevState: string[]) => prevState.filter((s) => s !== id));
        }
    };

    const columns = useMemo(
        () => [
            {
                title: '',
                render: (_, externalSession: ExternalSession) => {
                    const handleCheck = createHandleCheck(externalSession.id);

                    return <Checkbox onChange={handleCheck} />;
                },
                width: 5,
            },
            {
                title: intl.formatMessage(messages.name),
                dataIndex: 'name',
                key: 'name',
                width: 75,
            },
            {
                title: intl.formatMessage(messages.sessionId),
                dataIndex: 'id',
                key: 'id',
                width: 50,
            },
            {
                title: intl.formatMessage(messages.login),
                dataIndex: 'login',
                key: 'login',
                width: 65,
            },
            {
                title: intl.formatMessage(messages.startedAt),
                dataIndex: 'startedAt',
                key: 'startedAt',
                width: 50,
                render: (startedAt: string) => moment(startedAt).format('DD.MM.YYYY'),
            },
            {
                title: intl.formatMessage(messages.expiresAt),
                dataIndex: 'expireAt',
                key: 'expireAt',
                width: 75,
                render: (expiresAt: string) => moment(expiresAt).format('DD.MM.YYYY'),
            },
            {
                title: intl.formatMessage(messages.locale),
                dataIndex: 'locale',
                key: 'locale',
                width: 50,
            },
            {
                title: intl.formatMessage(messages.lisence),
                dataIndex: 'license',
                key: 'license',
                width: 50,
                render: (license: string) => (license ? intl.formatMessage(licenseTypesMessages[license]) : ''),
            },
            {
                title: intl.formatMessage(messages.token),
                dataIndex: 'token',
                key: 'token',
                width: 200,
            },
            {
                width: 50,
                render: (_, externalSession: ExternalSession) => (
                    <Button
                        type="link"
                        onClick={onDelete(externalSession.id)}
                        icon={<Icon spriteSymbol={icDelete} />}
                    />
                ),
            },
        ],
        [externalSessions],
    );

    return (
        <Table
            rowKey={(record) => record.id}
            className={theme.dataTable}
            columns={columns}
            dataSource={externalSessions}
            pagination={false}
            loading={loading}
            scroll={{
                y: 'max-content',
                x: 'max-content',
            }}
            data-test="external-sessions-table"
        />
    );
};
