import { findLast } from 'lodash';
import { SymbolTypeId } from '../ComplexSymbol.constants';
import { createFrameFromString } from '../../utils';
import { getVRFrameDiagramTemplate, getVRFrameLineTemplate } from './VRSymbol.template';
import { MxCell, MxPopupMenu } from 'MxGraph';
import { EditorMode } from '@/models/editorMode';
import messages from '../ComplexSymbols.messages';
import MetaInfoSerializer from '../serializers/MetaInfoSerializer.class';
import MetaInfoComplexSymbol from '../MetaInfoComplexSymbol.class';

export class VRSymbol extends MetaInfoComplexSymbol {
    template = getVRFrameDiagramTemplate();
    complexSymbolTypeId = SymbolTypeId.VR;
    serializer = new MetaInfoSerializer();

    backwardCompabilityDecorator(cells: MxCell[]) {
        const root: MxCell = cells[0];

        if (root) {
            const style = root.getStyle();
            root.setStyle(style.replace('startSize=720', 'startSize=30'));
        }

        return cells;
    }

    public loadPopupMenu(menu: MxPopupMenu, cell: MxCell) {
        const { mode } = this.graph;

        menu.addItem(
            this.graph.intl.formatMessage(messages.addLineToFrame),
            null,
            () => this.addLine(),
            null,
            '',
            mode === EditorMode.Edit,
        );

        menu.addItem(
            this.graph.intl.formatMessage(messages.removeLineFromFrame),
            null,
            () => this.removeLine(cell),
            null,
            '',
            mode === EditorMode.Edit,
        );
    }

    private addLine() {
        const { graph } = this;
        const [virtualLineRoot] = createFrameFromString(getVRFrameLineTemplate());
        const rootLine = graph.addCell(virtualLineRoot, this.getRootCell());

        this.serializer.includeCell(rootLine);
    }

    private removeLine(cell: MxCell) {
        const line =
            cell.complexSymbolTypeId === SymbolTypeId.VR
                ? findLast(cell.children, (c) => c.getStyle().includes('removalbeFramesLine'))
                : cell;
        const { graph } = this;
        graph.getModel().remove(line);
    }
}
