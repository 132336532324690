import { Table } from 'antd';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import accessTypesMsg from '../../../../models/userAccesses.messages';
import accessTypes from '../../../../models/userAccessRightTypes';
import { TAccessPermissionOwners } from '../../../../reducers/accessPermissions.reducer.types';
import { UserDTOAccessesEnum } from '../../../../serverapi/api';
import adminToolTheme from '../../style/AdminTools.scss';
import { EditButton, RowButtons } from '../../Button/RowButtons';
import umMessages from '../../UserManagement/messages/userManagment.messages';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { TAccessPermissionsOwnersActionProps, TAccessPermissionsOwnersProps } from '../AccessPermissionsOwners.types';

type TAccessPermissionsOwnersFullProps = TAccessPermissionsOwnersProps &
    TAccessPermissionsOwnersActionProps &
    WrappedComponentProps;

type TAccessPermissionsOwnersTableRecord = {
    key: UserDTOAccessesEnum;
    title: string;
    usersGroups: string;
};

class AccessPermissionsOwners extends Component<TAccessPermissionsOwnersFullProps> {
    componentDidMount() {
        this.props.fetchData(this.props.serverId);
    }

    getColumns = () => {
        return [
            {
                title: this.props.intl.formatMessage(umMessages.accessesRights),
                dataIndex: 'title',
                width: 150,
                sorter: (a: TAccessPermissionsOwnersTableRecord, b: TAccessPermissionsOwnersTableRecord) =>
                    sortByAlpha(a.title, b.title),
            },
            {
                title: this.props.intl.formatMessage(umMessages.usersGroupsColTitle),
                dataIndex: 'usersGroups',
                width: 150,
            },
            {
                title: '',
                dataIndex: 'manageBtn',
                key: 'manageBtn',
                width: 40,
                render: (text = '', record: TAccessPermissionsOwnersTableRecord) => (
                    <RowButtons buttons={[EditButton.build(() => this.props.onEditClicked(record.key))]} />
                ),
            },
        ];
    };

    // TODO: сделать рефакторинг, результат которого один метод вместо двух и выпилить в hoc
    prepareGroups = (accessTypes: UserDTOAccessesEnum) => {
        const { owners } = this.props;
        const licenseOwners: TAccessPermissionOwners | undefined = owners && owners.get(accessTypes);

        return licenseOwners && Array.isArray(licenseOwners.groups)
            ? licenseOwners.groups.map((group) => group.groupName).sort()
            : [];
    };

    prepareUsers = (accessTypes: UserDTOAccessesEnum) => {
        const { owners } = this.props;
        const licenseOwners: TAccessPermissionOwners | undefined = owners && owners.get(accessTypes);

        return licenseOwners && Array.isArray(licenseOwners.users)
            ? licenseOwners.users.map((user) => user.login).sort()
            : [];
    };

    getData = () =>
        Object.keys(accessTypes).map((k) => {
            const groups = this.prepareGroups(k as UserDTOAccessesEnum).join(', ');
            const users = this.prepareUsers(k as UserDTOAccessesEnum).join(', ');

            return {
                key: k,
                title: this.props.intl.formatMessage(accessTypesMsg[k]),
                usersGroups: `${groups}\r\n${users}`,
            };
        });

    render() {

        return (
            <Table
                className={adminToolTheme.dataTable}
                columns={this.getColumns()}
                dataSource={this.getData()}
                pagination={false}
                scroll={{ y: 'max-content' }}
            />
        );
    }
}

export default injectIntl(AccessPermissionsOwners);
