/* eslint-disable react/no-array-index-key */
import React, { FC, useState } from 'react';
import { CommentElement } from './CommentElement.component';
import messages from '../messages/CommentsPanel.messages';
import theme from './CommentsPanel.scss';
import { NavigatorPanel } from '../../Navigator/components/NavigatorPanel/NavigatorPanel.component';
import { Comment } from '../../../serverapi/api';
import { CommentStatus, CommentFilterStatus } from '../../../models/commentMarkerConstants';
import { TCommentsPanelProps } from './Comments.types';
import { NewCommentArea } from './NewCommentArea/NewCommentArea.component';
import { CommentPinnedPanel } from './CommentPinnedPanel';
import { TNavigatorTab } from '../../../reducers/navigator.reducer.types';
import { PanelActionsDropdown } from './CommentsAdditionalMenu/PanelActionsDropdown/PanelActionsDropdown.component';
import { CanvasActionsDropdown } from './CommentsAdditionalMenu/CanvasActionsDropdown/CanvasActionsDropdown.component';
import arrowLeft from '../../../resources/icons/icArrow_Left.svg';
import { Icon } from '../../UIKit';
import { sortCommentsByCreatedDate } from '../utils/commentsUtils';
import { useIntl } from 'react-intl';
import { useKeyDownListener } from '@/utils/useKeyDownListener';

export const CommentsPanel: FC<TCommentsPanelProps> = (props) => {
    const {
        scheme,
        disableFilters,
        deleteComment,
        getCommentsByParentId,
        closeCommentsPanel,
        createMarker,
        deleteMarker,
        pinComment,
        unpinComment,
        getPinnedComments,
        onCancel,
        changeDisplayCommentMarkers,
        isEditedComment,
        sortedComments,
        commentsInGraph,
    } = props;

    const intl = useIntl();

    const [atFirstOld, setAtFirstOld] = useState<boolean>(false);
    const [isPinnedCommentsFiltered, setIsPinnedCommentsFiltered] = useState<boolean>(false);
    const [filterStatus, setFilterStatus] = useState<CommentFilterStatus>(CommentFilterStatus.all);
    const [isShowMarkers, setIsShowMarkers] = useState<boolean>(true);
    useKeyDownListener(['altKey', 'ctrlKey'], 'KeyC', () => setIsShowMarkers((prev) => !prev), scheme.nodeId);

    const onChangeMarkersShow = (checked: boolean, filter?: CommentFilterStatus) => {
        const commentsInGraphIds: string[] = commentsInGraph.map((comment: Comment) => comment.commentId.id);

        const filteredCommentsInGraphIds: string[] = commentsInGraph
            .filter((comment: Comment) => {
                if (filter === CommentFilterStatus.resolved) return comment.commentStatus === CommentStatus.close;
                if (filter === CommentFilterStatus.notResolved) return comment.commentStatus === CommentStatus.open;

                return true;
            })
            .map((comment: Comment) => comment.commentId.id);

        // убираем все маркеры, потом размещаем те, которые соответствуют фильтру
        changeDisplayCommentMarkers(commentsInGraphIds, false);
        changeDisplayCommentMarkers(filteredCommentsInGraphIds, checked);
    };

    const onFilterPinnedComments = () => {
        setIsPinnedCommentsFiltered((prev) => !prev);
    };

    if (!scheme) {
        return <div />;
    }

    const serverId = scheme.nodeId ? scheme.nodeId.serverId : '';
    const comments: Comment[] = atFirstOld
        ? sortedComments
        : sortedComments.reverse();
    const pinnedComments: Comment[] = getPinnedComments(props.scheme.nodeId);

    let filteredComments: Comment[] = comments;
    if (filterStatus === CommentFilterStatus.resolved) {
        filteredComments = comments.filter((e) => e.commentStatus === CommentStatus.close);
    }
    if (filterStatus === CommentFilterStatus.notResolved) {
        filteredComments = comments.filter((e) => e.commentStatus === CommentStatus.open);
    }

    filteredComments = isPinnedCommentsFiltered ? pinnedComments : filteredComments;

    const commentElements = filteredComments.reduce((acc, value) => {
        acc[value.commentId.id] = React.createRef();

        return acc;
    }, {});

    const pinnedCommentTitle = (
        <div className={theme.pinnedCommentTitleContainer}>
            <Icon
                dataTest="comment-panel_back-button"
                spriteSymbol={arrowLeft}
                className={theme.arrowLeft}
                onClick={() => {
                    setIsPinnedCommentsFiltered(false);
                }}
            />
            <span className={theme.pinnedCommentTitle}>{intl.formatMessage(messages.pinnedComments)}</span>
        </div>
    );

    return (
        <NavigatorPanel
            type={TNavigatorTab.Comments}
            titleProps={{
                title: !isPinnedCommentsFiltered ? intl.formatMessage(messages.commentsPanelTitle) : pinnedCommentTitle,
            }}
            onClose={() => {
                if (isEditedComment) {
                    onCancel(scheme.nodeId);
                } else {
                    closeCommentsPanel(scheme.nodeId);
                }
            }}
            customButtons={
                isPinnedCommentsFiltered ? undefined : (
                    <>
                        <PanelActionsDropdown
                            atFirstOld={atFirstOld}
                            setTimeFilter={setAtFirstOld}
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                        />
                        {disableFilters ? null : (
                            <CanvasActionsDropdown
                                isShowMarkers={isShowMarkers}
                                setIsShowMarkers={setIsShowMarkers}
                                onChangeMarkersShow={onChangeMarkersShow}
                            />
                        )}
                    </>
                )
            }
        >
            {!isPinnedCommentsFiltered && <NewCommentArea modelId={scheme.nodeId} />}
            {pinnedComments?.length && !isPinnedCommentsFiltered ? (
                <CommentPinnedPanel
                    modelId={scheme.nodeId}
                    isPinnedCommentsFiltered={isPinnedCommentsFiltered!}
                    filteredPinnedComments={pinnedComments || []}
                    onFilterPinnedComments={onFilterPinnedComments}
                    handleMoveToPinnedComment={(id) => {
                        const comment: HTMLDivElement | null = commentElements![id].current.querySelector('.highlight');
                        if (comment) {
                            comment.style.backgroundColor = '#c2eafd';
                            comment.style.transition = '1s';
                            setTimeout(() => {
                                comment.style.backgroundColor = '#fff';
                            }, 1000);
                        }
                        commentElements![id].current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });
                    }}
                />
            ) : (
                ''
            )}
            <div className={theme.commentsMainPanelContent} data-test="comments-main_panel-content">
                {filteredComments.length
                    ? filteredComments.map((comment: Comment, index: number) => {
                        const answers = getCommentsByParentId(
                            {
                                id: comment.nodeId,
                                repositoryId: comment.commentId.repositoryId,
                                serverId,
                            },
                            comment.commentId.id,
                        );

                        const answersSortedByCreatedDate = atFirstOld
                            ? sortCommentsByCreatedDate(answers)
                            : sortCommentsByCreatedDate(answers).reverse();

                        return (
                            <div
                                ref={commentElements[comment.commentId.id]}
                                key={`comment-panel-element_${comment.commentId.id}_${index}`}
                            >
                                <CommentElement
                                    deleteComment={deleteComment}
                                    comment={comment}
                                    scheme={scheme}
                                    answers={answersSortedByCreatedDate}
                                    filterStatus={filterStatus}
                                    isMarkersEnabled
                                    createMarker={createMarker}
                                    deleteMarker={deleteMarker}
                                    onChangeMarkersShow={onChangeMarkersShow}
                                    pinComment={pinComment}
                                    unpinComment={unpinComment}
                                />
                            </div>
                        );
                    })
                    : intl.formatMessage(messages.noComments)}
            </div>
        </NavigatorPanel>
    );
};
