export enum ModelTypes {
    ORG_CHART = 'orgChart',
    MIND_MAP = 'mindMap',
    PSD_CHART = 'psdChart',
    SEQUENCE_DIAGRAM = 'm_uml_sequence_diagram',
}

export enum GeneralModelTypeDiscriminator {
    MODEL_TYPE = 'MODEL_TYPE',
    KANBAN = 'KANBAN',
}
