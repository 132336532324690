import { TRootState } from '../reducers/root.reducer.types';
import { createSelector, Selector } from 'reselect';
import { TGetActiveContentTypeSelector, TWorkspaceTab, TWorkspaceTabItemParams } from '../models/tab.types';
import { NodeId } from '../serverapi/api';
import { DEFAULT_ZOOM_LEVEL } from '../modules/dialogs/PrintDialog/PrintDialog.component';
import { EditorMode } from '../models/editorMode';

export namespace TabsSelectors {
    const getState = (state: TRootState) => state.schemes;
    const activeIdId = (state: TRootState) => state.schemes?.activeId?.id;
    const activeIdRepositoryId = (state: TRootState) => state.schemes?.activeId?.repositoryId;
    const activeIdServerId = (state: TRootState) => state.schemes?.activeId?.serverId;

    export const getTabList: Selector<TRootState, TWorkspaceTab[]> = createSelector(getState, (state) => {
        return state.data.values();
    });

    /**
     * Селектор getActiveTabId должен кешировать значения id, repositoryId и serverId.
     * Присутствуют случаи когда в state.schemes.activeId записывается новый объект,
     * а id, repositoryId и serverId внутри него остаются без изменений.
     * Если возвращать весь объект state.schemes.activeId, то это приводит к дополнительным
     * перерисовкам компонентов
     */
    export const getActiveTabId = createSelector(
        activeIdId,
        activeIdRepositoryId,
        activeIdServerId,
        (id, repositoryId, serverId) =>
            ({
                id,
                repositoryId,
                serverId,
            } as NodeId),
    );

    export const getActiveTab = createSelector(
        getState,
        getActiveTabId,
        (state, activeTabId?: NodeId): TWorkspaceTab | undefined => activeTabId && state.data.get(activeTabId),
    );

    export const getActiveContentType = createSelector(
        getActiveTab,
        (activeTab: TWorkspaceTab | undefined): TGetActiveContentTypeSelector => activeTab?.content?.type,
    );

    export const getActiveTabZoomLevel = createSelector(
        getState,
        getActiveTabId,
        (state, activeTabId?: NodeId): number =>
            (activeTabId && state.data.get(activeTabId)?.params.zoomLevel) || DEFAULT_ZOOM_LEVEL,
    );

    export const getActiveTabParams = createSelector(
        getState,
        getActiveTabId,
        (state, activeTabId: NodeId): TWorkspaceTabItemParams => state.data.get(activeTabId)?.params,
    );

    export const byId = (nodeId: NodeId) => {
        return createSelector(getState, (state): TWorkspaceTab => state.data.get(nodeId));
    };

    export const getEditorModeById = (nodeId: NodeId) => createSelector(byId(nodeId), (tab): EditorMode => tab?.mode);

    export const getTabTitleById = (nodeId: NodeId) => {
        return createSelector(byId(nodeId), (tab): string => tab.title);
    };

    export const getClipboard = () => {
        return createSelector(getState, (state) => state.clipboard);
    };
}
