import { TAction } from '../actions/index.actions.types';

export type TReducer<S> = (state: S, action: TAction) => S;

export enum PaperFormat {
    A0 = 'A0',
    A1 = 'A1',
    A2 = 'A2',
    A3 = 'A3',
    A4 = 'A4',
    A5 = 'A5',
    A6 = 'A6',
    A7 = 'A7',
    B4 = 'B4',
    B5 = 'B5',
}

export enum LengthUnit {
    MM = 'MM',
    CM = 'CM',
    PX = 'PX',
}

export enum Orientation {
    PORTRAIT = 'PORTRAIT',
    LANDSCAPE = 'LANDSCAPE',
}

export enum ZoomMode {
    SCALE = 'SCALE',
    FIT = 'FIT',
}

export enum Printable {
    MODEL = 'MODEL',
    SELECTION = 'SELECTION',
}

export type TPrintOptions = {
    grayscale: boolean;
    orientation: Orientation;
    paperFormat: PaperFormat;
    zoomMode: ZoomMode;
    zoomScale?: number;
    zoomFitAcross: number;
    zoomFitDown: number;
    showOffsets: boolean;
    printable: Printable;
};

export interface IEnumValue {
    value: number;
    name: string;
}

export type TextBlockStyle = Pick<
    CSSStyleDeclaration,
    | 'width'
    | 'fontFamily'
    | 'fontSize'
    | 'fontWeight'
    | 'textDecoration'
    | 'fontStyle'
    | 'textTransform'
    | 'textAlign'
    | 'lineHeight'
    | 'overflowWrap'
    | 'padding'
>;
