import { put, select, takeEvery, call } from 'redux-saga/effects';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction } from '../modules/Tree/models/tree';
import { IWorkspaceImportArisTabItemParams, TWorkspaceTab } from '../models/tab.types';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import messages from '../modules/Tree/messages/TreeContextMenu.messages';
import importArisMessages from '../modules/Import/Aris/messages/ImportAris.messages';
import { workspaceAddTab } from '../actions/tabs.actions';
import {
    changeImportArisStage,
    clearArisDescriptorFields,
    exportFileArisSuccess,
    setArisData,
    setArisResponseError,
    setInitialArisDescriptor,
} from '../actions/importAris.actions';
import { ArisData, ArisDataNode, ArisImportDescriptor, ArisImportDescriptorStageEnum } from '../serverapi/api';
import {
    LOAD_ARIS_FROM_EXCEL,
    SAVE_ARIS_IN_EXCEL,
    SEND_ANALYSIS_ARIS_FILE,
    START_ARIS_IMPORT,
    UPLOAD_FILE_EXPORT_ARIS,
} from '../actionsTypes/importAris.actionTypes';
import {
    TLoadArisFromExcel,
    TSaveArisInExcel,
    TSendAnalysisArisFileAction,
    TStartArisImportAction,
    TUploadFileExportArisAction,
} from '../actions/importAris.actions.types';
import { TreeSelectors } from '../selectors/tree.selectors';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { ImportArisDaoService } from '../services/dao/ImportArisDaoService';
import { FileDaoService } from '../services/dao/FileDaoService';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { getOrigin, replaceLastSlash } from '../utils/url.utils';
import { treeItemRefresh } from '../actions/tree.actions';
import { ImportArisSelectors } from '../selectors/importAris.selectors';
import { BrowserDaoService } from '../services/dao/BrowserDaoService';
import { cutExtensionInFileName } from '../modules/Import/Aris/components/ImportAris/importAris.utils';
import { showNotification } from '../actions/notification.actions';
import { v4 as uuid } from 'uuid';
import { NotificationType } from '../models/notificationType';

function* handleOpenImportAris({ payload: { nodeId, action } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.IMPORT_ARIS) {
        const { id, repositoryId, serverId } = nodeId;

        const { path } = yield TreeDaoService.getNodePath(nodeId.serverId, id, repositoryId);
        const stage: ArisImportDescriptorStageEnum = 'UPLOAD';

        const intl = LocalesService.useIntl(yield select(getCurrentLocale));

        yield put(setInitialArisDescriptor({ serverId, repositoryId, path, nodeId, stage }));

        const contentLoadingPageTab: TWorkspaceTab = {
            title: intl.formatMessage(messages.importAris),
            nodeId,
            type: WorkSpaceTabTypes.IMPORT_ARIS,
            mode: EditorMode.Read,
            params: {
                nodeId,
                path,
            } as IWorkspaceImportArisTabItemParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };

        yield put(workspaceAddTab(contentLoadingPageTab));
    }
}

function* handleUploadFileExportAris({
    payload: { nodeId, file, onUploadProgress, setXhr },
}: TUploadFileExportArisAction) {
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    try {
        const hostName = replaceLastSlash(getOrigin());
        const baseUrl = `/api/file-tmp-storage/uploadTmpFile`;
        const URL = hostName + baseUrl;
        const fileUUID: string = (yield FileDaoService.uploadTmpFileWithProgress(file, onUploadProgress, URL, setXhr))
            .result;
        yield put(exportFileArisSuccess({ fileId: fileUUID, nodeId, fileName: file.name }));
    } catch (e) {
        if (!e) return;
        const error = intl.formatMessage(importArisMessages.errorUploadFile);
        if (e.statusText) {
            yield put(setArisResponseError({ nodeId, error: e.statusText }));
        }
        // eslint-disable-next-line no-console
        console.error(error, e.statusText);
    }
}

function* handleAnalysisArisFile({ payload: { nodeId, fileId } }: TSendAnalysisArisFileAction) {
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const presetId: string = yield select(TreeSelectors.presetById(nodeId));

    try {
        const arisData: ArisData = yield ImportArisDaoService.analizeArisImport(presetId, fileId);
        yield put(setArisData({ nodeId, arisData }));
    } catch (e) {
        const error = intl.formatMessage(importArisMessages.errorAnalysis);
        yield put(setArisResponseError({ nodeId, error }));
        // eslint-disable-next-line no-console
        console.error(error);
    }
}

function* handleStartArisImport({ payload: { nodeId, descriptor } }: TStartArisImportAction) {
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    try {
        yield ImportArisDaoService.arisDoImport(descriptor);
        yield put(changeImportArisStage({ nodeId, stage: 'FINISH' }));
        yield put(clearArisDescriptorFields({ nodeId }));
        yield put(treeItemRefresh(descriptor.destination));
    } catch (e) {
        const error = intl.formatMessage(importArisMessages.errorArisImport);
        if (e.statusText) {
            yield put(setArisResponseError({ nodeId, error }));
        }
        // eslint-disable-next-line no-console
        console.error(error);
    }
}

function* handleLoadArisFromExcel({ payload: { nodeId, file } }: TLoadArisFromExcel) {
    const { serverId, repositoryId, id } = nodeId;
    const descriptor: ArisImportDescriptor | undefined = yield select(
        ImportArisSelectors.getArisDescriptor(serverId, repositoryId, id),
    );
    const nodesTree: ArisDataNode[] | undefined = descriptor?.arisData?.nodesTree;
    try {
        const arisData: ArisData = yield ImportArisDaoService.arisGetMapping(file);
        yield put(setArisData({ nodeId, arisData: { ...arisData, nodesTree } }));
    } catch (e) {
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType.LOAD_ARIS_FROM_EXCEL_ERROR,
            }),
        );
    }
}

function* handleSaveArisInExcel({ payload: { nodeId } }: TSaveArisInExcel) {
    const { serverId, repositoryId, id } = nodeId;
    const descriptor: ArisImportDescriptor | undefined = yield select(
        ImportArisSelectors.getArisDescriptor(serverId, repositoryId, id),
    );
    const arisData: ArisData | undefined = descriptor?.arisData;
    const fileName: string = descriptor?.fileName || '';
    if (!arisData) {
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType.SAVE_ARIS_IN_EXCEL_ERROR,
            }),
        );
        return;
    }
    try {
        const response: Response = yield ImportArisDaoService.arisGetExcel({ ...arisData, nodesTree: undefined });
        const file: File = yield call(() => response.blob());
        const fileNameWithoutExtension: string = cutExtensionInFileName(fileName);
        BrowserDaoService.downloadFile(file, {
            defaultPath: fileName ? `${fileNameWithoutExtension}.xlsx` : `${id}.xlsx`,
        });
    } catch (e) {
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType.SAVE_ARIS_IN_EXCEL_ERROR,
            }),
        );
    }
}

export function* importArisSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenImportAris);
    yield takeEvery(UPLOAD_FILE_EXPORT_ARIS, handleUploadFileExportAris);
    yield takeEvery(SEND_ANALYSIS_ARIS_FILE, handleAnalysisArisFile);
    yield takeEvery(START_ARIS_IMPORT, handleStartArisImport);
    yield takeEvery(LOAD_ARIS_FROM_EXCEL, handleLoadArisFromExcel);
    yield takeEvery(SAVE_ARIS_IN_EXCEL, handleSaveArisInExcel);
}
