import { Button, Table } from 'antd';
import React, { FC, useState } from 'react';
import theme from '../PresetProfile.scss';
import { TabHeader } from '../../Header/TabHeader.component';
import { useIntl } from 'react-intl';
import messages from '../PresetProfile.messages';
import { useDispatch, useSelector } from 'react-redux';
import { ModelType, ObjectType, PresetTypesAcl } from '../../../../../../../serverapi/api';
import { setAclRights, setAllAclRights } from '../../../../../../../actions/acl.actions';
import { AclSelectors } from '../../../../../../../selectors/acl.selectors';
import checkIcon from '../../../../../../../resources/icons/ic-acl-checked.svg';
import disabledIcon from '../../../../../../../resources/icons/ic-acl-disabled.svg';
import TableExpandIcon from '../../../../../TableExpandIcon/TableExpandIcon.component';
import { Icon } from '../../../../../../UIKit/components/Icon/Icon.component';
import { getEntityWithAcl } from '../utils';
import {
    TAclRightTypes,
    TEntityDataWithAcl,
    TEntityPrincipal,
    TPresetProfileEntityTypes,
} from '../presetProfile.types';
import { ObjectTypeSelectors } from '../../../../../../../selectors/objectType.selectors';
import { SymbolSelectors } from '../../../../../../../selectors/symbol.selectors';
import icObject from '../../../../../../../resources/icons/object.svg';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { v4 as uuid } from 'uuid';
import allowIcon from '../../../../../../../resources/icons/ic-checkbox-allow-color.svg';
import denyIcon from '../../../../../../../resources/icons/ic-checkbox-deny-color.svg';
import { Locale } from '../../../../../../Header/components/Header/header.types';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../../services/LocalesService';

type TPresetProfileObjectTypes = {
    serverId: string;
    presetId: string;
    profileId: string;
};

const PresetProfileObjectTypes: FC<TPresetProfileObjectTypes> = (props) => {
    const { serverId, presetId, profileId } = props;
    const intl = useIntl();
    const objectTypes = useSelector(ObjectTypeSelectors.listAllByPreset(serverId, presetId));
    const allSymbolsInPreset = useSelector(SymbolSelectors.byServerIdPresetId(serverId, presetId));
    const dispatch = useDispatch();
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [graph, setGraph] = useState<MethodologiesGraph | undefined>();
    const dataObject = useSelector(
        AclSelectors.allOfTypeInPreset({ profileId, serverId, presetId, type: TPresetProfileEntityTypes.object }),
    );
    const dataSymbol = useSelector(
        AclSelectors.allOfTypeInPreset({ profileId, serverId, presetId, type: TPresetProfileEntityTypes.symbol }),
    );
    const currentLocale: Locale = useSelector(getCurrentLocale);
    const objectAcls = dataObject?.acls || [];
    const symbolAcls = dataSymbol?.acls || [];

    const dataSource = objectTypes.reduce((groups: TEntityPrincipal[], object: ObjectType) => {
        const groupIndex = groups.findIndex((group) => group.id === object.objectTypeGroup.id);
        const objSymbols = allSymbolsInPreset.filter((sym) => sym.objectType === object.id);
        const symbolGroup = objSymbols.reduce((summ: TEntityDataWithAcl[], objSymbol) => {
            // поиск по имени символа
            if (objSymbol.name.toLowerCase().includes(searchFilter.toLowerCase())) {
                const symbolAcl = getEntityWithAcl(symbolAcls || [], objSymbol, currentLocale, object.id);
                summ.push(symbolAcl);
            }

            return summ;
        }, []);
        // если в типе объекта ни один символ не подошел под поисковую строку то этот тип объекта не отображать
        if (symbolGroup.length) {
            const objectAcl = getEntityWithAcl(objectAcls || [], object, currentLocale);
            objectAcl.children = symbolGroup;
            if (groupIndex === -1) {
                const objectGroup = {
                    id: object.objectTypeGroup.id,
                    name: LocalesService.internationalStringToString(
                        object.objectTypeGroup.multilingualName,
                        currentLocale,
                    ),
                    children: [objectAcl],
                };
                groups.push(objectGroup);
            } else {
                groups[groupIndex].children.push(objectAcl);
            }
        }

        return groups;
    }, []);

    const handleClick = (
        e: React.MouseEvent,
        right: TAclRightTypes,
        acl: PresetTypesAcl,
        record: TEntityPrincipal | TEntityDataWithAcl,
        isFolder: boolean,
    ) => {
        e.stopPropagation();
        dispatch(
            setAclRights({
                presetId,
                serverId,
                type: record.children ? TPresetProfileEntityTypes.object : TPresetProfileEntityTypes.symbol,
                ids: [acl.id],
                rightKey: right,
                rightValue: !acl[right],
                profileId,
            }),
        );

        if (record.children && !isFolder) {
            dispatch(
                setAclRights({
                    presetId,
                    serverId,
                    profileId,
                    type: TPresetProfileEntityTypes.symbol,
                    ids: record.children.map((child) => child.id),
                    rightKey: right,
                    rightValue: !acl[right],
                }),
            );
        }
    };

    const renderAclFieldByType =
        (right: TAclRightTypes) => (acl: PresetTypesAcl, record: TEntityPrincipal | TEntityDataWithAcl) => {
            const isFolder = !!record.children && !(record as TEntityDataWithAcl).acl;
            if (isFolder) {
                // папка c объектами
                return '';
            }

            let isRestrictedByParent = false;

            const isSymbol = !record.children && (record as TEntityDataWithAcl).acl;
            if (isSymbol) {
                const parentAcl = objectAcls.find((objectAcl) => objectAcl.id === record.parentId);
                isRestrictedByParent = !(parentAcl?.[right] ?? true);
            }

            return (
                <Button
                    disabled={isRestrictedByParent}
                    type="link"
                    onClick={(e) => handleClick(e, right, acl, record, isFolder)}
                >
                    <Icon spriteSymbol={acl[right] ? allowIcon : denyIcon} />
                </Button>
            );
        };

    const columns = [
        {
            title: intl.formatMessage(messages.name),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            className: theme.column,
            render: (value: string, record: TEntityPrincipal | TEntityDataWithAcl) => {
                const isSymbol = !record.children && (record as TEntityDataWithAcl).acl;
                if (isSymbol) {
                    const symbol = allSymbolsInPreset.find((sym) => sym.id === record.id);
                    if (symbol) {
                        return SymbolToImageConverterGraph.convertSymbol(symbol, intl, graph);
                    }
                }

                return value;
            },
        },
        {
            title: intl.formatMessage(messages.id),
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
        },
        {
            title: intl.formatMessage(messages.create),
            dataIndex: 'acl',
            key: 'create',
            width: 75,
            render: renderAclFieldByType('create'),
        },
        {
            title: intl.formatMessage(messages.read),
            dataIndex: 'acl',
            key: 'read',
            width: 70,
            render: renderAclFieldByType('read'),
        },
        {
            title: intl.formatMessage(messages.update),
            dataIndex: 'acl',
            key: 'update',
            width: 90,
            render: renderAclFieldByType('update'),
        },
        {
            title: intl.formatMessage(messages.delete),
            dataIndex: 'acl',
            key: 'delete',
            width: 80,
            render: renderAclFieldByType('delete'),
        },
    ];

    const setAllAcl = (allow: boolean) => () => {
        dispatch(
            setAllAclRights({
                presetId,
                serverId,
                type: TPresetProfileEntityTypes.object,
                allow,
                entityTypeIds: objectTypes.map((object) => object.id),
                profileId,
            }),
        );
        dispatch(
            setAllAclRights({
                presetId,
                serverId,
                type: TPresetProfileEntityTypes.symbol,
                allow,
                entityTypeIds: allSymbolsInPreset.map((symbol) => symbol.id),
                profileId,
            }),
        );
    };

    const converterInitialized = (g: MethodologiesGraph) => {
        if (!graph) {
            setGraph(g);
        }
    };

    return (
        <section className={theme.container}>
            <SymbolToImageConverterGraph modelType={{ id: uuid() } as ModelType} initialized={converterInitialized} />
            <div className={theme.headerContainer}>
                <TabHeader onSearchChange={setSearchFilter} />
                <div className={theme.btns}>
                    <Button onClick={setAllAcl(true)} icon={<Icon spriteSymbol={checkIcon} />}>
                        {intl.formatMessage(messages.allowAll)}
                    </Button>
                    <Button onClick={setAllAcl(false)} icon={<Icon spriteSymbol={disabledIcon} />}>
                        {intl.formatMessage(messages.denyAll)}
                    </Button>
                </div>
            </div>
            <Table
                rowKey={(row: TEntityPrincipal) => row.id}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                expandRowByClick
                expandIcon={({ record, onExpand, expandable, expanded }) => {
                    const isSymbolsFolder = record.children && (record as TEntityDataWithAcl).acl;

                    return (
                        <TableExpandIcon
                            record={record}
                            expandable={expandable}
                            expanded={expanded}
                            onExpand={onExpand}
                            expandIcon={isSymbolsFolder && icObject}
                        />
                    );
                }}
                className={theme.table}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
            />
        </section>
    );
};

export default PresetProfileObjectTypes;
