import { put, select, takeEvery } from 'redux-saga/effects';
import { NodeId, RootNodeId } from '../serverapi/api';
import {
    SELECT_SCRIPT_TO_SCHEDULE_DIALOG_INIT,
    SCRIPT_SELECT_DIALOG_INIT,
    SCRIPT_SETTINGS_SELECT_DIALOG_INIT,
} from '../actionsTypes/scriptSelectDialog.actionTypes';
import {
    TSelectScriptToScheduleDialogInitAction,
    TScriptSelectDialogInitAction,
} from '../actions/scriptSelectDialog.actions.types';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { treeItemExpand, treePartFetchAllScriptsRequest } from '../actions/tree.actions';
import { ServerSelectors } from '@/selectors/entities/server.selectors';

export function* handleScriptRequest({
    payload: { serverId, nodeId, nodesIdsList, modelId, element, elementsIdsList, schedule },
}: TScriptSelectDialogInitAction) {
    const scriptFolderNodeId: NodeId = {
        id: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        serverId,
    };
    yield put(treeItemExpand(scriptFolderNodeId, DialogType.DIAGRAM_EXECUTE_SCRIPT_DIALOG));
    yield put(
        openDialog(DialogType.DIAGRAM_EXECUTE_SCRIPT_DIALOG, {
            serverId,
            nodeId,
            nodesIdsList,
            modelId,
            element,
            elementsIdsList,
            schedule,
        }),
    );
    yield put(treePartFetchAllScriptsRequest(scriptFolderNodeId));
}

export function* handleScriptSettingsRequest() {
    const serverId: string = yield select(ServerSelectors.serverId);
    const scriptFolderNodeId: NodeId = {
        id: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        serverId,
    };
    yield put(treePartFetchAllScriptsRequest(scriptFolderNodeId));
    yield put(treeItemExpand(scriptFolderNodeId, DialogType.EVENT_SCRIPT_DIALOG));
}

export function* handleSelectScriptToScheduleDialogInit({
    payload: { serverId, nodeId, nodesIdsList, modelId, element, elementsIdsList, setScriptDataState },
}: TSelectScriptToScheduleDialogInitAction) {
    const scriptFolderNodeId: NodeId = {
        id: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        serverId,
    };
    yield put(treeItemExpand(scriptFolderNodeId, DialogType.SELECT_SCRIPT_TO_SCHEDULE_DIALOG));
    yield put(
        openDialog(DialogType.SELECT_SCRIPT_TO_SCHEDULE_DIALOG, {
            serverId,
            nodeId,
            nodesIdsList,
            modelId,
            element,
            elementsIdsList,
            setScriptDataState,
        }),
    );
    yield put(treePartFetchAllScriptsRequest(scriptFolderNodeId));
}

export function* scriptSelectDialogSagaInit() {
    yield takeEvery(SCRIPT_SELECT_DIALOG_INIT, handleScriptRequest);
    yield takeEvery(SCRIPT_SETTINGS_SELECT_DIALOG_INIT, handleScriptSettingsRequest);
    yield takeEvery(SELECT_SCRIPT_TO_SCHEDULE_DIALOG_INIT, handleSelectScriptToScheduleDialogInit);
}
