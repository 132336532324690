import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { NodeId } from '../../../../../serverapi/api';
import { Dialog } from '../../../../UIKit/components/Dialog/Dialog.component';
import messages from '../../../messages/NewSimulationNodeDIalog.messages';

type TNewSimulationNodeDialogProps = {
    parentNodeId: NodeId;
    onClose: () => void;
    onSubmit: (name: string, parentNodeId: NodeId) => void;
};

export const NewSimulationNodeDialog = (props: TNewSimulationNodeDialogProps) => {
    const { onClose, parentNodeId, onSubmit } = props;
    const [name, setName] = useState<string>();
    const intl = useIntl();
    const footer = [
        <Button
            key="cancel"
            onClick={onClose}
            data-test="create-element_im_cancel-button"
        >
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button
            disabled={!name?.trim()}
            onClick={() => {
                if (name) {
                    onSubmit(name, parentNodeId);
                }
            }}
            data-test="create-element_im_save-button"
        >
            {intl.formatMessage(messages.save)}
        </Button>,
    ];

    return (
        <Dialog
            visible
            okButtonProps={{ disabled: !name }}
            onOk={() => {
                if (name) {
                    onSubmit(name, parentNodeId);
                }
            }}
            onCancel={onClose}
            title={intl.formatMessage(messages.simulationAddTitle)}
            footer={footer}
        >
            <Form layout="vertical">
                <Form.Item
                    label={intl.formatMessage(messages.name)}
                    name="simulationModelTitle"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: intl.formatMessage(messages.requiredField),
                        },
                    ]}
                >
                    <Input
                        data-test="imitation-model-form_model-name-input"
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                        value={name}
                        autoComplete="off"
                        autoFocus
                    />
                </Form.Item>
            </Form>
        </Dialog>
    );
};
