import { ModelTypeGroup } from '../serverapi/api';
import { TReducer } from '../utils/types';
import { ADD_MODEL_TYPE_GROUPS, CLEAR_PRESET_REQUEST, SUBMIT_MODEL_TYPE_GROUP } from '../actionsTypes/methodologySetting.actionTypes';
import { DELETE_MODEL_TYPE_GROUP_SUCCESS } from '../actionsTypes/modelType.actionTypes';
import { TModelTypeGroupState } from './modelTypeGroup.reducer.types';

export const INITIAL_OBJECT_TYPE_SERVER_STATE = {
    byPresetId: {},
};

const initial: TModelTypeGroupState = {
    byServerId: {},
};

const initStateWithServerAndPreset = (state: TModelTypeGroupState, serverId: string, presetId: string) => {
    if (!state.byServerId[serverId]) {
        state.byServerId[serverId] = {
            byPresetId: {},
        };
    }
    if (!state.byServerId[serverId].byPresetId[presetId]) {
        state.byServerId[serverId].byPresetId[presetId] = {
            byId: {},
        };
    }
};

export const modelTypeGroupReducer: TReducer<TModelTypeGroupState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_MODEL_TYPE_GROUPS:
        case SUBMIT_MODEL_TYPE_GROUP: {
            const {
                payload: { serverNode, preset, modelTypeGroups },
            } = action;
            const stateClone: TModelTypeGroupState = JSON.parse(JSON.stringify(state));
            const presetId = preset.id;
            const {serverId} = serverNode.nodeId;
            if (serverId && presetId) {
                initStateWithServerAndPreset(stateClone, serverId, presetId);
                const preset = stateClone.byServerId[serverId].byPresetId[presetId];
                preset.byId = {
                    ...preset.byId,
                    ...modelTypeGroups.reduce((acc, modelTypeGroup: ModelTypeGroup) => {
                        acc[modelTypeGroup.id] = modelTypeGroup;

                        return acc;
                    }, {}),
                };
            }

            return stateClone;
        }
        case DELETE_MODEL_TYPE_GROUP_SUCCESS: {
            const { modelTypeGroups, serverNode } = action.payload;
            if (modelTypeGroups) {
                const {serverId} = serverNode.nodeId;
                const stateClone = JSON.parse(JSON.stringify(state));
                modelTypeGroups.forEach((modelTypeGroup) => {
                    const {presetId} = modelTypeGroup;
                    if (serverId && presetId) {
                        const byId = stateClone.byServerId[serverId]?.byPresetId[presetId]?.byId || {};
                        delete byId[modelTypeGroup.id];
                    }
                });

                return stateClone;
            }
            
            return state;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }
        default:
            return state;
    }
};
