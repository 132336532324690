import React, { useCallback, useEffect } from 'react';
import { Tooltip } from 'antd';
import cx from 'classnames';
import TreeItemIcon from '../../../Tree/components/TreeItem/TreeItemIcon.component';
import theme from './Workspace.scss';
import { IWorkspaceTabItemModelParams } from '../../../../models/tab.types';
import {
    convertNodeIdToString,
    getItemType,
    getSpecialIcon,
} from './Workspace.component.utils';
import { TreeItemType } from '../../../Tree/models/tree';
import { useIntl } from 'react-intl';
import modelTypeMessages from '../../../../models/modelType.messages';
import { WorkSpaceTabTypes } from '../../WorkSpaceTabTypesEnum';
import rightWhiteCorner from '../../../../resources/part-tab-right-white-bg.svg';
import leftWhiteCorner from '../../../../resources/part-tab-left-white-bg.svg';
import rightGrayCorner from '../../../../resources/part-tab-right-gray-bg.svg';
import leftGrayCorner from '../../../../resources/part-tab-left-gray-bg.svg';
import messages from '../../messages/HomePageTab.messages';
import { WorkspaceTabTitleContextMenu } from './WorkspaceTabTitleContextMenu.component';
import { TWorkspaceTabTitleProps } from '../../Workspace.types';
import { useContextMenuDublicateDelete } from '../../../../hooks/useContextMenuDublicateDelete';
import { Icon } from '../../../UIKit';
import NoIcon from '../../../../resources/icons/NoIcon.svg';
import { METHODOLOGY_SETTINGS_TYPES_ARR } from '../../workspaceTab.constants';

export const WorkspaceTabTitle = ({ tab, activeKey }: TWorkspaceTabTitleProps) => {
    const intl = useIntl();
    const [isContextMenuVisible, setContextMenuVisible] = useContextMenuDublicateDelete(
        'WorkspaceTabTitle',
        tab.nodeId,
    );

    const showContextMenu = useCallback(() => {
        setContextMenuVisible(true);
    }, []);

    const hideContextMenu = useCallback(() => {
        setContextMenuVisible(false);
    }, []);

    useEffect(() => {
        return () => {
            const tabDropdown = document.querySelector('.ant-tabs-dropdown') as HTMLDivElement;
            if (tabDropdown) {
                tabDropdown.classList.add('ant-tabs-dropdown-hidden');
            }
        };
    }, []);

    const anyTypeContent = tab.content as any;
    const isActiveTab = convertNodeIdToString(activeKey) === convertNodeIdToString(tab.nodeId);
    let tooltipSuffix = '';

    if (anyTypeContent) {
        switch (anyTypeContent.type) {
            case TreeItemType.Matrix:
                tooltipSuffix = `[${intl.formatMessage(modelTypeMessages.MATRIX)}]`;
                break;
            case TreeItemType.Wiki:
                tooltipSuffix = `[${intl.formatMessage(modelTypeMessages.WIKI)}]`;
                break;
            case TreeItemType.Model:
                tooltipSuffix = anyTypeContent.modelTypeName ? `[${anyTypeContent.modelTypeName}]` : '';
                if ((tab?.params as IWorkspaceTabItemModelParams | undefined)?.modelType?.name) {
                    tooltipSuffix = `[${(tab.params as IWorkspaceTabItemModelParams).modelType?.name}]`;
                }
                break;
            case TreeItemType.Kanban:
                tooltipSuffix = `[${intl.formatMessage(modelTypeMessages.KANBAN)}]`;
                break;
        }
    }

    const isHomePage = tab.type === WorkSpaceTabTypes.HOME_PAGE_TAB;
    const titleText = tab.title || intl.formatMessage(messages.homePageName);
    const rightIcon = (isActiveTab ? rightWhiteCorner : rightGrayCorner) as unknown as string;
    const leftIcon = (isActiveTab ? leftWhiteCorner : leftGrayCorner) as unknown as string;

    const onContextMenu = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        showContextMenu();
    }, []);

    return (
        <WorkspaceTabTitleContextMenu tab={tab} visible={isContextMenuVisible} onVisibilityChange={hideContextMenu}>
            <Tooltip title={`${titleText} ${tooltipSuffix}`}>
                <div
                    onContextMenu={onContextMenu}
                    className={cx(
                        theme.tabTitleContainer,
                        isActiveTab && theme.activeTab,
                        isHomePage && theme.homePageTab,
                    )}
                >
                    <img className={theme.leftIcon} src={leftIcon} />
                    <div className={theme.treeItemIcon}>
                        {METHODOLOGY_SETTINGS_TYPES_ARR.some(
                            (methodologySettingsTab: WorkSpaceTabTypes) => methodologySettingsTab === tab.type,
                        ) ? (
                                <Icon className={theme.entityIcon} spriteSymbol={NoIcon} />
                            ) : (
                                <TreeItemIcon
                                    type={getItemType(tab.type, tab.content)}
                                    nodeId={tab.nodeId}
                                    specialIcon={getSpecialIcon(tab.type, tab.content)}
                                />
                            )}
                    </div>
                    <span className={theme.tabTitleText} data-test="workspace-tab-name">
                        {titleText}
                    </span>
                    <img className={theme.rightIcon} src={rightIcon} />
                </div>
            </Tooltip>
        </WorkspaceTabTitleContextMenu>
    );
};
