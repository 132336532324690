import type { FavoriteNodeDTO, NodeId, Symbol } from '@/serverapi/api';
import type { TTreeNodeWithLevel } from '../../Tree.types';
import type { TreeNode } from '@/models/tree.types';
import { ExpandStatus } from '@/reducers/tree.reducer.types';
import React, { FC, useCallback } from 'react';
import { TreeItemType } from '../../models/tree';
import { FILE_ICON_TYPES } from '../../TreeItemIcons/treeItemIcons';
import theme from '../Tree/Tree.scss';
import classnames from 'classnames';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedItem, TreeSelectors } from '@/selectors/tree.selectors';
import { TreeItem } from '../TreeItem/TreeItem.component';
import { treeItemMove, treeItemsSelectSeveral, treeItemsSelectSlice } from '@/actions/tree.actions';
import { ServerProfileSelectors } from '@/selectors/serverProfile.selectors';
import { NavigatorTreeSearchSelector } from '@/selectors/navigatorTreeSearch.selectors';
import { getSelectedSlice } from '@/modules/Navigator/components/NavigatorSymbols/utils';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';

const LEVEL_PADDING = 9;

const getFileType = (extension: string): FILE_ICON_TYPES => {
    const ex = extension.toLocaleLowerCase().trim();
    if (['doc', 'docx', 'docm', 'dot', 'dotx'].includes(ex)) {
        return FILE_ICON_TYPES.WORD_FILE;
    }
    if (['xls', 'xlsx', 'xlt', 'xltx'].includes(ex)) {
        return FILE_ICON_TYPES.EXCEL_FILE;
    }
    if (['png', 'jpg', 'jpeg', 'svg', 'bpm', 'gif'].includes(ex)) {
        return FILE_ICON_TYPES.IMG_FILE;
    }
    if (['txt'].includes(ex)) {
        return FILE_ICON_TYPES.TEXT_FILE;
    }

    return FILE_ICON_TYPES.FILE;
};

type TTreeRowProps = {
    rowData: TTreeNodeWithLevel;
    symbols: Symbol[];
    selectedNodes: {
        nodeId: NodeId;
    }[];
    favoriteNodes: FavoriteNodeDTO[];
    selectedFoundNodeId: NodeId | undefined;
    treeName: string;
    isDndEnabled: boolean;
    isNavigatorStructure: boolean;
    disableContextMenu: boolean | undefined;
    flatData: TTreeNodeWithLevel[];
    getExpandStatus: (nodeId: NodeId) => ExpandStatus;
    onSelect: (selectedTreeItem: any) => void;
};

export const TreeRow: FC<TTreeRowProps> = (props) => {
    const {
        selectedFoundNodeId,
        isNavigatorStructure,
        flatData,
        isDndEnabled,
        disableContextMenu,
        treeName,
        symbols,
        selectedNodes,
        favoriteNodes,
        rowData,
        onSelect,
        getExpandStatus,
    } = props;
    const {
        name,
        nodeId,
        type,
        hasChildren,
        modelTypeId,
        idSymbol,
        language,
        parentNodeId,
        objectTypeId,
        edgeTypeId,
        fileFolderType,
        extension,
        countChildren,
        folderType,
        deleted,
        level,
    } = rowData;
    const { id } = nodeId;

    const dispatch = useDispatch();

    const connected = useSelector(TreeSelectors.connected);
    const connectedServerId = connected[0];
    const lastSelectedNode: TreeNode | undefined = useSelector(getSelectedItem);
    const profileName: string | undefined = useSelector(
        ServerProfileSelectors.activeProfileName(lastSelectedNode?.nodeId?.serverId!),
    );
    const menuMessageValues = profileName || '';
    const isSearchActive: boolean = useSelector(NavigatorTreeSearchSelector.getIsSearchActive);

    const expandStatus = getExpandStatus(nodeId);
    const isItemSelected = selectedNodes.some((selectedNode) => compareNodeIds(selectedNode.nodeId, nodeId));
    const isAddFavoriteTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG;
    const isApprovalCopyTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG;
    const foundNodeIds: NodeId[] = useSelector(NavigatorTreeSearchSelector.getFoundedNodeIds);

    const itemClassName = classnames(theme.item, {
        [theme.item_selected]: isItemSelected,
    });

    let specialIcon: string = '';
    if (idSymbol) {
        const symbol: Symbol | undefined = symbols.find((x) => x.id === idSymbol);
        specialIcon = symbol ? symbol.icon : '';
    }

    if (type === TreeItemType.Script && language) {
        specialIcon = language;
    }
    if (type === TreeItemType.File && extension) {
        specialIcon = FILE_ICON_TYPES[getFileType(extension)];
    }

    const onDropTreeItem = useCallback(
        (droppableNodeId: NodeId, targetNodeId: NodeId) => {
            if (!selectedNodes.some((n) => compareNodeIds(n.nodeId, droppableNodeId))) {
                // если есть выделенный кусок, но тащим другой узел
                onSelect(flatData.find((n) => compareNodeIds(n.nodeId, droppableNodeId))!);
            }
            dispatch(treeItemMove({ targetNodeId }));
        },
        [selectedNodes, flatData, onSelect],
    );

    const onSelectSlice = (selectedTreeItem) => dispatch(treeItemsSelectSlice(selectedTreeItem));
    const onSelectSeveral = (selectedTreeItem) => dispatch(treeItemsSelectSeveral(selectedTreeItem));

    const onSelectTreeItem = useCallback(
        (event: React.MouseEvent<HTMLInputElement>) => {
            if (event.shiftKey) {
                onSelectSlice(getSelectedSlice(flatData, rowData, lastSelectedNode));
            } else if (event.ctrlKey || event.metaKey) {
                onSelectSeveral(rowData);
            } else {
                const submenuElements = document.getElementsByClassName('ant-dropdown-menu-submenu-active');
                // Чтобы не сбрасывсалось выделение с нескольких элементов при наждатии на подменю
                // Проверяем, является ли элемент меню выпадающим списком, если является, то выделение не сбрасываем
                if (!submenuElements.length) {
                    onSelect(rowData);
                }
            }
        },
        [rowData, flatData, lastSelectedNode, onSelect],
    );

    const isFound =
        ((isNavigatorStructure && isSearchActive) || isApprovalCopyTree || isAddFavoriteTree) &&
        foundNodeIds.find((foundNodeId) => compareNodeIds(foundNodeId, nodeId)) &&
        !isItemSelected;

    const isSelectedFound =
        isNavigatorStructure &&
        isSearchActive &&
        compareNodeIds(nodeId, selectedFoundNodeId) &&
        !selectedNodes.some((node) => compareNodeIds(node.nodeId, selectedFoundNodeId));

    const favorite = !!favoriteNodes?.find((node: FavoriteNodeDTO) => compareNodeIds(node.nodeId, nodeId));

    const dataTest = `tree-menu_item${type === TreeItemType.Server ? '-server' : ''}${isFound ? '-found' : ''}${
        isSelectedFound ? '-selected' : ''
    }${deleted ? '-deleted' : ''}`;

    return (
        <div
            key={id}
            id={`tree-item-selected_${isItemSelected}`}
            data-test={dataTest}
            onClick={onSelectTreeItem}
            style={{ paddingLeft: `${(level + 1) * LEVEL_PADDING}px` }}
            className={classnames(
                itemClassName,
                {
                    [theme.item_collapsed]: expandStatus === ExpandStatus.CLOSED,
                },
                {
                    [theme.isFound]: isFound,
                },
                {
                    [theme.isSelectedFoundNode]: isSelectedFound,
                },
            )}
        >
            <TreeItem
                name={name}
                treeName={treeName}
                nodeId={nodeId}
                parentNodeId={parentNodeId}
                type={type}
                folderType={folderType}
                fileFolderType={fileFolderType}
                modelTypeId={modelTypeId}
                objectTypeId={objectTypeId}
                edgeTypeId={edgeTypeId}
                disableContextMenu={disableContextMenu}
                connectedServerId={connectedServerId}
                isDndEnabled={isDndEnabled}
                hasChildren={hasChildren}
                specialIcon={specialIcon}
                menuMessageValues={menuMessageValues}
                countChildren={countChildren}
                expandStatus={expandStatus}
                deleted={deleted}
                favorite={favorite}
                onDrop={onDropTreeItem}
                selectedNodes={selectedNodes}
            />
        </div>
    );
};
