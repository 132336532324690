import { MxCell, MxMouseEvent, MxSelectionCellsHandler } from '../mxgraph';
import { BPMMxGraph } from '../bpmgraph';

export class BPMMxSelectionCellsHandler extends MxSelectionCellsHandler {
    mouseDown(sender: BPMMxGraph, me: MxMouseEvent) {
        if (me.state && !sender.isMainCell(me.state.cell)) {
            const currentCells: MxCell[] = this.graph.getSelectionModel().cells;
            const currentSelectedCellId: string = currentCells && currentCells.length > 0 ? currentCells[0].id : '';

            if (currentSelectedCellId === '' || me.state.cell.id !== currentSelectedCellId) {
                const result = this.graph.view.getState(sender.getMainCell(me.state.cell.id));
                me.state = result;
            }
        }

        super.mouseDown(sender, me);
    }
}
