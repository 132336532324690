import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { put, select, takeEvery } from 'redux-saga/effects';
import { COPY_ITEM_LINK } from '../actionsTypes/modelLink.actionTypes';
import { TCopyLinkAction } from '../actions/copyLink.actions.types';
import { showNotificationByType } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { ExternalLinkBLLService } from '../services/bll/ExternalLinkBLLService';
import { TCopyContent } from '../modules/CopyDialog/CopyDialog.types';

/* function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        console.log(`Fallback: Copying text command was ${  msg}`);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
} */

function* copyLink({ payload: { nodeId, type, elementId } }: TCopyLinkAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const externalLink = ExternalLinkBLLService.createExternalLink(server.url, type, nodeId.repositoryId, nodeId.id, elementId);
    const copyContent: TCopyContent = ['link', externalLink];
    if (navigator.clipboard) {
        try {
            yield window.navigator.clipboard.writeText(externalLink);
            yield put(showNotificationByType(NotificationType.COPY_ITEM_LINK));
        } catch (e) {
            yield put(showNotificationByType(NotificationType.COPY_ITEM_LINK_ERROR));
            yield put(openDialog(DialogType.COPY_ITEM_DIALOG, {content: copyContent}));
        }
    } else {
        yield put(showNotificationByType(NotificationType.COPY_GUID_FAIL));
        yield put(openDialog(DialogType.COPY_ITEM_DIALOG, {content: copyContent}));
    }
}

export function* modelLinkSaga() {
    yield takeEvery(COPY_ITEM_LINK, copyLink);
}
