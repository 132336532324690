/* eslint-disable no-bitwise */
import { MxCell, MxUndoableEdit, MxConstants, MxUtils, MxEvent, MxGeometry, MxPoint } from '../mxgraph/mxgraph';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { ButtonEditLabelState } from '../models/buttonEditLabelState';
import { isUndefined } from 'is-what';
import { availableAlignments } from '../sagas/editor.saga.constants';
import { EdgeType, DiagramElement, DiagramElementTypeEnum } from '../serverapi/api';
import { TEdgeTypeSelectorState, initialEdgeTypeSelectorState } from '../models/edgeTypeSelectorState.types';
import { EdgeInstanceImpl } from '../models/bpm/bpm-model-impl';
import { EntityEnum, TSelectedElement } from '../models/navigatorPropertiesSelectorState.types';
import { NotationHelper } from '../services/utils/NotationHelper';
import { BPMMxGraphModel } from '../mxgraph/BPMMxGraphModel.class';
import { BPMMxConstants } from '../mxgraph/bpmgraph.constants';
import { EditorMode } from '../models/editorMode';
import { getShapeId, getShapeType } from './css.utils';
import { Alignment } from '../models/alignment';
import uniqBy from 'lodash/uniqBy';
import { getCopyableCells } from '@/services/bll/BpmMxEditorBLLService';
import { SymbolType } from '@/models/Symbols';

export enum CellTypes {
    Object = 'object',
    Edge = 'edge',
    Shape = 'shape',
    Label = 'label',
}

export const isIgnoredUndoableEdit = (edit: MxUndoableEdit): boolean => {
    let ignoreEdit = false;
    for (let i = 0; i < edit.changes.length; i++) {
        if (
            edit.changes[i].cell !== undefined &&
            MxUtils.indexOfStylename(edit.changes[i].cell.getStyle(), 'undoable=0') !== -1
        ) {
            ignoreEdit = true;
        }
        if (
            edit.changes[i].child !== undefined &&
            MxUtils.indexOfStylename(edit.changes[i].child.getStyle(), 'undoable=0') !== -1
        ) {
            ignoreEdit = true;
        }
    }

    if (edit.changes.length === 0) {
        ignoreEdit = true;
    }

    return ignoreEdit;
};

export const isLabelChange = (edit: MxUndoableEdit) => {
    const diffList = edit.changes;
    const diff = diffList[0];

    return diffList.length === 1 && diff.value === diff.previous;
};

export const hasForbiddenSymbols = (cellValue: any, sourceCells: MxCell[]) =>
    sourceCells.some((el) => cellValue.psdCellMetaInfo?.allowedSymbols.indexOf(el.getValue().symbolId) === -1);

export const setIgnoredEdit = (graph: BPMMxGraph, cell: MxCell): void => {
    graph.setCellStyles(BPMMxConstants.STYLE_UNDOABLE, '0', [cell]);
};

export const handleSelectedEvent = (arrayCell: MxCell[], graph: BPMMxGraph): ButtonEditLabelState => {
    // on selection group of cells we setup active buttons in general menu according rules bellow:
    // for FONT_STYLE: we search for all applied styles and select all appropriate buttons in general menu (BUI)
    // on press on buttons we will toggle styles
    // for STYLE_ALIGN: we search for all applied styles if we found just one style, we will select appropriate button
    // in general menu, in other case will select - nothing, on press to align buttons will
    // apply one style for all elements
    const result = new ButtonEditLabelState();
    let countAlignStyle: number = 0;
    let countFontSize: number = 0;
    let countFontFamily: number = 0;
    let lastAlignStyle: string;
    let lastFontSize: string = '';
    let lastFontFamily: string = '';
    const COUNT_POSSIBLE_DIFFERENT_STYLES: number = 1;
    if (arrayCell instanceof Array) {
        if (arrayCell.length > 0) {
            arrayCell.forEach((cell: MxCell) => {
                const currentStyles: any[] = graph.getCellStyle(cell); // tslint:disable-line:no-any
                let currentFontStyle: number = currentStyles[MxConstants.STYLE_FONTSTYLE];
                const currentAlignStyle: string = currentStyles[MxConstants.STYLE_ALIGN];
                let currentFontSize: string = currentStyles[MxConstants.STYLE_FONTSIZE];
                let currentFontFontFamily: string = currentStyles[MxConstants.STYLE_FONTFAMILY];
                if (isUndefined(currentFontStyle)) {
                    currentFontStyle = 0;
                }
                if (isUndefined(currentFontSize)) {
                    currentFontSize = MxConstants.DEFAULT_FONTSIZE;
                }
                if (isUndefined(currentFontFontFamily)) {
                    [currentFontFontFamily] = MxConstants.DEFAULT_FONTFAMILY.split(',');
                }
                /* tslint:disable:no-bitwise */
                result.isFontBoldSelected =
                    (currentFontStyle & MxConstants.FONT_BOLD) === 0 ? result.isFontBoldSelected : true;
                result.isFontItalicSelected =
                    (currentFontStyle & MxConstants.FONT_ITALIC) === 0 ? result.isFontItalicSelected : true;
                result.isFontUnderlineSelected =
                    (currentFontStyle & MxConstants.FONT_UNDERLINE) === 0 ? result.isFontUnderlineSelected : true;
                /* tslint:enable:no-bitwise */
                if (currentFontSize !== lastFontSize) {
                    countFontSize++;
                    lastFontSize = currentFontSize;
                }

                if (currentAlignStyle !== lastAlignStyle) {
                    countAlignStyle++;
                    lastAlignStyle = currentAlignStyle;
                }

                if (currentFontFontFamily !== lastFontFamily) {
                    countFontFamily++;
                    lastFontFamily = currentFontFontFamily;
                }
            });
            if (countAlignStyle === COUNT_POSSIBLE_DIFFERENT_STYLES) {
                const keyAlignment = Object.keys(availableAlignments).filter((key: string) => {
                    return availableAlignments[key] === lastAlignStyle;
                })[0];
                result.alignment = parseInt(keyAlignment, 10);
            } else {
                result.alignment = Alignment.CenterHorz;
            }
            if (countFontSize === COUNT_POSSIBLE_DIFFERENT_STYLES) {
                result.fontSize = lastFontSize;
            } else {
                result.fontSize = '';
            }
            if (countFontFamily === COUNT_POSSIBLE_DIFFERENT_STYLES) {
                result.fontFamily = lastFontFamily;
            } else {
                result.fontFamily = '';
            }
        }
    }

    return result;
};

export const getAvailableEdgeTypesForEvent = (cells: MxCell[], graph: BPMMxGraph): TEdgeTypeSelectorState => {
    // TODO: implement "smart" type switching
    const { modelType, id } = graph;
    const selectedEdge = cells.find((c) => c.edge);
    const result = { ...initialEdgeTypeSelectorState };

    if (selectedEdge && selectedEdge.source && selectedEdge.target && modelType) {
        result.availableTypes = <EdgeType[]>(
            NotationHelper.getEdgeTypeBySourceAndDestination(
                selectedEdge.source.value,
                selectedEdge.target.value,
                id.serverId,
                modelType,
            )
        );
        const edgeValue = selectedEdge.getValue();
        if (edgeValue instanceof EdgeInstanceImpl) {
            const { edgeTypeId } = edgeValue;
            result.currentTypeIndex = result.availableTypes.filter((t) => t).findIndex((t) => t.id === edgeTypeId);
        }
    }

    return result;
};

export const graphContainsEvent = (evt: PointerEvent, graph: BPMMxGraph) => {
    if (!graph.container) return false;

    const x = MxEvent.getClientX(evt);
    const y = MxEvent.getClientY(evt);
    const offset = MxUtils.getOffset(graph.container);
    const origin = MxUtils.getScrollOrigin();

    // Checks if event is inside the bounds of the graph container
    return (
        x >= offset.x - origin.x &&
        y >= offset.y - origin.y &&
        x <= offset.x - origin.x + graph.container.offsetWidth &&
        y <= offset.y - origin.y + graph.container.offsetHeight
    );
};

export const applyFontStyle = (arrayCell: MxCell[], graph: BPMMxGraph, fontStyle: number, apply: boolean) => {
    graph.getModel().beginUpdate();
    try {
        if (graph.cellEditor.isContentEditing()) {
            switch (fontStyle) {
                case MxConstants.FONT_BOLD:
                    document.execCommand('bold', false);
                    break;
                case MxConstants.FONT_ITALIC:
                    document.execCommand('italic', false);
                    break;
                case MxConstants.FONT_UNDERLINE:
                    document.execCommand('underline', false);
                    break;
                default:
                    break;
            }
        }
        arrayCell.forEach((cell: MxCell) => {
            const currentStyles: any[] = graph.getCellStyle(cell);
            let currentFontStyle: number = currentStyles[MxConstants.STYLE_FONTSTYLE];
            if (isUndefined(currentFontStyle)) {
                currentFontStyle = 0;
            }
            let calculatedFontStyle;
            if (apply) {
                calculatedFontStyle = currentFontStyle | fontStyle;
            } else {
                calculatedFontStyle = currentFontStyle & ~fontStyle;
            }
            graph.setCellStyles(MxConstants.STYLE_FONTSTYLE, calculatedFontStyle.toString(), [cell]);
        });
    } finally {
        graph.getModel().endUpdate();
    }
};

const getJumpedNeighborIndex = (arrayCell: MxCell[], currentIndex: number, ascending: boolean) => {
    const currentCell = arrayCell[currentIndex];
    const currentCellId = currentCell.value.type === CellTypes.Label ? currentCell.value.mainCellId : currentCell.id;
    const nextCell = ascending ? arrayCell[currentIndex + 1] : arrayCell[currentIndex - 1];
    if (!nextCell) {
        return null;
    }
    const nextCellId = nextCell.value.type === CellTypes.Label ? nextCell.value.mainCellId : nextCell.id;
    if (ascending) {
        return currentCellId === nextCellId ? currentIndex + 2 : currentIndex + 1;
    }

    return currentCellId === nextCellId ? currentIndex - 2 : currentIndex - 1;
};

export const changeCellsLayerIndex = (arrayCell: MxCell[], graph: BPMMxGraph, ascending: boolean) => {
    graph.getModel().beginUpdate();
    try {
        const model = graph.getModel();
        for (let i = 0; i < arrayCell.length; i++) {
            const cell = arrayCell[i];
            const parent = model.getParent(cell);
            const currentIndex = parent.getIndex(cell);
            let calculatedIndex;
            const jumpedNeighborIndex = getJumpedNeighborIndex(parent.children, currentIndex, ascending);
            if (!jumpedNeighborIndex) {
                return;
            }
            const jumpedNeighbor = parent.children[jumpedNeighborIndex];
            if (!jumpedNeighbor) {
                return;
            }
            const step =
                jumpedNeighbor.value.type === CellTypes.Shape || jumpedNeighbor.value.type === CellTypes.Edge ? 1 : 2;
            if (ascending) {
                calculatedIndex = currentIndex + step + i;
                model.add(parent, cell, calculatedIndex);
                model.add(parent, graph.getLabelCell(cell.id), calculatedIndex + 1);
            } else {
                calculatedIndex = currentIndex - step - i;
                calculatedIndex = calculatedIndex < 0 ? 0 : calculatedIndex;
                model.add(parent, cell, calculatedIndex);
                model.add(parent, graph.getLabelCell(cell.id), calculatedIndex + 1);
            }
        }
    } finally {
        graph.getModel().endUpdate();
    }
};

type TSimpleDiagramElementWithLabels = {
    id: string;
    type: string;
};

const transformToSimpleDiagramElementWithLabels = (dElements: DiagramElement[]): TSimpleDiagramElementWithLabels[] => {
    // в массиве DiagramElement у объектов нет лейблов, поэтому нужно их добавить чтобы индексы совпадали с model.cells
    const simpleElements: TSimpleDiagramElementWithLabels[] = [];
    dElements.forEach(({ id, type }) => {
        simpleElements.push({ id, type });
        if (type === CellTypes.Object) {
            simpleElements.push({ id, type: CellTypes.Label });
        }
    });

    return simpleElements;
};

export const changeEdgeLayerIndex = (graph: BPMMxGraph, dElements: DiagramElement[]) => {
    const simpleElements = transformToSimpleDiagramElementWithLabels(dElements);
    const model = graph.getModel();
    const arrayCell = Object.values(model.cells).filter((cell) => cell.value);
    arrayCell.forEach((cell) => {
        if (cell.value.type === CellTypes.Edge) {
            const elIndex = simpleElements.findIndex((el) => el.id === cell.id && el.type === cell.value.type);
            const parent = model.getParent(cell);
            model.add(parent, cell, elIndex);
        }
    });
};

export const getCellEntityType = (cell?: MxCell): EntityEnum | undefined => {
    if (!cell) return undefined;

    const cellType: DiagramElementTypeEnum = cell.getValue()?.type;
    switch (cellType) {
        case CellTypes.Object:
            return EntityEnum.OBJECT;
        case CellTypes.Edge:
            return EntityEnum.EDGE;
        default:
            return undefined;
    }
};

export const getLastSelectionObjectDefinitionForEvent = (
    cells: MxCell[] | null,
    graph: BPMMxGraph,
): TSelectedElement => {
    const lastCell: MxCell | undefined = cells?.at(-1);
    const cellType: string | undefined = lastCell?.getValue()?.type;

    if (!lastCell || cellType === 'layout') {
        return {};
    }

    const cell = cellType === CellTypes.Label ? graph.model.getCell(lastCell.getValue()?.mainCellId) : lastCell;

    return {
        cellId: cell.id,
    };
};

export const calculateRelativeCoordinatesForFoldedElement = (
    newParent: MxCell,
    previousParent: MxCell,
    graph: BPMMxGraph,
    point: MxPoint,
) => {
    const parentState = graph.view.getState(newParent);
    const oldState = graph.view.getState(previousParent);
    const o1 = parentState.origin;
    const o2 = oldState.origin;
    const dx = o2.x - o1.x;
    const dy = o2.y - o1.y;
    const geo = new MxGeometry(point.x, point.y);
    geo.translate(dx, dy);
    point.x = Math.max(0, geo.x);
    point.y = Math.max(0, geo.y);

    return point;
};

export const copySizesToCell = (targetCell: MxCell | undefined, sourceCell: MxCell | undefined, graph: BPMMxGraph) => {
    // если кликнули на пустое место на холсте то targetCell не будет
    if (!targetCell?.value || !sourceCell?.value) {
        return;
    }
    const availableTypes = [CellTypes.Object, CellTypes.Label, CellTypes.Shape];
    const sourceType = sourceCell.value.type;
    const targetType = targetCell.value.type;

    if (!availableTypes.includes(targetType) || !availableTypes.includes(sourceType)) {
        return;
    }

    if (targetType !== sourceType || targetCell.complexSymbolTypeId) {
        return;
    }

    const model = graph.getModel();
    const sourceGeo = model.getGeometry(sourceCell);
    const targetGeo = model.getGeometry(targetCell);
    targetGeo.height = sourceGeo.height;
    targetGeo.width = sourceGeo.width;

    model.setGeometry(targetCell, targetGeo);
};

export const getRelativeLabelPos = (cell: MxCell, label: MxCell, model: BPMMxGraphModel) => {
    const labelGeo = model.getGeometry(label);
    const cellGeo = model.getGeometry(cell);

    return {
        x: labelGeo.x - cellGeo.x,
        y: labelGeo.y - cellGeo.y,
    };
};

export const copyPositionToLabel = (
    targetCell: MxCell | undefined,
    targetLabel: MxCell | undefined,
    sourceCell: MxCell | undefined,
    sourceLabel: MxCell | undefined,
    graph: BPMMxGraph,
) => {
    // если кликнули на пустое место на холсте то targetCell не будет
    if (!targetCell?.value || !targetLabel || !sourceCell?.value || !sourceLabel) {
        return;
    }

    if (targetCell.value.type !== CellTypes.Object || sourceCell.value.type !== CellTypes.Object) {
        return;
    }

    const model = graph.getModel();
    const sourceLabelRelativePos = getRelativeLabelPos(sourceCell, sourceLabel, model);
    const targetLabelGeo = model.getGeometry(targetLabel);
    const targetCellGeo = model.getGeometry(targetCell);

    targetLabelGeo.x = targetCellGeo.x + sourceLabelRelativePos.x;
    targetLabelGeo.y = targetCellGeo.y + sourceLabelRelativePos.y;

    model.setGeometry(targetLabel, targetLabelGeo);
};

export const copyStylesToCell = (targetCell: MxCell | undefined, sourceCell: MxCell | undefined) => {
    if (!targetCell?.value || !sourceCell?.value) {
        return;
    }

    const availableTypes = [CellTypes.Object, CellTypes.Label, CellTypes.Shape];
    const sourceType = sourceCell.value.type;
    const targetType = targetCell.value.type;
    const sourceStyle = sourceCell.getStyle();
    const targetStyle = targetCell.getStyle();
    const targetShapeType = getShapeType(targetStyle);
    const targetShapeId = getShapeId(targetStyle);
    const sourceShapeId = getShapeId(sourceStyle);

    let sourceStyleWithTargetSymbol: string = '';

    if (!availableTypes.includes(targetType) || !availableTypes.includes(sourceType)) {
        return;
    }

    if (targetType !== sourceType || targetCell.complexSymbolTypeId) {
        return;
    }

    if (targetType === CellTypes.Object || targetType === CellTypes.Label) {
        sourceStyleWithTargetSymbol = sourceStyle.replace(sourceCell.value.symbolId, targetCell.value.symbolId);
    } else if (targetShapeType === CellTypes.Shape) {
        if (!targetShapeId.includes('shape=image')) {
            sourceStyleWithTargetSymbol = sourceStyle
                .replace(sourceShapeId, targetShapeId)
                .replace('strokeColor=none', '');
        } else {
            return;
        }
    } else if (targetShapeType === 'text') {
        if (!sourceStyle.includes(';strokeColor=none')) {
            sourceStyleWithTargetSymbol = sourceStyle.replace(sourceShapeId, ';strokeColor=none');
        } else {
            sourceStyleWithTargetSymbol = sourceStyle;
        }
    }
    targetCell.setStyle(sourceStyleWithTargetSymbol);
};

export const isGraphOnEditMode = (graph: BPMMxGraph): boolean => {
    return graph.mode === EditorMode.Edit;
};

export const unwindCompositeCells = (graph: BPMMxGraph, cells: MxCell[]): MxCell[] => {
    const result = [...cells];
    const compositeCells = result.filter((cell) => graph.isPartOfCompositeCell(cell));

    compositeCells.forEach((cell) => {
        if (graph.isMainCell(cell)) result.push(graph.getLabelCell(cell.id));
        if (graph.isLabelCell(cell)) result.unshift(graph.getMainCell(cell.id));
    });

    return uniqBy(result.filter(Boolean), 'id');
};

export function checkIfElementsAreRestrictedForCut(graph: BPMMxGraph): boolean {
    const selectedCells = graph.getSelectionCells();
    const anyMarkerSelected: boolean = selectedCells.some((cell) => cell.value.type === 'CommentMarker');
    const copyableCells = getCopyableCells(graph, selectedCells);

    if (anyMarkerSelected || copyableCells.length === 0) {
        return true;
    }

    return false;
}

export class CancelablePromise {
    private controller = new AbortController();

    public cancel() {
        this.controller.abort();
    }

    public get(cb) {
        new Promise<void>((resolve, reject) => {
            const listener = () => {
                this.controller.signal.removeEventListener('abort', listener);

                return reject();
            };
            this.controller.signal.addEventListener('abort', listener);
            cb && cb();

            return resolve();
        });
    }
}

export const pointToPercent = (point: MxPoint, target?: MxCell) => {
    if (!target) return point;

    const trgGeo: MxGeometry = target.getGeometry();

    return new MxPoint(point.x / trgGeo.width, point.y / trgGeo.height);
};

export const percentToPoint = (percentPoint: MxPoint, target?: MxCell) => {
    if (!target) return percentPoint;

    const trgGeo: MxGeometry = target.getGeometry();

    return new MxPoint(percentPoint.x * trgGeo.width, percentPoint.y * trgGeo.height);
};

export const isCommentCell = (cell: MxCell): boolean => {
    return cell?.value?.type === SymbolType.COMMENT;
};
