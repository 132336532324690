import { TOpenNodeRequestPayload } from '../../actions/openNode.actions.types';
import { TServerEntity } from '../../models/entities.types';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { prepareTreeItemData } from '../../modules/UIKit/components/EditableText/editableText.utils';
import { NodeId } from '../../serverapi/api';
import { HASH_LINK } from '../../utils/consts';
import { TNodeIdWithType } from './ExternalLinkBLLService.types';

export class ExternalLinkBLLService {
    public static createExternalLink(
        serverUrl: string,
        objectType: TreeItemType,
        repositoryId: string,
        objectId: string,
        elementId?: string,
    ) {
        if (serverUrl.includes('http://') || serverUrl.includes('https://')) {
            return `${serverUrl}${HASH_LINK}${objectType.toLowerCase()}/${repositoryId}/${objectId}${
                elementId ? `/${elementId}` : ''
            }`;
        }

        return `${location.protocol}//${serverUrl}${HASH_LINK}${objectType.toLowerCase()}/${repositoryId}/${objectId}${
            elementId ? `/${elementId}` : ''
        }`;
    }

    public static parseExternalLink(url: string, serverId: string): TNodeIdWithType {
        const nodeIdWithType: TNodeIdWithType = {
            nodeId: {
                id: '',
                repositoryId: '',
                serverId: '',
            },
            elementId: '',
            type: TreeItemType.Default,
        };
        try {
            const parsedHash: string[] = url.split('#')[1].split('/');
            if (
                parsedHash[0] === 'link' &&
                Object.values(TreeItemType).includes(parsedHash[1].toUpperCase() as TreeItemType)
            ) {
                nodeIdWithType.nodeId.id = parsedHash[3];
                nodeIdWithType.nodeId.repositoryId = parsedHash[2];
                nodeIdWithType.nodeId.serverId = serverId;
                nodeIdWithType.type = parsedHash[1].toUpperCase() as TreeItemType;
                nodeIdWithType.elementId = parsedHash[4] || '';
            }

            return nodeIdWithType;
        } catch (e) {
            return nodeIdWithType;
        }
    }

    public static isShortUri(uri: string) {
        return !uri.includes('://'); 
    }

    public static getNodePayloadFromUri(uri: string, existServer?: TServerEntity): TOpenNodeRequestPayload | undefined {
        if (ExternalLinkBLLService.isShortUri(uri)) {
            return undefined;
        }
        const includedHashLink = uri.includes(HASH_LINK);
        const includedBpmLink = uri.trim().startsWith('bpm://');
        const url = new URL(uri);
        const serverUrl = new URL(existServer?.url || '');

        if (includedBpmLink || (includedHashLink && url.host === serverUrl.host)) {
            if (existServer) {
                let type: TreeItemType = TreeItemType.Default;
                const elementIds: Array<string | undefined> = [];
                const nodeId: NodeId = {
                    id: '',
                    repositoryId: '',
                    serverId: existServer.id,
                };
                if (includedBpmLink) {
                    const parsedUri = prepareTreeItemData(uri);
                    nodeId.id = parsedUri.id;
                    nodeId.repositoryId = parsedUri.repositoryId;
                    type = parsedUri.type;
                } else {
                    const parsedUri = this.parseExternalLink(uri, existServer.id);
                    nodeId.id = parsedUri.nodeId.id;
                    nodeId.repositoryId = parsedUri.nodeId.repositoryId;
                    type = parsedUri.type;
                    elementIds.push(parsedUri.elementId);
                }
                if (nodeId.id) {
                    return { nodeId, type, elementIds };
                }
            }
        }

        return undefined;
    }
}
