import type { TreeNode } from '../../../../../../../models/tree.types';
import type {
    AttributeType,
    EdgeType,
    ModelEdgeDefinition,
    ModelType,
    Symbol,
} from '../../../../../../../serverapi/api';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { DefaultId } from '../../../../../../../serverapi/api';
import { useIntl } from 'react-intl';
import { ModelAddEdgeDialog } from '../Dialogs/ModelAddEdgeDialog.component';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import { DeleteSelected, TabHeader } from '../../Header/TabHeader.component';
import messages from '../ModelType.messages';
import edgeTypeDirectionMessages from '../../../../../../../models/edge-type-direction.messages';
import { Alert, Checkbox } from 'antd';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { SymbolsService } from '../../../../../../../services/SymbolsService';
import { SymbolConstants } from '../../../../../../../models/Symbols';
import { DeleteButton, EditButton, RowButtons, SettingsButton } from '../../../../../Button/RowButtons';
import {
    addModelTypeEdges,
    removeModelTypeEdges,
} from '../../../../../../../actions/workspaceTab/editModelTypeWorkspaceTab.actions';
import { showNotificationByType } from '../../../../../../../actions/notification.actions';
import { NotificationType } from '../../../../../../../models/notificationType';
import { editModelTypeEdgeAttributesStyles } from '../../../../../../../actions/symbol/symbolEditor.actions';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsDoubleEdgeDefinition, getEdgeAvailableAttributeTypes } from '../utils/modelTypes.utils';
import { contains } from '../../util/preset.utils';
import { ModelEditEdgeDialog } from '../Dialogs/ModelEditEdgeDialog.component';
import theme from './EdgesTab.scss';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { isEqual } from 'lodash';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table, TableCellProps } from 'react-virtualized';

type TEdgesTabProps = {
    availableAttributeTypes: AttributeType[];
    modelType: ModelType;
    availableSymbols: Symbol[];
    availableEdgeType: EdgeType[];
    modelTypeEdges: ModelEdgeDefinition[];
    serverId: string;
    serverNode: TreeNode;
};

type TModelEdgeWithTableData = ModelEdgeDefinition & {
    sourceImage: React.ReactNode;
    destinationImage: React.ReactNode;
    edgeImage: React.ReactNode;
    edgeTypeName: string;
    waypoint: string;
    checked: boolean;
};

const EdgesTab: FC<TEdgesTabProps> = (props) => {
    const {
        availableAttributeTypes,
        availableEdgeType,
        modelTypeEdges,
        availableSymbols,
        modelType,
        serverId,
        serverNode,
    } = props;
    const { presetId } = modelType;
    const modelTypeId = modelType.id;
    const cellHeightCache: CellMeasurerCache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 60,
    });

    const intl = useIntl();
    const dispatch = useDispatch();

    const [addEdgeDialogVisible, setAddEdgeDialogVisible] = useState<boolean>(false);
    const [editEdgeDialogVisible, setEditEdgeDialogVisible] = useState<boolean>(false);
    const [selectedRowKeysState, setSelectedRowKeysState] = useState<string[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [editingEdgeDefinition, setEditingEdgeDefinition] = useState<ModelEdgeDefinition | undefined>();
    const [graph, setGraph] = useState<MethodologiesGraph | undefined>();
    const [dataSource, setDataSource] = useState<TModelEdgeWithTableData[]>([]);
    const [edgeTypes, setEdgeTypes] = useState<EdgeType[]>([]);

    const currentLocale = useSelector(getCurrentLocale);

    const edgesForDelete: string | undefined = dataSource
        ?.filter((edge: TModelEdgeWithTableData) => selectedRowKeysState.some((key) => key === edge.id))
        ?.map((edge: TModelEdgeWithTableData) => `"${edge.edgeTypeName}"`)
        .join(', ');
    const deleteEdgesMessage = selectedRowKeysState.length ? (
        <Alert
            message={
                <>
                    <b>{`${intl.formatMessage(messages.deleteEdges)}: `}</b>
                    {edgesForDelete}
                </>
            }
        />
    ) : (
        ''
    );

    useEffect(() => {
        if (!isEqual(availableEdgeType, edgeTypes)) {
            setEdgeTypes(
                availableEdgeType.map((et) => {
                    return {
                        ...et,
                        name: LocalesService.internationalStringToString(et.multilingualName, currentLocale),
                    };
                }),
            );
        }
    }, [availableEdgeType]);

    useEffect(() => {
        const modelEdges = modelTypeEdges.filter((type) => {
            const edgeType = edgeTypes.find((e) => e.id === type.edgeType);

            return contains([edgeType?.name || '', edgeType?.direction || ''], searchFilter);
        });
        const modelEdgesImages = modelEdges.map((edge) => {
            const { sourceImage, destinationImage, edgeImage } = getImagesFromEdge(edge);
            const edgeType = getEdgeTypeById(edge.edgeType);
            const edgeTypeName = LocalesService.internationalStringToString(edgeType.multilingualName, currentLocale);
            let waypoint = '';
            if (edgeTypeDirectionMessages[edgeType.direction])
                waypoint = intl.formatMessage(edgeTypeDirectionMessages[edgeType.direction]);

            return {
                ...edge,
                sourceImage,
                destinationImage,
                edgeImage,
                edgeTypeName,
                waypoint,
                checked: false,
            };
        });
        if (!isEqual(modelEdgesImages, dataSource)) setDataSource(modelEdgesImages);
    }, [modelTypeEdges, edgeTypes, searchFilter]);

    useEffect(() => {
        return graph?.destroy();
    }, []);

    const onDeleteEdges = useCallback((edgeDefs: ModelEdgeDefinition[]) => {
        dispatch(removeModelTypeEdges({ serverId, presetId, modelTypeId, modelEdgeDefinitions: edgeDefs }));
    }, []);

    const onEditEdge = useCallback(
        (editableEdgeDefinition: ModelEdgeDefinition) => {
            setEditEdgeDialogVisible(false);
            const newModelTypeEdges: ModelEdgeDefinition[] = modelType.modelEdgeDefinitions.map((modelTypeEdge) =>
                modelTypeEdge.id === editableEdgeDefinition.id ? editableEdgeDefinition : modelTypeEdge,
            );

            dispatch(addModelTypeEdges({ serverId, presetId, modelTypeId, modelEdgeDefinitions: newModelTypeEdges }));
        },
        [modelType.modelEdgeDefinitions],
    );

    const onAddEdges = useCallback(
        (edgeDefinitions: ModelEdgeDefinition[]) => {
            const filterEdgeDefinitions: ModelEdgeDefinition[] = edgeDefinitions.filter(
                (edgeDefinition: ModelEdgeDefinition) => {
                    const isDouble: boolean = checkIsDoubleEdgeDefinition(
                        modelType.modelEdgeDefinitions,
                        edgeDefinition,
                    );

                    if (isDouble) {
                        dispatch(showNotificationByType(NotificationType.REPEAT_EDGE_DEFINITION));

                        return false;
                    }

                    return true;
                },
            );

            if (filterEdgeDefinitions.length) {
                const newModelTypeEdges = [...modelType.modelEdgeDefinitions, ...filterEdgeDefinitions];

                dispatch(
                    addModelTypeEdges({ serverId, presetId, modelTypeId, modelEdgeDefinitions: newModelTypeEdges }),
                );
            }
        },
        [modelType],
    );

    const onOpenAttributeSettings = useCallback(
        (edge: ModelEdgeDefinition) => {
            const edgeType: EdgeType | undefined = modelType.edgeTypes.find((et) => et.id === edge.edgeType);

            if (edgeType) {
                const { definitionAttributes, instanceAttributes } = getEdgeAvailableAttributeTypes(
                    availableAttributeTypes,
                    edgeType,
                );

                dispatch(
                    editModelTypeEdgeAttributesStyles({
                        definitionAttributes,
                        instanceAttributes,
                        serverNode,
                        modelType,
                        edgeType,
                    }),
                );
            } else {
                dispatch(showNotificationByType(NotificationType.NONE_EXISTING_EDGE_DEFINITION));
            }
        },
        [modelType, availableAttributeTypes],
    );

    const converterInitialized = useCallback(
        (initialGraph: MethodologiesGraph) => {
            new SymbolsService().applyStylesToGraph(initialGraph, [
                {
                    ...SymbolConstants.ENDPOINT_SYMBOL,
                    name: DefaultId.DEFAULT_PRESET_ID,
                },
                SymbolConstants.ANY_ELEMENT,
            ]);

            if (!graph) {
                setGraph(initialGraph);
            }
        },
        [graph],
    );

    const getEdgeTypeById = (edgeTypeId: string) => {
        return availableEdgeType.reduce((acc, e: EdgeType) => {
            return e.id === edgeTypeId ? e : acc;
        }, {} as EdgeType);
    };

    const getImagesFromEdge = (modelEdge: ModelEdgeDefinition) => {
        const source =
            (availableSymbols || []).find((s) => s.id === modelEdge.source) ||
            ({
                ...(modelType.objectTypes.reduce((acc, ot) => (ot.id === modelEdge.sourceObject ? ot : acc), {}) || {
                    id: modelEdge.sourceObject,
                    name: modelEdge.sourceObject,
                }),
                ...(modelEdge.anySourceAllowed ? SymbolConstants.ANY_ELEMENT : SymbolConstants.ENDPOINT_SYMBOL),
            } as Symbol);

        const destination =
            (availableSymbols || []).find((s) => s.id === modelEdge.destination) ||
            ({
                ...(modelType.objectTypes.reduce(
                    (acc, ot) => (ot.id === modelEdge.destinationObject ? ot : acc),
                    {},
                ) || {
                    id: modelEdge.destinationObject,
                    name: modelEdge.destinationObject,
                }),
                ...(modelEdge.anyTargetAllowed ? SymbolConstants.ANY_ELEMENT : SymbolConstants.ENDPOINT_SYMBOL),
            } as Symbol);
        const edgeType = getEdgeTypeById(modelEdge.edgeType);

        return {
            sourceImage: SymbolToImageConverterGraph.convertSymbol(source, intl, graph),
            destinationImage: SymbolToImageConverterGraph.convertSymbol(destination, intl, graph),
            edgeImage: SymbolToImageConverterGraph.convertEdge(edgeType, intl, graph),
        };
    };

    const onChangeHeaderCheckbox = (data) => {
        data.checked ? setSelectedRowKeysState(dataSource.map((row) => row.id)) : setSelectedRowKeysState([]);
    };

    const onChangeRowCheckbox = (index: number) => {
        const newRow = dataSource[index].id;
        selectedRowKeysState.includes(newRow)
            ? setSelectedRowKeysState((old) => old.filter((row) => row !== newRow))
            : setSelectedRowKeysState((old) => [...old, newRow]);
    };

    const checkboxCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer columnIndex={0} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={theme.measurerCell}>
                    <Checkbox
                        checked={selectedRowKeysState.includes(dataSource[rowIndex].id) === true}
                        onChange={(e) => onChangeRowCheckbox(rowIndex)}
                    />
                </div>
            </CellMeasurer>
        );
    };

    const checkboxHeaderRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer columnIndex={0} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={theme.measurerCell}>
                    <Checkbox
                        indeterminate={
                            selectedRowKeysState.length > 0 && selectedRowKeysState.length < dataSource.length
                        }
                        checked={selectedRowKeysState.length === dataSource.length}
                        onChange={(e) => onChangeHeaderCheckbox(e.target)}
                    />
                </div>
            </CellMeasurer>
        );
    };

    const sourceImageCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer columnIndex={1} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={theme.measurerCell}>{dataSource[rowIndex].sourceImage}</div>
            </CellMeasurer>
        );
    };

    const edgeImageCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer columnIndex={2} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={theme.measurerCell}>
                    <div>{dataSource[rowIndex].edgeTypeName}</div>
                    {dataSource[rowIndex].edgeImage}
                </div>
            </CellMeasurer>
        );
    };

    const destinationImageCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer columnIndex={3} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={theme.measurerCell}>{dataSource[rowIndex].destinationImage}</div>
            </CellMeasurer>
        );
    };

    const waypointCellRenderer = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        return (
            <CellMeasurer columnIndex={4} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={theme.measurerCell}>{dataSource[rowIndex].waypoint}</div>
            </CellMeasurer>
        );
    };

    const renderCellActionButtons = ({ dataKey, parent, rowIndex }: TableCellProps) => {
        const edgeName = dataSource[rowIndex].edgeTypeName;

        return (
            <CellMeasurer columnIndex={4} cache={cellHeightCache} key={dataKey} parent={parent} rowIndex={rowIndex}>
                <div className={theme.measurerCell}>
                    <RowButtons
                        buttons={[
                            EditButton.build(() => {
                                setEditEdgeDialogVisible(true);
                                setEditingEdgeDefinition(dataSource[rowIndex]);
                            }),
                            SettingsButton.build(() => onOpenAttributeSettings(dataSource[rowIndex])),
                            DeleteButton.build(
                                () => onDeleteEdges([dataSource[rowIndex]]),
                                undefined,
                                undefined,
                                undefined,
                                intl.formatMessage(messages.deleteEdgeMessage, { edgeName }),
                                '',
                            ),
                        ]}
                    />
                </div>
            </CellMeasurer>
        );
    };

    return (
        <div className={theme.container}>
            {addEdgeDialogVisible && (
                <ModelAddEdgeDialog
                    edgeDefinition={editingEdgeDefinition}
                    availableSymbols={availableSymbols}
                    availableEdgeType={edgeTypes}
                    modelType={modelType}
                    graph={graph}
                    onSave={onAddEdges}
                    onCancel={() => setAddEdgeDialogVisible(false)}
                />
            )}
            {editEdgeDialogVisible && (
                <ModelEditEdgeDialog
                    modelType={modelType}
                    edgeDefinition={editingEdgeDefinition}
                    availableSymbols={availableSymbols}
                    availableEdgeType={edgeTypes}
                    graph={graph}
                    onSave={onEditEdge}
                    onCancel={() => setEditEdgeDialogVisible(false)}
                />
            )}
            <SymbolToImageConverterGraph modelType={modelType} initialized={converterInitialized} />
            <TabHeader
                buttons={[
                    {
                        name: messages.newEdge,
                        disabled: !availableSymbols.length,
                        onAction: () => {
                            setAddEdgeDialogVisible(true);
                            setEditingEdgeDefinition(undefined);
                        },
                    },
                    DeleteSelected.build(
                        () => {
                            onDeleteEdges(
                                modelTypeEdges.filter((edge: ModelEdgeDefinition) =>
                                    selectedRowKeysState.some((key) => key === edge.id),
                                ),
                            );
                            setSelectedRowKeysState([]);
                        },
                        !selectedRowKeysState.length,
                        undefined,
                        intl.formatMessage(messages.deleteEdgesDialogTitle),
                        deleteEdgesMessage,
                    ),
                ]}
                onSearchChange={(searchFilterValue: string) => setSearchFilter(searchFilterValue)}
            />
            <div className={theme.tableContainer}>
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            rowClassName={theme.tableRow}
                            headerHeight={35}
                            width={width}
                            height={height}
                            headerClassName={theme.headerRowStyle}
                            rowHeight={cellHeightCache.rowHeight}
                            rowCount={dataSource.length}
                            rowGetter={({ index }) => dataSource[index]}
                            className={theme.table}
                            onRowClick={({ index }) => onChangeRowCheckbox(index)}
                        >
                            <Column
                                disableSort
                                headerStyle={{ border: 'none' }}
                                width={20}
                                minWidth={17}
                                dataKey="checkbox"
                                headerRenderer={checkboxHeaderRenderer}
                                cellRenderer={checkboxCellRenderer}
                            />

                            <Column
                                disableSort
                                width={width}
                                label={intl.formatMessage(messages.symbolSrc)}
                                dataKey="source"
                                cellRenderer={sourceImageCellRenderer}
                                className={theme.column}
                            />
                            <Column
                                disableSort
                                width={width}
                                label={intl.formatMessage(messages.connectionType)}
                                dataKey="edgeTypeName"
                                cellRenderer={edgeImageCellRenderer}
                                className={theme.column}
                            />
                            <Column
                                disableSort
                                width={width}
                                label={intl.formatMessage(messages.symbolDest)}
                                dataKey="destination"
                                cellRenderer={destinationImageCellRenderer}
                                className={theme.column}
                            />
                            <Column
                                disableSort
                                width={width}
                                label={intl.formatMessage(messages.waypoint)}
                                dataKey="waypoint"
                                cellRenderer={waypointCellRenderer}
                                className={theme.column}
                            />
                            <Column
                                disableSort
                                headerStyle={{ border: 'none' }}
                                width={width}
                                className={theme.buttonsColumn}
                                dataKey="groupId"
                                cellRenderer={renderCellActionButtons}
                            />
                        </Table>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
};

const withMemo = React.memo(EdgesTab);

export { withMemo as EdgesTab };
