import { isNullOrUndefined } from 'is-what';

type TEntry<K, V> = {
    key: K;
    value: V;
};

export class CustomMap<K, V> {
    private map: { [id: string]: TEntry<K, V> } = {};

    public set(key: K, value: V): void {
        if (!isNullOrUndefined(key)) {
            this.map[this.keyToUniqueString(key)] = {
                key,
                value,
            };
        }
    }

    public get(key: K): V {
        const entry = key && this.map[this.keyToUniqueString(key)];

        return entry && entry.value;
    }

    public entries(): [K, V][] {
        const arr: [K, V][] = [];
        this.keys().forEach((key) => arr.push([key, this.get(key)]));

        return arr;
    }

    public has(key: K): boolean {
        return !!this.get(key);
    }

    public size(): number {
        return this.keys().length;
    }

    public delete(key: K): void {
        key && delete this.map[this.keyToUniqueString(key)];
    }

    public deleteAll(keys: K[]): void {
        if (keys) {
            keys.forEach((key) => keys && delete this.map[this.keyToUniqueString(key)]);
        }
    }

    public deleteByPartKey(...partKeys: {}[]): void {
        partKeys.forEach((partKey) =>
            this.deleteAll(
                this.keys().filter((key) =>
                    Object.keys(partKey).reduce((res, p) => key[p] === partKey[p] && res, true),
                ),
            ),
        );
    }

    public keys(): K[] {
        return Object.values(this.map).map((entry) => entry.key);
    }

    public values(): V[] {
        return Object.values(this.map).map((entry) => entry.value);
    }

    public clone(): CustomMap<K, V> {
        const newMap: CustomMap<K, V> = new CustomMap();
        Object.values(this.map).forEach((val) => newMap.set(val.key, val.value));

        return newMap;
    }

    private keyToUniqueString(key: K): string {
        //@ts-ignore
        return Object.keys(key)
            .sort()
            .reduce((summ: string, current: string) => {
                if (key[current]) {
                    if (typeof key[current] === 'object') {
                        return summ.concat(this.keyToUniqueString(key[current]));
                    }

                    return summ.concat(key[current].toString());
                }

                return summ;
            }, '');
    }
}
