import { NodeId } from '../../serverapi/api';
import { CustomMap } from '../../utils/map';

export class TNodeState<T> {
    byNodeId = new CustomMap<NodeId, T>();

    public cloneByNodeId(): TNodeState<T> {
        const self = new TNodeState<T>();
        self.byNodeId = this.byNodeId.clone();

        return self;
    }

    public set(nodeId: NodeId, node: T): TNodeState<T> {
        const stateCopy = this.cloneByNodeId();
        stateCopy.byNodeId.set(nodeId, node);

        return stateCopy;
    }

    public get(nodeId: NodeId): T {
        return this.byNodeId.get(nodeId);
    }

    public delete(nodeId: NodeId): TNodeState<T> {
        const stateCopy = this.cloneByNodeId();
        stateCopy.byNodeId.delete(nodeId);

        return stateCopy;
    }

    public keys(): NodeId[] {
        return this.byNodeId.keys();
    }

    public values(): T[] {
        return this.byNodeId.values();
    }
}
