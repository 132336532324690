import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { ModelDialog } from '../../components/ModelDialog/ModelDialog.component';
import { TModelDialogProps } from '../../components/ModelDialog/ModelDialog.types';
import { modelDialogSubmit, modelDialogParentNodeIdChanged } from '../../../../actions/modelDialog.actions';
import { TPayloadModelDialogSubmitData } from '../../../../actions/modelDialog.actions.types';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { ModelDialogSelectors } from '../../../../selectors/modelDialog.selectors';
import { ServerSelectors } from '../../../../selectors/entities/server.selectors';
import { treeItemCollapseAll, treeItemSelect } from '../../../../actions/tree.actions';
import { hasModelEditorLicense } from '../../../../selectors/authorization.selectors';
import { TreeNode } from '@/models/tree.types';

type TModelDialogContainerProperties = {
    serverId?: string;
    openedSelectNode?: boolean;
};

const mapStateToProps = (state: TRootState, props: TModelDialogContainerProperties): Partial<TModelDialogProps> => {
    const serverId = props.serverId || ServerSelectors.connected(state)[0];
    const isModelEditor: boolean = hasModelEditorLicense(state);

    return {
        serverId: serverId || '',
        openedSelectNode: props.openedSelectNode,
        formInitData: ModelDialogSelectors.getFormInitData(state),
        isModelEditor,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.MODEL_DIALOG));
        dispatch(treeItemCollapseAll(DialogType.MODEL_DIALOG_TYPE));
        dispatch(treeItemCollapseAll(DialogType.MODEL_DIALOG));
    },
    onSubmit: (payload: TPayloadModelDialogSubmitData) => {
        dispatch(modelDialogSubmit(payload));
        dispatch(treeItemCollapseAll(DialogType.MODEL_DIALOG_TYPE));
        dispatch(treeItemCollapseAll(DialogType.MODEL_DIALOG));
    },
    onParentNodeIdChange: (parentNode: TreeNode) => {
        dispatch(
            modelDialogParentNodeIdChanged({
                parentNodeId: parentNode.nodeId,
                notationId: 'bpmNotation',
            }),
        );
        dispatch(treeItemSelect(parentNode));
    },
});

export const ModelDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ModelDialog);
