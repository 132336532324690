import type { TCurrentUserProfile } from '../reducers/userProfile.reducer.types';
import type { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import type { MxCell } from '../mxgraph/mxgraph';
import type {
    AttributeType,
    DiagramElement,
    EdgeDefinitionNode,
    EdgeInstance,
    EdgeType,
    ModelAssignment,
    ModelNode,
    ModelType,
    NodeId,
    ObjectType,
    PresetImage,
} from '../serverapi/api';
import type {
    TUpdateDefinitionOverlay,
    TUpdateAllCellsOverlaysAction,
    TUpdateCellsOverlaysAction,
} from '../actions/overlay.actions.types';
import { all, select, takeEvery, call, put } from 'redux-saga/effects';
import {
    OVERLAY_CELLS_UPDATE,
    OVERLAY_DEFINITION_UPDATE,
    UPDATE_ALL_CELLS_OVERLAYS,
} from '../actionsTypes/overlay.actionTypes';
import { TreeSelectors } from '../selectors/tree.selectors';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { LabelIdPreffix } from '../mxgraph/bpmgraph';
import { objectDefinitionService } from '../services/ObjectDefinitionService';
import { ObjectTypeSelectors } from '../selectors/objectType.selectors';
import { PresetImageSelectors } from '../selectors/presetSettings/presetImage.selectors';
import { UserProfileSelectors } from '../selectors/userProfile.selectors';
import { PrincipalsSelectors } from '../selectors/principals.selectors';
import { WhiteboardGraph } from '../mxgraph/WhiteboardGraph';
import { nodeService } from '../services/NodeService';
import { EdgeDefinitionSelectors } from '../selectors/edgeDefinition.selector';
import { getActiveGraph } from '../selectors/editor.selectors';
import { EdgeTypeSelectors } from '../selectors/edgeType.selectors';
import { getLastSelectionObjectDefinitionForEvent } from '@/utils/bpm.mxgraph.utils';
import { navigatorPropertiesChangeObjectAction } from '@/actions/navigatorProperties.actions';
import { TSelectedElement } from '@/models/navigatorPropertiesSelectorState.types';
import { ModelSelectors } from '@/selectors/model.selectors';

type TGetOverlaysPropsReturn = {
    attrTypes: AttributeType[];
    instanceAttrTypes: AttributeType[];
    modelAssignments: ModelAssignment[];
};

export function* getEdgeOverlaysProps(
    diagramElement: EdgeInstance,
    edgeDefinition?: EdgeDefinitionNode,
    modelType?: ModelType,
): Generator<any, TGetOverlaysPropsReturn, any> {
    const { nodeId, modelAssignments } = edgeDefinition || {};

    const presetId: string = yield select(TreeSelectors.presetById(nodeId));
    const edgeType: EdgeType | undefined = edgeDefinition?.edgeTypeId
        ? yield select(
            EdgeTypeSelectors.byId({
                edgeTypeId: edgeDefinition?.edgeTypeId || '',
                presetId,
                serverId: nodeId?.serverId || '',
            }),
        )
        : yield modelType?.edgeTypes.find((type) => diagramElement.edgeTypeId === type.id);

    return {
        attrTypes: edgeType?.attributeTypes || [],
        instanceAttrTypes: edgeType?.diagramElementAttributes || [],
        modelAssignments: modelAssignments || [],
    };
}

export function* getObjectOverlaysProps(
    objectDefinition: ObjectDefinitionImpl,
): Generator<any, TGetOverlaysPropsReturn, any> {
    const { nodeId, modelAssignments } = objectDefinition;
    const presetId: string | undefined = yield select(TreeSelectors.presetById(nodeId));

    const objectType: ObjectType | undefined = yield select(
        ObjectTypeSelectors.byId({
            objectTypeId: objectDefinition.objectTypeId,
            presetId: presetId || '',
            serverId: nodeId.serverId,
        }),
    );
    const attrTypes = objectType?.nodeAttributes || [];
    const instanceAttrTypes = objectType?.diagramElementAttributes || [];

    return {
        attrTypes,
        instanceAttrTypes,
        modelAssignments,
    };
}

function* updateCellOverlays(graphId: NodeId, cell: MxCell) {
    const graph = instancesBPMMxGraphMap.get(graphId);
    const presetId: string | undefined = yield select(TreeSelectors.presetById(graph.id));
    const presetImages: PresetImage[] =
        (yield select(PresetImageSelectors.listAllByPreset(graph.id.serverId, presetId || ''))) || [];
    const userProfile: TCurrentUserProfile | undefined = presetId
        ? yield select(UserProfileSelectors.selectUserProfileByType(graph.id.serverId, presetId))
        : undefined;
    const principals = yield select(PrincipalsSelectors.getAll);

    const diagramElement = cell.getValue();

    if (diagramElement?.type === 'edge' && !(graph instanceof WhiteboardGraph)) {
        let edgeDefinition: EdgeDefinitionNode | undefined;
        const edgeDefintionId = (diagramElement as EdgeInstance | undefined)?.edgeDefinitionId;

        if (edgeDefintionId) {
            edgeDefinition = yield select(EdgeDefinitionSelectors.byId({ ...graph.id, id: edgeDefintionId }));
        }

        const {
            attrTypes: edgeAttrTypes,
            instanceAttrTypes,
            modelAssignments,
        } = yield getEdgeOverlaysProps(diagramElement, edgeDefinition, graph.modelType);

        const model: ModelNode = yield select(ModelSelectors.byId(graphId));

        graph.cellOverlayManager.updateEdgeAttributesOverlays(
            cell,
            edgeAttrTypes || [],
            instanceAttrTypes || [],
            edgeDefinition?.attributes || [],
            diagramElement?.attributes || [],
            edgeDefinition,
            model,
            presetImages,
            modelAssignments || [],
            { userProfile, principals },
        );
    }

    if (diagramElement?.type === 'object') {
        const cellAttrValues = (diagramElement as DiagramElement | undefined)?.attributes || [];
        const objectDefinition = objectDefinitionService().getObjectDefinitionByInstance(cell.value, graph.id);

        if (objectDefinition) {
            const {
                attrTypes: objectAttrTypes,
                instanceAttrTypes,
                modelAssignments,
            }: TGetOverlaysPropsReturn = yield getObjectOverlaysProps(objectDefinition);
            const objAttributeValues = objectDefinition.attributes;

            const model: ModelNode = yield select(ModelSelectors.byId(graphId));

            graph.cellOverlayManager.updateObjectOverlays(
                cell,
                objectAttrTypes,
                instanceAttrTypes,
                objAttributeValues,
                cellAttrValues,
                objectDefinition,
                model,
                presetImages,
                modelAssignments,
                { userProfile, principals },
            );
        }
    }
}

/**
 * Отрисовка оверлеев на графе
 */
export function* handleUpdateOverlayDefinition({ payload: nodeId }: TUpdateDefinitionOverlay) {
    const cellIds = nodeService().findCellIdsByNodeId(nodeId);
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    const activeGraph = activeGraphId && instancesBPMMxGraphMap.get(activeGraphId);

    if (!activeGraph) return;

    const { cells } = activeGraph.getModel();
    const ids = cellIds.get(activeGraph.id)?.filter((id) => id && !id.startsWith(LabelIdPreffix));

    if (!ids?.length) return;

    activeGraph.getModel().beginUpdate();

    try {
        yield all(ids.map((cellId) => call(updateCellOverlays, activeGraphId, cells[cellId])));
    } finally {
        activeGraph.getModel().endUpdate();
    }
}

/**
 * Вызывается при добавлении нового элемента диаграммы или при переименовании
 * элемента диаграммы (при выборе обьекта когда всплывает диалог конфликта имен)
 * Обновляет стиль атрибутов на графе.
 * @param payload
 */
function* handleUpdateCellsOverlays({ payload }: TUpdateCellsOverlaysAction) {
    const { graphId, cells } = payload;
    const graph = instancesBPMMxGraphMap.get(graphId);
    if (!graph) return;
    graph.getModel().beginUpdate();

    try {
        yield all(cells.map((cell) => call(updateCellOverlays, graph.id, cell)));
    } finally {
        graph.getModel().endUpdate();
    }
}

function* handleUpdateAllCellsOverlays({ payload }: TUpdateAllCellsOverlaysAction) {
    const { graphId } = payload;
    const graph = instancesBPMMxGraphMap.get(graphId);
    const cells = Object.values(graph.getModel().cells).filter((cell) => cell?.getValue()) as MxCell[];

    yield call(handleUpdateCellsOverlays, { payload: { graphId, cells } } as TUpdateCellsOverlaysAction);

    if (graph) {
        const { cells } = graph.getSelectionModel();
        const navigationState: TSelectedElement = getLastSelectionObjectDefinitionForEvent(
            cells,
            graph,
        );
        yield put(navigatorPropertiesChangeObjectAction(navigationState));
    }
}

export function* overlaySaga() {
    yield takeEvery(OVERLAY_DEFINITION_UPDATE, handleUpdateOverlayDefinition);
    yield takeEvery(OVERLAY_CELLS_UPDATE, handleUpdateCellsOverlays);
    yield takeEvery(UPDATE_ALL_CELLS_OVERLAYS, handleUpdateAllCellsOverlays);
}
