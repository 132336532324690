import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'NewSimulationNodeDIalog.name',
        defaultMessage: 'Наименование',
    },

    cancel: {
        id: 'NewSimulationNodeDIalog.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'NewSimulationNodeDIalog.save',
        defaultMessage: 'Сохранить',
    },
    simulationAddTitle: {
        id: 'Simulation.simulationAddTitle',
        defaultMessage: 'Добавление имитационного моделирования',
    },
    requiredField: {
        id: 'Simulation.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    clearResults: {
        id: 'Simulation.clearResults',
        defaultMessage: 'При изменении параметров запуска результаты выполнения имитационного моделирования будут очищены. Продолжить?',
    },
    saveParamsLaunch: {
        id: 'Simulation.saveParamsLaunch',
        defaultMessage: 'Сохранение параметров запуска',
    },
    continueSave: {
        id: 'Simulation.continueSave',
        defaultMessage: 'Продолжить',
    },
});
