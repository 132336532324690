import React, { FC, useEffect, useState } from 'react';
import { ArisDataNode, ArisImportDescriptor, NodeId } from '../../../../../serverapi/api';
import { Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import TreeItemIcon from '../../../../Tree/components/TreeItem/TreeItemIcon.component';
import { useDispatch, useSelector } from 'react-redux';
import { setArisNodeIdsForImport } from '../../../../../actions/importAris.actions';
import style from './SelectDataStep.scss';
import { v4 as uuid } from 'uuid';
import { useIntl } from 'react-intl';
import messages from '../../messages/ImportAris.messages';
import { ImportArisSelectors } from '../../../../../selectors/importAris.selectors';

type TSelectDataStep = {
    nodeId: NodeId;
};

const SelectDataStep: FC<TSelectDataStep> = ({ nodeId }) => {
    const { serverId, repositoryId, id } = nodeId;

    const descriptor: ArisImportDescriptor = useSelector(
        ImportArisSelectors.getArisDescriptor(serverId, repositoryId, id),
    );

    const { arisData, nodeIdsForImport } = descriptor;

    const dispatch = useDispatch();
    const intl = useIntl();

    const [treeData, setTreeData] = useState<DataNode[]>([]);

    // TODO: разобраться с типами
    const addKeysForTreeData = (nodesTree: any): DataNode[] => {
        nodesTree.forEach((node) => {
            if (!node.children) {
                node.title = node.name;
                node.key = node.id;
                node.icon = <TreeItemIcon {...{ nodeId: {id: node.id} as NodeId, type: node.type }} />;
            } else {
                node.title = node.name;
                node.key = node.id;
                node.icon = <TreeItemIcon {...{ nodeId: {id: node.id} as NodeId, type: node.type }} />;
                addKeysForTreeData(node.children);
            }
        });

        return nodesTree;
    };

    const setNodesTree = (nodesTree: ArisDataNode[]) => {
        const treeWithKey: DataNode[] = addKeysForTreeData(nodesTree.map((node) => ({ ...node, id: uuid() })));
        setTreeData(treeWithKey);
    };

    const onCheck = (checkedKeys: React.Key[]) => {
        dispatch(setArisNodeIdsForImport({ nodeId, nodeIdsForImport: checkedKeys as string[] }));
    };

    useEffect(() => {
        if (arisData?.nodesTree) {
            setNodesTree(arisData.nodesTree);
        }
    }, [arisData?.nodesTree]);

    return (
        <div className={style.container} data-test="import-ARIS_choose-repository-container">
            <p className={style.description}>{intl.formatMessage(messages.selectModelForImport)}:</p>
            <Tree
                className={style.tree}
                showIcon
                checkable
                onCheck={onCheck}
                treeData={treeData}
                checkedKeys={nodeIdsForImport}
            />
        </div>
    );
};

export default SelectDataStep;
