import { NodeId } from '@/serverapi/api';
import { IScriptNode } from '../../models/bpm/bpm-model-impl.types';
import { setServerIdToNodeOriginal } from '../../utils/nodeId.utils';
import { apiBundle } from '../api/api-bundle';

export class ScriptDAOService {
    public static async createScript(script: IScriptNode): Promise<IScriptNode> {
        const savedScript: IScriptNode = (await apiBundle(script.nodeId.serverId).script.createScript({
            body: script,
        })) as IScriptNode;
        setServerIdToNodeOriginal(savedScript, script.nodeId.serverId);

        return savedScript;
    }

    public static async saveScript(script: IScriptNode): Promise<IScriptNode> {
        const savedScript: IScriptNode = (await apiBundle(script.nodeId.serverId).script.saveScript({
            body: script,
        })) as IScriptNode;
        setServerIdToNodeOriginal(savedScript, script.nodeId.serverId);

        return savedScript;
    }

    public static async getScript(scriptId: string, serverId: string): Promise<IScriptNode> {
        const script: IScriptNode = (await apiBundle(serverId).script.getScript({
            scriptId,
        })) as IScriptNode;
        setServerIdToNodeOriginal(script, serverId);

        return script;
    }

    public static async lockScript(nodeId: NodeId) {
        const lock = await apiBundle(nodeId.serverId).script.lockScript({
            scriptId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });

        return lock;
    }

    public static async unlockScript(nodeId: NodeId) {
        const unlock = await apiBundle(nodeId.serverId).script.unlockScript({
            scriptId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });

        return unlock;
    }
}
