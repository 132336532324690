import React from 'react';
import { Alert, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'antd/lib/form/Form';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { TDialogProps } from '../../UIKit/components/Dialog/Dialog.types';
import messages from './ServerForm.messages';
import theme from './theme/ServerEditDialog.scss';
import { UserEdit } from './UserEdit.component';
import { TServerAuthDialogProps, TUserConnectInfo } from '../ServerForm.types';

class ServerAuthDialog extends React.Component<TServerAuthDialogProps, TUserConnectInfo> {
    constructor(props: TServerAuthDialogProps) {
        super(props);
        this.state = { ...props.value };
    }

    render() {
        const { intl, onSubmit } = this.props;
        const dialogProps: Partial<TDialogProps> = {
            visible: true,
            title: intl.formatMessage(messages.userHeader),
            closable: true,
            zIndex: 1001,
            maskClosable: true,
            onCancel: this.props.onCancel,
        };

        const footer = [
            <Button key="cancel" size="large" disabled={this.props.pending} onClick={this.props.onCancel}>
                <FormattedMessage {...messages.cancel} />
            </Button>,
            <Button
                key="ok"
                size="large"
                type="primary"
                onClick={() => onSubmit(this.state, this.props.server)}
                disabled={!(this.state.password && this.state.username) || this.props.pending}
            >
                <FormattedMessage {...messages.connect} />
            </Button>,
        ];

        return (
            <Dialog {...dialogProps} footer={footer}>
                {this.props.error && (
                    <Alert
                        className={theme.alert}
                        type="error"
                        message={this.props.error}
                        showIcon
                    />
                )}
                <Form layout="vertical" hideRequiredMark>
                    <UserEdit
                        value={this.state}
                        onChange={(info) => this.setState(info)}
                        disabled={this.props.pending}
                    />
                </Form>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ServerAuthDialog);

export { IntlComponent as ServerAuthDialog };
