// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DbSearchListItem__listItemContainer___KNV_{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.DbSearchListItem__listItem__mOlyZ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;cursor:pointer;margin-right:20px}.DbSearchListItem__name__gu2yH{color:#41bcfb}.DbSearchListItem__type__oIBUp{color:#c4c4c4;margin-left:10px}.DbSearchListItem__deleted__Z8BCO{color:red}", "",{"version":3,"sources":["webpack://./src/modules/Search/DbSearchListItem/DbSearchListItem.scss"],"names":[],"mappings":"AACI,4CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,qBAAA,CAAA,kBAAA,CAAA,oBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAGJ,mCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,cAAA,CACA,iBAAA,CAGJ,+BACI,aAAA,CAGJ,+BACI,aAAA,CACA,gBAAA,CAGJ,kCACI,SAAA","sourcesContent":[":local {\r\n    .listItemContainer {\r\n        display: flex;\r\n        align-items: flex-end;\r\n        justify-content: space-between;\r\n    }\r\n\r\n    .listItem {\r\n        display: flex;\r\n        flex-direction: column;\r\n        cursor: pointer;\r\n        margin-right: 20px;\r\n    }\r\n\r\n    .name {\r\n        color: #41bcfb;\r\n    }\r\n\r\n    .type {\r\n        color: #c4c4c4;\r\n        margin-left: 10px;\r\n    }\r\n\r\n    .deleted {\r\n        color: red;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listItemContainer": "DbSearchListItem__listItemContainer___KNV_",
	"listItem": "DbSearchListItem__listItem__mOlyZ",
	"name": "DbSearchListItem__name__gu2yH",
	"type": "DbSearchListItem__type__oIBUp",
	"deleted": "DbSearchListItem__deleted__Z8BCO"
};
export default ___CSS_LOADER_EXPORT___;
