import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { FileUploadDialog } from '../components/FileUploadDialog.component';
import { importNodeDialogSubmit } from '../../../actions/exportNotation.actions';
import { NodeId } from '../../../serverapi/api';
import { TreeItemType } from '../../Tree/models/tree';
import { TFileUploadDialogProps } from '../types/FileUploadDialog.types';

type TFileUploadImportDialogOwnProps = {
    parentNodeId: NodeId;
    type: TreeItemType;
};

const mapDispatchToProps = (dispatch, ownProps: TFileUploadImportDialogOwnProps): Partial<TFileUploadDialogProps> => ({
    onClose: () => dispatch(closeDialog(DialogType.IMPORT_UPLOAD_DIALOG)),
    onSubmit: (file: File, serverId: string) =>
        dispatch(importNodeDialogSubmit({ file, parentNodeId: ownProps.parentNodeId, type: ownProps.type })),
});

export const FileUploadImportDialogContainer = connect(null, mapDispatchToProps)(FileUploadDialog);
