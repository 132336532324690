import type { TFileReplaceAttributesUpdateAction, TTreeItemSelectAction } from '../actions/tree.actions.types';
import type {
    TSelectedElement,
    TNavigatorPropertiesSelectorState,
} from '../models/navigatorPropertiesSelectorState.types';
import type { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import type { BPMMxGraph } from '../mxgraph/bpmgraph';
import type { MxCell } from '../mxgraph/mxgraph';
import type {
    TNavigatorPropertiesChangePropertyAction,
    TNavigatorPropertiesChangePropertySetAction,
    TNavigatorPropertiesChangeObjectAction,
} from '../actions/navigatorProperties.actions.types';
import type {
    TWorkspaceTabsAddAction,
    TWorkspaceTabsRemoveAction,
    TWorkspaceTabsActivateAction,
} from '../actions/tabs.actions.types';
import type {
    AttributeValueString,
    DiagramElement,
    EdgeInstance,
    Node,
    NodeId,
    ObjectInstance,
    ShapeInstance,
} from '../serverapi/api';
import { put, select, takeEvery } from 'redux-saga/effects';
import { changeEdgeMultilingualName, changeEdgeStyle, editorLabelChangedAction } from '../actions/editor.actions';
import {
    NAVIGATOR_PROPERTIES_CHANGE_OBJECT,
    NAVIGATOR_PROPERTIES_CHANGE_PROPERTY,
    NAVIGATOR_PROPERTIES_CHANGE_PROPERTY_SET,
} from '../actionsTypes/navigatorProperties.actionTypes';
import {
    navigatorClearProperties,
    navigatorPropertiesChangeObjectAction,
    navigatorPropertiesChangePropertyAction,
    navigatorPropertiesRenderPropAction,
} from '../actions/navigatorProperties.actions';
import { WORKSPACE_TABS_ACTIVATE, WORKSPACE_TABS_ADD } from '../actionsTypes/tabs.actionTypes';
import { TREE_ITEM_SELECT } from '../actionsTypes/tree.actionTypes';
import { nodePropertiesUpdate } from '../actions/tree.actions';
import { EdgeStyleDescriptor } from '../models/edge-style';
import { homePageTabId } from '../models/home-page';
import { convert, initialNavigatorPropertiesSelectorState, tabWithDefaultPropChangeAction } from '../models/navigatorPropertiesSelectorState';
import { EntityEnum } from '../models/navigatorPropertiesSelectorState.types';
import {
    PROP_KEY_EDGE_END_ARROW,
    PROP_KEY_EDGE_LINE_TYPE,
    PROP_KEY_EDGE_START_ARROW,
    PROP_NAME,
} from '../models/properties/accessible-properties';
import { TreeItemType } from '../modules/Tree/models/tree';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { getActiveGraph } from '../selectors/editor.selectors';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { nodeService } from '../services/NodeService';
import { getCellEntityType, getLastSelectionObjectDefinitionForEvent } from '../utils/bpm.mxgraph.utils';
import { compareNodeIds } from '../utils/nodeId.utils';
import getActiveTab = TabsSelectors.getActiveTab;
import { getActiveTabs } from '../selectors/navigator.selectors';
import { TNavigatorTab } from '../reducers/navigator.reducer.types';
import { FILE_REPLACE_ATTRIBUTES_UPDATE, NAVIGATOR_SELECT_TAB } from '../actionsTypes/navigator.actionTypes';
import { getSelectedState } from '../selectors/navigatorProperties.selectors';
import { objectDefinitionsAdd } from '../actions/entities/objectDefinition.actions';
import { edgeDefinitionsAdd } from '../actions/entities/edgeDefinition.actions';
import { TWorkspaceTab } from '../models/tab.types';

type TDefinitionContext = {
    graph: BPMMxGraph;
    cell: MxCell;
    diagramElement: DiagramElement | undefined;
    definitionId: NodeId | undefined;
};

function lookupDefinitionContext(graphId: NodeId, cellId: string): TDefinitionContext {
    const graph = instancesBPMMxGraphMap.get(graphId);

    if (graph) {
        const cell = graph.getModel().getCell(cellId);
        const diagramElement = cell?.getValue() as DiagramElement | undefined;
        if (diagramElement) {
            let id: string | undefined;

            if (diagramElement.type === 'object') {
                id = (diagramElement as ObjectInstance).objectDefinitionId;
            }

            if (diagramElement.type === 'edge') {
                id = (diagramElement as EdgeInstance).edgeDefinitionId;
            }

            if (diagramElement.type === 'shape') {
                id = (diagramElement as ShapeInstance).imageId;
            }
            const definitionId = id ? { ...graphId, id } : undefined;

            return { graph, cell, diagramElement, definitionId };
        }
    }

    return {} as TDefinitionContext;
}

function* handleNavPropsChangePropertySet({
    payload: { graphId, cellId, changes, removed },
}: TNavigatorPropertiesChangePropertySetAction) {
    if (removed.length !== 0) {
        const { diagramElement } = lookupDefinitionContext(graphId, cellId);

        if (diagramElement) {
            diagramElement.attributes = diagramElement.attributes
                ? diagramElement.attributes.filter((a) => !removed.includes(a.id))
                : [];
        }
    }

    const effects = Object.keys(changes)
        .map((k) => changes[k])
        .map(({ descriptor, value }) =>
            navigatorPropertiesChangePropertyAction({
                descriptor,
                value,
                graphId,
                cellId,
            }),
        );

    for (const a of effects) {
        yield handleNavPropsChangeProperty(a);
    }

    if (removed.length !== 0 && effects.length === 0) {
        const { definitionId } = lookupDefinitionContext(graphId, cellId);

        if (definitionId) {
            yield put(
                navigatorPropertiesChangeObjectAction({
                    cellId,
                }),
            );
        }
    }
}

function* handleNavPropsChangeProperty({
    payload: { descriptor, value, graphId, cellId },
}: TNavigatorPropertiesChangePropertyAction) {
    const { graph, cell, definitionId } = lookupDefinitionContext(graphId, cellId);
    if (cell?.getValue()) {
        descriptor.setValue(cell.getValue(), value);
        graph.getModel().beginUpdate();

        try {
            if (descriptor.key === PROP_KEY_EDGE_LINE_TYPE) {
                const v = +value?.value;
                const { styleKeys, styleValues } = EdgeStyleDescriptor.byValue(v);

                yield put(changeEdgeStyle(styleKeys, styleValues, cellId));
            }

            if ([PROP_KEY_EDGE_END_ARROW, PROP_KEY_EDGE_START_ARROW].includes(descriptor.key)) {
                yield put(changeEdgeStyle([descriptor.key], [value?.value], cellId));
            }

            if (descriptor === PROP_NAME) {
                graph.cellLabelChanged(cell, value?.value || '', false, undefined);

                yield put(editorLabelChangedAction(graph.id, cellId, value?.value || ''));
                yield put(
                    changeEdgeMultilingualName(graph.id, cellId, (value as AttributeValueString | undefined)?.str),
                );
            }

            if (graph.cellEditor.editingCell) {
                graph.cellEditor.stopEditing(true);
            }
        } finally {
            graph.getModel().endUpdate();
        }

        if (definitionId) {
            yield put(
                navigatorPropertiesChangeObjectAction({
                    cellId,
                }),
            );
        }
    }
}

function* updateNode(nodeId: NodeId) {
    const activeTabs: TNavigatorTab[] = yield select(getActiveTabs);
    const correctNodeId = !!(nodeId.id && nodeId.serverId && nodeId.repositoryId);

    if (activeTabs.includes(TNavigatorTab.Properties) && correctNodeId) {
        // в случае ошибки запроса свойств не показываем ее пользователю,
        // обновление данных в окне свойств в большинстве случаев это бобочное действие, напимер, при создании или выделении элемента
        // пользователи не понимают к чему относится ошибка, думают что к основному действию
        const node: Node | undefined = yield nodeService()
            .loadNodeFromServer(nodeId)
            .catch(() => undefined);

        if (node) {
            if (node.type === TreeItemType.ObjectDefinition) {
                yield put(objectDefinitionsAdd([node as ObjectDefinitionImpl]));
            }

            if (node.type === TreeItemType.EdgeDefinition) {
                yield put(edgeDefinitionsAdd([node]));
            }

            yield put(nodePropertiesUpdate(nodeId, node.type as TreeItemType, node));
        }
    }
}

function* handleNavPropsChangeObject({
    payload: { navigatorPropertiesSelectorState },
}: TNavigatorPropertiesChangeObjectAction) {
    const { cellId } = navigatorPropertiesSelectorState;
    const graphId: NodeId | undefined = yield select(getActiveGraph);
    let propertiesState: TNavigatorPropertiesSelectorState = initialNavigatorPropertiesSelectorState;
    if (graphId && cellId) {
        const { graph, cell, diagramElement, definitionId } = lookupDefinitionContext(graphId, cellId);
        const entityType: EntityEnum | undefined = getCellEntityType(cell);
        if (entityType === undefined) {
            yield put(navigatorClearProperties());

            return;
        }
        if (graph && cell && diagramElement) {
            if (definitionId) yield updateNode(definitionId);

            propertiesState = {
                nodeId: definitionId || propertiesState.nodeId,
                entityType,
                currentGraphId: graphId,
            };
        }
    }
    if (!cellId) {
        const activeScheme: TWorkspaceTab | undefined = yield select(getActiveTab);
        const nodeId: NodeId | undefined = activeScheme?.nodeId;
        if (!activeScheme) {
            yield put(navigatorClearProperties());

            return;
        }

        const entityType: EntityEnum | undefined = tabWithDefaultPropChangeAction[activeScheme.type];
        if (entityType === undefined || !nodeId) {
            yield put(navigatorClearProperties());

            return;
        }

        if (nodeId) {
            yield updateNode(nodeId);
            propertiesState = {
                nodeId,
                entityType,
            };
        }
    }
    yield put(navigatorPropertiesRenderPropAction({ ...navigatorPropertiesSelectorState, ...propertiesState }));
}

function* handleWorkspaceTabChanged(
    action: TWorkspaceTabsActivateAction | TWorkspaceTabsAddAction | TWorkspaceTabsRemoveAction,
) {
    
    const isDefaultTabChangeAction = (tabType: string) => Object.keys(tabWithDefaultPropChangeAction).includes(tabType);

    const { workspaceTab } = action.payload;

    if (compareNodeIds(workspaceTab.nodeId, homePageTabId) || workspaceTab.nodeId.id.includes('loading')) {
        yield put(navigatorClearProperties());

        return;
    }

    const activeGraphId = yield select(getActiveGraph);
    const activeGraph = instancesBPMMxGraphMap.get(activeGraphId);

    if (activeGraph) {
        const { cells } = activeGraph.getSelectionModel();
        let selectedElement: TSelectedElement = {};

        if (cells.length !== 0) {
            selectedElement = getLastSelectionObjectDefinitionForEvent(cells, activeGraph);
        }
        yield put(navigatorPropertiesChangeObjectAction(selectedElement));
    } else if (isDefaultTabChangeAction(workspaceTab.type)) {
        const nacState: TNavigatorPropertiesSelectorState = {
            nodeId: workspaceTab.nodeId,
            entityType: tabWithDefaultPropChangeAction[workspaceTab.type],
        };

        yield put(navigatorPropertiesRenderPropAction(nacState));
    } else {
        yield put(navigatorClearProperties());
    }
}

const defaultPropUpdateTypes = [
    TreeItemType.Model,
    TreeItemType.Wiki,
    TreeItemType.Matrix,
    TreeItemType.Script,
    TreeItemType.Repository,
    TreeItemType.Folder,
    TreeItemType.File,
    TreeItemType.ObjectDefinition,
    TreeItemType.SimulationModeling,
    TreeItemType.FileFolder,
    TreeItemType.ScriptFolder,
    TreeItemType.Spreadsheet,
    TreeItemType.Kanban,
    TreeItemType.EdgeDefinition,
    TreeItemType.Dashboard,
];

function* handleTreeNodeObjectSelection(action: TTreeItemSelectAction) {
    const { selectedNode } = action.payload;
    /// ////////
    // todo оптимизировать загрузку
    //

    // грузим всегда, чтобы открыть панель свойств и там были данные
    /* const navPanels: TNavigatorPanel[] = yield select(getPanels);
    const isActiveNavPropsTab = navPanels.filter((p: TNavigatorPanel) => p.type === TNavigatorTab.Properties).length;
    if ( isActiveNavPropsTab ) { */
    if (selectedNode) {
        const { type, nodeId } = selectedNode;

        if (defaultPropUpdateTypes.includes(type)) {
            yield updateNode(nodeId);
            const entityType: EntityEnum | undefined = convert(type);
            if (entityType === undefined) {
                yield put(navigatorClearProperties());

                return;
            }
            const propertiesState: TNavigatorPropertiesSelectorState = {
                nodeId,
                entityType,
                currentGraphId: undefined,
            };
            yield put(navigatorPropertiesRenderPropAction(propertiesState));
        } else {
            yield put(navigatorClearProperties());
        }
    }
    // }
}

function* handleNavigatorTabSelect() {
    const { nodeId }: TNavigatorPropertiesSelectorState = yield select(getSelectedState);

    yield updateNode(nodeId);
}

function* handleFileReplaceAttributesUpdate(action: TFileReplaceAttributesUpdateAction) {
    const { nodeId } = action.payload;

    yield updateNode(nodeId);
}

export function* navigatorPropertiesSaga() {
    yield takeEvery(WORKSPACE_TABS_ADD, handleWorkspaceTabChanged);
    yield takeEvery(WORKSPACE_TABS_ACTIVATE, handleWorkspaceTabChanged);
    yield takeEvery(NAVIGATOR_PROPERTIES_CHANGE_PROPERTY, handleNavPropsChangeProperty);
    yield takeEvery(NAVIGATOR_PROPERTIES_CHANGE_PROPERTY_SET, handleNavPropsChangePropertySet);
    yield takeEvery(NAVIGATOR_PROPERTIES_CHANGE_OBJECT, handleNavPropsChangeObject);
    yield takeEvery(NAVIGATOR_SELECT_TAB, handleNavigatorTabSelect);
    yield takeEvery(TREE_ITEM_SELECT, handleTreeNodeObjectSelection);
    yield takeEvery(FILE_REPLACE_ATTRIBUTES_UPDATE, handleFileReplaceAttributesUpdate);
}
