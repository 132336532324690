import { defineMessages } from 'react-intl';

export default defineMessages({
    noAvailableItems: {
        id: 'AttributeTypeSelector.noAvailableItems',
        defaultMessage: 'Нет доступных атрибутов',
    },
    attribute: {
        id: 'AttributeTypeSelector.attribute',
        defaultMessage: 'Выберите тип атрибута',
    },
    format: {
        id: 'AttributeTypeSelector.format',
        defaultMessage: 'Формат',
    },
    definitionAttributes: {
        id: 'AttributeTypeSelector.definitionAttributes',
        defaultMessage: 'Атрибуты определения',
    },
    instanceAttributes: {
        id: 'AttributeTypeSelector.instanceAttributes',
        defaultMessage: 'Атрибуты экземпляра',
    },
    systemAttributes: {
        id: 'AttributeTypeSelector.systemAttributes',
        defaultMessage: 'Системные атрибуты',
    },
    modelAttributes: {
        id: 'AttributeTypeSelector.modelAttributes',
        defaultMessage: 'Атрибуты модели',
    },
});
