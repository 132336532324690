import { SearchOutlined } from '@ant-design/icons';
import { Button, Select, Table } from 'antd';
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    getNotationReplacementDataByNodeIdRequest,
    getNotationReplacementDataByNodeIdSuccess,
    replaceNotations,
} from '../../actions/changeEntityType.actions';
import { workspaceRemoveTab } from '../../actions/tabs.actions';
import { IWorkspaceTabChangeEntityTypesParams, TWorkspaceTab } from '../../models/tab.types';
import { notationsByNodeId } from '../../selectors/changeEntityType.selector';
import { ModelTypeSelectors } from '../../selectors/modelType.selectors';
import { TreeSelectors } from '../../selectors/tree.selectors';
import { ModelType, NodeNotationReplacementDTO, ObjectType } from '../../serverapi/api';
import { sortByAlpha } from '../../utils/sortByAlpha';
import { TreeItemType } from '../Tree/models/tree';
import messages from './ChangeEntityTypeTab.messages';
import theme from './ChangeEntityTypeTab.scss';
import { SortOrder } from 'antd/es/table/interface';
import { TNotation } from '../../actions/changeEntityType.types';
import pick from 'lodash/pick';
import { TableCurrentDataSource } from 'antd/lib/table/interface';
import { presetMetaDataRequest } from '../../actions/notation.actions';
import { OptionData } from 'rc-select/lib/interface';
import { TTreeEntityState } from '../../models/tree.types';

type TChangeEntityTypeTab = {
    tab: TWorkspaceTab;
};

type TSearchDepthOption = {
    value: number;
    label: string;
};

export const ChangeEntityTypeTab = ({ tab }: TChangeEntityTypeTab) => {
    const { nodeId } = tab.params as IWorkspaceTabChangeEntityTypesParams;

    const intl = useIntl();

    const dispatch = useDispatch();

    const [searchDepth, setSearchDepth] = useState<number>(0);
    const [nodeTypes, setNodeTypes] = useState<TreeItemType[]>([TreeItemType.ObjectDefinition, TreeItemType.Model]);
    const [stateNotations, setStateNotations] = useState<NodeNotationReplacementDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const objectTypes: ObjectType[] = useSelector(
        ModelTypeSelectors.takeObjectTypesFromModelTypes(nodeId.serverId, presetId),
    );
    const modelTypes: ModelType[] = useSelector(ModelTypeSelectors.getAllModelTypes(nodeId.serverId, presetId));
    const notations: NodeNotationReplacementDTO[] = useSelector(notationsByNodeId(nodeId));
    const path: string = useSelector(TreeSelectors.nodePath(nodeId));
    const node: TTreeEntityState | undefined = useSelector(TreeSelectors.itemById(nodeId));
    const [newTypeSelectedName, setNewTypeSelectedName] = useState<{ [id: string]: string | undefined }>({});

    useEffect(() => {
        if (node?.type === TreeItemType.Repository) {
            dispatch(presetMetaDataRequest([presetId]));
        }

        return () => {
            dispatch(getNotationReplacementDataByNodeIdSuccess({ nodeId, notationReplacementData: [] }));
        };
    }, []);

    useEffect(() => {
        setStateNotations(notations || []);
        setLoading(false);
    }, [notations]);

    const getEntityType = useCallback(
        (record: NodeNotationReplacementDTO) => {
            switch (record.nodeType) {
                case TreeItemType.ObjectDefinition:
                    return objectTypes.find((ot) => ot.id === record.typeId);
                case TreeItemType.Model:
                    return modelTypes.find((mt) => mt.id === record.typeId);
                default:
                    return undefined;
            }
        },
        [objectTypes, modelTypes],
    );

    const getEntityName = useCallback((type: string): string => {
        switch (type) {
            case TreeItemType.ObjectDefinition:
                return intl.formatMessage(messages.object);
            case TreeItemType.Model:
                return intl.formatMessage(messages.model);
            default:
                return '';
        }
    }, []);

    const fullNotationReplacementsData: TNotation[] = useMemo(
        () =>
            stateNotations.map((notationDTO: NodeNotationReplacementDTO) => {
                const entityType = getEntityType(notationDTO);

                return {
                    ...notationDTO,
                    entityName: getEntityName(notationDTO.nodeType!),
                    typeExists: !!entityType,
                    typeName: entityType?.name || notationDTO.typeId,
                } as TNotation;
            }),
        [stateNotations, getEntityName, getEntityType],
    );

    const search = useCallback(() => {
        setLoading(true);
        dispatch(getNotationReplacementDataByNodeIdRequest({ nodeId, searchDepth, nodeTypes }));
    }, [tab, searchDepth, nodeTypes]);

    const onSelectChange = useCallback(
        (record: TNotation) => (newTypeId: string) => {
            const stateNotationsClone = stateNotations.slice();
            const index = stateNotationsClone.findIndex((notation) => notation.typeId === record.typeId);
            stateNotationsClone[index] = {
                ...stateNotationsClone[index],
                newTypeId,
            };
            setStateNotations(stateNotationsClone);
        },
        [stateNotations],
    );

    const renderSelectColumn = useCallback(
        (newTypeId, record: TNotation) => {
            type TItem = ObjectType | ModelType;
            let items: TItem[] = [];
            switch (record.nodeType) {
                case TreeItemType.ObjectDefinition:
                    items = objectTypes;
                    break;
                case TreeItemType.Model:
                    items = modelTypes;
                    break;
                default:
                    break;
            }

            return (
                <Select
                    showSearch
                    style={{ width: 275 }}
                    className={record.newTypeId ? theme.yellowRow : ''}
                    onSelect={(typeId) => {
                        const itemSelected = items.find((item) => item.id === typeId);
                        setNewTypeSelectedName({
                            ...newTypeSelectedName,
                            [typeId]: itemSelected?.name,
                        });
                    }}
                    onChange={onSelectChange(record)}
                    value={
                        record.newTypeId
                            ? newTypeSelectedName?.[record.newTypeId]
                            : intl.formatMessage(messages.dontChange)
                    }
                    filterOption={(input, option) =>
                        (option?.label as string).toLowerCase().includes(input.toLowerCase())
                    }
                    options={items.map((item: TItem) => ({
                        value: item.id,
                        label: item.name,
                    }))}
                />
            );
        },
        [onSelectChange, objectTypes, modelTypes],
    );

    const commonSorter = useCallback((dataIndex: string) => {
        return (a: TNotation, b: TNotation, sortOrder: SortOrder) => {
            if (a.typeExists && b.typeExists) {
                if (dataIndex === 'entityName') return sortByAlpha(a.entityName, b.entityName);
                if (dataIndex === 'typeName') {
                    return sortByAlpha(a.typeName, b.typeName);
                }

                return 0;
            }

            if (sortOrder === 'ascend') {
                if (!a.typeExists) return -1;
            }

            if (sortOrder === 'descend') {
                if (!a.typeExists) return 1;
            }

            return 0;
        };
    }, []);

    const columns = useMemo(
        () => [
            {
                title: intl.formatMessage(messages.entity),
                dataIndex: 'entityName',
                width: 100,
                sorter: commonSorter('entityName'),
            },
            {
                title: intl.formatMessage(messages.currentType),
                dataIndex: 'typeName',
                sorter: commonSorter('typeName'),
            },
            {
                title: intl.formatMessage(messages.newType),
                dataIndex: 'newTypeId',
                render: renderSelectColumn,
                width: 300,
            },
        ],
        [renderSelectColumn, commonSorter],
    );

    const depthOptions = useMemo(() => {
        const options: TSearchDepthOption[] = new Array(10).fill(undefined).map((_, index) => ({
            value: index + 1,
            label: intl.formatMessage(messages.searchDepthLimit, { searchDepth: index + 1 }),
        }));
        options.unshift({
            value: 0,
            label: intl.formatMessage(messages.searchDepthLimit, { searchDepth: intl.formatMessage(messages.no) }),
        });

        return options;
    }, []);

    const nodeTypesOptions = useMemo(
        () => [
            {
                value: `${TreeItemType.ObjectDefinition}_${TreeItemType.Model}`,
                label: intl.formatMessage(messages.objectsAndModels),
            },
            {
                value: TreeItemType.ObjectDefinition,
                label: intl.formatMessage(messages.objectTypes),
            },
            {
                value: TreeItemType.Model,
                label: intl.formatMessage(messages.modelTypes),
            },
        ],
        [],
    );

    const onSearchDepthChange = useCallback((value: string) => {
        setSearchDepth(parseInt(value, 10));
    }, []);

    const onNodeTypesChange = useCallback((value: string) => {
        setNodeTypes(value.split('_') as TreeItemType[]);
    }, []);

    const closeTab = useCallback(() => {
        dispatch(workspaceRemoveTab(tab));
    }, []);

    const changeEntityType = useCallback(() => {
        const body: NodeNotationReplacementDTO[] = stateNotations
            .filter((notation) => notation.newTypeId)
            .map((notation) => pick(notation, ['nodeType', 'typeId', 'newTypeId']));
        dispatch(
            replaceNotations({
                nodeId,
                tab,
                searchDepth,
                nodeTypes,
                body,
            }),
        );
    }, [tab, searchDepth, nodeTypes, stateNotations]);

    const addRowClassName = useCallback((record: TNotation) => {
        return !record.typeExists ? theme.redRow : '';
    }, []);

    const onSortChange = useCallback((pagination, filter, sorter, extra: TableCurrentDataSource<TNotation>) => {
        if (extra.currentDataSource) setStateNotations(extra.currentDataSource);
    }, []);

    const replaceButtonDisabled = useMemo(
        () => !stateNotations?.length || !stateNotations.some((notation) => notation.newTypeId),
        [stateNotations],
    );

    return (
        <div className={theme.container} data-test="change-entity-type-tab_container">
            <h2>{intl.formatMessage(messages.header)}</h2>
            <h3 className={theme.subheader}>{intl.formatMessage(messages.path, { path })}</h3>
            <div className={theme.headerControlsWrapper}>
                <Select
                    style={{ width: 250 }}
                    onChange={onNodeTypesChange}
                    defaultValue={nodeTypesOptions[0].label}
                    options={nodeTypesOptions}
                    data-test="change-element_entity-list"
                />
                <Select
                    onChange={onSearchDepthChange}
                    defaultValue={depthOptions[0].label}
                    style={{ width: 250 }}
                    options={depthOptions as OptionData[]}
                    data-test="change-element_depth-list"
                />
                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={search}
                    data-test="change-element_search-button"
                >
                    {intl.formatMessage(messages.search)}
                </Button>
            </div>
            <div className={theme.tableWrap}>
                <Table
                    className={theme.table}
                    rowClassName={addRowClassName}
                    bordered
                    columns={columns}
                    dataSource={fullNotationReplacementsData}
                    pagination={false}
                    loading={loading}
                    scroll={{
                        x: 'max-content',
                    }}
                    onChange={onSortChange}
                    data-test="change-element_table"
                />
            </div>
            <div className={theme.footer}>
                <Button size="large" onClick={closeTab} data-test="change-element_cancel-button">
                    {intl.formatMessage(messages.cancel)}
                </Button>
                <Button
                    disabled={replaceButtonDisabled}
                    size="large"
                    type="primary"
                    danger
                    onClick={changeEntityType}
                    data-test="change-element_replace-button"
                >
                    {intl.formatMessage(messages.replace)}
                </Button>
            </div>
        </div>
    );
};
