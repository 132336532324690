import { takeEvery } from 'redux-saga/effects';
import { appExit } from '../actions/app.actions';
import {
    appHaveAuthTabAction,
    nodeOpenFailedAction,
    nodeOpenSuccessfulAction,
    newAppInstanceIsOpenedAction,
} from '../actions/tabsBus.actions';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { AppExitMethod } from '../models/app';
import { TabsBusDAOService } from '../services/dao/TabsBusDAOService';
import { getStore } from '../store';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
/*
                                tab1                                                          tab2(авторизованная вкладка)
Подписка на шину                |NEW_TAB_OPENED(externalLink)-------------------------------->| 
                                |                                                             | если авторизован и пришел externalLink прокидываем openNode()
                                |<--------------------------(status)NODE_OPEN_SUCCESFUL/FAILED| внутри loadModel.saga вызываем TabsBusDAOService.send(SUCCESSFUL/FAILED)
Прокидываем openDialog(status)  |                                                             |
Если кликаем на кнопку          |                                                             |
"Продолжить в текущем клиенте"  |TABS_BUS_APP_EXIT_REQUEST----------------------------------->| если авторизованы прокидываем appExit()
______________________________________________________________________________________________________________________________________________________
Подписка на шину                |NEW_TAB_OPENED (открыта новая вкладка)---------------------->|
                                |                                                             | если авторизован и НЕТ externalLink
                                |<-------------------------------------APP_HAVE_AUTHORIZED_TAB| сразу в tabsBus.saga вызываем TabsBusDAOService.send(APP_HAVE_AUTHORIZED_TAB)
Прокидываем openDialog(status)  |                                                             |
Если кликаем на кнопку          |                                                             |
"Начать в текущем клиенте"      |TABS_BUS_APP_EXIT_REQUEST----------------------------------->| если авторизованы прокидываем appExit()
*/

function* tabsBusSubscribe() {
    const { dispatch } = getStore();

    const processMessage = (newValue: string) => {
        const message = JSON.parse(newValue);
        switch (message.actionType) {
            case TabsBusActions.NEW_APP_INSTANCE_IS_OPENED:
                dispatch(newAppInstanceIsOpenedAction(message.payload));
                break;
            case TabsBusActions.NODE_OPEN_SUCCESSFUL:
                dispatch(nodeOpenSuccessfulAction());
                break;
            case TabsBusActions.NODE_OPEN_FAILED:
                dispatch(nodeOpenFailedAction());
                break;
            case TabsBusActions.TABS_BUS_APP_EXIT_REQUEST:
                dispatch(appExit(AppExitMethod.Logout));
                break;
            case TabsBusActions.APP_HAVE_AUTHORIZED_TAB:
                dispatch(appHaveAuthTabAction());
                break;
        }
    };
    TabsBusDAOService.subscribeToTabsBus(processMessage);

    LocalStorageDaoService.setTabsBusAction(TabsBusActions.NEW_APP_INSTANCE_IS_OPENED, window?.location.hash);
}

function* tabsBusUnsubscribe() {
    TabsBusDAOService.unsubscribeFromTabsBus();
}

export function* tabsBusSubscribeSaga() {
    yield takeEvery(TabsBusActions.TABS_BUS_SUBSCRIBE, tabsBusSubscribe);
    yield takeEvery(TabsBusActions.TABS_BUS_UNSUBSCRIBE, tabsBusUnsubscribe);
}
