import { Tooltip, Dropdown, Menu } from 'antd';
import type { MenuProps } from 'antd';
import React, { Component, FC } from 'react';
import { injectIntl, WrappedComponentProps, MessageDescriptor } from 'react-intl';
import icDelete from '../../../resources/icons/delete.svg';
import icEdit from '../../../resources/icons/ic-edit.svg';
import icSettings from '../../../resources/icons/ic-settings.svg';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from './RowButtons.messages';
import buttonTheme from './RowButtons.scss';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { noop } from 'lodash';
import { TSpriteSymbol } from '../../../models/spriteSymbol.types';
import { showDeleteConfirmation } from './DeleteButton';
import { getMenuItem } from '../../../utils/antdMenuItem.utils';

type TIconPropsWithEvent = {
    spriteSymbol: TSpriteSymbol;
    onClick?: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
} & JSX.IntrinsicAttributes;

type TDropdownMenu = {
    items: Array<{
        key: string;
        message: MessageDescriptor;
        onClick: () => void;
    }>;
};

type TRowButtonProps = {
    buttons?: Array<
        TIconPropsWithEvent & {
            tooltip: MessageDescriptor;
            loading?: boolean;
            menu?: TDropdownMenu;
            dataTestID?: string;
        }
    >;
    loading?: boolean;
    className?: string;
    disabled?: boolean;
};

type TRowButtonFullProps = TRowButtonProps & WrappedComponentProps;

enum ButtonKey {
    DELETE = 'DELETE',
    EDIT = 'EDIT',
    SETTINGS = 'SETTINGS',
}

const RowButtons: FC<TRowButtonFullProps> = (props) => {
    const { loading, className, buttons } = props;

    return (
        <div className={cx(buttonTheme.alignRight, className)}>
            {buttons?.map((button) => {
                const items: MenuProps['items'] =
                    button?.menu?.items.map((item) =>
                        getMenuItem(
                            <Menu.Item key={item.key + uuid()} onClick={item.onClick}>
                                {props.intl.formatMessage(item.message)}
                            </Menu.Item>,
                            item.key + uuid(),
                        ),
                    ) || [];
                const disabled = props.disabled || loading;

                return (
                    <Tooltip
                        mouseLeaveDelay={0}
                        title={props.intl.formatMessage(button.tooltip)}
                        key={button.key + uuid()}
                    >
                        <Dropdown destroyPopupOnHide menu={{ items }}>
                            <span data-test={`row-button_${button.dataTestID || ''}`}>
                                <Icon
                                    className={cx(buttonTheme.button, loading && buttonTheme.displayWaitScreen)}
                                    disabled={disabled}
                                    onClick={disabled ? noop : button.onClick}
                                    {...button}
                                />
                            </span>
                        </Dropdown>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export class EditButton {
    static build(
        onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void,
        disabled?: boolean,
        key?: string,
        menu?: TDropdownMenu,
    ) {
        return {
            key: key || ButtonKey.EDIT,
            onClick: disabled ? noop : onClick,
            disabled,
            spriteSymbol: icEdit,
            tooltip: messages.edit,
            menu,
            dataTestID: 'EDIT',
        };
    }
}

export class DeleteButton extends Component<TRowButtonFullProps> {
    static build(
        onConfirm: () => void,
        disabled?: boolean,
        key?: string,
        menu?: TDropdownMenu,
        title?: string,
        dialogContent?: React.ReactNode,
        className?: string,
    ) {
        return {
            key: key || ButtonKey.DELETE,
            onClick: disabled
                ? noop
                : (e) => {
                      e.stopPropagation();
                      showDeleteConfirmation({ dialogContent, onDelete: onConfirm, deleteQuestion: title, className });
                  },
            disabled,
            spriteSymbol: icDelete,
            tooltip: messages.delete,
            menu,
            dataTestID: 'DELETE',
        };
    }
}

export class SettingsButton extends Component<TRowButtonFullProps> {
    static build(onClick: () => void, disabled?: boolean, key?: string, menu?: TDropdownMenu) {
        return {
            key: key || ButtonKey.SETTINGS,
            onClick: disabled ? noop : onClick,
            disabled,
            spriteSymbol: icSettings,
            tooltip: messages.settings,
            menu,
            dataTestID: 'SETTINGS',
        };
    }
}

export class DeleteImmediately {
    static build(
        onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void,
        disabled?: boolean,
        key?: string,
        menu?: TDropdownMenu,
    ) {
        return {
            key: key || ButtonKey.EDIT,
            onClick: disabled ? noop : onClick,
            disabled,
            spriteSymbol: icDelete,
            tooltip: messages.delete,
            menu,
            dataTestID: 'DeleteImmediately',
        };
    }
}
const RowButtonsIntl = injectIntl(RowButtons);

export { RowButtonsIntl as RowButtons };
