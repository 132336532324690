import { ModelTypes } from '../models/ModelTypes';
import { SymbolConstants } from '../models/Symbols';
import { ModelType, Symbol } from '../serverapi/api';
import uniqBy from 'lodash/uniqBy';

export function getSymbolsFromModelType(modelType: ModelType | undefined): Symbol[] {
    const symbols = modelType?.symbols || [];

    if (modelType?.id === ModelTypes.SEQUENCE_DIAGRAM) {
        symbols.push(SymbolConstants.UML_FRAME_SYMBOL, SymbolConstants.UML_NOTE_SYMBOL);
    }

    return uniqBy(symbols, 'id');
}
