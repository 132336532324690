import React, { createRef } from 'react';
import { GroupDTO } from '../../../../serverapi/api';
import { Button, FormInstance } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import formMessages from '../../../../models/formDefault.messages';
import GroupMainDataComponent from '../../GroupMainData/components/GroupMainData.component';
import GroupUsersContainer from '../../GroupUsers/containers/GroupUsers.container';
import theme from '../../UserManagement/components/UserManagment.scss';
import admTheme from '../../style/AdminTools.scss';
import { TGroupDataEditingTabActionProps, TGroupDataEditingTabProps } from '../GroupDataEditingTab.types';
import { convertStringToArray } from '../../../../utils/convertStringToArray';

type TGroupDataEditingTabFullProps = TGroupDataEditingTabProps &
    TGroupDataEditingTabActionProps &
    WrappedComponentProps;

type TUserDataEditingState = {
    editingGroupData: GroupDTO;
    isNewGroup: boolean;
};

class GroupDataEditingTab extends React.Component<TGroupDataEditingTabFullProps> {
    state: TUserDataEditingState = {
        editingGroupData: this.props.groupData || ({} as GroupDTO),
        isNewGroup: !this.props.groupData || !this.props.groupData.id,
    };

    formRef = createRef<FormInstance>();

    componentDidMount() {
        if (this.props.groupData.id) {
            this.props.fetchGroupFullData(this.props.groupData.id);
        }
    }

    validateFields = (callBack: Function) => {
        const form = this.formRef.current;
        if (form) {
            form.validateFields()
                .then((formValues) => {
                    const groupData: GroupDTO = {
                        ...this.state.editingGroupData,
                        groupName: formValues.groupName.trim(),
                        description: formValues.description?.trim(),
                        synonyms: convertStringToArray(formValues.synonyms || ''),
                    };
                    this.setState({
                        editingGroupData: {
                            ...groupData,
                        },
                    });
                    callBack(groupData, this.state.isNewGroup);
                })
                .catch((error) => console.error('error', error));
        }
    };

    handleSubmit = () => {
        this.validateFields(this.props.onSubmit);
    };

    onFieldChange = () => {
        this.validateFields(this.props.onChangeMainData);
    };

    render() {
        const { intl, tab, groupData } = this.props;

        return (
            <div className={theme.container}>
                <div className={admTheme.containerInner} style={{ height: 'calc(100% - 45px' }}>
                    <div className={theme.rightBorder}>
                        <div className={theme.groupDataContainer}>
                            <GroupMainDataComponent
                                groupData={groupData}
                                formRef={this.formRef}
                                onChangeMainData={this.onFieldChange}
                            />
                        </div>
                    </div>
                    <div className={admTheme.m10}>
                        <GroupUsersContainer groupId={`${groupData.id}`} />
                    </div>
                </div>
                <div className={admTheme.admEditingTabFooter}>
                    <Button key="cancel" size="large" onClick={() => this.props.onClose(tab)}>
                        {intl.formatMessage(formMessages.cancelButton)}
                    </Button>
                    <Button
                        data-test="user-managment-group_save-adding-user-to-group"
                        key="ok"
                        size="large"
                        type="primary"
                        onClick={this.handleSubmit}
                    >
                        {intl.formatMessage(formMessages.saveButtonLabel)}
                    </Button>
                </div>
            </div>
        );
    }
}

const withIntl = injectIntl(GroupDataEditingTab);

export default withIntl;
