import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'PrintDialog.title',
        defaultMessage: 'Печать модели',
    },
    model: {
        id: 'PrintDialog.model',
        defaultMessage: 'Печать всей модели',
    },
    selection: {
        id: 'PrintDialog.selection',
        defaultMessage: 'Печать выделенного фрагмента',
    },
    paperFormat: {
        id: 'PrintDialog.paperFormat',
        defaultMessage: 'Размер бумаги',
    },
    millimeters: {
        id: 'PrintDialog.millimeters',
        defaultMessage: 'мм',
    },
    orientation: {
        id: 'PrintDialog.orientation',
        defaultMessage: 'Ориентация',
    },
    auto: {
        id: 'PrintDialog.auto',
        defaultMessage: 'Авто',
    },
    portrait: {
        id: 'PrintDialog.portrait',
        defaultMessage: 'Книжная',
    },
    landscape: {
        id: 'PrintDialog.landscape',
        defaultMessage: 'Альбомная',
    },
    zoom: {
        id: 'PrintDialog.zoom',
        defaultMessage: 'Масштаб модели',
    },
    scale: {
        id: 'PrintDialog.scale',
        defaultMessage: 'Пользовательский масштаб (30% - 300%)',
    },
    fit: {
        id: 'PrintDialog.fit',
        defaultMessage: 'Уместить модель на',
    },
    across: {
        id: 'PrintDialog.across',
        defaultMessage: 'страниц в ширину',
    },
    down: {
        id: 'PrintDialog.down',
        defaultMessage: 'страниц в высоту',
    },
    grayscale: {
        id: 'PrintDialog.grayscale',
        defaultMessage: 'Печать в градациях серого (чёрно-белая печать)',
    },
    offset: {
        id: 'PrintDialog.offset',
        defaultMessage: 'Отступы от края холста',
    },
    print: {
        id: 'PrintDialog.print',
        defaultMessage: 'Печать',
    },
    preview: {
        id: 'PrintDialog.preview',
        defaultMessage: 'Просмотр',
    },
    cancel: {
        id: 'PrintDialog.cancel',
        defaultMessage: 'Отмена',
    },
});
