import { NodeId } from '../serverapi/api';
import { TreeNode, TTreeEntityState } from '../models/tree.types';
import { TreeItemType } from '../modules/Tree/models/tree';

export enum ExpandStatus {
    OPEN = 'open',
    CLOSED = 'closed',
    LOADING = 'loading',
}

type TypeData<T> = {
    byRepositoryId: {
        [repositoryId: string]: {
            byId: {
                [id: string]: T;
            };
        };
    };
};

export type TTreeState = {
    byServerId: {
        [serverId: string]: TypeData<TTreeEntityState>;
    };
    appliedFilters: TreeItemType[];
    showDeletedObjectsFilter: boolean;
    renamingId: NodeId;
    expanded: {
        [componentName: string]: {
            [serverId: string]: {
                [repositoryId: string]: {
                    [id: string]: {
                        expand: ExpandStatus;
                    };
                };
            };
        };
    };
    draggedId?: NodeId;
    scrolledNodeId?: NodeId;
    selectedNode?: TreeNode; // выделенная строка одиночным кликом без шифта или прошлая выделенная строка если зажат шифт
    selectedNodes: TreeNode[];
    error?: string;
};
