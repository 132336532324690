import {
    MxCell,
    MxClient,
    MxConstants,
    MxDragSource,
    MxEvent,
    MxPoint,
    MxShape,
    MxStencil,
    MxStencilRegistry,
    MxUtils,
    MxRectangle,
    MxCellRenderer,
} from '../mxgraph';
import { Symbol } from '../../serverapi/api';
import { BPMMxGraph } from '../bpmgraph';
import { PictureSymbolConstants } from '../../models/pictureSymbolConstants';
import { KeyCodes } from '../../utils/keys';
import { calculateRelativeCoordinatesForFoldedElement } from '../../utils/bpm.mxgraph.utils';
import { compareSize } from './BPMMxDragSource.utils';
import { SymbolType } from '@/models/Symbols';
import { BPMMxCellHighlight } from '../handler/BPMMxCellHighlight';
import { sequenceShapeBySymbolId } from '../ComplexSymbols/symbols/ComplexSymbol.constants';

const createPreviewFromSymbol = (symbol: Symbol, scale: number = 1, currentStencil?: MxStencil) => {
    const svg = document.createElementNS(MxConstants.NS_SVG, 'svg');
    const g = document.createElementNS(MxConstants.NS_SVG, 'g');
    svg.appendChild(g);

    if (symbol) {
        let stencil: MxStencil = MxStencilRegistry.getStencil(symbol.id);
        if (!stencil) {
            const xml: Document = MxUtils.parseXml(symbol.graphical);
            stencil = new MxStencil(xml.firstChild);
        }

        const width = symbol.width || currentStencil?.w0 || stencil.w0 || 100;
        const height = symbol.height || currentStencil?.h0 || stencil.h0 || 100;

        if (symbol.symbolTypeId && sequenceShapeBySymbolId[symbol.symbolTypeId]) {
            const shapeId = sequenceShapeBySymbolId[symbol.symbolTypeId];
            const shape: MxShape | undefined = new MxCellRenderer.defaultShapes[shapeId]();
            if (shape) {
                shape.pointerEvents = false;
                shape.init(g);
                shape.redrawShape();
                const shapeWidth = shape.bounds.width || width;
                const shapeHeight = shape.bounds.height || height;
                svg.style.width = `${Math.round(shapeWidth * scale)}px`;
                svg.style.height = `${Math.round(shapeHeight * scale)}px`;
                svg.style.overflow = 'visible';

                return svg;
            }
        }

        if (stencil) {
            if (!stencil.bgNode && !stencil.fgNode) {
                const divElem = document.createElement('div');
                divElem.style.border = 'dashed black 1px';
                divElem.style.width = `${Math.round(width * scale)}px`;
                divElem.style.height = `${Math.round(height * scale)}px`;

                return divElem;
            }
            const shape = new MxShape(stencil);

            shape.pointerEvents = false;
            shape.init(g);
            const canvas = shape.createCanvas();

            canvas.setStrokeColor('#000000');
            stencil.drawShape(canvas, stencil, 0, 0, width, height);
            svg.style.width = `${Math.round(width * scale)}px`;
            svg.style.height = `${Math.round(height * scale)}px`;
            svg.style.overflow = 'visible';
        }
    }

    return svg;
};

type TBPMMxDropHandler = (
    graph: BPMMxGraph,
    evt: PointerEvent,
    target: MxCell,
    point: MxPoint,
    elementOffsetPoint?: MxPoint,
) => void;

export class BPMMxDragSource extends MxDragSource {
    highlightDropTargets = true;
    dragOffset = new MxPoint(0, MxConstants.TOOLTIP_VERTICAL_OFFSET);

    protected stencil: MxStencil;
    protected graph: BPMMxGraph;
    protected symbol: Symbol;
    protected serverUrl: string;
    private width: number;
    private height: number;
    private DEFAULT_IMAGE_DIMENSION: number;

    constructor(symbol: Symbol, dropHandler: TBPMMxDropHandler, graph: BPMMxGraph, serverUrl?: string) {
        const previewElem = createPreviewFromSymbol(symbol);

        super(previewElem, (currentGraph, evt, dropTarget, x, y) => {
            const { target, point, elementOffsetPoint } = this.getDropPosition(evt, x, y);

            return dropHandler(currentGraph, evt, target, point, elementOffsetPoint);
        });

        this.symbol = symbol;
        this.stencil = symbol && MxStencilRegistry.getStencil(symbol.id);
        this.graph = graph;
        this.serverUrl = serverUrl!;
        this.DEFAULT_IMAGE_DIMENSION = 300;
        let offset = new MxPoint(
            -(symbol.width || this.stencil?.w0 || 0) / 2,
            -(symbol.height || this.stencil?.h0 || 0) / 2,
        );
        if (symbol.symbolTypeId && sequenceShapeBySymbolId[symbol.symbolTypeId]) {
            const shapeId = sequenceShapeBySymbolId[symbol.symbolTypeId];
            const shape: MxShape | undefined = new MxCellRenderer.defaultShapes[shapeId]();
            if (shape) {
                const shapeWidth = shape.bounds.width;
                const shapeHeight = shape.bounds.height;
                offset = new MxPoint(-shapeWidth / 2, -shapeHeight / 2);
            }
        }
        this.dragOffset = offset;
        this.previewOffset = offset;
    }

    getWidth() {
        return this.width || this.DEFAULT_IMAGE_DIMENSION;
    }

    getHeight() {
        return this.height || this.DEFAULT_IMAGE_DIMENSION;
    }

    emulatePointerEvent() {
        this.element.dispatchEvent(new Event(MxClient.IS_POINTER ? 'pointerdown' : 'mousedown'));
        document.addEventListener('keydown', this.escHandler);
    }

    stopDrag() {
        super.stopDrag();
        document.removeEventListener('keydown', this.escHandler);
        this.graph.container.style.cursor = 'auto';
    }

    getGraphForEvent() {
        return this.graph;
    }

    getDropTarget(graph: BPMMxGraph, x: number, y: number, evt: MouseEvent) {
        if (this.symbol.id === SymbolType.COMMENT) {
            const cell: MxCell | null = this.graph.getCellAt(x, y);
            if (cell && !cell.isEdge()) {
                return this.graph.getMainPartCellOrDefault(cell);
            }
        }

        // eslint-disable-next-line prefer-rest-params
        const cell = super.getDropTarget.apply(this, arguments);

        return this.graph.getDropTarget([], evt, cell, false);
    }

    getRoundedSize(cb: number): number {
        return Math.round(cb * this.graph.view.scale);
    }

    createPreviewElement(): HTMLElement | null {
        const elt = document.createElement('div');

        if (!this.symbol && !this.stencil) {
            return null;
        }
        if (this.symbol && this.symbol.id === PictureSymbolConstants.PICTURE_SYMBOL_ID) {
            const img = document.createElement('img');
            img.onload = () => {
                this.width = img.naturalWidth;
                this.height = img.naturalHeight;
                img.style.width = '100%';
                img.style.height = 'auto';
                elt.appendChild(img);
                elt.style.width = `${compareSize(
                    this.getRoundedSize(this.getWidth()),
                    this.getRoundedSize(this.getHeight()),
                    this.getRoundedSize(this.DEFAULT_IMAGE_DIMENSION),
                    'width',
                )}px`;
                elt.style.height = `${compareSize(
                    this.getRoundedSize(this.getWidth()),
                    this.getRoundedSize(this.getHeight()),
                    this.getRoundedSize(this.DEFAULT_IMAGE_DIMENSION),
                    'height',
                )}px`;
            };
            // tslint:disable-next-line:max-line-length

            img.setAttribute(
                'src',
                `${this.serverUrl}/${PictureSymbolConstants.DOWNLOAD_LINK}/${this.symbol.graphical}`,
            );
        } else {
            return createPreviewFromSymbol(this.symbol, this.graph.view.scale, this.stencil);
        }

        return elt;
    }

    getSwimlaneAtPointerEvent(event: PointerEvent) {
        const pt = MxUtils.convertPoint(this.graph.container, MxEvent.getClientX(event), MxEvent.getClientY(event));
        pt.x -= this.graph.panDx;
        pt.y -= this.graph.panDy;

        return this.graph.getSwimlaneAt(pt.x, pt.y);
    }

    getDropPosition(evt: PointerEvent, x: number, y: number) {
        let target: MxCell = this.graph.getDefaultParent();
        // x, y - это абсолютные координаты скорректированные под направляющие если они отрисованы, иначе координаты прямо под курсором
        let point: MxPoint = new MxPoint(Math.max(x, 0), Math.max(y, 0));
        let elementOffsetPoint: MxPoint = new MxPoint(Math.max(x, 0), Math.max(y, 0));

        if (this.symbol.id === SymbolType.COMMENT) {
            const cell = this.graph.getCellAt(x, y);
            if (cell) {
                target = this.graph.getMainPartCellOrDefault(cell);
                elementOffsetPoint = calculateRelativeCoordinatesForFoldedElement(
                    target,
                    this.graph.getDefaultParent(),
                    this.graph,
                    point,
                );

                return { target, point, elementOffsetPoint };
            }
        }

        const swimlane = this.getSwimlaneAtPointerEvent(evt);
        const isValidDropTarget = this.graph.isValidCommonDropTarget(swimlane, []);

        if (isValidDropTarget) {
            point = calculateRelativeCoordinatesForFoldedElement(swimlane, target, this.graph, point);
            target = swimlane;
        }

        return { target, point, elementOffsetPoint };
    }

    private escHandler = (e: KeyboardEvent) => {
        if (e.keyCode === KeyCodes.ESCAPE) {
            if (super.isActive()) {
                super.reset();
            }
            document.removeEventListener('keydown', this.escHandler);
        }
    };

    dragEnter = (graph: BPMMxGraph, evt: PointerEvent) => {
        super.dragEnter(graph, evt);
        if (this.symbol.id === SymbolType.COMMENT) {
            this.currentGuide.horizontal = false;
            this.currentGuide.vertical = false;
            this.currentGuide.distance = false;
        }
        if (this.highlightDropTargets) {
            this.currentHighlight = new BPMMxCellHighlight(graph, MxConstants.DROP_TARGET_COLOR);
        }
    };

    dragOver = (graph: BPMMxGraph, evt: PointerEvent) => {
        const offset = MxUtils.getOffset(graph.container);
        const origin = MxUtils.getScrollOrigin(graph.container);
        let x = MxEvent.getClientX(evt) - offset.x + origin.x - graph.panDx;
        let y = MxEvent.getClientY(evt) - offset.y + origin.y - graph.panDy;

        if (graph.autoScroll && (this.autoscroll == null || this.autoscroll)) {
            graph.scrollPointToVisible(x, y, graph.autoExtend);
        }

        // Highlights the drop target under the mouse
        if (this.currentHighlight != null && (graph.isDropEnabled() || this.symbol.id === SymbolType.COMMENT)) {
            this.currentDropTarget = this.getDropTarget(graph, x, y, evt);
            const state = graph.getView().getState(this.currentDropTarget);

            this.currentHighlight.highlight(state);
        }

        // Updates the location of the preview
        if (this.previewElement != null) {
            if (this.previewElement.parentNode == null) {
                graph.container.appendChild(this.previewElement);

                this.previewElement.style.zIndex = '3';
                this.previewElement.style.position = 'absolute';
            }

            const gridEnabled = this.isGridEnabled() && graph.isGridEnabledEvent(evt);
            let hideGuide = true;

            // Grid and guides
            if (this.currentGuide != null && this.currentGuide.isEnabledForEvent(evt)) {
                // LATER: HTML preview appears smaller than SVG preview
                const w = parseInt(this.previewElement.style.width, 10);
                const h = parseInt(this.previewElement.style.height, 10);
                const bounds = new MxRectangle(0, 0, w, h);

                if (this.previewOffset != null) {
                    x += this.previewOffset.x;
                    y += this.previewOffset.y;
                }
                let delta = new MxPoint(x, y);
                delta = this.currentGuide.move(bounds, delta, gridEnabled, true);
                hideGuide = false;
                x = delta.x;
                y = delta.y;
            } else if (gridEnabled) {
                const { scale } = graph.view;
                const tr = graph.view.translate;
                const off = graph.gridSize / 2;
                x = (graph.snap(x / scale - tr.x - off) + tr.x) * scale;
                y = (graph.snap(y / scale - tr.y - off) + tr.y) * scale;
            }

            if (this.currentGuide != null && hideGuide) {
                this.currentGuide.hide();
            }

            this.previewElement.style.left = `${Math.round(x)}px`;
            this.previewElement.style.top = `${Math.round(y)}px`;
            this.previewElement.style.visibility = 'visible';
        }
        this.currentPoint = new MxPoint(x, y);
    };

    startDrag = (evt: PointerEvent) => {
        this.graph.container.style.cursor = 'pointer';
        this.dragElement = this.createDragElement(evt);
        this.dragElement.style.position = 'absolute';
        this.dragElement.style.zIndex = this.dragElementZIndex;
        MxUtils.setOpacity(this.dragElement, this.dragElementOpacity);
        if (this.checkEventSource && MxClient.IS_SVG) {
            this.dragElement.style.pointerEvents = 'none';
        }
    };
}
