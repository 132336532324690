import React, { ReactNode } from 'react';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import 'react-reflex/styles.css';
import theme from './Navigator.scss';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { TNavigatorTab } from '../../../../reducers/navigator.reducer.types';
import icNavigator from '../../../../resources/icons/ic-sidemenu-navigator.svg';
import icProperties from '../../../../resources/icons/ic-sidemenu-properties.svg';
import icSymbols from '../../../../resources/icons/ic-sidemenu-symbols.svg';
import icOutline from '../../../../resources/icons/ic-sidemenu-outline.svg';
import icComments from '../../../../resources/icons/ic-sidemenu-comments.svg';
import { homePageTabId } from '../../../../models/home-page';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { NavigatorStructureContainer } from '../../containers/NavigatorStructure.container';
import { NavigatorSymbolsContainer } from '../../containers/NavigatorSymbols.container';
import { NavigatorPropertiesContainer } from '../../containers/NavigatorProperties.container';
import { NavigatorOutlineContainer } from '../../containers/NavigatorOutline.container';
import { isEqual } from 'lodash';
import { Tooltip } from 'antd';
import messages from '../../messages/Navigator.messages';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { TNavigatorActionsProps, TNavigatorStoreProps } from './Navigator.types';
import { NAVIGATOR_CONTAINER_ID } from '../../../../mxgraph/components/BPMPopupMenu.types';
import { instancesBPMMxGraphMap } from '../../../../mxgraph/bpm-mxgraph-instance-map';
import { BPMMxGraph } from '../../../../mxgraph/bpmgraph';

type TNavigatorProps = TNavigatorStoreProps & WrappedComponentProps & TNavigatorActionsProps;

const positionPanel = {
    [TNavigatorTab.Tree]: 0,
    [TNavigatorTab.Symbols]: 1,
    [TNavigatorTab.Properties]: 2,
    [TNavigatorTab.Outline]: 3,
};

function getPanel(activeTabs: TNavigatorTab[] = []) {
    const panels = {
        [TNavigatorTab.Tree]: {
            direction: 1,
            component: <NavigatorStructureContainer />,
            minSize: 40,
        },
        [TNavigatorTab.Symbols]: {
            direction: -1,
            component: <NavigatorSymbolsContainer />,
            minSize: 40,
        },
        [TNavigatorTab.Properties]: {
            direction: -1,
            component: <NavigatorPropertiesContainer />,
            minSize: 40,
        },
        [TNavigatorTab.Outline]: {
            direction: -1,
            component: <NavigatorOutlineContainer />,
            minSize: 40,
            maxSize: activeTabs.length !== 1 ? 190 : 100000,
        },
    };

    return activeTabs
        .sort((a, b) => positionPanel[a] - positionPanel[b])
        .map((item: TNavigatorTab) => {
            const panel = panels[item];
            const size = 100000;

            return (
                <ReflexElement
                    size={size}
                    minSize={panel.minSize}
                    key={`reflexPanel_${item}`}
                    direction={panel.direction}
                >
                    {panel.component}
                </ReflexElement>
            );
        });
}

const Navigator = (props: TNavigatorProps) => {
    const {
        activeTabs = [],
        activeSchemeId,
        onCommentsOpen,
        onCommentsClose,
        scheme,
        commentsEnabledSchemesIds,
        intl,
    } = props;

    const panels = getPanel(activeTabs);
    const isCommentBtnDisabled = !(scheme?.content?.type === 'MODEL' || scheme?.content?.type === 'WIKI');
    const isSpreadsheet = scheme?.content?.type === 'SPREADSHEET';
    const isTreeTabActive = activeTabs.includes(TNavigatorTab.Tree);
    const isSymbolsTabActive = activeTabs.includes(TNavigatorTab.Symbols);
    const isPropertiesTabActive = activeTabs.includes(TNavigatorTab.Properties);
    const isOutlineTabActive = activeTabs.includes(TNavigatorTab.Outline);
        const isCommentsTabActive =
        !!activeSchemeId && commentsEnabledSchemesIds.some((id) => compareNodeIds(id, activeSchemeId));

    const onClick = (isActive: boolean, item: TNavigatorTab) => () =>
        isActive ? props.onTabDeselect && props.onTabDeselect(item) : props.onTabSelect && props.onTabSelect(item);
    const onCommentsPanelClick = () => {
        if (scheme && scheme.content && scheme.nodeId !== homePageTabId && !scheme.nodeId.id.includes('loading')) {
            if (isCommentsTabActive) {
                let graph: BPMMxGraph | undefined = undefined;
                if (activeSchemeId) graph = instancesBPMMxGraphMap.get(activeSchemeId);
                graph?.tooltipHandler.close();
                onCommentsClose(activeSchemeId);
            } else {
                onCommentsOpen(activeSchemeId);
            }
        }
    };

    const { length } = panels;

    const content = panels.reduce((acc: ReactNode[], panel, index) => {
        const addedElements = [panel];

        if (index !== length - 1) {
            addedElements.push(<ReflexSplitter className={theme.splitter} key={`splitter${uuid()}`} />);
        }

        return [...acc, ...addedElements];
    }, []);

    return (
        <div className={theme.container} id={NAVIGATOR_CONTAINER_ID}>
            <div className={theme.navTabs}>
                <Tooltip mouseLeaveDelay={0} placement="right" title={intl.formatMessage(messages.structure)}>
                    <div
                        id="treePanelOpenButton"
                        data-test="navigator-panel_toggle-button"
                        className={`${theme.navItem} ${isTreeTabActive ? theme.navItemSelected : ''}`}
                        onClick={onClick(isTreeTabActive, TNavigatorTab.Tree)}
                    >
                        <Icon spriteSymbol={icNavigator} />
                    </div>
                </Tooltip>
                <Tooltip mouseLeaveDelay={0} placement="right" title={intl.formatMessage(messages.symbols)}>
                    <div
                        id="symbolPanelOpenButton"
                        data-test="symbol-panel_toggle-button"
                        className={`${theme.navItem} ${isSymbolsTabActive ? theme.navItemSelected : ''} ${
                            isSpreadsheet ? theme.navItemDisabled : ''
                        }`}
                        onClick={isSpreadsheet ? () => {} : onClick(isSymbolsTabActive, TNavigatorTab.Symbols)}
                    >
                        <Icon spriteSymbol={icSymbols} disabled={isSpreadsheet} />
                    </div>
                </Tooltip>
                <Tooltip mouseLeaveDelay={0} placement="right" title={intl.formatMessage(messages.properties)}>
                    <div
                        id="propertiesPanelOpenButton"
                        data-test="properties-panel_toggle-button"
                        className={`${theme.navItem} ${isPropertiesTabActive ? theme.navItemSelected : ''}`}
                        onClick={onClick(isPropertiesTabActive, TNavigatorTab.Properties)}
                    >
                        <Icon spriteSymbol={icProperties} />
                    </div>
                </Tooltip>
                <Tooltip mouseLeaveDelay={0} placement="right" title={intl.formatMessage(messages.outline)}>
                    <div
                        id="outlinePanelOpenButton"
                        data-test="map-panel_toggle-button"
                        className={`${theme.navItem} ${isOutlineTabActive ? theme.navItemSelected : ''} ${
                            isSpreadsheet ? theme.navItemDisabled : ''
                        }`}
                        onClick={isSpreadsheet ? () => {} : onClick(isOutlineTabActive, TNavigatorTab.Outline)}
                    >
                        <Icon spriteSymbol={icOutline} disabled={isSpreadsheet} />
                    </div>
                </Tooltip>
                <Tooltip mouseLeaveDelay={0} placement="right" title={intl.formatMessage(messages.comments)}>
                    <div
                        id="commentsPanelOpenButton"
                        data-test="comment-panel_toggle-button"
                        className={`${theme.navItem} ${isCommentsTabActive ? theme.navItemSelected : ''} ${
                            isCommentBtnDisabled ? theme.navItemDisabled : ''
                        }`}
                        onClick={!isCommentBtnDisabled ? onCommentsPanelClick : undefined}
                    >
                        <Icon spriteSymbol={icComments} disabled={isCommentBtnDisabled} />
                    </div>
                </Tooltip>
            </div>
            <div className={theme.panel}>
                <ReflexContainer orientation="horizontal">{content}</ReflexContainer>
            </div>
        </div>
    );
};

Navigator.defaultProps = {
    activeTabs: [],
};

const areEqual = (prevProps: TNavigatorProps, nextProps: TNavigatorProps): boolean => {
    if (prevProps?.activeSchemeId?.id !== nextProps?.activeSchemeId?.id) return false;
    if (!isEqual(prevProps.activeTabs, nextProps.activeTabs)) return false;
    if (prevProps.scheme?.nodeId.id !== nextProps.scheme?.nodeId.id) return false;
    if (!isEqual(prevProps?.activeSchemeId, nextProps?.activeSchemeId)) return false;
    if (!isEqual(prevProps.commentsEnabledSchemesIds, nextProps.commentsEnabledSchemesIds)) return false;

    return true;
};

const withMemo = injectIntl(React.memo(Navigator, areEqual));

export { withMemo as Navigator };
