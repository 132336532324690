import React, { ChangeEvent, FC } from 'react';
import { Form, Input } from 'antd';
import messages from './InputId.messages';
import { useIntl } from 'react-intl';
import theme from './InputId.scss';
import { MAX_GUID_LENGTH } from '../../utils/consts';
import { Rule } from 'antd/es/form';

type TInputIdProps = {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    required: boolean;
    disabled: boolean;
    mainInputName: string;
};

const unacceptableCharacters = '"\'\\#[]?%;.';

export const idValidator = (value: string = '') =>
    !value.split('').find((item) => unacceptableCharacters.includes(item));

const antdIdValidator = (_, val: string) => (idValidator(val) ? Promise.resolve() : Promise.reject());

export const InputId: FC<TInputIdProps> = (props) => {
    const { disabled, required, onChange, value, mainInputName } = props;
    const intl = useIntl();

    const rules: Rule[] = [
        {
            required,
            whitespace: true,
            message: intl.formatMessage(messages.requiredField),
        },
        {
            max: MAX_GUID_LENGTH,
            message: intl.formatMessage(messages.maxCharacters),
        },
    ];

    if (!disabled)
        rules.push({
            required: true,
            message: intl.formatMessage(messages.unacceptableCharacters),
            validator: antdIdValidator,
        });

    return (
        <div>
            <Form.Item
                className={theme.formItem}
                label={intl.formatMessage(messages.id)}
                name={mainInputName}
                initialValue={value}
                rules={rules}
            >
                <Input disabled={disabled} onChange={onChange} />
            </Form.Item>
        </div>
    );
};
