import React, { FC, useState } from 'react';
import theme from './ObjectPropertiesDialog.scss';
import { Form, Input, Modal } from 'antd';
import footerMessages from '../../AdminTools/Methodology/components/Presets/Footer/EditorFooterButtons.messages';
import messages from '../messages/ObjectPropertiesDialog.messages';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { useIntl } from 'react-intl';
import { TAttributeUrlValues } from './AttributeTab.types';
import { TValueTypeEnum } from '../../../models/ValueTypeEnum.types';
import { AttributeValue, AttributeValueUrl } from '../../../serverapi/api';
import { shouldCallModalSubmitHandler } from '../../../services/utils/ModalHelper';

type TUrlValidateFieldValues = {
    urlRu: string;
    urlEn: string;
    nameRu: string;
    nameEn: string;
};

type TMultiLangStringDialogProps = {
    handleOk: (
        recordId: string,
        valueType: TValueTypeEnum,
        changedValues: TAttributeUrlValues,
        isInternationalString: boolean,
    ) => void;
    record: AttributeValue;
};

export const MultiLangUrlDialogComponent: FC<TMultiLangStringDialogProps> = ({ handleOk, record }) => {
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();
    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const onValidateFields = () => {
        form.validateFields().then((formValues: TUrlValidateFieldValues) => {
            const { nameRu, nameEn, urlRu, urlEn } = formValues;
            const changedValues: TAttributeUrlValues = {
                name: { ru: nameRu.trim(), en: nameEn.trim() },
                url: { ru: urlRu, en: urlEn },
            };
            handleOk(record.id, record.valueType, changedValues, true);
            setIsModalVisible(false);
        });
    };

    const showModal = (attrValue: AttributeValueUrl) => {
        const { url, name } = attrValue;

        form.resetFields();
        form.setFieldsValue({
            urlEn: url?.en || '',
            urlRu: url?.ru || '',
            nameEn: name?.en || '',
            nameRu: name?.ru || '',
        });

        setIsModalVisible(true);
    };

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    onValidateFields();
                }
            }}
        >
            <span className={theme.attribute_openMultiLangBtn} onClick={() => showModal(record)}>
                <u data-test="URL_change-links-for-two-locale">({currentLocale.toUpperCase()})</u>
            </span>
            <Modal
                title={intl.formatMessage(messages.multiLangUrlDialogHeader)}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={onValidateFields}
                okText={<span data-test="translation-link-window_button-ok">{intl.formatMessage(footerMessages.save)}</span> }
                cancelText={<span data-test="translation-link-window_button-cancel">{intl.formatMessage(footerMessages.cancel)}</span>}
                okButtonProps={{ size: 'large' }}
                cancelButtonProps={{ size: 'large' }}
            >
                <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} form={form}>
                    <Form.Item 
                        name="nameRu"
                        label={intl.formatMessage(messages.multiLangUrlDialogName)}
                        className={theme.formItem}
                    >
                        <Input data-test="attribute-LINK_name-RU_input"/>
                    </Form.Item>
                    <Form.Item
                        name="urlRu"
                        label={intl.formatMessage(messages.multiLangUrlDialogUrl)}
                        className={theme.formItem}
                    >
                        <Input data-test="attribute-LINK_URL-RU_input" />
                    </Form.Item>
                    <Form.Item name="nameEn" label="Name" className={theme.formItem}>
                        <Input data-test="attribute-LINK_name-EN_input"/>
                    </Form.Item>
                    <Form.Item name="urlEn" label="Url" className={theme.formItem}>
                        <Input data-test="attribute-LINK_URL-EN_input"/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
