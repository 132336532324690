import { put, select, takeEvery } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import { kanbanRemoveSuccess, kanbanAdd, kanbanRename } from '../actions/entities/kanban.actions';
import {
    TKanbanDefaultAction,
    TKanbanMoveItemAction,
    TKanbanOpenByIdAction,
} from '../actions/entities/kanban.actions.types';
import { modelDialogSubmitResult } from '../actions/modelDialog.actions';
import { recentAddModel } from '../actions/recent.actions';
import { workspaceAddTab } from '../actions/tabs.actions';
import { TWorkspaceTabsRemoveAction } from '../actions/tabs.actions.types';
import { treeItemChildAdd } from '../actions/tree.actions';
import { KANBAN_CREATE, KANBAN_MOVE_ITEM, KANBAN_OPEN_BY_ID } from '../actionsTypes/entities/kanban.actionTypes';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../actionsTypes/tabs.actionTypes';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { IWorkspaceTabItemKanbanParams, TWorkspaceTab } from '../models/tab.types';
import { TreeNode } from '../models/tree.types';
import { TreeItemType } from '../modules/Tree/models/tree';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { KanbanModelTypeSelectors } from '../selectors/kanbanModelType.selectors';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { TreeSelectors } from '../selectors/tree.selectors';
import {
    AttributeType,
    KanbanBoardType,
    KanbanCardType,
    KanbanNode,
    ObjectDefinitionNode,
    Symbol,
} from '../serverapi/api';
import { KanbanDaoService } from '../services/dao/KanbanDaoService';
import { ObjectDefinitionsDAOService } from '../services/dao/ObjectDefinitionsDAOService';
import { SymbolSelectors } from '../selectors/symbol.selectors';
import { KanbanCardTypeSelectors } from '../selectors/kanbanCardType.selectors';
import { v4 as uuid } from 'uuid';
import { Locale } from '../modules/Header/components/Header/header.types';
import {
    KANBAN_COLUMN_ITEM_TYPE,
    KanbanBoardObjectChangingConditionType,
    KanbanObjectChangingActionType,
    TKanbanState,
} from '../models/kanban.types';
import { AttributeTypeSelectors } from '../selectors/attributeType.selectors';
import { KanbanSelectors } from '../selectors/kanban.selectors';
import { objectDefinitionsAdd } from '../actions/entities/objectDefinition.actions';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { KanbanBoardObjectChangingAction } from '../serverapi/api';
import { TREE_ITEM_TITLE_CHANGE_REQUEST } from '../actionsTypes/tree.actionTypes';
import { TTreeItemTitleChangeRequestAction } from '../actions/tree.actions.types';
import { KanbanBll } from '@/services/bll/KanbanBllService';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';

function* openKanban(kanbanNode: KanbanNode) {
    const presetId: string = yield select(TreeSelectors.presetById(kanbanNode.parentNodeId));
    const serverId: string = kanbanNode.nodeId.serverId;
    const modelType: KanbanBoardType | undefined = yield select(
        KanbanModelTypeSelectors.byId(kanbanNode.modelTypeId!, presetId),
    );
    if (!modelType) return;

    const currentLocale: Locale = yield select(getCurrentLocale);
    const attributeTypes: AttributeType[] = yield select(AttributeTypeSelectors.allInPreset(serverId, presetId)) || [];

    // временно - получить все objectDefinitionNodes (потом будет отдельный запрос на бэк)
    const objectDefinitionNodes: ObjectDefinitionNode[] =
        yield ObjectDefinitionsDAOService.getObjectDefinitionWithChildren({
            serverId,
            id: kanbanNode.nodeId.repositoryId,
            repositoryId: kanbanNode.nodeId.repositoryId,
        });
    // временно

    const allSymbols: Symbol[] = yield select(SymbolSelectors.byServerIdPresetId(serverId, presetId));
    const availableSymbols = allSymbols.filter((symbol) =>
        modelType.availableSymbolsCardTypesIds.symbolsIds.includes(symbol.id),
    );
    const availableKanbanCardTypes: KanbanCardType[] = yield select(
        KanbanCardTypeSelectors.byIds({
            kanbanCardTypeIds: modelType.availableSymbolsCardTypesIds.cardTypesIds,
            presetId,
        }),
    );
    const availableObjectTypeIds: string[] = [
        ...availableSymbols.map((symbol) => symbol.objectType),
        ...availableKanbanCardTypes.map((card) => card.objectTypeId),
    ];
    const availableObjectDefinitionNodes = objectDefinitionNodes.filter((obj) =>
        obj.objectTypeId ? availableObjectTypeIds.includes(obj.objectTypeId) : false,
    );

    yield put(objectDefinitionsAdd(availableObjectDefinitionNodes as ObjectDefinitionImpl[]));

    const kanban: TKanbanState = {
        node: kanbanNode,
        columnIdToColumnItemIdsMap: {},
        columnItems: {},
    };

    modelType.columnsSettings.columns.forEach((col) => (kanban.columnIdToColumnItemIdsMap[col.id] = []));

    availableObjectDefinitionNodes.forEach((objectDefinition) => {
        modelType.rules.placingRules.some((rule) => {
            const symbolKanbanCardType: Symbol | KanbanCardType | undefined =
                availableKanbanCardTypes.find((cardType) => cardType.id === rule.symbolCardTypeId) ||
                availableSymbols.find((symbol) => symbol.id === rule.symbolCardTypeId);
            if (!symbolKanbanCardType) return false;

            const isSymbol = modelType.availableSymbolsCardTypesIds.symbolsIds.includes(symbolKanbanCardType.id);
            const ruleObjectTypeId = isSymbol
                ? (symbolKanbanCardType as Symbol).objectType
                : (symbolKanbanCardType as KanbanCardType).objectTypeId;
            if (objectDefinition.objectTypeId !== ruleObjectTypeId) return false;

            const isConditionsMatched: boolean = rule.conditions.every((condition) => {
                return KanbanBll.checkIsRuleConditionMatched({
                    condition,
                    attributes: objectDefinition.attributes,
                    currentLocale,
                    attributeTypes,
                });
            });

            if (isConditionsMatched) {
                const newItemId = uuid();
                kanban.columnIdToColumnItemIdsMap[rule.columnId].push(newItemId);
                kanban.columnItems[newItemId] = {
                    id: newItemId,
                    symbolCardTypeId: symbolKanbanCardType.id,
                    objectDefinitionId: objectDefinition.nodeId.id,
                    type: isSymbol ? KANBAN_COLUMN_ITEM_TYPE.SYMBOL : KANBAN_COLUMN_ITEM_TYPE.KANBAN_CARD_TYPE,
                    columnId: rule.columnId,
                    allowedColumnIds: [],
                    forbiddenColumnIds: [],
                };
                const allowedColumnIds: string[] = [];

                modelType.rules.objectChangingRules.forEach((objectChangingrule) => {
                    const isConditionsMatched = KanbanBll.checkIsObjectChangingRuleMatched({
                        rule: objectChangingrule,
                        columnItem: kanban.columnItems[newItemId],
                        columnId: kanban.columnItems[newItemId].columnId,
                        isMoveItemMode: false,
                        objectDefinition,
                        currentLocale,
                        attributeTypes,
                    });

                    if (isConditionsMatched) {
                        const allowedColumnId = objectChangingrule.conditions.filter(
                            (condition) => condition.type === KanbanBoardObjectChangingConditionType.OBJECT_IN_COLUMN,
                        )[0].columnId;
                        allowedColumnIds.push(allowedColumnId!);
                    }
                });

                kanban.columnItems[newItemId].allowedColumnIds = allowedColumnIds;
                kanban.columnItems[newItemId].forbiddenColumnIds = Object.keys(
                    kanban.columnIdToColumnItemIdsMap,
                ).filter((colId) => !allowedColumnIds.includes(colId));
            }

            return isConditionsMatched;
        });
    });

    yield put(kanbanAdd({ kanban }));

    const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
        title: kanbanNode.name,
        type: WorkSpaceTabTypes.KANBAN,
        nodeId: kanbanNode.nodeId,
        content: kanbanNode,
        mode: EditorMode.Edit,
        params: {
            closable: true,
            serverId,
            modelType,
        } as IWorkspaceTabItemKanbanParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(workspaceTab));

    const modelTypeName = modelType?.multilingualName!;
    yield put(
        recentAddModel({
            nodeId: kanbanNode.nodeId,
            type: kanbanNode.type as TreeItemType,
            parentId: kanbanNode.parentNodeId!,
            createdAt: new Date().toISOString(),
            title: kanbanNode.name,
            modelTypeId: kanbanNode.modelTypeId,
            modelTypeName: (modelType && modelTypeName[currentLocale]) || '',
        }),
    );
}

function* handleKanbanMoveItem(action: TKanbanMoveItemAction) {
    const { kanbanNodeId, itemId, columnId } = action.payload;
    const kanbanState: TKanbanState = yield select(KanbanSelectors.byId(kanbanNodeId));
    const columnItem = kanbanState.columnItems[itemId];

    if (!columnItem.allowedColumnIds.includes(columnId)) return;

    const presetId: string = yield select(TreeSelectors.presetById(kanbanNodeId));
    const kanbanBoardType: KanbanBoardType = yield select(
        KanbanModelTypeSelectors.byId(kanbanState.node.modelTypeId!, presetId),
    );
    const currentLocale = yield select(getCurrentLocale);
    const attributeTypes: AttributeType[] = yield select(
        AttributeTypeSelectors.allInPreset(kanbanNodeId.serverId, presetId),
    ) || [];
    const objectDefinition: ObjectDefinitionNode = yield select(
        ObjectDefinitionSelectors.byId({
            id: kanbanState.columnItems[itemId].objectDefinitionId,
            repositoryId: kanbanNodeId.repositoryId,
            serverId: kanbanNodeId.serverId,
        }),
    );

    let objectChangingActions: KanbanBoardObjectChangingAction[] = [];

    kanbanBoardType.rules.objectChangingRules.some((rule) => {
        const isConditionsMatched = KanbanBll.checkIsObjectChangingRuleMatched({
            rule,
            columnItem,
            columnId,
            isMoveItemMode: true,
            objectDefinition,
            currentLocale,
            attributeTypes,
        });

        if (isConditionsMatched) {
            objectChangingActions = rule.actions;
            return true;
        }

        return false;
    });

    if (!objectChangingActions.length || !objectDefinition.attributes?.length) {
        return;
    }

    let updatedAttributes = [...objectDefinition.attributes];

    objectChangingActions.forEach((action) => {
        if (action.type === KanbanObjectChangingActionType.CHANGE_OBJECT_ATTRIBUTE) {
            const index = updatedAttributes.findIndex((attr) => attr.typeId === action.attributeTypeId);
            if (index === -1) return;
            updatedAttributes[index] = action.attributeValue;
        }
    });

    const changedObjectDefinition: ObjectDefinitionNode = yield ObjectDefinitionsDAOService.saveObjectDefinition(
        kanbanNodeId.serverId,
        { ...objectDefinition, attributes: updatedAttributes },
    );

    if (changedObjectDefinition) {
        yield put(objectDefinitionsAdd([changedObjectDefinition] as ObjectDefinitionImpl[]));
        const newKanbanState = cloneDeep(kanbanState);
        newKanbanState.columnIdToColumnItemIdsMap[columnItem.columnId] = newKanbanState.columnIdToColumnItemIdsMap[
            columnItem.columnId
        ].filter((id) => id !== columnItem.id);

        const isRuleMatched = kanbanBoardType.rules.placingRules.some((rule) => {
            if (columnItem.symbolCardTypeId !== rule.symbolCardTypeId) return false;

            const isConditionsMatched: boolean = rule.conditions.every((condition) => {
                return KanbanBll.checkIsRuleConditionMatched({
                    condition,
                    attributes: changedObjectDefinition.attributes,
                    currentLocale,
                    attributeTypes,
                });
            });

            if (isConditionsMatched) {
                const newColumnItem = { ...columnItem, columnId: rule.columnId };
                const allowedColumnIds: string[] = [];

                kanbanBoardType.rules.objectChangingRules.forEach((objectChangingrule) => {
                    const isConditionsMatched = KanbanBll.checkIsObjectChangingRuleMatched({
                        rule: objectChangingrule,
                        columnItem: newColumnItem,
                        columnId: newColumnItem.columnId,
                        isMoveItemMode: false,
                        objectDefinition: changedObjectDefinition,
                        currentLocale,
                        attributeTypes,
                    });

                    if (isConditionsMatched) {
                        const allowedColumnId = objectChangingrule.conditions.filter(
                            (condition) => condition.type === KanbanBoardObjectChangingConditionType.OBJECT_IN_COLUMN,
                        )[0].columnId;
                        allowedColumnIds.push(allowedColumnId!);
                    }
                });

                newColumnItem.allowedColumnIds = allowedColumnIds;
                newColumnItem.forbiddenColumnIds = Object.keys(newKanbanState.columnIdToColumnItemIdsMap).filter(
                    (colId) => !allowedColumnIds.includes(colId),
                );

                newKanbanState.columnItems[itemId] = newColumnItem;
                newKanbanState.columnIdToColumnItemIdsMap[rule.columnId].push(itemId);
            }

            return isConditionsMatched;
        });

        if (!isRuleMatched) {
            delete newKanbanState.columnItems[itemId];
        }

        yield put(kanbanAdd({ kanban: newKanbanState }));
    }
}

function* handleKanbanCreate(action: TKanbanDefaultAction) {
    const serverId = action.payload.kanban.nodeId.serverId;

    const kanbanNode: KanbanNode = yield KanbanDaoService.createKanban(serverId, action.payload.kanban);
    if (!kanbanNode) {
        yield put(modelDialogSubmitResult('error'));
        return;
    }
    yield put(modelDialogSubmitResult('success'));
    yield put(
        treeItemChildAdd({
            parentNodeId: kanbanNode.parentNodeId!,
            child: [kanbanNode as TreeNode],
        }),
    );

    yield openKanban(kanbanNode);
}

function* handleKanbanOpenByTreeNode(action: TKanbanOpenByIdAction) {
    const {
        nodeId: { serverId, repositoryId, id },
    } = action.payload;
    try {
        const kanbanNode: KanbanNode = yield KanbanDaoService.getKanbanById(serverId, repositoryId, id);

        if (kanbanNode) {
            const presetId: string = yield select(TreeSelectors.presetById(kanbanNode.parentNodeId));
            const modelType: KanbanBoardType | undefined = yield select(
                KanbanModelTypeSelectors.byId(kanbanNode.modelTypeId!, presetId),
            );
            if (!modelType) return;

            yield openKanban(kanbanNode);

            LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
        } else {
            LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        }
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

function* handleTabKanbanClose(action: TWorkspaceTabsRemoveAction) {
    const { workspaceTab } = action.payload;
    if (workspaceTab.content && workspaceTab.content.type === TreeItemType.Kanban) {
        yield put(kanbanRemoveSuccess(workspaceTab.nodeId));
    }
}

function* handleKanbanTitleChange(action: TTreeItemTitleChangeRequestAction) {
    const { nodeId, title } = action.payload;
    const treeNode: TreeNode = yield select(TreeSelectors.itemById(nodeId));

    if (treeNode.type === TreeItemType.Kanban) {
        const locale: Locale = yield select(getCurrentLocale);
        yield put(kanbanRename({ nodeId, title, locale }));
    }
}

export function* kanbanSaga() {
    yield takeEvery(KANBAN_CREATE, handleKanbanCreate);
    yield takeEvery(KANBAN_OPEN_BY_ID, handleKanbanOpenByTreeNode);
    yield takeEvery(KANBAN_MOVE_ITEM, handleKanbanMoveItem);
    yield takeEvery(TREE_ITEM_TITLE_CHANGE_REQUEST, handleKanbanTitleChange);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabKanbanClose);
}
