import { AttributeType, AttributeTypeGroup } from '../../serverapi/api';
import { apiBundle } from '../api/api-bundle';

export class AttributeTypesDaoService {
    public static async deleteAttributeTypes(serverId: string, attributeTypes: AttributeType[]): Promise<void> {
        const attributeTypeList: AttributeType[] = attributeTypes.map((at) => {
            return {
                id: at.id,
                presetId: at.presetId,
                name: '',
            };
        });

        await apiBundle(serverId).attributeType.bulkDelete({ body: attributeTypeList });
    }

    public static async attributeTypeGroupBulkDelete(
        serverId,
        attributeTypeGroups: AttributeTypeGroup[],
    ): Promise<void> {
        await apiBundle(serverId).attributeTypeGroup.bulkDelete({ body: attributeTypeGroups });
    }

    public static async saveAttributeType(serverId, attributeTypes: AttributeType[]): Promise<void> {
        await apiBundle(serverId).attributeType.bulkSave({ body: attributeTypes });
    }

    public static async createAttributeType(serverId, attributeType: AttributeType): Promise<void> {
        await apiBundle(serverId).attributeType.create({ body: attributeType });
    }

    public static async deleteAttributeTypesAndGroupsRequest(
        serverId,
        attributeTypes: AttributeType[],
        attributeTypeGroups: AttributeTypeGroup[],
    ): Promise<void> {
        await this.deleteAttributeTypes(serverId, attributeTypes);
        await this.attributeTypeGroupBulkDelete(serverId, attributeTypeGroups);
    }
}
