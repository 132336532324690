import React, { FC, useState } from 'react';
import { InternationalString, NodeId, PresetImage, PresetImageGroup } from '../../../../../../serverapi/api';
import theme from '../Presets.scss';
import messages from './PresetImage.messages';
import presetMessages from '../../../messages/Presets.messages';
import { Form, Tabs } from 'antd';
import { EditorFooterButtons } from '../Footer/EditorFooterButtons.component';
import footerMessages from '../Footer/EditorFooterButtons.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceRemoveTabByNodeId } from '../../../../../../actions/tabs.actions';
import { submitPresetImages } from '../../../../../../actions/presetSettings/presetImage.actions';
import { TSubmitPresetImagePayload } from '../../../../../../actions/presetSettings/presetImage.actions.types';
import { IWorkspaceTabItemPresetImageEditorParams, TWorkspaceTab } from '../../../../../../models/tab.types';
import { GeneralTab } from './PresetImageEditorGeneralTab.component';
import { PresetImageGroupSelectors } from '../../../../../../selectors/presetSettings/presetImageGroup.selectors';
import { LocalesService } from '../../../../../../services/LocalesService';
import { createButtonsCompositeDataTestId } from '../util/createButtonsCompositeDataTestId.utils';
import { TBtnProperties } from '../Header/TabHeader.types';
import type { Tab } from 'rc-tabs/lib/interface';

type TPresetImageEditorProps = {
    tab: TWorkspaceTab;
};

const PresetImageEditorComponent: FC<TPresetImageEditorProps> = (props) => {
    const dispatch = useDispatch();

    const intl = useIntl();

    const tabNodeId: NodeId = props.tab.nodeId;

    const { presetImage, createMode, preset, serverNode } = props.tab
        .params as IWorkspaceTabItemPresetImageEditorParams;

    const [form] = Form.useForm();

    const [presetImageState, setPresetImageState] = useState<PresetImage>(presetImage);
    const [saveDisabled, setSaveDisabled] = useState<boolean>(!presetImage.presetImageGroup);

    const presetImageGroupsById = useSelector(
        PresetImageGroupSelectors.byPresetId({
            serverId: serverNode.nodeId.serverId,
            presetId: preset.id || '',
        }),
    );
    const presetImageGroups: PresetImageGroup[] = Object.values(presetImageGroupsById.byId);

    const onSubmit = (payload: TSubmitPresetImagePayload) => {
        dispatch(submitPresetImages(payload));
    };

    const onDropFile = (fileBase64: string) => {
        setPresetImageState({
            ...presetImageState,
            graphical: fileBase64,
        });
        setSaveDisabled(!presetImageState.presetImageGroup);
    };
    const onChangePresetImageName = (multilingualName: InternationalString) => {
        setPresetImageState({ ...presetImageState, multilingualName });
    };

    const onChangePresetImageId = (id: string) => {
        setPresetImageState({ ...presetImageState, id });
    };

    const onChangePresetImageGroup = (presetImageGroup: PresetImageGroup) => {
        setPresetImageState({ ...presetImageState, presetImageGroup });
        setSaveDisabled(!presetImageState.graphical);
    };

    const deleteQuestion: string = intl.formatMessage(presetMessages.deleteImage, {
        name: LocalesService.internationalStringToString(presetImage.multilingualName),
    });

    const buttons: TBtnProperties[] = [
        {
            name: intl.formatMessage(footerMessages.cancel),
            onAction: () => dispatch(workspaceRemoveTabByNodeId(tabNodeId)),
            dataTestId: 'methodology-icon-editor_cancel-btn',
        },
        {
            disabled: saveDisabled,
            name: createMode ? intl.formatMessage(footerMessages.create) : intl.formatMessage(footerMessages.save),
            type: 'primary',
            onAction: () => {
                if (form) {
                    form.validateFields()
                        .then(() => {
                            onSubmit({
                                serverNode,
                                preset,
                                createMode,
                                tabNodeId,
                                presetImages: [
                                    {
                                        ...presetImageState,
                                    },
                                ],
                            });
                        })
                        .catch(() => undefined);
                }
            },
            dataTestId: 'methodology-icon-editor_save-btn',
        },
    ];

    const items: Tab[] = [
        {
            label: intl.formatMessage(messages.generalSettings),
            key: 'GeneralSettings',
            children: (
                <div className={theme.tabContent}>
                    <Form form={form} layout="vertical">
                        <GeneralTab
                            generalForm={form}
                            createMode={createMode}
                            presetImage={presetImageState}
                            presetImageGroups={presetImageGroups}
                            onChangePresetImageGroup={onChangePresetImageGroup}
                            onChangePresetImageName={onChangePresetImageName}
                            onChangePresetImageId={onChangePresetImageId}
                            onDropFile={onDropFile}
                        />
                    </Form>
                    <EditorFooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={theme.container}>
            <span className={theme.navigationTitle}>
                {`${preset.name} > ${intl.formatMessage(
                    messages.presetImage,
                )} > ${LocalesService.internationalStringToString(presetImage.multilingualName)}`}
            </span>
            <Tabs className={theme.tabs} tabPosition="left" defaultActiveKey="GeneralSettings" items={items} />
        </div>
    );
};

export default PresetImageEditorComponent;
