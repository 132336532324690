import { EdgeType } from '@/serverapi/api';
import { EdgeInstanceImpl } from '../../models/bpm/bpm-model-impl';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolsManager.class';
import { LifelineSymbolMainClass } from '../ComplexSymbols/symbols/LifeLine/LifelineSymbolMain.class';
import SequenceUtils from '../ComplexSymbols/symbols/Sequence/sequence.utils';
import { cellsTreeWalkerDown, currentTreeDepth } from '../ComplexSymbols/utils';
import { BPMMxConnectionHandler } from '../handler/BPMMxConnectionHandler.class';
import { MxCell, MxConstants, MxEvent, MxEventObject, MxLog, MxMouseEvent, MxPoint, MxCellState } from '../mxgraph';
import { SequenceCellMarker } from './SequenceCellMarker';
import { edgeTerminalOffsets, forkEdgeOffset, SequenceEdgeTypesId } from './SequenceConstants';
import { SequenceConstraintHandler } from './SequenceConstraintHandler';
import { SequenceGraph } from './SequenceGraph';

/**
 *Обработчик событий графа, который создает новые соединения.
 */

export class SequenceConnectionHandler extends BPMMxConnectionHandler {
    outlineConnect = true;
    livePreview = true;
    enabled = true;
    waypointsEnabled = false;
    constraintHandler: SequenceConstraintHandler;
    isReverce: boolean;
    graph: SequenceGraph;
    isDurationEdge: boolean;
    isRecursive: boolean;
    startPoint: MxPoint;
    availableEdgeType: EdgeType[];
    currentEdgeTypeIndex: number;
    prevEdgeType: EdgeType | null;
    didChangeEdgeType: boolean;
    sourceEdge?: MxCell;
    wheelHandler: (event: WheelEvent) => void;

    constructor(graph: SequenceGraph, factoryMethod?) {
        super(graph, factoryMethod);
        this.isReverce = false;
    }

    init() {
        super.init();
        this.constraintHandler = new SequenceConstraintHandler(this.graph);
        this.isDurationEdge = false;
        this.isRecursive = false;
        this.availableEdgeType = [];
        this.currentEdgeTypeIndex = 0;
        this.didChangeEdgeType = false;
        this.prevEdgeType = null;
    }

    /**
     * Начинает новое соединение для заданного состояния и координат.
     * @param { MxCellState } state источник новой связи
     * @param { number } x x коориданата начала связи
     * @param { number } y y коориданата начала связи
     * @param { MxCellState } edgeState начальное состояние свзяи
     */
    start(
        state: MxCellState,
        x: number | null,
        y: number | null,
        edgeState: MxCellState,
        availableEdgeType?: EdgeType[],
        sourceEdge?: MxCell,
    ) {
        super.start(state, x, y, edgeState);
        this.sourceEdge = sourceEdge;
        this.didChangeEdgeType = false;
        this.prevEdgeType = null;
        this.isDurationEdge = this.graph.isDurationEdge(edgeState.cell);
        this.isRecursive = this.graph.isRecursiveEdge(edgeState.cell);
        this.availableEdgeType = availableEdgeType || [];
        this.currentEdgeTypeIndex =
            availableEdgeType?.findIndex((edgeType) => edgeType.id === edgeState.cell.value.edgeTypeId) || 0;

        const wheelHandler = (event: WheelEvent) => {
            event.preventDefault();

            if (
                SequenceUtils.isUmlMessage(this.currentState?.cell) ||
                SequenceUtils.isUmlMessage(this.previous?.cell)
            ) {
                return;
            }

            const newIndex = this.calcEdgeTypelIndex(event);

            if (availableEdgeType?.[newIndex] && availableEdgeType[newIndex].id) {
                const newEdgeType = availableEdgeType[newIndex];
                this.changeEdgeType(newEdgeType);
            }
        };
        this.wheelHandler = wheelHandler;
        document.addEventListener('wheel', wheelHandler, { passive: false });
    }

    changeEdgeType(newEdgeType: EdgeType) {
        const model = this.graph.getModel();
        model.beginUpdate();
        const currentPoint: MxPoint = this.constraintHandler?.currentPoint?.clone() || this.currentPoint;
        const { value } = this.edgeState.cell;
        this.prevEdgeType = value.edgeType;
        value.edgeTypeId = newEdgeType.id;
        value.edgeType = newEdgeType;
        this.isDurationEdge = this.graph.isDurationEdge(this.edgeState.cell);
        this.isRecursive = this.graph.isRecursiveEdge(this.edgeState.cell);
        const style = newEdgeType.edgeStyle;
        value.style = style;
        this.graph.setCellStyle(style, [this.edgeState.cell]);
        value.edgeType.edgeStyle = style;
        if (this.isRecursive) {
            this.currentState = this.previous;
            this.edgeState.absolutePoints[0].y = Math.min(this.graph.popupMenuHandler.triggerY, currentPoint.y);
            this.edgeState.absolutePoints[this.edgeState.absolutePoints.length - 1].y = Math.max(
                this.graph.popupMenuHandler.triggerY,
                currentPoint.y,
            );
        } else {
            this.edgeState.absolutePoints[0].y = currentPoint.y;
            this.edgeState.absolutePoints[this.edgeState.absolutePoints.length - 1].y = currentPoint.y;
        }
        this.edgeState.style = this.graph.getCellStyle(this.edgeState.cell);
        this.edgeState.style[MxConstants.STYLE_ROUNDED] = false;
        this.shape.resetStyles();
        this.shape.apply(this.edgeState);
        this.updateEdgeState(currentPoint, null);
        model.endUpdate();
    }

    setCurrentEdgeTypelIndex(index: number) {
        this.currentEdgeTypeIndex = index;
    }

    calcEdgeTypelIndex(event: WheelEvent) {
        if (event.deltaY > 0) {
            const maxIndex = this.availableEdgeType.length - 1;
            const newIndex = this.currentEdgeTypeIndex + 1;
            if (newIndex > maxIndex) {
                this.setCurrentEdgeTypelIndex(0);

                return 0;
            }
            this.setCurrentEdgeTypelIndex(newIndex);

            return newIndex;
        }
        if (event.deltaY < 0) {
            const maxIndex = this.availableEdgeType.length - 1;
            const newIndex = this.currentEdgeTypeIndex - 1;
            if (newIndex < 0) {
                this.setCurrentEdgeTypelIndex(maxIndex);

                return maxIndex;
            }
            this.setCurrentEdgeTypelIndex(newIndex);

            return newIndex;
        }

        return this.currentEdgeTypeIndex;
    }

    /**
     * Событие обработки движения мыши при проведении новой связи
     * @param { any } sender
     * @param { MxMouseEvent } me информация о событии
     */
    mouseMove(sender, me: MxMouseEvent) {
        if (!me.isConsumed() && (this.ignoreMouseDown || this.first != null || !this.graph.isMouseDown)) {
            const view = this.graph.getView();
            const { scale } = view;
            const tr = view.translate;
            let point = new MxPoint(me.getGraphX(), me.getGraphY());
            this.error = null;

            if (this.graph.isGridEnabledEvent(me.getEvent())) {
                point = new MxPoint(
                    (this.graph.snap(point.x / scale - tr.x) + tr.x) * scale,
                    (this.graph.snap(point.y / scale - tr.y) + tr.y) * scale,
                );
            }

            this.currentPoint = point;

            if (
                (this.first != null || (this.isEnabled() && this.graph.isEnabled())) &&
                (this.shape != null ||
                    this.first == null ||
                    Math.abs(me.getGraphX() - this.first.x) > this.graph.tolerance ||
                    Math.abs(me.getGraphY() - this.first.y) > this.graph.tolerance)
            ) {
                this.updateCurrentState(me, point);
            }
            if (this.first != null) {
                let constraint = null;
                let current: MxPoint | null = point;

                // Uses the current point from the constraint handler if available
                if (
                    this.constraintHandler.currentConstraint != null &&
                    this.constraintHandler.currentFocus != null &&
                    this.constraintHandler.currentPoint != null
                ) {
                    constraint = this.constraintHandler.currentConstraint;
                    current = this.constraintHandler.currentPoint.clone();
                } else if (
                    this.previous != null &&
                    !this.graph.isIgnoreTerminalEvent(me.getEvent()) &&
                    MxEvent.isShiftDown(me.getEvent())
                ) {
                    if (
                        Math.abs(this.previous.getCenterX() - point.x) < Math.abs(this.previous.getCenterY() - point.y)
                    ) {
                        point.x = this.previous.getCenterX();
                    } else {
                        point.y = this.previous.getCenterY();
                    }
                }

                // Uses edge state to compute the terminal points
                if (this.edgeState != null) {
                    this.updateEdgeState(current, constraint);
                    current = this.edgeState.absolutePoints[this.edgeState.absolutePoints.length - 1];
                }

                // Creates the preview shape (lazy)
                if (this.shape == null) {
                    const dx = Math.abs(me.getGraphX() - this.first.x);
                    const dy = Math.abs(me.getGraphY() - this.first.y);

                    if (dx > this.graph.tolerance || dy > this.graph.tolerance) {
                        this.shape = this.createShape();

                        if (this.edgeState != null) {
                            this.shape.apply(this.edgeState);
                        }

                        // Revalidates current connection
                        this.updateCurrentState(me, point);
                    }
                }

                // Updates the points in the preview edge
                if (this.shape != null) {
                    if (this.edgeState != null) {
                        this.shape.points = this.edgeState.absolutePoints;
                    }
                    this.drawPreview();
                }

                MxEvent.consume(me.getEvent());
                me.consume();
            } else if (!this.isEnabled() || !this.graph.isEnabled()) {
                this.constraintHandler.reset();
            } else if (this.previous !== this.currentState && this.edgeState == null) {
                this.destroyIcons();

                // Sets the cursor on the current shape
                if (
                    this.currentState != null &&
                    this.error == null &&
                    this.constraintHandler.currentConstraint == null
                ) {
                    this.icons = this.createIcons(this.currentState);

                    if (this.icons == null) {
                        this.currentState.setCursor(MxConstants.CURSOR_CONNECT);
                        me.consume();
                    }
                }
                this.previous = this.currentState;
            } else if (
                this.previous === this.currentState &&
                this.currentState != null &&
                this.icons == null &&
                !this.graph.isMouseDown
            ) {
                // Makes sure that no cursors are changed
                this.constraintHandler.reset();
                this.previous = null;
                this.currentState = null;
                me.consume();
            }

            this.isReverce = !!this.previous && point.x < this.previous.getCenterX();
        } else {
            this.constraintHandler.reset();
        }
    }

    /**
     * Функция расчета Y координаты конечной точки для наклонной связи
     * @param { number } srcX - X координата начальной точки связи
     * @param { number } trgX - X координата конечной точки связи
     * @param { number } srcY - Y координата начальной точки связи
     * @returns { number } trgY - Y координата конечной точки связи
     */
    calcYForDurationEdge(srcX: number, trgX: number, srcY: number): number {
        return srcY + Math.abs((srcX - trgX) / Math.tan(30));
    }

    /**
     * Метод обновления состояния создаваймой связи
     * @param { MxPoint | null } current - текущяя точка на графе
     * @param { any } constraint - X координата конечной точки связи
     */
    updateEdgeState(current: MxPoint | null, constraint) {
        if (!this.previous || !current) return;

        const sourceState = this.previous;
        const minY = sourceState.y;
        const maxY = sourceState.y + sourceState.height;
        const isReverce = sourceState.getCenterX() > current.x;
        const xConstrainSource = this.calcXConstrain(this.edgeState, true, sourceState, isReverce);
        const calcSourceX = sourceState.x + sourceState.width * xConstrainSource;
        let calcSourceY = this.sourceEdge ? current.y : Math.min(Math.max(minY, current.y), maxY);

        if (SequenceUtils.isUmlMessage(this.currentState?.cell)) {
            calcSourceY = this.currentState.y + this.currentState.height / 2;
        }

        if (SequenceUtils.isUmlMessage(sourceState.cell)) {
            calcSourceY = sourceState.y + sourceState.height / 2;
        }

        if (!this.isRecursive || SequenceUtils.isUmlMessage(this.currentState?.cell)) {
            const targetY = this.isDurationEdge
                ? this.calcYForDurationEdge(calcSourceX, current.x, calcSourceY)
                : calcSourceY;
            const edgeTargetPoint = new MxPoint(current.x, targetY);
            const edgeStartPoint = new MxPoint(calcSourceX, calcSourceY);

            let absolutePoints = [edgeStartPoint, edgeTargetPoint];
            let constraintPoint = edgeStartPoint;

            if (this.sourceEdge) {
                const sourceEdgeState = this.graph.view.getState(this.sourceEdge);
                edgeStartPoint.x += isReverce ? -forkEdgeOffset : forkEdgeOffset;
                [constraintPoint] = sourceEdgeState.absolutePoints;
                absolutePoints = [sourceEdgeState.absolutePoints[0], edgeStartPoint, edgeTargetPoint];
            }

            this.edgeState.absolutePoints = absolutePoints;
            this.edgeState.cell.geometry.points = this.edgeState.absolutePoints;
            if (this.currentState != null) {
                if (constraint == null) {
                    constraint = this.graph.getOutlineConstraint(
                        constraintPoint,
                        this.currentState,
                        this.edgeState,
                        false,
                        isReverce,
                        false,
                    );
                }
                this.graph.view.updateFixedTerminalPoint(this.edgeState, this.currentState, false, constraint);
            }
            this.graph.view.updateFloatingTerminalPoints(this.edgeState, this.previous, this.currentState);
        } else {
            const xConstrainSrc = this.calcXConstrain(this.edgeState, true, sourceState, false, true);
            const xConstrainTrg = this.calcXConstrain(this.edgeState, false, sourceState, false, true);
            const calcSrcX = sourceState.x + sourceState.width * xConstrainSrc;
            const calcTrgX = sourceState.x + sourceState.width * xConstrainTrg;
            const srcPoint = new MxPoint(calcSrcX, Math.min(this.graph.popupMenuHandler.triggerY, calcSourceY));
            const trgPoint = new MxPoint(calcTrgX, Math.max(this.graph.popupMenuHandler.triggerY, calcSourceY));
            const offsetX = Math.max(srcPoint.x + 20, trgPoint.x + 20);
            const newPoints = [srcPoint, new MxPoint(offsetX, srcPoint.y), new MxPoint(offsetX, trgPoint.y), trgPoint];
            this.edgeState.absolutePoints = newPoints;
            this.edgeState.cell.geometry.points = this.edgeState.absolutePoints;
        }
        if (this.shape != null) {
            if (this.edgeState != null) {
                this.shape.points = this.edgeState.absolutePoints;
            }
            this.drawPreview();
        }
    }

    /**
     * Метод обновления состояния текущего выбранного объекта на графе
     * @param { MxMouseEvent } me - информация о событии
     * @param { currentPoint } MxPoint - текущяя точка на графе
     */
    updateCurrentState(me: MxMouseEvent, currentPoint: MxPoint) {
        if (!this.previous) return;

        this.constraintHandler.update(
            me,
            this.first === null,
            false,
            this.first === null || me.isSource(this.marker.highlight.shape) ? null : currentPoint,
        );

        let isReverce = this.currentState && this.currentState.getCenterX() > currentPoint.x;

        this.marker.process(me);

        const currentState: MxCellState | null = this.marker.getValidState();
        const startPoint: MxPoint = this.edgeState?.absolutePoints?.[0] || currentPoint;
        if (
            !currentState ||
            (startPoint.y > currentState.y && startPoint.y < currentState.y + currentState.height) ||
            (this.isRecursive &&
                currentState.y >= this.previous.y &&
                currentState.y + currentState.height <= this.previous.y + this.previous.height)
        ) {
            if (this.sourceEdge && SequenceUtils.isUmlMessage(this.currentState?.cell)) {
                this.currentState = null;
            } else {
                this.currentState = currentState;
            }
        }

        if (this.isRecursive && !SequenceUtils.isUmlMessage(this.currentState?.cell)) {
            if (!this.currentState) this.currentState = this.previous;
            const srcPoint = new MxPoint(0, this.graph.popupMenuHandler.triggerY);
            const pointForTrg = srcPoint.clone();
            pointForTrg.y = Math.min(Math.max(currentPoint.y, this.previous.y), this.previous.y + this.previous.height);
            this.constraintHandler.currentConstraint = this.graph.getOutlineConstraint(
                pointForTrg,
                this.currentState,
                this.edgeState,
                false,
                isReverce,
                true,
            );
            this.sourceConstraint = this.graph.getOutlineConstraint(
                srcPoint,
                this.previous,
                this.edgeState,
                true,
                isReverce,
                true,
            );
            this.constraintHandler.currentPoint = currentPoint;
            this.constraintHandler.setFocus(me, this.currentState);
        } else {
            if (
                !this.didChangeEdgeType &&
                this.currentState?.cell &&
                SequenceUtils.isUmlMessage(this.currentState.cell) &&
                this.previous?.cell &&
                !SequenceUtils.isUmlMessage(this.previous?.cell)
            ) {
                const asynchMessage = this.availableEdgeType.find(
                    (type) => type.id === SequenceEdgeTypesId.ASYNCHRON_MESSAGE,
                );
                if (asynchMessage) {
                    this.changeEdgeType(asynchMessage);
                    this.didChangeEdgeType = true;
                }
            }
            if (!SequenceUtils.isUmlMessage(this.currentState?.cell) && this.didChangeEdgeType && this.prevEdgeType) {
                this.changeEdgeType(this.prevEdgeType);
                this.didChangeEdgeType = false;
                this.prevEdgeType = null;
            }

            if (this.currentState !== null && this.currentState !== this.previous) {
                if (me.isSource(this.marker.highlight.shape)) {
                    // Handles special case where mouse is on outline away from actual end point
                    // in which case the grid is ignored and mouse point is used instead
                    currentPoint = new MxPoint(me.getGraphX(), me.getGraphY());
                }
                const sourceState: MxCellState = this.previous;

                if (!this.sourceEdge) {
                    currentPoint.y = Math.min(
                        Math.max(currentPoint.y, sourceState.y),
                        sourceState.y + sourceState.height,
                    );
                }

                this.constraintHandler.setFocus(me, this.currentState);
                const pointFotTarget: MxPoint = currentPoint.clone();
                isReverce = sourceState.getCenterX() > currentPoint.x;
                if (this.isDurationEdge && this.edgeState?.absolutePoints.length) {
                    const srcPoint: MxPoint = this.edgeState.absolutePoints[0];
                    const xConstrain = this.calcXConstrain(this.edgeState, false, this.currentState, isReverce);
                    const bounds = this.graph.view.getPerimeterBounds(this.currentState);
                    const trgX = bounds.x + bounds.width * xConstrain;
                    pointFotTarget.y = this.calcYForDurationEdge(srcPoint.x, trgX, srcPoint.y);
                }
                this.constraintHandler.currentConstraint = this.graph.getOutlineConstraint(
                    pointFotTarget,
                    this.currentState,
                    this.edgeState,
                    false,
                    isReverce,
                    false,
                );
                this.constraintHandler.currentPoint = currentPoint;
                this.sourceConstraint = this.graph.getOutlineConstraint(
                    currentPoint,
                    this.previous,
                    this.edgeState,
                    false,
                    isReverce,
                    false,
                );
                this.marker.markCell(this.currentState.cell);
            }
        }

        if (this.outlineConnect) {
            if (this.marker.highlight !== null && this.marker.highlight.shape !== null) {
                const s = this.graph.view.scale;
                if (this.marker.getValidState())
                    if (
                        this.constraintHandler.currentConstraint !== null &&
                        this.constraintHandler.currentFocus !== null
                    ) {
                        this.marker.highlight.shape.stroke = MxConstants.OUTLINE_HIGHLIGHT_COLOR;
                        this.marker.highlight.repaint();
                    } else if (this.marker.hasValidState()) {
                        // Handles special case where actual end point of edge and current mouse point
                        // are not equal (due to grid snapping) and there is no hit on shape or highlight
                        if (this.marker.getValidState() !== me.getState()) {
                            this.marker.highlight.shape.stroke = 'transparent';
                            this.currentState = null;
                        } else {
                            this.marker.highlight.shape.stroke = MxConstants.DEFAULT_VALID_COLOR;
                        }

                        this.marker.highlight.shape.strokewidth = MxConstants.HIGHLIGHT_STROKEWIDTH / s;
                        this.marker.highlight.repaint();
                    }
            }
        }
    }

    /**
     * Метод проведения новой свзяи
     * @param { MxCell | null } source - источник связи
     * @param { MxCell | null } target - целевой объект связм
     * @param { any } evt - информация о событии
     * @param { MxCell | null } dropTarget - текущая ячейка на которой находится указатель мыши
     */
    connect(source: MxCell | null, target: MxCell | null, evt, dropTarget: MxCell | null) {
        if (target != null || this.isCreateTarget(evt) || this.graph.allowDanglingEdges) {
            if (source?.id === target?.id && !this.isRecursive) return;
            // Uses the common parent of source and target or
            // the default parent to insert the edge
            const model = this.graph.getModel();
            const terminalInserted = false;
            let edge: MxCell | null = null;

            model.beginUpdate();
            try {
                // Uses the value of the preview edge state for inserting
                // the new edge into the graph
                let value: EdgeInstanceImpl | null = null;
                let style: string | null = null;
                let absPoints: MxPoint[] = [];

                if (this.edgeState != null) {
                    value = this.edgeState.cell.value;
                    style = `${this.edgeState.cell.style}${MxConstants.STYLE_ROUNDED}=false;`;
                    absPoints = this.edgeState.absolutePoints;
                }

                const parent = this.graph.getDefaultParent();

                edge = this.insertEdge(parent, value?.id, value, source, target, style);
                const s = this.graph.view.scale;
                edge.geometry.points = absPoints.map((point) => new MxPoint(point?.x / s, point?.y / s));

                if (edge != null) {
                    // Updates the connection constraints
                    this.graph.setConnectionConstraint(
                        edge,
                        this.sourceConstraint,
                        this.constraintHandler.currentConstraint,
                    );
                    this.fireEvent(
                        new MxEventObject(
                            MxEvent.CONNECT,
                            'cell',
                            edge,
                            'terminal',
                            target,
                            'event',
                            evt,
                            'target',
                            dropTarget,
                            'terminalInserted',
                            terminalInserted,
                        ),
                    );
                }
            } catch (e) {
                MxLog.show();
                MxLog.debug(e.message);
            } finally {
                model.endUpdate();
                this.didChangeEdgeType = false;
                this.prevEdgeType = null;
            }

            if (this.select) {
                this.selectCells(edge, terminalInserted ? target : null);
            }
        }
    }

    /**
     * Создания маркера для выделения текущей ячейки на графе
     * @returns { SequenceCellMarker } SequenceCellMarker
     */
    createMarker() {
        const marker = new SequenceCellMarker(this.graph, null, null, MxConstants.DEFAULT_HOTSPOT);
        marker.hotspotEnabled = true;
        marker.highlight.spacing = 0;

        // Overrides to return cell at location only if valid (so that
        // there is no highlight for invalid cells)
        marker.getCell = (me) => {
            let cell: MxCell | null = SequenceCellMarker.prototype.getCell(me);
            this.error = null;

            // Checks for cell at preview point (with grid)
            if (cell == null && this.currentPoint != null) {
                cell = this.graph.getCellAt(this.currentPoint.x, this.currentPoint.y);
            }

            // Uses connectable parent vertex if one exists
            if (cell != null && !this.graph.isCellConnectable(cell)) {
                const parent = this.graph.getModel().getParent(cell);

                if (this.graph.getModel().isVertex(parent) && this.graph.isCellConnectable(parent)) {
                    cell = parent;
                }
            }

            if (
                (this.graph.isSwimlane(cell) &&
                    this.currentPoint != null &&
                    this.graph.hitsSwimlaneContent(cell, this.currentPoint.x, this.currentPoint.y)) ||
                !this.isConnectableCell(cell)
            ) {
                cell = null;
            }

            if (cell != null) {
                if (this.isConnecting()) {
                    if (this.previous != null) {
                        this.error = this.validateConnection(this.previous.cell, cell);

                        if (this.error != null && this.error.length === 0) {
                            cell = null;

                            // Enables create target inside groups
                            if (this.isCreateTarget(me.getEvent())) {
                                this.error = null;
                            }
                        }
                    }
                } else if (!this.isValidSource(cell, me)) {
                    cell = null;
                }
            } else if (this.isConnecting() && !this.isCreateTarget(me.getEvent()) && !this.graph.allowDanglingEdges) {
                this.error = '';
            }

            return cell;
        };

        // Sets the highlight color according to validateConnection
        marker.isValidState = (state) => {
            return SequenceCellMarker.prototype.isValidState(state);
        };

        // Overrides to use marker color only in highlight mode or for
        // target selection
        marker.getMarkerColor = (evt, state, isValid) => {
            return this.connectImage == null || this.isConnecting()
                ? SequenceCellMarker.prototype.getMarkerColor(evt, state, isValid)
                : null;
        };

        // Overrides to use hotspot only for source selection otherwise
        // intersects always returns true when over a cell
        marker.intersects = (state, evt) => {
            if (this.connectImage != null || this.isConnecting()) {
                return true;
            }

            return SequenceCellMarker.prototype.intersects(state, evt);
        };

        return marker;
    }

    /**
     * Функция подчета отступа для связи.
     * @param { MxCellState } edgeState - состояния связи
     * @param { boolean } source - расчет отсутпа для источника или таргета
     * @param { MxCellState | null } objectState - терминальный объект для связи
     * @param { boolean } [isReverce] - является ли связь обратной
     * @param { boolean } [isRecursive] - является ли связь рекурсивной
     * @returns { number } отступ от объекта
     */
    calcXConstrain(
        edgeState: MxCellState,
        source: boolean,
        objectState: MxCellState | null,
        isReverce?: boolean,
        isRecursive?: boolean,
    ): number {
        let state: MxCellState | null = objectState;
        if (!edgeState?.absolutePoints) return edgeTerminalOffsets[0];
        if (!state) {
            state = source ? edgeState.visibleSourceState : edgeState.visibleTargetState;
        }
        const isSequenceActor = SequenceUtils.isSequenceActor(state.cell);
        const point: MxPoint = source
            ? edgeState.absolutePoints[0]
            : edgeState.absolutePoints[edgeState.absolutePoints.length - 1];
        const complexSymbol = ComplexSymbolManager.getComplexSymbolInstance(state.cell) as LifelineSymbolMainClass;
        const headerSize: number = complexSymbol?.headerSize || 40;
        const s = this.graph.view.scale;

        if (point.y <= state.y + headerSize * s) {
            if (isReverce) {
                return source || isRecursive ? 0 : 1;
            }

            return source || isRecursive ? 1 : 0;
        }

        if (isSequenceActor) return isReverce ? 1 - edgeTerminalOffsets[1] : edgeTerminalOffsets[1];

        if (state.cell) {
            let lvl: number = 0;
            const cb = (cell: MxCell) => {
                const cellState = this.graph.view.getState(cell);
                if (!cellState || cell.isEdge()) return;
                if (point.y >= cellState.y && point.y <= cellState.y + cellState.height) {
                    lvl = currentTreeDepth(cell);
                }
            };
            cellsTreeWalkerDown([state.cell], cb);
            if (!isReverce && (source || isRecursive)) return edgeTerminalOffsets[lvl];
            if (isReverce && !source && !isRecursive) return edgeTerminalOffsets[lvl];

            return lvl ? 1 - edgeTerminalOffsets[1] : edgeTerminalOffsets[0];
        }

        return edgeTerminalOffsets[0];
    }

    reset(): void {
        document.removeEventListener('wheel', this.wheelHandler);
        super.reset();
    }
}
