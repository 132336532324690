import type { IModelNode } from '../../models/bpm/bpm-model-impl.types';
import type {
    NodeAttributes,
    ITreeApi,
    MoveNodeRequest,
    NodeId,
    Node,
    PathResponse,
    StubResponse,
    ApprovalDTO,
    SubscriptionPrincipalInfo,
    SubscriptionInfo,
    FavoriteNodeDTO,
} from '../../serverapi/api';
import { setServerIdToFavoriteNode, setServerIdToNodeInterface } from '../../utils/nodeId.utils';
import { apiBundle } from '../api/api-bundle';

export class TreeDaoService {
    /*
        находит список родительских элементов
    */
    public static async findAllParentElementId(nodesIds: NodeId[]): Promise<{ [key: string]: NodeId[] }> {
        if (!nodesIds || nodesIds.length === 0) {
            return Promise.resolve({});
        }
        const { serverId } = nodesIds[0];
        const api: ITreeApi = apiBundle(serverId).tree;

        const childMap: { [key: string]: NodeId[] } = await api.findAllParentElementId({ body: nodesIds });

        Object.values(childMap).forEach((child) => child.forEach((c) => (c.serverId = serverId)));

        return childMap;
    }

    public static async delete(nodeId: NodeId): Promise<boolean> {
        if (!nodeId?.serverId) {
            return Promise.resolve(false);
        }

        const { serverId, repositoryId, id } = nodeId;
        const api: ITreeApi = apiBundle(serverId).tree;

        return api.deleteNode({ repositoryId, nodeId: id });
    }

    public static async erase(nodeId: NodeId): Promise<void> {
        if (!nodeId?.serverId) {
            return Promise.resolve();
        }

        const { serverId, repositoryId, id } = nodeId;
        const api: ITreeApi = apiBundle(serverId).tree;

        return api.erase({
            body: {
                repositoryId,
                ids: [id],
            },
        });
    }

    public static async restore(
        nodeId: NodeId,
        options?: {
            newParentId?: string;
            restoreParent?: boolean;
            restoreChildren?: boolean;
            restoreOnlySameDeleteOperation?: boolean;
            restoreModelElements?: boolean;
        },
    ): Promise<void> {
        if (!nodeId?.serverId) {
            return Promise.resolve();
        }

        const { serverId, repositoryId, id } = nodeId;
        const api: ITreeApi = apiBundle(serverId).tree;

        return api.restore({
            body: {
                repositoryId,
                ids: [id],
                ...(options || {}),
            },
        });
    }

    public static async getFavorites(serverId: string): Promise<Array<FavoriteNodeDTO>> {
        if (!serverId) {
            return Promise.resolve([]);
        }

        const favoriteNodes: FavoriteNodeDTO[] = await apiBundle(serverId).favorites.getFavoriteNodes();

        favoriteNodes.forEach((node) => setServerIdToFavoriteNode(node, serverId));

        return favoriteNodes;
    }

    public static async addToFavorites(nodeId: NodeId): Promise<Array<FavoriteNodeDTO>> {
        if (!nodeId?.serverId) {
            return Promise.resolve([]);
        }

        const { serverId, repositoryId, id } = nodeId;
        const favoriteNodes: FavoriteNodeDTO[] = await apiBundle(serverId).favorites.save({
            repositoryId,
            nodeId: id,
        });

        favoriteNodes.forEach((node) => setServerIdToFavoriteNode(node, serverId));

        return favoriteNodes;
    }

    public static async removeFromFavorites(nodeId: NodeId): Promise<Array<FavoriteNodeDTO>> {
        if (!nodeId?.serverId) {
            return Promise.resolve([]);
        }

        const { serverId, repositoryId, id } = nodeId;
        const favoriteNodes: FavoriteNodeDTO[] = await apiBundle(serverId).favorites._delete({
            repositoryId,
            nodeId: id,
        });

        favoriteNodes.forEach((node) => setServerIdToFavoriteNode(node, serverId));

        return favoriteNodes;
    }

    public static async move(parentNodeId: NodeId, ids: string[]): Promise<Array<Node>> {
        const request: MoveNodeRequest = {
            ids,
            parentNodeId,
        };
        const updatedNodes: Node[] = await apiBundle(parentNodeId.serverId).tree.move({ body: request });
        updatedNodes.forEach((updatedNode) => setServerIdToNodeInterface(updatedNode, parentNodeId.serverId));

        return updatedNodes;
    }

    public static async getNodePath(serverId: string, id: string, repositoryId: string): Promise<PathResponse> {
        const path = await apiBundle(serverId).tree.getNodePath({ id, repositoryId });

        return path;
    }

    public static async getAllChild(nodeId: NodeId): Promise<Array<Node>> {
        const { id, repositoryId } = nodeId;
        const allChild: Node[] = await apiBundle(nodeId.serverId).tree.getAllChild({ id, repositoryId });
        await allChild.forEach((n) => setServerIdToNodeInterface(n, nodeId.serverId));

        return allChild;
    }

    public static async saveShowDeletedObjectProperty(serverId: string, value: boolean): Promise<void> {
        return apiBundle(serverId).session.saveShowDeletedObjectProperty({ body: { value } });
    }

    public static async getNode(serverId: string, nodeId: NodeId): Promise<Node> {
        const node = await apiBundle(serverId).tree.getNode({ nodeId: nodeId.id, repositoryId: nodeId.repositoryId });

        setServerIdToNodeInterface(node, serverId);

        return node;
    }

    public static async getNodeChildren(serverId: string, nodeId: NodeId): Promise<Node[]> {
        const node: Node = await apiBundle(serverId).tree.getNodeChildren({
            nodeId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });
        const children: Node[] = node?.children || [];

        children.forEach((n) => setServerIdToNodeInterface(n, serverId));

        return children;
    }

    // использовать этот метод вместо getAllChild, если нужны атрибуты (getAllChild не возвращает атрибуты)
    public static async getNodeAndChildrenList(serverId: string, nodeId: NodeId): Promise<Node[]> {
        const nodes: Node[] = [];
        const loadNodeChildrenRecursive = async (nodeId: NodeId): Promise<void> => {
            const node = await apiBundle(serverId).tree.getNode({
                nodeId: nodeId.id,
                repositoryId: nodeId.repositoryId,
                children: 'true',
            });

            if (!nodes.find(({ nodeId: { id } }) => id === node.nodeId.id)) nodes.push(node);

            if (!node.children || node.children.length === 0) return;

            for (const child of node.children) {
                if (child.countChildren !== 0) {
                    await loadNodeChildrenRecursive(child.nodeId);
                } else {
                    nodes.push(child);
                }
            }
        };

        await loadNodeChildrenRecursive(nodeId);

        return nodes.map((node) => {
            return { ...setServerIdToNodeInterface(node, serverId), children: [] };
        });
    }

    public static async calculateNewParentId(
        serverId: string,
        params: { repositoryId: string; parentId: string; typeId: string },
        options?: {},
    ): Promise<NodeId> {
        const newParentId: NodeId = await apiBundle(serverId).tree.calculateNewParentId(params, options);

        return newParentId;
    }

    public static async findMethodologyId(serverId: string, repositoryId: string, options?: {}): Promise<StubResponse> {
        const res: StubResponse = await apiBundle(serverId).tree.findMethodologyId({ repositoryId }, options);

        return res;
    }

    public static async getNodes(nodeIds: NodeId[], serverId: string): Promise<Node[]> {
        const nodes = await apiBundle(serverId).tree.loadBulk({ body: nodeIds });
        nodes.forEach((n) => setServerIdToNodeInterface(n, serverId));

        return nodes;
    }

    public static async getNodesByParentNode(nodeId: NodeId): Promise<Node[]> {
        const { repositoryId, id } = nodeId;
        const nodes: Node[] = await apiBundle(nodeId.serverId).tree.byParentNode({ repositoryId, id });
        nodes.forEach((n) => {
            if (n.type === 'MODEL') {
                // временный костыль, пока с бэка printable приходит всегда false
                delete (n as IModelNode).printable;
            }

            setServerIdToNodeInterface(n, nodeId.serverId);
        });

        return nodes;
    }

    public static async deleteTreeNodes(ids: NodeId[]): Promise<PromiseSettledResult<boolean>[]> {
        return await Promise.allSettled(ids.map((id) => this.delete(id)));
    }

    public static async saveAttributes(nodeId: NodeId, body: NodeAttributes): Promise<void> {
        const { repositoryId, id } = nodeId;
        await apiBundle(nodeId.serverId).tree.saveAttributes({ repositoryId, nodeId: id, body });
    }

    public static async getNodeApprovals(nodeId: NodeId): Promise<ApprovalDTO[]> {
        const { serverId, repositoryId, id } = nodeId;
        const approvals = await apiBundle(serverId).tree.getApprovals({ repositoryId, id });

        return approvals.map((approval) => {
            approval.id.serverId = serverId;
            return approval;
        });
    }

    public static async getNodeSubscriptions(nodeId: NodeId): Promise<SubscriptionPrincipalInfo[]> {
        const { serverId, repositoryId, id } = nodeId;
        const nodeSubscriptions = await apiBundle(serverId).tree.getNodeSubscriptions({ repositoryId, id });

        return nodeSubscriptions;
    }

    public static async subscribe(nodeId: NodeId): Promise<SubscriptionInfo> {
        const { serverId, repositoryId, id } = nodeId;
        const subscriptionInfo = await apiBundle(serverId).tree.createSubscription({
            repositoryId,
            id,
            body: { subscriptionType: 'ALL' },
        });

        return subscriptionInfo;
    }

    public static async unsubscribe(nodeId: NodeId): Promise<void> {
        const { serverId, repositoryId, id } = nodeId;

        return apiBundle(serverId).tree.deleteSubscription({ repositoryId, id });
    }
}
