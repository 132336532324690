// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchField__searchFieldContainer__x_Tgk{margin-top:10px}.SearchField__searchFieldContainer__x_Tgk>button{margin-left:10px}", "",{"version":3,"sources":["webpack://./src/modules/Search/SearchField/SearchField.scss"],"names":[],"mappings":"AAAA,0CACI,eAAA,CAEA,iDACI,gBAAA","sourcesContent":[".searchFieldContainer {\r\n    margin-top: 10px;\r\n\r\n    & > button {\r\n        margin-left: 10px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchFieldContainer": "SearchField__searchFieldContainer__x_Tgk"
};
export default ___CSS_LOADER_EXPORT___;
