import { ContentState, EditorState, ContentBlock } from 'draft-js';
import { clone, values } from 'lodash';
import {
    createBlocksLine,
    createTableBlock,
    createTableShapeLine,
    getBlockTablePosition,
    getRowspanDelta,
    isTableBlock,
    setTableShape,
    transpos,
    unTranspos,
} from './TableContentsBlocks.utils';
import {
    WIKI_TABLE_KEY,
    WIKI_TABLE_POSITION_KEY,
    WIKI_TABLE_SHAPE_KEY,
    WIKI_TABLE_SHOW_HEADER_KEY,
} from './WikiTable.constants';

type TLeaf = {
    children: ContentBlock[][];
    simpleBlock?: ContentBlock;
    tableKey?: string;
};
type TCellIndex = [rowIndex: number, columnIndex: number];
type TSelectedCells = TCellIndex[];

const defaultSortFn = (a: string, b: string) => {
    return a.localeCompare(b);
};

export default class BlockTree {
    editorState: EditorState;
    changeEditorState: (editorState: EditorState) => void;
    trees: TLeaf[] = [];

    constructor(editorState, changeEditorState) {
        this.editorState = editorState;
        this.changeEditorState = changeEditorState;
        this.trees = this.parseState();
    }

    public getEditorState() {
        return this.editorState;
    }

    public insertRow(currentBlock) {
        if (!isTableBlock(currentBlock)) {
            return;
        }

        const { y, tableKey } = getBlockTablePosition(currentBlock);

        const table = this.trees.find((leaf) => leaf.tableKey === tableKey);
        if (!table) {
            return;
        }

        const tableShape = this.getTableShape(table);
        const newColumnSize = Math.max(...tableShape.map((row) => row.length));
        const delta = getRowspanDelta(tableShape[y]);
        const tableShapeLine = createTableShapeLine(newColumnSize);
        const blocksLine = createBlocksLine(y + 1 + delta, newColumnSize, tableKey);
        tableShape.splice(y + 1 + delta, 0, tableShapeLine);
        this.getTableRoot(table).getData().set(WIKI_TABLE_SHAPE_KEY, tableShape);

        table.children.splice(y + 1 + delta, 0, blocksLine);

        this.updateEditorState();
    }

    public deleteRow(currentBlock) {
        if (!isTableBlock(currentBlock)) {
            return;
        }

        const { tableKey, y: deleteRowIndex } = getBlockTablePosition(currentBlock);

        const table = this.trees.find((leaf) => leaf.tableKey === tableKey);

        if (!table) {
            return;
        }

        const oldTableRootBlock = this.getTableRoot(table);

        const tableShape = this.getTableShape(table);
        tableShape.splice(deleteRowIndex, 1);
        table.children.splice(deleteRowIndex, 1);

        if (deleteRowIndex === 0) {
            const text = this.getTableRoot(table).getText();
            const firstBlock = createTableBlock(
                {
                    tableShape,
                    tableKey,
                    text,
                    colIndex: 0,
                    rowIndex: 0,
                },
                oldTableRootBlock,
            );
            this.setTableRoot(table, firstBlock);
        }

        this.updateEditorState();
    }

    public insertColumn(currentBlock) {
        if (!isTableBlock(currentBlock)) {
            return;
        }

        const { x: insertColumnIndex, tableKey } = getBlockTablePosition(currentBlock);
        const table = this.trees.find((leaf) => leaf.tableKey === tableKey);

        if (!table) {
            return;
        }

        let tableShape = this.getTableShape(table);
        const newColumnSize = tableShape.length;

        const tableShapeMatrix = transpos(tableShape);
        const tableShapeLine = createTableShapeLine(newColumnSize);
        tableShapeMatrix.splice(insertColumnIndex + 1, 0, tableShapeLine);
        tableShape = unTranspos(tableShapeMatrix);

        const transTableBlocksMatrix = transpos(table.children);
        const blocksLine = createBlocksLine(insertColumnIndex + 1, newColumnSize, tableKey);
        transTableBlocksMatrix.splice(insertColumnIndex + 1, 0, blocksLine);
        table.children = unTranspos(transTableBlocksMatrix) as ContentBlock[][];

        const firstBlock = createTableBlock(
            {
                tableShape,
                tableKey,
                colIndex: 0,
                rowIndex: 0,
            },
            this.getTableRoot(table),
        );

        this.setTableRoot(table, firstBlock);

        this.updateEditorState();
    }

    public deleteColumn(currentBlock) {
        if (!isTableBlock(currentBlock)) {
            return;
        }

        const { tableKey, x: deleteColumnIndex } = getBlockTablePosition(currentBlock);

        const table = this.trees.find((leaf) => leaf.tableKey === tableKey);

        if (!table) {
            return;
        }

        let tableShape = this.getTableShape(table);
        const oldTableRootBlock = this.getTableRoot(table);

        const tableShapeMatrix = transpos(tableShape);
        tableShapeMatrix.splice(deleteColumnIndex, 1);
        tableShape = unTranspos(tableShapeMatrix);

        const transTableBlocksMatrix = transpos(table.children);
        transTableBlocksMatrix.splice(deleteColumnIndex, 1);
        table.children = unTranspos(transTableBlocksMatrix) as ContentBlock[][];

        if (deleteColumnIndex === 0) {
            const text = this.getTableRoot(table).getText();
            const firstBlock = createTableBlock(
                {
                    tableShape,
                    tableKey,
                    text,
                    colIndex: 0,
                    rowIndex: 0,
                },
                oldTableRootBlock,
            );
            this.setTableRoot(table, firstBlock);
        } else {
            const firstBlock = createTableBlock(
                {
                    tableShape,
                    tableKey,
                    colIndex: 0,
                    rowIndex: 0,
                },
                this.getTableRoot(table),
            );
            this.setTableRoot(table, firstBlock);
        }

        this.updateEditorState();
    }

    public unionCells(
        selected: TSelectedCells = [
            [-1, -1],
            [-1, -1],
        ],
        currentBlock,
    ) {
        if (selected.length < 2) {
            return;
        }

        if (!isTableBlock(currentBlock)) {
            return;
        }

        const { tableKey } = getBlockTablePosition(currentBlock);
        const table = this.getTable(tableKey);

        if (!table) {
            return;
        }

        const tableRoot = this.getTableRoot(table);
        const tableShape = this.getTableShape(table);
        const [first, ...rest] = selected;
        const inRowUnion = first[0] === rest[0][0];

        if (inRowUnion) {
            const selectedColumnIndex = first[0];
            const indexes = rest.map((s) => s[1]);
            const element = tableShape[selectedColumnIndex][first[1]];
            element.colspan = selected.length;

            tableShape[selectedColumnIndex] = tableShape[selectedColumnIndex].filter(
                (cell, index) => !indexes.includes(index),
            );
            table.children[selectedColumnIndex] = table.children[selectedColumnIndex].filter(
                (cell, index) => !indexes.includes(index),
            );

            this.updateEditorState();

            return;
        }

        // column union

        const indexes = [...rest.map((s) => s[0])];
        const element = tableShape[first[0]][first[1]];

        element.rowspan = selected.length;
        indexes.forEach((rowIndex, i) => {
            const selectedColumnIndex = selected[i + 1][1];
            tableShape[rowIndex].splice(selectedColumnIndex, 1);
            table.children[rowIndex].splice(selectedColumnIndex, 1);
        });

        const firstBlock = createTableBlock(
            {
                tableShape,
                tableKey,
                colIndex: 0,
                rowIndex: 0,
            },
            tableRoot,
        );
        this.setTableRoot(table, firstBlock);

        this.updateEditorState();
    }

    public divideCell(currentBlock) {
        const { tableKey, x, y } = getBlockTablePosition(currentBlock);
        const table = this.getTable(tableKey);

        if (!table) {
            return;
        }

        const tableShape = this.getTableShape(table);

        if (!tableShape) {
            return;
        }

        const shapeElement = tableShape[y][x];

        if (shapeElement.rowspan > 1) {
            const shapeElements = createTableShapeLine(1);
            const blockElements = createBlocksLine(x, 1, tableKey);
            for (let i = 1; i < shapeElement.rowspan; i++) {
                tableShape[y + i].splice(x, 0, ...shapeElements);
                table.children[y + i].splice(x, 0, ...blockElements);
            }

            delete shapeElement.rowspan;
        }

        if (shapeElement.colspan > 1) {
            const shapeElements = createTableShapeLine(shapeElement.colspan - 1);
            const blockElements = createBlocksLine(x, shapeElement.colspan - 1, tableKey);
            tableShape[y].splice(x, 0, ...shapeElements);
            table.children[y].splice(x, 0, ...blockElements);

            delete shapeElement.colspan;
        }

        this.updateEditorState();
    }

    public clearTableCell(currentBlock) {
        if (!isTableBlock(currentBlock)) {
            return;
        }
        const { tableKey, x, y } = getBlockTablePosition(currentBlock);
        const table = this.getTable(tableKey);

        if (!table?.children[y][x]) {
            return;
        }

        table.children[y][x] = table?.children[y][x].set('text', '') as ContentBlock;

        this.updateEditorState();
    }

    public setBlockBGColor(currentBlock, value = '#000000') {
        if (!isTableBlock(currentBlock)) {
            console.log('not a table block');
        }
        const { editorState, changeEditorState } = this;
        const { tableKey, x, y } = getBlockTablePosition(currentBlock);

        const table = this.getTable(tableKey);

        const tableRoot = this.getTableRoot(table);
        const tableShape = this.getTableShape(table);

        const line = tableShape[y];
        const cell = line[x];

        cell.style.backgroundColor = value;

        changeEditorState(setTableShape(editorState, tableRoot, tableShape));
    }

    public toggleHeader(currentBlock) {
        if (!isTableBlock(currentBlock)) {
            return;
        }
        const { tableKey } = getBlockTablePosition(currentBlock);
        const table = this.trees.find((leaf) => leaf.tableKey === tableKey);

        if (!table) {
            return;
        }

        const tableRoot = this.getTableRoot(table);
        const showHeader = !tableRoot.getData().get(WIKI_TABLE_SHOW_HEADER_KEY);
        if (!tableRoot) {
            return;
        }

        this.setTableRoot(
            table,
            createTableBlock(
                {
                    showHeader,
                },
                tableRoot,
            ),
        );

        this.updateEditorState();
    }

    public isShowTableHeader(tableKey): boolean {
        const table = this.trees.find((leaf) => leaf.tableKey === tableKey);

        if (!table) {
            return false;
        }

        const root = this.getTableRoot(table);

        return !!root.getData().get(WIKI_TABLE_SHOW_HEADER_KEY);
    }

    public isSortAvailable(tableKey): boolean {
        const table = this.trees.find((leaf) => leaf.tableKey === tableKey);

        if (!table) {
            return false;
        }

        const tableShape = this.getTableShape(table);

        return this.isShowTableHeader(tableKey) && !tableShape[0].find((cell) => cell.colspan || cell.rowspan);
    }

    public sortColumn(tableKey: string, columnIndex: number, sortFn = defaultSortFn) {
        const table = this.trees.find((leaf) => leaf.tableKey === tableKey);

        if (!table) {
            return;
        }

        const spanMap = this.getSpanMap(table);
        const hashMap: { key: number; value: string; relativeColumnIndex: number }[] = [];

        for (let i = 1; i < table.children.length; i++) {
            const [, relativeColumnIndex] = spanMap[i][columnIndex];
            const cellBlock = table.children[i][relativeColumnIndex];

            hashMap.push({ value: cellBlock.getText(), key: i, relativeColumnIndex });
        }

        const sortedHashMap = clone(hashMap).sort((a, b) => {
            return sortFn(a.value, b.value);
        });

        sortedHashMap.forEach((hash, i) => {
            const { relativeColumnIndex, key } = hashMap[i];
            const { value } = hash;
            table.children[key][relativeColumnIndex] = table.children[key][relativeColumnIndex].set(
                'text',
                value,
            ) as ContentBlock;
        });

        this.updateEditorState();
    }

    private getSpanMap(table) {
        const { cols, rows } = this.getTableSize(table);

        const map = Array(rows)
            .fill(0)
            .map((row, i) =>
                Array(cols)
                    .fill(row)
                    .map((_, j) => [i, j]),
            );

        const tableShape = this.getTableShape(table);

        for (let i = 0; i < tableShape.length; i++) {
            const row = tableShape[i];
            for (let j = 0; j < row.length; j++) {
                const cell = row[j];
                const rowspan = parseInt(cell.rowspan, 10);
                if (rowspan) {
                    for (let k = 0; k < rowspan; k++) {
                        const currentRow = map[i + k];
                        currentRow[j] = [i, j];

                        let index = currentRow[k][0] === i ? currentRow[k][0] : 0;
                        for (let m = j + 1; m < currentRow.length; m++) {
                            currentRow[m][1] = index;
                            index++;
                        }
                    }
                }

                const colspan = parseInt(cell.colspan, 10);
                if (colspan) {
                    const currentRow = map[i];

                    for (let k = 0; k < colspan; k++) {
                        currentRow[j + k + 1] = [i, j];
                    }
                    let index = currentRow[j + colspan][1] + 1;
                    for (let k = j + colspan + 1; k < currentRow.length; k++) {
                        if (currentRow[k][0] === i) {
                            currentRow[k][1] = index;
                            index++;
                        }
                    }
                }
            }
        }

        return map;
    }

    private getTableSize(table) {
        const tableShape = this.getTableShape(table);
        const rows = tableShape.length;
        const firstRow = tableShape[0];

        let cols = 0;
        for (let i = 0; i < firstRow.length; i++) {
            const cell = firstRow[i];

            if (cell.colspan) {
                cols += parseInt(cell.colspan, 10);
            } else {
                cols++;
            }
        }

        return { rows, cols };
    }

    public getTable(tableKey) {
        return this.trees.find((leaf) => leaf.tableKey === tableKey);
    }

    private getTableShape(table) {
        return this.getTableRoot(table).getData().get(WIKI_TABLE_SHAPE_KEY);
    }

    private getTableRoot(table) {
        return table.children[0][0];
    }

    private setTableRoot(table: TLeaf, block) {
        table.children[0][0] = block;
    }

    private parseState() {
        const content = this.editorState.getCurrentContent();
        const blockMap = content.getBlockMap();

        const getTableTree = (plain): TLeaf => {
            const mainBlockData = plain[0].getData();
            const tableKey = mainBlockData.get(WIKI_TABLE_KEY);
            const children = {};

            plain.forEach((block) => {
                const data = block?.getData();
                const [, x] = data.get(WIKI_TABLE_POSITION_KEY).split('-');

                children[x] = children[x] || [];
                children[x].push(block);
            });

            return {
                tableKey,
                children: values(children),
            };
        };

        const trees: TLeaf[] = [];
        let tableCells: ContentBlock[] = [];

        blockMap.forEach((block: ContentBlock) => {
            const data = block?.getData();

            if (!data?.get(WIKI_TABLE_KEY)) {
                if (tableCells.length) {
                    trees.push(getTableTree(tableCells));
                    tableCells = [];
                }

                trees.push({
                    children: [],
                    simpleBlock: block,
                });

                return;
            }

            tableCells.push(block);
        });

        return trees;
    }

    private updateEditorState() {
        const { trees, editorState, changeEditorState } = this;

        const flatTable = (root) => {
            const flat: ContentBlock[] = [];
            root.forEach((row, rowIndex) => {
                row.forEach((block, colIndex) => {
                    const tableKey = block.getData().get(WIKI_TABLE_KEY);
                    const newBlock = createTableBlock(
                        {
                            tableKey,
                            colIndex,
                            rowIndex,
                        },
                        block,
                    );

                    flat.push(newBlock);
                });
            });

            return flat;
        };

        const newBlockArray: ContentBlock[] = [];
        const entityMap = editorState.getCurrentContent().getEntityMap();

        trees.forEach((tree) => {
            if (!tree?.children?.length && tree.simpleBlock) {
                newBlockArray.push(tree.simpleBlock);

                return;
            }

            newBlockArray.push(...flatTable(tree.children));
        });

        const newContentState = ContentState.createFromBlockArray(newBlockArray, entityMap);
        const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');

        changeEditorState(newEditorState);
    }
}
