import { GET_ACLS_BY_TYPE_SUCCESS, SET_ACL_RIGHTS, SET_ALL_ACL_RIGHTS } from '../actionsTypes/acl.actionTypes';
import { PresetTypesAcl } from '../serverapi/api';
import { CustomMap } from '../utils/map';
import { TReducer } from '../utils/types';
import { TAclState } from './acl.reducer.types';

const initial: TAclState = new CustomMap();

export const aclReducer: TReducer<TAclState> = (state = initial, action) => {
    switch (action.type) {
        case GET_ACLS_BY_TYPE_SUCCESS: {
            const { presetId, serverId, acls, type, profileId } = action.payload;
            const clonState = state.clone();
            clonState.set(
                { presetId, serverId, type, profileId },
                {
                    acls,
                },
            );

            return clonState;
        }

        case SET_ACL_RIGHTS: {
            const { profileId, presetId, serverId, ids, type, rightKey, rightValue } = action.payload;
            const clonState = state.clone();
            const { acls } = clonState.get({ presetId, serverId, type, profileId });

            ids.forEach((id) => {
                const index = acls.findIndex((acl) => acl.id === id);
                if (index === -1) {
                    const newAclItem = { id, [rightKey]: rightValue };
                    acls.push(newAclItem);
                } else {
                    const newAclItem = { ...acls[index], [rightKey]: rightValue };
                    acls.splice(index, 1, newAclItem);
                }
            });

            clonState.set(
                { presetId, serverId, type, profileId },
                {
                    acls,
                },
            );

            return clonState;
        }

        case SET_ALL_ACL_RIGHTS: {
            const { presetId, serverId, type, allow, entityTypeIds, profileId } = action.payload;
            const clonState = state.clone();

            const acls: PresetTypesAcl[] = entityTypeIds.map((id) => ({
                id,
                create: allow,
                read: allow,
                update: allow,
                delete: allow,
            }));

            clonState.set(
                { presetId, serverId, type, profileId },
                {
                    acls,
                },
            );

            return clonState;
        }

        default:
            return state;
    }
};
