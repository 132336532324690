import { defineMessages } from 'react-intl';

export default defineMessages({
    params: {
        id: 'DatetimeSchedulerSettings.params',
        defaultMessage: 'Параметры',
    },
    singleCase: {
        id: 'DatetimeSchedulerSettings.singleCase',
        defaultMessage: 'Однократно',
    },
    daily: {
        id: 'DatetimeSchedulerSettings.daily',
        defaultMessage: 'Ежедневно',
    },
    weekly: {
        id: 'DatetimeSchedulerSettings.weekly',
        defaultMessage: 'Еженедельно',
    },
    monthly: {
        id: 'DatetimeSchedulerSettings.monthly',
        defaultMessage: 'Ежемесячно',
    },
    start: {
        id: 'DatetimeSchedulerSettings.start',
        defaultMessage: 'Начать:',
    },
    selectDatetime: {
        id: 'DatetimeSchedulerSettings.selectDatetime',
        defaultMessage: 'Выберите дату и время',
    },
    additionalParams: {
        id: 'DatetimeSchedulerSettings.additionalParams',
        defaultMessage: 'Дополнительные параметры',
    },
    repeatEvery: {
        id: 'DatetimeSchedulerSettings.repeatEvery',
        defaultMessage: 'Повторять задачу каждые:',
    },
    while: {
        id: 'DatetimeSchedulerSettings.while',
        defaultMessage: 'в течение:',
    },
    endTime: {
        id: 'DatetimeSchedulerSettings.endTime',
        defaultMessage: 'Срок действия:',
    },
    empty: {
        id: 'DatetimeSchedulerSettings.empty',
        defaultMessage: 'пусто',
    },
    seconds: {
        id: 'DatetimeSchedulerSettings.seconds',
        defaultMessage: 'секунд',
    },
    minutes: {
        id: 'DatetimeSchedulerSettings.minutes',
        defaultMessage: 'минут',
    },
    hour: {
        id: 'DatetimeSchedulerSettings.hour',
        defaultMessage: 'час',
    },
    hourGenitive: {
        id: 'DatetimeSchedulerSettings.hourGenitive',
        defaultMessage: 'часа',
    },
    hoursGenitive: {
        id: 'DatetimeSchedulerSettings.hoursGenitive',
        defaultMessage: 'часов',
    },
    day: {
        id: 'DatetimeSchedulerSettings.day',
        defaultMessage: 'день',
    },
    dayGenitive: {
        id: 'DatetimeSchedulerSettings.dayGenitive',
        defaultMessage: 'дня',
    },
    daylyLabel: {
        id: 'DatetimeSchedulerSettings.daylyLabel',
        defaultMessage: 'Повторять каждые:',
    },
    daylyAddon: {
        id: 'DatetimeSchedulerSettings.daylyAddon',
        defaultMessage: 'дн.',
    },
    weeklyLabel: {
        id: 'DatetimeSchedulerSettings.weeklyLabel',
        defaultMessage: 'Повторять каждую:',
    },
    weeklyAddon: {
        id: 'DatetimeSchedulerSettings.weeklyAddon',
        defaultMessage: 'неделю',
    },
    selectWeekDays: {
        id: 'DatetimeSchedulerSettings.selectWeekDays',
        defaultMessage: 'Выберите дни недели',
    },
    at: {
        id: 'DatetimeSchedulerSettings.at',
        defaultMessage: 'в:',
    },
    MONDAY: {
        id: 'DatetimeSchedulerSettings.MONDAY',
        defaultMessage: 'понедельник',
    },
    TUESDAY: {
        id: 'DatetimeSchedulerSettings.TUESDAY',
        defaultMessage: 'вторник',
    },
    WEDNESDAY: {
        id: 'DatetimeSchedulerSettings.WEDNESDAY',
        defaultMessage: 'среда',
    },
    THURSDAY: {
        id: 'DatetimeSchedulerSettings.THURSDAY',
        defaultMessage: 'четверг',
    },
    FRIDAY: {
        id: 'DatetimeSchedulerSettings.FRIDAY',
        defaultMessage: 'пятница',
    },
    SATURDAY: {
        id: 'DatetimeSchedulerSettings.SATURDAY',
        defaultMessage: 'суббота',
    },
    SUNDAY: {
        id: 'DatetimeSchedulerSettings.SUNDAY',
        defaultMessage: 'воскресенье',
    },
    all: {
        id: 'DatetimeSchedulerSettings.all',
        defaultMessage: 'выбрать все',
    },
    last: {
        id: 'DatetimeSchedulerSettings.last',
        defaultMessage: 'последний',
    },
    lastAccusative: {
        id: 'DatetimeSchedulerSettings.lastAccusative',
        defaultMessage: 'последнюю',
    },
    months: {
        id: 'DatetimeSchedulerSettings.months',
        defaultMessage: 'Месяцы:',
    },
    selectMonths: {
        id: 'DatetimeSchedulerSettings.selectMonths',
        defaultMessage: 'Выберите месяцы',
    },
    JANUARY: {
        id: 'DatetimeSchedulerSettings.JANUARY',
        defaultMessage: 'январь',
    },
    FEBRUARY: {
        id: 'DatetimeSchedulerSettings.FEBRUARY',
        defaultMessage: 'февраль',
    },
    MARCH: {
        id: 'DatetimeSchedulerSettings.MARCH',
        defaultMessage: 'март',
    },
    APRIL: {
        id: 'DatetimeSchedulerSettings.APRIL',
        defaultMessage: 'апрель',
    },
    MAY: {
        id: 'DatetimeSchedulerSettings.MAY',
        defaultMessage: 'май',
    },
    JUNE: {
        id: 'DatetimeSchedulerSettings.JUNE',
        defaultMessage: 'июнь',
    },
    JULY: {
        id: 'DatetimeSchedulerSettings.JULY',
        defaultMessage: 'июль',
    },
    AUGUST: {
        id: 'DatetimeSchedulerSettings.AUGUST',
        defaultMessage: 'август',
    },
    SEPTEMBER: {
        id: 'DatetimeSchedulerSettings.SEPTEMBER',
        defaultMessage: 'сентябрь',
    },
    OCTOBER: {
        id: 'DatetimeSchedulerSettings.OCTOBER',
        defaultMessage: 'октябрь',
    },
    NOVEMBER: {
        id: 'DatetimeSchedulerSettings.NOVEMBER',
        defaultMessage: 'ноябрь',
    },
    DECEMBER: {
        id: 'DatetimeSchedulerSettings.DECEMBER',
        defaultMessage: 'декабрь',
    },
    days: {
        id: 'DatetimeSchedulerSettings.days',
        defaultMessage: 'Дни:',
    },
    selectDays: {
        id: 'DatetimeSchedulerSettings.selectDays',
        defaultMessage: 'Выберите дни',
    },
    in: {
        id: 'DatetimeSchedulerSettings.in',
        defaultMessage: 'В:',
    },
    selectWeeks: {
        id: 'DatetimeSchedulerSettings.selectWeeks',
        defaultMessage: 'Выберите недели',
    },
    repeatEveryError: {
        id: 'DatetimeSchedulerSettings.repeatEveryError',
        defaultMessage: 'Длительность повтора задачи не может быть меньше его интервала',
    },
});
