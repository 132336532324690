import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import { NodeId } from '../../../serverapi/api';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { createDashboard } from '../../../actions/dashboard.actions';
import messages from './NewDashboardDialog.messages';

type TNewDashboardDialogProps = {
    parentNodeId: NodeId;
};

export const NewDashboardDialog = (props: TNewDashboardDialogProps) => {
    const [name, setName] = useState<string>('');
    const dispatch = useDispatch();
    const { parentNodeId } = props;

    const onClose = () => dispatch(closeDialog(DialogType.DASHBOARD_CREATE_DIALOG));
    const onSubmit = (name: string, parentNodeId: NodeId) => dispatch(createDashboard(name, parentNodeId));

    const intl = useIntl();
    const footer = [
        <Button key="cancel_button" onClick={onClose}>
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button key="save_button" disabled={!name?.trim()} onClick={() => onSubmit(name, parentNodeId)}>
            {intl.formatMessage(messages.save)}
        </Button>,
    ];

    return (
        <Dialog
            open
            okButtonProps={{ disabled: !name }}
            onOk={() => onSubmit(name, parentNodeId)}
            onCancel={onClose}
            title={intl.formatMessage(messages.title)}
            footer={footer}
        >
            <Form layout="vertical">
                <Form.Item
                    label={intl.formatMessage(messages.name)}
                    name="dashboardTitle"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: intl.formatMessage(messages.requiredField),
                        },
                    ]}
                >
                    <Input
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                        value={name}
                        autoComplete="off"
                        autoFocus
                    />
                </Form.Item>
            </Form>
        </Dialog>
    );
};
