import React, { FC } from 'react';
import theme from './ApprovalsTab.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { hasModelEditorLicense } from '@/selectors/authorization.selectors';
import icApprovalsNotFound from '../../../../resources/icons/ic-aprovals-not-found.svg';
import icCreateApproval from '../../../../resources/icons/ic-create-approval.svg';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import messages from './ApprovalsTab.messages';
import { createApproval } from '@/actions/approval.actions';
import { NodeId } from '@/serverapi/api';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import icAddApprovalStage from '../../../../resources/icons/ic-add-approval-stage.svg';
import { Approval } from './Approval.component';

type TApprovalsTab = {
    approvedItemNodeId: NodeId;
};

export const ApprovalsTab: FC<TApprovalsTab> = ({ approvedItemNodeId }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const isModelEditor = useSelector(hasModelEditorLicense);
    const approvals = useSelector(ApprovalSelectors.byApprovedItemIdArrNoDraft(approvedItemNodeId));

    const onCreateApprovalClick = () => {
        dispatch(createApproval({ approvedItemNodeId }));
    };

    return (
        <>
            {approvals.length === 0 ? (
                <div className={theme.approvalsNotFoundContainer}>
                    <Icon spriteSymbol={icApprovalsNotFound} className={theme.approvalNotFoundIcon} />
                    <div>{intl.formatMessage(messages.approvalIsNotCreatedYet)}</div>
                    {isModelEditor ? (
                        <button className={theme.createApprovalBtn} onClick={onCreateApprovalClick}>
                            <Icon spriteSymbol={icCreateApproval} className={theme.approvalCreateIcon} />
                            {intl.formatMessage(messages.createApproval)}
                        </button>
                    ) : null}
                </div>
            ) : (
                <div className={theme.approvals}>
                    <button className={theme.newApprovalBtn} onClick={onCreateApprovalClick}>
                        <Icon spriteSymbol={icAddApprovalStage} className={theme.newApprovalIcon} />
                        {intl.formatMessage(messages.newApproval)}
                    </button>
                    {approvals.map((approval) => {
                        return <Approval approval={approval} key={approval.id.id} />;
                    })}
                </div>
            )}
        </>
    );
};
