import { Button, Popover, Tooltip } from 'antd';
import cx from 'classnames';
import React, { FC, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import mainMenuTheme from '../items/MenuItem.scss';
import theme from './GraphObjectToolbar.scss';
import messages from '../../messages/CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { Icon } from '../../../UIKit';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralMenuEdgeTypeSelectorState } from '../../../../selectors/generalMenu.selectors';
import icFontTextColor from '../../../../resources/icons/toolbar/font.svg';
import icFilling from '../../../../resources/icons/color-filling.svg';
import { changeEdgeColor, changeFontColor, editorCellColorPickedAction } from '../../../../actions/editor.actions';
import { MxCell } from '../../../../mxgraph/mxgraph';

type TGraphObjectToolbarColorSettingsProps = {
    disabled: boolean;
    selectedCells: MxCell[];
    compact: boolean;
};

export const GraphObjectToolbarColorSettings: FC<TGraphObjectToolbarColorSettingsProps> = ({
    disabled,
    selectedCells,
    compact,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const edgeTypeSelectorState = useSelector(getGeneralMenuEdgeTypeSelectorState);
    const isEdgeSelected = edgeTypeSelectorState.currentTypeIndex !== -1;
    const edgeEditDisabled = !isEdgeSelected || disabled;
    const colorFillingDisabled = disabled && edgeEditDisabled;
    const hasSelectedEdge = selectedCells.some((cell) => cell.isEdge());
    const hasSelectedObject = selectedCells.some((cell) => cell.isVertex());

    const [fontColor, setFontColor] = useState<string>('');
    const [color, setColor] = useState<string>('');

    const handleColorChange = (color: ColorResult) => {
        setColor(color.hex);
        if (hasSelectedEdge) dispatch(changeEdgeColor(color.hex));
        if (hasSelectedObject) dispatch(editorCellColorPickedAction(color.hex));
    };

    const handleFontColorChange = (color: ColorResult) => {
        setFontColor(color.hex);
        dispatch(changeFontColor(color.hex));
    };

    return (
        <span className={cx(mainMenuTheme.spanGroupRow, theme.spanGroupRow, compact && theme.compact)}>
            <Tooltip
                title={
                    !disabled ? intl.formatMessage(messages.fontColor) : intl.formatMessage(messages.selectEdgeOrObject)
                }
                mouseLeaveDelay={0}
            >
                <span className={cx(mainMenuTheme.buttonWrapper, disabled && mainMenuTheme.buttonDisabled)}>
                    <Popover content={<SketchPicker color={fontColor} onChange={handleFontColorChange} disableAlpha/>}>
                        <Button
                            data-test="object-toolbar_font-text-color"
                            className={mainMenuTheme.buttonTextFormat}
                            disabled={disabled}
                        >
                            <Icon disabled={disabled} spriteSymbol={icFontTextColor} />
                        </Button>
                    </Popover>
                </span>
            </Tooltip>
            <Tooltip
                title={
                    !disabled || !edgeEditDisabled
                        ? intl.formatMessage(messages.colorFilling)
                        : intl.formatMessage(messages.selectEdgeOrObject)
                }
                mouseLeaveDelay={0}
            >
                <span className={cx(mainMenuTheme.buttonWrapper, colorFillingDisabled && mainMenuTheme.buttonDisabled)}>
                    <Popover content={<SketchPicker color={color} onChange={handleColorChange} disableAlpha/>}>
                        <Button
                            data-test="object-toolbar_font-text-format"
                            className={mainMenuTheme.buttonTextFormat}
                            disabled={colorFillingDisabled}
                        >
                            <Icon spriteSymbol={icFilling} disabled={colorFillingDisabled} />
                        </Button>
                    </Popover>
                </span>
            </Tooltip>
        </span>
    );
};
