import React from 'react';
import { useDispatch } from 'react-redux';
import messages from './SelectScriptToScheduleDialog.messages';
import { useIntl } from 'react-intl';
import { TreeNode } from '../../../models/tree.types';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { handleScriptSubmittedViaNode } from '../../../actions/entities/script.actions';
import { DiagramElement, NodeId, ScriptParameter } from '../../../serverapi/api';
import { ChooseScriptDialog } from '@/modules/Script/DiagramScriptExececutionDialog/ChooseScriptDialog.component';
import { IScriptNode } from '@/models/bpm/bpm-model-impl.types';

type TSelectScriptToScheduleDialogProps = {
    serverId: string;
    nodeId: NodeId;
    nodesIdsList: NodeId[];
    modelId?: NodeId;
    element?: DiagramElement;
    elementsIdsList?: string[];
    setScriptDataState: (data: {
        script: IScriptNode;
        existingParams: ScriptParameter[];
        parsedParams: ScriptParameter[];
    }) => void;
};

export const SelectScriptToScheduleDialog = (props: TSelectScriptToScheduleDialogProps) => {
    const { serverId, nodeId, nodesIdsList, modelId, element, elementsIdsList, setScriptDataState } = props;

    const intl = useIntl();
    const dispatch = useDispatch();

    const onSubmit = (selectedScript: TreeNode) => {
        dispatch(
            handleScriptSubmittedViaNode({
                scriptNodeId: { ...selectedScript.nodeId, serverId },
                nodeId,
                nodesIdsList,
                modelId,
                elementId: element?.id,
                elementsIdsList,
                setScriptDataState,
            }),
        );
    };

    return (
        <ChooseScriptDialog
            serverId={serverId}
            nodeId={nodeId}
            dialogType={DialogType.SELECT_SCRIPT_TO_SCHEDULE_DIALOG}
            modelId={modelId}
            isContextSensitive
            title={intl.formatMessage(messages.scriptSelectorDialogName)}
            okButtonTitle={intl.formatMessage(messages.scriptFormConfirmButtonRun)}
            errorMessage={intl.formatMessage(messages.scriptNotSelectedError)}
            onSubmit={onSubmit}
        />
    );
};
