// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditableText__container__ePeHl{margin-bottom:0;width:100%}.EditableText__container__ePeHl .ant-form-item{margin-bottom:0}.EditableText__multiLineEllipsis__y9BVn{overflow:hidden;display:-webkit-box;box-orient:vertical;-webkit-line-clamp:5;-webkit-box-orient:vertical}.EditableText__linkWrapper__cRm31{color:#41bcfb;cursor:pointer;width:100%}.EditableText__textWrapper___TIkF{white-space:pre-wrap}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/EditableText/EditableText.scss"],"names":[],"mappings":"AAAA,gCACI,eAAA,CACA,UAAA,CAEI,+CACI,eAAA,CAKZ,wCACI,eAAA,CACA,mBAAA,CACA,mBAAA,CACA,oBAAA,CACA,2BAAA,CAGJ,kCACI,aAAA,CACA,cAAA,CACA,UAAA,CAGJ,kCACI,oBAAA","sourcesContent":[".container {\r\n    margin-bottom: 0;\r\n    width: 100%;\r\n    :global {\r\n        .ant-form-item {\r\n            margin-bottom: 0;\r\n        }\r\n    }\r\n}\r\n\r\n.multiLineEllipsis {\r\n    overflow: hidden;\r\n    display: -webkit-box;\r\n    box-orient: vertical;\r\n    -webkit-line-clamp: 5;\r\n    -webkit-box-orient: vertical;\r\n}\r\n\r\n.linkWrapper {\r\n    color: #41bcfb;\r\n    cursor: pointer;\r\n    width: 100%;\r\n}\r\n\r\n.textWrapper {\r\n    white-space: pre-wrap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EditableText__container__ePeHl",
	"multiLineEllipsis": "EditableText__multiLineEllipsis__y9BVn",
	"linkWrapper": "EditableText__linkWrapper__cRm31",
	"textWrapper": "EditableText__textWrapper___TIkF"
};
export default ___CSS_LOADER_EXPORT___;
