import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import GroupDataEditingTab from '../componets/GroupDataEditingTab.component';
import { TGroupDataEditingTabActionProps, TGroupDataEditingTabProps } from '../GroupDataEditingTab.types';
import { GroupsSelectors } from '../../../../selectors/groups.selectors';
import { fetchGroupById, submitingGroupData, saveGroupMainDataChanges } from '../../../../actions/groups.actions';
import { workspaceRemoveTabRequest } from '../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { TWorkspaceTab } from '../../../../models/tab.types';
import { GroupDTO } from '../../../../serverapi/api';
import { withWindowResize } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';

const mapStateToProps = (state: TRootState): Partial<TGroupDataEditingTabProps> => {
    const tab = TabsSelectors.getActiveTab(state);

    return {
        groupData: GroupsSelectors.getEditing(tab?.nodeId.id || '')(state),
        tab,
    };
};

const mapDispatchToProps: (dispatch) => Partial<TGroupDataEditingTabActionProps> = (dispatch) => ({
    onSubmit: (groupData: GroupDTO, isNewGroup: boolean) => dispatch(submitingGroupData({ groupData, isNewGroup })),
    onClose: (tab: TWorkspaceTab) => dispatch(workspaceRemoveTabRequest(tab)),
    fetchGroupFullData: (groupId: number) => dispatch(fetchGroupById(groupId)),
    onChangeMainData: (groupData: GroupDTO) => dispatch(saveGroupMainDataChanges(groupData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withWindowResize(GroupDataEditingTab));
