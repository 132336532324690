import {
    mxLine,
    mxObjectCodec,
    mxGraphModel,
    mxGraph,
    mxGraphView,
    mxRectangle,
    mxPoint,
    mxUtils,
    mxClipboard,
    mxConstants,
    mxGraphHandler,
    mxEdgeStyle,
    mxShape,
    mxCell,
    mxGeometry,
    mxStencil,
    mxStencilRegistry,
    mxSwimlaneManager,
    mxEdgeHandler,
    mxConnectionHandler,
    mxRubberband,
    mxKeyHandler,
    mxEvent,
    mxUndoableEdit,
    mxUndoManager,
    mxEventObject,
    mxCellRenderer,
    mxClient,
    mxRectangleShape,
    mxMouseEvent,
    mxVertexHandler,
    mxEllipse,
    mxHandle,
    mxDragSource,
    mxGuide,
    mxConnectionConstraint,
    mxPopupMenuHandler,
    mxPopupMenu,
    mxCellEditor,
    mxConstraintHandler,
    mxImageShape,
    mxImage,
    mxCellState,
    mxPerimeter,
    mxStackLayout,
    mxLayoutManager,
    mxSwimlaneLayout,
    mxCellOverlay,
    mxOutline,
    mxMorphing,
    mxStyleRegistry,
    mxEdgeSegmentHandler,
    mxCodec,
    mxSvgCanvas2D,
    mxImageExport,
    mxSelectionCellsHandler,
    mxResources,
    mxGraphLayout,
    mxFastOrganicLayout,
    mxHierarchicalLayout,
    mxCircleLayout,
    mxPanningHandler,
    mxStylesheet,
    mxGraphSelectionModel,
    mxDictionary,
    Graph,
    Editor,
    Sidebar,
    mxStyleChange,
    mxLabel,
    mxMarker,
    mxCellMarker,
    mxLog,
    mxTooltipHandler,
    mxCellHighlight
} from '../../../bpm-drawio-lib/dist'; // TODO: поменять на импорт из npm после публикации

export { mxGraphSelectionModel as MxGraphSelectionModel };

export { mxStylesheet as MxStylesheet };

export { mxPanningHandler as MxPanningHandler };

export { mxGraphModel as MxGraphModel };

export { mxGraphView as MxGraphView };

export { mxRectangle as MxRectangle };

export { mxPoint as MxPoint };

export { mxUtils as MxUtils };

export { mxClipboard as MxClipboard };

export { mxConstants as MxConstants };

export { mxEdgeStyle as MxEdgeStyle };

export { mxGraphHandler as MxGraphHandler };

export { mxKeyHandler as MxKeyHandler };

export { mxEvent as MxEvent };

export { mxShape as MxShape };

export { mxStencil as MxStencil };

export { mxStencilRegistry as MxStencilRegistry };

export { mxSwimlaneManager as MxSwimlaneManager };

export { mxEdgeHandler as MxEdgeHandler };

export { mxConnectionHandler as MxConnectionHandler };

export { mxRubberband as MxRubberband };

export { mxEventObject as MxEventObject };

export { mxUndoManager as MxUndoManager };

export { mxUndoableEdit as MxUndoableEdit };

export { mxClient as MxClient };

export { mxCellRenderer as MxCellRenderer };

export { mxHandle as MxHandle };

export { mxEllipse as MxEllipse };

export { mxMouseEvent as MxMouseEvent };

export { mxRectangleShape as MxRectangleShape };

export { mxVertexHandler as MxVertexHandler };

export { mxDragSource as MxDragSource };

export { mxGuide as MxGuide };

export { mxGeometry as MxGeometry };

export { mxCell as MxCell };

export { mxConnectionConstraint as MxConnectionConstraint };

export { mxPopupMenuHandler as MxPopupMenuHandler };

export { mxPopupMenu as MxPopupMenu };

export { mxCellEditor as MxCellEditor };

export { mxConstraintHandler as MxConstraintHandler };

export { mxImageShape as MxImageShape };

export { mxImage as MxImage };

export { mxCellState as MxCellState };

export { mxPerimeter as MxPerimeter };

export { mxCellOverlay as MxCellOverlay };

export { mxOutline as MxOutline };

export { mxMorphing as MxMorphing };

export { mxStyleRegistry as MxStyleRegistry };

export { mxEdgeSegmentHandler as MxEdgeSegmentHandler };

export { mxCodec as MxCodec };

export { mxObjectCodec as MxObjectCodec };

export { mxSvgCanvas2D as MxSvgCanvas2D };

export { mxImageExport as MxImageExport };

export { mxSelectionCellsHandler as MxSelectionCellsHandler };

export { mxResources as MxResources };

export { mxStackLayout as MxStackLayout };

export { mxHierarchicalLayout as MxHierarchicalLayout };

export { mxLayoutManager as MxLayoutManager };

export { mxSwimlaneLayout as MxSwimlaneLayout };

export { mxGraphLayout as MxGraphLayout };

export { mxCircleLayout as MxCircleLayout };

export { mxFastOrganicLayout as MxFastOrganicLayout };

export { mxLine as MxLine };

export { mxGraph as MxGraph };

export { Editor, Sidebar, Graph };

export { mxDictionary as MxDictionary };

export { mxStyleChange as MxStyleChange };

export { mxLabel as MxLabel };

export { mxMarker as MxMarker };

export { mxCellMarker as MxCellMarker };

export { mxLog as MxLog };

export { mxTooltipHandler as MxTooltipHandler };

export { mxCellHighlight as MxCellHighlight };

// mxClient.link('stylesheet', `${mxClient.basePath}/../drawio/styles/grapheditor.css`);
