import { createSelector } from 'reselect';
import { NodeId } from '../../src/serverapi/api';
import { TRootState } from '../reducers/root.reducer.types';

export const getActiveGraph = (state: TRootState): NodeId | undefined => state.schemes?.activeId;

export const getWhereCopiedFrom = (state: TRootState) => state.copy.whereCopiedFrom;

export const getCopiedElements = (state: TRootState) => state.copy.elements;

export const getEditorState = (state: TRootState) => state.editor;

export const getSelectedCellIds = createSelector(
    getEditorState,
    getActiveGraph,
    (state, activeGraphId) => (activeGraphId && state.data.get(activeGraphId)?.selectedCellsIds) || [],
);
