export default {
    USERS_EDITOR: 'USERS_EDITOR',
    DB_EDITOR: 'DB_EDITOR',
    DB_ADMIN: 'DB_ADMIN',
    SCRIPT_EDITOR: 'SCRIPT_EDITOR',
    SCRIPT_RUNNER: 'SCRIPT_RUNNER',
    META_EDITOR: 'META_EDITOR',
    LICENSE_EDITOR: 'LICENSE_EDITOR',
    SECURITY_ADMIN: 'SECURITY_ADMIN',
    LOG_READER: 'LOG_READER',
    SERVER_ADMIN: 'SERVER_ADMIN',
    PROFILE_EDITOR: 'PROFILE_EDITOR',
    EVENT_ACCESS: 'EVENT_ACCESS',
    ACCESS_KEY_ADMIN: 'ACCESS_KEY_ADMIN',
    SHOW_DELETED_OBJECT: 'SHOW_DELETED_OBJECT',
    APPROVAL_ADMIN: 'APPROVAL_ADMIN',
    DB_AUDIT: 'DB_AUDIT',
    SERVER_AUDIT: 'SERVER_AUDIT',
};
