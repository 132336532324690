import { Select } from 'antd';
import React from 'react';
import { AttributeType, AttributeTypeSelectPropertyValue, AttributeValue } from '../../../../../../serverapi/api';
import { getAntdPopupContainer } from '../../../../../../utils/getAntdPopupContainer';
import { LocalesService } from '../../../../../../services/LocalesService';
import theme from './StorageDefaultValueToComponent.scss';
import { getAttrbuteValue } from './util/attributeTypeEditorDialog.utils';

type TSelectComponent = {
    attributeType: AttributeType,
    onChangeDefaultValue: (attributeValue: AttributeValue | undefined) => void,
}

export const SelectComponent = (props: TSelectComponent) => {
    const { attributeType, onChangeDefaultValue } = props;
    const defaultValue = attributeType.attributeDefaultValue?.value;
    const values: AttributeTypeSelectPropertyValue[] | undefined = attributeType.selectPropertyValues;
    const notEmptyValues: AttributeTypeSelectPropertyValue[] = values?.filter((value) => value.id) || []

    return (
        <div className={theme.selectContainer}>
            <Select
                data-test="properties-window_select-attribute_value-select"
                defaultValue={defaultValue}
                onChange={(value) => {
                    onChangeDefaultValue({
                        ...getAttrbuteValue(attributeType),
                        value: value,
                    });
                }}
                allowClear={true}
                getPopupContainer={getAntdPopupContainer}
                placement="topLeft"
            >
                {notEmptyValues.map((v) => {
                    return (
                        <Select.Option
                            data-test={`attribute-SELECT_select-value_${LocalesService.internationalStringToString(
                                v.value,
                            )}`}
                            key={v.id}
                            value={v.id}
                        >
                            {LocalesService.internationalStringToString(v.value) || v.id}
                        </Select.Option>
                    );
                })}
            </Select>
        </div>
    );
};
