import { TReducer } from '../../utils/types';
import {
    MATRIX_REQUEST_SUCCESS,
    MATRIX_SAVE_REQUEST_SUCCESS,
    CHANGE_MATRIX_PROPERTIES,
} from '../../actionsTypes/entities/matrix.actionTypes';
import { TNodeState } from './TNodeState';
import { MatrixNode } from '../../serverapi/api';
import { TMatrixState } from './matrix.reducer.types';

const INITIAL_MATRIX_STATE: TMatrixState = new TNodeState();

export const matrixReducer: TReducer<TMatrixState> = (state = INITIAL_MATRIX_STATE, action) => {
    switch (action.type) {
        case MATRIX_SAVE_REQUEST_SUCCESS:
        case MATRIX_REQUEST_SUCCESS: {
            const {
                payload: { matrix },
            } = action;

            return state.set(matrix.nodeId, matrix);
        }

        case CHANGE_MATRIX_PROPERTIES: {
            const {
                payload: { matrix, nodeId },
            } = action;
            const existMatrix: MatrixNode = state.byNodeId.get(nodeId);
            if (existMatrix) {
                return state.set(nodeId, { ...existMatrix, ...matrix });
            }
 
            return state.set(nodeId, { ...matrix, nodeId });
            
        }

        default:
            return state;
    }
};
