import type { TTreeEntityState } from '@/models/tree.types';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { TREE_ITEM_CONTEXT_MENU_ACTION, TREE_ITEM_MANAGE_PERMISSIONS_REQUEST } from '../actionsTypes/tree.actionTypes';
import { treeItemManagePermissionsRequest } from '../actions/tree.actions';
import { TTreeItemManagePermissionsRequestAction, TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction } from '../modules/Tree/models/tree';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import {
    INSTANCE_PERMISSIONS_DIALOG_SUBMIT_RESULT,
    INSTANCE_PERMISSIONS_REQUEST,
} from '../actionsTypes/instancePermissions.actionTypes';
import {
    addPrincipals,
    instancePermissionsData,
    instancePermissionsFailure,
    instancePermissionsRequest,
} from '../actions/instancePermissions.actions';
import { TInstancePermissionsRequestAction } from '../actions/instancePermissions.actions.types';
import { TreeSelectors } from '../selectors/tree.selectors';
import { PrincipalPermissions, InstancePermissions, PrincipalDescriptor } from '../serverapi/api';
import {
    getInstancePermissions,
    getPrincipalPermissions,
    getServerId,
} from '../selectors/instancePermissions.selectors';
import { instanceDescriptorOfTreeNode } from './mappers/permissions';
import {
    PRINCIPAL_CHOOSE_DIALOG_OPEN,
    PRINCIPAL_CHOOSE_DIALOG_SUBMIT_RESULT,
    PRINCIPAL_REQUEST,
} from '../actionsTypes/principal.actionTypes';
import { principalRequestFailure, principalRequest, principalRequestSuccess } from '../actions/principal.actions';
import {
    TChoosePrincipalDialogSubmitResult,
    TPrincipalChooseDialogOpenAction,
    TPrincipalRequestAction,
} from '../actions/principal.actions.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TServerEntity } from '../models/entities.types';
import messages from '../modules/Permissions/components/InstancePermissionsDialog/InstancePermissionsDialog.messages';
import { ServerErrorType } from '../models/serverErrorType';
import { InstanceDescriptor } from '../models/security/Principal.types';
import { ManagePermissionsDAOService } from '../services/dao/ManagePermissionsDAOService';
import { allPermissionsData, allPermissionsFailure } from '../actions/allPermissions.actions';
import { TAllPermissionsForNodeRequestAction } from '../actions/allPermissions.actions.types';
import { ALL_PERMISSIONS_FOR_NODE_REQUEST } from '../actionsTypes/allPermissions.actionTypes';
import { PrincipalsSelectors } from '../selectors/principals.selectors';

function* handleInstancePermissionsDialogSubmitData() {
    const instancePermission: InstancePermissions = yield select(getInstancePermissions);
    const serverId = yield select(getServerId);
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    yield call(() =>
        server.api.securityManagement.changeSecuredObjectInstanceAccess({
            repositoryId: instancePermission.instanceId!.repositoryId,
            objectId: instancePermission.instanceId!.id,
            body: instancePermission.permissions,
        }),
    );

    yield put(closeDialog(DialogType.INSTANCE_PERMISSIONS_DIALOG));
}

function* handleContextMenuAction({ payload: { action, nodeId } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.MANAGE_PERMISSIONS) {
        yield put(treeItemManagePermissionsRequest({ nodeId }));
    }
}

function* handleManagePermissionsRequest({ payload: { nodeId } }: TTreeItemManagePermissionsRequestAction) {
    try {
        const treeNode: TTreeEntityState = yield select(TreeSelectors.itemById(nodeId));
        const path: string = yield select(TreeSelectors.nodePath(nodeId));
        const { type } = treeNode;

        const instanceDescriptor: InstanceDescriptor = instanceDescriptorOfTreeNode(treeNode);
        yield put(principalRequest(instanceDescriptor.nodeId.serverId));
        yield put(
            openDialog(DialogType.INSTANCE_PERMISSIONS_DIALOG, {
                title: path,
                objectType: type,
                nodeId,
            }),
        );
        yield put(instancePermissionsRequest(instanceDescriptor));
    } catch (ex) {
        console.error(ex); // tslint:disable-line:no-console
        if (ex.status === ServerErrorType.FORBIDDEN) {
            yield put(instancePermissionsFailure(messages.instancePermissionsAccessDeniedErrorMessage.defaultMessage));
        } else {
            yield put(instancePermissionsFailure(ex.message));
        }
    }
}

function* handleInstancePermissionsRequest({ payload: { instanceDescriptor } }: TInstancePermissionsRequestAction) {
    const { nodeId } = instanceDescriptor;
    try {
        const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
        const instancePermissions: InstancePermissions = yield call(() =>
            server.api.securityManagement.getSecuredObjectInstanceAccess({
                objectId: nodeId.id,
                repositoryId: nodeId.repositoryId,
            }),
        );
        yield put(instancePermissionsData(nodeId.serverId, instancePermissions));
    } catch (ex) {
        console.error(ex); // tslint:disable-line:no-console
        if (ex.status === ServerErrorType.FORBIDDEN) {
            yield put(instancePermissionsFailure(messages.instancePermissionsAccessDeniedErrorMessage.defaultMessage));
        } else if (ex.status === ServerErrorType.UNAUTHORIZED) {
            yield put(closeDialog(DialogType.INSTANCE_PERMISSIONS_DIALOG));
            yield put(openDialog(DialogType.AUTHORIZATION, { serverId: nodeId.serverId }));
        } else {
            yield put(instancePermissionsFailure());
        }
    }
}

function* handleAllPermissionsForNodeRequest({ payload: { nodeId } }: TAllPermissionsForNodeRequestAction) {
    const { id: objectId, repositoryId, serverId } = nodeId;
    try {
        const allPermissions: PrincipalPermissions[] = yield ManagePermissionsDAOService.allPermissionsForNode(
            serverId,
            objectId,
            repositoryId,
        );
        yield put(allPermissionsData(serverId, allPermissions));
    } catch (ex) {
        console.error(ex); // tslint:disable-line:no-console
        if (ex.status === ServerErrorType.FORBIDDEN) {
            yield put(allPermissionsFailure(messages.instancePermissionsAccessDeniedErrorMessage.defaultMessage));
        } else if (ex.status === ServerErrorType.UNAUTHORIZED) {
            yield put(closeDialog(DialogType.INSTANCE_PERMISSIONS_DIALOG));
            yield put(openDialog(DialogType.AUTHORIZATION, { serverId: nodeId.serverId }));
        } else {
            yield put(allPermissionsFailure());
        }
    }
}

function* handlePrincipalChooseDialogOpen({ payload: { serverId } }: TPrincipalChooseDialogOpenAction) {
    try {
        const principalPermissions: Array<PrincipalPermissions> = yield select(getPrincipalPermissions);
        const hiddenRowKeys = principalPermissions.map((item) => `${item.principalId}`);
        yield put(principalRequest(serverId, true));
        yield put(openDialog(DialogType.CHOOSE_PRINCIPAL_DIALOG, { hiddenRowKeys }));
    } catch (ex) {
        console.error(ex); // tslint:disable-line:no-console
        if (ex.status === ServerErrorType.FORBIDDEN) {
            yield put(instancePermissionsFailure(messages.instancePermissionsAccessDeniedErrorMessage.defaultMessage));
        } else {
            yield put(instancePermissionsFailure(ex.message));
        }
    }
}

function* handlePrincipalRequest({ payload: { serverId, forceLoad } }: TPrincipalRequestAction) {
    try {
        const server: TServerEntity = yield select(ServerSelectors.server(serverId));
        let principals: PrincipalDescriptor[] = yield select(PrincipalsSelectors.getAll);

        if (isEmpty(principals) || forceLoad) {
            principals = yield call(() => server.api.principalSearch.search());
            yield put(principalRequestSuccess(principals));
        }
    } catch (ex) {
        console.error(ex); // tslint:disable-line:no-console
        if (ex.status === ServerErrorType.FORBIDDEN) {
            yield put(instancePermissionsFailure(messages.instancePermissionsAccessDeniedErrorMessage.defaultMessage));
        } else {
            yield put(principalRequestFailure(ex.message));
        }
    }
}

function* handlePrincipalChooseDialogResult({ payload: { data } }: TChoosePrincipalDialogSubmitResult) {
    yield put(addPrincipals(data));
    yield put(closeDialog(DialogType.CHOOSE_PRINCIPAL_DIALOG));
}

export function* managePermissionsSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleContextMenuAction);
    yield takeEvery(TREE_ITEM_MANAGE_PERMISSIONS_REQUEST, handleManagePermissionsRequest);
    yield takeEvery(INSTANCE_PERMISSIONS_DIALOG_SUBMIT_RESULT, handleInstancePermissionsDialogSubmitData);
    yield takeEvery(INSTANCE_PERMISSIONS_REQUEST, handleInstancePermissionsRequest);
    yield takeEvery(ALL_PERMISSIONS_FOR_NODE_REQUEST, handleAllPermissionsForNodeRequest);
    yield takeEvery(PRINCIPAL_CHOOSE_DIALOG_OPEN, handlePrincipalChooseDialogOpen);
    yield takeEvery(PRINCIPAL_REQUEST, handlePrincipalRequest);
    yield takeEvery(PRINCIPAL_CHOOSE_DIALOG_SUBMIT_RESULT, handlePrincipalChooseDialogResult);
}
