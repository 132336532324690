import React, { FC, useMemo } from 'react';
import icRibbonLineType from '../../../../resources/icons/toolbar/ic-line-type.svg';
import { createCompactableSelect } from '../CompactableSelect/CompactableSelect.component';
import { useIntl } from 'react-intl';
import messages from '../../messages/CommonToolbar.messages';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { useSelector } from 'react-redux';
import { ICompactableSelectItemValue, TCompactableSelectItemType } from '../CompactableSelect/CompactableSelect.types';
import { edgeStylesNames } from '../../../../models/edge-style';
import theme from '../CompactableDropdownButton/CompactableDropdownButton.scss';

type TEdgeLineTypeSelectProps = {
    value: number;
    onSelect?: (value: string) => void;
    compact?: boolean;
    disabled?: boolean;
};

const CompactableSelect = createCompactableSelect<string>();

export const EdgeLineTypeSelect: FC<TEdgeLineTypeSelectProps> = (props) => {
    const { disabled, compact, onSelect } = props;
    const intl = useIntl();
    const locale = useSelector(getCurrentLocale);

    const items = useMemo(
        () =>
            edgeStylesNames(locale, intl).map(
                (item) =>
                    ({
                        type: TCompactableSelectItemType.Value,
                        value: item.id,
                        label: item.value[locale],
                        disabled: false,
                    } as ICompactableSelectItemValue<string>),
            ),
        [locale],
    );

    return (
        <CompactableSelect
            title={intl.formatMessage(messages.lineType)}
            compact={compact}
            items={items}
            onSelect={onSelect}
            defaultSprite={icRibbonLineType}
            disabled={disabled}
            className={theme.btn}
        />
    );
};
