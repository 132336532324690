import React, { FC } from 'react';
import theme from './ApprovalsTab.scss';
import { ApprovalDTO } from '@/serverapi/api';
import { ApprovalHeader } from './ApprovalHeader.component';
import { ApprovalStage } from './ApprovalStage.component';

type TApproval = {
    approval: ApprovalDTO;
};

export const Approval: FC<TApproval> = ({ approval }) => {
    return (
        <div className={theme.approvalContainer}>
            <ApprovalHeader name={approval.name} id={approval.id} status={approval.status!} />

            <div className={`${theme.main} hidden`} id={approval.id.id}>
                {approval.description ? <div className={theme.description}>{approval.description}</div> : null}
                {approval.stages
                    ? approval.stages.map((stage) => (
                          <ApprovalStage
                              stage={stage}
                              approvalStatus={approval.status!}
                              approvalId={approval.id}
                              key={stage.id}
                          />
                      ))
                    : null}
            </div>
        </div>
    );
};
