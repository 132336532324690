import { put, select, take, takeLatest } from 'redux-saga/effects';
import { AUTHORIZATION_KEYCLOAK_REQUEST, AUTHORIZATION_REQUEST } from '../actionsTypes/authorization.actionTypes';
import { loginCompleted, loginFailed } from '../actions/authorization.actions';
import {
    TAuthorizationKeycloakRequestAction,
    TAuthorizationRequestAction,
} from '../actions/authorization.actions.types';
import { addServerBatch, changeOnlineServerStatus } from '../actions/entities/servers.actions';
import { Agreements, LoginResponse } from '../serverapi/api';
import authorizationService from '../services/AuthorizationService';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import messages from '../services/response.messages';
import { appLoadInitialViewSetting, appStart } from '../actions/app.actions';
import { getIsStarted } from '../selectors/app.selector';
import { ApiBundle, apiBundle } from '../services/api/api-bundle';
import { TREE_PART_FETCH_FAILURE, TREE_PART_FETCH_SUCCESS } from '../actionsTypes/tree.actionTypes';
import { treeItemExpand, treePartFetchRequest } from '../actions/tree.actions';
import { TTreePartFetchFailureAction, TTreePartFetchSuccessAction } from '../actions/tree.actions.types';
import { workspaceAddTab } from '../actions/tabs.actions';
import { homePageTab } from '../models/home-page';
import { recentRefresh } from '../actions/recent.actions';
import { favoritesFetchRequest } from '@/actions/favorites.actions';
import electron from '../electron';
import { loadActiveServerProfile } from '../actions/serverProfile.actions';
import { newAppInstanceIsOpenedAction } from '../actions/tabsBus.actions';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { LocalesService } from '../services/LocalesService';
import { UserAgreementsDaoService } from '../services/dao/UserAgreementsDaoService';
import { NAVIGATOR_STRUCTURE } from '../utils/consts';
import { initWSConnection } from '../actions/wsConnection.actions';
import { principalRequest } from '../actions/principal.actions';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { getOrigin, replaceLastSlash } from '@/utils/url.utils';

function* proccessAuthorization(
    response: LoginResponse | undefined,
    serverId: string,
    serverName: string,
    url: string,
    api: ApiBundle,
) {
    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);

    if (!response) {
        yield put(loginFailed(intl.formatMessage(messages.serverConnectionError)));

        return;
    }
    if (response.status === 'USER_OR_PASSWORD_NOT_FOUND') {
        yield put(loginFailed(intl.formatMessage(messages.invalidCredentials)));

        return;
    }
    if (response.status === 'NEED_LICENSE') {
        yield put(loginFailed(intl.formatMessage(messages.invalidLicense)));

        return;
    }
    if (response.status === 'USER_BLOCKED') {
        yield put(loginFailed(intl.formatMessage(messages.userBlocked)));

        return;
    }
    if (response.status === 'USER_TMP_BLOCKED') {
        const endOfBlockAuthTime = response.endOfBlockAuthTime;
        const remainingMinutes: number = Math.trunc(endOfBlockAuthTime ? (endOfBlockAuthTime - Date.now()) / 60000 : 0);
        yield put(loginFailed(intl.formatMessage(messages.userTmpBlocked, { min: remainingMinutes })));

        return;
    }
    if (response.status === 'REQUIREMENT_LICENSE_FOR_AUTHENTICATION') {
        yield put(loginFailed(intl.formatMessage(messages.requirementLicense)));

        return;
    }
    if (response.status === 'AUTH_IS_DISABLED') {
        yield put(loginFailed(intl.formatMessage(messages.authorizationIsDisabled)));

        return;
    }
    // WRONG_VERSION_NULL передается со статусом 500 поэтому сюда статус WRONG_VERSION_NULL не дойдет
    if (response.status === 'WRONG_VERSION' || response.status === 'WRONG_VERSION_NULL') {
        yield put(loginFailed(intl.formatMessage(messages.invalidVersion)));
        if (!electron) {
            yield put(openDialog(DialogType.REFRESH_PAGE_DIALOG));
        }

        return;
    }
    if (response.status === 'PASSWORD_EXPIRED') {
        yield put(loginFailed(intl.formatMessage(messages.passwordExpired)));

        return;
    }

    if (response.status === 'INVALID_KEYCLOAK_TOKEN') {
        yield put(loginFailed(intl.formatMessage(messages.invalidKeycloakToken)));

        return;
    }

    if (!response.user) {
        yield put(changeOnlineServerStatus(serverId, false));
        yield put(loginFailed(intl.formatMessage(messages.invalidCredentials)));

        return;
    }

    yield put(
        addServerBatch([
            {
                id: serverId,
                url,
                name: serverName,
                api,
                lastLoginTimestamp: new Date().getTime(),
            },
        ]),
    );

    yield put(treePartFetchRequest(undefined, serverId, NAVIGATOR_STRUCTURE, response.user));
    const partFetchResult: TTreePartFetchSuccessAction | TTreePartFetchFailureAction = yield take([
        TREE_PART_FETCH_SUCCESS,
        TREE_PART_FETCH_FAILURE,
    ]);

    if (partFetchResult.type !== TREE_PART_FETCH_SUCCESS) {
        yield put(loginFailed(intl.formatMessage(messages.loadDataError)));

        return;
    }

    yield put(changeOnlineServerStatus(serverId, true));
    // вычисляем разницу во времени между клиентом и сервером
    if (response.property?.serverTime) {
        response.property.serverTimeOffset = Date.now() - response.property.serverTime;
    }

    yield put(loginCompleted(response.user, response.property || {}));
    yield put(treeItemExpand({ serverId, repositoryId: serverId, id: serverId }, NAVIGATOR_STRUCTURE));
    yield put(workspaceAddTab(homePageTab));
    yield put(closeDialog(DialogType.AUTHORIZATION));

    if (response.property) {
        yield put(appLoadInitialViewSetting(response.property));
    }

    const isAppStarted = yield select(getIsStarted);
    if (!isAppStarted) {
        yield put(appStart());
    }
    yield put(recentRefresh());
    yield put(favoritesFetchRequest(serverId));
    yield put(loadActiveServerProfile(serverId));
    yield put(principalRequest(serverId));

    if (window?.location.hash) {
        yield put(newAppInstanceIsOpenedAction(window?.location.hash));
    }
    const agreements: Agreements = yield UserAgreementsDaoService.getUserAgreements(serverId);

    if (response?.property?.isActiveAgreement && !response?.user?.agreementConfirmed) {
        if (agreements.agreement) {
            const text = LocalesService.internationalStringToString(agreements.agreement, currentLocale);
            yield put(openDialog(DialogType.ACCEPT_AGREEMENT, { serverId, text }));
        }
    }
    if (response?.property?.isActiveSilaAgreement && !response?.user?.silaAgreementConfirmed) {
        if (agreements.silaAgreement) {
            const text = LocalesService.internationalStringToString(agreements.silaAgreement, currentLocale);
            yield put(openDialog(DialogType.ACCEPT_SILA_AGREEMENT, { serverId, text }));
        }
    }
    yield put(initWSConnection({ url, serverId }));
}

function* authorize(action: TAuthorizationRequestAction) {
    const { username, password, id: serverId, serverName, autoLogin, url: actUrl } = action.payload;
    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);
    try {
        const url = new URL(actUrl.includes('://') ? actUrl : `http://${action.payload.url}`).origin;
        const api = apiBundle(serverId, url);

        let response: LoginResponse | undefined;
        if (autoLogin) {
            response = yield authorizationService.loadUserInfo(api);
        } else {
            response = yield authorizationService.login(username.trim(), password, api);
        }

        yield proccessAuthorization(response, serverId, serverName, url, api);
    } catch (e) {
        yield put(loginFailed(intl.formatMessage(messages.serverConnectionError)));
    }
}

function* authorizeKeycloak(action: TAuthorizationKeycloakRequestAction) {
    const { accessToken, rerenderCallback } = action.payload;
    const hostName = replaceLastSlash(getOrigin());
    const api: ApiBundle = new ApiBundle(hostName);
    const serverId: string = yield select(ServerSelectors.serverId);
    const currentLocale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(currentLocale);

    // убираем из урла лишние данные которые добавляет keycloak, чтобы работало открытие моделей по ссылке
    window.history.replaceState(null, '', window.location.href.replace('?auth=keycloak', ''));
    window.history.replaceState(null, '', window.location.href.replace(/.iss=.*/i, ''));
    rerenderCallback();

    try {
        let response: LoginResponse | undefined;
        response = yield authorizationService.loginKeycloak(accessToken, api);

        yield proccessAuthorization(response, serverId, hostName, hostName, api);
    } catch (error) {
        yield put(loginFailed(intl.formatMessage(messages.serverConnectionError)));
    }
}

export function* authorizationSaga() {
    yield takeLatest(AUTHORIZATION_REQUEST, authorize);
    yield takeLatest(AUTHORIZATION_KEYCLOAK_REQUEST, authorizeKeycloak);
}
