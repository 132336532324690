import React from 'react';
import { Table, Row, Col, Button } from 'antd';
import { useIntl } from 'react-intl';
import { ColumnProps } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import { MetaDataSelectors } from '../../../selectors/admintools.selectors';
import messages from '../messages/eventSettings.messages';
import DeleteButton from '../Button/DeleteButton';
import theme from "./EventAccess.scss";
import { deleteEvent } from '../../../actions/eventAccess.actions';
import { EventsAccessSelectors } from '../../../selectors/eventsAccess.selectors';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TEvent } from '../../../reducers/eventsAccess.reducer.types';
import EditButton from '../Button/EditButton';
import actionTypesMessages from '../ActionsAudit/actionTypes.messages';

export const EventAccess = () => {
    const intl = useIntl();
    const serverId = useSelector(MetaDataSelectors.getCurrentServerId);
    const data: TEvent[] = useSelector(EventsAccessSelectors.getAllData(serverId));

    const dispatch = useDispatch();
    const renderRowBtns = (text, event: TEvent) => {
        return (
            <div className={theme.buttonsBox}>
                <EditButton
                    onClick={() => {
                        dispatch(openDialog(DialogType.EVENT_DIALOG, { serverId, editing: true, event }));
                    }}
                />
                <DeleteButton
                    onDelete={
                        () => {dispatch(deleteEvent(serverId, event.id));
                        }}
                    deleteQuestion={intl.formatMessage(messages.deleteEvent, {eventName: event.name || ''})}
                />
            </div>
        );
    };
    const columns: ColumnProps<any>[] = [
        {
            title: intl.formatMessage(messages.eventName),
            dataIndex: 'name',
            key: 'name',
            width: 150,
        },
        {
            title: intl.formatMessage(messages.strategy),
            dataIndex: 'eventRunStrategy',
            key: 'eventRunStrategy',
            width: 100,
        },
        {
            title: intl.formatMessage(messages.actions),
            dataIndex: 'eventActions',
            key: 'eventActions',
            width: 120,
            render: (eventActions: string) => actionTypesMessages[eventActions]
                ? intl.formatMessage(actionTypesMessages[eventActions])
                : eventActions
        },
        {
            title: intl.formatMessage(messages.userId),
            dataIndex: 'userName',
            key: 'userName',
            width: 120,
        },
        {
            title: intl.formatMessage(messages.groupId),
            dataIndex: 'groupName',
            key: 'groupName',
            width: 110,
        },
        {
            title: intl.formatMessage(messages.eventNodeElementName),
            dataIndex: 'eventNodeElementName',
            key: 'eventNodeElementName',
            width: 130,
        },
        {
            title: intl.formatMessage(messages.eventParentNodeElementName),
            dataIndex: 'eventParentNodeElementName',
            key: 'eventParentNodeElementName',
            width: 100,
        },
        {
            title: intl.formatMessage(messages.className),
            dataIndex: 'className',
            key: 'className',
            width: 100,
        },
        {
            title: intl.formatMessage(messages.params),
            dataIndex: 'params',
            key: 'params',
            width: 100,
        },
        {
            title: intl.formatMessage(messages.scriptId),
            dataIndex: 'eventScriptName',
            key: 'eventScriptName',
            width: 100,
        },
        {
            title: '',
            dataIndex: 'manageBtn',
            key: 'manageBtn',
            width: 60,
            render: renderRowBtns,
        },
    ];

    return (
        <div className={theme.container}>
            <Row className={theme.eventRowTitle}>
                <Col span={24}>
                    <div className={theme.eventBtnLabelWrapper}>
                        <Button data-test="add-event-btn" type="primary" size="large" onClick={
                            () => {
                                dispatch(openDialog(DialogType.EVENT_DIALOG, { serverId },
                                ));
                            }}>
                            {intl.formatMessage(messages.addEvent)}
                        </Button>
                    </div>
                </Col>
            </Row>
            <Table
                rowKey={record => record.id}
                className={theme.dataTable}
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
                data-test="event-handling-table"
            />
        </div>
    );
};
