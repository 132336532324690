import { COPY_ITEM_LINK } from '../actionsTypes/modelLink.actionTypes';
import { TreeItemType } from '../modules/Tree/models/tree';
import { NodeId } from '../serverapi/api';
import { TCopyLinkAction } from './copyLink.actions.types';

export const copyLinkAction = (nodeId: NodeId, type: TreeItemType, elementId?: string): TCopyLinkAction => ({
    type: COPY_ITEM_LINK,
    payload: {
        nodeId,
        type,
        elementId,
    },
});
