import { SymbolTypeId } from '../ComplexSymbol.constants';
import { getSequenceFrameTemplate } from './sequenceSymbolDiagramElements';
import { MxCell } from 'MxGraph';
import { UMLFrame } from './UMLFrame.class';
import { SequenceShapeSymbol } from './SequenceShapeSymbol';

export class UMLFrameTitle extends SequenceShapeSymbol {
    complexSymbolTypeId = SymbolTypeId.UML_FRAME_TITLE;
    template = getSequenceFrameTemplate();

    addToGraph() {
        const { graph, rootCellValue } = this;
        const { x = 0, y = 0, width = 160, height = 26 } = rootCellValue;
        const parent = graph.getModel().getCell(rootCellValue.parent as string) || graph.getDefaultParent();
        const cellId = UMLFrame.getUMLFrameTitleIdByParentId(parent.getId());

        const style = `shape=${SymbolTypeId.UML_FRAME_TITLE};connectable=0;deletable=0;movable=0`;
        const root = graph.insertVertex(parent, cellId, rootCellValue, x, y, width, height, style);

        return this.afterCreate(root);
    }

    static getUMLFrameTitleIdByCell(cell: MxCell): string {
        return `${cell.parent.id}_${SymbolTypeId.UML_FRAME_TITLE}`;
    }

    static getUMLFrameTitleIdByParentId(parentId: string): string {
        return `${parentId}_${SymbolTypeId.UML_FRAME_TITLE}`;
    }

    static isUMLFrameTitle(cell: MxCell): boolean {
        return cell.id === UMLFrameTitle.getUMLFrameTitleIdByCell(cell);
    }
}
