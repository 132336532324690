import React, {useState} from 'react';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {ModelType} from '../../../../../../serverapi/api';
import {Dialog} from '../../../../../UIKit/components/Dialog/Dialog.component';
import messages from './ObjectType.messages';
import {GroupedTypesTable} from "../util/GroupedTypesTable.component";
import icAttribute from "../../../../../../resources/icons/ic-attribute.svg";
import {uniqBy} from "lodash";

type TAddModelTypeDialogIntlProps = {
    modelTypes: ModelType[];
    dialogVisible: boolean;
};

type TAddModelTypeDialogIntlActionProps = {
    onOk: (modelTypes: ModelType[]) => void;
    onCancel: () => void;
};

type TAddModelTypeDialogIntlFullProps = TAddModelTypeDialogIntlProps &
    TAddModelTypeDialogIntlActionProps &
    WrappedComponentProps;

const AddModelTypeDialogComponent = (props: TAddModelTypeDialogIntlFullProps) => {
    const [newModelTypes, setNewModelTypes] = useState<ModelType[]>([]);

    const modelTypeGroups = uniqBy(props.modelTypes.map((mt) => mt.modelTypeGroup), 'id');

    const heightTable = 250;

    return (
        <div>
            <Dialog
                onOk={() => {
                    props.onOk(newModelTypes);
                }}
                onCancel={props.onCancel}
                open={props.dialogVisible}
                title={props.intl.formatMessage(messages.addModelType)}
                okText={props.intl.formatMessage(messages.save)}
                cancelText={props.intl.formatMessage(messages.cancel)}
            >
                <div>
                    <GroupedTypesTable
                        types={props.modelTypes?.map((type) => ({
                            ...type,
                            groupId: type.modelTypeGroup?.id
                        }))}
                        typeGroups={modelTypeGroups}
                        defaultColumnWidth="90%"
                        actionsDisabled={false}
                        onSelectType={setNewModelTypes as any}
                        icon={icAttribute}
                        hideGUIDColumn
                        wHeight={heightTable}
                    />
                </div>
            </Dialog>
        </div>
    );
}

const AddModelTypeDialogIntl = injectIntl(AddModelTypeDialogComponent);

export {AddModelTypeDialogIntl as AddModelTypeDialog};
