import { NodeId, Node, RootNodeId, DiagramElement } from '../serverapi/api';
import { TreeNode, TTreeEntityState } from '../models/tree.types';
import { TRootState } from '../reducers/root.reducer.types';
import { getTreeItems, TreeSelectors } from './tree.selectors';
import {
    filterScriptsByContext,
    filterScriptsByEvent,
    getFilteredByNameTree,
} from '../services/bll/fetchScriptsBllService';

const getFetchedScripts = (state: TRootState): Node[] => state.fetchedScripts.scripts;

export const getFilteredTreeNodeScripts =
    ({
        serverId,
        filter,
        nodeId,
        modelId,
        element,
        isContextSensitive,
        isEvent,
    }: {
        serverId: string;
        filter: string;
        nodeId?: NodeId;
        modelId?: NodeId;
        element?: DiagramElement;
        isContextSensitive?: boolean;
        isEvent?: boolean;
    }) =>
    (state: TRootState): TreeNode[] => {
        const treeItemsById: { [id: string]: TTreeEntityState } = getTreeItems(
            serverId,
            RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        )(state);
        const nodes: Node[] = getFetchedScripts(state);
        const node: TTreeEntityState | undefined = TreeSelectors.itemById(nodeId)(state);
        const modelNode: TTreeEntityState | undefined = TreeSelectors.itemById(modelId)(state);
        const isRunFromNavigator = !element;

        const filteredItemsByScriptContext = isContextSensitive
            ? filterScriptsByContext(treeItemsById, node, modelNode, element, isRunFromNavigator)
            : treeItemsById;

        const filteredItems = isEvent
            ? filterScriptsByEvent(filteredItemsByScriptContext)
            : filteredItemsByScriptContext;

        return getFilteredByNameTree(nodes, filteredItems, filter);
    };
