import { IMatrixNode, IModelNode, IScriptNode, ISpreadsheetNode, IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import { EditorMode } from '@/models/editorMode';
import { TWorkspaceTab } from '@/models/tab.types';
import { TreeNode } from '@/models/tree.types';
import { TDashboard } from '@/modules/Dashboard/Dashboard.types';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { DefaultGraph } from '@/mxgraph/DefaultGraph';
import { instancesBPMMxGraphMap } from '@/mxgraph/bpm-mxgraph-instance-map';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { MatrixSelectors } from '@/selectors/entities/matrix.selectors';
import { SpreadsheetSelectors } from '@/selectors/entities/spreadsheet.selectors';
import { WikiSelectors } from '@/selectors/entities/wiki.selectors';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { ModelSelectors } from '@/selectors/model.selectors';
import { ScriptSelectors } from '@/selectors/script.selectors';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { GeneralModel } from '@/serverapi/api';
import { lockService } from '@/services/LockService';
import { matrixService } from '@/services/MatrixService';
import { modelService } from '@/services/ModelService';
import { DashboardDaoService } from '@/services/dao/DashboardDAOService';
import { ScriptDAOService } from '@/services/dao/ScriptDAOService';
import { SpreadsheetDaoService } from '@/services/dao/SpreadsheetDaoService';
import { WikiDaoService } from '@/services/dao/WikiDAOService';
import { select } from 'redux-saga/effects';
import { TAutoSaveModelIntervalIdsState } from '@/reducers/autoSaveModelIntervalIds.reducer.types';
import { getAutoSaveModelIntervalIdsState } from '@/selectors/autoSaveModelIntervalIds.selectors';

export function* saveAndUnlockModels() {
    const tabs: TWorkspaceTab[] = yield select(TabsSelectors.getTabList);
    const saveNodePromises: Promise<GeneralModel | undefined>[] = [];
    const unlockNodePromises: Promise<void>[] = [];

    const autoSaveModelIntervalIds: TAutoSaveModelIntervalIdsState = yield select(getAutoSaveModelIntervalIdsState);
    autoSaveModelIntervalIds.forEach((id) => clearInterval(id));

    for (let tab of tabs) {
        const shouldSave =
            (tab.mode === EditorMode.Edit &&
                (tab.type === WorkSpaceTabTypes.EDITOR ||
                    tab.type === WorkSpaceTabTypes.MARTIX_EDITOR ||
                    tab.type === WorkSpaceTabTypes.SCRIPT_EDITOR ||
                    tab.type === WorkSpaceTabTypes.SPREADSHEET ||
                    tab.type === WorkSpaceTabTypes.DASHBOARD)) ||
            tab.type === WorkSpaceTabTypes.WIKI_EDITOR;

        if (shouldSave) {
            const nodeId = tab.nodeId;
            switch (tab.type) {
                case WorkSpaceTabTypes.EDITOR:
                    const graph: BPMMxGraph = instancesBPMMxGraphMap.get(nodeId);
                    const isDefaultGraph = graph instanceof DefaultGraph;
                    const model: IModelNode = yield select(ModelSelectors.byId(nodeId));
                    const locale: Locale = yield select(getCurrentLocale);
                    const forceSave = true;
                    const modelPromise = modelService().prepareAndSaveModel(
                        graph,
                        isDefaultGraph,
                        model,
                        tab,
                        locale,
                        forceSave,
                    );
                    const unlockModelPromise = lockService().unlock(TreeItemType.Model, nodeId);

                    saveNodePromises.push(modelPromise);
                    unlockNodePromises.push(unlockModelPromise);
                    break;
                case WorkSpaceTabTypes.WIKI_EDITOR:
                    const wiki: IWikiNode = yield select(WikiSelectors.byId(nodeId));
                    const wikiPromise = WikiDaoService.saveWiki(nodeId.serverId, wiki as IWikiNode);

                    saveNodePromises.push(wikiPromise);
                    break;
                case WorkSpaceTabTypes.MARTIX_EDITOR:
                    const matrix: IMatrixNode = yield select(MatrixSelectors.byId(nodeId));

                    const matrixPromise = matrixService().saveMatrix(matrix as IMatrixNode);
                    const unlockMatrixPromise = lockService().unlock(TreeItemType.Matrix, nodeId);

                    saveNodePromises.push(matrixPromise);
                    unlockNodePromises.push(unlockMatrixPromise);
                    break;
                case WorkSpaceTabTypes.SCRIPT_EDITOR:
                    const script: IScriptNode = yield select(ScriptSelectors.byId(nodeId));

                    const scriptPromise = ScriptDAOService.saveScript(script as IScriptNode);
                    const unlockScriptPromise = lockService().unlock(TreeItemType.Script, nodeId);

                    saveNodePromises.push(scriptPromise);
                    unlockNodePromises.push(unlockScriptPromise);
                    break;
                case WorkSpaceTabTypes.SPREADSHEET:
                    const spreadsheet: ISpreadsheetNode = yield select(SpreadsheetSelectors.byId(nodeId));
                    const spreadsheetPromise = SpreadsheetDaoService.saveSpreadsheet(nodeId.serverId, {
                        body: spreadsheet as ISpreadsheetNode,
                    });
                    const unlockSpreadsheetPromise = lockService().unlock(TreeItemType.Spreadsheet, nodeId);

                    saveNodePromises.push(spreadsheetPromise);
                    unlockNodePromises.push(unlockSpreadsheetPromise);
                    break;
                case WorkSpaceTabTypes.DASHBOARD:
                    const dashboard: TDashboard = yield select(DashboardSelector.dashboardById(nodeId));
                    const { name, parentNodeId }: TreeNode = yield select(TreeSelectors.itemById(nodeId));
                    const dashboardPromise = DashboardDaoService.saveDashboard(dashboard, nodeId, name, parentNodeId!);

                    saveNodePromises.push(dashboardPromise);
                    break;
                default:
                    break;
            }
        }
    }

    if (saveNodePromises.length > 0) {
        yield Promise.allSettled([...saveNodePromises, ...unlockNodePromises]);
    }
}
