import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId } from '../serverapi/api';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';

export const objectDefinitionStateSelector = (state: TRootState) => state.objectDefinition;

export namespace ObjectDefinitionSelectors {
    export const byId = (nodeId: NodeId) => createSelector(objectDefinitionStateSelector, (state) => state.get(nodeId));

    export const byIds = (ids: NodeId[]) =>
        createSelector(objectDefinitionStateSelector, (state) => ids.map((nodeId) => state.get(nodeId)));

    export const values = () => createSelector(objectDefinitionStateSelector, (state) => state.values());

    export const byServerIdRepositoryId = (serverId: string, repositoryId: string) =>
        createSelector(objectDefinitionStateSelector, (state) => {
            const objectsMap: { [key: string]: ObjectDefinitionImpl } = {};
            state.values().forEach((o) => {
                const { nodeId } = o;
                if (nodeId.serverId === serverId && nodeId.repositoryId === repositoryId) {
                    objectsMap[nodeId.id] = o;
                }
            });

            return objectsMap;
        });
}
