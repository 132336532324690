import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { AutoSizer, Dimensions, List, CellMeasurerCache, CellMeasurer, ListRowProps } from 'react-virtualized';
import theme from './Tree.scss';
import 'react-virtualized/styles.css';
import { FavoriteNodeDTO, NodeId, Symbol } from '../../../../serverapi/api';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { TExpandTree, TTreeFullProps, TTreeNodeWithLevel } from '../../Tree.types';
import { useDispatch, useSelector } from 'react-redux';
import { getScrolledNode, TreeSelectors } from '../../../../selectors/tree.selectors';
import { SymbolSelectors } from '../../../../selectors/symbol.selectors';
import { treeItemScroll, treeItemSelect } from '../../../../actions/tree.actions';
import { NavigatorTreeSearchSelector } from '../../../../selectors/navigatorTreeSearch.selectors';
import { changeFoundNodeIdsBySearchStr } from '../../../../actions/navigatorTreeSearch.actions';
import { NAVIGATOR_STRUCTURE } from '../../../../utils/consts';
import { useExpandStatus, useFlatTree } from '../../../../hooks/useFlatTree.hook';
import { TreeRow } from '../TreeRow/TreeRow.component';
import { FavoritesSelectors } from '@/selectors/favorites.selectors';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';

const ROW_HEIGHT = 28;

const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 20,
});

export const Tree: FC<TTreeFullProps> = (props) => {
    const { data, selectedNodes, treeName, disableContextMenu, isDndEnabled = false, onSelect } = props;
    const dispatch = useDispatch();
    const scrolledNodeId: NodeId | undefined = useSelector(getScrolledNode);
    const symbols = useSelector(SymbolSelectors.all);
    const repoPresetMap = useSelector(TreeSelectors.repositoryPresetMap);
    const expandTree: TExpandTree | undefined = useSelector(TreeSelectors.expandTreeByName(treeName));

    const selectedFoundNodeId: NodeId | undefined = useSelector(NavigatorTreeSearchSelector.getSelectedFoundNodeId);
    const favoriteNodes: FavoriteNodeDTO[] = useSelector(FavoritesSelectors.nodes);
    const isNavigatorStructure: boolean = treeName === NAVIGATOR_STRUCTURE;
    const isApprovalCopyTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG;
    const isAddFavoriteTree: boolean = treeName === DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG;
    const getExpandStatus = useExpandStatus(expandTree);

    const flatData: TTreeNodeWithLevel[] = useFlatTree(data, expandTree);

    useEffect(() => {
        if (isNavigatorStructure || isApprovalCopyTree || isAddFavoriteTree) {
            dispatch(changeFoundNodeIdsBySearchStr(flatData));
        }
    }, [flatData]);

    useEffect(() => {
        if (
            isNavigatorStructure &&
            selectedFoundNodeId &&
            flatData.find((treeItemData) => compareNodeIds(treeItemData.nodeId, selectedFoundNodeId))
        )
            dispatch(treeItemScroll(selectedFoundNodeId));
    }, [selectedFoundNodeId]);

    const onSelectHandler = useCallback(
        (selectedTreeItem) => {
            if (onSelect) {
                onSelect(selectedTreeItem);
            } else {
                dispatch(treeItemSelect(selectedTreeItem));
            }
        },
        [onSelect],
    );
    const onScroll = useCallback(
        (itemNodeId) => {
            if (scrolledNodeId) dispatch(treeItemScroll(itemNodeId));
        },
        [scrolledNodeId],
    );

    const measureRowRenderer = useCallback(
        (rowProps: ListRowProps) => {
            const node: TTreeNodeWithLevel = flatData[rowProps.index];

            let symbolsArr: Symbol[] = [];

            if (symbols) {
                const presetId = repoPresetMap.get({ ...node.nodeId, id: node.nodeId.repositoryId } as NodeId) || '';
                const getted = symbols.entries().reduce((summ: Symbol[], current) => {
                    if (current[0].serverId === node.nodeId.serverId && current[0].presetId === presetId) {
                        summ.push(current[1]);
                    }

                    return summ;
                }, []);
                symbolsArr = getted || [];
            }

            return (
                <CellMeasurer
                    cache={cache}
                    columnIndex={rowProps.columnIndex}
                    key={`${rowProps.key}_${node.deleted}`}
                    rowIndex={rowProps.index}
                    parent={rowProps.parent}
                >
                    <div style={rowProps.style}>
                        <TreeRow
                            disableContextMenu={disableContextMenu}
                            flatData={flatData}
                            rowData={node}
                            symbols={symbolsArr}
                            isDndEnabled={isDndEnabled}
                            isNavigatorStructure={isNavigatorStructure}
                            selectedFoundNodeId={selectedFoundNodeId}
                            selectedNodes={selectedNodes}
                            favoriteNodes={favoriteNodes}
                            treeName={treeName}
                            onSelect={onSelectHandler}
                            getExpandStatus={getExpandStatus}
                        />
                    </div>
                </CellMeasurer>
            );
        },
        [
            expandTree,
            disableContextMenu,
            isDndEnabled,
            flatData,
            treeName,
            symbols,
            repoPresetMap,
            selectedNodes,
            selectedFoundNodeId,
        ],
    );

    const scrolledIndex = useMemo(
        () => scrolledNodeId && flatData.findIndex((el) => compareNodeIds(el.nodeId, scrolledNodeId)),
        [scrolledNodeId, flatData],
    );

    return (
        <div className={theme.container}>
            <AutoSizer>
                {({ height, width }: Dimensions) => (
                    <List
                        deferredMeasurementCache={cache}
                        height={height}
                        overscanRowCount={10}
                        rowCount={flatData.length}
                        rowHeight={ROW_HEIGHT}
                        className={theme.list}
                        rowRenderer={measureRowRenderer}
                        width={width}
                        scrollToIndex={scrolledIndex && scrolledIndex >= 0 ? scrolledIndex : undefined}
                        scrollToAlignment="center"
                        onScroll={onScroll}
                    />
                )}
            </AutoSizer>
        </div>
    );
};
