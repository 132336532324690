import { ConfigProvider, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import { LocalesService } from '../../../../../../services/LocalesService';
import {
    dateFormat,
    dateTimeFormat,
    momentDateToTimestamp,
    timestampToMomentDate,
} from '../../../../../../utils/date.time.utils';
import { useIntl } from 'react-intl';
import theme from './StorageDefaultValueToComponent.scss';
import dayjs from 'dayjs';
import { AttributeType, AttributeValue } from '../../../../../../serverapi/api';
import messages from '../AttributeType/AttributeType.messages';
import { getAttrbuteValue } from './util/attributeTypeEditorDialog.utils';

type TDatePickerComponent = {
    attributeType: AttributeType;
    onChangeDefaultValue: (attributeValue: AttributeValue | undefined) => void;
};

export const DatePickerComponent = (props: TDatePickerComponent) => {
    const { attributeType, onChangeDefaultValue } = props;
    const { valueType } = attributeType;

    const intl = useIntl();
    const val: string | undefined = attributeType.attributeDefaultValue?.value;

    const defaultValue: dayjs.Dayjs | undefined = val ? timestampToMomentDate(+val) : undefined;
    const [data, setData] = useState<dayjs.Dayjs | undefined>(defaultValue);
    const [isFirstReneder, setisFirstReneder] = useState<boolean>(true);

    useEffect(() => {
        if (!isFirstReneder) setData(undefined);
        setisFirstReneder(false);
    }, [valueType]);

    return (
        <div className={theme.dateContainer}>
            <ConfigProvider locale={LocalesService.checkAndSetLocale(intl.locale)}>
                <DatePicker
                    data-test="select_attribute_date_input"
                    style={{ width: '100%' }}
                    showTime={valueType === 'DATE_TIME'}
                    format={valueType === 'DATE_TIME' ? dateTimeFormat : dateFormat}
                    onChange={(date: dayjs.Dayjs) => {
                        onChangeDefaultValue({
                            ...getAttrbuteValue(attributeType),
                            value: `${momentDateToTimestamp(date)}`,
                        });
                        setData(date);
                    }}
                    value={data}
                    placeholder={
                        valueType === 'DATE_TIME'
                            ? intl.formatMessage(messages.selectDateAndTime)
                            : intl.formatMessage(messages.selectDate)
                    }
                />
            </ConfigProvider>
        </div>
    );
};
