import { connect } from 'react-redux';
import {
    addNewSimulation,
    deleteSimulationRun,
    runSimulation,
    saveSimulationSettings,
    updateSimulationRun,
    getReport,
} from '../../../actions/simulationModeling.actions';
import { ObjectInstanceImpl } from '../../../models/bpm/bpm-model-impl';
import { ISimulationModelingWorkspaceParams } from '../../../models/tab.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { ModelSelectors } from '../../../selectors/model.selectors';
import { ModelTypeSelectors } from '../../../selectors/modelType.selectors';
import { ObjectDefinitionSelectors } from '../../../selectors/objectDefinition.selectors';
import { SimulationModelingSelectors } from '../../../selectors/simulationModeling.selectors';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { ModelType, NodeId, SimulationRun, SimulationSettings } from '../../../serverapi/api';
import { SimulationModeling } from '../components/SimulationModeling/SimulationModeling.component';
import { TSimulationModelingContainerProps } from '../SimulationModeling.types';
import { TTreeEntityState } from '@/models/tree.types';

const mapStateToProps = (state: TRootState, props: TSimulationModelingContainerProps) => {
    const params = props.tab.params as ISimulationModelingWorkspaceParams;
    const { nodeId } = params;
    const simulationRuns = SimulationModelingSelectors.simulationRuns(nodeId)(state);
    const simulationSettings = SimulationModelingSelectors.simulationSettings(nodeId)(state);
    const simulationNode: TTreeEntityState | undefined = TreeSelectors.itemById(nodeId)(state);
    const repositoryNode: TTreeEntityState | undefined = TreeSelectors.itemById({ ...nodeId, id: nodeId.repositoryId })(
        state,
    );
    const modelIdAndDiagramElement: { modelId: NodeId; objectDiagramElement: ObjectInstanceImpl } =
        TabsSelectors.getClipboard()(state) as any;
    const model = modelIdAndDiagramElement && ModelSelectors.byId(modelIdAndDiagramElement.modelId)(state);
    const object =
        modelIdAndDiagramElement &&
        ObjectDefinitionSelectors.byId({
            ...nodeId,
            id: modelIdAndDiagramElement?.objectDiagramElement?.objectDefinitionId || '',
        })(state);
    const modelTypes: ModelType[] =
        ModelTypeSelectors.getAllModelTypes(nodeId.serverId, repositoryNode?.presetId || '')(state) || [];

    return {
        ...params,
        simulationRuns,
        simulationSettings,
        simulationNode,
        model,
        object,
        objectDiagramElement: modelIdAndDiagramElement?.objectDiagramElement,
        modelTypes,
    };
};

const mapDispatchToProps = (dispatch) => ({
    runSimulation: (simulation: SimulationRun) => {
        dispatch(runSimulation(simulation));
    },
    addNewSimulation: (simulation: SimulationRun) => {
        dispatch(addNewSimulation(simulation));
    },
    saveSimulation: (simulation: SimulationRun) => {
        dispatch(updateSimulationRun(simulation));
    },
    deleteSimulation: (simulation: SimulationRun) => {
        dispatch(deleteSimulationRun(simulation));
    },
    saveSimulationSettings: (simulationSettings: SimulationSettings, simulationId: NodeId) => {
        dispatch(saveSimulationSettings(simulationSettings, simulationId));
    },
    getReport: (simulation: SimulationRun) => {
        dispatch(getReport(simulation));
    },
});

export const SimulationModelingContainer = connect(mapStateToProps, mapDispatchToProps)(SimulationModeling);
