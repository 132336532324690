import { BPMMxVertexHandler } from '../handler/BPMMxVertexHandler.class';
import { MxPoint, MxRectangle, MxConstants, MxUtils, MxMouseEvent, MxCell, MxGuide, MxCellState } from '../mxgraph';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolsManager.class';
import { UMLFrame } from '../ComplexSymbols/symbols/Sequence/UMLFrame.class';
import SequenceUtils from '../ComplexSymbols/symbols/Sequence/sequence.utils';
import { LifelineSymbolMainClass } from '../ComplexSymbols/symbols/LifeLine/LifelineSymbolMain.class';
import { SequenceGraph } from './SequenceGraph';

export class SequenceVertexHandler extends BPMMxVertexHandler {
    currentCell: MxCell;
    livePreviewActive = false;
    graph: SequenceGraph;

    constructor(state: MxCellState) {
        super(state);
        this.redrawSizers();
    }

    redraw(): void {
        this.redrawSizers();
        super.redraw();
    }

    /**
     * Обработчик события изменения размера объекта при перетаскивании контрольных точек
     * @param {MxMouseEvent} me - информация о событии
     */
    resizeVertex(me: MxMouseEvent) {
        const delta = new MxPoint(0, 0);
        this.guides.setSourceState(me?.sourceState);
        this.lastEventStorage.lastEvent = me;
        this.lastEventStorage.lastDelta = this.guides.move(this.bounds, delta);
        const cell = this.currentCell;
        const isSequenceExecutionSymbol = SequenceUtils.isSequenceExecutionSymbol(cell);
        const isSequenceDiagramCell = SequenceUtils.isSequenceDiagramCell(cell);
        const disableWidthResize = isSequenceDiagramCell || isSequenceExecutionSymbol;

        const ct = new MxPoint(this.state.getCenterX(), this.state.getCenterY());
        const alpha = MxUtils.toRadians(this.state.style[MxConstants.STYLE_ROTATION] || '0');
        const point = new MxPoint(me.getGraphX(), me.getGraphY());
        const tr = this.graph.view.translate;
        const { scale } = this.graph.view;
        let cos = Math.cos(-alpha);
        let sin = Math.sin(-alpha);

        const complexSymbol = ComplexSymbolManager.getComplexSymbolInstance(cell) as LifelineSymbolMainClass;
        const headerSize = complexSymbol?.headerSize || 40;
        let relativeParentY = cell.geometry.y;
        if (isSequenceExecutionSymbol) {
            let tmpCell = cell;
            while (tmpCell.parent && SequenceUtils.isSequenceExecutionSymbol(tmpCell.parent)) {
                relativeParentY += tmpCell.parent.geometry.y;
                tmpCell = tmpCell.parent;
            }
        }
        const statrYPosition = this.index > 4 ? relativeParentY + cell.geometry.height : relativeParentY;
        const minShiftY = -Math.max(statrYPosition - headerSize, 0);
        let dx = disableWidthResize ? 0 : point.x - this.startX;
        let dy = isSequenceExecutionSymbol ? Math.max(point.y - this.startY, minShiftY) : point.y - this.startY;

        // Rotates vector for mouse gesture
        const tx = cos * dx - sin * dy;
        const ty = sin * dx + cos * dy;

        dx = tx;
        dy = ty;

        const isConstrainedEvent = disableWidthResize ? false : this.isConstrainedEvent(me);
        const geo = this.graph.getCellGeometry(this.state.cell);
        this.unscaledBounds = this.union(
            geo,
            dx / scale,
            dy / scale,
            this.index,
            this.graph.isGridEnabledEvent(me.getEvent()),
            1,
            new MxPoint(0, 0),
            isConstrainedEvent,
            0,
            20,
        );

        // Keeps vertex within maximum graph or parent bounds
        if (!geo.relative) {
            let max = this.graph.getMaximumGraphBounds();

            // Handles child cells
            if (max != null && this.parentState != null) {
                max = MxRectangle.fromRectangle(max);

                max.x -= (this.parentState.x - tr.x * scale) / scale;
                max.y -= (this.parentState.y - tr.y * scale) / scale;
            }

            if (this.graph.isConstrainChild(this.state.cell)) {
                let tmp = this.graph.getCellContainmentArea(this.state.cell);

                if (tmp != null) {
                    const overlap = this.graph.getOverlap(this.state.cell);

                    if (overlap > 0) {
                        tmp = MxRectangle.fromRectangle(tmp);

                        tmp.x -= tmp.width * overlap;
                        tmp.y -= tmp.height * overlap;
                        tmp.width += 2 * tmp.width * overlap;
                        tmp.height += 2 * tmp.height * overlap;
                    }

                    if (max == null) {
                        max = tmp;
                    } else {
                        max = MxRectangle.fromRectangle(max);
                        max.intersect(tmp);
                    }
                }
            }

            if (max != null) {
                if (this.unscaledBounds.x < max.x) {
                    this.unscaledBounds.width -= max.x - this.unscaledBounds.x;
                    this.unscaledBounds.x = max.x;
                }

                if (this.unscaledBounds.y < max.y) {
                    this.unscaledBounds.height -= max.y - this.unscaledBounds.y;
                    this.unscaledBounds.y = max.y;
                }

                if (this.unscaledBounds.x + this.unscaledBounds.width > max.x + max.width) {
                    this.unscaledBounds.width -= this.unscaledBounds.x + this.unscaledBounds.width - max.x - max.width;
                }

                if (this.unscaledBounds.y + this.unscaledBounds.height > max.y + max.height) {
                    this.unscaledBounds.height -=
                        this.unscaledBounds.y + this.unscaledBounds.height - max.y - max.height;
                }
            }
        }

        const old = this.bounds;
        this.bounds = new MxRectangle(
            (this.parentState != null ? this.parentState.x : tr.x * scale) + this.unscaledBounds.x * scale,
            (this.parentState != null ? this.parentState.y : tr.y * scale) + this.unscaledBounds.y * scale,
            this.unscaledBounds.width * scale,
            this.unscaledBounds.height * scale,
        );

        if (geo.relative && this.parentState != null) {
            this.bounds.x += this.state.x - this.parentState.x;
            this.bounds.y += this.state.y - this.parentState.y;
        }

        cos = Math.cos(alpha);
        sin = Math.sin(alpha);

        const c2 = new MxPoint(this.bounds.getCenterX(), this.bounds.getCenterY());

        dx = c2.x - ct.x;
        dy = c2.y - ct.y;

        const dx2 = cos * dx - sin * dy;
        const dy2 = sin * dx + cos * dy;

        const dx3 = dx2 - dx;
        const dy3 = dy2 - dy;

        const dx4 = this.bounds.x - this.state.x;
        const dy4 = this.bounds.y - this.state.y;

        const dx5 = cos * dx4 - sin * dy4;
        const dy5 = sin * dx4 + cos * dy4;

        this.bounds.x += dx3;
        this.bounds.y += dy3;

        // Rounds unscaled bounds to int
        this.unscaledBounds.x = this.roundLength(this.unscaledBounds.x + dx3 / scale);
        this.unscaledBounds.y = this.roundLength(this.unscaledBounds.y + dy3 / scale);
        this.unscaledBounds.width = this.roundLength(this.unscaledBounds.width);
        this.unscaledBounds.height = this.roundLength(this.unscaledBounds.height);

        // Shifts the children according to parent offset
        if (!this.graph.isCellCollapsed(this.state.cell) && (dx3 !== 0 || dy3 !== 0)) {
            this.childOffsetX = this.state.x - this.bounds.x + dx5;
            this.childOffsetY = this.state.y - this.bounds.y + dy5;
        } else {
            this.childOffsetX = 0;
            this.childOffsetY = 0;
        }

        if (!old.equals(this.bounds)) {
            if (this.livePreviewActive) {
                this.updateLivePreview(me);
            }

            if (this.preview != null) {
                this.drawPreview();
            } else {
                this.updateParentHighlight();
            }
        }
    }

    /**
     * Обработчик события движения мыши
     * @param {MxMouseEvent} me - информация о событии
     */
    mouseMove(sender, me) {
        if (!me.isConsumed() && this.index != null) {
            // Checks tolerance for ignoring single clicks
            this.checkTolerance(me);

            if (!this.inTolerance) {
                this.resizeVertex(me);
                this.updateHint(me);
            }
            me.consume();
        }
    }

    /**
     * Начало обработки событий мыши
     */
    start() {
        this.currentCell = this.state.cell;
        const result = super.start.apply(this, arguments);
        const states = this.getGuideStates();

        this.guides = new MxGuide(this.graph, states);
        this.guides.horizontal = false;
        this.guides.vertical = false;
        this.guides.distance = false;

        return result;
    }

    /**
     *
     * @param {any} bounds
     * @param {number}dx
     * @param {number}dy
     * @param {number}index
     * @param {boolean}gridEnabled
     * @param {number}scale
     * @param {any}tr
     * @param {any}constrained
     * @param {number}minWidth
     * @param {number}minHeight
     */
    union(bounds, dx, dy, index, gridEnabled, scale, tr, constrained, minWidth = 0, minHeight = 0) {
        const result = super.union.apply(this, arguments);
        result.width = Math.max(result.width, minWidth);
        result.height = Math.max(result.height, minHeight);

        return result;
    }

    /**
     * Перерисовывает точки для ресайза объекта, по умолчанию доступны все 8 точек
     */
    redrawSizers() {
        let enabledSizersIndices = [...Array(8)].map((_, i) => i);
        if (
            SequenceUtils.isSequenceDiagramCell(this.state.cell) ||
            SequenceUtils.isSequenceExecutionSymbol(this.state.cell)
        ) {
            enabledSizersIndices = [1, 6];
        }

        if (UMLFrame.isUMLFrameTitle(this.state.cell)) {
            enabledSizersIndices = [4, 6, 7];
        }

        this.sizers?.forEach((sizer, i) => {
            if (!enabledSizersIndices.includes(i)) sizer.visible = false;
        });
    }

    mouseUp() {
        super.mouseUp.apply(this, arguments);
        if (this.currentCell) this.graph.graphHandler.initCellsMerging([this.currentCell]);
    }

    redrawHandles(): void {
        super.redrawHandles();
        this.redrawSizers();
    }
}
