// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PresetImage__uploadSvg__hTAKr{width:70px;height:70px}.PresetImage__imageContainer__A7Syv{text-align:center}.PresetImage__image__jTvLg{width:200px;height:200px;margin-left:50px}.PresetImage__footer__o1V3L{padding:0 14px}.PresetImage__uploadArea__lK00W{height:200px;margin-top:20px;display:-webkit-box;display:-ms-flexbox;display:flex}.PresetImage__dragger__ELeoy{width:200px !important}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/IconsLib/PresetImage.scss"],"names":[],"mappings":"AAAA,+BACI,UAAA,CACA,WAAA,CAEJ,oCACI,iBAAA,CAEJ,2BACI,WAAA,CACA,YAAA,CACA,gBAAA,CAEJ,4BACI,cAAA,CAEJ,gCACI,YAAA,CACA,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEJ,6BACI,sBAAA","sourcesContent":[".uploadSvg {\r\n    width: 70px;\r\n    height: 70px;\r\n}\r\n.imageContainer {\r\n    text-align: center;\r\n}\r\n.image {\r\n    width: 200px;\r\n    height: 200px;\r\n    margin-left: 50px;\r\n}\r\n.footer {\r\n    padding: 0 14px;\r\n}\r\n.uploadArea {\r\n    height: 200px;\r\n    margin-top: 20px;\r\n    display: flex;\r\n}\r\n.dragger {\r\n    width: 200px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadSvg": "PresetImage__uploadSvg__hTAKr",
	"imageContainer": "PresetImage__imageContainer__A7Syv",
	"image": "PresetImage__image__jTvLg",
	"footer": "PresetImage__footer__o1V3L",
	"uploadArea": "PresetImage__uploadArea__lK00W",
	"dragger": "PresetImage__dragger__ELeoy"
};
export default ___CSS_LOADER_EXPORT___;
