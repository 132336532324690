import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, FormInstance, Input, Typography } from 'antd';
import messages from '../../SystemProperties.messages';
import errorMessages from '../../../../../modules/App/messages/AppNotifications.messages';
import { LdapProperties, LdapPropertiesPasswordStatusEnum, LdapStatus } from '../../../../../serverapi/api';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import { CheckStatus, SystemPropertiesFormItemNames, TLdapFormRef, formItemLayout } from '../../SystemProperties.types';
import { SystemPropertiesDaoService } from '../../../../../services/dao/SystemPropertiesDaoService';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../../../actions/notification.actions';
import { NotificationType } from '../../../../../models/notificationType';
import { IGenericNotification } from '../../../../../models/notification.types';
import { v4 as uuid } from 'uuid';
import { validateLdapProperties } from '../../SystemProperties.utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const { Text } = Typography;

type TLdapPropertiesProps = {
    index: number;
    form: FormInstance<any> | null;
    ldapSettings: LdapProperties[];
    isNeedValidateLdap: boolean;
    LdapFormRefs: TLdapFormRef;
};

type TLdapPropertiesActionProps = {
    onRemoveServer: () => void;
    onChangeSetter: (value: LdapProperties) => void;
};

type TFullLdapPropertiesProps = TLdapPropertiesProps & TLdapPropertiesActionProps;

export const LdapPropertiesComponent = (props: TFullLdapPropertiesProps) => {
    const { index, onRemoveServer, onChangeSetter, form, ldapSettings, isNeedValidateLdap, LdapFormRefs } = props;
    LdapFormRefs[`LdapFormRef${index}`] = useRef<FormInstance>(null);
    const ldapProperties = ldapSettings[index];
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        baseDn,
        bindDn,
        bindPasswd,
        domainNameNetBios,
        domainName,
        usersGroup,
        ignoreFailCert,
        defaultDomain,
        searchFilter,
        serverUrl,
        serverUrlAlt,
        id,
        passwordStatus,
        ignore,
        receiveGroupFromToken,
    } = ldapProperties;
    const [checkStatus, setCheckStatus] = useState<CheckStatus | undefined>(undefined);
    const [properties, setProperties] = useState<LdapProperties>(ldapProperties);
    const [ldapServiceStatus, setLdapServiceStatus] = useState<LdapStatus | undefined>(undefined);

    useEffect(() => {
        if (isNeedValidateLdap) {
            LdapFormRefs[`LdapFormRef${index}`].current?.validateFields();
        }
    }, [isNeedValidateLdap]);

    const handleCheckLdapService = (index: number) => {
        const ldapServer: LdapProperties | undefined = form?.getFieldValue(
            SystemPropertiesFormItemNames.ldapSettings,
        )?.[index];
        if (ldapServer) {
            validateLdapProperties([ldapServer])
                .then(async () => {
                    setCheckStatus(CheckStatus.validating);

                    try {
                        const result: LdapStatus = await SystemPropertiesDaoService.checkLdap(properties);

                        if (result) {
                            setCheckStatus(CheckStatus.success);
                            setLdapServiceStatus(result);
                        } else {
                            setCheckStatus(CheckStatus.error);
                        }
                    } catch (error) {
                        setCheckStatus(CheckStatus.error);
                    }
                })
                .catch((error: Error) => {
                    dispatch(
                        showNotification({
                            id: uuid(),
                            type: NotificationType.CHECK_LDAP_SERVER_ERROR,
                            data: { message: error.message } as IGenericNotification,
                        }),
                    );
                });
        }
    };

    const onChange = (newProperties: LdapProperties) => {
        const newPropertiesState = { ...properties, ...newProperties };
        // храним состояние в сторе и в форме, т.к. из формы не получается вернуть актуальные данные
        // onChange из формы возвращает не измененные данные
        setProperties(newPropertiesState);
        onChangeSetter(newPropertiesState);
    };

    const getPasswordStatusMessage = (
        status: LdapPropertiesPasswordStatusEnum | undefined,
        intl: IntlShape,
    ): string => {
        let descriptor: MessageDescriptor = messages.ldapBindPasswdNone;
        if (status === 'SAVE') {
            descriptor = messages.ldapBindPasswdSave;
        } else if (status === 'SAFE') {
            descriptor = messages.ldapBindPasswdSafe;
        }

        return intl.formatMessage(descriptor);
    };

    return (
        <div key={`ldapProperties-${index}`}>
            <Text style={{ fontWeight: 'bold' }}>{`${intl.formatMessage(messages.ldapServer)} ${index + 1}`}</Text>
            <Form
                {...formItemLayout}
                scrollToFirstError
                ref={LdapFormRefs[`LdapFormRef${index}`]}
                onValuesChange={(changedValues) => {
                    if ((Object.keys(changedValues)[0] === SystemPropertiesFormItemNames.defaultDomain)) {
                        Object.keys(LdapFormRefs).forEach((key) => {
                            LdapFormRefs[`${key}`].current?.validateFields([
                                SystemPropertiesFormItemNames.defaultDomain,
                            ]);
                        });
                    }
                    LdapFormRefs[`LdapFormRef${index}`].current?.validateFields([changedValues]);
                }}
            >
                <Form.Item
                    className={theme.formItem}
                    label={intl.formatMessage(messages.ldapServerId)}
                    initialValue={id}
                    name={SystemPropertiesFormItemNames.id}
                >
                    <Input disabled data-test="server-settings_ldap_server-id" />
                </Form.Item>
                <Form.Item
                    required
                    className={theme.formItem}
                    label={intl.formatMessage(messages.ldapBaseDn)}
                    initialValue={baseDn}
                    name={SystemPropertiesFormItemNames.baseDn}
                    rules={[{ required: true, message: intl.formatMessage(messages.missingRequiredParameter) }]}
                >
                    <Input
                        data-test="LDAP_root-DN"
                        onChange={(event: any) => onChange({ baseDn: event?.target?.value })}
                    />
                </Form.Item>
                <Form.Item
                    className={theme.formItem}
                    label={intl.formatMessage(messages.ldapBindDn)}
                    name={SystemPropertiesFormItemNames.bindDn}
                    initialValue={bindDn}
                    rules={[{ required: true, message: intl.formatMessage(messages.missingRequiredParameter) }]}
                >
                    <Input
                        data-test="LDAP_system-user-DN"
                        onChange={(event: any) => onChange({ bindDn: event?.target?.value })}
                    />
                </Form.Item>
                <Form.Item className={theme.formItem} label={intl.formatMessage(messages.ldapBindPasswd)}>
                    <Form.Item name={SystemPropertiesFormItemNames.bindPasswd} initialValue={bindPasswd} noStyle>
                        <Input
                            data-test="LDAP_system-user_password"
                            onChange={(event: any) => onChange({ bindPasswd: event?.target?.value })}
                        />
                    </Form.Item>
                    <Text>{getPasswordStatusMessage(passwordStatus, intl)}</Text>
                </Form.Item>
                <Form.Item
                    className={theme.formItem}
                    initialValue={domainNameNetBios}
                    name={SystemPropertiesFormItemNames.domainNameNetBios}
                    label={intl.formatMessage(messages.ldapNetBiosName)}
                    rules={[
                        {
                            validator: () => {
                                const nbNames = ldapSettings.map((p) => p.domainNameNetBios).filter((s) => s);
                                const hasDubNBNames = nbNames.length !== new Set(nbNames).size;

                                return hasDubNBNames
                                    ? Promise.reject(new Error(intl.formatMessage(errorMessages.LdapNetBiosErrorTitle)))
                                    : Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input onChange={(event: any) => onChange({ domainNameNetBios: event?.target?.value })} />
                </Form.Item>
                <Form.Item
                    className={theme.formItem}
                    label={intl.formatMessage(messages.ldapDomainName)}
                    initialValue={domainName}
                    name={SystemPropertiesFormItemNames.domainName}
                    rules={[
                        {
                            validator: () => {
                                const domains = ldapSettings.map((p) => p.domainName).filter((s) => s);
                                const hasDubDomain = domains.length !== new Set(domains).size;

                                return hasDubDomain
                                    ? Promise.reject(new Error(intl.formatMessage(errorMessages.LdapDomainsErrorTitle)))
                                    : Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input onChange={(event: any) => onChange({ domainName: event?.target?.value })} />
                </Form.Item>
                <Form.Item
                    className={theme.formItem}
                    label={intl.formatMessage(messages.ldapUsersGroup)}
                    initialValue={usersGroup}
                    name={SystemPropertiesFormItemNames.usersGroup}
                >
                    <Input onChange={(event: any) => onChange({ usersGroup: event?.target?.value })} />
                </Form.Item>
                <Form.Item
                    className={theme.formItem}
                    label={intl.formatMessage(messages.ldapIgnoreFailCert)}
                    initialValue={ignoreFailCert}
                    name={SystemPropertiesFormItemNames.ignoreFailCert}
                    valuePropName="checked" // todo проверить работу
                >
                    <Checkbox
                        className={theme.checkbox}
                        onChange={(event: CheckboxChangeEvent) => onChange({ ignoreFailCert: event?.target?.checked })}
                    />
                </Form.Item>
                <Form.Item
                    className={theme.formItem}
                    label={intl.formatMessage(messages.ldapDefaultDomain)}
                    initialValue={defaultDomain}
                    valuePropName="checked"
                    name={SystemPropertiesFormItemNames.defaultDomain}
                    rules={[
                        {
                            validator: () => {
                                const ldapSettings = form?.getFieldValue('ldapSettings');
                                const defaultDomainOneOrNone = ldapSettings.filter((p) => p.defaultDomain).length <= 1;

                                return defaultDomainOneOrNone
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(intl.formatMessage(errorMessages.LdapDefaultDomainErrorTitle)),
                                    );
                            },
                        },
                    ]}
                >
                    <Checkbox
                        data-test="LDAP_default-domain"
                        className={theme.checkbox}
                        onChange={(event: CheckboxChangeEvent) => onChange({ defaultDomain: event?.target?.checked })}
                    />
                </Form.Item>
                <Form.Item
                    className={theme.formItem}
                    initialValue={searchFilter}
                    name={SystemPropertiesFormItemNames.searchFilter}
                    label={intl.formatMessage(messages.ldapSearchFilter)}
                    rules={[{ required: true, message: intl.formatMessage(messages.missingRequiredParameter) }]}
                >
                    <Input
                        data-test="LDAP_search-filter"
                        onChange={(event: any) => onChange({ searchFilter: event?.target?.value })}
                    />
                </Form.Item>
                <Form.Item required className={theme.formItem} label={intl.formatMessage(messages.ldapServerUrl)}>
                    <Form.Item
                        rules={[{ required: true, message: intl.formatMessage(messages.missingRequiredParameter) }]}
                        initialValue={serverUrl}
                        name={SystemPropertiesFormItemNames.ldapServerUrl}
                        noStyle
                    >
                        <Input
                            data-test="LDAP_server-url"
                            onChange={(event: any) => onChange({ serverUrl: event?.target?.value })}
                        />
                    </Form.Item>
                    <span style={{ marginBottom: '3px' }}>
                        {checkStatus === CheckStatus.success && ldapServiceStatus?.mainUrlOk && (
                            <Text data-test="LDAP_server-status_active" style={{ color: 'green' }} strong>
                                {intl.formatMessage(messages.ldapServerStatusOk)}
                            </Text>
                        )}
                        {checkStatus === CheckStatus.success && !ldapServiceStatus?.mainUrlOk && (
                            <Text type="danger">{intl.formatMessage(messages.ldapServerStatusNotOk)}</Text>
                        )}
                        {checkStatus === CheckStatus.error && (
                            <Text type="danger">{intl.formatMessage(messages.ldapServerCheckError)}</Text>
                        )}
                    </span>
                </Form.Item>
                <Form.Item className={theme.formItem} label={intl.formatMessage(messages.ldapServerUrlAlt)}>
                    <Form.Item
                        initialValue={serverUrlAlt}
                        name={SystemPropertiesFormItemNames.serverUrlAlt}
                        noStyle
                        rules={[
                            {
                                validator: () => {
                                    return !properties?.serverUrlAlt || properties.serverUrlAlt !== properties.serverUrl
                                        ? Promise.resolve()
                                        : Promise.reject(
                                            new Error(intl.formatMessage(errorMessages.LdapServerUrlErrorTitle)),
                                        );
                                },
                            },
                        ]}
                    >
                        <Input onChange={(event: any) => onChange({ serverUrlAlt: event?.target?.value })} />
                    </Form.Item>
                    <span style={{ marginBottom: '3px' }}>
                        {checkStatus === CheckStatus.success && ldapServiceStatus?.altUrlOk && (
                            <Text style={{ color: 'green' }} strong>
                                {intl.formatMessage(messages.ldapServerStatusOk)}
                            </Text>
                        )}
                        {checkStatus === CheckStatus.success && !ldapServiceStatus?.altUrlOk && (
                            <Text type="danger">{intl.formatMessage(messages.ldapServerStatusNotOk)}</Text>
                        )}
                        {checkStatus === CheckStatus.error && (
                            <Text type="danger">{intl.formatMessage(messages.ldapServerCheckError)}</Text>
                        )}
                    </span>
                </Form.Item>
                <Form.Item
                    className={theme.formItem}
                    label={intl.formatMessage(messages.ldapIgnoreServer)}
                    initialValue={ignore}
                    name={SystemPropertiesFormItemNames.ignore}
                    valuePropName="checked"
                >
                    <Checkbox
                        className={theme.checkbox}
                        onChange={(event: CheckboxChangeEvent) => onChange({ ignore: event?.target?.checked })}
                    />
                </Form.Item>
                <Form.Item
                    className={theme.formItem}
                    label={intl.formatMessage(messages.receiveGroupFromToken)}
                    initialValue={receiveGroupFromToken}
                    name={SystemPropertiesFormItemNames.receiveGroupFromToken}
                    valuePropName="checked"
                >
                    <Checkbox
                        className={theme.checkbox}
                        onChange={(event: CheckboxChangeEvent) => onChange({ receiveGroupFromToken: event?.target?.checked })}
                    />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        xs: { span: 24, offset: 0 },
                        sm: { span: 16, offset: 8 },
                    }}
                >
                    <Button data-test="LDAP_check-server" onClick={() => handleCheckLdapService(index)}>
                        {intl.formatMessage(messages.ldapCheckServer)}
                    </Button>
                    <span style={{ marginBottom: '3px' }}>
                        {checkStatus === CheckStatus.validating && (
                            <Text type="secondary">{intl.formatMessage(messages.ldapServerChecking)}...</Text>
                        )}
                    </span>
                    <Button data-test="LDAP_delete-server_button" danger onClick={onRemoveServer}>
                        {intl.formatMessage(messages.ldapRemoveServer)}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
