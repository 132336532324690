import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Checkbox, Divider, Form, InputNumber, Radio, Select } from 'antd';
import { paperSizes } from '../../../utils/print.utils';
import { Orientation, TPrintOptions, ZoomMode } from '../../../utils/types';
import messages from './PrintDialog.messages';
import theme from './PrintForm.component.scss';
import { FieldData } from 'antd/node_modules/rc-field-form/es/interface';
import { MAX_PAGES, MAX_SCALE, MIN_PAGES, MIN_SCALE } from './PrintForm.constans';
import { debounce } from 'lodash';

type TPrintFormProps = {
    options: TPrintOptions;
    onFieldsChange: (changedFields: FieldData[]) => void;
} & JSX.IntrinsicAttributes;

type TPrintFormAllProps = TPrintFormProps & WrappedComponentProps;

const PrintForm = (props: TPrintFormAllProps) => {
    const { intl, options, onFieldsChange } = props;
    const {
        grayscale,
        orientation,
        paperFormat,
        zoomFitAcross,
        zoomFitDown,
        zoomMode,
        zoomScale,
        showOffsets,
        printable,
    } = options;

    return (
        <Form className={theme.form} layout="vertical" onFieldsChange={debounce(onFieldsChange, 250)}>
            <Form.Item initialValue={printable} name="printable" className={theme.formItem}>
                <Select data-test="print-window_dropdown-menu_print-mode" dropdownStyle={{ zIndex: 9999 }}>
                    <Select.Option data-test="model-printing_btn" value="MODEL">
                        {intl.formatMessage(messages.model)}
                    </Select.Option>
                    <Select.Option data-test="fragment-printing_btn" value="SELECTION">
                        {intl.formatMessage(messages.selection)}
                    </Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label={intl.formatMessage(messages.paperFormat)}
                initialValue={paperFormat}
                name="paperFormat"
                className={theme.formItem}
            >
                <Select data-test="print-window_dropdown-menu_sheet-size" dropdownStyle={{ zIndex: 9999 }}>
                    {Object.keys(paperSizes).map((format) => {
                        const [paperWidth, paperHeight] = paperSizes[format];

                        return (
                            <Select.Option data-test={`sheet_format_${format}`} value={format} key={format}>
                                {format} ({paperWidth}
                                {intl.formatMessage(messages.millimeters)}
                                {' x '}
                                {paperHeight}
                                {intl.formatMessage(messages.millimeters)})
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label={intl.formatMessage(messages.orientation)}
                initialValue={orientation}
                name="orientation"
                className={theme.formItem}
            >
                <Radio.Group className={theme.radioGroup}>
                    <Radio data-test="portrait_sheet_orientation_btn" value={Orientation.PORTRAIT}>
                        {intl.formatMessage(messages.portrait)}
                    </Radio>
                    <Radio data-test="landscape_sheet_orientation_btn" value={Orientation.LANDSCAPE}>
                        {intl.formatMessage(messages.landscape)}
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Divider className={theme.divider} />
            <Form.Item
                name="zoomMode"
                initialValue={zoomMode}
                label={intl.formatMessage(messages.zoom)}
                style={{ marginBottom: 0 }}
            >
                <Radio.Group>
                    <Radio value={ZoomMode.SCALE} style={{ display: 'block', height: '55px' }}>
                        {intl.formatMessage(messages.scale)}
                        <Form.Item initialValue={zoomScale} name="zoomScale" style={{ marginBottom: 0 }}>
                            <InputNumber
                                data-test="print-window_zoom-box"
                                min={MIN_SCALE}
                                max={MAX_SCALE}
                                formatter={(value) => `${value || MIN_SCALE}%`}
                                size="small"
                                style={{
                                    width: '70px',
                                    marginLeft: '23px',
                                }}
                            />
                        </Form.Item>
                    </Radio>
                    <Radio
                        data-test="adjustment-to-the-number-of-sheets_radio-btn"
                        value={ZoomMode.FIT}
                        style={{ display: 'block' }}
                    >
                        {intl.formatMessage(messages.fit)}
                        <div data-test="container_number-of-pages-wide_input-fields" className={theme.fitBlock}>
                            <Form.Item initialValue={zoomFitAcross} name="zoomFitAcross" style={{ marginBottom: 0 }}>
                                <InputNumber
                                    data-test="number-of-pages-wide_input-fields"
                                    min={MIN_PAGES}
                                    max={MAX_PAGES}
                                    formatter={(value) => `${value || MIN_PAGES}`}
                                    size="small"
                                    style={{
                                        width: '50px',
                                        marginLeft: '23px',
                                        marginRight: '10px',
                                    }}
                                />
                            </Form.Item>
                            <div className={theme.fitBlock_description}>{intl.formatMessage(messages.across)}</div>
                        </div>
                        <div data-test="container_number-of-pages-in-height_input-fields" className={theme.fitBlock}>
                            <Form.Item initialValue={zoomFitDown} name="zoomFitDown" style={{ marginBottom: 0 }}>
                                <InputNumber
                                    data-test="number-of-pages-in-height_input-fields"
                                    min={MIN_PAGES}
                                    max={MAX_PAGES}
                                    formatter={(value) => `${value || MIN_PAGES}`}
                                    size="small"
                                    style={{
                                        width: '50px',
                                        marginRight: '10px',
                                        marginLeft: '23px',
                                    }}
                                />
                            </Form.Item>
                            <div className={theme.fitBlock_description}>{intl.formatMessage(messages.down)}</div>
                        </div>
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Divider className={theme.divider} />
            <Form.Item
                valuePropName="checked"
                initialValue={showOffsets}
                name="showOffsets"
                style={{ marginBottom: 0 }}
            >
                <Checkbox data-test="check-box_indent" disabled={zoomMode !== ZoomMode.SCALE}>
                    {intl.formatMessage(messages.offset)}
                </Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" initialValue={grayscale} name="grayscale" style={{ marginBottom: 0 }}>
                <Checkbox data-test="check-box_ink_and_white_printing">
                    {intl.formatMessage(messages.grayscale)}
                </Checkbox>
            </Form.Item>
        </Form>
    );
};

const WrappedPrintFormWithIntl = injectIntl(PrintForm);

export { WrappedPrintFormWithIntl as PrintForm };
