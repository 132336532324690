import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModelEventsSelectors } from '../../../../selectors/modelEvents.selector';
import { getModelEventsRequest } from '../../../../actions/modelEvents.actions';
import { EventDescriptorMacros, NodeId } from '../../../../serverapi/api';
import { ModelEventsListHeader } from './ModelEventsListHeader.component';
import { ModelEventsListTable } from './ModelEventsListTable.component';
import { nodesSelector } from '../../../../selectors/nodes.selector';

type TModelEventsListProps = {
    nodeId: NodeId;
    modelEvents: EventDescriptorMacros[];
    setModelEvents: (modelEvents: EventDescriptorMacros[]) => void;
};

export const ModelEventsList: FC<TModelEventsListProps> = ({ nodeId, modelEvents, setModelEvents }) => {
    const dispatch = useDispatch();

    const loading = useSelector(ModelEventsSelectors.loading);
    const scripts = useSelector(nodesSelector);

    useEffect(() => {
        dispatch(getModelEventsRequest(nodeId));
    }, []);

    useEffect(() => {
        if (modelEvents) setModelEvents(modelEvents);
    }, [modelEvents]);

    return (
        <>
            <ModelEventsListHeader
                nodeId={nodeId}
                modelEvents={modelEvents}
                setModelEvents={setModelEvents}
                scripts={scripts}
            />
            <ModelEventsListTable
                loading={loading}
                modelEvents={modelEvents}
                scripts={scripts}
                setModelEvents={setModelEvents}
            />
        </>
    );
};
