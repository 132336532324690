// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GeneralSettingsTab__form___eeI6{padding:5px 15px;width:500px;margin-left:10px}.GeneralSettingsTab__form___eeI6 span{font-size:13px}.GeneralSettingsTab__form___eeI6 .ant-checkbox{top:unset}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/GeneralSettingsTab/GeneralSettingsTab.scss"],"names":[],"mappings":"AAAA,iCACI,gBAAA,CACA,WAAA,CACA,gBAAA,CAEA,sCACI,cAAA,CAGA,+CACI,SAAA","sourcesContent":[".form {\r\n    padding: 5px 15px;\r\n    width: 500px;\r\n    margin-left: 10px;\r\n\r\n    span {\r\n        font-size: 13px;\r\n    }\r\n    :global {\r\n        .ant-checkbox {\r\n            top: unset;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "GeneralSettingsTab__form___eeI6"
};
export default ___CSS_LOADER_EXPORT___;
