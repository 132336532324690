import type { TTreeItemSelectDialogProps } from './TreeItemSelectDialog.types';
import type { TreeNode } from '../../../models/tree.types';
import React, { Component } from 'react';
import messages from './TreeItemSelectDialog.messages';
import { injectIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './TreeItemSelectDialog.component.scss';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { Tree } from '../../Tree/components/Tree/Tree.component';
import { Button } from 'antd';

class TreeItemSelectDialogComponent extends Component<TTreeItemSelectDialogProps> {
    state = {
        nodeId: {
            id: '',
            repositoryId: '',
            serverId: '',
        },
        selectedNode: null,
    };

    handleSubmit = () => {
        this.props.onSubmit(this.state.nodeId, this.state.selectedNode);
    };

    handleSelectTreeItem = (selectedNode: TreeNode) => {
        this.setState({ nodeId: selectedNode.nodeId, selectedNode });
    };

    handleClear = () => {
        this.setState({
            nodeId: {
                id: '',
                repositoryId: '',
                serverId: '',
            },
            selectedNode: null,
        });
        this.props.onClear();
    };

    render() {
        const { onClose, intl, treeLocation, disableContextMenu, visible, isTreeWithClearButton } = this.props;
        const footer = [
            <div className={theme.buttons}>
                <div>
                    <Button data-test="clear_attribute-NODE_btn" key="clear" danger onClick={this.handleClear}>
                        {intl.formatMessage(messages.formClearAttributeButton)}
                    </Button>
                </div>
                <div>
                    <Button data-test="node-translation_button_cancel" key="cancel" onClick={onClose}>
                        {intl.formatMessage(messages.formDeclineButton)}
                    </Button>

                    <Button
                        data-test="node-translation_button_submit"
                        key="ok"
                        type="primary"
                        onClick={this.handleSubmit}
                    >
                        {intl.formatMessage(messages.formConfirmButton)}
                    </Button>
                </div>
            </div>,
        ];
        const children = (
            <Tree
                treeName="TreeItemSelect"
                onSelect={this.handleSelectTreeItem}
                data={treeLocation || []}
                disableContextMenu={disableContextMenu}
                selectedNodes={[this.state]}
            />
        );

        return (
            <Dialog
                footer={isTreeWithClearButton && footer}
                className={theme.modal}
                onOk={this.handleSubmit}
                okButtonProps={{ disabled: !this.state.nodeId.id }}
                onCancel={onClose}
                title={intl.formatMessage(messages.formName)}
                open={visible}
                width={DEFAULT_DIALOG_WIDTH}
                okText={intl.formatMessage(messages.formConfirmButton)}
                cancelText={intl.formatMessage(messages.formDeclineButton)}
            >
                {children}
            </Dialog>
        );
    }
}

const TreeItemSelectDialogWithIntl = injectIntl(TreeItemSelectDialogComponent);

export { TreeItemSelectDialogWithIntl as TreeItemSelectDialog };
