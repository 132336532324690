import { Alignment } from '../models/alignment';
import { MxConstants } from '../mxgraph/mxgraph';

export const availableAlignments = {
    [Alignment.Left]: MxConstants.ALIGN_LEFT,
    [Alignment.CenterVert]: MxConstants.ALIGN_CENTER,
    [Alignment.Right]: MxConstants.ALIGN_RIGHT,
    [Alignment.Top]: MxConstants.ALIGN_TOP,
    [Alignment.CenterHorz]: MxConstants.ALIGN_MIDDLE,
    [Alignment.Bottom]: MxConstants.ALIGN_BOTTOM,
};

export enum CustomMxEvent {
    CHANGE_COMMENTS_POSITION = 'CHANGE_COMMENTS_POSITION',
    GRAPH_SYMBOL_ADDED = 'GRAPH_SYMBOL_ADDED',
    UPDATE_CELLS_OVERLAYS = 'UPDATE_CELLS_OVERLAYS',
}
