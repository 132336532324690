import type { AttributeTypeStyleRule, AttributeTypeStyleRuleTypeEnum } from '../../../../../serverapi/api';
import React from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import { AttributeTypeSelectors } from '../../../../../selectors/attributeType.selectors';
import { getActiveGraph } from '../../../../../selectors/editor.selectors';
import { getCurrentLocale } from '../../../../../selectors/locale.selectors';
import { TreeSelectors } from '../../../../../selectors/tree.selectors';
import { LocalesService } from '../../../../../services/LocalesService';
import { ErrorWrapper } from './ErrorWrapper';
import { FloatingAttributesPanelSelectors } from '../../../selectors/FloatingAttributes.selectors';

type TSelectInput = {
    attributeStyleId: string;
    rule: AttributeTypeStyleRule;
    disabled?: boolean;
    mode?: 'multiple' | 'tags' | undefined;
    handleRuleParams: (ruleType: AttributeTypeStyleRuleTypeEnum | undefined, value1: string, value2?: string) => void;
};

export const SelectInput = (props: TSelectInput) => {
    const { attributeStyleId, rule, mode, disabled, handleRuleParams } = props;
    const currentLocale = useSelector(getCurrentLocale);
    const activeGraphId = useSelector(getActiveGraph);
    const serverId = activeGraphId?.serverId || '';
    const presetId: string =
        useSelector(TreeSelectors.presetById(activeGraphId)) ||
        useSelector(FloatingAttributesPanelSelectors.getPresetId);
    const attributeType = useSelector(
        AttributeTypeSelectors.byObjectTypeId({ objectTypeId: attributeStyleId, presetId, serverId }),
    );
    const values = attributeType?.selectPropertyValues || [];
    const handleChangeAttributeValue = (ids: string[]) => {
        handleRuleParams(rule.type, ids.toString());
    };

    return (
        <ErrorWrapper error={!rule.param}>
            <Select
                data-test="change-the-value-of-the-rule_select-type"
                value={rule.param ? rule.param?.split(',') : undefined}
                disabled={disabled}
                style={{ width: '100%' }}
                mode={mode}
                onChange={handleChangeAttributeValue}
            >
                {values?.map((v) => (
                    <Select.Option
                        data-test={`select-value-for-the-rule_${
                            LocalesService.internationalStringToString(v.value, currentLocale) || ''
                        }`}
                        key={v.id}
                        value={v.id}
                    >
                        {LocalesService.internationalStringToString(v.value, currentLocale) || ''}
                    </Select.Option>
                ))}
            </Select>
        </ErrorWrapper>
    );
};
