import { connect } from 'react-redux';
import { TRootState } from '../../reducers/root.reducer.types';
import { closeDialog } from '../../actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { MatrixSettingsDialog } from './MatrixSettingsDialog.component';
import { TMatrixSettingsDialogProps } from './MatrixSettingsDialog.types';
import { MatrixSelectors } from '../../selectors/entities/matrix.selectors';
import { TabsSelectors } from '../../selectors/tabs.selectors';
import { matrixChangeDataRequest, matrixUploadFile } from '../../actions/entities/matrix.actions';
import { ServerSelectors } from '../../selectors/entities/server.selectors';
import { getTreeItems, treeNestedChildrenMap, filterTreeIncludeTypes } from '../../selectors/tree.selectors';
import { TreeItemType } from '../Tree/models/tree';
import { MatrixData, MatrixNode, NodeId } from '../../serverapi/api';
import { TreeNode, TTreeEntityState } from '../../models/tree.types';

const ALLOW_NODE_TYPES = [
    TreeItemType.Server,
    TreeItemType.Repository,
    TreeItemType.ObjectDefinition,
    TreeItemType.Folder,
];

const mapStateToProps = (state: TRootState, props: TMatrixSettingsDialogProps): Partial<TMatrixSettingsDialogProps> => {
    const nodeId: NodeId | undefined = TabsSelectors.getActiveTabId(state);
    const matrixNode: MatrixNode | undefined = MatrixSelectors.byId(nodeId || ({} as NodeId))(state);

    let treeNodes: TreeNode[] = [];
    let treeItemsById: { [id: string]: TTreeEntityState } = {};

    if (nodeId && nodeId.repositoryId) {
        treeItemsById = getTreeItems(nodeId.serverId, nodeId.repositoryId)(state);
        treeNodes = treeNestedChildrenMap(treeItemsById, nodeId.repositoryId);
        treeNodes = filterTreeIncludeTypes(treeNodes, ALLOW_NODE_TYPES);
    }

    const { serverId } = matrixNode.parentNodeId!;

    return {
        ...props,
        content: { ...matrixNode, serverId },
        matrixId: nodeId,
        serverId,
        serverUrl: ServerSelectors.server(serverId)(state).url,
        treeNodes,
        treeItemsById,
    };
};

const mapDispatchToProps = (dispatch) => ({
    // Hot-fix т.к.сохранение пользовательской иконки происходит в саге, надо при отмене пересохранять ноду,
    // получается метод нужен только один
    // onCancel(matrix: MatrixNode) {
    //
    //     dispatch(matrixChangeRequest(matrix));
    //     dispatch(closeDialog(DialogType.MATRIX_SETTINGS_DIALOG));
    // },
    onSubmit(nodeId: NodeId, data: MatrixData | undefined) {
        if (data) {
            dispatch(matrixChangeDataRequest(nodeId, data));
            dispatch(closeDialog(DialogType.MATRIX_SETTINGS_DIALOG));
        }
    },
    onFileDrop(
        nodeId: NodeId,
        data: MatrixData,
        draggedFile: File,
        serverId: string,
        description: string,
        setContent: (data: MatrixData) => void,
    ) {
        dispatch(matrixUploadFile(nodeId, data, draggedFile, serverId, description, setContent));
    },
});

export const MatrixSettingsDialogContainer = connect(mapStateToProps, mapDispatchToProps)(MatrixSettingsDialog);
