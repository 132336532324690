import {
    FETCH_AUDIT,
    FETCH_DETAILED_AUDIT,
    FETCH_DETAILED_AUDIT_SUCCESS,
    FETCH_AUDIT_SUCCESS,
    DOWNLOAD_CSV,
    DELETE_AUDIT_RANGE,
    DELETE_SESSION_RANGE,
    FINISH_DOWNLOAD_CSV,
    SET_AUDIT_FILTERED_TYPES,
    SET_AUDIT_FILTERED_ACCESSES,
} from '../actionsTypes/audit.actionTypes';
import { AuditDto, DetailedAuditDTO } from '../serverapi/api';
import {
    IDeleteAuditRange,
    IDeleteSessionRange,
    IFetchDetailedAudit,
    IFetchDetailedAuditSuccess,
    IAuditFetchParams,
    IFetchAudit,
    IFetchAuditSuccess,
    TDownloadCSVAction,
    TFinishDownloadCSVAction,
    IFilteredTypes,
    IFilteredAccesses,
    accessTypes,
} from './audit.actions.types';

export const fetchAudit = (serverId: string, params: IAuditFetchParams): IFetchAudit => ({
    type: FETCH_AUDIT,
    params,
    serverId,
});

export const fetchDetailedAudit = (payload: { serverId: string; id: number }): IFetchDetailedAudit => ({
    type: FETCH_DETAILED_AUDIT,
    payload,
});

export const deleteAuditRange = (payload: {
    start: number;
    end: number;
    serverId: string;
    password: string;
}): IDeleteAuditRange => ({
    type: DELETE_AUDIT_RANGE,
    payload,
});

export const deleteSessionRange = (payload: {
    start: number;
    end: number;
    serverId: string;
    password: string;
}): IDeleteSessionRange => ({
    type: DELETE_SESSION_RANGE,
    payload,
});

export const fetchDetailedAuditSuccess = (payload: {
    serverId: string;
    detailedAudit: DetailedAuditDTO;
}): IFetchDetailedAuditSuccess => ({
    type: FETCH_DETAILED_AUDIT_SUCCESS,
    payload,
});

export const fetchAuditSuccess = (serverId: string, audit: AuditDto[]): IFetchAuditSuccess => ({
    type: FETCH_AUDIT_SUCCESS,
    serverId,
    audit,
});

export const finishDownloadCSV = (): TFinishDownloadCSVAction => ({
    type: FINISH_DOWNLOAD_CSV,
});

export const downloadCSV = (serverId: string, params: IAuditFetchParams): TDownloadCSVAction => ({
    type: DOWNLOAD_CSV,
    payload: { serverId, params },
});

export const setAuditFilteredTypes = (filteredTypes: string[]): IFilteredTypes => ({
    type: SET_AUDIT_FILTERED_TYPES,
    payload:  { filteredTypes },
});

export const setAuditFilteredAccesses = (filteredAccesses: accessTypes[]): IFilteredAccesses => ({
    type: SET_AUDIT_FILTERED_ACCESSES,
    payload:  { filteredAccesses },
});

