import type { TDecompositionsListDialogOwnProps } from '../modules/Models/components/DecompositionsList/DecompositionsListDialog.types';
import type { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import type { TTreeEntityState } from '../models/tree.types';
import type {
    EdgeDefinitionNode,
    EdgeType,
    MatrixNode,
    ModelAssignment,
    ModelAssignmentNodeTypeEnum,
    ModelNode,
    ModelType,
    NodeId,
    ObjectDefinitionNode,
    ObjectType,
    PathResponse,
    WikiNode,
} from '../serverapi/api';
import type {
    TObjectDecompositionDialogInit,
    TObjectDecompositionDialogSubmit,
    TObjectDecompositionIconClicked,
    TObjectDecompositionOpen,
} from '../actions/entities/objectDecomposition.actions.types';
import { put, race, select, take, takeEvery } from 'redux-saga/effects';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { objectDecompositionOpen } from '../actions/entities/objectDecomposition.actions';
import {
    OBJECT_DECOMPOSITION_DIALOG_INIT,
    OBJECT_DECOMPOSITION_DIALOG_SUBMIT,
    OBJECT_DECOMPOSITION_ICON_CLICKED,
    OBJECT_DECOMPOSITION_OPEN,
} from '../actionsTypes/entities/objectDecomposition.actionTypes';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import requiredValue from '../utils/required-value';
import { WIKI_CREATE_FAILURE, WIKI_CREATE_SUCCESS } from '../actionsTypes/entities/wiki.actionTypes';
import { modelDialogSubmit } from '../actions/modelDialog.actions';
import { SAVE_MODEL_FAIL, SAVE_MODEL_SUCCESS } from '../actionsTypes/save.actionTypes';
import { openNodeByTreeItemNodeId } from './tree.saga';
import { ModelTypeSelectors } from '../selectors/modelType.selectors';
import { TreeItemType } from '../modules/Tree/models/tree';
import { compareStringIds } from '../utils/nodeId.utils';
import { MATRIX_CREATE_SUCCESS, MATRIX_SAVE_REQUEST_FAILURE } from '../actionsTypes/entities/matrix.actionTypes';
import { TreeSelectors } from '../selectors/tree.selectors';
import { MxCell } from '../mxgraph/mxgraph';
import { saveObjectDefinition } from '../actions/entities/objectDefinition.actions';
// todo (BPM-5139)
// import { treeItemCollapseAll, treeItemsExpandWithoutLoad } from '../actions/tree.actions';
// import { getChainFromChildToParent } from '../services/utils/treeService.utils';
import { ObjectTypeSelectors } from '../selectors/objectType.selectors';
import { EdgeDefinitionSelectors } from '../selectors/edgeDefinition.selector';
import { EdgeTypeSelectors } from '../selectors/edgeType.selectors';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { ObjectDefinitionsDAOService } from '../services/dao/ObjectDefinitionsDAOService';
import { edgeDefinitionsUpdate } from '../actions/entities/edgeDefinition.actions';
import { EdgeDefinitionDAOService } from '../services/dao/EdgeDefinitionDAOService';
import { showNotificationByType } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import {
    navigatorPropertiesChangeObjectAction,
} from '../actions/navigatorProperties.actions';
import { treeItemSelect } from '@/actions/tree.actions';

function* handleDialogCreation({ payload }: TObjectDecompositionDialogInit) {
    const { graphId, objectDefinitionId, edgeDefinitionId, edgeInstance, setStateAssignments, stateAssignments } =
        payload;
    const { repositoryId, serverId } = graphId;

    let validDecompositionModelTypesIds: string[] | undefined;
    let allowAnyDecomposition: boolean = true;
    let modelAssignments: ModelAssignment[] = [];

    if (edgeDefinitionId) {
        const edgeNode: EdgeDefinitionNode = yield select(EdgeDefinitionSelectors.byId(edgeDefinitionId));
        if (!edgeNode) {
            yield put(showNotificationByType(NotificationType.FORBIDDEN_ERROR));

            return;
        }

        const presetId: string = yield select(TreeSelectors.presetById(edgeDefinitionId));
        const edgeType: EdgeType | undefined =
            edgeNode.edgeTypeId &&
            (yield select(
                EdgeTypeSelectors.byId({
                    edgeTypeId: edgeNode.edgeTypeId,
                    presetId,
                    serverId,
                }),
            ));
        validDecompositionModelTypesIds = edgeType?.validDecompositionModelTypesIds;
        allowAnyDecomposition = edgeType ? edgeType.allowAnyDecomposition : true;
        modelAssignments = edgeNode.modelAssignments || [];
    }

    if (objectDefinitionId) {
        // todo (BPM-5139)
        // const { id } = objectDefinitionId;
        const objectNode: ObjectDefinitionImpl = yield select(ObjectDefinitionSelectors.byId(objectDefinitionId));
        const presetId: string = yield select(TreeSelectors.presetById(objectDefinitionId));
        const objectType: ObjectType | undefined =
            objectNode.objectTypeId &&
            (yield select(
                ObjectTypeSelectors.byId({
                    objectTypeId: objectNode.objectTypeId,
                    presetId,
                    serverId,
                }),
            ));
        validDecompositionModelTypesIds = objectType?.validDecompositionModelTypesIds;
        allowAnyDecomposition = objectType ? objectType.allowAnyDecomposition : true;
        modelAssignments = objectNode.modelAssignments || [];

        // todo (BPM-5139)
        // yield put(treeItemCollapseAll(DialogType.MODEL_DIALOG));
        // const nodes = yield select(getTreeItems(serverId, repositoryId));
        // const ids: NodeId[] = getChainFromChildToParent(nodes, id)
        //     .map((n) => n.nodeId)
        //     .splice(1);
        // yield put(treeItemsExpandWithoutLoad(ids, DialogType.MODEL_DIALOG));
    }

    const repositoryNode: TTreeEntityState = yield select(
        TreeSelectors.itemById({ id: repositoryId, repositoryId, serverId }),
    );
    yield put(treeItemSelect(repositoryNode));

    yield put(
        openDialog(DialogType.OBJECT_DECOMPOSITION_CREATE, {
            repositoryId,
            objectDefinitionId,
            edgeDefinitionId,
            edgeInstance,
            serverId,
            setStateAssignments,
            validDecompositionModelTypesIds: validDecompositionModelTypesIds || [],
            allowAnyDecomposition,
            modelAssignmentsIds: modelAssignments.map((modelAssignment) => modelAssignment.modelId),
            stateAssignments,
        }),
    );
}

function* handleDecompositionDialogSubmit({ payload }: TObjectDecompositionDialogSubmit) {
    let { modelNodeId, nodeType } = payload;
    const { setStateAssignments, stateAssignments, oldGraphId } = payload;
    const { isNewModel, modelName, objectDefinitionId, edgeDefinitionId, serverId, parentNodeId, modelTypeId } =
        payload;
    const presetId: string = yield select(TreeSelectors.presetById(parentNodeId));
    const modelType = yield select(ModelTypeSelectors.byId({ modelTypeId, serverId }, presetId));

    if (isNewModel) {
        yield put(
            modelDialogSubmit({
                parentNodeId,
                modelTypeId: modelType.id,
                modelName,
            }),
        );
        const { saveFail, saveModelSuccess, createWikiSuccess, createMatrixSuccess } = yield race({
            saveFail: take([SAVE_MODEL_FAIL, MATRIX_SAVE_REQUEST_FAILURE, WIKI_CREATE_FAILURE]),
            saveModelSuccess: take(SAVE_MODEL_SUCCESS),
            createWikiSuccess: take(WIKI_CREATE_SUCCESS),
            createMatrixSuccess: take(MATRIX_CREATE_SUCCESS),
        });

        if (saveModelSuccess && saveModelSuccess.payload && saveModelSuccess.payload.model) {
            const newModel: ModelNode = saveModelSuccess.payload.model;
            modelNodeId = newModel.nodeId;
            nodeType = newModel.type;
        } else if (createWikiSuccess && createWikiSuccess.payload && createWikiSuccess.payload.wiki) {
            const newWiki: WikiNode = createWikiSuccess.payload.wiki;
            modelNodeId = newWiki.nodeId;
            nodeType = newWiki.type;
        } else if (createMatrixSuccess && createMatrixSuccess.payload && createMatrixSuccess.payload.matrix) {
            const newMatrix: MatrixNode = createMatrixSuccess.payload.matrix;
            modelNodeId = newMatrix.nodeId;
            nodeType = newMatrix.type;
        } else if (saveFail) {
            return;
        }
    }

    yield decompositionNewAssignment(
        modelNodeId!,
        modelType,
        modelName,
        nodeType!,
        objectDefinitionId,
        edgeDefinitionId,
        oldGraphId,
        setStateAssignments,
        stateAssignments,
    );
    
    yield put(navigatorPropertiesChangeObjectAction({}));
    yield put(closeDialog(DialogType.OBJECT_DECOMPOSITION_CREATE));
}

function* decompositionNewAssignment(
    modelId: NodeId,
    modelType: ModelType,
    modelName: string,
    nodeType: ModelAssignmentNodeTypeEnum,
    objectDefinitionId?: NodeId,
    edgeDefinitionId?: NodeId,
    oldGraphId?: NodeId,
    // при создании декомпозиции таб переключается поэтому graphId нужно передавать а не использовать activeGraphId
    setStateAssignments?: (modelAssignments: ModelAssignment[]) => void,
    stateAssignments?: ModelAssignment[],
) {
    const newAssignment: ModelAssignment = yield buildAssignmentByNodeId(modelId, modelType, modelName, nodeType);
    let objectDefinition: ObjectDefinitionNode | undefined;
    let edgeDefinition: EdgeDefinitionNode | undefined;

    if (edgeDefinitionId) {
        edgeDefinition = yield select(EdgeDefinitionSelectors.byId(edgeDefinitionId));
    }

    if (objectDefinitionId) {
        objectDefinition = requiredValue(
            yield select(ObjectDefinitionSelectors.byId(objectDefinitionId)),
            "objectDefinition couldn't be empty",
        );
    }

    const modelAssignments: ModelAssignment[] =
        objectDefinition?.modelAssignments || edgeDefinition?.modelAssignments || [];
    const isNewAssignment = modelAssignments.every(
        (m: ModelAssignment) => !compareStringIds(m.modelId, newAssignment.modelId),
    );

    if (isNewAssignment) {
        if (setStateAssignments && stateAssignments) {
            // при создании декомпозиции через св-ва нужно хранить modelAssignments в стейте компонента
            setStateAssignments([...stateAssignments, newAssignment]);
        } else if (objectDefinition && oldGraphId) {
            // при создании декомпозиции через контекстное меню объект/связь сохраняется сразу
            yield put(
                saveObjectDefinition(oldGraphId.serverId, {
                    ...objectDefinition,
                    modelAssignments: [...modelAssignments, newAssignment],
                } as ObjectDefinitionImpl),
            );
        } else if (edgeDefinition && oldGraphId) {
            yield put(
                edgeDefinitionsUpdate({ ...edgeDefinition, modelAssignments: [...modelAssignments, newAssignment] }),
            );
        }
    }
}

// 1647 how to set return type? IterableIterator error
export function* buildAssignmentByNodeId(
    modelId: NodeId,
    modelType: ModelType,
    modelName: string,
    nodeType: ModelAssignmentNodeTypeEnum,
) {
    const modelTreeNode: TTreeEntityState = yield select(TreeSelectors.itemById(modelId));
    const { serverId, id, repositoryId } = modelId;
    const pathResponse: PathResponse = yield TreeDaoService.getNodePath(serverId, id, repositoryId);

    return {
        modelId: modelTreeNode?.nodeId.id,
        modelName,
        modelTypeName: modelType ? modelType.name : null,
        nodeType,
        modelPath: pathResponse.path,
    } as ModelAssignment;
}

export function findCellById(
    cells: { [index: string]: MxCell } | undefined,
    cellId: string | undefined,
): MxCell | undefined {
    if (!cells || !cellId) {
        return undefined;
    }

    const cell = cells[cellId];

    if (cell) {
        return cell;
    }
    // при создании новой связи через BPMMxGraph.createConnectionHandler у нее id не совпадает с id в EdgeInstance.

    return Object.values(cells || []).find((c) => (c as MxCell)?.getValue && (c as MxCell).getValue()?.id === cellId);
}

function* handleDecompositionIconClick({ payload }: TObjectDecompositionIconClicked) {
    const { graphId, objectDefinitionId, edgeDefinitionId } = payload;
    const graph = instancesBPMMxGraphMap.get(graphId);
    const { repositoryId, serverId } = graph.id;
    let objectDefinition: ObjectDefinitionImpl | undefined;
    let edgeDefinition: EdgeDefinitionNode | undefined;

    if (objectDefinitionId) {
        const objNodeId = { ...graphId, id: objectDefinitionId } as NodeId;
        objectDefinition = yield ObjectDefinitionsDAOService.getObjectDefinitionById(objNodeId);
    }

    if (edgeDefinitionId) {
        const edgeNodeId = { ...graphId, id: edgeDefinitionId } as NodeId;
        edgeDefinition = yield EdgeDefinitionDAOService.getEdgeDefinition(edgeNodeId);
    }

    const modelAssignments = objectDefinition?.modelAssignments || edgeDefinition?.modelAssignments || [];

    if (modelAssignments.length > 1) {
        const props: TDecompositionsListDialogOwnProps = { objectDefinition, edgeDefinition, modelId: graphId };

        yield put(openDialog(DialogType.DECOMPOSITIONS_LIST_DIALOG, props));
    } else if (modelAssignments.length === 1) {
        const modelAssignment = modelAssignments[0];

        if (modelAssignment?.modelId && modelAssignment?.nodeType) {
            yield put(
                objectDecompositionOpen({
                    nodeId: { id: modelAssignment.modelId, repositoryId, serverId },
                    type: modelAssignment.nodeType as TreeItemType,
                }),
            );
        }
    }
}

export function* openDecomposition({ payload: { nodeId, type } }: TObjectDecompositionOpen) {
    yield openNodeByTreeItemNodeId(nodeId, type);
}

export function* objectDecompositionSagaInit() {
    yield takeEvery(OBJECT_DECOMPOSITION_ICON_CLICKED, handleDecompositionIconClick);
    yield takeEvery(OBJECT_DECOMPOSITION_OPEN, openDecomposition);
    yield takeEvery(OBJECT_DECOMPOSITION_DIALOG_INIT, handleDialogCreation);
    yield takeEvery(OBJECT_DECOMPOSITION_DIALOG_SUBMIT, handleDecompositionDialogSubmit);
}
