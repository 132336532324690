export class BrowserDaoService {

    public static downloadFile(data: Blob | string, dialogOptions: Electron.SaveDialogOptions) {
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.href = this.getFileUrl(data);
        downloadAnchorNode.download = dialogOptions.defaultPath ? dialogOptions.defaultPath : '';
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    private static getFileUrl (data: Blob | string) {

        if (typeof data === 'string') {
            return data;
        };

        if (data instanceof Blob) {
            return window.URL.createObjectURL(data);
        };

        throw new Error('bad url');
    }
    
}