import type { TEditorCopyWhiteboardAction, TEditorPasteWhiteboardAction } from '@/actions/editor.actions.types';
import type { BPMMxGraphModel } from '@/mxgraph/BPMMxGraphModel.class';
import type { WhiteboardGraph } from '@/mxgraph/WhiteboardGraph';
import type { MxCell, MxPoint } from 'MxGraph';
import type { TServerEntity } from '@/models/entities.types';
import type { ModelNode } from '@/serverapi/api';
import { EDITOR_COPY_WHITEBOARD, EDITOR_PASTE_WHITEBOARD } from '@/actionsTypes/editor.actionTypes';
import { put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { MxEventObject } from '@/mxgraph/mxgraph';
import { MxClipboard } from '@/mxgraph/mxgraph';
import { svgService } from '@/services/SvgServices';
import { ModelSelectors } from '@/selectors/model.selectors';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { setRepositoryIdWhereCopiedFrom } from '@/actions/editor.actions';
import { showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';
import { WhiteboardDragSource } from '@/mxgraph/util/WhiteboardDragSource';
import { SymbolType } from '@/models/Symbols';
import { MxEvent } from '@/mxgraph/mxgraph';
import { TWhereCopiedFrom } from '@/reducers/copy.reducer.types';
import { getWhereCopiedFrom } from '@/selectors/editor.selectors';
import { compareNodeIds } from '@/utils/nodeId.utils';

function* handleCopyWhiteboard({ payload: { graph } }: TEditorCopyWhiteboardAction) {
    const cells: MxCell[] = graph.getSelectionCells();
    const cellsId: string[] = cells.map((cell) => cell.id);
    const filteredCells: MxCell[] = cells.filter((cell: MxCell) => {
        if (cell.edge) {
            return cellsId.includes(cell.target.id) && cellsId.includes(cell.source.id);
        }

        return true;
    });

    if (!filteredCells.length) return;

    yield put(setRepositoryIdWhereCopiedFrom(graph.id.repositoryId, graph.id.serverId));

    MxClipboard.copy(graph, filteredCells);
    graph.selectionModel.fireEvent(new MxEventObject('CLIPBOARD_UPDATE'));

    const model: ModelNode | undefined = yield select(ModelSelectors.byId(graph.id));

    if (!model?.printable) return;

    const server: TServerEntity = yield select(ServerSelectors.server(graph.id.serverId));
    const svg = yield svgService().getSvg(graph.id, model, server, true);

    svgService().svgToClipboard(svg);
}

function pasteCopiedCells(graph: WhiteboardGraph, cells: MxCell[], pastePoint: MxPoint, evt: PointerEvent) {
    let gx;
    let gy;

    for (const cell of cells) {
        if (!cell.isEdge()) {
            if (gx === undefined || cell.geometry.x < gx) {
                gx = cell.geometry.x;
            }
            if (gy === undefined || cell.geometry.y < gy) {
                gy = cell.geometry.y;
            }
        }
    }

    const model: BPMMxGraphModel = graph.getModel();
    const dx = pastePoint.x - graph.view.translate.x - gx;
    const dy = pastePoint.y - graph.view.translate.y - gy;

    model.beginUpdate();

    try {
        const newCells = graph.moveCells(cells, dx, dy, true, undefined, evt);

        newCells.forEach((cell) => {
            if (cell.value.type === SymbolType.SHAPE) {
                cell.setValue({
                    ...cell.getValue(),
                    id: uuid(),
                });
            }
        });
    } finally {
        model.endUpdate();
    }
}

function* handlePasteWhiteboard({ payload: { graph } }: TEditorPasteWhiteboardAction) {
    const copiedCells: MxCell[] = MxClipboard.getCells();

    if (!copiedCells.length) {
        yield put(showNotificationByType(NotificationType.EMPTY_CLIPBOARD));

        return;
    }

    const whereCopiedFrom: TWhereCopiedFrom | null = yield select(getWhereCopiedFrom);

    if (!whereCopiedFrom || !compareNodeIds({ ...whereCopiedFrom, id: '' }, { ...graph.id, id: '' })) {
        yield put(showNotificationByType(NotificationType.CANNOT_PASTE_FROM_ANOTHER_REPOSITORY));

        return;
    }

    const dropHandler = (graph: WhiteboardGraph, evt: PointerEvent, target: MxCell, point: MxPoint) =>
        MxEvent.isMouseEvent(evt) &&
        MxEvent.isLeftMouseButton(evt) &&
        pasteCopiedCells?.(graph, copiedCells, point, evt);

    const dragSource = new WhiteboardDragSource(copiedCells, dropHandler, graph, undefined);

    dragSource.emulatePointerEvent();
}

export function* whiteboardSagaActions() {
    yield takeEvery(EDITOR_COPY_WHITEBOARD, handleCopyWhiteboard);
    yield takeEvery(EDITOR_PASTE_WHITEBOARD, handlePasteWhiteboard);
}
