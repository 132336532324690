import type {
    DiagramElement,
    EdgeDefinitionNode,
    EdgeInstance,
    EdgeWaypoint,
    ObjectInstance,
    Symbol,
} from '@/serverapi/api';
import type { BPMMxGraph } from '@/mxgraph/bpmgraph';
import type { MxCell, MxStencil } from 'MxGraph';
import { MxPoint } from 'MxGraph';
import { LayoutInstanceImpl, ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { EdgeInstanceImpl, ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';
import { v4 as uuid } from 'uuid';
import { symbolService } from '@/services/SymbolsService';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { MxStencilRegistry } from '@/mxgraph/mxgraph';
import { MxUtils } from '@/mxgraph/mxgraph';
import { MxConstants } from '@/mxgraph/mxgraph';
import { DefaultGraph } from '@/mxgraph/DefaultGraph';
import { MxEventObject } from '@/mxgraph/mxgraph';
import { objectDefinitionService } from '@/services/ObjectDefinitionService';
import { EdgeDefinitionDAOService } from '@/services/dao/EdgeDefinitionDAOService';
import { nodeService } from '@/services/NodeService';
import { CustomMxEvent } from './editor.saga.constants';
import { SymbolType } from '@/models/Symbols';
import { cloneDeep } from 'lodash';
import { psdCellType } from '@/mxgraph/psdDiagram/psdTable';
import { sortParentElementsFirst } from '@/mxgraph/ComplexSymbols/utils';

type TInsertSymbolToGraph = {
    target?: MxCell;
    point: MxPoint;
    symbol: Symbol;
    graph: BPMMxGraph;
    objectDefinitions?: ObjectDefinitionImpl[];
    serverUrl?: string;
};

export function insertSymbolToGraph({
    target,
    point,
    symbol,
    graph,
    objectDefinitions,
    serverUrl,
}: TInsertSymbolToGraph) {
    if (!graph || !graph.enabled || !graph.isCellsEditable()) {
        return;
    }

    try {
        let parent = target || graph.getDefaultParent();

        symbolService().applyStylesToGraph(graph, [symbol]);
        graph.getModel().beginUpdate();

        const objectDefinition = objectDefinitions?.[0];
        const id = uuid();
        const objectDef: ObjectDefinitionImpl | undefined = objectDefinition;
        let symbolVertex;

        const complesSymbolInstance = graph.complexSymbolManager.createComplexSymbol(symbol, objectDefinition, {
            x: point.x,
            y: point.y,
            parent: parent?.getId() || '1',
        });

        if (complesSymbolInstance) {
            return complesSymbolInstance.getRootCell();
        }

        if (symbol.id === PictureSymbolConstants.PICTURE_SYMBOL_ID) {
            // TODO переместить в общий метод/сервис
            // срабатывает если перетащили на холст символ - картинку, не нашел как это сейчас можно сделать
            symbolVertex = graph.insertVertex(
                parent,
                id,
                symbol.name,
                point.x,
                point.y,
                symbol.width! / 2,
                symbol.height! / 2,
                `${symbol.id};${PictureSymbolConstants.SHAPE_IMAGE};image=${symbolService().prepareImageLinkToShow(
                    symbol.graphical,
                    serverUrl || '',
                )};`,
            );
            symbolVertex.edge = false;
        } else {
            const stencil: MxStencil = MxStencilRegistry.getStencil(symbol.id);
            const width = symbol.width ? symbol.width : stencil.w0;
            const height = symbol.height ? symbol.height : stencil.h0;

            if (graph.modelType && graph.modelType.id === 'psdChart') {
                const targetCell: MxCell = graph.getCellAt(point.x, point.y, parent);

                parent = targetCell || parent;
            }

            let symbolStyle: string = '';
            let objectInstanceImpl: ObjectInstanceImpl;

            objectInstanceImpl = new ObjectInstanceImpl({
                type: 'object',
                id,
                symbolId: symbol.id,
                objectDefinitionId: objectDef?.nodeId.id,
                showLabel: symbol.showLabel,
            });

            symbolStyle = symbol.style ? symbol.style : '';
            symbolStyle = MxUtils.setStyle(symbolStyle, MxConstants.STYLE_NOLABEL, Number(!symbol.showLabel));
            symbolStyle = MxUtils.setStyle(symbolStyle, MxConstants.STYLE_EDITABLE, Number(symbol.showLabel));

            const additionalStyle =
                symbol.style && symbol.style.indexOf('silaText=1') >= 0 ? ';html=1;' : ';html=1;whiteSpace=wrap;';

            if (graph instanceof DefaultGraph) {
                symbolVertex = (graph as DefaultGraph).insertVertex(
                    parent,
                    id,
                    objectInstanceImpl,
                    point.x,
                    point.y,
                    width,
                    height,
                    `${symbol.id};${symbolStyle}${additionalStyle}`,
                    undefined,
                    symbol.labelStyle,
                    symbol.labelWidth,
                    symbol.labelHeight,
                    symbol.labelXOffset,
                    symbol.labelYOffset,
                );
            } else {
                symbolVertex = graph.insertVertex(
                    parent,
                    id,
                    symbol.name,
                    point.x,
                    point.y,
                    width,
                    height,
                    `${symbol.id};${symbol.style}${additionalStyle}`,
                );
            }
        }

        return symbolVertex;
    } finally {
        graph.getModel().endUpdate();
    }
}

export function drawObject({
    target,
    point,
    symbol,
    graph,
    objectDefinitions,
    serverUrl,
}: TInsertSymbolToGraph): MxCell | undefined {
    const cell = insertSymbolToGraph({
        target,
        point,
        symbol,
        graph,
        objectDefinitions,
        serverUrl,
    });

    if (!cell) {
        return;
    }

    graph.getModel().fireEvent(new MxEventObject(CustomMxEvent.GRAPH_SYMBOL_ADDED, 'cellsIds', [cell?.getId()]));

    return cell;
}

/**
 *
 * Создает все возможные связи между sourceCells и targetCells в соответствии с edgeDefinition
 *
 * @param graph
 * @param sourceCells исходящие ячейки
 * @param targetCells входящие ячейки
 * @param edgeDefinition определение связи, по которому создаются связи
 * @param unique флаг, отвечающий за уникальность созданных связей
 *
 */
export function createEdges(
    graph: BPMMxGraph,
    sourceCells: MxCell[],
    targetCells: MxCell[],
    edgeDefinition: EdgeDefinitionNode,
    unique?: boolean,
): MxCell[] {
    const createdCells: MxCell[] = [];
    const edgeInstances: MxCell[] = nodeService().findModelCellsByDefinitionId(edgeDefinition.nodeId.id, graph);

    const createEdge = (source: MxCell, target: MxCell) => {
        const edgeType = graph.modelType?.edgeTypes.find((el) => el.id === edgeDefinition.edgeTypeId);
        const style = edgeType?.edgeStyle || '';
        const edgeInstance = new EdgeInstanceImpl({
            id: uuid(),
            edgeDefinitionId: edgeDefinition.nodeId.id,
            edgeType,
            edgeTypeId: edgeDefinition.edgeTypeId,
            style,
            source,
            target,
            name: '',
            invisible: false,
        });

        const edge: MxCell = graph.createCustomEdge(edgeInstance, style);

        edge.setEdge(true);
        edge.setVisible(true);

        return edge;
    };

    sourceCells.forEach((sourceCell) => {
        targetCells.forEach((targetCell) => {
            if (
                unique &&
                edgeInstances.find((el) => el.source.id === sourceCell.id && el.target.id === targetCell.id)
            ) {
                return;
            }

            const edgeCell = graph.addEdge(
                createEdge(sourceCell, targetCell),
                graph.getDefaultParent(),
                sourceCell,
                targetCell,
            );

            createdCells.push(edgeCell);
        });
    });

    return createdCells;
}

/**
 *
 * Создает связи между заданной ячейкой и остальными ячейками графа в соответствии с имеющимися edge definitions
 *
 * @param graph
 * @param cell
 *
 */
export async function drawEdges(graph: BPMMxGraph, cell?: MxCell): Promise<MxCell[]> {
    if (!graph.modelType?.autoCreateEdge || !cell) {
        return [];
    }

    const cellValue = cell.getValue();
    const addedCells: MxCell[] = [];

    const objectDefinitions = objectDefinitionService().findAllObjectsInGraph(graph.id);
    const objectDefinitionsIds = objectDefinitions.map(({ nodeId }) => nodeId.id);

    const sourceCellEdgeDefinitions: EdgeDefinitionNode[] =
        await EdgeDefinitionDAOService.searchExistingEdgeDefinitions(
            graph.id,
            [cellValue.objectDefinitionId],
            objectDefinitionsIds,
        );
    const targetCellEdgeDefinitions: EdgeDefinitionNode[] =
        await EdgeDefinitionDAOService.searchExistingEdgeDefinitions(graph.id, objectDefinitionsIds, [
            cellValue.objectDefinitionId,
        ]);

    sourceCellEdgeDefinitions
        .filter(({ edgeTypeId }) => graph.modelType?.edgeTypes.some((el) => el.id === edgeTypeId))
        .forEach((edgeDefinition) => {
            const cells = edgeDefinition.targetObjectDefinitionId
                ? nodeService().findModelCellsByDefinitionId(edgeDefinition.targetObjectDefinitionId, graph)
                : [];
            const mainCells = cells.filter((cell) => graph.isMainCell(cell));
            const addeEdges: MxCell[] = createEdges(graph, [cell], mainCells, edgeDefinition, true);

            addedCells.push(...addeEdges);
        });

    targetCellEdgeDefinitions
        .filter(({ edgeTypeId }) => graph.modelType?.edgeTypes.some((el) => el.id === edgeTypeId))
        .forEach((edgeDefinition) => {
            const cells = edgeDefinition.sourceObjectDefinitionId
                ? nodeService().findModelCellsByDefinitionId(edgeDefinition.sourceObjectDefinitionId, graph)
                : [];
            const mainCells = cells.filter((cell) => graph.isMainCell(cell));
            const addeEdges: MxCell[] = createEdges(graph, mainCells, [cell], edgeDefinition, true);

            addedCells.push(...addeEdges);
        });

    return addedCells;
}

/**
 *
 * Удаляет все существующие связи указанной ячейки (исходящие, входящие и рекурсивные)
 *
 * @param graph
 * @param cell
 *
 */
export function deleteAllCellEdgesWithDefinition(graph: BPMMxGraph, cell: MxCell) {
    const cellEdges = (graph.model.getEdges(cell, true, true, true) || []).filter(
        (c) => (c?.getValue() as EdgeInstance).edgeDefinitionId,
    );

    graph.removeCells(cellEdges);
}

/**
 *
 * Проверяет возможность вставки копии элемента
 *
 * @param element
 * @param graph
 * @param allowedSymbolsIds
 *
 */
export function isElementAllowedToPaste(
    element: DiagramElement,
    graph: BPMMxGraph,
    allowedSymbolsIds: string[],
): boolean {
    const modelTypeId = graph.modelType?.id;

    const isBpmnSymbol =
        modelTypeId === 'bpmn2' &&
        (element as LayoutInstanceImpl).psdCellMetaInfo &&
        ((element as LayoutInstanceImpl).psdCellMetaInfo?.type === psdCellType.BPMN_POOL ||
            (element as LayoutInstanceImpl).psdCellMetaInfo?.type === psdCellType.BPMN_LANE);

    if (element.type !== 'edge' && !isBpmnSymbol) {
        // todo: add 'SHAPE' symbol Id for MindMap diagram
        const isPictureSymbol = (element as ObjectInstance).symbolId === PictureSymbolConstants.PICTURE_SYMBOL_ID;
        const isAllowedSymbol =
            allowedSymbolsIds.includes((element as ObjectInstance).symbolId) || graph?.modelType?.allowAnyObject;
        const isShape = element.type === SymbolType.SHAPE;

        if (!isPictureSymbol && !isAllowedSymbol && !isShape) {
            return false;
        }
    }

    return true;
}

/**
 *
 * Возвращает смещение для группы элементов
 *
 * @param elements
 *
 */
export function getFramePosition(elements: DiagramElement[]): MxPoint {
    let gx;
    let gy;

    for (const element of sortParentElementsFirst(elements)) {
        const copiedWithParent = !!elements.find(({ id }) => element.parent === id);

        if (element.type !== 'edge' && element.x && element.y && !copiedWithParent) {
            if (gx === undefined || element.x < gx) {
                gx = element.x;
            }

            if (gy === undefined || element.y < gy) {
                gy = element.y;
            }
        }
    }

    return new MxPoint(gx, gy);
}

/**
 *
 * Возвращает позиционированные на полотне элементы с учетом смещения группы элементов
 *
 * @param elements
 * @param position
 * @param graph
 *
 */
export const getPositionedElements = (
    elements: DiagramElement[],
    position: MxPoint,
    graph: BPMMxGraph,
): DiagramElement[] => {
    const frameShift: MxPoint = getFramePosition(elements);
    const xShift = position.x - frameShift.x;
    const yShift = position.y - frameShift.y;

    return elements.map((el) => {
        if (el.type !== 'edge') {
            // Элементы, скопированные вместе с контейнером не меняют положения
            const copiedWithParent = !!elements.find(({ id }) => el.parent === id);
            const x = (el.x || 0) + (copiedWithParent ? 0 : xShift);
            const y = (el.y || 0) + (copiedWithParent ? 0 : yShift);

            return { ...el, x, y };
        }

        if (el.type === 'edge' && (el as EdgeInstance).waypoints) {
            const hasDefaultParent = graph.getDefaultParent().id === el.parent;
            const waypoints: EdgeWaypoint[] | undefined = (el as EdgeInstance).waypoints?.map(
                (point) =>
                    new MxPoint(
                        (point.x || 0) + (!hasDefaultParent ? 0 : xShift),
                        (point.y || 0) + (!hasDefaultParent ? 0 : yShift),
                    ),
            );

            return {
                ...el,
                waypoints,
            };
        }

        return el;
    });
};

/**
 *
 * Создает копии элементов
 *
 * @param elements
 * @param graph
 *
 */
export const getElementsWithCopiedData = (elements: DiagramElement[], graph: BPMMxGraph): DiagramElement[] => {
    const oldToNewIdMap: Record<string, string> = {};
    const copiedElements: DiagramElement[] = [];
    const defaultParent = graph.getDefaultParent();

    sortParentElementsFirst(elements).forEach((el) => {
        const newId = uuid();
        oldToNewIdMap[el.id] = newId;

        if (el.type !== 'edge') {
            const copiedWithParent = !!elements.find(({ id }) => el.parent === id);

            copiedElements.push({
                ...el,
                id: newId,
                parent: copiedWithParent && el.parent ? oldToNewIdMap[el.parent] : defaultParent.id,
                attributes: cloneDeep(el.attributes),
                attributeStyles: cloneDeep(el.attributeStyles),
            });
        }
    });

    elements.forEach((el) => {
        const newId = uuid();

        if (el.type === 'edge') {
            const edge = el as EdgeInstance;

            copiedElements.push({
                ...el,
                id: newId,
                source: edge.source ? oldToNewIdMap[edge.source] : edge.source,
                target: edge.target ? oldToNewIdMap[edge.target] : edge.target,
                attributes: cloneDeep(edge.attributes),
                attributeStyles: cloneDeep(edge.attributeStyles),
            } as EdgeInstance);
        }
    });

    return copiedElements;
};
