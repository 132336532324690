import React, { useCallback, useLayoutEffect, useMemo, useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { ContextMenu } from '../../modules/ContextMenu/components/ContextMenu.component';
import type { MenuProps } from 'antd';
import { getMenuItem } from '../../utils/antdMenuItem.utils';
import {
    CLASS_NAME,
    HEADER_CONTAINER_ID,
    MAIN_MENU_CONTENT_ID,
    TBPMPopupMenuProps,
    TContextTreeData,
    TPlacement,
    WORKSPACE_CONTAINER_ID,
} from './BPMPopupMenu.types';
import MxGraphSideEffects from '../sideEffects';

export const BPMPopupMenu = ({ items, width, onClick, visible, graph, triggerX, pageY }: TBPMPopupMenuProps) => {
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        // В ant нельзя получить ссылку на DOM-элемент Dropdown через ref, поэтому использован querySelector
        const dropdown = document.querySelector(`.${CLASS_NAME}`);
        if (dropdown) setHeight(dropdown.clientHeight);
    }, []);

    useEffect(() => {
        MxGraphSideEffects.closeNavigatorContextMenu();
    }, []);

    const placement: TPlacement = useMemo(() => {
        const defaultPlacement = 'bottomLeft';
        const { clientWidth, clientHeight } = graph.container;
        let [placementY, placementX] = defaultPlacement.split(/(?=[A-Z])/);

        const mainMenuContent = document.getElementById(MAIN_MENU_CONTENT_ID);
        const headerContainer = document.getElementById(HEADER_CONTAINER_ID);
        const workSpace = document.getElementById(WORKSPACE_CONTAINER_ID);
        // если getElementById вдруг ничего не вернет, то пусть поправка будет 140
        let offset: number = 140;
        if (mainMenuContent && headerContainer && workSpace) {
            offset =
                mainMenuContent?.clientHeight +
                headerContainer?.clientHeight +
                (workSpace?.clientHeight - clientHeight);
        }
        if (pageY - offset + height >= clientHeight) {
            placementY = 'top';
        }
        if (triggerX + width >= clientWidth) {
            placementX = 'Right';
        }

        return [placementY, placementX].join('') as TPlacement;
    }, [height]);

    const renderItem = useCallback((item: TContextTreeData) => {
        const {dataTest} = item;
        if (item.children && item.children.length > 0) {
            const subItems: MenuProps['items'] = [];
            item.children.forEach((children) => subItems.push(renderItem(children)));

            return getMenuItem(<span data-test={dataTest}>{item.label}</span>, item.key, !item.enabled, undefined, subItems);
        }

        return getMenuItem(
            <div data-test={dataTest}>
                <Tooltip title={item.addTooltip ? item.addTooltip() : ''}>
                    {item.image && <img src={item.image} style={{ width: '15px', height: '10px' }} />}
                    {item.label}
                </Tooltip>
            </div>,
            item.key,
            !item.enabled,
        );
    }, []);

    const renderContextMenu = useCallback(() => {
        const menuItems: MenuProps['items'] = [];
        items.forEach((item) => {
            if (item.isDivider) {
                menuItems.push({ type: 'divider' });
            } else {
                menuItems.push(renderItem(item));
            }
        });

        return menuItems;
    }, []);

    return (
        <ContextMenu
            overlay={renderContextMenu()}
            visible={visible}
            placement={placement}
            overlayClassName={CLASS_NAME}
            onClick={onClick}
        >
            <div />
        </ContextMenu>
    );
};
