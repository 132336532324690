import { defineMessages } from 'react-intl';

export default defineMessages({
    profileName: {
        id: 'PresetProfileList.profileName',
        defaultMessage: 'Название профиля',
    },
    description: {
        id: 'PresetProfileList.description',
        defaultMessage: 'Описание',
    },
    addProfile: {
        id: 'PresetProfileList.addProfile',
        defaultMessage: 'Добавить профиль',
    },
    serverProfile: {
        id: 'PresetProfileList.serverProfile',
        defaultMessage: 'Профиль сервера',
    },
    serverProfileFinded: {
        id: 'PresetProfileList.serverProfileFinded',
        defaultMessage: 'Данный профиль методологии связан с профилем сервера',
    },
    delete: {
        id: 'PresetProfileList.delete',
        defaultMessage: 'Удалить профиль {profile}?',
    }
});
