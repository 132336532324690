import { Locale } from '../modules/Header/components/Header/header.types';
import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { INITIAL_FOLDER_TYPE_SERVER_STATE } from '../reducers/folderType.reducer';
import { FolderType, NodeId, ObjectType } from '../serverapi/api';
import { TreeSelectors } from './tree.selectors';
import { TreeItemType } from '../modules/Tree/models/tree';
import { getCreatableObjectDefinitionTypes, getIsTreeItemAllowedInFolder } from '../services/bll/FolderTypeBLLService';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { ObjectTypeSelectors } from './objectType.selectors';
import { ObjectDefinitionSelectors } from './objectDefinition.selectors';
import { uniqBy } from 'lodash';
import { LocalesService } from '../../src/services/LocalesService';
import messages from '../modules/FolderDialog/FolderDialog.messages';
import { TTreeEntityState } from '@/models/tree.types';

export const folderTypeStateSelector = (state: TRootState) => state.folderType;

export namespace FolderTypeSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(folderTypeStateSelector, (state) => {
            const s = state.byServerId[serverId];
            if (s) {
                return { ...s };
            }

            return { ...INITIAL_FOLDER_TYPE_SERVER_STATE };
        });

    export const byPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), (state) => state.byPresetId[compositeId.presetId]);

    export const listByPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(
            byPresetId({
                serverId: compositeId.serverId,
                presetId: compositeId.presetId,
            }),
            (state) => Object.values(state?.byId || {}) as FolderType[],
        );
    export const listWithDefaultFolderTypeByPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector<
            TRootState,
            {
                byId: {
                    [id: string]: FolderType;
                };
            },
            FolderType[]
        >(
            byPresetId({
                serverId: compositeId.serverId,
                presetId: compositeId.presetId,
            }),
            (state) => {
                if (!state?.byId) return [];
                const foldersTypes: FolderType[] = Object.values(state.byId);
                const locale: Locale = LocalesService.getLocale();
                const intl = LocalesService.useIntl();

                return foldersTypes.find((type) => type.id === 'default')
                    ? foldersTypes
                    : [
                          ...foldersTypes,
                          {
                              id: 'default',
                              presetId: compositeId.presetId,
                              multilingualDescription: '',
                              multilingualName: { [locale]: intl.formatMessage(messages.defaultFolderType) },
                          } as FolderType,
                      ];
            },
        );

    export const recordByPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(
            byPresetId({
                serverId: compositeId.serverId,
                presetId: compositeId.presetId,
            }),
            (state) => state?.byId || {},
        );

    export const byId = (compositeId: { folderTypeId: string; presetId: string; serverId: string }) =>
        createSelector(byPresetId({ serverId: compositeId.serverId, presetId: compositeId.presetId }), (state) => {
            return state?.byId[compositeId.folderTypeId];
        });

    export const byNodeId = (compositeId: { nodeId: NodeId; presetId: string }) =>
        createSelector(
            (state: TRootState) => TreeSelectors.itemById(compositeId.nodeId)(state),
            (state: TRootState) =>
                byPresetId({
                    serverId: compositeId.nodeId.serverId,
                    presetId: compositeId.presetId,
                })(state),
            (node: TTreeEntityState, presetFolders) => presetFolders?.byId[node?.folderType || ''],
        );

    export const getParentNodeFolderType = (nodeId: NodeId | undefined) =>
        createSelector(
            (state: TRootState) => {
                const treeNode: TTreeEntityState | undefined = TreeSelectors.itemById(nodeId)(state);
                if (!treeNode) return;
                const parentNode: TTreeEntityState | undefined = TreeSelectors.itemById(treeNode.parentNodeId)(state);
                const presetId: string = TreeSelectors.presetById(nodeId)(state);

                return parentNode?.type === TreeItemType.Folder
                    ? FolderTypeSelectors.byNodeId({
                          nodeId: parentNode?.nodeId,
                          presetId,
                      })(state)
                    : undefined;
            },
            (parentFolderType) => parentFolderType,
        );

    export const getIsTreeItemTypeAllowed = (nodeId: NodeId | undefined) =>
        createSelector(
            TreeSelectors.itemById(nodeId),
            FolderTypeSelectors.getParentNodeFolderType(nodeId),
            (node: TTreeEntityState, parentFolderType) => {
                if (parentFolderType) {
                    return getIsTreeItemAllowedInFolder(node, parentFolderType);
                }

                return true;
            },
        );

    export const byObjectDefinitionId = (objectDefinitionId: string, graphId: NodeId) =>
        createSelector(
            (state: TRootState) => {
                const { serverId, repositoryId } = graphId;
                const objectDef: ObjectDefinitionImpl | undefined = ObjectDefinitionSelectors.byId({
                    id: objectDefinitionId,
                    repositoryId,
                    serverId,
                })(state);
                const parentFolderTypeNodeId: NodeId | undefined = objectDef?.parentNodeId;

                if (!parentFolderTypeNodeId) return undefined;

                const presetId: string = TreeSelectors.presetById(graphId)(state);
                const parentFolderType: FolderType | undefined = FolderTypeSelectors.byNodeId({
                    nodeId: parentFolderTypeNodeId,
                    presetId,
                })(state);

                return parentFolderType;
            },
            (parentFolderType: FolderType | undefined) => parentFolderType,
        );

    export const uniqObjectTypes = (objectDefinitionIds: string[], graphId: NodeId) => (state: TRootState) => {
        const objectsTypes = objectDefinitionIds
            .map((id) => ObjectTypeSelectors.byObjectDefinitionId(id, graphId)(state))
            .filter((t) => t);

        return uniqBy(objectsTypes, 'id');
    };

    export const ifLeastOneObjDefNotAllowed = (objectDefinitionIds: string[], graphId: NodeId) =>
        createSelector(
            uniqObjectTypes(objectDefinitionIds, graphId),
            FolderTypeSelectors.byObjectDefinitionId(objectDefinitionIds[0], graphId),
            (objectTypes: ObjectType[], parentFolderType: FolderType | undefined) => {
                const creatableObjectDefinitionTypes: ObjectType[] = getCreatableObjectDefinitionTypes(
                    objectTypes,
                    parentFolderType,
                );
                const ifLeastOneNotAllowed: boolean = creatableObjectDefinitionTypes.length < objectTypes.length;

                return ifLeastOneNotAllowed;
            },
        );
}
