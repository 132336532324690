import React, { useState } from 'react';
import { Form, Modal, Select } from 'antd';
import { TreeNode } from '../../../models/tree.types';
import { TPreset } from '../../../models/preset.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { useDispatch } from 'react-redux';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { shouldCallModalSubmitHandler } from '../../../services/utils/ModalHelper';
import { createModelType } from '../../../actions/presetSettings/presetSettingsModelType.actions';
import { createKanbanModelType } from '../../../actions/presetSettings/presetSettingsKanbanModelType.actions';
import { GeneralModelTypeDiscriminator } from '../../../models/ModelTypes';
import { GeneralModelTypeDiscriminatorEnum } from '../../../serverapi/api';
import { useIntl } from 'react-intl';
import messages from './SelectModelTypeDialog.messages';

type TSelectModelTypeDialog = {
    serverNode: TreeNode;
    preset: TPreset;
};

export const SelectModelTypeDialog = ({ serverNode, preset }: TSelectModelTypeDialog) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [modelType, setModelType] = useState<GeneralModelTypeDiscriminatorEnum>(
        GeneralModelTypeDiscriminator.MODEL_TYPE,
    );

    const handleCancelClick = () => {
        dispatch(closeDialog(DialogType.SELECT_MODEL_TYPE));
    };

    const handleOkClick = (): void => {
        dispatch(closeDialog(DialogType.SELECT_MODEL_TYPE));

        if (modelType === GeneralModelTypeDiscriminator.MODEL_TYPE) {
            dispatch(
                createModelType({
                    serverNode,
                    preset,
                }),
            );
        }

        if (modelType === GeneralModelTypeDiscriminator.KANBAN) {
            dispatch(
                createKanbanModelType({
                    serverNodeId: serverNode.nodeId,
                    presetId: preset.id,
                }),
            );
        }
    };

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    handleOkClick();
                }
            }}
        >
            <Modal
                visible
                title={intl.formatMessage(messages.selectModelType)}
                cancelText={intl.formatMessage(messages.cancel)}
                okText={intl.formatMessage(messages.create)}
                onOk={handleOkClick}
                onCancel={handleCancelClick}
            >
                <Form style={{ display: 'flex', flexDirection: 'column' }}>
                    <Select
                        defaultValue={GeneralModelTypeDiscriminator.MODEL_TYPE}
                        onChange={(val) => setModelType(val)}
                    >
                        <Select.Option key="1" value={GeneralModelTypeDiscriminator.MODEL_TYPE}>
                            {intl.formatMessage(messages.defaultModel)}
                        </Select.Option>
                        <Select.Option key="2" value={GeneralModelTypeDiscriminator.KANBAN}>
                            {intl.formatMessage(messages.kanbanBoard)}
                        </Select.Option>
                    </Select>
                </Form>
            </Modal>
        </div>
    );
};
