import { Form, Input } from 'antd';
import theme from './TreeItemNameEditor.scss';
import React, { MouseEvent, ChangeEvent, FormEvent, SyntheticEvent, FC, useState, useEffect } from 'react';

type TTreeItemNameEditorProps = {
    name: string | undefined; // в некоторых случаях текст приходит пустой BPM-5608
    isEditing: boolean;
    suffix?: string;
    allowEmptyValue?: boolean;
    onActivateEditable?: () => void;
    onDeactivateEditable?: () => void;
    onChange?: (text: string) => void;
};

export const TreeItemNameEditor: FC<TTreeItemNameEditorProps> = (props) => {
    const { name, suffix, isEditing, allowEmptyValue, onChange, onActivateEditable, onDeactivateEditable } = props;
    const [hasError, setHasError] = useState<boolean>(false);
    const [isLocalEditing, setIsLocalEditing] = useState<boolean | null>(null);
    const [currentName, setCurrentName] = useState<string>(name || '');

    useEffect(() => {
        if (name !== undefined) setCurrentName(name);
    }, [name]);

    useEffect(() => {
        setIsLocalEditing(isEditing);
    }, [isEditing]);

    useEffect(() => {
        if (isLocalEditing) {
            if (onActivateEditable) onActivateEditable();
        } else if (isLocalEditing !== null && onDeactivateEditable) {
            onDeactivateEditable();
        }
    }, [isLocalEditing]);

    useEffect(() => {
        setCurrentName(name || '');
    }, [name]);

    const onSaveText = (e: MouseEvent | FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!hasError && onChange && name !== currentName) {
            onChange(currentName);
        }
        if (!hasError) setIsLocalEditing(false);
    };

    const onChangeText = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        const {
            target: { value },
        } = e;
        setCurrentName(value.trim().length ? value : value.trim());
        setHasError(value.trim().length === 0 && !allowEmptyValue);
    };

    const onBlur = (e: SyntheticEvent<HTMLInputElement> | SyntheticEvent<HTMLTextAreaElement>) => {
        onSaveText(e);
    };

    const titleText = isLocalEditing ? '' : (name || '').concat(suffix || '');

    return (
        <div className={theme.container} title={titleText}>
            {isLocalEditing ? (
                <Form.Item validateStatus={hasError ? 'error' : 'success'}>
                    <Input
                        size="small"
                        autoFocus
                        value={currentName}
                        onChange={onChangeText}
                        onPressEnter={onSaveText}
                        onBlur={onBlur}
                        type="text"
                    />
                </Form.Item>
            ) : (
                (name || '').concat(suffix || '')
            )}
        </div>
    );
};
