import { NodeId } from '../../../serverapi/api';
import { getNodeName } from '../../Navigator/navigatorUtils/navigatorTreeSearch.utils';
import { TTreeNodeWithLevel } from '../Tree.types';

export const getFoundNodeIds = (searchString: string, data: TTreeNodeWithLevel[]): NodeId[] => {
    return searchString
        ? data.filter((node) => isIncludeStr(getNodeName(node), searchString)).map((node) => node.nodeId)
        : [];
};

export const isIncludeStr = (mainSring: string, searchString: string): boolean => {
    return mainSring.toLowerCase().includes(searchString.toLowerCase());
};
