import type {
    AttributeType,
    EdgeType,
    ModelEdgeDefinition,
    ModelType,
    ObjectType,
    Symbol,
} from '../../../../../../../serverapi/api';
import { some } from 'lodash';
import { ComplexSymbolManager } from '../../../../../../../mxgraph/ComplexSymbols/ComplexSymbolsManager.class';

export const checkPoolsHaveLanes = (modelTypeSymbols: Symbol[]): boolean => {
    const symbolsHaveHPool = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === 'frame.type.pool.h',
    );
    const symbolsHaveVPool = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === 'frame.type.pool.v',
    );
    const symbolsHaveVLane = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === 'frame.type.swimlane.v',
    );
    const symbolsHaveHLane = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === 'frame.type.swimlane.h',
    );
    const symbolsHaveCrossPool = modelTypeSymbols.some(
        (symbol: Symbol) => ComplexSymbolManager.getSymbolType(symbol) === 'frame.type.cross',
    );
    if (symbolsHaveHPool && !symbolsHaveHLane) {
        return false;
    }
    if (symbolsHaveVPool && !symbolsHaveVLane) {
        return false;
    }
    if (symbolsHaveCrossPool && (!symbolsHaveVLane || !symbolsHaveHLane)) {
        return false;
    }

    return true;
};

export const checkIsDoubleEdgeDefinition = (
    modelTypeEdgeDefinitions: ModelEdgeDefinition[],
    addedEdgeDefinition: ModelEdgeDefinition,
): boolean => {
    const paramEdgeDefinition = {
        edgeType: addedEdgeDefinition.edgeType,
        modelTypeId: addedEdgeDefinition.modelTypeId,
        source: addedEdgeDefinition.source,
        destination: addedEdgeDefinition.destination,
        anySourceAllowed: addedEdgeDefinition.anySourceAllowed,
        anyTargetAllowed: addedEdgeDefinition.anyTargetAllowed,
    };

    return some(modelTypeEdgeDefinitions, paramEdgeDefinition);
};

export const getObjectAvailableAttributeTypes = (
    availableAttributes: AttributeType[],
    objectTypeId: string,
    modelType: ModelType,
) => {
    const objectType: ObjectType | undefined = modelType.objectTypes.find((el) => el.id === objectTypeId);
    const definitionAttributes: AttributeType[] = availableAttributes.filter((el) =>
        objectType?.nodeAttributes.some((nodeAttr) => nodeAttr.id === el.id),
    );
    const instanceAttributes: AttributeType[] = availableAttributes.filter((el) =>
        objectType?.diagramElementAttributes.some((instAttr) => instAttr.id === el.id),
    );

    return { definitionAttributes, instanceAttributes };
};

export const getEdgeAvailableAttributeTypes = (availableAttributes: AttributeType[], edgeType: EdgeType) => {
    const definitionAttributes: AttributeType[] = availableAttributes.filter((el) =>
        edgeType?.attributeTypes.some((attrTypes) => attrTypes.id === el.id),
    );
    const instanceAttributes: AttributeType[] = availableAttributes.filter((el) =>
        edgeType?.diagramElementAttributes.some((instAttr) => instAttr.id === el.id),
    );

    return { definitionAttributes, instanceAttributes };
};
