import * as React from 'react';
import { NavigatorPanel } from '../NavigatorPanel/NavigatorPanel.component';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import messages from '../../messages/Navigator.messages';
import theme from './NavigatorStructure.scss';
import navigatorPanelTheme from '../NavigatorPanel/NavigatorPanel.scss';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import iconTreeAddServer from '../../../../resources/icons/ic-tree-add-server.svg';
import iconAdd from '../../../../resources/icons/ic-add.svg';
import { Button } from 'antd';
import { TreeItemType } from '../../../Tree/models/tree';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TNavigatorStructureActionsProps, TNavigatorStructureReduxProps } from './NavigatorStructure.types';
import { NAVIGATOR_STRUCTURE } from '../../../../utils/consts';
import { Tree } from '../../../Tree/components/Tree/Tree.component';
import { TreeFiltersDropdown } from './TreeFiltersDropdown/TreeFiltersDropdown.component';
import { TNavigatorTab } from '../../../../reducers/navigator.reducer.types';
import { TreeSearch } from './TreeSearch/treeSearch.component';

type TNavigatorStructureProps = WrappedComponentProps & TNavigatorStructureReduxProps & TNavigatorStructureActionsProps;

class NavigatorStructure extends React.Component<TNavigatorStructureProps> {
    renderAsideControls() {
        return <Icon onClick={this.props.onServerAdd} className={theme.serverAdd} spriteSymbol={iconTreeAddServer} />;
    }

    handlerFilterChange = (event: CheckboxChangeEvent) => {
        const { onFilterChange } = this.props;
        const {
            target: { checked, value },
        } = event;
        if (onFilterChange) {
            switch (value) {
                case TreeItemType.Model: {
                    onFilterChange(checked, [
                        TreeItemType.Model,
                        TreeItemType.Wiki,
                        TreeItemType.Matrix,
                        TreeItemType.SimulationModeling,
                        TreeItemType.Spreadsheet,
                        TreeItemType.Kanban,
                    ]);
                    break;
                }
                case TreeItemType.ObjectDefinition: {
                    onFilterChange(checked, [TreeItemType.ObjectDefinition]);
                    break;
                }
                default: {
                    console.warn('Unsupported filter');
                }
            }
        }
    };

    render() {
        const { intl, data, isEmptyTree, selectedNodes } = this.props;

        return (
            <NavigatorPanel
                type={TNavigatorTab.Tree}
                className={navigatorPanelTheme.container_tree}
                aside={!isEmptyTree ? undefined : this.renderAsideControls()}
                titleProps={{
                    title: intl.formatMessage(messages.structure),
                }}
                customButtons={
                    <>
                        <TreeSearch />
                        <TreeFiltersDropdown />
                    </>
                }
            >
                <Tree isDndEnabled selectedNodes={selectedNodes} data={data} treeName={NAVIGATOR_STRUCTURE} />
                {isEmptyTree && (
                    <Button
                        className={theme.serverAddButton}
                        type="primary"
                        size="large"
                        onClick={this.props.onServerAdd}
                    >
                        <div className={theme.serverAddButtonInner}>
                            <Icon className={theme.serverAddButtonIcon} spriteSymbol={iconAdd} />
                            <FormattedMessage {...messages.serverAdd} />
                        </div>
                    </Button>
                )}
            </NavigatorPanel>
        );
    }
}

const NavigatorStructureWithIntl = injectIntl(NavigatorStructure);

export { NavigatorStructureWithIntl as NavigatorStructure };
