import React, { Component } from 'react';
import { GroupDTO } from '../../../../serverapi/api';
import { Table } from 'antd';
import admTheme from '../../style/AdminTools.scss';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import groupMessages from '../../messages/groups.messages';
import { TWithWindowResizeProps } from '../../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import { difference } from 'lodash';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { toggleArrayItem } from '../../../../utils/toggleArrayItem';
import { TGroupListProps } from './GroupList.components.types';

type TGroupListActionProps = {
    onGroupClicked: (id: number) => void;
    onCheckbox: (added: number[] | undefined, removed: number[] | undefined) => void;
};

type TGroupListFullProps = TGroupListProps & TGroupListActionProps & WrappedComponentProps & TWithWindowResizeProps;

type TGroupListState = {
    selectedRowKeys: number[] | undefined;
    added?: number[] | undefined;
    removed?: number[] | undefined;
};

type TGroupListTableRecord = {
    key: number;
    groupName: string;
};

class GroupList extends Component<TGroupListFullProps, TGroupListState> {
    state: TGroupListState = {
        selectedRowKeys: this.props.selected,
    };

    getColumns = () => {
        const { intl } = this.props;

        return [
            {
                title: intl.formatMessage(groupMessages.groupName),
                dataIndex: 'groupName',
                key: 'groupName',
                width: 200,
                sorter: (a: TGroupListTableRecord, b: TGroupListTableRecord) => sortByAlpha(a.groupName, b.groupName),
            },
        ];
    };

    getData = (srcData: GroupDTO[], pattern: string): TGroupListTableRecord[] => {
        const data: GroupDTO[] =
            pattern === ''
                ? srcData
                : srcData.filter(
                    (item) =>
                        [item.groupName, item.id]
                            .filter((s) => s)
                            .map((s) => `${s}`.toLowerCase())
                            .filter((s) => s.includes(pattern)).length > 0,
                );

        return data.map((item) => ({
            key: item.id,
            groupName: item.groupName || '',
        }));
    };

    onSelectChange = (selectedRowKeys: number[]) => {
        const { allGroups, search } = this.props;
        let newSelectedRowKeys: number[] = selectedRowKeys;
        const groupsData = this.getData(allGroups, search);
        if (this.props.search !== '' && this.state.selectedRowKeys) {
            newSelectedRowKeys.push(...this.state.selectedRowKeys.filter(
                (id) => !groupsData.some((user) => user.key === id),
           ))
        }
        const removed = difference(this.props.selected, newSelectedRowKeys);
        const added = this.props.selected ? difference(newSelectedRowKeys, this.props.selected) : newSelectedRowKeys;
        this.setState({ selectedRowKeys: newSelectedRowKeys });
        this.props.onCheckbox(added, removed);
    };

    onRow = (row: TGroupListTableRecord) => {
        const newState: number[] = toggleArrayItem<number>(row.key, this.state.selectedRowKeys);

        return {
            onClick: () => {
                // todo (BPM-3451):
                // поправить функцию в GroupsAndUsersPrivilegeEditingTab.components.tsx (сейчас она меняет стейт)
                // и раскомментировать эту строку:
                // this.props.onGroupClicked(row.key);
                this.onSelectChange(newState);
            },
            'data-test': row.groupName,
        };
    };

    addRowClassName = (record: TGroupListTableRecord) =>
        record.key === this.props.currentClicked ? admTheme.selected : '';

    render() {
        const { allGroups, search } = this.props;
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange, // todo (BPM-3451): this.props.onGroupClicked должен срабатывать и тут
        };
        const maxTableHeight = this.props.wHeight - 329;

        return (
            <Table
                className={admTheme.dataTable}
                rowSelection={rowSelection}
                columns={this.getColumns()}
                dataSource={this.getData(allGroups, search)}
                pagination={false}
                onRow={this.onRow}
                rowClassName={this.addRowClassName}
                scroll={{ y: maxTableHeight }}
            />
        );
    }
}

export default injectIntl(GroupList);
