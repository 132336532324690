import { getSequenceNoteTemplate } from './sequenceSymbolDiagramElements';
import { SequenceShapeSymbol } from './SequenceShapeSymbol';
import { MxCell } from 'MxGraph';
import { SymbolTypeId } from '../ComplexSymbol.constants';

export class UMLNote extends SequenceShapeSymbol {
    complexSymbolTypeId: SymbolTypeId.UML_NOTE;
    template = getSequenceNoteTemplate();

    addToGraph() {
        return !this.rootCellValue.metaInfo ? this.insert() : this.restore();
    }

    insert(): MxCell {
        const { graph, rootCellValue } = this;
        const { id, x = 0, y = 0 } = rootCellValue;
        const width = 156;
        const height = 64;
        const style = 'shape=uml-note;connectable=0;';
        const parent = graph.getModel().getCell(rootCellValue.parent as string) || graph.getDefaultParent();

        const root = graph.insertVertex(parent, id, rootCellValue, x, y, width, height, style);

        return root;
    }

    restore(): MxCell {
        const { graph, rootCellValue } = this;
        const { id, x = 0, y = 0, width = 156, height = 64, style } = rootCellValue;
        const parent = graph.getModel().getCell(rootCellValue.parent as string) || graph.getDefaultParent();
        const root = graph.insertVertex(parent, id, rootCellValue, x, y, width, height, style);

        return root;
    }
}
