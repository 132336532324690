import { defineMessages } from 'react-intl';

export default defineMessages({
    eventFormName: {
        id: 'EventDialog.eventFormName',
        defaultMessage: 'Создать новое событие',
    },
    eventRunStrategyRequiredError: {
        id: 'EventDialog.eventRunStrategyRequiredError',
        defaultMessage: 'Введите имя стратегии',
    },
    eventActionsRequiredError: {
        id: 'EventDialog.eventActionsRequiredError',
        defaultMessage: 'Введите имя действия',
    },
    eventFormConfirmButton: {
        id: 'EventDialog.eventFormConfirmButton',
        defaultMessage: 'Сохранить',
    },
    eventFormDeclineButton: {
        id: 'EventDialog.eventFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    eventFormNameEdit: {
        id: 'EventDialog.eventFormNameEdit',
        defaultMessage: 'Редактировать событие',
    },
    eventNameLabel: {
        id: 'EventDialog.eventNameLabel',
        defaultMessage: 'Название события',
    },
    eventNamePlaceholder: {
        id: 'EventDialog.eventNamePlaceholder',
        defaultMessage: 'Введите название события',
    },
    eventStrategyLabel: {
        id: 'EventDialog.eventStrategyLabel',
        defaultMessage: 'Стратегия',
    },
    eventActionsLabel: {
        id: 'EventDialog.eventActionsLabel',
        defaultMessage: 'Действие',
    },
    eventUserIdLabel: {
        id: 'EventDialog.eventUserIdLabel',
        defaultMessage: 'Пользователь',
    },
    eventGroupIdLabel: {
        id: 'EventDialog.eventGroupIdLabel',
        defaultMessage: 'Группа',
    },
    eventNodeIdLabel: {
        id: 'EventDialog.eventNodeIdLabel',
        defaultMessage: 'Элемент',
    },
    eventParentNodeIdLabel: {
        id: 'EventDialog.eventParentNodeIdLabel',
        defaultMessage: 'Элемент родитель',
    },
    eventClassNameLabel: {
        id: 'EventDialog.eventClassNameLabel',
        defaultMessage: 'Обработчик',
    },
    eventParamsLabel: {
        id: 'EventDialog.eventParamsLabel',
        defaultMessage: 'Параметры',
    },
    eventParamsPlaceholder: {
        id: 'EventDialog.eventParamsPlaceholder',
        defaultMessage: 'Введите название параметров',
    },
    eventScriptIdLabel: {
        id: 'EventDialog.eventScriptIdLabel',
        defaultMessage: 'Скрипт',
    },
    eventScriptIdPlaceholder: {
        id: 'EventDialog.eventScriptIdPlaceholder',
        defaultMessage: 'Введите идентификатор скрипта',
    },
    eventRequiredFields: {
        id: 'EventDialog.eventRequiredFields',
        defaultMessage: 'Обязательные поля должны быть заполнены',
    },




});
