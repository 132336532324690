import { defineMessages } from 'react-intl';

export default defineMessages({
    fileImageDialogTitle: {
        id: 'fileImageDialogTitle.title',
        defaultMessage: 'Выбор файла',
    },

    fileImageDialogSelectButton: {
        id: 'fileImageDialogDialogSelectButton.select',
        defaultMessage: 'Выбрать',
    },

    fileImageDialogCancelButton: {
        id: 'fileImageDialogCancelButton.cancel',
        defaultMessage: 'Отмена',
    },
});
