import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'AttributeType.name',
        defaultMessage: 'Название',
    },
    format: {
        id: 'AttributeType.format',
        defaultMessage: 'Формат',
    },
    addAttributes: {
        id: 'AttributeType.addAttributes',
        defaultMessage: 'Новый тип атрибута',
    },
    ok: {
        id: 'AttributeType.ok',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'AttributeType.cancel',
        defaultMessage: 'Отмена',
    },
    deleteGroupMessage: {
        id: 'AttributeType.deleteGroupMessage',
        defaultMessage: 'Удалить группу типов атрибутов "{name}"',
    },
    deleteElMessage: {
        id: 'AttributeType.deleteElMessage',
        defaultMessage: 'Удалить тип атрибута "{name}"',
    },
    deleteAttributesDialogTitle: {
        id: 'AttributeType.deleteAttributesDialogTitle',
        defaultMessage: 'Удалить выбранные типы атрибутов?',
    },
    clearAttributeDefaultValue: {
        id: 'AttributeType.clearAttributeDefaultValue',
        defaultMessage: 'Удалить значение атрибута по умолчанию?',
    },
    selectDateAndTime: {
        id: 'AttributeType.selectDateAndTime',
        defaultMessage: 'Выберите дату и время',
    },
    selectDate: {
        id: 'AttributeType.selectDate',
        defaultMessage: 'Выберите дату',
    },
});
