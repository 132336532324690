import { Locale } from '@/modules/Header/components/Header/header.types';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolsManager.class';
import { LifelineSymbolMainClass } from '@/mxgraph/ComplexSymbols/symbols/LifeLine/LifelineSymbolMain.class';
import SequenceUtils from '@/mxgraph/ComplexSymbols/symbols/Sequence/sequence.utils';
import { SequenceEdgeTypesId } from '@/mxgraph/SequenceGraph/SequenceConstants';
import { LocalesService } from '@/services/LocalesService';
import { MxCell, MxPopupMenu } from 'MxGraph';
import messages from '../modules/Editor/messages/EditorPopupMenu.messages';
import { EdgeType, ModelType } from '@/serverapi/api';
import { UMLMessage } from '@/mxgraph/ComplexSymbols/symbols/Sequence/UMLMessage.class';

export const addMenuEdgeTypesForSequence = (menu: MxPopupMenu, graph: BPMMxGraph, cell: MxCell, locale: Locale) => {
    const { modelType }: { modelType?: ModelType } = graph;
    const complexSymbol = ComplexSymbolManager.getComplexSymbolInstance(cell) as
        | LifelineSymbolMainClass
        | UMLMessage
        | null;

    if (modelType && complexSymbol?.startConnection) {
        const typeEdge = menu.addItem(
            graph.intl.formatMessage(messages.typeEdge),
            null,
            () => {},
            null,
            '',
        );
        const isUmlMessage: boolean = SequenceUtils.isUmlMessage(cell);

        const availableTypes: EdgeType[] = isUmlMessage
            ? modelType.edgeTypes.filter((edgeType: EdgeType) => edgeType.id === SequenceEdgeTypesId.ASYNCHRON_MESSAGE)
            : modelType.edgeTypes;
        availableTypes.forEach((edgeType: EdgeType) => {
            menu.addItem(
                LocalesService.internationalStringToString(edgeType.multilingualName, locale),
                null,
                () => complexSymbol.startConnection(cell, edgeType, availableTypes),
                typeEdge,
                '',
            );
        });
    }
};

export const isAllowForkConnection = (cell?: MxCell) => {
    if (!cell) return false;

    const isRecursiveEdge = cell.value?.edgeTypeId === SequenceEdgeTypesId.RECURSIVE_MESSAGE;
    const isDurationEdge = cell.value?.edgeTypeId === SequenceEdgeTypesId.DURATION;
    const isForkEdge = SequenceUtils.isForkEdge(cell);
    const { source, target } = cell;
    const sourceComplexSymbol = ComplexSymbolManager.getComplexSymbolInstance(source);
    const allowForkConnection = !!(sourceComplexSymbol as LifelineSymbolMainClass)?.startForkConnection;
    const isUmlMessage = SequenceUtils.isUmlMessage(source) || SequenceUtils.isUmlMessage(target);

    return !isRecursiveEdge && !isDurationEdge && !isForkEdge && allowForkConnection && !isUmlMessage;
};
