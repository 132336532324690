import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    IDeleteAuditRange,
    IDeleteSessionRange,
    IFetchDetailedAudit,
    IFetchAudit,
    TDownloadCSVAction,
} from '../actions/audit.actions.types';
import { fetchAuditSuccess, fetchDetailedAuditSuccess, finishDownloadCSV } from '../actions/audit.actions';
import {
    DELETE_AUDIT_RANGE,
    DELETE_SESSION_RANGE,
    DOWNLOAD_CSV,
    FETCH_AUDIT,
    FETCH_DETAILED_AUDIT,
} from '../actionsTypes/audit.actionTypes';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { AuditDto, DetailedAuditDTO } from '../serverapi/api';
import { BrowserDaoService } from '../services/dao/BrowserDaoService';


function* handleAuditFecth({ params, serverId }: IFetchAudit) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const audit: AuditDto[] = yield call(() => server.api.audit.audit(params));
    yield put(fetchAuditSuccess(serverId, audit));
}

function* deleteAuditRange({ payload: { start, end, serverId, password } }: IDeleteAuditRange) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    yield call(() => server.api.audit.deleteAudit({
        body: {
            startTime: start,
            endTime: end,
            userPassword: password
        }
    }));
}

function* deleteSessionRange({ payload: { start, end, serverId, password } }: IDeleteSessionRange) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    yield call(() => server.api.session.deleteSession({
        body: {
            startTime: start,
            endTime: end,
            userPassword: password
        }
    }));
}

function* handleDetailedAuditFetch({ payload: { id, serverId } }: IFetchDetailedAudit) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const detailedAudit: DetailedAuditDTO = yield call(() => server.api.audit.detail({ id }));
    yield put(fetchDetailedAuditSuccess({ serverId, detailedAudit }));
}

function* handleDownloadCSV({ payload: { serverId, params } }: TDownloadCSVAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const requestParams = Object.keys(params)
        .map((item): string => `${item}=${params[item] ?? ''}`)
        .join('&');

    BrowserDaoService.downloadFile(`${server.url}/api/audit/file?${requestParams}`, {});

    yield put(finishDownloadCSV());
}

export function* auditSaga() {
    yield takeEvery(FETCH_AUDIT, handleAuditFecth);
    yield takeEvery(DELETE_AUDIT_RANGE, deleteAuditRange);
    yield takeEvery(DELETE_SESSION_RANGE, deleteSessionRange);
    yield takeEvery(FETCH_DETAILED_AUDIT, handleDetailedAuditFetch);
    yield takeEvery(DOWNLOAD_CSV, handleDownloadCSV);
}
