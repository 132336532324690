import type { TRootState } from '../../../reducers/root.reducer.types';
import type { TreeNode } from '@/models/tree.types';
import { NavigatorStructure } from '../components/NavigatorStructure/NavigatorStructure.component';
import {
    TNavigatorStructureReduxProps,
    TNavigatorStructureActionsProps,
} from '../components/NavigatorStructure/NavigatorStructure.types';
import { navigatorTabDeselect } from '../../../actions/navigator.actions';
import { connect } from 'react-redux';
import { TNavigatorTab } from '../../../reducers/navigator.reducer.types';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { getSelectedItems, TreeSelectors } from '../../../selectors/tree.selectors';
import { treeFilter } from '../../../actions/tree.actions';
import React from 'react';
import { isEqual } from 'lodash';

const panelType = TNavigatorTab.Tree;

const mapStateToProps = (state: TRootState): TNavigatorStructureReduxProps => {
    const treeData: TreeNode[] = TreeSelectors.fullTreeStructure(state);

    return {
        isEmptyTree: treeData.length === 0,
        appliedFilters: TreeSelectors.filters(state),
        data: TreeSelectors.treeNodesWithoutHidden(state),
        selectedNodes: getSelectedItems(state),
    };
};

const mapDispatchToProps = (dispatch): TNavigatorStructureActionsProps => ({
    onClose: () => dispatch(navigatorTabDeselect(panelType)),
    onFilterChange: (checked, filterTypes) => dispatch(treeFilter(checked, filterTypes)),
    onServerAdd: () => dispatch(openDialog(DialogType.SERVER_EDIT)),
});

export const NavigatorStructureContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    React.memo(NavigatorStructure, (prev, next) => {
        return isEqual(prev, next);
    }),
);
