import { Button, Checkbox, Form, Popover } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import {
    DEFAULT_MAIN_COLOR,
    DEFAULT_SECOND_COLOR,
    DEFAULT_SELECTED_ROW_COLOR,
    SystemPropertiesFormItemNames,
    TColorsPicker,
    TPropsWithFormRef,
} from '../../SystemProperties.types';
import { ColorResult, SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { getAppColor, getIsEnLocaleAllowed } from '../../../../../selectors/app.selector';
import { TAppColor } from '@/reducers/app.reducer.types';

export const InterFaceSettings = (props: TPropsWithFormRef) => {
    const intl = useIntl();

    const { formRef } = props;
    const appColor: TAppColor = useSelector(getAppColor);
    const isEnLocaleAllowed: boolean = useSelector(getIsEnLocaleAllowed);
    const [colorsPicker, setColorsPicker] = useState<TColorsPicker>({
        mainColor: appColor.mainColor || DEFAULT_MAIN_COLOR,
        secondColor: appColor.secondColor || DEFAULT_SECOND_COLOR,
        selectedRowColor: appColor.selectedRowColor || DEFAULT_SELECTED_ROW_COLOR,
    });

    const handleChangeColorPicker = (colors: TColorsPicker) => {
        const newColors = { ...colorsPicker };
        Object.keys(colors).forEach((colorType) => {
            newColors[colorType] = colors[colorType];
            formRef.current?.setFieldValue(colorType, colors[colorType]);
        });
        setColorsPicker(newColors);
    };

    const isOpenNavigator = useSelector(SystemPropertiesSelectors.getIsOpenNavigator);
    const isCompactMainMenu = useSelector(SystemPropertiesSelectors.getIsCompactMainMenu);
    const isOpenMainMenu = useSelector(SystemPropertiesSelectors.getIsOpenMainMenu);

    const { mainColor, secondColor, selectedRowColor } = colorsPicker;

    return (
        <div className={theme.formTab}>
            <div className={theme.formItemWrapper}>
                <div className={theme.wrapperColum}>
                    <Form.Item
                        initialValue={isOpenNavigator}
                        name={SystemPropertiesFormItemNames.isOpenNavigator}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isOpenNavigator)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                    <Form.Item
                        initialValue={isCompactMainMenu}
                        name={SystemPropertiesFormItemNames.isCompactMainMenu}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isCompactMainMenu)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                    <Form.Item
                        initialValue={isOpenMainMenu}
                        name={SystemPropertiesFormItemNames.isOpenMainMenu}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isOpenMainMenu)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                    <Form.Item
                        initialValue={isEnLocaleAllowed}
                        name={SystemPropertiesFormItemNames.isEnLocaleAllowed}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.isEnLocaleAllowed)}
                        valuePropName="checked"
                    >
                        <Checkbox className={theme.checkbox} />
                    </Form.Item>
                </div>
                <div className={theme.wrapperColor}>
                    <div className={theme.wrapperColum}>
                        <Form.Item
                            initialValue={mainColor}
                            name={SystemPropertiesFormItemNames.mainColor}
                            className={theme.formItemCheckBox}
                            label={intl.formatMessage(messages.mainColor)}
                        >
                            <Popover
                                content={
                                    <SketchPicker
                                        color={mainColor}
                                        onChange={(color: ColorResult) =>
                                            handleChangeColorPicker({ mainColor: color.hex as string })
                                        }
                                        disableAlpha
                                    />
                                }
                                trigger="click"
                            >
                                <div className={theme.changeColorBlock} style={{ backgroundColor: `${mainColor}` }} />
                            </Popover>
                        </Form.Item>
                        <Form.Item
                            initialValue={secondColor}
                            name={SystemPropertiesFormItemNames.secondColor}
                            className={theme.formItemCheckBox}
                            label={intl.formatMessage(messages.secondColor)}
                        >
                            <Popover
                                content={
                                    <SketchPicker
                                        color={secondColor}
                                        onChange={(color: ColorResult) =>
                                            handleChangeColorPicker({ secondColor: color.hex as string })
                                        }
                                        disableAlpha
                                    />
                                }
                                trigger="click"
                            >
                                <div className={theme.changeColorBlock} style={{ backgroundColor: `${secondColor}` }} />
                            </Popover>
                        </Form.Item>
                        <Form.Item
                            initialValue={selectedRowColor}
                            name={SystemPropertiesFormItemNames.selectedRowColor}
                            className={theme.formItemCheckBox}
                            label={intl.formatMessage(messages.selectedRowColor)}
                        >
                            <Popover
                                content={
                                    <SketchPicker
                                        color={selectedRowColor}
                                        onChange={(color: ColorResult) =>
                                            handleChangeColorPicker({ selectedRowColor: color.hex as string })
                                        }
                                        disableAlpha
                                    />
                                }
                                trigger="click"
                            >
                                <div
                                    className={theme.changeColorBlock}
                                    style={{ backgroundColor: `${selectedRowColor}` }}
                                />
                            </Popover>
                        </Form.Item>
                    </div>
                    <Form.Item
                        className={theme.resetColorsButton}
                        wrapperCol={{
                            xs: { span: 24, offset: 0 },
                            sm: { span: 16, offset: 8 },
                        }}
                    >
                        <Button
                            onClick={() =>
                                handleChangeColorPicker({
                                    mainColor: DEFAULT_MAIN_COLOR,
                                    secondColor: DEFAULT_SECOND_COLOR,
                                    selectedRowColor: DEFAULT_SELECTED_ROW_COLOR,
                                })
                            }
                        >
                            {intl.formatMessage(messages.resetToDefaultColors)}
                        </Button>
                    </Form.Item>
                </div>
            </div>
        </div>
    );
};
