import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import theme from './ApprovalDialog.scss';
import { cloneDeep } from 'lodash';
import messages from './ApprovalDialog.messages';
import {
    Checkbox,
    ConfigProvider,
    DatePicker,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Radio,
    RadioChangeEvent,
    Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { editApproval } from '@/actions/approval.actions';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { LocalesService } from '@/services/LocalesService';
import { momentDateToTimestamp, timestampToMomentDate } from '@/utils/date.time.utils';
import dayjs from 'dayjs';
import {
    ApprovalDTO,
    ApprovalStageDTOAbstentionVoteStrategyEnum,
    ApprovalUserDTO,
    PrincipalDescriptor,
} from '@/serverapi/api';
import icRemoveApprovalUser from '../../resources/icons/ic-remove-approval-user.svg';
import icArrowDownDark from '../../resources/icons/icArrowDownDark.svg';
import icCalendar from '../../resources/icons/icCalendar.svg';
import icApprovalUser from '../../resources/icons/ic-approval-user.svg';
import { Icon } from '../UIKit';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import classNames from 'classnames';
import {
    ApprovalRateOfSuccess,
    ApprovalStageDTOAbstentionVoteStrategy,
    ApprovalStageDTOStatus,
    ApprovalStageDTOType,
} from './ApprovalDialog.types';
import { PrincipalsSelectors } from '../../selectors/principals.selectors';

type TStageSettingsProps = {
    stageFormRef: React.RefObject<FormInstance>;
    stageId: string;
    isCreateMode: boolean;
};

const StageSettingsTab: FC<TStageSettingsProps> = (props) => {
    const { stageFormRef, stageId, isCreateMode } = props;
    const approval: ApprovalDTO = useSelector(ApprovalSelectors.getEditingApproval);
    const selectedStage = approval.stages?.find((stage) => stage.id === stageId);
    if (!selectedStage) return null;

    const intl = useIntl();
    const dispatch = useDispatch();
    const users: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getUsers);
    const notSelectedUsers = users.filter((user) => {
        return selectedStage.approvalUsersDTO
            ? !selectedStage.approvalUsersDTO.find((userDTO) => userDTO.principalId === user.id)
            : true;
    });

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        return stage.id === stageId ? { ...stage, name: e.target.value } : stage;
                    }),
                },
            }),
        );
    };

    const onTypeChange = (e: RadioChangeEvent) => {
        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        return stage.id === stageId ? { ...stage, type: e.target.value } : stage;
                    }),
                },
            }),
        );
    };

    const onChangeVoteAllowed = (e: CheckboxChangeEvent) => {
        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        return stage.id === stageId ? { ...stage, changeVoteAllowed: e.target.checked } : stage;
                    }),
                },
            }),
        );
    };

    const onRateOfSuccessChange = (e: number | null) => {
        const rateOfSuccess = e === null ? undefined : e;

        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        return stage.id === stageId ? { ...stage, rateOfSuccess } : stage;
                    }),
                },
            }),
        );

        if (e === 100) {
            const fieldsValue = stageFormRef.current?.getFieldsValue();
            if (fieldsValue) {
                fieldsValue.RateOfSuccessType = ApprovalRateOfSuccess.ALL_POSITIVE;
                stageFormRef.current?.setFieldsValue(fieldsValue);
            }
        }
    };

    const onRateOfSuccessTypeChange = (e: RadioChangeEvent) => {
        const value = e.target.value === ApprovalRateOfSuccess.ALL_POSITIVE ? 100 : null;
        onRateOfSuccessChange(value);

        const fieldsValue = stageFormRef.current?.getFieldsValue();
        if (fieldsValue) {
            fieldsValue.RateOfSuccess = value;
            stageFormRef.current?.setFieldsValue(fieldsValue);
        }
    };

    const onFinishDateChange = (date: dayjs.Dayjs) => {
        const scheduledFinishDate = momentDateToTimestamp(date);

        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        return stage.id === stageId
                            ? { ...stage, scheduledFinishDate: scheduledFinishDate || undefined }
                            : stage;
                    }),
                },
            }),
        );

        const fieldsValue = stageFormRef.current?.getFieldsValue();
        if (fieldsValue) {
            fieldsValue.scheduledFinishDate = scheduledFinishDate || undefined;
            stageFormRef.current?.setFieldsValue(fieldsValue);
            if (!scheduledFinishDate) {
                stageFormRef.current?.validateFields(['scheduledFinishDate']);
            }
        }
    };

    const onVoteStrategyCHange = (val: ApprovalStageDTOAbstentionVoteStrategyEnum) => {
        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        return stage.id === stageId ? { ...stage, abstentionVoteStrategy: val } : stage;
                    }),
                },
            }),
        );
    };

    const onChangeTimeConstraints = (e: CheckboxChangeEvent) => {
        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        if (stage.id !== stageId) return stage;
                        const newStageData = cloneDeep(stage);
                        if (e.target.checked) {
                            newStageData.abstentionVoteStrategy = ApprovalStageDTOAbstentionVoteStrategy.APPROVED;
                            return newStageData;
                        }
                        delete newStageData.abstentionVoteStrategy;
                        delete newStageData.scheduledFinishDate;
                        return newStageData;
                    }),
                },
            }),
        );
    };

    const onApprovalUserAdd = (id: number) => {
        stageFormRef.current?.resetFields(['ApprovalUsers']);
        const user = users.find((user) => user.id === id);
        if (!user) return;

        const approvalUserName = `${[user.lastname, user.name, user.middlename].filter((el) => !!el).join(' ')}`;

        const newApprovalUser = { principalId: user.id, login: user.login, name: approvalUserName } as ApprovalUserDTO;
        const approvalUsersDTO = selectedStage.approvalUsersDTO
            ? [...selectedStage.approvalUsersDTO, newApprovalUser]
            : [newApprovalUser];

        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        return stage.id === stageId ? { ...stage, approvalUsersDTO } : stage;
                    }),
                },
            }),
        );
    };

    const onApprovalUserRemove = (id: number) => {
        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        return stage.id === stageId
                            ? {
                                  ...stage,
                                  approvalUsersDTO: stage.approvalUsersDTO?.filter(
                                      (userDTO) => userDTO.principalId !== id,
                                  ),
                              }
                            : stage;
                    }),
                },
            }),
        );
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination || !selectedStage.approvalUsersDTO) return;

        if (!isCreateMode) {
            const userToReplace = selectedStage.approvalUsersDTO[result.destination.index];
            if (!!userToReplace.vote || !!userToReplace.comment) return;
        }

        const newApprovalUsers = cloneDeep(selectedStage.approvalUsersDTO);
        const [removed] = newApprovalUsers.splice(result.source.index, 1);
        newApprovalUsers.splice(result.destination.index, 0, removed);

        dispatch(
            editApproval({
                approval: {
                    ...approval,
                    stages: approval.stages?.map((stage) => {
                        return stage.id === stageId ? { ...stage, approvalUsersDTO: newApprovalUsers } : stage;
                    }),
                },
            }),
        );
    };

    const showApprovalUsersError = selectedStage.approvalUsersDTO?.length === 0;
    const isEditingDisabled =
        !isCreateMode &&
        (selectedStage.status === ApprovalStageDTOStatus.APPROVED ||
            selectedStage.status === ApprovalStageDTOStatus.NOT_APPROVED);
    const locale = LocalesService.checkAndSetLocale(intl.locale);

    return (
        <Form ref={stageFormRef} layout="vertical" className={theme.form}>
            <Form.Item
                label={intl.formatMessage(messages.stageName)}
                name="StageName"
                initialValue={selectedStage.name}
                required
                rules={[
                    {
                        whitespace: true,
                        message: intl.formatMessage(messages.youEnteredOnlyWhitespaces),
                    },
                    {
                        required: true,
                        message: intl.formatMessage(messages.thisFieldIsRequired),
                    },
                ]}
            >
                <Input disabled={isEditingDisabled} value={selectedStage.name} maxLength={60} onChange={onChangeName} />
            </Form.Item>

            <Form.Item
                label={intl.formatMessage(messages.listOfApprovers)}
                required
                name="ApprovalUsers"
                className={classNames({
                    [theme.userSelectorError]: showApprovalUsersError,
                })}
            >
                <Select
                    suffixIcon={<Icon spriteSymbol={icArrowDownDark} />}
                    placeholder={intl.formatMessage(messages.selectUser)}
                    style={{ width: '100%' }}
                    onChange={onApprovalUserAdd}
                    disabled={isEditingDisabled}
                    virtual={false}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return ((option?.children ?? '') as string)
                            .toLowerCase()
                            .includes(input.toLocaleLowerCase().trim());
                    }}
                >
                    {notSelectedUsers.map((user) => {
                        const { id, login, lastname, name, middlename } = user;
                        let displayName: string;
                        if (!lastname && !name && !middlename) {
                            displayName = login;
                        } else {
                            displayName = `${[lastname, name, middlename].filter((el) => !!el).join(' ')}`;
                        }
                        return (
                            <Select.Option value={id} key={id}>
                                {displayName}
                            </Select.Option>
                        );
                    })}
                </Select>
                {showApprovalUsersError ? (
                    <div className="ant-form-item-explain-error">
                        {intl.formatMessage(messages.thisFieldIsRequired)}
                    </div>
                ) : null}
            </Form.Item>

            {selectedStage.approvalUsersDTO ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="droppable"
                        renderClone={(draggableProvided, snapshot, rubric) => {
                            return (
                                <div
                                    ref={draggableProvided.innerRef}
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                >
                                    <div style={{ fontSize: '14px', color: '#000000A6' }}>
                                        <span style={{ marginRight: '13px' }}>
                                            <svg
                                                style={{ width: '6px', height: '10px' }}
                                                viewBox={icApprovalUser.viewBox}
                                            >
                                                <use xlinkHref={`#${icApprovalUser.id}`} />
                                            </svg>
                                        </span>
                                        {
                                            (selectedStage.approvalUsersDTO as ApprovalUserDTO[])[rubric.source.index]
                                                .name
                                        }
                                    </div>
                                </div>
                            );
                        }}
                    >
                        {(droppableProvided) => (
                            <div
                                className={theme.usersContainer}
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                            >
                                {(selectedStage.approvalUsersDTO as ApprovalUserDTO[]).map((user, index) => {
                                    const isDragDisabled: boolean =
                                        (!isCreateMode && (!!user.vote || !!user.comment)) || isEditingDisabled;
                                    const isDeleteUserDisabled: boolean =
                                        isEditingDisabled || !!user.vote || !!user.comment;
                                    return (
                                        <Draggable
                                            key={`${user.principalId}`}
                                            draggableId={`${user.principalId}`}
                                            index={index}
                                            isDragDisabled={isDragDisabled}
                                        >
                                            {(draggableProvided) => (
                                                <div
                                                    className={theme.user}
                                                    ref={draggableProvided.innerRef}
                                                    {...draggableProvided.draggableProps}
                                                    {...draggableProvided.dragHandleProps}
                                                >
                                                    <div
                                                        className={classNames(theme.userInfo, {
                                                            [theme.dragDisabled]: isDragDisabled,
                                                        })}
                                                    >
                                                        <span>
                                                            <Icon spriteSymbol={icApprovalUser} />
                                                        </span>
                                                        {user.name}
                                                    </div>
                                                    <button
                                                        disabled={isDeleteUserDisabled}
                                                        type="button"
                                                        className={classNames(theme.deleteApprovalUser, {
                                                            [theme.deleteDisabled]: isDeleteUserDisabled,
                                                        })}
                                                        onClick={() => {
                                                            onApprovalUserRemove(user.principalId);
                                                        }}
                                                    >
                                                        <Icon spriteSymbol={icRemoveApprovalUser} />
                                                    </button>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : null}

            <hr />
            <Form.Item
                label={intl.formatMessage(messages.approvalType)}
                initialValue={selectedStage.type || ApprovalStageDTOType.PARALLEL}
                name="ApprovalType"
            >
                <Radio.Group
                    onChange={onTypeChange}
                    value={selectedStage.type || ApprovalStageDTOType.PARALLEL}
                    disabled={
                        isEditingDisabled ||
                        (selectedStage.status === ApprovalStageDTOStatus.IN_PROCESS && !isCreateMode)
                    }
                >
                    <Radio value={ApprovalStageDTOType.FOLLOWING}>{intl.formatMessage(messages.following)}</Radio>
                    <Radio value={ApprovalStageDTOType.SUCCESSIVE}>
                        {intl.formatMessage(messages.followingSuccessive)}
                    </Radio>
                    <Radio value={ApprovalStageDTOType.PARALLEL}>{intl.formatMessage(messages.parallel)}</Radio>
                </Radio.Group>
            </Form.Item>
            <hr />
            <div className={theme.timeCheckboxContainer}>
                <Checkbox
                    disabled={isEditingDisabled}
                    defaultChecked={!!selectedStage.abstentionVoteStrategy}
                    onChange={onChangeTimeConstraints}
                >
                    <span>{intl.formatMessage(messages.restrictVotingTime)}</span>
                </Checkbox>
            </div>

            {!!selectedStage.abstentionVoteStrategy ? (
                <div className={theme.timeConstraintContainer}>
                    <Form.Item
                        label={intl.formatMessage(messages.finishDateTime)}
                        initialValue={
                            selectedStage.scheduledFinishDate
                                ? timestampToMomentDate(selectedStage.scheduledFinishDate)
                                : undefined
                        }
                        name="scheduledFinishDate"
                        required
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage(messages.thisFieldIsRequired),
                            },
                        ]}
                    >
                        <ConfigProvider
                            locale={{
                                ...locale,
                                DatePicker: locale.DatePicker
                                    ? {
                                          ...locale.DatePicker,
                                          lang: {
                                              ...locale.DatePicker.lang,
                                              ok: intl.formatMessage(messages.apply),
                                          },
                                      }
                                    : undefined,
                            }}
                        >
                            <DatePicker
                                suffixIcon={<Icon spriteSymbol={icCalendar} />}
                                disabled={isEditingDisabled}
                                placeholder={intl.formatMessage(messages.selectDateTime)}
                                style={{ width: '100%' }}
                                showTime={true}
                                format="DD.MM.YYYY HH:mm:ss"
                                defaultValue={
                                    selectedStage.scheduledFinishDate
                                        ? timestampToMomentDate(selectedStage.scheduledFinishDate)
                                        : undefined
                                }
                                onChange={onFinishDateChange}
                            />
                        </ConfigProvider>
                    </Form.Item>

                    <Form.Item
                        label={intl.formatMessage(messages.abstentionsCountedAs)}
                        initialValue={
                            selectedStage.abstentionVoteStrategy || ApprovalStageDTOAbstentionVoteStrategy.APPROVED
                        }
                        name="AbstentionVoteStrategy"
                    >
                        <Select
                            suffixIcon={<Icon spriteSymbol={icArrowDownDark} />}
                            style={{ width: '100%' }}
                            defaultValue={
                                selectedStage.abstentionVoteStrategy || ApprovalStageDTOAbstentionVoteStrategy.APPROVED
                            }
                            onChange={onVoteStrategyCHange}
                            disabled={isEditingDisabled}
                        >
                            <Select.Option value={ApprovalStageDTOAbstentionVoteStrategy.APPROVED}>
                                {intl.formatMessage(messages.approved)}
                            </Select.Option>
                            <Select.Option value={ApprovalStageDTOAbstentionVoteStrategy.NOT_APPROVED}>
                                {intl.formatMessage(messages.notApproved)}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </div>
            ) : null}

            <Checkbox
                disabled={isEditingDisabled}
                checked={selectedStage.changeVoteAllowed}
                onChange={onChangeVoteAllowed}
            >
                <span>{intl.formatMessage(messages.allowVoteChange)}</span>
            </Checkbox>

            <hr />

            <Form.Item
                label={intl.formatMessage(messages.rateOfSuccessLabel)}
                initialValue={
                    selectedStage.rateOfSuccess === 100
                        ? ApprovalRateOfSuccess.ALL_POSITIVE
                        : ApprovalRateOfSuccess.PERCENTAGE
                }
                name="RateOfSuccessType"
            >
                <Radio.Group
                    disabled={isEditingDisabled}
                    onChange={onRateOfSuccessTypeChange}
                    value={
                        selectedStage.rateOfSuccess === 100
                            ? ApprovalRateOfSuccess.ALL_POSITIVE
                            : ApprovalRateOfSuccess.PERCENTAGE
                    }
                >
                    <Radio value={ApprovalRateOfSuccess.PERCENTAGE}>{intl.formatMessage(messages.percentage)}</Radio>
                    <Radio value={ApprovalRateOfSuccess.ALL_POSITIVE}>
                        {intl.formatMessage(messages.allVotedPositive)}
                    </Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                initialValue={selectedStage.rateOfSuccess}
                name="RateOfSuccess"
                required
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages.thisFieldIsRequired),
                    },
                ]}
            >
                <InputNumber
                    placeholder={intl.formatMessage(messages.selectPositiveVotePercentage)}
                    size={'middle'}
                    min={1}
                    max={100}
                    value={selectedStage.rateOfSuccess}
                    controls={false}
                    onChange={onRateOfSuccessChange}
                    disabled={selectedStage.rateOfSuccess === 100 || isEditingDisabled}
                />
            </Form.Item>
        </Form>
    );
};

export { StageSettingsTab };
