import type { TUMLClassSymbolCustomProps } from '../../ComplexSymbol.class.types';
import type { TUmlClassData } from './classSymbol.types';
import type { MxCell } from 'MxGraph';
import { v4 as uuid } from 'uuid';
import { UmlClassSymbolChildren } from '@/mxgraph/ComplexSymbols/symbols/UML/ClassSymbol/classSymbol.constants';
import { MxPoint, MxRectangle } from 'MxGraph';
import { SymbolTypeId, FIXED_STYLE_INDICATOR, MULTILINE_OVERFLOW_ATTRIBUTE } from '../../ComplexSymbol.constants';
import { getUmlClassCellValue, getUmlClassCellSizes } from './classSymbol.utils';
import { getUmlClassObjectAttributes } from './sideEffects';
import { UMLSymbol } from '../UMLSymbol.class';
import { SYMBOL_HORIZONTAL_PADDING } from '../UMLSymbols.constants';

export class UMLClassSymbol extends UMLSymbol {
    complexSymbolTypeId = SymbolTypeId.UML_CLASS;
    customProps: TUMLClassSymbolCustomProps;
    getObjectAttributes: () => TUmlClassData;

    addToGraph() {
        const staticAttributes = this.customProps?.getObjectAttributes?.();

        this.getObjectAttributes = staticAttributes
            ? () => staticAttributes
            : () => getUmlClassObjectAttributes(this.graph, this.rootCellValue);

        const objectAttributes = this.getObjectAttributes();
        const root = this.createSymbolCells(objectAttributes);

        return this.afterCreate(root);
    }

    public convertValueToString(cell: MxCell): string {
        const { complexSymbolTypeId } = cell;
        const { height, width } = cell.getGeometry();
        const objectAttributes = this.getObjectAttributes();

        return getUmlClassCellValue(objectAttributes, complexSymbolTypeId || '', { height, width });
    }

    public getResizedCells(bound: MxRectangle) {
        const objectAttributes = this.getObjectAttributes();
        const cells: MxCell[] = [];
        const updatedBounds: MxRectangle[] = [];

        const cellSizes = getUmlClassCellSizes(objectAttributes, bound);

        const rootCellGeo = this.rootCell.getGeometry();
        const itemSize = cellSizes[this.rootCell?.complexSymbolTypeId || ''];
        const newRectangle = new MxRectangle(rootCellGeo.x, rootCellGeo.y, itemSize.width, itemSize.height);

        cells.push(this.rootCell);
        updatedBounds.push(newRectangle);

        this.setCustomBound(true);

        this.rootCell.children?.forEach((childCell: MxCell) => {
            if (childCell?.complexSymbolTypeId && cellSizes[childCell?.complexSymbolTypeId]) {
                const { complexSymbolTypeId } = childCell;
                const childCellGeo = childCell.getGeometry();
                const childSize = cellSizes[complexSymbolTypeId];
                const childRectangle = new MxRectangle(childCellGeo.x, childSize.y, childSize.width, childSize.height);

                cells.push(childCell);
                updatedBounds.push(childRectangle);
            }
        });

        return [cells, updatedBounds];
    }

    public redraw() {
        const { rootCell } = this;
        const objectAttributes = this.getObjectAttributes();
        const model = this.graph.getModel();
        const rootCellGeo = model.getGeometry(rootCell).clone();
        const cellSizes = getUmlClassCellSizes(objectAttributes, this.hasCustomBound() ? rootCellGeo : undefined);
        const listSize = cellSizes[SymbolTypeId.UML_CLASS];

        const listStyle = model.getStyle(rootCell);

        if (
            rootCellGeo.height !== listSize.height ||
            rootCellGeo.width !== listSize.width ||
            listStyle !== listSize.style
        ) {
            rootCellGeo.height = listSize.height;
            rootCellGeo.width = listSize.width;

            model.setGeometry(rootCell, rootCellGeo);
            model.setStyle(rootCell, listSize.style);
        }

        rootCell.children?.forEach((childCell) => {
            if (childCell?.complexSymbolTypeId && cellSizes[childCell?.complexSymbolTypeId]) {
                const childCellGeo = model.getGeometry(childCell).clone();
                const { height, y } = cellSizes[childCell?.complexSymbolTypeId];

                if (childCellGeo.height !== height || childCellGeo.y !== y || childCellGeo.width !== listSize.width) {
                    childCellGeo.height = height;
                    childCellGeo.width = listSize.width;
                    childCellGeo.y = y;

                    model.setGeometry(childCell, childCellGeo);
                }
            }
            this.graph.refresh(childCell);
        });

        // вызывает пересчет value в convertValueToString
        this.graph.refresh(rootCell);
    }

    createSymbolCells(classAttributes: TUmlClassData): MxCell {
        const { graph } = this;
        const cellBound = this.hasCustomBound()
            ? {
                  height: this.rootCellValue.height,
                  width: this.rootCellValue.width,
              }
            : {};
        const cellSizes = getUmlClassCellSizes(classAttributes, cellBound);
        const listSize = cellSizes[SymbolTypeId.UML_CLASS];
        const attributesSize = cellSizes[UmlClassSymbolChildren.ATTRIBUTES];
        const operationsSize = cellSizes[UmlClassSymbolChildren.OPERATIONS];
        const receptionsSize = cellSizes[UmlClassSymbolChildren.RECEPTIONS];
        const cellStyle =
            `${FIXED_STYLE_INDICATOR};text;fillColor=default;${MULTILINE_OVERFLOW_ATTRIBUTE}=1;` +
            `align=left;spacingLeft=${SYMBOL_HORIZONTAL_PADDING};spacingRight=${SYMBOL_HORIZONTAL_PADDING};` +
            'verticalAlign=top;whiteSpace=nowrap;overflow=hidden;rotatable=0;html=1;deletable=0;cloneable=0;' +
            'expand=0;connectable=0;allowArrows=0;resizable=0;pointerEvents=0;editable=0;movable=0;';
        const parent: MxCell = this.rootCellValue?.parent
            ? this.graph.getModel().getCell(this.rootCellValue?.parent)
            : this.graph.getDefaultParent();

        const list = graph.createVertex(
            parent,
            this.rootCellValue?.id || uuid(),
            this.rootCellValue,
            0,
            0,
            listSize.width,
            listSize.height,
            listSize.style,
        );

        // TODO: Create classes for this attributes
        const firstItem = graph.createVertex(
            null,
            uuid(),
            getUmlClassCellValue(classAttributes, UmlClassSymbolChildren.ATTRIBUTES, {
                height: attributesSize.height,
                width: attributesSize.width,
            }),
            0,
            attributesSize.y,
            attributesSize.width,
            attributesSize.height,
            cellStyle,
        );
        firstItem.complexSymbolTypeId = UmlClassSymbolChildren.ATTRIBUTES as any;

        const secondItem = graph.createVertex(
            null,
            uuid(),
            getUmlClassCellValue(classAttributes, UmlClassSymbolChildren.OPERATIONS, {
                height: operationsSize.height,
                width: operationsSize.width,
            }),
            0,
            operationsSize.y,
            operationsSize.width,
            operationsSize.height,
            cellStyle,
        );
        secondItem.complexSymbolTypeId = UmlClassSymbolChildren.OPERATIONS as any;

        const thirdItem = graph.createVertex(
            null,
            uuid(),
            getUmlClassCellValue(classAttributes, UmlClassSymbolChildren.RECEPTIONS, {
                height: receptionsSize.height,
                width: receptionsSize.width,
            }),
            0,
            receptionsSize.y,
            receptionsSize.width,
            receptionsSize.height,
            cellStyle,
        );
        thirdItem.complexSymbolTypeId = UmlClassSymbolChildren.RECEPTIONS as any;

        list.insert(firstItem);
        list.insert(secondItem);
        list.insert(thirdItem);

        list.complexSymbolTypeId = SymbolTypeId.UML_CLASS;

        const { x = 0, y = 0 } = this.rootCellValue;
        const position = new MxPoint(x, y);
        const [cells] = this.graph.importCellsWithIds([list], position, parent);
        const [root] = cells;

        return root;
    }
}
