import { KanbanBoardObjectChangingConditionType, KanbanSizeType, TKanbanStateColumnItem } from '@/models/kanban.types';
import { AttributeValueType } from '@/modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { storageValueToString } from '@/modules/ObjectPropertiesDialog/components/utils';
import {
    AttributeType,
    AttributeValue,
    AttributeValueMultiSelect,
    KanbanBoardColumnsSettings,
    KanbanBoardObjectChangingCondition,
    KanbanBoardObjectChangingRule,
    KanbanBoardPlacingCondition,
    KanbanBoardSingleColumnSettings,
    ObjectDefinitionNode,
    PresetImage,
} from '@/serverapi/api';
import { isEqual } from 'lodash';

type TGetIsObjectChangingRuleMatched = {
    rule: KanbanBoardObjectChangingRule;
    columnItem: TKanbanStateColumnItem;
    columnId: string;
    isMoveItemMode: boolean;
    objectDefinition: ObjectDefinitionNode;
    currentLocale: Locale;
    attributeTypes: AttributeType[] | undefined;
};

type TCheckIsRuleConditionsMatched = {
    condition: KanbanBoardObjectChangingCondition | KanbanBoardPlacingCondition;
    attributes: AttributeValue[] | undefined;
    currentLocale: Locale;
    attributeTypes: AttributeType[] | undefined;
};

type TComputeColumnStyleData = {
    singleColSettings: KanbanBoardSingleColumnSettings;
    columnsSettings: KanbanBoardColumnsSettings;
    presetImages: PresetImage[];
    currentLocale: Locale;
};

type TColumnStyleData = {
    style: {
        width: string;
        minWidth: string;
        backgroundColor: string;
    };
    icon: PresetImage | undefined;
    headerStyle: { backgroundColor: string };
    title: string;
};

export class KanbanBll {
    public static checkIsRuleConditionMatched({
        condition,
        attributes,
        currentLocale,
        attributeTypes,
    }: TCheckIsRuleConditionsMatched): boolean {
        const attribute = attributes?.find((attr) => attr.typeId === condition.attributeTypeId);

        if (!attribute) {
            return false;
        }

        if (attribute?.valueType === AttributeValueType.MULTI_SELECT) {
            return isEqual(
                (attribute as AttributeValueMultiSelect).valueIds?.sort(),
                (condition.value as AttributeValueMultiSelect).valueIds?.sort(),
            );
        }

        return (
            storageValueToString(attribute, currentLocale, { attributeTypes }) ===
            storageValueToString(condition.value, currentLocale, {
                attributeTypes,
            })
        );
    }

    public static checkIsObjectChangingRuleMatched({
        rule,
        columnItem,
        columnId,
        isMoveItemMode,
        objectDefinition,
        currentLocale,
        attributeTypes,
    }: TGetIsObjectChangingRuleMatched): boolean {
        if (columnItem.symbolCardTypeId !== rule.symbolCardTypeId) return false;

        const uniqueColumnIds = rule.conditions
            .filter((condition) => condition.type === KanbanBoardObjectChangingConditionType.OBJECT_IN_COLUMN)
            .map((condition) => condition.columnId!)
            .filter((columnId, index, array) => array.indexOf(columnId) === index);
        if (uniqueColumnIds.length !== 1) return false;

        const isWrongObjectInColumnColumnConditions = isMoveItemMode
            ? uniqueColumnIds[0] !== columnId
            : uniqueColumnIds[0] === columnId;

        if (isWrongObjectInColumnColumnConditions) return false;

        return rule.conditions.every((condition) => {
            if (condition.type === KanbanBoardObjectChangingConditionType.OBJECT_IN_COLUMN) return true;

            return KanbanBll.checkIsRuleConditionMatched({
                condition,
                attributes: objectDefinition.attributes,
                currentLocale,
                attributeTypes,
            });
        });
    }

    public static computeColumnStyleData({
        singleColSettings,
        columnsSettings,
        presetImages,
        currentLocale,
    }: TComputeColumnStyleData): TColumnStyleData {
        let style = {};

        if (columnsSettings.sameColumnsWidth) {
            if (columnsSettings.widthType === KanbanSizeType.RELATIVE) {
                const width = 100 / columnsSettings.columns.length;
                style = { width: `${width}%` };
            } else {
                style = { width: `${columnsSettings.widthValue}px` };
            }
        } else {
            const unit = singleColSettings.widthType === KanbanSizeType.RELATIVE ? '%' : 'px';
            style = { width: `${singleColSettings.widthValue}${unit}` };
        }

        style = {
            ...style,
            minWidth: 'min-content',
            backgroundColor: singleColSettings.columnColor,
        };
        const headerStyle = {
            backgroundColor: singleColSettings.headerColor,
        };
        let icon: PresetImage | undefined;

        if (singleColSettings.hasHeaderIcon)
            icon = presetImages.find((image) => image.id === singleColSettings.headerIcon);

        return {
            style,
            icon,
            headerStyle,
            title: `${singleColSettings?.multilingualName[currentLocale]}`,
        } as TColumnStyleData;
    }
}
