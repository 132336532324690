import { Form, InputNumber } from 'antd';
import React from 'react';
import theme from './FormItemInputNumberWithLabel.scss';
import { Rule } from 'antd/lib/form';

type TFormItemInputWithLabelProps = {
    label: string;
    placeholder?: string;
    initialValue: number | string | undefined | Date;
    name: string | number | (string | number)[];
    rules?: Rule[];
    dependencies?: (string | number)[];
    shouldUpdate?: (prevValue, curValue) => boolean;
    onChange?: (value: number ) => void;
};

export const FormItemInputNumberWithLabel = (props: TFormItemInputWithLabelProps) => {
    const { label, placeholder, initialValue, name, rules, dependencies, shouldUpdate, onChange } = props;

    return (
        <Form.Item
            label={label}
            initialValue={initialValue}
            name={name}
            className={theme.formItem}
            rules={rules}
            dependencies={dependencies}
            shouldUpdate={shouldUpdate}
        >
            <InputNumber
                placeholder={placeholder}
                onChange={onChange}
                parser={(value) => Math.round(Number(value))}
            />
        </Form.Item>
    );
};
