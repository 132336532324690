import { CURRENT_USER_PROFILE_REQUEST_SUCCESS } from '../actionsTypes/profile.actionTypes';
import { CustomMap } from '../utils/map';
import { TReducer } from '../utils/types';
import { TCurrentUserProfile, TUserProfileState } from './userProfile.reducer.types';
import { profileAclsMap } from './utils/useProfile.utils';

const initial: TUserProfileState = new CustomMap();

export const userProfileReducer: TReducer<TUserProfileState> = (state = initial, action) => {
    switch (action.type) {
        case CURRENT_USER_PROFILE_REQUEST_SUCCESS: {
            const {
                payload: { serverId, presetId, profileAcls },
            } = action;
            const profile: TCurrentUserProfile = {
                modelTypeAcls: profileAclsMap(profileAcls.modelTypeAcls),
                objectTypeAcls: profileAclsMap(profileAcls.objectTypeAcls),
                symbolAcls: profileAclsMap(profileAcls.symbolAcls),
                edgeTypeAcls: profileAclsMap(profileAcls.edgeTypeAcls),
                attributeTypeAcls: profileAclsMap(profileAcls.attributeTypeAcls),
            };

            const clonState = state.clone();
            clonState.set({ presetId, serverId }, profile);

            return clonState;
        }

        default:
            return state;
    }
};
