import { List } from 'antd';
import React, { FC, useRef } from 'react';
import { instancesBPMMxGraphMap } from '../../../../../mxgraph/bpm-mxgraph-instance-map';
import { MxCell } from '../../../../../mxgraph/mxgraph';
import { ModelType, NodeId, ObjectModelConnections } from '../../../../../serverapi/api';
import messages from './NavigatorEdgeTab.messages';
import { useIntl } from 'react-intl';
import theme from './NavigatorEdgeTab.scss';
import NavigatorEdgeTabListItem from './NavigatorEdgeTabListItem.component';
import { useSelector, useDispatch } from 'react-redux';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { EditorMode } from '../../../../../models/editorMode';
import { TreeSelectors } from '../../../../../selectors/tree.selectors';
import { compareNodeIds } from '../../../../../utils/nodeId.utils';
import { ModelTypeSelectors } from '../../../../../selectors/modelType.selectors';
import { loadModelById } from '../../../../../actions/loadModel.actions';
import { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import { getExternalEdgeConnections } from '../../../../../services/bll/NavigatorEdgePropertyBll';
import { ObjectDefinitionSelectors } from '../../../../../selectors/objectDefinition.selectors';

type TNavigatorEdgeTab = {
    cellId?: string;
    graphId?: NodeId;
    nodeId?: NodeId;
};

type TModelTypes = {
    [id: string]: ModelType;
};

const NavigatorEdgeTab: FC<TNavigatorEdgeTab> = (props) => {
    const { graphId, cellId, nodeId } = props;
    const intl = useIntl();
    const unknownText = intl.formatMessage(messages.unknown);
    const dispatch = useDispatch();

    const graph = graphId ? instancesBPMMxGraphMap.get(graphId) : null;
    const presetId: string = useSelector(TreeSelectors.presetById(graph?.id || nodeId));
    const activeScheme = useSelector(TabsSelectors.getActiveTab);
    const prevNodeId = useRef<NodeId | null>(null);
    const prevCellId = useRef('');

    const nodeIdForSelector = nodeId || { id: '', repositoryId: '', serverId: '' };
    const objectDefinition: ObjectDefinitionImpl | undefined = useSelector(
        ObjectDefinitionSelectors.byId(nodeIdForSelector),
    );

    const serverIdForSelector = graph?.id.serverId || nodeIdForSelector.serverId;
    const modelTypes: TModelTypes =
        useSelector(ModelTypeSelectors.byServerIdPresetId(serverIdForSelector, presetId)).byId || [];

    const handleOpenModelById = (id: string) => {
        dispatch(loadModelById({ ...nodeId!, id }));
    };

    const renderOtherModelObjectConnections = (objectModelConnections: ObjectModelConnections[]) => {
        const objectConnectionsData = getExternalEdgeConnections(objectModelConnections, modelTypes, unknownText);

        return objectConnectionsData.map((data) => (
            <List
                key={data.modelId}
                header={<b data-test="connection-window_model-name">{data.modelName}:</b>}
                dataSource={data.connections}
                renderItem={(connection) => (
                    <List.Item>
                        <div>
                            <div data-test="connection-window_edge">
                                {`${intl.formatMessage(messages.edge)}: `}
                                <span className={theme.link} onClick={() => handleOpenModelById(data.modelId)}>
                                    {connection.edgeTypeName}
                                </span>
                                {connection.isOutgoingEdge
                                    ? ` (${intl.formatMessage(messages.source)})`
                                    : ` (${intl.formatMessage(messages.target)})`}
                            </div>
                            <div data-test="connection-window_object-name">
                                {`${intl.formatMessage(messages.object)}: `}
                                <span className={theme.link} onClick={() => handleOpenModelById(data.modelId)}>
                                    {connection.connectedObjectName}
                                </span>
                                {` (${connection.objectTypeName})`}
                            </div>
                        </div>
                    </List.Item>
                )}
                className={`${theme.list} ${theme.header}`}
            />
        ));
    };

    const isObjectSelectedInGraphAndNavigator =
        !compareNodeIds(prevNodeId.current, nodeId) && prevCellId.current === cellId;

    if (!graphId || !cellId || !graph || isObjectSelectedInGraphAndNavigator) {
        if (objectDefinition) {
            const objectModelConnections = objectDefinition.objectModelConnections || [];

            return <>{renderOtherModelObjectConnections(objectModelConnections)}</>;
        }

        return null;
    }

    prevNodeId.current = nodeIdForSelector;
    prevCellId.current = cellId;

    const isEditMode = activeScheme?.mode !== EditorMode.Edit;
    const focusObj = graph.model.getCell(cellId);
    const edgesList: MxCell[] = focusObj?.edges || [];

    const filteredObjectModelConnections: ObjectModelConnections[] =
        objectDefinition?.objectModelConnections?.filter((objectModel) => objectModel.modelId !== graphId.id) || [];

    return (
        <>
            {edgesList.length ? (
                <List
                    header={
                        <b data-test="connection-window_model-name">{intl.formatMessage(messages.currentModel)}:</b>
                    }
                    dataSource={edgesList}
                    renderItem={(edge: MxCell) => (
                        <NavigatorEdgeTabListItem
                            presetId={presetId}
                            isEditMode={isEditMode}
                            focusObj={focusObj}
                            edge={edge}
                            graph={graph}
                        />
                    )}
                    className={theme.list}
                />
            ) : (
                ''
            )}
            {renderOtherModelObjectConnections(filteredObjectModelConnections)}
        </>
    );
};
const withMemo = React.memo(NavigatorEdgeTab);

export { withMemo as NavigatorEdgeTab };
