import React from 'react';
import theme from './ScriptDashboard.scss';
import { ScriptExecutionsTable } from '../ScriptExecutionTable/ScriptExecutionsTable.component';
import { TScriptDashboardProps } from './ScriptDashboard.types';

export const ScriptDashboard = (props: TScriptDashboardProps) => {
    const { content, server, setReportDownloadDate, downloadFile } = props;

    return (
        <section className={theme.container}>
            <ScriptExecutionsTable
                data={content}
                setDownloadDate={(scriptExecutionId) => {
                    setReportDownloadDate(server.id, scriptExecutionId);
                }}
                downloadFile={downloadFile}
            />
        </section>
    );
};
