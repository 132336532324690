import { put, select, takeEvery } from 'redux-saga/effects';
import {
    SELECT_OBJECT_DEFINITION_DIALOG_PREPARE_DATA_REQUEST,
    SELECT_OBJECT_DIALOG_SUBMIT_DATA,
} from '../actionsTypes/selectObjectDefinitionDialog.actionTypes';
import {
    TSelectObjectDialogPrepareDataRequestAction,
    TSelectObjectDialogSubmitData,
} from '../actions/selectObjectDefinitionDialog.actions.types';
import { editorSetObjectToCell, reuseObjectName } from '../actions/editor.actions';
import { closeDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { getActiveGraph } from '../selectors/editor.selectors';
import { TreeSelectors } from '../selectors/tree.selectors';
import { ObjectTypeSelectors } from '../selectors/objectType.selectors';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { SymbolSelectors } from '../selectors/symbol.selectors';
import { checkDeletableDuplicateObj } from './utils';
import { NodeId, ObjectType, Symbol } from '../serverapi/api';
import { MxCell } from '../mxgraph/mxgraph';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { selectObjectDialogPrepareDataSuccess } from '../actions/selectObjectDefinitionDialog.actions';
import { nodeService } from '../services/NodeService';
import { SelectObjectDefinitionDialogSelectors } from '../selectors/selectObjectDefinitionDialog.selectors';
import { TSelectObjectDefinitionDialogState } from '../reducers/selectObjectDefinitionDialog.reducer.types';
import { SelectedStrategy } from '../models/selectObjectDialog.types';
import { TNodePath } from '../modules/ObjectPropertiesDialog/components/ObjectPropertiesDialog.types';

function* hanldlePrepareData({ payload: { instances, cellId } }: TSelectObjectDialogPrepareDataRequestAction) {
    if (!instances.length) return;

    const [objectDef] = instances;
    const { objectTypeId, nodeId } = objectDef;

    const activeGraphId: NodeId = yield select(getActiveGraph);
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
    const presetId: string = yield select(TreeSelectors.presetById(nodeId));
    const currentState: TSelectObjectDefinitionDialogState = yield select(
        SelectObjectDefinitionDialogSelectors.selectObjectDefinitionDialogState,
    );
    const objectType: ObjectType | undefined = yield select(
        ObjectTypeSelectors.byId({ objectTypeId: objectTypeId || '', presetId, serverId: nodeId.serverId }),
    );

    const newState: TSelectObjectDefinitionDialogState = { ...currentState };

    if (objectType) newState.objectTypeTitle = objectType.name || '';

    if (graph && cellId) {
        const cell: MxCell = graph.getModel().getCell(cellId);
        const oldObjectNodeId: NodeId = { ...nodeId, id: cell.value.objectDefinitionId };
        const oldObject: ObjectDefinitionImpl | undefined = yield select(
            ObjectDefinitionSelectors.byId(oldObjectNodeId),
        );
        if (oldObject) newState.oldName = oldObject.name || '';
        const symbol: Symbol | undefined = yield select(
            SymbolSelectors.byId({ id: oldObject?.idSymbol || '', serverId: nodeId.serverId }, presetId),
        );
        newState.isObjectDeletable = yield checkDeletableDuplicateObj(oldObject, symbol, graph);
    }

    for (const instance of instances) {
        const nodePath: TNodePath = yield nodeService().loadPathFromNodeId(instance.nodeId);
        if (nodePath?.path) newState.pathsMap.set(instance.nodeId.id, nodePath.path);
    }

    yield put(selectObjectDialogPrepareDataSuccess(newState));
}

function* handleSubmit({ payload: { data, cellId, object, isDeletable } }: TSelectObjectDialogSubmitData) {
    switch (data.strategy) {
        case SelectedStrategy.setNewName:
            yield put(reuseObjectName(cellId, object));
            break;
        case SelectedStrategy.useExisting:
            yield put(editorSetObjectToCell(cellId, object, isDeletable));
            break;
        default:
            break;
    }

    yield put(closeDialog(DialogType.SELECT_OBJECT_DIALOG));
}

export function* selectObjectSagaInit() {
    yield takeEvery(SELECT_OBJECT_DIALOG_SUBMIT_DATA, handleSubmit);
    yield takeEvery(SELECT_OBJECT_DEFINITION_DIALOG_PREPARE_DATA_REQUEST, hanldlePrepareData);
}
