import React, { FC, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import theme from './ApprovalDialog.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import messages from './ApprovalDialog.messages';
import { Button, Modal, Tabs } from 'antd';
import {
    ApprovalDTOStatus,
    ApprovalStageDTOStatus,
    ApprovalStageDTOType,
    TApprovalDialogProps,
} from './ApprovalDialog.types';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { closeDialog, openDialog } from '@/actions/dialogs.actions';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { FormInstance } from 'antd/lib';
import { GeneralSettingsTab } from './GeneralSettingsTab.component';
import icAddApprovalStage from '../../resources/icons/ic-add-approval-stage.svg';
import icRemoveApprovalStage from '../../resources/icons/ic-remove-approval-stage.svg';
import icMoveUpApprovalStage from '../../resources/icons/ic-approval-arrow-up.svg';
import icMoveDownApprovalStage from '../../resources/icons/ic-approval-arrow-down.svg';
import { Icon } from '../UIKit';
import { editApproval, submitApproval } from '@/actions/approval.actions';
import { cloneDeep } from 'lodash';
import { StageSettingsTab } from './StageSettingsTab.component';
import { ApprovalDTO, ApprovalDTOStatusEnum } from '@/serverapi/api';
import classNames from 'classnames';

const GENERAL_SETTINGS = 'GeneralSettings';
const APPROVAL_STAGES = 'ApprovalStages';

export const ApprovalDialog: FC<TApprovalDialogProps> = (props) => {
    const { isDraft, isCreateMode } = props;
    const dispatch = useDispatch();
    const [tabName, setTabName] = useState<string>(GENERAL_SETTINGS);
    const intl = useIntl();
    const approval: ApprovalDTO = useSelector(ApprovalSelectors.getEditingApproval);
    const formRef = useRef<FormInstance>(null);

    const onCancel = () => dispatch(closeDialog(DialogType.APPROVAL));

    const validateStages = (): boolean => {
        return !!(approval.stages && approval.stages.length > 0);
    };
    const enableStagesErrorDisplay = () => {
        if (!approval.stages) {
            dispatch(
                editApproval({
                    approval: {
                        ...approval,
                        stages: [],
                    },
                }),
            );
        }
    };

    const validateCurrentStageUsers = (): boolean => {
        if (tabName === GENERAL_SETTINGS) return true;

        const currentStage = approval.stages?.find((stage) => stage.id === tabName);
        return !!(currentStage?.approvalUsersDTO && currentStage?.approvalUsersDTO?.length > 0);
    };
    const enableCurrentStageUsersErrorDisplay = () => {
        const currentStage = approval.stages?.find((stage) => stage.id === tabName);

        if (currentStage && !currentStage.approvalUsersDTO) {
            dispatch(
                editApproval({
                    approval: {
                        ...approval,
                        stages: approval.stages?.map((stage) => {
                            return stage.id === tabName
                                ? {
                                      ...stage,
                                      approvalUsersDTO: [],
                                  }
                                : stage;
                        }),
                    },
                }),
            );
        }
    };

    const onSubmit = (status: ApprovalDTOStatusEnum | undefined) => {
        if (formRef.current) {
            formRef.current
                .validateFields()
                .then(() => {
                    if (!validateStages()) {
                        enableStagesErrorDisplay();
                        return;
                    }

                    if (!validateCurrentStageUsers()) {
                        enableCurrentStageUsersErrorDisplay();
                        return;
                    }

                    dispatch(
                        submitApproval({
                            approval: {
                                ...approval,
                                name: approval.name.trim(),
                                stages: approval.stages?.map((stage) => {
                                    return { ...stage, name: stage.name.trim() };
                                }),
                                status,
                            },
                            isDraft,
                            isCreateMode,
                        }),
                    );
                })
                .catch((e) => {
                    enableStagesErrorDisplay();
                    enableCurrentStageUsersErrorDisplay();
                });
        }
    };
    const onTabChange = (activeKey: string) => {
        if (activeKey === APPROVAL_STAGES) return;

        if (formRef.current) {
            formRef.current
                .validateFields()
                .then(() => {
                    if (!validateCurrentStageUsers()) {
                        enableCurrentStageUsersErrorDisplay();
                        return;
                    }

                    setTabName(activeKey);
                })
                .catch((e) => {
                    enableCurrentStageUsersErrorDisplay();
                });
        }
    };

    const addNewStage = () => {
        if (!validateCurrentStageUsers()) {
            enableCurrentStageUsersErrorDisplay();
            return;
        }

        const approvalStagesClone = cloneDeep(approval.stages) || [];
        const id = uuid();
        approvalStagesClone.push({
            id,
            name: '',
            type: ApprovalStageDTOType.PARALLEL,
            changeVoteAllowed: true,
            rateOfSuccess: 100,
        });
        dispatch(
            editApproval({
                approval: { ...approval, stages: approvalStagesClone },
            }),
        );
        setTabName(id);
    };

    const onStageAdd = () => {
        if (formRef.current) {
            formRef.current
                .validateFields()
                .then(() => {
                    addNewStage();
                })
                .catch((e) => {
                    if (e?.errorFields?.length === 0) {
                        addNewStage();
                    }

                    enableCurrentStageUsersErrorDisplay();
                });
        }
    };

    const onStageRemove = (id: string) => {
        const deletingStage = approval.stages?.find((stage) => stage.id === id);
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    if (id === tabName) {
                        setTabName(GENERAL_SETTINGS);
                    }

                    dispatch(
                        editApproval({
                            approval: { ...approval, stages: approval.stages?.filter((stage) => stage.id !== id) },
                        }),
                    );
                },
                title: intl.formatMessage(messages.deletingApprovalStage),
                question: intl.formatMessage(messages.deleteStageConfirmationDescription, {
                    name: deletingStage?.name || '',
                }),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    const onStageMove = (indexOfClickedStage, isDirectionUp: boolean) => {
        if (!approval.stages) return;

        const approvalStagesClone = cloneDeep(approval.stages) || [];
        const indexOfNeighbourStage = isDirectionUp ? indexOfClickedStage - 1 : indexOfClickedStage + 1;
        approvalStagesClone[indexOfClickedStage] = approval.stages[indexOfNeighbourStage];
        approvalStagesClone[indexOfNeighbourStage] = approval.stages[indexOfClickedStage];

        dispatch(
            editApproval({
                approval: { ...approval, stages: approvalStagesClone },
            }),
        );
    };

    const footer = [
        <Button key="cancel" size="large" onClick={onCancel}>
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button
            className={classNames({
                [theme.hidden]: !isCreateMode,
            })}
            key="draft"
            size="large"
            onClick={() => onSubmit(ApprovalDTOStatus.DRAFT)}
        >
            {intl.formatMessage(messages.saveAsDraft)}
        </Button>,
        <Button
            key="ok"
            size="large"
            type="primary"
            onClick={() => {
                const submitStatus = isCreateMode ? ApprovalDTOStatus.IN_PROCESS : approval.status;
                onSubmit(submitStatus);
            }}
        >
            {isCreateMode ? intl.formatMessage(messages.createAndBegin) : intl.formatMessage(messages.save)}
        </Button>,
    ];

    return (
        <Modal
            open={props.visible}
            width={900}
            onCancel={onCancel}
            title={
                isCreateMode
                    ? intl.formatMessage(messages.creatingNewApproval)
                    : intl.formatMessage(messages.editApproval)
            }
            footer={footer}
            className={theme.approvalDialog}
        >
            <Tabs tabPosition="left" className={theme.tabs} activeKey={tabName} onChange={onTabChange}>
                <Tabs.TabPane tab={intl.formatMessage(messages.generalSettings)} key={GENERAL_SETTINGS}>
                    <GeneralSettingsTab generalFormRef={formRef} isCreateMode={isCreateMode} />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={
                        <div className={theme.stagesTabContainer}>
                            <div className={theme.stagesTabPane}>{intl.formatMessage(messages.approvalStages)}</div>
                            {approval.status === ApprovalDTOStatus.APPROVED ||
                            approval.status === ApprovalDTOStatus.NOT_APPROVED ? null : (
                                <button className={theme.addApprovalStageBtn} onClick={onStageAdd}>
                                    <Icon spriteSymbol={icAddApprovalStage} className={theme.approvalCreateIcon} />
                                </button>
                            )}
                        </div>
                    }
                    key={APPROVAL_STAGES}
                ></Tabs.TabPane>

                {approval.stages?.map((stage, index, arr) => {
                    const isNotMovable = !isCreateMode && stage.status;
                    const isNotMovableUp = isNotMovable || (!isNotMovable && index > 0 && arr[index - 1].status);
                    const isNotMovableDown =
                        isNotMovable || (!isNotMovable && index < arr.length - 1 && arr[index + 1].status);
                    const isNotDeletable =
                        !isCreateMode &&
                        (stage.status === ApprovalStageDTOStatus.APPROVED ||
                            stage.status === ApprovalStageDTOStatus.NOT_APPROVED);

                    return (
                        <Tabs.TabPane
                            tab={
                                <div className={theme.stage}>
                                    <div title={stage.name} className={theme.stageTabPane}>
                                        {stage.name || intl.formatMessage(messages.newStage)}
                                    </div>
                                    <div className={theme.flex}>
                                        {index > 0 && !isNotMovableUp ? (
                                            <button
                                                className={theme.actionApprovalStageBtn}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onStageMove(index, true);
                                                }}
                                            >
                                                <Icon
                                                    spriteSymbol={icMoveUpApprovalStage}
                                                    className={theme.approvalCreateIcon}
                                                />
                                            </button>
                                        ) : null}

                                        {index < arr.length - 1 && !isNotMovableDown ? (
                                            <button
                                                className={theme.actionApprovalStageBtn}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onStageMove(index, false);
                                                }}
                                            >
                                                <Icon
                                                    spriteSymbol={icMoveDownApprovalStage}
                                                    className={theme.approvalCreateIcon}
                                                />
                                            </button>
                                        ) : null}
                                        {!isNotDeletable ? (
                                            <button
                                                className={theme.actionApprovalStageBtn}
                                                style={{ marginRight: '3px' }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onStageRemove(stage.id);
                                                }}
                                            >
                                                <Icon
                                                    spriteSymbol={icRemoveApprovalStage}
                                                    className={theme.approvalCreateIcon}
                                                />
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            }
                            key={stage.id}
                        >
                            <StageSettingsTab stageFormRef={formRef} stageId={stage.id} isCreateMode={isCreateMode} />
                        </Tabs.TabPane>
                    );
                })}
            </Tabs>
        </Modal>
    );
};
