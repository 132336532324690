export enum EventRunStrategy {
    BEFORE = 'BEFORE',
    AFTER = 'AFTER',
    ERROR = 'ERROR',
    INSTEAD = 'INSTEAD',
}

export enum EventActions {
    READ_TREE_NODE = 'READ_TREE_NODE',
    SAVE_TREE_NODE = 'SAVE_TREE_NODE',
    MOVE_TREE_NODE = 'MOVE_TREE_NODE',
    UNLOCK_TREE_NODE = 'UNLOCK_TREE_NODE',
    DELETE_TREE_NODE = 'DELETE_TREE_NODE',
}
