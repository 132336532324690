import { defineMessages } from 'react-intl';

export default defineMessages({
    homePageName: {
        id: 'HomePageTab.homePageName',
        defaultMessage: 'Главная страница',
    },
    recentTabTitle: {
        id: 'HomePageTab.recentTabTitle',
        defaultMessage: 'Последние',
    },
    todayTimePrefix: {
        id: 'HomePageTab.todayTimePrefix',
        defaultMessage: 'Сегодня',
    },
    creationTitle: {
        id: 'HomePageTab.creationTitle',
        defaultMessage: 'Создать',
    },
    buttonCreationLabel: {
        id: 'HomePageTab.buttonCreationLabel',
        defaultMessage: 'Создать модель',
    },
    yesterdayTimePrefix: {
        id: 'HomePageTab.yesterdayTimePrefix',
        defaultMessage: 'Вчера',
    },
    emptyModelType: {
        id: 'HomePageTab.emptyModelType',
        defaultMessage: 'Тип модели отсутствует',
    },
    needLicense: {
        id: 'HomePageTab.needLicense',
        defaultMessage: 'Для этого действия вам необходима лицензия',
    },
    favorite: {
        id: 'HomePageTab.favorite',
        defaultMessage: 'Избранное',
    },
    deleteFromFavorite: {
        id: 'HomePageTab.deleteFromFavorite',
        defaultMessage: 'Удалить из избранного',
    },
    copyLink: {
        id: 'HomePageTab.copyLink',
        defaultMessage: 'Скопировать ссылку',
    },
    deleted: {
        id: 'HomePageTab.deleted',
        defaultMessage: 'удален',
    },
    search: {
        id: 'HomePageTab.search',
        defaultMessage: 'Поиск',
    },
    add: {
        id: 'HomePageTab.add',
        defaultMessage: 'Добавить',
    },
});
