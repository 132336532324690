import { defineMessages } from 'react-intl';

export default defineMessages({
    closeCurrent: {
        id: 'WorkspaceTabTitleContextMenu.closeCurrent',
        defaultMessage: 'Закрыть',
    },
    closeAnother: {
        id: 'WorkspaceTabTitleContextMenu.closeAnother',
        defaultMessage: 'Закрыть другие вкладки',
    },
    closeLeft: {
        id: 'WorkspaceTabTitleContextMenu.closeLeft',
        defaultMessage: 'Закрыть вкладки слева',
    },
    closeRight: {
        id: 'WorkspaceTabTitleContextMenu.closeRight',
        defaultMessage: 'Закрыть вкладки справа',
    },
    closeAll: {
        id: 'WorkspaceTabTitleContextMenu.closeAll',
        defaultMessage: 'Закрыть все вкладки',
    },
});
