import React, { FC, useState } from 'react';
import { Select } from 'antd';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import theme from './CloneModelDialog.scss';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import messages from './CloneModelDialog.messages';
import { TreeItemType } from '../../Tree/models/tree';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TreeNode } from '../../../models/tree.types';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { withWindowResize } from '../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import { TWithWindowResizeProps } from '../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import { NodeId, NodeTypeEnum } from '../../../serverapi/api';
import { Tree } from '../../Tree/components/Tree/Tree.component';

export enum ModelCloneStrategy {
    USE_EXISTING_DEFINITION_NODES = 'USE_EXISTING_DEFINITION_NODES',
    CREATE_NEW_NODES_IN_MODEL_FOLDER = 'CREATE_NEW_NODES_IN_MODEL_FOLDER',
    CREATE_NEW_NODES_IN_SOURCE_FOLDERS = 'CREATE_NEW_NODES_IN_SOURCE_FOLDERS',
}

type TCopyModelComponentProps = TWithWindowResizeProps &
    WrappedComponentProps & {
        modelName: String;
        type: NodeTypeEnum;

        treeLocation: TreeNode[];
        onClose: () => void;
        onSubmit: (strategy: ModelCloneStrategy, parentNodeIdOfModelCopy?: NodeId) => void;
    };

const cloneModelDialogComponent: FC<TCopyModelComponentProps> = (props) => {
    const [visible] = useState(true);
    const [isSubmitEnabled, setSubminEnabled] = useState(false);
    const [cloneStrategy, setCloneStrategy] = useState(ModelCloneStrategy.USE_EXISTING_DEFINITION_NODES);
    const [parentOfModelCopy, setParentOfModelCopy] = useState<TreeNode>();

    const onSelectPath = (selectedNode: TreeNode) => {
        if (selectedNode.type !== TreeItemType.Server) {
            setParentOfModelCopy(selectedNode);
            setSubminEnabled(true);
        }
    };

    const { intl, onClose, onSubmit, type } = props;

    const onOk = () => {
        if (isSubmitEnabled) {
            onSubmit(cloneStrategy, parentOfModelCopy?.nodeId);
        }
    };

    return (
        <Dialog
            onOk={onOk}
            onCancel={onClose}
            okText={intl.formatMessage(messages.confirm)}
            cancelText={intl.formatMessage(messages.cancel)}
            okButtonProps={{ disabled: !isSubmitEnabled }}
            title={intl.formatMessage(messages.title)}
            open={visible}
            width={DEFAULT_DIALOG_WIDTH}
        >
            <div>
                <p>{`${intl.formatMessage(messages.secondLevelTitle)}: "${props.modelName || ''}".`}</p>
            </div>
            <div className={theme.treeLocation}>
                <p>
                    {intl.formatMessage(messages.pathLabel)}: <b>{parentOfModelCopy?.name}</b>
                </p>
                <Tree
                    data={props.treeLocation}
                    treeName={DialogType.CLONE_MODEL_DIALOG}
                    onSelect={onSelectPath}
                    selectedNodes={parentOfModelCopy ? [parentOfModelCopy] : []}
                    disableContextMenu
                />
            </div>
            {type === TreeItemType.Model && (
                <div>
                    <Select className={theme.selectStrategy} onSelect={setCloneStrategy} defaultValue={cloneStrategy}>
                        {Object.keys(ModelCloneStrategy).map((option) => (
                            <Select.Option key={option} value={option}>
                                {intl.formatMessage(messages[option])}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            )}
        </Dialog>
    );
};

const ResizableModelFormWithIntl = withWindowResize(cloneModelDialogComponent);
const ComponentResizeIntl = injectIntl(ResizableModelFormWithIntl);

export { ComponentResizeIntl as CloneModelDialog };
