import React, { ChangeEvent, FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Col, Form, FormInstance, Row, Select } from 'antd';
import presetCss from '../../Presets.scss';
import css from './ModelTypeGeneralTab.scss';
import messages from '../ModelType.messages';
import { ModelType, ModelTypeGroup, InternationalString } from '../../../../../../../serverapi/api';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../../selectors/locale.selectors';
import {
    changeModelTypeAllowAnyObject,
    changeModelTypeAllowApprovals,
    changeModelTypeAutoCreateEdge,
    changeModelTypeDescription,
    changeModelTypeEnableModel,
    changeModelTypeGroupSymbol,
    changeModelTypeId,
    changeModelTypeName,
    changeModelTypeSynonymsId,
} from '../../../../../../../actions/workspaceTab/editModelTypeWorkspaceTab.actions';
import { LocalesService } from '../../../../../../../services/LocalesService';
import { InputId } from '../../../../../../InputId/InputId.component';
import { InputSynonymsIds } from '../../../../../../InputSynonymsIds/InputSynonymsIds.component';
import { convertStringToArray } from '../../../../../../../utils/convertStringToArray';

type TModelTypeGeneralTabProps = {
    modelType: ModelType;
    modelTypeGroups: ModelTypeGroup[];
    createMode: boolean;
    generalForm: FormInstance<any> | undefined;
    onChangeModelTypeGroup: (group: ModelTypeGroup) => void;
    serverId: string;
};

const ModelTypeGeneralTab: FC<TModelTypeGeneralTabProps> = (props) => {
    const dispatch = useDispatch();
    const { createMode, modelType, modelTypeGroups, generalForm, serverId } = props;
    const { presetId } = modelType;
    const modelTypeId = modelType.id;
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);

    const onChangeModelTypeGroup = (groupId: string) => {
        props.onChangeModelTypeGroup(modelTypeGroups.find((mtg) => mtg.id === groupId)!);
    };

    const onChangeModelTypeName = useCallback((multilingualName: InternationalString) => {
        dispatch(changeModelTypeName({ multilingualName, serverId, presetId, modelTypeId }));
    }, []);

    /**
     * useCallback при монтировании компонента кэширует modelTypeId,
     * и при изменении id записывает изменения в нужную ветку редакса.
     */
    const onChangeModelTypeId = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeModelTypeId({ serverId, presetId, modelTypeId, id: e.target.value.trim() }));
    }, []);

    const onChangeModelTypeSynonymsIds = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
        const synonymsIds: string[] = convertStringToArray(e.target.value);

        dispatch(changeModelTypeSynonymsId({ serverId, presetId, modelTypeId, synonymsIds }));
    }, []);

    const onChangeModelTypeDescription = useCallback((multilingualDescription: InternationalString) => {
        dispatch(changeModelTypeDescription({ serverId, presetId, modelTypeId, multilingualDescription }));
    }, []);

    const onChangeModelTypeGroupSymbol = useCallback((groupSymbol: boolean) => {
        dispatch(changeModelTypeGroupSymbol({ serverId, presetId, modelTypeId, groupSymbol }));
    }, []);

    const onChangeModelTypeEnableModel = useCallback((enableModel: boolean) => {
        dispatch(changeModelTypeEnableModel({ serverId, presetId, modelTypeId, enableModel }));
    }, []);

    const onChangeModelTypeAllowAnyObject = useCallback((allowAnyObject: boolean) => {
        dispatch(changeModelTypeAllowAnyObject({ serverId, presetId, modelTypeId, allowAnyObject }));
    }, []);

    const onChangeModelTypeAllowApprovals = useCallback((allowApprovals: boolean) => {
        dispatch(changeModelTypeAllowApprovals({ serverId, presetId, modelTypeId, allowApprovals }));
    }, []);

    const onChangeModelTypeAutoCreateEdge = useCallback((autoCreateEdge: boolean) => {
        dispatch(changeModelTypeAutoCreateEdge({ serverId, presetId, modelTypeId, autoCreateEdge }));
    }, []);

    return (
        <div data-test="model-type-general-tab" className={presetCss.form}>
            <div className={css.firstRow}>
                <div className={css.name}>
                    <MultiLangInputDialog
                        data-test="model-type-name-input"
                        placeholder={intl.formatMessage(messages.name)}
                        multiLangValue={modelType.multilingualName}
                        onChange={onChangeModelTypeName}
                        label={intl.formatMessage(messages.name)}
                        mainInputName="multilingualName"
                        generalForm={generalForm}
                        required
                        autoFocus
                    />
                </div>
            </div>
            <div className={css.input}>
                <InputId
                    disabled={!createMode}
                    value={modelType.id}
                    required
                    mainInputName="GUID"
                    onChange={onChangeModelTypeId}
                />
            </div>
            <Row align="middle">
                <Col span={12} className={presetCss.selectGroupType}>
                    <Form.Item
                        label={intl.formatMessage(messages.modelTypeGroups)}
                        name="edgeTypeGroup"
                        initialValue={modelType.modelTypeGroup?.id}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            data-test="model-type-group-select"
                            onChange={onChangeModelTypeGroup}
                            value={modelType.modelTypeGroup?.id}
                        >
                            {modelTypeGroups.map((group: ModelTypeGroup) => {
                                return (
                                    <Select.Option key={group.id} value={group.id}>
                                        {LocalesService.internationalStringToString(
                                            group.multilingualName,
                                            currentLocale,
                                        )}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <div className={css.input}>
                <InputSynonymsIds
                    value={modelType.synonymsIds}
                    mainInputName="synonymsIds"
                    onChange={onChangeModelTypeSynonymsIds}
                />
            </div>
            <div className={css.description}>
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.description)}
                    multiLangValue={modelType.multilingualDescription}
                    onChange={onChangeModelTypeDescription}
                    textarea
                    label={intl.formatMessage(messages.description)}
                    mainInputName="multilingualDescription"
                    generalForm={generalForm}
                    data-test="model-type-description-input"
                />
            </div>
            <div className={css.checkBoxContainer}>
                <Checkbox
                    className={css.modelTypeVisibility}
                    checked={modelType.enableModel}
                    onChange={(e: CheckboxChangeEvent) => onChangeModelTypeEnableModel(e.target.checked)}
                >
                    {intl.formatMessage(messages.enableModel)}
                </Checkbox>
            </div>
            <div className={css.checkBoxContainer}>
                <Checkbox
                    checked={modelType.groupSymbol}
                    onChange={(e: CheckboxChangeEvent) => onChangeModelTypeGroupSymbol(e.target.checked)}
                >
                    {intl.formatMessage(messages.groupSymbol)}
                </Checkbox>
            </div>
            <div className={css.checkBoxContainer} data-test="allow-any-object_checkbox">
                <Checkbox
                    checked={modelType.allowAnyObject}
                    onChange={(e: CheckboxChangeEvent) => onChangeModelTypeAllowAnyObject(e.target.checked)}
                >
                    {intl.formatMessage(messages.allowAnyObject)}
                </Checkbox>
            </div>
            <div className={css.checkBoxContainer}>
                <Checkbox
                    checked={modelType.allowApprovals}
                    onChange={(e: CheckboxChangeEvent) => onChangeModelTypeAllowApprovals(e.target.checked)}
                >
                    {intl.formatMessage(messages.allowApprovals)}
                </Checkbox>
            </div>
            <div className={css.checkBoxContainer} data-test="auto-edge-creation_checkbox">
                <Checkbox
                    checked={modelType.autoCreateEdge}
                    onChange={(e: CheckboxChangeEvent) => onChangeModelTypeAutoCreateEdge(e.target.checked)}
                >
                    {intl.formatMessage(messages.autoCreateEdge)}
                </Checkbox>
            </div>
        </div>
    );
};

const withMemo = React.memo(ModelTypeGeneralTab);

export { withMemo as ModelTypeGeneralTab };
