import { differenceBy } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import icAttribute from '../../../../../../resources/icons/ic-attribute.svg';
import { AttributeType, AttributeTypeGroup } from '../../../../../../serverapi/api';
import { DeleteSelected, TabHeader } from '../Header/TabHeader.component';
import { GroupedTypesTable } from '../util/GroupedTypesTable.component';
import { AttributesTabDialog } from './AttributesTabDialog/AddAttributesDialog.component';
import messages from './AttributeType.messages';
import presetMessages from '../../../messages/Presets.messages';
import modelTypeMessages from '../../../../../../models/modelType.messages';
import theme from './AttributeType.scss';
import { Alert, Select } from 'antd';
import { TreeItemType } from '../../../../../Tree/models/tree';
import { attributeTypeFormatMessage } from '../AttributeTypesTab/util/attributeTypesTab.utils';
import { getMetodologyElementsDeleteMessages } from '../util/metodologyElementsDeleteMessages.utils';

type TAttributesTabProps = {
    attributeTypes: AttributeType[];
    attributeTypeGroups?: AttributeTypeGroup[];
    availableAttributeTypes: AttributeType[];
    availableTreeNodeTypesState?: TreeItemType[];
    selectedTreeItemType?: string;
    disabled?: boolean;
};

type TAttributesTabActionProps = {
    addAttributeTypes: (attributeTypes: AttributeType[]) => void;
    deleteAttributeTypes: (attributeTypes: AttributeType[]) => void;
    deleteAttributeTypeGroups: (attributeTypeGroups: AttributeTypeGroup[]) => void;
    setSelectedTreeItemType?: (value: React.SetStateAction<string>) => void;
};

type TAttributesTabFullProps = TAttributesTabProps & TAttributesTabActionProps;

const AttributesTab = (props: TAttributesTabFullProps) => {
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [selectedAttributeTypes, setSelectedAttributeTypes] = useState<AttributeType[]>([]);
    const [selectedAttributeTypeGroups, setSelectedAttributeTypeGroups] = useState<AttributeTypeGroup[]>([]);
    const selected = selectedAttributeTypes.length || selectedAttributeTypeGroups.length;
    const intl = useIntl();

    const attributeTypeGroups: AttributeTypeGroup[] = props.attributeTypes
        .map((at) => at.attributeTypeGroup)
        .reduce((acc: AttributeTypeGroup[], atg: AttributeTypeGroup) => {
            if (!acc.some((attributeTypeGroup) => atg.id === attributeTypeGroup.id)) {
                acc.push(atg);
            }

            return acc;
        }, []);

    const filteredAvailableAttributes = () => differenceBy(props.availableAttributeTypes, props.attributeTypes, 'id');

    const selectOnChange = (type) => {
        if (props.setSelectedTreeItemType) {
            props.setSelectedTreeItemType(type);
        }
    };

    const { deleteMessage: deleteAttributesMessage, deleteGroupsMessage } =
        getMetodologyElementsDeleteMessages({selectedAttributeTypes, selectedAttributeTypeGroups});

    return (
        <div className={theme.container}>
            {props.availableTreeNodeTypesState && (
                <div className={theme.typeSelectContainer}>
                    <div>
                        <span className={theme.typeSelectTitle}>
                            {intl.formatMessage(presetMessages.choosingNodeType)}
                        </span>
                    </div>
                    <div className={theme.typeSelect}>
                        <Select data-test="edit-methodology_select-element"
                            defaultValue={props.selectedTreeItemType}
                            className={theme.typeSelect}
                            onChange={selectOnChange}
                        >
                            {props.availableTreeNodeTypesState.map((type) => (
                                <Select.Option data-test={`edit-methodology_select-element_${intl.formatMessage(modelTypeMessages[type])}`} value={type} key={type}>
                                    {intl.formatMessage(modelTypeMessages[type])}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
            )}
            {dialogVisible && (
                <AttributesTabDialog
                    dialogVisible={dialogVisible}
                    attributeTypes={filteredAvailableAttributes()}
                    onCancel={() => setDialogVisible(false)}
                    onOk={(attributeTypes: AttributeType[]) => {
                        setDialogVisible(false);
                        props.addAttributeTypes(attributeTypes);
                    }}
                />
            )}
            <TabHeader
                buttons={[
                    {
                        name: messages.addAttributes,
                        dataTestId: 'new-attribute_type_button',
                        onAction: () => setDialogVisible(true),
                    },
                    DeleteSelected.build(
                        () => {
                            if (selectedAttributeTypes.length) {
                                setSelectedAttributeTypes([]);
                                props.deleteAttributeTypes(selectedAttributeTypes);
                            }
                            if (selectedAttributeTypeGroups.length) {
                                props.deleteAttributeTypeGroups(selectedAttributeTypeGroups);
                            }
                        },
                        !selected,
                        undefined,
                        intl.formatMessage(messages.deleteAttributesDialogTitle),
                        <Alert message={
                            <>
                                {deleteGroupsMessage}
                                {deleteAttributesMessage}
                            </>
                        } type="warning" />,
                    ),
                ]}
                onSearchChange={setSearchFilter}
            />
            <GroupedTypesTable
                types={props.attributeTypes?.map((type) => ({
                    ...type,
                    groupId: type.attributeTypeGroup?.id,
                    valueType: type.valueType && attributeTypeFormatMessage(type.valueType),
                }))}
                icon={icAttribute}
                onSelectType={setSelectedAttributeTypes as any}
                onSelectGroup={setSelectedAttributeTypeGroups as any}
                typeGroups={attributeTypeGroups}
                searchFilter={searchFilter}
                actionsDisabled={!!props.disabled}
                onDeleteType={(type: AttributeType) => props.deleteAttributeTypes([type])}
                onDeleteGroup={(group: AttributeTypeGroup) => props.deleteAttributeTypeGroups([group])}
                selectedTreeItemType={props.selectedTreeItemType}
                columns={[
                    {
                        title: intl.formatMessage(messages.format),
                        width: 130,
                        dataIndex: 'valueType',
                    },
                ]}
                deleteGroupMessage={messages.deleteGroupMessage}
                deleteElMessage={messages.deleteElMessage}
            />
        </div>
    );
};

const withMemo = React.memo(AttributesTab);

export { withMemo as AttributesTab };
