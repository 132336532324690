import { uniqWith } from 'lodash';
import { ApprovalDTO, NodeId, Node } from '../../../../serverapi/api';
import { compareNodeIds } from '../../../../utils/nodeId.utils';

export const getUniqNodeIdsFromApprovals = (approvals: ApprovalDTO[]): NodeId[] => {
    const nodeIds: NodeId[] = approvals
        .map((approval) => approval.elementIds.map((elementId) => ({ ...approval.id, id: elementId })))
        .flat();
    return uniqWith(nodeIds, compareNodeIds) as NodeId[];
};

export const getElementNodes = (nodes: Node[], elementIds: string[], nodeId: NodeId): Node[] => {
    return nodes.filter((node) =>
        elementIds.find((elementId) => compareNodeIds(node.nodeId, { ...nodeId, id: elementId })),
    );
};
