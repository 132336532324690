import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { workspaceAddTab, workspaceChangeTabTitle } from '../actions/tabs.actions';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTabItemParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import {
    treeFilter,
    treeItemContextMenuAction,
    treeItemExpand,
    treeItemScroll,
    treeItemSelect,
} from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import {
    GET_SEARCH_RESULT,
    OPEN_MODEL_ON_CANVAS,
    OPEN_SEARCH_PATH_DIALOG,
    SET_SEARCH_PATH_ELEMENT,
} from '../actionsTypes/search.actionTypes';
import { setSearchData } from '../actions/search.actions';
import {
    TSetSearchPathElementAction,
    TGetSearchResultAction,
    TOpenModelOnCanvasAction,
} from '../actions/search.actions.types';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { NodeId, PathResponse, SearchResult } from '../serverapi/api';
import { SearchSelectors } from '../selectors/dbSearch.selector';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { TSearchDataListItem } from '../reducers/search.reducer.types';
import { EDITOR_MOVE_TO_DIRECT } from '../actionsTypes/editor.actionTypes';
import { TEditorMoveToDirectAction } from '../actions/editor.actions.types';
import { getFiltersApplied, TreeSelectors } from '../selectors/tree.selectors';
import { treeExpandHandler } from './tree.saga';
import { LocalesService } from '../services/LocalesService';
import messages from '../modules/Tree/messages/TreeContextMenu.messages';
import { openNode } from '../actions/openNode.actions';
import { nodeService } from '../services/NodeService';
import { showNotificationByType } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { ExpandStatus } from '../reducers/tree.reducer.types';
import { NAVIGATOR_STRUCTURE } from '../utils/consts';
import { generateCustomNodeId } from '../utils/nodeId.utils';
import { DialogsSelectors } from '@/selectors/dialogs.selectors';

type TGetSearchPathAction = {
    payload: {
        id: string;
        nodeId: NodeId;
    };
};

function* handleOpenSearchTab({ payload: { nodeId, name, action, type } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.DB_SEARCH) {
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));

        const contentLoadingPageTab: TWorkspaceTab = {
            title: `${intl.formatMessage(messages.dbSearch)} «${name}»`,
            nodeId: generateCustomNodeId(nodeId, 'SearchTab'),
            type: WorkSpaceTabTypes.DB_SEARCH,
            mode: EditorMode.Read,
            params: {
                nodeId,
                name,
                type,
            } as TWorkspaceTabItemParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };

        yield fork(getSearchPath, { payload: { id: nodeId.id, nodeId } });
        yield put(setSearchData({ id: nodeId.id, nodeId, name, type }));
        yield put(workspaceAddTab(contentLoadingPageTab));
    }
}

function* getSearchPath({ payload: { id, nodeId } }: TGetSearchPathAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const { serverId, repositoryId } = nodeId;
    const response: PathResponse = yield TreeDaoService.getNodePath(serverId, nodeId.id, repositoryId);

    yield put(setSearchData({ id, path: `${server.name}/${response.path}` }));
}

function* handleOpenSearchPathDialod() {
    yield put(openDialog(DialogType.SEARCH_PATH));
}

function* handleSetSearchPathElement({ payload: { nodeId, type, name } }: TSetSearchPathElementAction) {
    const id = yield select(SearchSelectors.getId);
    const tab = yield select(TabsSelectors.getActiveTab);
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (tab) {
        yield put(workspaceChangeTabTitle(tab, `${intl.formatMessage(messages.dbSearch)} «${name}»`));
    }

    yield fork(getSearchPath, { payload: { id, nodeId } });
    yield put(setSearchData({ id, nodeId, name, type }));
}

function* handleGetSearchResult({ payload: { searchText } }: TGetSearchResultAction) {
    const serverId = yield select(SearchSelectors.getServerId);
    const id = yield select(SearchSelectors.getId);

    if (serverId) {
        const node: NodeId = yield select(SearchSelectors.getNodeId);
        const server: TServerEntity = yield select(ServerSelectors.server(node.serverId));

        try {
            yield put(setSearchData({ id, isLoading: true }));
            const response: SearchResult[] = yield call(() =>
                server.api.search.search({
                    repositoryId: node.repositoryId,
                    id: node.id,
                    searchText,
                }),
            );
            const searchResult: TSearchDataListItem[] = response.map((item) => ({
                multilingualName: item.multilingualName,
                path: `${item.path}`,
                type: item.nodeType as TreeItemType,
                elementType: item.elementType || '',
                nodeId: {
                    ...item.nodeId,
                    serverId: node.serverId,
                },
                deleted: item.deleted,
            }));

            yield put(setSearchData({ id, searchResult, searchText }));
        } finally {
            yield put(setSearchData({ id, isLoading: false }));
        }
    }
}

function* handleOpenModelOnCanvas({ payload: { nodeId, type, multilingualName } }: TOpenModelOnCanvasAction) {
    if (
        [
            TreeItemType.Model,
            TreeItemType.Matrix,
            TreeItemType.Wiki,
            TreeItemType.Spreadsheet,
            TreeItemType.Kanban,
            TreeItemType.SimulationModeling,
        ].includes(type)
    ) {
        yield put(openNode({ nodeId, type }));
    } else {
        if (type === TreeItemType.Folder) {
            yield put(openNode({ nodeId, type }));
        }

        yield put(
            treeItemContextMenuAction({
                nodeId,
                name: LocalesService.internationalStringToString(multilingualName),
                type,
                action: TreeItemContextMenuAction.PROPERTIES,
            }),
        );
    }
}

export function* handleMoveToDirect({ payload: { treeNode } }: TEditorMoveToDirectAction) {
    const { nodeId } = treeNode;
    const appliedFilters = yield select(getFiltersApplied);
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const lastOpenDialogType: DialogType | undefined = yield select(DialogsSelectors.getLastVisibleDialogType);
    const treeName =
        lastOpenDialogType === DialogType.OBJECT_DECOMPOSITION_CREATE || lastOpenDialogType === DialogType.MODEL_DIALOG
            ? DialogType.MODEL_DIALOG
            : NAVIGATOR_STRUCTURE;
    const isItemOnOpenTree = yield select(TreeSelectors.isItemOnOpenTree(nodeId, treeName));

    if (appliedFilters.includes(treeNode.type)) {
        yield put(treeFilter(true, [treeNode.type]));
    }

    const serverNode: NodeId = { id: server.id, repositoryId: server.id, serverId: server.id };
    const expandStatus = yield select(TreeSelectors.expandStatus(treeName, serverNode));

    if (expandStatus === ExpandStatus.CLOSED) {
        // открываем папку сервера если она еще не открыта
        yield treeExpandHandler(treeItemExpand(serverNode, treeName));
    }

    if (isItemOnOpenTree) {
        yield put(treeItemSelect(treeNode));
        yield put(treeItemScroll(nodeId));

        return;
    }

    const parentElements: { [key: string]: NodeId[] } = yield call(() =>
        server.api.tree.findAllParentElementId({
            body: [nodeId],
        }),
    );

    const node: Node | undefined = yield nodeService()
        .loadNodeFromServer(nodeId)
        .catch(() => undefined);

    if (!node) {
        yield put(showNotificationByType(NotificationType.GOTO_ERROR));

        return;
    }

    const parentList = parentElements[nodeId.id].map((id) => ({ ...id, serverId: nodeId.serverId })).reverse();

    for (const parent of parentList) {
        yield treeExpandHandler(treeItemExpand(parent, treeName));
    }

    yield put(treeItemSelect(treeNode));

    yield put(treeItemScroll(nodeId));
}

export function* searchSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenSearchTab);
    yield takeEvery(OPEN_SEARCH_PATH_DIALOG, handleOpenSearchPathDialod);
    yield takeEvery(SET_SEARCH_PATH_ELEMENT, handleSetSearchPathElement);
    yield takeEvery(GET_SEARCH_RESULT, handleGetSearchResult);
    yield takeEvery(OPEN_MODEL_ON_CANVAS, handleOpenModelOnCanvas);
    yield takeEvery(EDITOR_MOVE_TO_DIRECT, handleMoveToDirect);
}
