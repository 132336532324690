import React from 'react';
import { Form, Input } from 'antd';
import theme from '../ModelDialog.scss';
import { injectIntl } from 'react-intl';
import messages from './ModelForm.messages';
import { TreeItemType } from '../../../../Tree/models/tree';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { TreeNode } from '../../../../../models/tree.types';
import { TModelFormProps } from './ModelForm.types';
import { Tree } from '../../../../Tree/components/Tree/Tree.component';

export class ModelFormComponent extends React.Component<TModelFormProps> {
    onSelectType = (typeItem: TreeNode) => {
        if ([TreeItemType.Model, TreeItemType.Wiki, TreeItemType.Matrix, TreeItemType.Kanban].includes(typeItem.type)) {
            this.props.onSelectTypeId(typeItem.nodeId.id);
        }
    };

    onSelectPath = (selectedNode: TreeNode) => {
        if (selectedNode.type !== TreeItemType.Server) {
            this.props.onParentNodeIdChange(selectedNode);
        }
    };

    render() {
        const {
            intl,
            formRef,
            defaultModelName,
            selectedNode,
            selectedModelType,
            errors,
            openedSelectNode,
            onFormChange,
        } = this.props;
        return (
            <Form ref={formRef} onValuesChange={onFormChange} layout="vertical" className={theme.modelForm}>
                <Form.Item
                    label={intl.formatMessage(messages.modelNameLabel)}
                    className={theme.modelName}
                    name="modelName"
                    initialValue={defaultModelName}
                    rules={[
                        {
                            whitespace: true,
                            required: true,
                            message: intl.formatMessage(messages.modelNameRequiredError),
                        },
                    ]}
                >
                    <Input
                        data-test="model-form_model-name-input"
                        placeholder={intl.formatMessage(messages.modelNamePlaceholder)}
                        autoComplete="off"
                        autoFocus
                    />
                </Form.Item>
                {!openedSelectNode && (
                    <div data-test="model-form_location" className={theme.selectedNodeName}>
                        {intl.formatMessage(messages.pathLabel)}: <b>{selectedNode && selectedNode.name}</b>
                    </div>
                )}
                {openedSelectNode && (
                    <Form.Item
                        data-test="model-form_location"
                        required
                        name="modelLocation"
                        label={
                            <span>
                                {intl.formatMessage(messages.pathLabel)}: <b>{selectedNode && selectedNode.name}</b>
                            </span>
                        }
                    >
                        <div className={theme.treeLocation}>
                            {errors.modelLocation && (
                                <div className="ant-form-item-explain ant-form-item-explain-error">
                                    <div role="alert">{intl.formatMessage(messages.requiredModelLocation)}</div>
                                </div>
                            )}
                            <Tree
                                data={this.props.treeLocation}
                                treeName={DialogType.MODEL_DIALOG}
                                onSelect={this.onSelectPath}
                                selectedNodes={selectedNode ? [selectedNode] : []}
                                disableContextMenu={false}
                            />
                        </div>
                    </Form.Item>
                )}
                <Form.Item
                    data-test="model-form_type"
                    required
                    name="modelType"
                    label={
                        <span>
                            {intl.formatMessage(messages.modelTypeLabel)}:{' '}
                            <b>{selectedModelType && selectedModelType.name}</b>
                        </span>
                    }
                >
                    <div className={theme.treeType}>
                        {errors.modelType && (
                            <div className="ant-form-item-explain ant-form-item-explain-error">
                                <div role="alert">
                                    {this.props.treeTypes.length
                                        ? intl.formatMessage(messages.requiredModelType)
                                        : intl.formatMessage(messages.noAvailableModelTypeError)}
                                </div>
                            </div>
                        )}
                        <Tree
                            data={this.props.treeTypes}
                            treeName={DialogType.MODEL_DIALOG_TYPE}
                            onSelect={this.onSelectType}
                            selectedNodes={selectedModelType ? [selectedModelType] : []}
                        />
                    </div>
                </Form.Item>
            </Form>
        );
    }
}

const ModelFormWithIntl = injectIntl(ModelFormComponent);

export { ModelFormWithIntl as ModelForm };
