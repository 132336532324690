// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ObjectType__hideInNavigator__BOUd0{margin:10px 0}.ObjectType__container__TH4Ar{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:100%;width:100%;max-width:100%;overflow:hidden}.ObjectType__input__Ic1e8{width:300px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/ObjectType.scss"],"names":[],"mappings":"AAAA,oCACI,aAAA,CAGJ,8BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CACA,eAAA,CAGJ,0BACI,WAAA","sourcesContent":[".hideInNavigator {\r\n    margin: 10px 0;\r\n}\r\n\r\n.container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    width: 100%;\r\n    max-width: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.input {\r\n    width: 300px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hideInNavigator": "ObjectType__hideInNavigator__BOUd0",
	"container": "ObjectType__container__TH4Ar",
	"input": "ObjectType__input__Ic1e8"
};
export default ___CSS_LOADER_EXPORT___;
