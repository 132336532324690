import { Locale } from '../modules/Header/components/Header/header.types';

export const PROJECT_SCHEME = 'bpm';

export const LARGE_DIALOG_WIDTH = '612px';

export const DEFAULT_DIALOG_WIDTH = '480px';

export const SMALL_DIALOG_WIDTH = '320px';

export const DEFAULT_ZOOM_VALUES = [200, 150, 125, 100, 75];

export const DEFAULT_LOCALE: Locale = Locale.ru; 

export const LANG = {
    ru: 'Русский',
    en: 'English',
};
