import type { TRestoreDeletedNodeDialogSubmitAction } from '@/actions/restoreDeletedNodeDialog.actions.types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { closeDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { RESTORE_DELETED_NODE_DIALOG_SUBMIT } from '@/actionsTypes/restoreDeletedNodeDialog.actionTypes';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { treeItemRefresh } from '@/actions/tree.actions';
import { showNotificationByType } from '@/actions/notification.actions';
import { NotificationType } from '@/models/notificationType';

function* handlRestoreDeletedNodeDialogSubmit({ payload }: TRestoreDeletedNodeDialogSubmitAction) {
    const {
        nodeId,
        nodeId: { serverId, repositoryId },
        newParentNodeId,
        restoreParent,
        restoreChildren,
        restoreOnlySameDeleteOperation,
        restoreModelElements,
    } = payload;

    try {
        yield call(() =>
            TreeDaoService.restore(nodeId, {
                newParentId: newParentNodeId?.id,
                restoreParent,
                restoreChildren,
                restoreOnlySameDeleteOperation,
                restoreModelElements,
            }),
        );

        yield put(closeDialog(DialogType.RESTORE_DELETED_NODE_DIALOG));
        yield put(treeItemRefresh({ serverId, repositoryId, id: repositoryId }));

        if (newParentNodeId && newParentNodeId.repositoryId !== repositoryId) {
            yield put(
                treeItemRefresh({
                    serverId,
                    repositoryId: newParentNodeId.repositoryId,
                    id: newParentNodeId.repositoryId,
                }),
            );
        }
    } catch (e) {
        yield put(showNotificationByType(NotificationType.RESTORE_NODE));
    }
}

export function* restoreDeletedNodeDialogSaga() {
    yield takeEvery(RESTORE_DELETED_NODE_DIALOG_SUBMIT, handlRestoreDeletedNodeDialogSubmit);
}
