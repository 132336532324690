import { ModelTypes } from '../../models/ModelTypes';
import { SymbolConstants } from '../../models/Symbols';
import { TreeNode } from '../../models/tree.types';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { BPMMxGraph } from '../../mxgraph/bpmgraph';
import { SequenceSymbolTypeId } from '../../mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { ComplexSymbolManager } from '../../mxgraph/ComplexSymbols/ComplexSymbolsManager.class';
import { TCurrentUserProfile } from '../../reducers/userProfile.reducer.types';
import { ObjectType, Symbol } from '../../serverapi/api';
import { ModelTypeUtils } from '../../utils/modelType.utils';

export class MoveElementBLL {
    static getObjectDefinitionSupportedSymbols(objectDefinition: TreeNode, symbols: Symbol[], graph: BPMMxGraph) {
        const supportedSymbols = symbols.filter((s) => s.objectType.includes(objectDefinition.objectTypeId!));

        if (!supportedSymbols.length && graph.modelType?.id === ModelTypes.SEQUENCE_DIAGRAM) {
            const symbol = symbols.find((symbol) => symbol.symbolTypeId === SequenceSymbolTypeId.LIFE_LINE);
            supportedSymbols.push(
                symbol || {
                    ...SymbolConstants.LIFELINE_SYMBOL,
                    objectType: objectDefinition.objectTypeId!,
                },
            );
        }

        return supportedSymbols;
    }

    static getModelSupportedSymbols(model: TreeNode, objectTypes: ObjectType[], symbols: Symbol[]) {
        const validSymbols: Symbol[] = [];

        for (let index = 0; index < symbols.length; index++) {
            const symbol = symbols[index];

            const objectType = objectTypes.find((type) => type.id === symbol.objectType);

            const isValidSymbol =
                !ComplexSymbolManager.isComplexSymbol(symbol) &&
                (objectType?.allowAnyDecomposition ||
                    objectType?.validDecompositionModelTypesIds?.includes(
                        model.modelTypeId! || ModelTypeUtils.getIdByTreeNode(model),
                    ));

            if (isValidSymbol) {
                validSymbols.push(symbol);
            }
        }

        return validSymbols;
    }

    static getGrantedSymbolsByUserProfile(symbols: Symbol[], userProfile?: TCurrentUserProfile) {
        return symbols.filter(
            (s) =>
                userProfile &&
                ![...Object.keys(userProfile.objectTypeAcls), ...Object.keys(userProfile.symbolAcls)].some((key) =>
                    s.objectType.includes(key),
                ),
        );
    }

    static isNodeToObjectMovePermitted(node: TreeNode, objectType: ObjectType): boolean {
        switch (node.type) {
            case TreeItemType.Model:
            case TreeItemType.Wiki:
            case TreeItemType.Matrix:
            case TreeItemType.Kanban:
                return !!(objectType.allowAnyModelType || objectType.validModelTypes?.includes(node.modelTypeId!));
            case TreeItemType.ObjectDefinition:
                return !!(objectType.allowAnyObjectType || objectType.validObjectTypes?.includes(node.objectTypeId!));
            case TreeItemType.Folder:
                return !!(objectType.allowAnyFolderType || objectType.validFolderTypes?.includes(node.folderType!));
            case TreeItemType.Spreadsheet:
            case TreeItemType.File:
            case TreeItemType.SimulationModeling:
                return !!(objectType.allowAnyNodeType || objectType.validNodeTypes?.includes(node.type!));
            default:
                return false;
        }
    }
}
