import { Select } from 'antd';
import React, { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { SymbolConstants } from '../../../../../../../models/Symbols';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { EdgeType, ModelEdgeDefinition, ModelType, ObjectType, Symbol } from '../../../../../../../serverapi/api';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import messages from '../ModelType.messages';
import style from './ModelEditEdgeDialog.component.scss';
import { useIntl } from 'react-intl';

type TEdgesTabProps = {
    modelType: ModelType;
    graph?: MethodologiesGraph;
    availableSymbols: Symbol[];
    availableEdgeType: EdgeType[];
    edgeDefinition?: ModelEdgeDefinition;
};

type TEdgesTabActionProps = {
    onSave: (modelTypeEdges: ModelEdgeDefinition) => void;
    onCancel: () => void;
};

type TModelEdgeDialogFullProps = TEdgesTabProps & TEdgesTabActionProps;

export const ModelEditEdgeDialog: FC<TModelEdgeDialogFullProps> = (props) => {
    const intl = useIntl();

    const { edgeDefinition, modelType, availableEdgeType, availableSymbols, onCancel, graph, onSave } = props;

    const EndpointType = {
        SYMBOL: intl.formatMessage(messages.symbol),
        OBJECT_TYPE: intl.formatMessage(messages.objectType),
        ANY: intl.formatMessage(messages.any),
    };

    const sourceSymbol = edgeDefinition?.source || (availableSymbols && availableSymbols[0]?.id);
    const targetSymbol = edgeDefinition?.destination || (availableSymbols && availableSymbols[0]?.id);
    const sourceObject = edgeDefinition?.sourceObject || (modelType?.objectTypes && modelType.objectTypes[0]?.id);
    const targetObject = edgeDefinition?.destinationObject || (modelType?.objectTypes && modelType.objectTypes[0]?.id);
    const availableEdge = edgeDefinition?.edgeType || (availableEdgeType[0]?.id);

    const [selectedSource, setSelectedSource] = useState<string>(sourceSymbol);
    const [selectedTarget, setSelectedTarget] = useState<string>(targetSymbol);
    const [selectedEdgeType, setSelectedEdgeType] = useState<string>(availableEdge);
    const [selectedSourceObject, setSelectedSourceObject] = useState<string>(sourceObject);
    const [selectedTargetObject, setSelectedTargetObject] = useState<string>(targetObject);

    const [sourceType, setSourceType] = useState<string>(
        edgeDefinition?.sourceObject
            ? EndpointType.OBJECT_TYPE
            : edgeDefinition?.anySourceAllowed
            ? EndpointType.ANY
            : EndpointType.SYMBOL,
    );
    const [targetType, setTargetType] = useState<string>(
        edgeDefinition?.destinationObject
            ? EndpointType.OBJECT_TYPE
            : edgeDefinition?.anyTargetAllowed
            ? EndpointType.ANY
            : EndpointType.SYMBOL,
    );

    const selectSource = (source: string) => {
        setSelectedSource(sourceType === EndpointType.SYMBOL ? source : selectedSource);
        setSelectedSourceObject(sourceType === EndpointType.OBJECT_TYPE ? source : selectedSourceObject);
    };

    const selectDestination = (destination: string) => {
        setSelectedTarget(targetType === EndpointType.SYMBOL ? destination : selectedTarget);
        setSelectedTargetObject(targetType === EndpointType.OBJECT_TYPE ? destination : selectedTargetObject);
    };

    const onChangeSelectEndPoint = (value: string) => {
        setSelectedEdgeType(value);
    };

    const symbols = availableSymbols;
    const edgeTypes = availableEdgeType;
    const getSelectedTargetBySelectedEndpointType = () => {
        return targetType === EndpointType.OBJECT_TYPE ? selectedTargetObject : selectedTarget;
    };
    const getSelectedSourceBySelectedEndpointType = () => {
        return sourceType === EndpointType.OBJECT_TYPE ? selectedSourceObject : selectedSource;
    };
    const renderEndpoint = (selectedTypeValue, selectedEndPointValue, onChangeType, onChangeEndpoint, title) => {
        return (
            <div className={style.selectEndPoint}>
                <span>{title}</span>
                <Select className={style.selectTypeValue} value={selectedTypeValue} onChange={onChangeType}>
                    {Object.keys(EndpointType).map((s) => (
                        <Select.Option key={EndpointType[s]} value={EndpointType[s]}>
                            {EndpointType[s]}
                        </Select.Option>
                    ))}
                </Select>
                <div className={style.selectedEndPointContainer}>
                    {selectedTypeValue !== EndpointType.ANY && (
                        <Select value={selectedEndPointValue} className={style.svgSelect} onChange={onChangeEndpoint}>
                            {(selectedTypeValue === EndpointType.OBJECT_TYPE
                                ? modelType.objectTypes
                                      .reduce((acc: ObjectType[], ot) => {
                                          if (!acc.some((objectType) => objectType.id === ot.id)) {
                                              acc.push(ot);
                                          }
                                          return acc;
                                      }, [])
                                      .map((ot) => {
                                          return {
                                              ...ot,
                                              ...SymbolConstants.ENDPOINT_SYMBOL,
                                              objectType: ot.id,
                                          } as Symbol;
                                      })
                                : symbols
                            ).map((symbol: any) => (
                                <Select.Option
                                    value={
                                        selectedTypeValue === EndpointType.OBJECT_TYPE ? symbol.objectType : symbol.id
                                    }
                                    key={selectedTypeValue === EndpointType.OBJECT_TYPE ? symbol.objectType : symbol.id}
                                >
                                    {SymbolToImageConverterGraph.convertSymbol(symbol, intl, graph)}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </div>
            </div>
        );
    };

    return (
        <Dialog
            width="650px"
            open={true}
            onOk={() => {
                const newEdge: ModelEdgeDefinition = {
                    id: edgeDefinition?.id || uuid(),
                    modelTypeId: modelType.id,
                    edgeType: selectedEdgeType,
                    destination: targetType === EndpointType.SYMBOL ? selectedTarget : undefined,
                    source: sourceType === EndpointType.SYMBOL ? selectedSource : undefined,
                    destinationObject: targetType === EndpointType.OBJECT_TYPE ? selectedTargetObject : undefined,
                    sourceObject: sourceType === EndpointType.OBJECT_TYPE ? selectedSourceObject : undefined,
                    anySourceAllowed: sourceType === EndpointType.ANY,
                    anyTargetAllowed: targetType === EndpointType.ANY,
                };

                onSave(newEdge);
            }}
            onCancel={onCancel}
            title={intl.formatMessage(messages.editingEdge)}
            okText={intl.formatMessage(messages.saveButton)}
            cancelText={intl.formatMessage(messages.cancelButton)}
        >
            <div className={style.edgeTypeDialogSelectRow}>
                {renderEndpoint(
                    sourceType,
                    getSelectedSourceBySelectedEndpointType(),
                    setSourceType,
                    selectSource,
                    intl.formatMessage(messages.source),
                )}
                <div className={style.selectEndPoint}>
                    <span>{intl.formatMessage(messages.edgeType)}</span>
                    <Select
                        defaultValue={selectedEdgeType}
                        className={style.selectTypeValue}
                        onChange={onChangeSelectEndPoint}
                    >
                        {edgeTypes.map((edgeType) => {
                            return (
                                <Select.Option key={edgeType.id} value={edgeType.id}>
                                    <div className={style.selectEdge}>
                                        <div>{edgeType.name}</div>
                                        {SymbolToImageConverterGraph.convertEdge(edgeType, intl, graph)}
                                    </div>
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                {renderEndpoint(
                    targetType,
                    getSelectedTargetBySelectedEndpointType(),
                    setTargetType,
                    selectDestination,
                    intl.formatMessage(messages.target),
                )}
            </div>
        </Dialog>
    );
};
