import { Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../../../../../models/bpm/bpm-model-impl';
import {
    EndObjectInstance,
    ModelNode,
    SimulationRun,
    SimulationRunParameters,
    StartObjectInstance,
} from '../../../../../serverapi/api';
import { EditActionsIcon } from './Icon/EditActionsIcon.component';
import { EventEditorDialogs } from './Dialog/EventEditorDialogs.component';
import theme from '../SimulationModeling.scss';
import messages from '../../../messages/SimulationModeling.messages';

type TEventParametersTableProps = WrappedComponentProps & {
    model?: ModelNode;
    object?: ObjectDefinitionImpl;
    activeSimulation: SimulationRun;
    objectDiagramElement?: ObjectInstanceImpl;
    saveSimulation: (simulation: SimulationRun) => void;
};

const EventParametersTable = (props: TEventParametersTableProps) => {
    const { activeSimulation, saveSimulation, intl } = props;

    const [startEventEditorDialogVisible, setStartEventEditorDialogVisible] = useState<boolean>(false);
    const [endEventEditorDialogVisible, setEndEventEditorDialogVisible] = useState<boolean>(false);
    const [startObject, setStartObject] = useState<StartObjectInstance>();
    const [endObject, setEndObject] = useState<StartObjectInstance>();

    const startColumns: Array<ColumnProps<StartObjectInstance>> = [
        {
            key: 'name',
            render: (record: StartObjectInstance) => {
                return (
                    <div>
                        <div data-test="simulation_start-parameter-name">{`${record.modelName  }/${  record.elementName}`}</div>
                        <div data-test="simulation_start-parameter-limit">{`${record.startEventsMaxRuns} ${intl.formatMessage(messages.time)} (${intl.formatMessage(
                            messages.by,
                        )} ${record.countCreatedEvents} ${intl.formatMessage(messages.every)} ${
                            record.intervalCreatedEvents
                        } ${intl.formatMessage(messages.sec)})`}</div>
                    </div>
                );
            },
        },
        {
            width: 40,
            key: 'edit',
            render: (record: StartObjectInstance) => {
                return (
                    <EditActionsIcon
                        onDelete={() => {
                            const simulation: SimulationRun = {
                                ...activeSimulation,
                                simulationRunParameters: {
                                    ...activeSimulation?.simulationRunParameters,
                                    startObjectInstances:
                                        activeSimulation?.simulationRunParameters?.startObjectInstances.filter(
                                            (o) => record.id?.elementId !== o.id?.elementId,
                                        ),
                                } as SimulationRunParameters,
                            };
                            saveSimulation(simulation);
                        }}
                        onEdit={() => {
                            setStartObject(record);
                            setStartEventEditorDialogVisible(true);
                        }}
                    />
                );
            },
        },
    ];

    const endObjectColumns: Array<ColumnProps<EndObjectInstance>> = [
        {
            key: 'name',
            render: (record: EndObjectInstance) => {
                return (
                    <div>
                        <div data-test="simulation_end-parameter-name">{`${record.modelName  }/${  record.elementName}`}</div>
                        <div data-test="simulation_end-parameter-limit">
                            {record.maxEventsCount
                                ? `${intl.formatMessage(messages.cancelSimulation)} ${
                                    record.maxEventsCount
                                } ${intl.formatMessage(messages.challenges)}`
                                : intl.formatMessage(messages.notAffectsOnSimulation)}
                        </div>
                    </div>
                );
            },
        },
        {
            width: 40,
            key: 'edit',
            render: (record: EndObjectInstance) => {
                return (
                    <EditActionsIcon
                        onDelete={() => {
                            const simulation: SimulationRun = {
                                ...activeSimulation,
                                simulationRunParameters: {
                                    ...activeSimulation?.simulationRunParameters,
                                    endObjectInstances:
                                        activeSimulation?.simulationRunParameters?.endObjectInstances.filter(
                                            (o) => record.id?.elementId !== o.id?.elementId,
                                        ),
                                } as SimulationRunParameters,
                            };
                            saveSimulation(simulation);
                        }}
                        onEdit={() => {
                            setEndObject(record);
                            setEndEventEditorDialogVisible(true);
                        }}
                    />
                );
            },
        },
    ];

    const closeEventEditorDialog = () => {
        setStartEventEditorDialogVisible(false);
        setEndEventEditorDialogVisible(false);
    };

    return (
        <div>
            <EventEditorDialogs
                model={props.model}
                object={props.object}
                startObject={startObject}
                endObject={endObject}
                openedSimulation={activeSimulation}
                objectDiagramElement={props.objectDiagramElement}
                startEventEditorDialogVisible={startEventEditorDialogVisible}
                endEventEditorDialogVisible={endEventEditorDialogVisible}
                onClose={closeEventEditorDialog}
                onSubmit={(simulation: SimulationRun) => {
                    saveSimulation(simulation);
                    closeEventEditorDialog();
                }}
            />
            <div data-test="simulation_start-parameters">
                <div className={theme.addSimulation}>
                    <p>{intl.formatMessage(messages.startParameters)}</p>
                    <PlusOutlined
                        onClick={() => {
                            setStartObject(undefined);
                            setStartEventEditorDialogVisible(true);
                        }}
                    />
                </div>
                <div>
                    <Table
                        rowKey={(record: StartObjectInstance) => `${uuid()}`}
                        columns={startColumns}
                        dataSource={activeSimulation.simulationRunParameters.startObjectInstances}
                        size="small"
                        className={theme.table}
                        showHeader={false}
                        bordered
                        pagination={false}
                    />
                </div>
            </div>
            <div data-test="simulation_end-parameters">
                <div className={theme.addSimulation}>
                    <p>{intl.formatMessage(messages.endParameters)}</p>
                    <PlusOutlined
                        onClick={() => {
                            setEndObject(undefined);
                            setEndEventEditorDialogVisible(true);
                        }}
                    />
                </div>
                <div>
                    <Table
                        rowKey={(record: EndObjectInstance) => `${uuid()}`}
                        columns={endObjectColumns}
                        dataSource={activeSimulation.simulationRunParameters.endObjectInstances}
                        size="small"
                        className={theme.table}
                        showHeader={false}
                        bordered
                        pagination={false}
                    />
                </div>
            </div>
        </div>
    );
};

const IntlComponent = injectIntl(EventParametersTable);

export { IntlComponent as EventParametersTables };
