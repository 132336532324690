import { cloneDeep, isEmpty } from 'lodash';
import { getOrigin, replaceLastSlash } from '../../../src/utils/url.utils';
import { KeycloakSettings, LdapProperties, LdapStatus, MailServerStatus, SystemProperties } from '../../serverapi/api';
import { ApiBundle, apiBundle } from '../api/api-bundle';

export const getDays = (milliseconds: number | undefined) => Math.floor((milliseconds || 0) / 1000 / 60 / 60 / 24);

export const getMilliseconds = (days: number | undefined) => (days || 0) * 1000 * 60 * 60 * 24;

export class SystemPropertiesDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getAllSystemProperties(): Promise<SystemProperties> {
        const api = this.getApi();
        const systemProperties: SystemProperties = await api.systemProperty.all();
        const { passwordMinDurationTime, passwordMaxDurationTime } = systemProperties;

        return {
            ...systemProperties,
            // todo: убрать это преобразование, когда на бэке будет в днях
            passwordMinDurationTime: getDays(passwordMinDurationTime),
            passwordMaxDurationTime: getDays(passwordMaxDurationTime),
        };
    }

    public static async saveSystemProperties(
        properties: SystemProperties,
        serverId: string,
    ): Promise<SystemProperties> {
        const { passwordMinDurationTime, passwordMaxDurationTime } = properties;
        const propertiesCopy = cloneDeep(properties);
        if (isEmpty(propertiesCopy.keycloakSettings)) {
            delete propertiesCopy.keycloakSettings;
        }
        const systemProperties = await apiBundle(serverId).systemProperty.save({
            body: {
                ...propertiesCopy,
                // todo: убрать это преобразование, когда на бэке будет в днях
                passwordMinDurationTime: getMilliseconds(passwordMinDurationTime),
                passwordMaxDurationTime: getMilliseconds(passwordMaxDurationTime),
            },
        });

        return systemProperties;
    }

    public static async checkImageService(properties: SystemProperties | undefined): Promise<string> {
        const api = this.getApi();
        const result = await api.systemProperty.checkImageService({ body: properties });

        return result;
    }

    public static async checkScriptEngine(properties: SystemProperties | undefined): Promise<string> {
        const api = this.getApi();
        const result = await api.systemProperty.checkScriptEngine({ body: properties });

        return result;
    }

    public static async checkLdap(properties: LdapProperties): Promise<LdapStatus> {
        const api = this.getApi();
        const result = await api.systemProperty.checkLdap({ body: properties });

        return result;
    }

    public static async checkMail(properties: SystemProperties | undefined): Promise<MailServerStatus> {
        const api = this.getApi();
        const result: MailServerStatus = await api.systemProperty.checkMail({ body: properties });

        return result;
    }

    public static async checkKeycloak(keycloakSettings: KeycloakSettings): Promise<string> {
        const api = this.getApi();
        const result = await api.systemProperty.checkKeycloak({ body: keycloakSettings });

        return result;
    }
}
