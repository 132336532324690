// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditorFooterButtons__generalButtons__BpCcP{background-color:rgba(17,255,238,0);text-align:right;padding:10px;white-space:nowrap}.EditorFooterButtons__button__AnEh_{border-radius:4px !important}.EditorFooterButtons__button__AnEh_+.EditorFooterButtons__button__AnEh_{margin-left:10px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/Footer/EditorFooterButtons.scss"],"names":[],"mappings":"AAAA,4CACI,mCAAA,CACA,gBAAA,CACA,YAAA,CACA,kBAAA,CAGJ,oCACI,4BAAA,CAEA,wEACI,gBAAA","sourcesContent":[".generalButtons {\r\n    background-color: #11ffee00;\r\n    text-align: right;\r\n    padding: 10px;\r\n    white-space: nowrap;\r\n}\r\n\r\n.button {\r\n    border-radius: 4px !important;\r\n\r\n    + .button {\r\n        margin-left: 10px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generalButtons": "EditorFooterButtons__generalButtons__BpCcP",
	"button": "EditorFooterButtons__button__AnEh_"
};
export default ___CSS_LOADER_EXPORT___;
