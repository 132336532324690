// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableExpandIcon__expandIcon__ujVjS{margin-right:10px;cursor:pointer}.TableExpandIcon__expandIcon__ujVjS .TableExpandIcon__groupIcon__pkCx5{vertical-align:bottom}.TableExpandIcon__togglerIcon__APUV2{width:9px;min-width:9px;margin-right:9px;-webkit-transition:-webkit-transform .2s;transition:-webkit-transform .2s;transition:transform .2s;transition:transform .2s, -webkit-transform .2s;-webkit-transform:translateY(4px) rotate(-90deg) !important;transform:translateY(4px) rotate(-90deg) !important}.TableExpandIcon__togglerIcon_expanded__KRvvt{-webkit-transform:translateY(2px) rotate(0deg) !important;transform:translateY(2px) rotate(0deg) !important}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/TableExpandIcon/TableExpandIcon.scss"],"names":[],"mappings":"AAAA,oCACI,iBAAA,CAMA,cAAA,CAJA,uEACI,qBAAA,CAMR,qCACI,SAAA,CACA,aAAA,CACA,gBAAA,CACA,wCAAA,CAAA,gCAAA,CAAA,wBAAA,CAAA,+CAAA,CACA,2DAAA,CAAA,mDAAA,CAEA,8CACI,yDAAA,CAAA,iDAAA","sourcesContent":[".expandIcon {\r\n    margin-right: 10px;\r\n\r\n    .groupIcon {\r\n        vertical-align: bottom;\r\n    }\r\n\r\n    cursor: pointer;\r\n}\r\n\r\n.togglerIcon {\r\n    width: 9px;\r\n    min-width: 9px;\r\n    margin-right: 9px;\r\n    transition: transform 0.2s;\r\n    transform: translateY(4px) rotate(-90deg) !important;\r\n\r\n    &_expanded {\r\n        transform: translateY(2px) rotate(0deg) !important;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandIcon": "TableExpandIcon__expandIcon__ujVjS",
	"groupIcon": "TableExpandIcon__groupIcon__pkCx5",
	"togglerIcon": "TableExpandIcon__togglerIcon__APUV2",
	"togglerIcon_expanded": "TableExpandIcon__togglerIcon_expanded__KRvvt"
};
export default ___CSS_LOADER_EXPORT___;
