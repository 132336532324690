// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DbSearch-component__container__BLJ6Q{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding:20px;height:100%}.DbSearch-component__listContainer__nJm_4{width:100%;overflow:auto;margin-top:20px;padding-right:20px}.DbSearch-component__listContainer__nJm_4 .ant-list-pagination{text-align:left}", "",{"version":3,"sources":["webpack://./src/modules/Search/DbSearch/DbSearch.component.scss"],"names":[],"mappings":"AAGI,sCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,YANC,CAOD,WAAA,CAGJ,0CACI,UAAA,CACA,aAAA,CACA,eAbC,CAcD,kBAdC,CAiBG,+DACI,eAAA","sourcesContent":["$indent: 20px;\r\n\r\n:local {\r\n    .container {\r\n        display: flex;\r\n        flex-direction: column;\r\n        padding: $indent;\r\n        height: 100%;\r\n    }\r\n\r\n    .listContainer {\r\n        width: 100%;\r\n        overflow: auto;\r\n        margin-top: $indent;\r\n        padding-right: $indent;\r\n\r\n        :global {\r\n            .ant-list-pagination {\r\n                text-align: left;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DbSearch-component__container__BLJ6Q",
	"listContainer": "DbSearch-component__listContainer__nJm_4"
};
export default ___CSS_LOADER_EXPORT___;
