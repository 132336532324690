import { defineMessages } from 'react-intl';

export default defineMessages({
    name: {
        id: 'NewDashboardDialog.name',
        defaultMessage: 'Наименование',
    },

    cancel: {
        id: 'NewDashboardDialog.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'NewDashboardDialog.save',
        defaultMessage: 'Сохранить',
    },
    title: {
        id: 'NewDashboardDialog.Title',
        defaultMessage: 'Создать дашборд',
    },
    requiredField: {
        id: 'NewDashboardDialog.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
});
