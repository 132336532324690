type TImportedImg = {
    content: string;
    id: string;
    node: Node;
    viewBox: string;
};

const imageMimeTypes = {
    cod: 'image/cis-cod',
    jpe: 'image/jpeg',
    jpg: 'image/jpeg',
    jfif: 'image/pipeg',
    svg: 'image/svg+xml',
    tif: 'image/tiff',
    ras: 'image/x-cmu-raster',
    cmx: 'image/x-cmx',
    ico: 'image/x-icon',
    pnm: 'image/x-portable-anymap',
    pbm: 'image/x-portable-bitmap',
    pgm: 'image/x-portable-graymap',
    ppm: 'image/x-portable-pixmap',
    rgb: 'image/x-rgb',
    xbm: 'image/x-xbitmap',
    xpm: 'image/x-xpixmap',
    xwd: 'image/x-xwindowdump',
};

export const getImageMimeType = (extension: string) => {
    if (!extension) return '';

    return imageMimeTypes[extension] || `image/${extension}`;
};

export const convertSvgSymbolToSvgString = (svgSymbol: string, w: number, h: number): string => {
    let svgString = svgSymbol.replace('<symbol', `<svg width="${w}" height="${h}"`);
    svgString = svgString.replace('</symbol>', '</svg>');

    return svgString;
};

export const convertImportedSvgToBase64 = (img: TImportedImg, w: number, h: number): string => {
    const svgString = convertSvgSymbolToSvgString(img.content, w, h);
    const base64 = window.btoa(svgString);

    return base64;
};
