import React, { Dispatch } from 'react';
import { useIntl } from 'react-intl';
import theme from '../CommentElement.scss';
import { Comment } from '../../../../serverapi/api';
import { formatBytes, getFileLink, renderIcon } from '../../utils/commentsUtils';
import messages from '../../messages/CommentsPanel.messages';
import { BrowserDaoService } from '@/services/dao/BrowserDaoService';
import classnames from 'classnames';
import { SizeModeEnum } from '../Comments.types';

type TCommentElementUploadedFilesAreaProps = {
    filesContainerRef: React.RefObject<HTMLDivElement>;
    filesFullSizeMode: SizeModeEnum;
    isEditMode: boolean;
    comment: Comment;
    setFilesFullSizeMode: Dispatch<SizeModeEnum>;
};

export const CommentElementUploadedFilesArea = ({
    filesContainerRef,
    filesFullSizeMode,
    isEditMode,
    comment,
    setFilesFullSizeMode,
}: TCommentElementUploadedFilesAreaProps) => {
    const intl = useIntl();
    const downLoadFile = (repositoryId: string, commentId: string, fileId: string, name: string) => {
        if (fileId) {
            BrowserDaoService.downloadFile(getFileLink(repositoryId, commentId, fileId), { defaultPath: name });
        }
    };

    const isFullSizeOrEditMode = filesFullSizeMode === SizeModeEnum.FULL_SIZE || isEditMode;

    const filesCommentContainerClass = classnames({
        [theme.filesCommentContainerFullSize]: isFullSizeOrEditMode,
        [theme.filesCommentContainer]: !isFullSizeOrEditMode && !!comment.commentFiles?.length
    });

    return (
        <>
            <div
                ref={filesContainerRef}
                className={filesCommentContainerClass}
            >
                {comment.commentFiles?.map((file) => {
                    return (
                        <div
                            className={theme.fileWrapper}
                            key={`comment-element-upload-files-area_${file.id}`}
                            onClick={() =>
                                downLoadFile(comment.commentId.repositoryId, comment.commentId.id, file.id, file.name)
                            }
                        >
                            {renderIcon(file.name)}
                            <span className={theme.fileName}>{`${file.name}`}</span>
                            <span className={theme.fileSize}> {formatBytes(file.size)} </span>
                        </div>
                    );
                })}
            </div>
            {filesFullSizeMode === SizeModeEnum.COLLAPSED && (
                <div className={theme.moreButton} onClick={() => setFilesFullSizeMode(SizeModeEnum.FULL_SIZE)}>
                    {intl.formatMessage(messages.openFiles)}
                </div>
            )}
            {filesFullSizeMode === SizeModeEnum.FULL_SIZE && (
                <div className={theme.moreButton} onClick={() => setFilesFullSizeMode(SizeModeEnum.COLLAPSED)}>
                    {intl.formatMessage(messages.closeFiles)}
                </div>
            )}
        </>
    );
};
