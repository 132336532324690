import React, { ChangeEvent } from 'react';
import newTheme from './Select.scss';
import likeAnAntdTheme from './SelectLikeAnAntd.scss';

type TSearchInputProps = {
    showSearch?: boolean;
    // todo: после перехода на новые стили этот пропс будет не нужен
    originalTheme?: boolean;
    onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    searchValue: string;
};

export const SearchInput = (props: TSearchInputProps) => {
    const { showSearch, originalTheme, onSearch, searchValue } = props;

    const theme = originalTheme ? newTheme : likeAnAntdTheme;

    return (
        <>
            {showSearch && (
                <div className={theme.inputContainer}>
                    <input autoFocus className={theme.input} onChange={onSearch} value={searchValue} type="text" />
                </div>
            )}
        </>
    );
};
