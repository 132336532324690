import { TReducer } from '../utils/types';
import { SYMBOLS_DELETE_REQUEST_SUCCESS, SYMBOLS_REQUEST_SUCCESS } from '../actionsTypes/symbol.actionTypes';
import { CustomMap } from '../utils/map';
import { TSymbolState, TSymbolStateKey } from './symbol.reducer.types';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import { APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM } from '../actionsTypes/app.actionTypes';
import { Symbol } from '../serverapi/api';

const initial: TSymbolState = {
    data: new CustomMap(),
};

export const symbolReducer: TReducer<TSymbolState> = (state = initial, action) => {
    switch (action.type) {
        case APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM: {
            const {
                payload: { definition },
            } = action;

            if (!definition?.symbols?.length) {
                return state;
            }
            
            const map = new CustomMap<TSymbolStateKey, Symbol>();
            definition.symbols.forEach((symbol) => map.set({ serverId: '', presetId: '', symbolId: symbol.id }, symbol));

            return { ...state, data: map };
        }
        case SYMBOLS_DELETE_REQUEST_SUCCESS: {
            const {
                payload: { serverId, symbols, presetId },
            } = action;
            const mapClone = state.data.clone();
            mapClone.deleteAll(symbols.map((s) => ({ presetId, serverId, symbolId: s.id })));

            return { ...state, data: mapClone };
        }
        case SYMBOLS_REQUEST_SUCCESS: {
            const {
                payload: { serverId, data: symbols, presetId },
            } = action;
            const mapClone = state.data.clone();
            symbols.forEach((symbol) => mapClone.set({ serverId, presetId, symbolId: symbol.id }, symbol));

            return { ...state, data: mapClone };
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            const mapClone = state.data.clone();
            mapClone.deleteByPartKey({ serverId, presetId });

            return { ...state, data: mapClone };
        }

        default:
            return state;
    }
};
