// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModelEditEdgeDialog-component__edgeTypeDialogSelectRow__ZcaZP{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.ModelEditEdgeDialog-component__svgSelect__z8MX2{margin-top:15px;width:100%}.ModelEditEdgeDialog-component__svgSelect__z8MX2 .ant-select-selector{min-height:54px}.ModelEditEdgeDialog-component__edgeTypeDialogSelectRow__ZcaZP{display:-webkit-box;display:-ms-flexbox;display:flex}.ModelEditEdgeDialog-component__selectEndPoint__mdat2{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:30%}.ModelEditEdgeDialog-component__selectTypeValue__imlUd{margin-top:15px;height:32px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ModelType/Dialogs/ModelEditEdgeDialog.component.scss"],"names":[],"mappings":"AAAA,+DACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,UAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CAIJ,iDACI,eAAA,CACA,UAAA,CACA,sEACI,eAAA,CAIR,+DACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAGJ,sDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,SAAA,CAGJ,uDACI,eAAA,CACA,WAAA","sourcesContent":[".edgeTypeDialogSelectRow {\r\n    display: flex;\r\n    width: 100%;\r\n    justify-content: space-between;\r\n\r\n}\r\n\r\n.svgSelect {\r\n    margin-top: 15px;\r\n    width: 100%;\r\n    :global(.ant-select-selector) {\r\n        min-height: 54px;\r\n    }\r\n}\r\n\r\n.edgeTypeDialogSelectRow {\r\n    display: flex;\r\n}\r\n\r\n.selectEndPoint {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 30%;\r\n}\r\n\r\n.selectTypeValue {\r\n    margin-top: 15px;\r\n    height: 32px;\r\n}\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edgeTypeDialogSelectRow": "ModelEditEdgeDialog-component__edgeTypeDialogSelectRow__ZcaZP",
	"svgSelect": "ModelEditEdgeDialog-component__svgSelect__z8MX2",
	"selectEndPoint": "ModelEditEdgeDialog-component__selectEndPoint__mdat2",
	"selectTypeValue": "ModelEditEdgeDialog-component__selectTypeValue__imlUd"
};
export default ___CSS_LOADER_EXPORT___;
