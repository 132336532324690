import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { NodeId } from '../serverapi/api';
import { TContextMenuReducerState } from '../reducers/contextMenu.reducer.types';

export const contextMenuState = (state: TRootState) => state.contextMenu;

export namespace ContextMenuSelectors {
    export const getOpenItemParentId = (contextMenuAreaName: string) =>
        createSelector<TRootState, TContextMenuReducerState, NodeId | undefined>(
            contextMenuState,
            (state) => state?.[contextMenuAreaName],
        );

    export const isOpenOnThisItem = (parentId: NodeId, contextMenuAreaName: string) =>
        createSelector<TRootState, NodeId | undefined, boolean>(getOpenItemParentId(contextMenuAreaName), (state) => {
            if (parentId.id && parentId.repositoryId && state?.id && state?.repositoryId) {
                return state?.id === parentId.id && state.repositoryId === parentId.repositoryId;
            }

            return false;
        });
}
