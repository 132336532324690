import React, { ChangeEvent, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Tabs } from 'antd';
import { InternationalString, NodeId } from '../../../../../../serverapi/api';
import { EditorFooterButtons } from '../Footer/EditorFooterButtons.component';
import theme from '../Presets.scss';
import messages from '../../../messages/Presets.messages';
import footerMessages from '../Footer/EditorFooterButtons.messages';
import { useDispatch } from 'react-redux';
import { IWorkspaceTabItemPresetImageGroupEditParams, TWorkspaceTab } from '../../../../../../models/tab.types';
import { submitPresetImageGroup } from '../../../../../../actions/presetSettings/presetImageGroup.actions';
import { MultiLangInputDialog } from '../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { LocalesService } from '../../../../../../services/LocalesService';
import { workspaceRemoveTabByNodeId } from '../../../../../../actions/tabs.actions';
import { InputId } from '../../../../../InputId/InputId.component';
import { createButtonsCompositeDataTestId } from '../util/createButtonsCompositeDataTestId.utils';
import { TBtnProperties } from '../Header/TabHeader.types';
import type { Tab } from 'rc-tabs/lib/interface';

type TTypeGroupEditorProps = {
    tab: TWorkspaceTab;
};
const PresetImageGroupEditor: FC<TTypeGroupEditorProps> = (props) => {
    const tabNodeId: NodeId = props.tab.nodeId;
    const { presetImageGroup, preset, serverNode, createMode } = props.tab
        .params as IWorkspaceTabItemPresetImageGroupEditParams;
    const intl = useIntl();
    const [generalForm] = Form.useForm();

    const dispatch = useDispatch();
    const [typeGroup, setTypeGroup] = useState(presetImageGroup);

    const onChangeName = (value: InternationalString) => {
        setTypeGroup({ ...typeGroup, multilingualName: value });
    };

    const onChangeDescription = (value: InternationalString) => {
        setTypeGroup({ ...typeGroup, multilingualDescription: value });
    };

    const onChangeGuid = (e: ChangeEvent<HTMLInputElement>) => {
        setTypeGroup({ ...typeGroup, id: e.target.value });
    };

    const deleteQuestion: string = intl.formatMessage(messages.deleteGroupImages, {
        name: LocalesService.internationalStringToString(presetImageGroup.multilingualName),
    });

    const buttons: TBtnProperties[] = [
        {
            name: intl.formatMessage(footerMessages.cancel),
            onAction: () => dispatch(workspaceRemoveTabByNodeId(tabNodeId)),
            dataTestId: 'methodology-icon-group-editor_cancel-btn',
        },
        {
            name: intl.formatMessage(footerMessages.save),
            type: 'primary',
            onAction: () => {
                generalForm
                    .validateFields()
                    .then(() => {
                        dispatch(
                            submitPresetImageGroup({
                                serverNode,
                                presetImageGroups: [typeGroup],
                                preset,
                                createMode,
                                tabNodeId,
                            }),
                        );
                    })
                    .catch(() => undefined);
            },
            dataTestId: 'methodology-icon-group-editor_save-btn',
        },
    ];

    const items: Tab[] = [
        {
            label: intl.formatMessage(messages.generalSettings),
            key: 'GeneralSettings',
            children: (
                <div className={theme.tabContent}>
                    <div className={theme.form}>
                        <Form form={generalForm} layout="vertical">
                            <div>
                                <MultiLangInputDialog
                                    placeholder={intl.formatMessage(messages.newGroup)}
                                    multiLangValue={typeGroup.multilingualName}
                                    onChange={onChangeName}
                                    label={intl.formatMessage(messages.name)}
                                    mainInputName="multilingualName"
                                    generalForm={generalForm}
                                    required
                                    data-test="preset-icon-group-name-input"
                                />
                            </div>
                            <div className={theme.guidInput}>
                                <InputId
                                    value={typeGroup.id}
                                    onChange={onChangeGuid}
                                    required
                                    disabled={!createMode}
                                    mainInputName={intl.formatMessage(messages.id)}
                                />
                            </div>
                            <div className={theme.description}>
                                <MultiLangInputDialog
                                    placeholder={intl.formatMessage(messages.description)}
                                    multiLangValue={typeGroup.multilingualDescription}
                                    onChange={onChangeDescription}
                                    textarea
                                    label={intl.formatMessage(messages.description)}
                                    mainInputName="multilingualDescription"
                                    generalForm={generalForm}
                                    data-test="preset-icon-group-description-input"
                                />
                            </div>
                        </Form>
                    </div>
                    <EditorFooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={theme.container}>
            <span className={theme.navigationTitle}>
                {`${preset.name} > ${intl.formatMessage(messages.groupOfPresetImage)} >
                ${LocalesService.internationalStringToString(typeGroup.multilingualName)}`}
            </span>
            <Tabs className={theme.tabs} tabPosition="left" defaultActiveKey="GeneralSettings" items={items} />
        </div>
    );
};

export { PresetImageGroupEditor };
