import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { getIsSuperAdmin, isUserHasAccesse } from '../../../selectors/authorization.selectors';
import messages from './audit.messages';
import { TSideBarMenuItem, SideBarItemsId, TAvailableSideBarItems } from './SafetySettings.types';

const sideBarItems: TSideBarMenuItem[] = [
    { id: SideBarItemsId.ACTIONS_AUDIT, titleId: messages.actionsAudit },
    { id: SideBarItemsId.SESSIONS_LIST, titleId: messages.sessionsList },
    { id: SideBarItemsId.CLEAR_JOURNAL, titleId: messages.clearJournal },
    { id: SideBarItemsId.ACCESS_TOKENS, titleId: messages.accessTokens },
];

export const getAvailableSideBarItems = createSelector<
    TRootState,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    TAvailableSideBarItems
>(
    getIsSuperAdmin,
    isUserHasAccesse('SECURITY_ADMIN'),
    isUserHasAccesse('LOG_READER'),
    isUserHasAccesse('ACCESS_KEY_ADMIN'),
    isUserHasAccesse('SERVER_AUDIT'),
    (
        isSuperAdmin,
        isSecurityAdminAccessEnable,
        isLogReaderAccessEnable,
        isAccessKeyAdmin,
        isServerAuditAccessEnable,
    ) => {
        const availableMenuItems: TSideBarMenuItem[] = sideBarItems.filter((item: TSideBarMenuItem): boolean => {
            if (item.id === SideBarItemsId.CLEAR_JOURNAL) {
                return isSuperAdmin;
            }
            if (item.id === SideBarItemsId.SESSIONS_LIST) {
                return isSecurityAdminAccessEnable || isServerAuditAccessEnable;
            }
            if (item.id === SideBarItemsId.ACTIONS_AUDIT) {
                return isLogReaderAccessEnable || isServerAuditAccessEnable;
            }
            if (item.id === SideBarItemsId.ACCESS_TOKENS) {
                return isAccessKeyAdmin || isServerAuditAccessEnable;
            }

            return true;
        });

        return {
            availableMenuItems,
            sideBarItemDefaultId: availableMenuItems.length ? (availableMenuItems[0].id as SideBarItemsId) : '',
        };
    },
);
