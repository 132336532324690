import { uniqBy } from 'lodash';
import { MxCell } from '../mxgraph';

export const getEdges = (cells: MxCell[]): MxCell[] => {
    const edges = cells.filter((cell) => cell.isEdge());
    const cellEdges = cells
        .filter((cell) => !cell.isEdge())
        .flatMap((cell) => cell.edges)
        .filter((edge) => edge);

    return uniqBy([...cellEdges, ...edges], 'id');
};
