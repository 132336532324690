import React, { useEffect } from 'react';
import { List } from 'antd';
import style from './DbSearch.component.scss';
import { SearchPath } from '../SearchPath/SearchPath.component';
import { DbSearchListItem } from '../DbSearchListItem/DbSearchListItem.component';
import { useDispatch, useSelector } from 'react-redux';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { TSearchDataListItem } from '../../../reducers/search.reducer.types';
import { SearchField } from '../SearchField/SearchField.component';
import { SearchFilter } from '../SearchFilter/SearchFilter.component';
import { resetSearchData } from '../../../actions/search.actions';
import { NodeId } from '../../../serverapi/api';
import { TreeItemType } from '../../Tree/models/tree';
import { LocalesService } from '../../../services/LocalesService';
import { presetMetaDataRequestWithPresetId } from '../../../actions/notation.actions';
import { SearchElementTypeSelectors } from '../selectors/searchElementType.selectors';

export const sortSearchResults = (results: TSearchDataListItem[]) => {
    /**
     * separatedTypes нужен, чтобы придерживаться порядка: БД -> папки -> модели -> вики -> матрицы -> ИМ -> объекты.
     * Если добавится новый тип и не будет внесен в константу - он будет добавлен в конце массива
     */

    const separatedTypes: TreeItemType[] = [
        TreeItemType.Repository,
        TreeItemType.Folder,
        TreeItemType.Model,
        TreeItemType.Wiki,
        TreeItemType.Matrix,
        TreeItemType.SimulationModeling,
        TreeItemType.ObjectDefinition,
    ];

    const fullSeparatedTypes = Array.from(new Set([...separatedTypes, ...Object.values(TreeItemType)]));
    const setSortId = (type: TreeItemType): number => fullSeparatedTypes.indexOf(type);
    const compareAlphabetically = (a: TSearchDataListItem, b: TSearchDataListItem): number => {
        const nameA: string = LocalesService.internationalStringToString(a.multilingualName).toLowerCase();
        const nameB: string = LocalesService.internationalStringToString(b.multilingualName).toLowerCase();

        if (nameA === nameB) return 0;

        return nameA < nameB ? -1 : 1;
    };

    return results?.sort((a: TSearchDataListItem, b: TSearchDataListItem) =>
        a.type !== b.type ? setSortId(a.type) - setSortId(b.type) : compareAlphabetically(a, b),
    );
};

export const DbSearch = () => {
    const dispatch = useDispatch();
    const isLoading: boolean = useSelector(SearchSelectors.getLoadingStatus);
    const nodeId: NodeId = useSelector(SearchSelectors.getNodeId);
    const filterSearchResult: TSearchDataListItem[] = useSelector(SearchElementTypeSelectors.getSearchResult(nodeId));

    useEffect(() => {
        if (nodeId) {
            dispatch(presetMetaDataRequestWithPresetId(nodeId));
        }

        return () => {
            dispatch(resetSearchData(nodeId));
        };
    }, []);

    return (
        <div className={style.container}>
            <SearchPath />
            <SearchField />
            {!!filterSearchResult?.length && <SearchFilter />}
            <div className={style.listContainer}>
                <List
                    itemLayout="vertical"
                    dataSource={filterSearchResult}
                    loading={isLoading}
                    renderItem={(item, index) => (
                        <List.Item>
                            <DbSearchListItem
                                index={index + 1}
                                path={item.path}
                                type={item.type}
                                multilingualName={item.multilingualName}
                                nodeId={item.nodeId}
                                elementType={item.elementType}
                                itemTypeName={item.itemTypeName}
                                deleted={item.deleted}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};
