import { MultiLangInputDialog } from '../../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import messages from '../../FolderTypeTab.messages';
import { Checkbox, Form, FormInstance, Select } from 'antd';
import React, { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import theme from '../../FolderType.scss';
import { InternationalString, PresetImage } from '../../../../../../../../serverapi/api';
import { Icon } from '../../../../../../../UIKit/components/Icon/Icon.component';
import icFolder from '../../../../../../../../resources/icons/ic-tree-folder.svg';
import { useDispatch } from 'react-redux';
import { folderTypeEditorEditValue } from '../../../../../../../../actions/folderTypeEditor.actions';
import { LocalesService } from '../../../../../../../../services/LocalesService';
import { InputId } from '../../../../../../../InputId/InputId.component';
import { InputSynonymsIds } from '../../../../../../../InputSynonymsIds/InputSynonymsIds.component';
import { convertStringToArray } from '../../../../../../../../utils/convertStringToArray';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type TFolderTypeEditorGeneralTabProps = {
    generalForm: FormInstance;
    presetImages: PresetImage[];
    folderTypeId: string;
    createMode: boolean;
    imageId?: string;
    multilingualName?: InternationalString;
    multilingualDescription?: InternationalString;
    synonymsIds?: string[];
    allowApprovals?: boolean;
};

export const FolderTypeEditorGeneralTab: FC<TFolderTypeEditorGeneralTabProps> = ({
    generalForm,
    multilingualName,
    multilingualDescription,
    presetImages,
    imageId,
    folderTypeId,
    createMode,
    synonymsIds,
    allowApprovals,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    function onChangeIcon(graphical: string) {
        dispatch(folderTypeEditorEditValue({ graphical }));
    }

    function changeNameHandler(name: InternationalString) {
        dispatch(folderTypeEditorEditValue({ multilingualName: name }));
    }

    function onChangeFolderAllowApprovals(allowApprovals: boolean) {
        dispatch(folderTypeEditorEditValue({ allowApprovals }));
    }

    function changeFolderTypeIdHandler(id: string) {
        dispatch(folderTypeEditorEditValue({ id }));
    }

    function changeDescriptionHandler(description: InternationalString) {
        dispatch(folderTypeEditorEditValue({ multilingualDescription: description }));
    }

    function changeFolderTypeSynonymsIdsHandler(e: ChangeEvent<HTMLInputElement>): void {
        dispatch(folderTypeEditorEditValue({ synonymsIds: convertStringToArray(e.target.value) }));
    }

    return (
        <section>
            <div className={theme.form}>
                <Form form={generalForm} layout="vertical">
                    <div className={theme.guidInput}>
                        <MultiLangInputDialog
                            placeholder={intl.formatMessage(messages.folderType)}
                            label={intl.formatMessage(messages.name)}
                            multiLangValue={multilingualName}
                            mainInputName="multilingualName"
                            generalForm={generalForm}
                            onChange={changeNameHandler}
                            required
                            data-test='folder-type_name_input'
                        />
                    </div>
                    <div className={theme.guidInput}>
                        <InputId
                            disabled={!createMode}
                            value={folderTypeId}
                            required
                            mainInputName="GUID"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                changeFolderTypeIdHandler(e.target.value.trim());
                            }} />
                        <InputSynonymsIds
                            value={synonymsIds}
                            mainInputName="synonymsIds"
                            onChange={changeFolderTypeSynonymsIdsHandler}
                        />
                    </div>
                    <div className={theme.description}>
                        <MultiLangInputDialog
                            placeholder={intl.formatMessage(messages.description)}
                            label={intl.formatMessage(messages.description)}
                            multiLangValue={multilingualDescription}
                            mainInputName="multilingualDescription"
                            generalForm={generalForm}
                            onChange={changeDescriptionHandler}
                        />
                    </div>
                    <div>
                        <div>{intl.formatMessage(messages.icon)}</div>
                        <Select
                            className={theme.iconSelect}
                            onChange={onChangeIcon}
                            defaultValue={imageId || 'default icon'}
                        >
                            {presetImages.map(({ multilingualName, graphical, id }) => {
                                const FolderIcon = graphical ? (
                                    <img className={theme.iconGeometry} src={graphical} alt="" />
                                ) : (
                                    <Icon spriteSymbol={icFolder} />
                                );

                                return (
                                    <Select.Option key={id} value={id} className={theme.iconOption}>
                                        {FolderIcon}
                                        <span>{multilingualName[LocalesService.getLocale()]}</span>
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                    <Form.Item className = {theme.formItem}>
                        <Checkbox
                            checked={ allowApprovals }
                            onChange={(e: CheckboxChangeEvent) => onChangeFolderAllowApprovals(e.target.checked)}
                        >
                            {intl.formatMessage(messages.allowFoldersApproval)}
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>
        </section>
    );
};
