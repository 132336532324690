import { defineMessages } from 'react-intl';

export default defineMessages({
    dialogTitle: {
        id: 'SelectTreeItemAddFavoriteDialog.dialogTitle',
        defaultMessage: 'Выберите элемент для добавления в избранное',
    },
    confirm: {
        id: 'SelectTreeItemAddFavoriteDialog.confirmButton',
        defaultMessage: 'Добавить',
    },
    cancel: {
        id: 'SelectTreeItemAddFavoriteDialog.cancel',
        defaultMessage: 'Отмена',
    },
    name: {
        id: 'SelectTreeItemAddFavoriteDialog.name',
        defaultMessage: 'Наименование',
    },
});

