import { Checkbox } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import theme from './SymbolEditor.scss';
import messages from './ObjectType.messages';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type TDisableLabelCheckBoxProps = {
    checked: boolean;
    checkboxChanged: (e: CheckboxChangeEvent) => void
}

export const DisableLabelCheckBox = (props: TDisableLabelCheckBoxProps) => {
    const intl = useIntl();
    const {checkboxChanged, checked} = props;

    return (
        <div className={theme.groupRow}>
            <Checkbox onChange={checkboxChanged} checked={checked}>
                <span>{intl.formatMessage(messages.disableLabel)}</span>
            </Checkbox>
        </div>
    );
};
