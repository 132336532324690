import React, { FC, useState } from 'react';
import { Button } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import dialogMessages from './messages/FileImageIdDialog.messages';
import { NodeId } from '../../../../serverapi/api';
import { TTreeEntityState, TreeNode } from '../../../../models/tree.types';
import messages from '../../../ModelDialog/components/ModelDialog/Model/ModelForm.messages';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import theme from '../../../ModelDialog/components/ModelDialog/ModelDialog.scss';
import { TreeItemType } from '../../../Tree/models/tree';
import { TWorkspaceTab } from '../../../../models/tab.types';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceTabSetParams } from '../../../../actions/tabs.actions';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { ServerSelectors } from '../../../../selectors/entities/server.selectors';
import { getFileFolderTree, TreeSelectors } from '../../../../selectors/tree.selectors';
import { WorkSpaceTabTypes } from '../../../Workspace/WorkSpaceTabTypesEnum';
import { AdminToolTreeType } from '../../../AdminTools/data/admintool.types';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { Tree } from '../../../Tree/components/Tree/Tree.component';

type TFileImageIdDialogProps = WrappedComponentProps & {
    visible: boolean;
};

const FileImageIdDialog: FC<TFileImageIdDialogProps> = (props) => {
    const { intl, visible } = props;

    const dispatch = useDispatch();

    const serverId = useSelector(ServerSelectors.connected)[0];
    const fileFolderTree = useSelector(getFileFolderTree);
    const id = WorkSpaceTabTypes.SERVER_SETTINGS;
    const repositoryId = AdminToolTreeType.ADMIN_TOOL_ROOT;
    const workspaceTab = useSelector(
        TabsSelectors.byId({
            serverId,
            id,
            repositoryId,
        }),
    );

    const getTreeNode = (nodeId: NodeId): TTreeEntityState | undefined => useSelector(TreeSelectors.itemById(nodeId));

    const [parentNodeId, setParentNodeId] = useState<NodeId>({
        repositoryId: '',
        id: '',
        serverId: '',
    });
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);

    const selectedNode = parentNodeId && getTreeNode(parentNodeId);

    const onSubmit = (nodeId: NodeId, workspaceTab: TWorkspaceTab) => {
        dispatch(workspaceTabSetParams(nodeId, workspaceTab.params));
        dispatch(closeDialog(DialogType.LOAD_FILE_IMAGE_ID_DIALOG));
    };

    const onCancel = () => {
        dispatch(closeDialog(DialogType.LOAD_FILE_IMAGE_ID_DIALOG));
    };

    const onSubmitFileId = () => {
        const tabSystemProperties = workspaceTab.params.systemProperties;
        const newTabWorkspace: TWorkspaceTab = {
            ...workspaceTab,
            params: {
                ...workspaceTab.params,
                systemProperties: tabSystemProperties,
            },
        };
        onSubmit(workspaceTab.nodeId, newTabWorkspace);
    };

    const onParentNodeIdChange = (parentNodeId: NodeId): void => {
        setParentNodeId(parentNodeId);
    };

    const onSelectPath = (selectedNode: TreeNode) => {
        onParentNodeIdChange(selectedNode.nodeId);

        if (selectedNode.type !== TreeItemType.File) {
            setIsSubmitEnabled(true);
        }
        if (selectedNode.type === TreeItemType.File) {
            setIsSubmitEnabled(false);
        }
    };

    const createFooter = () => {
        return [
            <Button key="cancel" size="large" onClick={onCancel}>
                {intl.formatMessage(dialogMessages.fileImageDialogCancelButton)}
            </Button>,
            <Button key="ok" size="large" type="primary" onClick={onSubmitFileId} disabled={isSubmitEnabled}>
                {intl.formatMessage(dialogMessages.fileImageDialogSelectButton)}
            </Button>,
        ];
    };

    return (
        <Dialog
            open={visible}
            onCancel={onCancel}
            title={intl.formatMessage(dialogMessages.fileImageDialogTitle)}
            onOk={onSubmitFileId}
            footer={createFooter()}
        >
            <div className={theme.treeLocation}>
                <p>
                    {intl.formatMessage(messages.pathLabel)}: <b>{selectedNode && selectedNode.name}</b>
                </p>
                <Tree
                    data={fileFolderTree}
                    treeName={DialogType.MODEL_DIALOG}
                    onSelect={onSelectPath}
                    selectedNodes={selectedNode ? [selectedNode] : []}
                    disableContextMenu
                />
            </div>
        </Dialog>
    );
};

const IntlComponent = injectIntl(FileImageIdDialog);

export { IntlComponent as FileImageIdDialog };
