/* eslint-disable func-names */
import { SymbolType } from '@/models/Symbols';
import { findLast } from 'lodash';
import { BPMMxGraph } from './bpmgraph';
import { MxCellState, MxGuide, MxConstants, MxPoint } from './mxgraph';

// здесь идет переопределение метода isStateIgnored для обработчика MxGuide.prototype.move
// MxGuide.prototype.move срабатывает при перемещении cell из палитры на холст
const originaLIsStateIgnored = MxGuide.prototype.isStateIgnored;
MxGuide.prototype.isStateIgnored = (state: MxCellState) => {
    if (state?.cell?.value?.type === SymbolType.LABEL) {
        return true;
    }

    return originaLIsStateIgnored(state);
};

MxGuide.prototype.setSourceState = function (source: MxCellState) {
    this.sourceState = source;
};

const DEFAULT_GUIDE_TOLERANCE = 10;
const LEDGE_SIZE = 5;
const DEFAULT_DESTINATIONS_GUIDE_COLOR = '#41bcfb';
MxGuide.prototype.tolerance = DEFAULT_GUIDE_TOLERANCE;
MxGuide.prototype.guidesDistance = [];
MxGuide.prototype.distance = true;

MxGuide.prototype.drawLine = function ({ from, to, color = DEFAULT_DESTINATIONS_GUIDE_COLOR, strokewidth = 2 }) {
    const shapePoints = [from, to];
    const shape = this.createGuideShape(false);
    shape.stroke = color;
    shape.strokewidth = strokewidth;
    shape.dialect = this.graph.dialect !== MxConstants.DIALECT_SVG ? MxConstants.DIALECT_VML : MxConstants.DIALECT_SVG;
    shape.init(this.graph.getView().getOverlayPane());
    shape.node.style.visibility = 'visible';
    shape.points = shapePoints;
    this.guidesDistance.push(shape);
    shape.redraw();
};

MxGuide.prototype.drawDistanceGuideForY = function (from: MxCellState, to: MxCellState) {
    const fromX = Math.max(from.x + from.width, to.x + to.width);
    this.drawLine({
        from: new MxPoint(fromX, from.y + from.height),
        to: new MxPoint(fromX, to.y),
        strokewidth: 4,
    });

    this.drawLine({
        from: new MxPoint(Math.min(from.x + from.width, to.x + to.width), from.y + from.height),
        to: new MxPoint(Math.max(from.x + from.width, to.x + to.width) + LEDGE_SIZE, from.y + from.height),
        strokewidth: 1,
    });

    this.drawLine({
        from: new MxPoint(Math.min(from.x + from.width, to.x + to.width), to.y),
        to: new MxPoint(Math.max(from.x + from.width, to.x + to.width) + LEDGE_SIZE, to.y),
        strokewidth: 1,
    });
};

MxGuide.prototype.drawDistanceGuideForX = function (from: MxCellState, to: MxCellState) {
    const fromY = Math.max(from.y + from.height, to.y + to.height);
    this.drawLine({
        from: new MxPoint(from.x + from.width, fromY),
        to: new MxPoint(to.x, fromY),
        strokewidth: 4,
    });

    this.drawLine({
        from: new MxPoint(from.x + from.width, Math.min(from.y + from.height, to.y + to.height)),
        to: new MxPoint(from.x + from.width, Math.max(from.y + from.height, to.y + to.height)),
        strokewidth: 1,
    });

    this.drawLine({
        from: new MxPoint(to.x, Math.min(from.y + from.height, to.y + to.height)),
        to: new MxPoint(to.x, Math.max(from.y + from.height, to.y + to.height)),
        strokewidth: 1,
    });
};

MxGuide.prototype.drawDistanceGuideBy = function ({ coordinateKey = 'y' }: TSnapDestinationKeys) {
    const me = this;

    return function (from: MxCellState, to: MxCellState) {
        // eslint-disable-next-line no-unused-expressions
        coordinateKey === 'y' ? me.drawDistanceGuideForY(from, to) : me.drawDistanceGuideForX(from, to);
    };
};

MxGuide.prototype.clearLines = function () {
    this.guidesDistance.forEach((shape) => {
        shape.destroy();
    });
    this.guidesDistance = [];
};
type TSnapDestinationKeys = {
    coordinateKey: string;
    sizeKey: string;
    alterCoordinateKey: string;
    alterSizeKey: string;
};

const originalMove = MxGuide.prototype.move;
const isSameState = (s1, s2) => {
    return s1.x === s2.x && s1.y === s2.y;
};

export const isOneLineBy =
    ({ alterCoordinateKey, alterSizeKey }: TSnapDestinationKeys) =>
    (s1, s2) =>
        !(
            s1[alterCoordinateKey] + s1[alterSizeKey] <= s2[alterCoordinateKey] ||
            s2[alterCoordinateKey] + s2[alterSizeKey] <= s1[alterCoordinateKey]
        );

const isAvailableStatesBy =
    ({ alterCoordinateKey, alterSizeKey }: TSnapDestinationKeys) =>
    (s1, s2) =>
        isOneLineBy({ alterCoordinateKey, alterSizeKey } as TSnapDestinationKeys)(s1, s2) ||
        (s1[alterCoordinateKey] > s2[alterCoordinateKey] &&
            s1[alterCoordinateKey] + s1[alterSizeKey] <= s2[alterCoordinateKey] + s2[alterSizeKey]) ||
        (s2[alterCoordinateKey] > s1[alterCoordinateKey] &&
            s2[alterCoordinateKey] + s2[alterSizeKey] <= s1[alterCoordinateKey] + s1[alterSizeKey]);

const isOverlapStates = (s1: MxCellState, s2: MxCellState) =>
    s1.x + s1.width > s2.x && s2.x + s2.width > s1.x && s1.y + s1.height > s2.y && s2.y + s2.height > s1.y;

const sortBy =
    ({ coordinateKey, alterCoordinateKey }: TSnapDestinationKeys) =>
    (a, b) =>
        a[coordinateKey] - b[coordinateKey] || a[alterCoordinateKey] - b[alterCoordinateKey];

const sortByAlter =
    ({ coordinateKey, alterCoordinateKey }: TSnapDestinationKeys) =>
    (a, b) =>
        a[alterCoordinateKey] - b[alterCoordinateKey] || a[coordinateKey] - b[coordinateKey];

type TCloserDistance = [state: MxCellState, distanceValue: number] | [];

const getCloserDistanceBy =
    (snapKeys: TSnapDestinationKeys) =>
    (states: MxCellState[] = [], current: MxCellState): TCloserDistance => {
        const sortedStates = states.sort(sortBy(snapKeys));
        const { coordinateKey, sizeKey }: TSnapDestinationKeys = snapKeys;
        const fromEnd = sortedStates.find((state) => state[coordinateKey] > current[coordinateKey] + current[sizeKey]);
        const fromBegin = findLast(
            sortedStates as any,
            (state) => state[coordinateKey] + state[sizeKey] < current[coordinateKey],
        );

        if (!fromBegin && !fromEnd) return [];

        const calculateBegin = (fromBeginStates) =>
            fromBeginStates[coordinateKey] + fromBeginStates[sizeKey] - current[coordinateKey];
        const calculateEnd = (fromEndStates) =>
            current[coordinateKey] + current[sizeKey] - fromEndStates[coordinateKey];

        if (fromEnd && fromBegin) {
            const deltaBegin = calculateBegin(fromBegin);
            const deltaEnd = calculateEnd(fromEnd);

            return Math.abs(deltaBegin) < Math.abs(deltaEnd) ? [fromBegin, deltaBegin] : [fromEnd, deltaEnd];
        }

        return fromBegin ? [fromBegin, calculateBegin(fromBegin)] : [fromEnd, calculateEnd(fromEnd)];
    };

type TDistancesHashMap = {
    [distance: string]: MxCellState[][];
};

export const getStatesInOnelineBy = (snap: TSnapDestinationKeys) => (states: MxCellState[]) => {
    const isOneLine = isOneLineBy(snap);
    const lines: MxCellState[][] = [];
    let line = [states[0]];

    for (let i = 0; i < states.length - 1; i++) {
        const curr = states[i];
        const next = states[i + 1];
        if (isOneLine(curr, next)) {
            line.push(next);
        } else {
            lines.push(line);
            line = [next];
        }
    }
    if (line.length) lines.push(line);

    return lines;
};

const getDistancesBy =
    (snap: TSnapDestinationKeys) =>
    (states: MxCellState[]): TDistancesHashMap => {
        const { coordinateKey, sizeKey } = snap;

        const distance: any = {};
        const sortedStates = states.sort(sortByAlter(snap));
        const groups = getStatesInOnelineBy(snap)(sortedStates);

        groups.forEach((group) => {
            group.sort(sortBy(snap)).forEach((a, i) => {
                if (i > 0) {
                    const prev = group[i - 1];
                    const pairDistance = a[coordinateKey] - (prev[coordinateKey] + prev[sizeKey]);
                    if (pairDistance > 0) {
                        const pair = [{ ...prev }, { ...a }];
                        distance[pairDistance] = distance[pairDistance] || [];
                        distance[pairDistance].push(pair);
                    }
                }
            });
        });

        return distance;
    };

const getMatchedPairsBy =
    (snapKeys: TSnapDestinationKeys) =>
    (
        states: MxCellState[],
        current: MxCellState,
        tolerance: number,
    ): [MxCellState, MxCellState[][] | [], number] | [] => {
        const distances = getDistancesBy(snapKeys)(states);
        const [closerState, closerDistance] = getCloserDistanceBy(snapKeys)(states, current);
        const closerToleranceDistance = closerDistance
            ? parseInt(
                  Object.keys(distances).find(
                      (d) =>
                          parseInt(d, 10) + tolerance > Math.abs(closerDistance) &&
                          parseInt(d, 10) - tolerance < Math.abs(closerDistance),
                  ) || '0',
                  10,
              )
            : 0;

        return closerToleranceDistance && closerState && closerToleranceDistance
            ? [closerState, distances[closerToleranceDistance], closerToleranceDistance]
            : [];
    };

MxGuide.prototype.snapDistanceBy = function (snapKeys: TSnapDestinationKeys) {
    return (bounds, delta) => {
        let newDeltaBy = delta[snapKeys.coordinateKey];
        const currentCellState = { ...bounds, x: bounds.x + delta.x, y: bounds.y + delta.y };
        const isAvailableStates = isAvailableStatesBy(snapKeys);
        const states = this.states.filter(
            (s) =>
                !isSameState(s, bounds) &&
                !isOverlapStates(s, currentCellState as MxCellState) &&
                isAvailableStates(s, currentCellState) &&
                !(this.graph as BPMMxGraph).isLabelCell(s?.cell),
        );

        if (states.length) {
            const { scale } = this.graph.getView();
            const tolerance = (this.getGuideTolerance() || DEFAULT_GUIDE_TOLERANCE) * scale;
            const getMatchedPairs = getMatchedPairsBy(snapKeys);
            const [closerState, matchedPairs, closerDistance] = getMatchedPairs(states, currentCellState, tolerance);

            if (matchedPairs && closerState && closerDistance) {
                const { coordinateKey, sizeKey } = snapKeys;

                newDeltaBy =
                    currentCellState[snapKeys.coordinateKey] > closerState[snapKeys.coordinateKey]
                        ? closerState[coordinateKey] + closerState[sizeKey] + closerDistance - bounds[coordinateKey]
                        : closerState[coordinateKey] -
                          currentCellState[sizeKey] -
                          closerDistance -
                          bounds[coordinateKey];

                const drawDistanceGuide = this.drawDistanceGuideBy(snapKeys);
                matchedPairs.forEach((pair: MxCellState[]) => {
                    const [from, to] = pair;
                    drawDistanceGuide(from, to);
                });

                currentCellState[snapKeys.coordinateKey] = bounds[snapKeys.coordinateKey] + newDeltaBy;
                // eslint-disable-next-line no-unused-expressions
                closerState[snapKeys.coordinateKey] > currentCellState[snapKeys.coordinateKey]
                    ? drawDistanceGuide(currentCellState as MxCellState, closerState)
                    : drawDistanceGuide(closerState, currentCellState as MxCellState);
            }
        }

        const result = delta;
        result[snapKeys.coordinateKey] = newDeltaBy;

        return new MxPoint(result.x, result.y);
    };
};

MxGuide.prototype.move = function (bounds, delta) {
    let result = originalMove.apply(this, arguments);

    this.states = this.states.filter((s) => this.sourceState !== s);
    if (!this.distance) return result;
    this.clearLines();

    const newDeltaY = this.snapDistanceBy({
        coordinateKey: 'y',
        sizeKey: 'height',
        alterCoordinateKey: 'x',
        alterSizeKey: 'width',
    })(bounds, delta);

    result = newDeltaY;

    const newDeltaX = this.snapDistanceBy({
        coordinateKey: 'x',
        sizeKey: 'width',
        alterCoordinateKey: 'y',
        alterSizeKey: 'height',
    })(bounds, delta);

    result = newDeltaX;

    return result;
};

const originalDestroy = MxGuide.prototype.destroy;
MxGuide.prototype.destroy = function () {
    this.clearLines();

    return originalDestroy.apply(this, arguments);
};
