import { defineMessages } from 'react-intl';

export default defineMessages({
    cancel: {
        id: 'FooterButtons.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'FooterButtons.save',
        defaultMessage: 'Сохранить',
    },
    create: {
        id: 'FooterButtons.create',
        defaultMessage: 'Создать',
    },
    remove: {
        id: 'FooterButtons.remove',
        defaultMessage: 'Удалить',
    },
    confirm: {
        id: 'FooterButtons.confirm',
        defaultMessage: 'Сохранить',
    },
    yes: {
        id: 'FooterButtons.yes',
        defaultMessage: 'Да',
    },
    no: {
        id: 'FooterButtons.no',
        defaultMessage: 'Нет',
    },
});
