// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttributesDialog__item__hoXon,.AttributesDialog__selectedItem___sJDQ{min-height:35px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 10px;cursor:pointer;border-bottom:1px solid #ccc}.AttributesDialog__selectedItem___sJDQ{background-color:var(--selectedRowColor)}.AttributesDialog__icon__RJ7go{vertical-align:middle}", "",{"version":3,"sources":["webpack://./src/modules/AttributesDialog/components/AttributesDialog.scss"],"names":[],"mappings":"AAAA,sEACI,eAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,cAAA,CACA,cAAA,CACA,4BAAA,CAGJ,uCACI,wCAAA,CAGJ,+BACI,qBAAA","sourcesContent":[".item, .selectedItem {\r\n    min-height: 35px;\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 0 10px;\r\n    cursor: pointer;\r\n    border-bottom: 1px solid #ccc;\r\n}\r\n\r\n.selectedItem {\r\n    background-color: var(--selectedRowColor);\r\n}\r\n\r\n.icon {\r\n    vertical-align: middle;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "AttributesDialog__item__hoXon",
	"selectedItem": "AttributesDialog__selectedItem___sJDQ",
	"icon": "AttributesDialog__icon__RJ7go"
};
export default ___CSS_LOADER_EXPORT___;
