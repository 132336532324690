import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { PrincipalsSelectors } from './principals.selectors';
import { TEvent } from '../reducers/eventsAccess.reducer.types';
import { PrincipalDescriptor, Node } from '../serverapi/api';
import { TNodesState } from '../reducers/nodes.reducer.types';


export const eventsAccessState = (state: TRootState) => state.eventsAccess;

export const nodesState = (state: TRootState) => state.nodes;

export namespace EventsAccessSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(eventsAccessState, (state) => state.byServerId[serverId]);

    export const getAllEvents = (serverId: string) =>
        createSelector(byServerId(serverId), (state) => {
            return (state && Object.values(state.byId)) || [];
        });

    export const getNodeParams = () => createSelector(eventsAccessState, (state) => state.nodeParams);

    export const getEventNodes = () => createSelector<TRootState, TNodesState, Node[]> (nodesState, (state) => state?.values() || []);

    export const getAllData = (serverId: string) =>
        createSelector(
            getAllEvents(serverId),
            PrincipalsSelectors.getAll,
            getEventNodes(),
            (events: TEvent[], principal: PrincipalDescriptor[], eventNodes) => {
                return events.map((event) => {
                    return {
                        ...event,
                        eventParentNodeElementName:
                            eventNodes?.find((el) => el.nodeId?.id === event?.parentNodeId?.id)?.name ||
                            event?.parentNodeId?.id ||
                            '',
                        eventNodeElementName:
                            eventNodes?.find((el) => el.nodeId.id === event?.nodeId?.id)?.name ||
                            event?.nodeId?.id ||
                            '',
                        eventScriptName: 
                            eventNodes?.find((el) => el.nodeId.id === event?.scriptId)?.name ||
                            event?.scriptId ||
                            '',
                        userName: principal?.find((el) => el.id === event.userId)?.login || event.userId || '',
                        groupName: principal?.find((el) => el.id === event.groupId)?.login || event.groupId || '',
                    };
                });
            },
        );
}
