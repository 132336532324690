import React, { useState, useEffect } from 'react';
import { Select, Button, Table, Input, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { v4 as uuid } from 'uuid';
import { SymbolConstants } from '../../../../../../../models/Symbols';
import { MethodologiesGraph } from '../../../../../../../mxgraph/MethodologiesGraph';
import { EdgeType, ModelEdgeDefinition, ModelType, ObjectType, Symbol } from '../../../../../../../serverapi/api';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { SymbolToImageConverterGraph } from '../../SymbolToImageConverterGraph.component';
import messages from '../ModelType.messages';
import { SearchOutlined } from '@ant-design/icons';
import { OptionData } from 'rc-select/lib/interface';
import presetsTheme from '../../Presets.scss';
import theme from './ModelAddEdgeDialog.component.scss';

type TEdgesTabIntlProps = {
    modelType: ModelType;
    graph?: MethodologiesGraph;
    availableSymbols: Symbol[];
    availableEdgeType: EdgeType[];
    edgeDefinition?: ModelEdgeDefinition;
};

type TEdgesTabIntlActionProps = {
    onSave: (modelTypeEdge: ModelEdgeDefinition[]) => void;
    onCancel: () => void;
};

type TModelEdgeDialogIntlFullProps = TEdgesTabIntlProps & TEdgesTabIntlActionProps & WrappedComponentProps;

enum Step {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

const ModelAddEdgeDialog = (props: TModelEdgeDialogIntlFullProps) => {
    const EndpointType = {
        SYMBOL: props.intl.formatMessage(messages.symbol),
        OBJECT_TYPE: props.intl.formatMessage(messages.objectType),
        ANY: props.intl.formatMessage(messages.any),
    };

    type TEndpointType = typeof EndpointType.SYMBOL | typeof EndpointType.OBJECT_TYPE | typeof EndpointType.ANY;

    const sourceSymbol = props.edgeDefinition?.source || (props.availableSymbols && props.availableSymbols[0]?.id);
    const sourceObject =
        props.edgeDefinition?.sourceObject || (props.modelType?.objectTypes && props.modelType.objectTypes[0]?.id);
    const availableEdge = props.edgeDefinition?.edgeType || (props.availableEdgeType && props.availableEdgeType[0]?.id);
    const symbols = props.availableSymbols;
    const edgeTypes: EdgeType[] = props.availableEdgeType.sort((a, b) => a.name.localeCompare(b.name));

    const [step, setStep] = useState<Step>(Step.ONE);
    const [symbolFilter, setSymbolFilter] = useState<string>('');
    const [selectedAllSymbols, setselectedAllSymbols] = useState<boolean>(false);
    const [newSymbols, setNewSymbols] = useState<Symbol[]>([]);
    const [selectedSource, setSelectedSource] = useState<string>(sourceSymbol);
    const [selectedEdgeType, setSelectedEdgeType] = useState<string>(availableEdge);
    const [isActiveSearchInput, setIsActiveSearchInput] = useState<boolean>(false);
    const [searchedEdgeType, setSearchedEdgeType] = useState<EdgeType[]>(edgeTypes);
    const [selectedSourceObject, setSelectedSourceObject] = useState<string>(sourceObject);
    const [inputValue, setInputValue] = React.useState('');

    const [sourceType, setSourceType] = useState<TEndpointType>(
        props.edgeDefinition?.sourceObject
            ? EndpointType.OBJECT_TYPE
            : props.edgeDefinition?.anySourceAllowed
                ? EndpointType.ANY
                : EndpointType.SYMBOL,
    );
    const [targetType, setTargetType] = useState<TEndpointType>(
        props.edgeDefinition?.destinationObject
            ? EndpointType.OBJECT_TYPE
            : props.edgeDefinition?.anyTargetAllowed
                ? EndpointType.ANY
                : EndpointType.SYMBOL,
    );

    const selectSource = (source: string) => {
        sourceType === EndpointType.SYMBOL && setSelectedSource(source);
        sourceType === EndpointType.OBJECT_TYPE && setSelectedSourceObject(source);
    };

    const getSelectedSourceBySelectedEndpointType = () => {
        return sourceType === EndpointType.OBJECT_TYPE ? selectedSourceObject : selectedSource;
    };

    const renderTitleRowActionButtons = () => {
        return (
            <div>
                <Checkbox
                    onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked) {
                            setNewSymbols([...symbols]);
                        } else {
                            setNewSymbols([]);
                        }
                    }}
                    checked={selectedAllSymbols}
                />
            </div>
        );
    };

    useEffect(() => {
        if (newSymbols.length === symbols.length) {
            setselectedAllSymbols(true);
        } else {
            setselectedAllSymbols(false);
        }
    }, [newSymbols]);

    const renderDialogRowActionButtons = (symbol: Symbol, selectedTypeValue: TEndpointType) => {
        const field = selectedTypeValue === EndpointType.OBJECT_TYPE ? 'objectType' : 'id';

        return (
            <div data-test={`edge-target-type-checkbox-value_${symbol.name}`}>
                <Checkbox
                    onChange={(e: CheckboxChangeEvent) => {
                        if (e.target.checked) {
                            setNewSymbols([...newSymbols, symbol]);
                        } else {
                            setNewSymbols(newSymbols.filter((s) => s[field] !== symbol[field]));
                        }
                    }}
                    checked={Boolean(newSymbols.find((s) => s[field] === symbol[field]))}
                />
            </div>
        );
    };

    const filter = (symbol: Symbol, filter?: string) => {
        if (!filter) {
            return true;
        }

        const filterL = filter.toLowerCase();

        return symbol.name?.toLocaleLowerCase().includes(filterL) || symbol.id?.toLocaleLowerCase().includes(filterL);
    };

    const renderEndpoint = (selectedTypeValue: TEndpointType, onChangeType, title) => {
        const dialogColumns = [
            {
                title: () => renderTitleRowActionButtons(),
                width: 40,
                dataIndex: 'groupId',
                key: 'groupId-dialog',
                render: (value: string, record: Symbol) => renderDialogRowActionButtons(record, selectedTypeValue),
            },
            {
                title: props.intl.formatMessage(messages.symbol),
                dataIndex: 'graphical',
                key: 'graphical-symbol',
                render: (value: string, record: Symbol) =>
                    <div> {SymbolToImageConverterGraph.convertSymbol(record, props.intl, props.graph)} </div>
            },
        ];

        const data =
            selectedTypeValue === EndpointType.OBJECT_TYPE
                ? props.modelType.objectTypes
                    .reduce((acc: ObjectType[], ot) => {
                        if (!acc.some((objectType) => objectType.id === ot.id)) {
                            acc.push(ot);
                        }

                        return acc;
                    }, [])
                    .map((ot) => {
                        return {
                            ...ot,
                            ...SymbolConstants.ENDPOINT_SYMBOL,
                            objectType: ot.id,
                        } as Symbol;
                    })
                : symbols;

        const handleChangeType = (endpointType: TEndpointType) => {
            onChangeType(endpointType);

            setNewSymbols([]);
        };

        return (
            <div className={theme.selectEndPoint}>
                <span>{title}</span>
                <Select data-test="edge-source-type-select" value={selectedTypeValue} onChange={handleChangeType} className={theme.sourceSelect}>
                    {Object.keys(EndpointType).map((s) => (
                        <Select.Option data-test="edge-source-type-select-option" key={EndpointType[s]} value={EndpointType[s]}>
                            {EndpointType[s]}
                        </Select.Option>
                    ))}
                </Select>

                <div className={theme.selectedEndPointContainer}>
                    {selectedTypeValue !== EndpointType.ANY &&
                        (step === Step.ONE ? (
                            <Select
                                value={getSelectedSourceBySelectedEndpointType()}
                                className={theme.svgSelect}
                                popupClassName={theme.svgSelectDropdown}
                                onChange={selectSource}
                                data-test="edge-source-symbol-select"
                            >
                                {data.map((symbol: Symbol) => {
                                    const field = selectedTypeValue === EndpointType.OBJECT_TYPE ? 'objectType' : 'id';

                                    return (
                                        <Select.Option value={symbol[field]} key={symbol[field]} data-test={`edge-source-symbol-select-option_${symbol.name}`}>
                                            {SymbolToImageConverterGraph.convertSymbol(symbol, props.intl, props.graph)}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        ) : (
                            <div className={presetsTheme.creationTitle}>
                                <Input
                                    data-test="edge-source-symbol_input"
                                    suffix={<SearchOutlined />}
                                    className={presetsTheme.search}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setSymbolFilter(e.target.value)
                                    }
                                />
                                <Table
                                    rowKey={({ id, objectType }: Symbol) => `${id + objectType}`}
                                    columns={dialogColumns}
                                    dataSource={data.filter((symbol: Symbol) => filter(symbol, symbolFilter))}
                                    size="small"
                                    scroll={{ y: 250 }}
                                    pagination={false}
                                />
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    const handleNext = () => setStep(step + 1);

    const handlePrev = () => setStep(step - 1);

    const handleOk = () => {
        // назначение объкт или символ
        if (targetType === EndpointType.SYMBOL || targetType === EndpointType.OBJECT_TYPE) {
            const newEdge = newSymbols.map((symbol: Symbol) => {
                return {
                    id: props.edgeDefinition?.id || uuid(),
                    modelTypeId: props.modelType.id,
                    edgeType: selectedEdgeType,
                    destination: targetType === EndpointType.SYMBOL ? symbol.id : undefined,
                    source: sourceType === EndpointType.SYMBOL ? selectedSource : undefined,
                    destinationObject: targetType === EndpointType.OBJECT_TYPE ? symbol.objectType : undefined,
                    sourceObject: sourceType === EndpointType.OBJECT_TYPE ? selectedSourceObject : undefined,
                    anySourceAllowed: sourceType === EndpointType.ANY,
                    anyTargetAllowed: false, // targetType === EndpointType.ANY
                };
            });
            if (!newEdge.length) {
                return;
            }
            props.onSave(newEdge);
        } else {
            // назначение любой
            const newEdge = [
                {
                    id: props.edgeDefinition?.id || uuid(),
                    modelTypeId: props.modelType.id,
                    edgeType: selectedEdgeType,
                    destination: undefined,
                    source: sourceType === EndpointType.SYMBOL ? selectedSource : undefined,
                    destinationObject: undefined,
                    sourceObject: sourceType === EndpointType.OBJECT_TYPE ? selectedSourceObject : undefined,
                    anySourceAllowed: sourceType === EndpointType.ANY,
                    anyTargetAllowed: targetType === EndpointType.ANY,
                },
            ];
            props.onSave(newEdge);
        }

        props.onCancel();
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSearchedEdgeType(
            edgeTypes.filter((edgeType) => edgeType.name.toLowerCase().includes(e.target.value.toLowerCase())),
        );

    return (
        <Dialog
            width="400px"
            visible
            onOk={handleOk}
            okButtonProps={{ disabled: !newSymbols.length && targetType !== EndpointType.ANY }}
            onCancel={props.onCancel}
            title={props.intl.formatMessage(messages.addEdges)}
            okText={props.intl.formatMessage(messages.saveButton)}
            cancelText={props.intl.formatMessage(messages.cancelButton)}
        >
            <div className={theme.edgeTypeDialogSelectRow}>
                {step === Step.ONE && (
                    <div>{renderEndpoint(sourceType, setSourceType, props.intl.formatMessage(messages.source))}</div>
                )}
                <div className={step === Step.TWO ? theme.selectEndPoint : theme.hidden}>
                    <span >{props.intl.formatMessage(messages.edgeType)}</span>
                    <Input
                        value={inputValue}
                        data-test="add-edge-type_step-2_input"
                        suffix={<SearchOutlined />}
                        className={theme.svgInputStepTwo}
                        onFocus={() => setIsActiveSearchInput(true)}
                        // setTimeout чтобы не успевал закрыться при переходе с инпута на селект
                        onBlur={() => setTimeout(() => setIsActiveSearchInput(false), 250)}
                        onChange={(e) => {
                            handleSearch(e);
                            setInputValue(e.target.value);
                        }}
                    />
                    <Select
                        data-test="add-edge-type_step-2_select"
                        defaultValue={selectedEdgeType}
                        className={theme.svgSelectStepTwo}
                        popupClassName={theme.svgSelectDropdown}
                        onChange={(val) => setSelectedEdgeType(val)}
                        onClick={(e) => {
                            const { target } = e;
                            if (target instanceof HTMLElement) {
                                const {classList} = target;
                                if (classList.value !== 'rc-virtual-list-scrollbar-thumb' && classList.value !== 'rc-virtual-list') {
                                    setIsActiveSearchInput(!isActiveSearchInput);
                                }
                            }
                        }}
                        onBlur={(e) => {
                            const { relatedTarget } = e;
                            if (relatedTarget instanceof HTMLElement) {
                                const {classList} = relatedTarget;
                                if (classList.value !== 'ant-input') {
                                    setIsActiveSearchInput(false);
                                }
                            }
                        }}
                        open={isActiveSearchInput}
                        optionFilterProp="children"
                        filterOption={(input: string, option: OptionData) =>
                            option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {searchedEdgeType.map((edgeType) => {
                            return (
                                <Select.Option key={edgeType.id} value={edgeType.id} name={edgeType.name}>
                                    <div className={theme.selectEdge}>
                                        <div data-test={`edge-type-select-option-${edgeType.name}`}>{edgeType.name}</div>
                                        {SymbolToImageConverterGraph.convertEdge(edgeType, props.intl, props.graph)}
                                    </div>
                                </Select.Option>
                            );
                        })}
                    </Select>
                </div>
                {step === Step.THREE && (
                    <div>{renderEndpoint(targetType, setTargetType, props.intl.formatMessage(messages.target))}</div>
                )}
                <div>
                    {step !== Step.ONE && (
                        <Button onClick={handlePrev}>{props.intl.formatMessage(messages.back)}</Button>
                    )}
                    {step !== Step.THREE && (
                        <Button onClick={handleNext}>{props.intl.formatMessage(messages.next)}</Button>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

const ModelEdgeDialogTabIntl = injectIntl(ModelAddEdgeDialog);

export { ModelEdgeDialogTabIntl as ModelAddEdgeDialog };
