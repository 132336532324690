import type { TRootState } from '../../reducers/root.reducer.types';
import type { PresetImage } from '../../serverapi/api';
import { createSelector } from 'reselect';
import { isPlainObject } from 'lodash';
import { INITIAL_PRESET_ICON_SERVER_STATE } from '../../reducers/presetSettings/presetIcon.reducer';

const filterImages = (images: Record<string, PresetImage>) => {
    if (!images) return {};

    const asArray = Object.entries(images);
    const filteredImages = asArray.filter(([, value]) => !!value.id);

    return Object.fromEntries(filteredImages);
};

export const presetImageStateSelector = (state: TRootState) => state.presetIcon;

export namespace PresetImageSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(presetImageStateSelector, (state) => {
            const s = state.byServerId[serverId];

            if (s) {
                return { ...s };
            }

            return { ...INITIAL_PRESET_ICON_SERVER_STATE };
        });

    export const byPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), (state) => ({
            byId: filterImages(state.byPresetId[compositeId.presetId]?.byId),
        }));

    export const listAllByPreset = (serverId: string, presetId: string) =>
        createSelector(
            byPresetId({ serverId, presetId }),
            (imagesById) => (imagesById && Object.values(imagesById.byId)) || [],
        );

    export const byId = (compositeId: { presetImageId: string; presetId: string; serverId: string }) =>
        createSelector(byPresetId({ serverId: compositeId.serverId, presetId: compositeId.presetId }), (state) => {
            return state?.byId && isPlainObject(state.byId) ? state.byId[compositeId.presetImageId] : undefined;
        });
}
