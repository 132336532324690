import { SvgImage } from '../../../mxgraph/bpmgraph';
import { TModelOffset } from '../../../sagas/image.saga.types';
import svgService from '../../../services/SvgServices';
import { getPaperSize } from '../../../utils/print.utils';
import { LengthUnit, Orientation, TPrintOptions, ZoomMode } from '../../../utils/types';
import { DEFAULT_OFFSET } from './PrintDialog.component';

export const getZoomToFit = ([width, height]: number[], options: TPrintOptions): number[] => {
    const { orientation, paperFormat, zoomFitAcross, zoomFitDown } = options;

    const [paperWidth, paperHeight] = getPaperSize(paperFormat, orientation, LengthUnit.PX);
    const printWidth = paperWidth * zoomFitAcross;
    const printHeight = paperHeight * zoomFitDown;

    if (width / height < printWidth / printHeight) {
        return [width * (printHeight / height), printHeight];
    }

    return [printWidth, height * (printWidth / width)];
};

export const getZoomToScale = ([width, height]: number[], zoomScale: number = 100) => {
    const scaleFactor = zoomScale / 100;
    const scaledWidth = width * scaleFactor;
    const scaledHeight = height * scaleFactor;

    return [scaledWidth, scaledHeight];
};

export const resizeSvg = (svgImage: SvgImage, [width, height]: number[]): SvgImage => {
    const { svgString } = svgImage;
    const svg = svgService.parseFromString(svgString);
    svg.documentElement.setAttribute('width', `${width}px`);
    svg.documentElement.setAttribute('height', `${height}px`);

    return svgService.serializeToImage(svg);
};

type TOffsetsWithoutEmptySheets = {
    offsetTopWithoutEmptySheet: number;
    offsetLeftWithoutEmptySheet: number;
};

export const deleteEmptySheets = (
    offset: TModelOffset,
    paperHeight: number,
    paperWidth: number,
): TOffsetsWithoutEmptySheets => ({
    offsetTopWithoutEmptySheet: Math.floor((offset.top / paperHeight - Math.floor(offset.top / paperHeight)) * paperHeight),
    offsetLeftWithoutEmptySheet: Math.floor((offset.left / paperWidth - Math.floor(offset.left / paperWidth)) * paperWidth),
});

export const getPageCount = (
    [width, height]: number[],
    options: TPrintOptions,
    offset: TModelOffset = DEFAULT_OFFSET,
): number[] => {
    let pagesAcross = 0;
    let pagesDown = 0;

    const { zoomMode, zoomFitAcross, zoomFitDown } = options;
    if (zoomMode === ZoomMode.FIT) {
        pagesAcross = zoomFitAcross;
        pagesDown = zoomFitDown;
    } else if (zoomMode === ZoomMode.SCALE) {
        const { orientation, paperFormat } = options;

        const [paperWidth, paperHeight] = getPaperSize(paperFormat, orientation, LengthUnit.PX);
        const { offsetTopWithoutEmptySheet, offsetLeftWithoutEmptySheet } = deleteEmptySheets(
            offset,
            paperHeight,
            paperWidth,
        );

        pagesAcross = Math.ceil((width + offsetLeftWithoutEmptySheet) / paperWidth);
        pagesDown = Math.ceil((height + offsetTopWithoutEmptySheet) / paperHeight);
    }

    return [pagesAcross, pagesDown];
};

export const detectOrientation = ([width, height]: number[], options: TPrintOptions, offset: TModelOffset): Orientation => {
    let orientation;

    const { zoomMode, zoomScale } = options;
    if (zoomMode === ZoomMode.SCALE) {
        const [scaledWidth, scaledHeight] = getZoomToScale([width, height], zoomScale);
        const [portraitPagesAcross, portraitPagesDown] = getPageCount([scaledWidth, scaledHeight], {
            ...options,
            orientation: Orientation.PORTRAIT,
        }, offset);
        const portraitPageAmount = portraitPagesAcross * portraitPagesDown;

        const [landscapePagesAcross, landscapePagesDown] = getPageCount([scaledWidth, scaledHeight], {
            ...options,
            orientation: Orientation.LANDSCAPE,
        }, offset);
        const landscapetPageAmount = landscapePagesAcross * landscapePagesDown;
        
        orientation =
        portraitPageAmount <= landscapetPageAmount 
            ? Orientation.PORTRAIT
            : Orientation.LANDSCAPE;
    } else if (zoomMode === ZoomMode.FIT) {
        const [portraitScaledWidth, portraitScaledHeight] = getZoomToFit([width, height], {
            ...options,
            orientation: Orientation.PORTRAIT,
        });
        const portraitScaledSquare = portraitScaledWidth * portraitScaledHeight;

        const [landscapeScaledWidth, landscapeScaledHeight] = getZoomToFit([width, height], {
            ...options,
            orientation: Orientation.LANDSCAPE,
        });
        const landscapeScaledSquare = landscapeScaledWidth * landscapeScaledHeight;

        orientation = portraitScaledSquare >= landscapeScaledSquare ? Orientation.PORTRAIT : Orientation.LANDSCAPE;
    }

    return orientation;
};

export const getPrintStyle = (options: TPrintOptions): string => {
    const { paperFormat, orientation } = options;

    const [paperWidth, paperHeight] = getPaperSize(paperFormat, orientation);
    const [paperWidthPx, paperHeightPx] = getPaperSize(paperFormat, orientation, LengthUnit.PX);

    return `
        html, body {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
        }

        * {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
        }

        .page {
            width: ${paperWidthPx}px;
            height: ${paperHeightPx}px;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }

        @page {
            size: ${paperWidth}mm ${paperHeight}mm;
            margin: 0px;
        }

        @media print {
            html, body {
                margin: 0 !important;
                padding: 0 !important;
            }

            * {
                box-sizing: border-box;
                -moz-box-sizing: border-box;
            }

            .page {
                margin: 0 !important;
                padding: 0 !important;
                overflow: hidden;
            }
        }
    `;
};

export const getImagesStyle = (canvas: HTMLCanvasElement, options: TPrintOptions, offset: TModelOffset, showOffsets: boolean): string => {
    const { grayscale, orientation, paperFormat } = options;

    const [paperWidth, paperHeight] = getPaperSize(paperFormat, orientation, LengthUnit.PX);
    const [pagesAcross, pagesDown] = getPageCount([canvas.width, canvas.height], options, offset);

    const wrapper = `
        .page-wrapper {
            margin-left: ${Math.floor(offset.left)}px;
            margin-top: ${Math.floor(offset.top)}px;
        }
    `;
    let style = `
        .image {
            filter: ${grayscale ? 'grayscale(100%)' : 'none'};
            width: ${paperWidth}px;
            height: ${paperHeight}px;
            object-fit: none;
            transform: ${showOffsets ? 'scale(0.96, 0.98)' : 'scale(1, 1)'};
            margin: 0;
            padding: 0;
        }

        ${offset.top || offset.left ? wrapper : ''}
    `;

    const { offsetTopWithoutEmptySheet, offsetLeftWithoutEmptySheet } = deleteEmptySheets(
        offset,
        paperHeight,
        paperWidth,
    );

    for (let row = 0; row < pagesDown; row++) {
        for (let column = 0; column < pagesAcross; column++) {
            const verticalOffset = row
                ? `-${row * paperHeight - offsetTopWithoutEmptySheet}`
                : offsetTopWithoutEmptySheet;
            const horizontalOffset = column
                ? `-${column * paperWidth - offsetLeftWithoutEmptySheet}`
                : offsetLeftWithoutEmptySheet;

            style += `
            .image-${row}-${column} {
                  object-position: ${horizontalOffset}px ${verticalOffset}px;
                }
            `;
        }
    }

    return style;
};

export const getBodyHtml = (
    canvas: HTMLCanvasElement,
    options: TPrintOptions,
    offset: TModelOffset,
    imageData: string,
): string => {
    let html = '';

    const [pagesAcross, pagesDown] = getPageCount([canvas.width, canvas.height], options, offset);

    for (let row = 0; row < pagesDown; row++) {
        for (let column = 0; column < pagesAcross; column++) {
            html += `
                <div class="page">
                    <img class="image image-${row}-${column}" src="${imageData}">
                </div>
            `;
        }
    }

    return html;
};

export const getPrintHtml = (
    canvas: HTMLCanvasElement,
    options: TPrintOptions,
    offset: TModelOffset,
    imageData: string,
    showOffsets: boolean = true
): string => {
    return `
        <style>
            ${getPrintStyle(options)}
            ${getImagesStyle(canvas, options, offset, showOffsets)}
        </style>
        ${getBodyHtml(canvas, options, offset, imageData)}
    `;
};
