import * as React from 'react';
import { useSelector } from 'react-redux';
import { TreeItemType } from '../../models/tree';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import TreeItemIconsMap from '../../TreeItemIcons/treeItemIcons';
import theme from './TreeItem.scss';
import imIcon from '../../../../resources/icons/ic-tree-im.svg';
import defaultIcon from '../../../../resources/icons/defaultIcon.svg';
import { adminToolsIcons } from '../../../AdminTools/data/admintool';
import { FolderType, NodeId, NodeTypeEnum, PresetImage } from '../../../../serverapi/api';
import { PresetImageSelectors } from '../../../../selectors/presetSettings/presetImage.selectors';
import { FolderTypeSelectors } from '../../../../selectors/folderType.selectors';
import { TreeSelectors } from '../../../../selectors/tree.selectors';
import { AdminToolTreeType } from '@/modules/AdminTools/data/admintool.types';
import { homePageTabId } from '@/models/home-page';
import { LoadingOutlined } from '@ant-design/icons';

interface ITreeItemIconProps {
    nodeId: NodeId;
    type: TreeItemType | NodeTypeEnum | string;
    folderType?: string;
    isConnected?: boolean;
    specialIcon?: string;
}

const TreeItemIcon = ({ nodeId, type, isConnected, specialIcon, folderType }: ITreeItemIconProps) => {
    const { id, serverId } = nodeId;
    const renderIcon = (id: string, type: string, isConnected = false, specialIcon?: string) => {
        switch (type) {
            case 'MODEL_LOADING': {
                return <LoadingOutlined spin />;
            }
            case TreeItemType.Server: {
                const symbol = isConnected ? TreeItemIconsMap[type] : TreeItemIconsMap.ServerOFF;

                return <Icon className={theme.entityIcon} spriteSymbol={symbol} />;
            }
            case TreeItemType.AdminTool: {
                const symbol =
                    id === homePageTabId.id
                        ? undefined
                        : adminToolsIcons[id] || adminToolsIcons[AdminToolTreeType.USER_MANAGMENT];
                return <Icon className={theme.entityIcon} spriteSymbol={symbol} />;
            }
            case TreeItemType.ObjectDefinition: {
                return specialIcon ? (
                    <svg className={theme.entityIcon}>
                        <image xlinkHref={specialIcon} />
                    </svg>
                ) : (
                    <Icon className={theme.entityIcon} spriteSymbol={defaultIcon} />
                );
            }
            case TreeItemType.EdgeDefinition: {
                return (
                    <Icon
                        className={theme.entityIcon}
                        spriteSymbol={TreeItemIconsMap[specialIcon || TreeItemType.EdgeDefinition]}
                    />
                );
            }
            case TreeItemType.Script: {
                return (
                    <Icon
                        className={theme.entityIcon}
                        spriteSymbol={TreeItemIconsMap[specialIcon || TreeItemType.Script]}
                    />
                );
            }
            case TreeItemType.SimulationModeling: {
                return <Icon className={theme.entityIcon} spriteSymbol={imIcon} />;
            }
            case TreeItemType.File: {
                return (
                    <Icon
                        className={theme.entityIcon}
                        spriteSymbol={TreeItemIconsMap[specialIcon || TreeItemType.File]}
                    />
                );
            }
            case TreeItemType.Default: {
                return <Icon className={theme.entityIcon} spriteSymbol={defaultIcon} />;
            }
            case TreeItemType.Folder: {
                const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
                const ft: FolderType = useSelector(
                    FolderTypeSelectors.byId({ folderTypeId: folderType!, serverId, presetId }),
                );
                const presetImage: PresetImage | undefined = useSelector(
                    PresetImageSelectors.byId({ presetImageId: ft?.graphical!, serverId, presetId }),
                );
                const ic = presetImage?.graphical || specialIcon;

                return (
                    (ic && <img className={theme.entityIcon} src={ic} />) || (
                        <Icon className={theme.entityIcon} spriteSymbol={TreeItemIconsMap[type]} />
                    )
                );
            }
            default: {
                return <Icon className={theme.entityIcon} spriteSymbol={TreeItemIconsMap[type]} />;
            }
        }
    };

    return <span>{renderIcon(id, type, isConnected, specialIcon)}</span>;
};

export default TreeItemIcon;
