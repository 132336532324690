import React from 'react';
import theme from './TreeItem.scss';
import { Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import { DropdownProps } from 'antd/lib/dropdown/dropdown';
import { MenuInfo } from 'rc-menu/lib/interface';
import {
    TreeItemContextMenuAction,
    TreeItemType,
    TreeContextMenuActionItem,
    TreeItemLocation,
} from '../../models/tree';
import { CONTEXT_MENU_ACTION, CONTEXT_MENU_DIVIDER } from '../../../../actionsTypes/tree.actionTypes';
import { FormattedMessage } from 'react-intl';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import * as ContextMenuItems from '../../ContextMenu/contextMenuItems';
import contextMenuMessages from '../../messages/TreeContextMenu.messages';
import { ContextMenu } from '../../../ContextMenu/components/ContextMenu.component';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import { TFilteredMenu, TTreeContextMenuItem } from '../../Tree.types';
import { ContextMenuItemDivider, ItemSubscribe, ItemUnsubscribe } from '../../ContextMenu/contextMenuItems';
import { ExperimentalFeatures } from '../../../../models/ExperimentalFeatures';
import { getMenuItem } from '../../../../utils/antdMenuItem.utils';

type TTreeItemContextMenuProps = {
    type: TreeItemType;
    isOpenInDialog: boolean;
    location: TreeItemLocation;
    menuMessageValues?: string;
    visible: boolean;
    hideContextMenu: () => void;
    filterMenuActions: () => TFilteredMenu;
    onAction: (action: TreeItemContextMenuAction) => void;
} & Omit<DropdownProps, 'overlay'>;

type TSubItemMenu = {
    titleAction: string;
    formattedMessageId: string;
    icon: TSpriteSymbol;
};

const contextMenuItemsByTreeItemType = {
    [TreeItemType.Server]: {
        [TreeItemLocation.Default]: ContextMenuItems.serverActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Repository]: {
        [TreeItemLocation.Default]: ContextMenuItems.DBActions,
        [TreeItemLocation.CreateDecompositionDialog]: [ContextMenuItems.ItemCreateFolder],
        [TreeItemLocation.CreateModelDialog]: [ContextMenuItems.ItemCreateFolder],
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Folder]: {
        [TreeItemLocation.Default]: ContextMenuItems.FolderActions,
        [TreeItemLocation.CreateDecompositionDialog]: [ContextMenuItems.ItemCreateFolder],
        [TreeItemLocation.CreateModelDialog]: [ContextMenuItems.ItemCreateFolder],
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.ObjectDefinition]: {
        [TreeItemLocation.Default]: ContextMenuItems.ObjectActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Model]: {
        [TreeItemLocation.Default]: ContextMenuItems.ModelActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.ScriptFolder]: {
        [TreeItemLocation.Default]: ContextMenuItems.ScriptFolderActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Script]: {
        [TreeItemLocation.Default]: ContextMenuItems.ScriptActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Wiki]: {
        [TreeItemLocation.Default]: ContextMenuItems.WikiActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Matrix]: {
        [TreeItemLocation.Default]: ContextMenuItems.MatrixActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.SimulationModeling]: {
        [TreeItemLocation.Default]: ContextMenuItems.SimulationModelingActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.FileFolder]: {
        [TreeItemLocation.Default]: ContextMenuItems.FileFolderActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.File]: {
        [TreeItemLocation.Default]: ContextMenuItems.FileActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Spreadsheet]: {
        [TreeItemLocation.Default]: ContextMenuItems.SpreadsheetActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Kanban]: {
        [TreeItemLocation.Default]: ContextMenuItems.KanbanActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.EdgeDefinition]: {
        [TreeItemLocation.Default]: ContextMenuItems.EdgeActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Dashboard]: {
        [TreeItemLocation.Default]: ContextMenuItems.DashboardActions,
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.AdminTool]: {
        [TreeItemLocation.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
};

export const TreeItemContextMenu = (props: TTreeItemContextMenuProps) => {
    const {
        visible,
        type,
        menuMessageValues,
        isOpenInDialog,
        location,
        children,
        hideContextMenu,
        onAction,
        filterMenuActions,
    } = props;

    const handleMenuAction = (param: MenuInfo) => {
        if (hideContextMenu) {
            hideContextMenu();
        }

        if (TreeItemContextMenuAction[param.key]) {
            onAction(param.key as TreeItemContextMenuAction);
        }
    };

    const getActions = (itemType: TreeItemType): TTreeContextMenuItem[] => {
        let menuItems = contextMenuItemsByTreeItemType[itemType]?.[location] || [];
        if (TreeItemType.Repository === itemType && !isOpenInDialog && ExperimentalFeatures.isEnable()) {
            menuItems = props.location === TreeItemLocation.CreateScriptDialog ? menuItems : [...menuItems, ContextMenuItemDivider, ItemSubscribe, ItemUnsubscribe];
        }

        return menuItems;
    };

    const renderMenuItem = (action: TreeContextMenuActionItem, filtered: TFilteredMenu, subItems: TSubItemMenu[]) => {
        const disabled = filtered[action.titleAction]?.disabled;
        const disabledMsg = filtered[action.titleAction]?.message;
        const hidden = filtered[action.titleAction]?.hidden;

        if (hidden) {
            return null;
        }

        if (subItems.length && !disabled) {
            const childrenItems: MenuProps['items'] = subItems.map((subItem: TSubItemMenu) => {
                const itemDisabled = disabled || filtered[subItem.titleAction]?.disabled;

                return getMenuItem(
                    <div data-test={`tree-item_context-menu_options_${subItem.titleAction}`}>
                        <Icon
                            disabled={itemDisabled}
                            spriteSymbol={subItem.icon}
                            className={theme.iconContextMenuItem}
                        />
                        <FormattedMessage {...contextMenuMessages[subItem.formattedMessageId]} />
                    </div>,
                    subItem.titleAction,
                    itemDisabled,
                );
            });

            return getMenuItem(
                <div style={{ display: 'inline' }} data-test={`tree-item_context-menu_${action.titleAction}`}>
                    <Icon disabled={disabled} spriteSymbol={action.icon} className={theme.iconContextMenuItem} />
                    <FormattedMessage {...contextMenuMessages[action.formattedMessageId]} />
                </div>,
                `SUB_${action.titleAction}`,
                false,
                undefined,
                childrenItems,
            );
        }

        return getMenuItem(
            <div data-test={`tree-item_context-menu-options_${action.formattedMessageId}`}>
                <Tooltip title={disabledMsg}>
                    <Icon disabled={disabled} spriteSymbol={action.icon} className={theme.iconContextMenuItem} />
                    <FormattedMessage
                        {...contextMenuMessages[action.formattedMessageId]}
                        values={{
                            profileName: menuMessageValues ? `: ${menuMessageValues}` : '',
                        }}
                    />
                </Tooltip>
            </div>,
            action.titleAction,
            disabled,
        );
    };

    const actions = getActions(type);

    const items: MenuProps['items'] = visible
        ? actions.map((item, index) => {
            if (item.type === CONTEXT_MENU_ACTION) {
                const filteredMenu: TFilteredMenu =
                    typeof filterMenuActions === 'function' ? filterMenuActions() : {};

                if (item.subMenu.length) {
                    return renderMenuItem(item, filteredMenu, item.subMenu);
                }

                return renderMenuItem(item, filteredMenu, []);
            }
            if (index > 0 && item.type === CONTEXT_MENU_DIVIDER) {
                return { type: 'divider' };
            }

            return getMenuItem('', `empty_${index}`);
        })
        : [];

    return (
        <ContextMenu
            menuId="TreeItemContextMenu"
            trigger={['click']}
            overlay={actions.length ? items : null}
            visible={visible}
            onOpenChange={hideContextMenu}
            onClick={handleMenuAction}
        >
            {children}
        </ContextMenu>
    );
};
