import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Menu, Checkbox } from 'antd';
import modelTypeMessages from '../../../models/modelType.messages';
import { setFilterData } from '../../../actions/search.actions';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { NodeId } from '../../../serverapi/api';
import messages from './SearchFilter.messages';
import style from './SearchFilter.scss';
import { injectIntl } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';

const SearchFilter = ({ intl }) => {
    const dispatch = useDispatch();
    const filterUniqueType = useSelector(SearchSelectors.getFilterUniqueType());
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const { id }: NodeId = useSelector(SearchSelectors.getNodeId);
    const currentMarkedElem: string[] = useSelector(SearchSelectors.getFilter);

    const onChangeByType = (type: string) => {
        let elements: string[] = [];

        if (currentMarkedElem.includes(type)) {
            elements = currentMarkedElem.filter((e: string) => e !== type);
        } else {
            elements = [...currentMarkedElem, type];
        }

        dispatch(setFilterData({ id, filter: elements }));
    };

    const handleDropdownToggle = (flag: boolean) => {
        setDropdownVisible(flag);
    };

    const menu = (
        <Menu>
            {filterUniqueType &&
                filterUniqueType
                    .filter((type) => {
                        return intl.formatMessage(modelTypeMessages[type]);
                    })
                    .map((type) => (
                        <Menu.Item key={type}>
                            <Checkbox checked={currentMarkedElem.includes(type)} onChange={() => onChangeByType(type)}>
                                {intl.formatMessage(modelTypeMessages[type])}
                            </Checkbox>
                        </Menu.Item>
                    ))}
        </Menu>
    );

    return (
        <div className={style.searchFilterContainer}>
            <Dropdown
                onOpenChange={handleDropdownToggle}
                overlay={menu}
                trigger={['hover']}
                open={dropdownVisible}
                placement="bottomRight"
                overlayStyle={{ width: 400 }}
                destroyPopupOnHide
            >
                <Button data-test="search_search-filter-button" className={style.searchFilterButton}>
                    {intl.formatMessage(messages.filter)} <DownOutlined />
                </Button>
            </Dropdown>
        </div>
    );
};

const WithIntl = injectIntl(SearchFilter);

export { WithIntl as SearchFilter };
