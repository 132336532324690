import { TReducer } from '../utils/types';
import {
    GENERAL_MENU_EXPAND,
    GENERAL_MENU_COLLAPSE,
    GENERAL_MENU_TOGGLE_STYLE_BUTTONS,
    GENERAL_MENU_UPDATE_AVAILABLE_EDGE_TYPES,
} from '../actionsTypes/generalMenu.actionTypes';
// import { EDITOR_SELECTION_MODEL_CHANGED } from '../actionsTypes/editor.actionTypes';
import { ButtonEditLabelState } from '../models/buttonEditLabelState';
import { initialEdgeTypeSelectorState } from '../models/edgeTypeSelectorState.types';
import { TGeneralMenuState } from './generalMenu.reduces.types';

const initial: TGeneralMenuState = {
    isCompact: false,
    buttonEditLabelState: new ButtonEditLabelState(),
    edgeTypeSelectorState: { ...initialEdgeTypeSelectorState },
};

export const generalMenuReducer: TReducer<TGeneralMenuState> = (state = initial, action) => {
    switch (action.type) {
        case GENERAL_MENU_EXPAND: {
            return {
                ...state,
                isCompact: false,
            };
        }

        case GENERAL_MENU_COLLAPSE: {
            return {
                ...state,
                isCompact: true,
            };
        }

        case GENERAL_MENU_TOGGLE_STYLE_BUTTONS: {
            return {
                ...state,
                buttonEditLabelState: action.payload.buttonEditLabelState,
            };
        }

        case GENERAL_MENU_UPDATE_AVAILABLE_EDGE_TYPES: {
            return {
                ...state,
                edgeTypeSelectorState: action.payload.edgeTypeSelectorState,
            };
        }

        default: {
            return state;
        }
    }
};
