import React, { useEffect, useState } from 'react';
import { FavoriteNodeDTO, NodeId, RootNodeId } from '@/serverapi/api';
import { Button, Input } from 'antd';
import theme from './SelectTreeItemAddFavoriteDialog.scss';
import { useIntl } from 'react-intl';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { useDispatch, useSelector } from 'react-redux';
import { TreeSelectors, filterTreeExcludeNodes, filterTreeExcludeTypes } from '@/selectors/tree.selectors';
import messages from './SelectTreeItemAddFavoriteDialog.messages';
import { TreeNode } from '@/models/tree.types';
import { Dialog } from '@/modules/UIKit/components/Dialog/Dialog.component';
import { DEFAULT_DIALOG_WIDTH } from '@/config/config';
import { Tree } from '@/modules/Tree/components/Tree/Tree.component';
import { Icon } from '@/modules/UIKit';
import icSearch from '../../../../resources/icons/icSearch.svg';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { setFoundNodeIds, setSearchString } from '@/actions/navigatorTreeSearch.actions';
import { NavigatorTreeSearchSelector } from '@/selectors/navigatorTreeSearch.selectors';
import { favoritesAdd } from '@/actions/favorites.actions';
import { FavoritesSelectors } from '@/selectors/favorites.selectors';

export const SelectTreeItemAddFavoriteDialog = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [selectedNodeId, setSelectedNodeId] = useState<NodeId>({ id: '', repositoryId: '', serverId: '' });
    const searchStr: string = useSelector(NavigatorTreeSearchSelector.getSearchString);
    const treeStructure: TreeNode[] = useSelector(TreeSelectors.treeStructure);
    const favoriteNodes: FavoriteNodeDTO[] = useSelector(FavoritesSelectors.nodes);
    const favoriteNodesIds: NodeId[] = favoriteNodes.map((node) => node.nodeId);
    const allRepos = treeStructure[0]?.children || [];
    const filteredRepos = filterTreeExcludeNodes(
        filterTreeExcludeTypes(allRepos, [TreeItemType.AdminTool, TreeItemType.EdgeDefinition]),
        favoriteNodesIds,
    );
    const isConfirmDisabled =
        !selectedNodeId.id ||
        selectedNodeId.id === RootNodeId.FILE_FOLDER_ROOT_ID ||
        selectedNodeId.id === RootNodeId.ROOT_SCRIPT_FOLDER_ID;

    useEffect(() => {
        return () => {
            dispatch(setSearchString(''));
            dispatch(setFoundNodeIds([]));
        };
    }, []);

    const handleSubmit = () => {
        dispatch(favoritesAdd(selectedNodeId));
        dispatch(closeDialog(DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG));
    };

    const handleSelectTreeItem = (selectedNode: TreeNode) => {
        setSelectedNodeId(selectedNode.nodeId);
    };

    const handleCloseDialog = () => {
        dispatch(closeDialog(DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG));
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchString(e.target.value));
    };

    const footer = (
        <div className={theme.buttons}>
            <div>
                <Button key="cancel" onClick={handleCloseDialog}>
                    {intl.formatMessage(messages.cancel)}
                </Button>

                <Button key="ok" type="primary" onClick={handleSubmit} disabled={isConfirmDisabled}>
                    {intl.formatMessage(messages.confirm)}
                </Button>
            </div>
        </div>
    );

    const header = (
        <div>
            <span className={theme.title}>{intl.formatMessage(messages.dialogTitle)}</span>
            <Input
                className={theme.search}
                prefix={<Icon spriteSymbol={icSearch} />}
                onChange={handleSearchChange}
                value={searchStr}
            />
            <div className={theme.label}>{intl.formatMessage(messages.name)}</div>
        </div>
    );

    return (
        <Dialog
            footer={footer}
            className={theme.modal}
            onOk={handleSubmit}
            onCancel={handleCloseDialog}
            title={header}
            open
            width={DEFAULT_DIALOG_WIDTH}
            okText={intl.formatMessage(messages.confirm)}
            cancelText={intl.formatMessage(messages.cancel)}
        >
            <Tree
                treeName={DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG}
                onSelect={handleSelectTreeItem}
                data={filteredRepos}
                disableContextMenu
                selectedNodes={[{ nodeId: selectedNodeId }]}
            />
        </Dialog>
    );
};
