import { ModelType, ObjectModelConnections } from '../../serverapi/api';

type Tconnection = {
    connectedObjectName: string | undefined;
    objectTypeName: string | undefined;
    edgeTypeName: string | undefined;
    isOutgoingEdge: boolean | undefined;
    connectedObjectInstanceId: string | undefined;
};

type TObjectConnectionData = { modelName: string; modelId: string; connections: Tconnection[] };

type TModelTypes = {
    [id: string]: ModelType;
};

export function getExternalEdgeConnections(
    objectModelConnections: ObjectModelConnections[],
    modelTypes: TModelTypes,
    unknownText: string,
): TObjectConnectionData[] {
    const result: TObjectConnectionData[] = [];
    objectModelConnections.forEach((objectConnection) => {
        const modelName = objectConnection.modelName!;
        const modelTypeId = objectConnection.modelTypeId!;
        const modelId = objectConnection.modelId!;
        const modelType = modelTypes[modelTypeId];
        const objectConnectionData: TObjectConnectionData = {
            modelName,
            modelId,
            connections: [],
        };

        objectConnection.connections?.forEach((connection) => {
            let objectTypeName: string = unknownText;
            let edgeTypeName: string = unknownText;

            if (modelType) {
                const objectType = modelType.objectTypes.find(
                    (objectType) => objectType.id === connection.connectedObjectTypeId,
                );
                const edgeType = modelType.edgeTypes.find((edgeType) => edgeType.id === connection.edgeTypeId);
                objectTypeName = objectType?.name || unknownText;
                edgeTypeName = edgeType?.name || unknownText;
            }

            objectConnectionData.connections.push({
                connectedObjectName: connection.connectedObjectName,
                objectTypeName,
                edgeTypeName,
                isOutgoingEdge: connection.isOutgoingEdge,
                connectedObjectInstanceId: connection.connectedObjectInstanceId,
            });
        });
        result.push(objectConnectionData);
    });

    return result;
}
