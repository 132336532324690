import React, { useState } from 'react';
import theme from './approvalTab.scss';
import { useIntl } from 'react-intl';
import { ApprovalTable } from '../ApprovalsTable/ApprovalTable.component';
import { ConfigProvider, DatePicker } from 'antd';
import { LocalesService } from '../../../services/LocalesService';
import { dateFormat } from '../../../utils/date.time.utils';
import { ApprovalDTOStatusEnum } from '../../../serverapi/api';
import { Select } from '../../UIKit/components/Select/Select.component';
import messages from '../messages/approval.messages';
import cx from 'classnames';
import { DeleteButton } from '../ApprovalsTable/DeleteButton.component';
import { CreatorSelector } from '../ApprovalsTable/CreatorsSelector.component';
import { Dayjs } from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import iRanger from '../../../resources/icons/iRangePicker.svg';
import { TApprovalTableData } from '../ApprovalsTable/approvalsTable.types';
import { useSelector } from 'react-redux';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { isEqual } from 'lodash';

type TStatusArr = ApprovalDTOStatusEnum[];
const statusArr: TStatusArr = ['APPROVED', 'NOT_APPROVED', 'CANCELLED', 'IN_PROCESS', 'DRAFT'];

export const ApprovalTab = React.memo((): JSX.Element => {
    const intl = useIntl();
    const [showPlaceHolder, setShowPlaceHolder] = useState<boolean>(true);
    const [statusFilter, setStatusFilter] = useState<TStatusArr>([]);
    const [creatorFilter, setCreatorFilter] = useState<string[]>([]);
    const [createdAtRangeFilter, setCreatedAtRangeFilter] = useState<RangeValue<Dayjs>>(null);
    const filteredTableData: TApprovalTableData[] = useSelector(
        ApprovalSelectors.getFilteredApprovalTableData(statusFilter, creatorFilter, createdAtRangeFilter),
        (oldData: TApprovalTableData[], newData: TApprovalTableData[]) => isEqual(oldData, newData),
    );

    const handleChange = (e: RangeValue<Dayjs>) => {
        setCreatedAtRangeFilter(e);
        if (e) {
            setShowPlaceHolder(false);
        } else {
            setShowPlaceHolder(true);
        }
    };

    const handleChangeStatusFilter = (statusArr: TStatusArr) => {
        setStatusFilter(statusArr);
    };

    return (
        <div className={theme.approvalTab}>
            <div className={theme.headerContainer}>
                <div className={theme.rangePickerContainer}>
                    <ConfigProvider locale={LocalesService.checkAndSetLocale(intl.locale)}>
                        <DatePicker.RangePicker
                            className={cx(theme.rangePicker, { [theme.showPlaceHolder]: showPlaceHolder })}
                            format={dateFormat}
                            placeholder={showPlaceHolder ? [intl.formatMessage(messages.period), ''] : ['', '']}
                            onChange={handleChange}
                            suffixIcon={<Icon className={theme.iconRange} spriteSymbol={iRanger} />}
                        />
                    </ConfigProvider>
                </div>
                <CreatorSelector creatorFilter={creatorFilter} setCreatorFilter={setCreatorFilter} />
                <div className={theme.selectContainer}>
                    <Select
                        isMultiSelect
                        value={intl.formatMessage(messages.status)}
                        onChange={handleChangeStatusFilter}
                    >
                        {statusArr.map((status) => (
                            <Select.Option
                                key={status}
                                value={status}
                                checked={statusFilter.includes(status)}
                                label={intl.formatMessage(messages[status])}
                            />
                        ))}
                    </Select>
                </div>
                <DeleteButton />
            </div>
            <div className={theme.tableContainer}>
                <ApprovalTable approvalTableData={filteredTableData} />
            </div>
        </div>
    );
});
