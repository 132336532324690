import React, { Component } from 'react';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import messages from '../../UserManagement/messages/userManagment.messages';
import formMessages from '../../../../models/formDefault.messages';
import { Button, Table } from 'antd';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { UserSessionHistory } from '../../../../serverapi/api';
import { formatDate } from '../../../../utils/date.time.utils';
import { DEFAULT_DIALOG_WIDTH } from '../../../../config/config';
import {
    TUserSessionsHistoryDialogActionProps,
    TUserSessionsHistoryDialogProps,
} from '../UserSessionsHistoryDialog.types';

type TUserSessionsHistoryDialogFullProps = TUserSessionsHistoryDialogActionProps &
    TUserSessionsHistoryDialogProps &
    WrappedComponentProps;

class UserSessionsHistoryDialog extends Component<TUserSessionsHistoryDialogFullProps> {
    componentDidMount() {
        if (this.props.userId) {
            this.props.fetchUserSessions(this.props.userId);
        }
    }

    getColumns = () => {
        const { intl } = this.props;

        return [
            {
                title: intl.formatMessage(messages.beginSession),
                dataIndex: 'at',
                key: 'at',
                width: 100,
            },
            {
                title: intl.formatMessage(messages.endSession),
                dataIndex: 'to',
                key: 'to',
                width: 100,
            },
        ];
    };

    getData = () => {
        const { intl, locale, sessions } = this.props;
        if (!sessions) {
            return [];
        }
        const today = intl.formatMessage(messages.today);
        const yesterday = intl.formatMessage(messages.yesterday);

        return sessions
            .map((s) => {
                return {
                    startedAt: s.startedAt && new Date(s.startedAt),
                    finishedAt: s.finishedAt && new Date(s.finishedAt),
                } as UserSessionHistory;
            })
            .sort((a, b) => (!a.startedAt || !b.startedAt ? -1 : b.startedAt.getTime() - a.startedAt.getTime()))
            .map((item) => ({
                at: formatDate(today, yesterday, locale, item.startedAt),
                to: formatDate(today, yesterday, locale, item.finishedAt),
            }));
    };

    render() {
        const { onClose, intl, visible } = this.props;
        const footer = [
            <Button data-test="user-edit_session-history-accept" key="ok" size="large" type="primary" onClick={onClose}>
                {intl.formatMessage(formMessages.OkButton)}
            </Button>,
        ];

        return (
            <Dialog
                onCancel={onClose}
                onOk={onClose}
                title={intl.formatMessage(messages.showSessionsLabel)}
                open={visible}
                width={DEFAULT_DIALOG_WIDTH}
                footer={footer}
            >
                <Table
                    size="middle"
                    columns={this.getColumns()}
                    dataSource={this.getData()}
                    scroll={{ y: 400 }}
                    pagination={false}
                />
            </Dialog>
        );
    }
}

export default injectIntl(UserSessionsHistoryDialog);
