import type { IComplexSymbol, TComplexSymbolCreationProps, TRootCellValue } from './ComplexSymbol.class.types';
import type { ObjectInstanceImpl } from '@/models/bpm/bpm-model-impl';
import type { BPMMxGraph } from '@/mxgraph/bpmgraph';
import type { MxCell } from 'MxGraph';
import type { SymbolTypeId, SequenceSymbolTypeId } from './ComplexSymbol.constants';
import { MOVE_CELL_STEP } from './ComplexSymbol.constants';
import { KeyCodes } from '@/utils/keys';
import { objectDefinitionService } from '@/services/ObjectDefinitionService';
import { ISerializer } from './serializers/serializer.types';
import { cellsTreeWalkerDown } from '../utils';
import { isSequenceDiagramCell } from './Sequence/sequence.utils';

export abstract class ComplexSymbol implements IComplexSymbol {
    abstract serializer: ISerializer;
    abstract complexSymbolTypeId: SymbolTypeId | SequenceSymbolTypeId;
    abstract addToGraph(): MxCell;

    protected rootCell: MxCell;
    protected rootCellValue: TRootCellValue;
    protected graph: BPMMxGraph;
    protected customProps: any;
    private managedCells: MxCell[] = [];

    constructor({ graph, rootCellValue, customProps }: TComplexSymbolCreationProps) {
        this.graph = graph;
        this.rootCellValue = rootCellValue;
        this.customProps = customProps;
    }

    public getRootCell(): MxCell {
        return this.rootCell;
    }

    public serialize() {
        return this.serializer.serialize();
    }

    public getManagedCells(): MxCell[] {
        return this.managedCells;
    }

    public getSymbolTypeId(): SymbolTypeId | SequenceSymbolTypeId {
        return this.complexSymbolTypeId;
    }

    protected afterCreate(rootCell: MxCell): MxCell {
        rootCell.setValue(this.rootCellValue);
        rootCell.complexSymbolRef = this;
        rootCell.setConnectable(this.isConnectable);

        if (isSequenceDiagramCell(rootCell)) {
            cellsTreeWalkerDown([rootCell], (cell: MxCell) => {
                cell.complexSymbolRef = this;
            });
        } else {
            rootCell.children?.forEach((child) => {
                if (!child.complexSymbolRef) {
                    child.complexSymbolRef = this;
                }
            });
        }

        this.rootCell = rootCell;
        this.serializer.afterCreate(rootCell);
        this.managedCells.push(rootCell);
        this.graph.refresh(rootCell);

        return rootCell;
    }

    protected isTitleHidden(): boolean {
        return false;
    }

    protected isRootCell(cell: MxCell): boolean {
        return this.rootCell.getId() === cell.getId();
    }

    public get isUseRenameDialog(): boolean {
        return true;
    }

    public get isConnectable(): boolean {
        return false;
    }

    public redraw() {}

    public convertValueToString(cell: MxCell): string {
        const value: ObjectInstanceImpl | undefined = cell.getValue();

        if (this.isTitleHidden()) return '';
        if (value?.objectDefinitionId) {
            const { serverId, repositoryId } = this.graph.id;
            const objectDefinition = objectDefinitionService().getObjectDefinition({
                serverId,
                repositoryId,
                id: value.objectDefinitionId,
            });

            if (objectDefinition) {
                return objectDefinition.name;
            }
        }

        return '';
    }

    public getCopyableCell(cell: MxCell): MxCell | null {
        return null;
    }

    public isCellCopyable(cell: MxCell): boolean {
        return false;
    }

    public isCellMovable(cell: MxCell): boolean {
        return true;
    }

    public handleKeyPress(cell: MxCell, key: KeyCodes) {
        if (!this.isCellMovable(cell)) {
            return;
        }

        let dx = 0;
        let dy = 0;
        if (key === KeyCodes.LEFT) dx = -MOVE_CELL_STEP;
        if (key === KeyCodes.RIGHT) dx = MOVE_CELL_STEP;
        if (key === KeyCodes.UP) dy = -MOVE_CELL_STEP;
        if (key === KeyCodes.DOWN) dy = MOVE_CELL_STEP;

        this.graph.moveCells([cell], dx, dy, false, undefined, undefined);
    }

    public isCellStyleEditable(cell: MxCell): boolean {
        return false;
    }
}
