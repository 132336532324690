import { connect } from 'react-redux';
import { TRootState } from '../../../../reducers/root.reducer.types';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { closeDialog, openDialog } from '../../../../actions/dialogs.actions';
import { scriptExecute, scriptSchedule } from '../../../../actions/entities/script.actions';
import { IScriptNode } from '../../../../models/bpm/bpm-model-impl.types';
import { ScriptExecuteDialog } from './ScriptExecuteDialog.component';
import { TScriptExecuteDialogStoreProps } from './ScriptExecuteDialog.types';
import { ScriptParameter } from '../../../../serverapi/api';
import {
    scriptExecuteDialogAddNodeId,
    scriptExecuteDialogDeleteNodeId,
    scriptExecuteDialogDeleteParamFiles,
    scriptExecuteDialogRequestSuccess,
} from '../../../../actions/scriptExecuteDialog.actions';
import { TScriptExecuteDialogFileParams } from '../../../../reducers/scriptExecuteDialog.reducer.types';

const mapStateToProps = (state: TRootState): Partial<TScriptExecuteDialogStoreProps> => ({
    fileParams: state.scriptExecuteDialog.fileParams,
    nodeParams: state.scriptExecuteDialog.nodeParams,
});

const mapDispatchToProps = (dispatch, props: TScriptExecuteDialogStoreProps) => ({
    onClose: (fileParams: TScriptExecuteDialogFileParams) => {
        dispatch(closeDialog(DialogType.SCRIPT_EXECUTE_DIALOG));
        dispatch(
            scriptExecuteDialogDeleteParamFiles(
                props.script.nodeId.serverId,
                Object.values(fileParams)
                    .filter((fileParam) => fileParam.uploadedFileName)
                    .map((fileParam) => fileParam.uploadedFileName!),
            ),
        );
    },
    onSubmit: (script: IScriptNode, params: ScriptParameter[], timeStart?: Date) => {
        if (!timeStart) {
            dispatch(scriptExecute(script.nodeId, params));
            dispatch(closeDialog(DialogType.SCRIPT_EXECUTE_DIALOG));
            dispatch(scriptExecuteDialogRequestSuccess());
        } else {
            dispatch(scriptSchedule(script.nodeId, params, timeStart));
            dispatch(closeDialog(DialogType.SCRIPT_EXECUTE_DIALOG));
        }
    },

    onUploadFile: (serverId: string, paramName: string) => {
        dispatch(openDialog(DialogType.SCRIPT_UPLOAD_PARAM_FILE_DIALOG, { serverId, paramName }));
    },

    onAddNode: (paramName) => {
        dispatch(
            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                serverId: props.script.serverId,
                onSubmit: (nodeId) => dispatch(scriptExecuteDialogAddNodeId(paramName, nodeId)),
                disableContextMenu: true,
            }),
        );
    },

    onDeleteNode: (paramName) => dispatch(scriptExecuteDialogDeleteNodeId(paramName)),
});

export const ScriptExecuteWithParamsDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ScriptExecuteDialog);
