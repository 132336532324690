import { FRAME_STYLES_INDICATOR, SymbolTypeId } from '../ComplexSymbol.constants';
import ObjectInstanceSerializer from '../serializers/ObjectInstanceSerializer.class';
import SwimLaneSymbol from './SwimLaneSymbol.class';

export abstract class HorizontalSwimLaneSymbol extends SwimLaneSymbol {
    complexSymbolTypeId = SymbolTypeId.HORIZONTAL_SWIMLANE;
    serializer = new ObjectInstanceSerializer();

    addToGraph() {
        const { graph } = this;
        const { x = 0, y = 0, width = 460, height = 120, parent = graph.getDefaultParent() } = this.rootCellValue;
        const style = this.rootCellValue.style || `${FRAME_STYLES_INDICATOR};swimlane;startSize=30;horizontal=0;`;
        const swimline = graph.insertVertexFromValue({
            ...this.rootCellValue,
            parent,
            x,
            y,
            width,
            height,
            style,
        });

        return this.afterCreate(swimline);
    }
}
