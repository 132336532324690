import type { IComplexSymbol } from './symbols/ComplexSymbol.class.types';
import { SymbolTypeId, SequenceSymbolTypeId } from './symbols/ComplexSymbol.constants';
import { CrossFunctionalFlowchartSymbol } from './symbols/CrossFunctionalFlowchart';
import {
    LifelineSymbol,
    LifelineBounderySymbol,
    LifelineEntitySymbol,
    LifelineControlSymbol,
    LifelineActorSymbol,
} from './symbols/LifeLine';
import {
    HorizontalSwimLaneSymbol,
    VerticalSwimLaneSymbol,
    HorizontalPoolSymbol,
    VerticalPoolSymbol,
} from './symbols/Pool';
import { UMLFrame } from './symbols/Sequence/UMLFrame.class';
import { UMLFrameTitle } from './symbols/Sequence/UMLFrameTitle.class';
import { UMLNote } from './symbols/Sequence/UMLNote.class';
import { TableSymbolClass, TableSymbol } from './symbols/Table';
import { UMLClassSymbol } from './symbols/UML/ClassSymbol/ClassSymbol.class';
import { UMLPackageSymbol } from './symbols/UML/PackageSymbol/PackageSymbol.class';
import { VRSymbol } from './symbols/VR';
import { IDEFSymbol } from './symbols/IDEF/IDEFSymbol';
import { UMLMessage } from './symbols/Sequence/UMLMessage.class';

const ComplexTypesToClassesMap = {
    [SymbolTypeId.VR]: VRSymbol,
    [SymbolTypeId.IDEF]: IDEFSymbol,
    [SymbolTypeId.HORIZONTAL_SWIMLANE]: HorizontalSwimLaneSymbol,
    [SymbolTypeId.VERTICAL_SWIMLANE]: VerticalSwimLaneSymbol,
    [SymbolTypeId.HORIZONTAL_POOL]: {
        value: HorizontalPoolSymbol,
        customProps: {
            createSwimlanes: true,
        },
    },
    [SymbolTypeId.VERTICAL_POOL]: {
        value: VerticalPoolSymbol,
        customProps: {
            createSwimlanes: true,
        },
    },
    [SymbolTypeId.CROSS]: {
        value: CrossFunctionalFlowchartSymbol,
        customProps: {
            col: 3,
            row: 3,
            requestProps: true,
        },
    },
    [SymbolTypeId.TABLE_SYMBOL_CLASS]: TableSymbolClass,
    [SymbolTypeId.TABLE_SYMBOL]: TableSymbol,
    [SymbolTypeId.UML_CLASS]: UMLClassSymbol,
    [SymbolTypeId.UML_PACKAGE]: UMLPackageSymbol,
    [SymbolTypeId.UML_NOTE]: UMLNote,
    [SymbolTypeId.UML_MESSAGE]: UMLMessage,
    [SymbolTypeId.UML_FRAME]: {
        value: UMLFrame,
        customProps: {
            createFrameTitle: true,
        },
    },
    [SymbolTypeId.UML_FRAME_TITLE]: UMLFrameTitle,
    [SequenceSymbolTypeId.LIFE_LINE]: LifelineSymbol,
    [SequenceSymbolTypeId.LIFE_LINE_BOUNDERY]: LifelineBounderySymbol,
    [SequenceSymbolTypeId.LIFE_LINE_ENTITY]: LifelineEntitySymbol,
    [SequenceSymbolTypeId.LIFE_LINE_CONTROL]: LifelineControlSymbol,
    [SequenceSymbolTypeId.LIFE_LINE_ACTOR]: LifelineActorSymbol,
};

export function findComplexSymbolImplementation(...rest: string[]): {
    ComplexSymbolClass: IComplexSymbol;
    customProps: any;
} | null {
    const symbolTypeId = rest.find((name: string) => ComplexTypesToClassesMap[name]);

    if (!symbolTypeId) {
        return null;
    }

    const classValue = ComplexTypesToClassesMap[symbolTypeId];

    return {
        ComplexSymbolClass: classValue.value ? classValue.value : classValue,
        customProps: classValue.customProps,
    };
}

export default ComplexTypesToClassesMap;
