import { PermissionModel, PermissionModelPermissionEnum, PrincipalDescriptor, PrincipalPermissions } from '../../../../serverapi/api';
import { isUndefined } from 'is-what';
import { IPrincipalPermissionsColumnData } from './PermissionTables.types';

export function isPrincipalHasPatternInKeyForSearch(
    userPermissions: PrincipalPermissions,
    keyForSeach: string,
    principals: PrincipalDescriptor[],
    pattern: string,
): boolean {
    return !!principals
        .find((e) => e.id === userPermissions.principalId)
        ?.[keyForSeach]?.toLowerCase()
        ?.includes(pattern.toLowerCase());
}

export function getFilteredData(
    pattern: string,
    data: PrincipalPermissions[],
    principals: PrincipalDescriptor[],
): PrincipalPermissions[] {
    return !pattern
        ? data
        : data.filter(
            (e) =>
                isPrincipalHasPatternInKeyForSearch(e, 'login', principals, pattern) ||
                isPrincipalHasPatternInKeyForSearch(e, 'name', principals, pattern),
        );
}

export function getPermissionByEnum(actionType: PermissionModelPermissionEnum, permissions?: Array<PermissionModel>) {
    let model: PermissionModel | undefined;
    if (!isUndefined(permissions)) {
        model = permissions.find((e) => e.permission === actionType);
    }

    return model;
}

export function getColumnsData(
    principals: Array<PrincipalDescriptor>,
    rawData: Array<PrincipalPermissions>,
    filter: string,
): IPrincipalPermissionsColumnData[] {
    const principalIds = principals.map((p) => p.id);
    const data = rawData.filter((d) => (d.principalId ? principalIds.includes(d.principalId) : false));
    const filteredData = getFilteredData(filter, data, principals);
    const columnsData = filteredData.map((e) => {
        const item: IPrincipalPermissionsColumnData = {};
        item.principalPermissions = e;
        item.principal = principals.find((t) => t.id === e.principalId);
        item.authCreate = getPermissionByEnum('CREATE', e.permissions);
        item.authRead = getPermissionByEnum('READ', e.permissions);
        item.authWrite = getPermissionByEnum('UPDATE', e.permissions);
        item.authDelete = getPermissionByEnum('DELETE', e.permissions);
        item.authAdministration = getPermissionByEnum('CONTROL', e.permissions);

        return item;
    });

    return columnsData;
}
