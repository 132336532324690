import React from 'react';
import { useDispatch } from 'react-redux';
import messages from './DiagramScriptDialog.messages';
import { useIntl } from 'react-intl';
import { TreeNode } from '../../../models/tree.types';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { handleScriptSubmittedViaNode } from '../../../actions/entities/script.actions';
import { DiagramElement, NodeId } from '../../../serverapi/api';
import { ChooseScriptDialog } from './ChooseScriptDialog.component';

type TDiagramScriptDialogExternalProps = {
    serverId: string;
    nodeId: NodeId;
    nodesIdsList: NodeId[];
    modelId?: NodeId;
    element?: DiagramElement;
    elementsIdsList?: string[];
    schedule?: boolean;
};

export const DiagramScriptDialog = (props: TDiagramScriptDialogExternalProps) => {
    const { serverId, nodeId, nodesIdsList, modelId, element, elementsIdsList, schedule } = props;
    const intl = useIntl();
    const dispatch = useDispatch();

    const onSubmit = (selectedScript: TreeNode) => {
        dispatch(
            handleScriptSubmittedViaNode({
                scriptNodeId: { ...selectedScript.nodeId, serverId },
                nodeId,
                nodesIdsList,
                modelId,
                elementId: element?.id,
                elementsIdsList,
                schedule,
            }),
        );
    };

    const okButtonTitle = intl.formatMessage(
        schedule ? messages.scriptFormConfirmButtonContinue : messages.scriptFormConfirmButtonRun,
    );

    return (
        <ChooseScriptDialog
            serverId={serverId}
            nodeId={nodeId}
            dialogType={DialogType.DIAGRAM_EXECUTE_SCRIPT_DIALOG}
            modelId={modelId}
            isContextSensitive
            title={intl.formatMessage(messages.scriptSelectorDialogName)}
            okButtonTitle={okButtonTitle}
            errorMessage={intl.formatMessage(messages.scriptNotSelectedError)}
            onSubmit={onSubmit}
        />
    );
};
