import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeId, PrincipalDescriptor } from '../../../../serverapi/api';
import { allPermissionsClearData, allPermissionsRequest } from '../../../../actions/allPermissions.actions';
import {
    getAllPermissionsError,
    getAllPermissionsIsLoading,
    getAllPermissionsWithLeastOneGranting,
} from '../../../../selectors/allPermissions.selectors';
import { Alert, Form, Input, Select, Spin } from 'antd';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { useIntl } from 'react-intl';
import msg from '../../messages/InstancePermissionTable.messages';
import { PermissionSwitch } from '../PermissionSwitch/PermissionSwitch.component';
import PrincipalLabel from '../PrincipalLabel/PrincipalLabel.component';
import theme from './AllPermissionsTable.scss';
import { SearchOutlined } from '@ant-design/icons';
import messages from '../../messages/InstancePermissionsDialog.messages';
import { getFilteredData } from './PermissionTablesUtils';

type TAllPermissionsTableProps = {
    nodeId: NodeId;
    principals: Array<PrincipalDescriptor>;
};

export const AllPermissionsResultTable = ({ nodeId, principals }: TAllPermissionsTableProps): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState<string>('');
    const isLoading = useSelector(getAllPermissionsIsLoading);
    const allPermissions = useSelector(getAllPermissionsWithLeastOneGranting) || [];
    const error = useSelector(getAllPermissionsError);
    const errorMsg = error === '' ? intl.formatMessage(messages.fetchError) : error;

    useEffect(() => {
        dispatch(allPermissionsRequest(nodeId));

        return () => {
            dispatch(allPermissionsClearData());
        };
    }, []);

    if (isLoading) {
        return (
            <div className={theme.spinner_container}>
                <Spin />
            </div>
        );
    }

    const principalIds = principals.map((p) => p.id);
    const preparedDataForTable = allPermissions
        .filter((permission) => (permission.principalId ? principalIds.includes(permission.principalId) : false))
        .map((permission) => {
            const principal = principals.find((el) => {
                return el.id === permission.principalId;
            });

            return { ...permission, principal };
        });

    function usersColumnRenderer({ rowData }) {
        const { principalId } = rowData;
        const user = preparedDataForTable.find((el) => el.principalId === principalId);

        if (!user || !user.principal) return null;

        return <PrincipalLabel {...user.principal} />;
    }

    function permissionColumnRenderer({ rowData, dataKey }) {
        const { isGranting } = rowData.permissions.find((el) => el.permission === dataKey);

        return <PermissionSwitch isGranting={isGranting} onlyTwoIcon readOnly isAllPermissions dataKey={ dataKey }/>;
    }

    function filterHandler(e: ChangeEvent<HTMLInputElement>) {
        setFilter(e.target.value.toLowerCase().trim());
    }

    const filteredPermissions = getFilteredData(filter, preparedDataForTable, principals);
    const permission = filteredPermissions || preparedDataForTable;
    const rowGetter = ({ index }) => permission && permission[index];

    return (
        <>
            <Form.Item>
                <div className={theme.wrapper}>
                    <Input
                    className={theme.searchInput}
                        suffix={<SearchOutlined />}
                        onChange={filterHandler}
                    />
                    <Select className={theme.selectFilter} defaultValue={intl.formatMessage(messages.selectFilterAllUsers)}>
                        <Select.Option>
                            {intl.formatMessage(messages.selectFilterAllUsers)}
                        </Select.Option>
                        <Select.Option>
                            {intl.formatMessage(messages.selectFilterUsersWithPermissions)}
                        </Select.Option>
                        <Select.Option>
                            {intl.formatMessage(messages.selectFilterUsersWithoutPermissions)}
                        </Select.Option>
                    </Select>
                </div>
            </Form.Item>
            {errorMsg && (
                <Form.Item>
                    <Alert type="error" message={errorMsg} showIcon />
                </Form.Item>
            )}
            <div className={theme.container} data-test="permission-dialog_all-permissions-table">
                <AutoSizer>
                    {({ height, width }) => (
                        <Table
                            width={width}
                            height={height}
                            rowHeight={48}
                            className={theme.table}
                            headerHeight={50}
                            rowCount={permission.length}
                            rowGetter={rowGetter}
                        >
                            <Column
                                width={1500}
                                dataKey="authority"
                                label={intl.formatMessage(msg.authority)}
                                cellRenderer={usersColumnRenderer}
                            />
                            <Column
                                width={width}
                                dataKey="CREATE"
                                className={theme.permColumn}
                                label={intl.formatMessage(msg.create)}
                                cellRenderer={permissionColumnRenderer}
                            />
                            <Column
                                width={width}
                                dataKey="READ"
                                className={theme.permColumn}
                                label={intl.formatMessage(msg.read)}
                                cellRenderer={permissionColumnRenderer}
                            />
                            <Column
                                width={width}
                                dataKey="UPDATE"
                                className={theme.permColumn}
                                label={intl.formatMessage(msg.write)}
                                cellRenderer={permissionColumnRenderer}
                            />
                            <Column
                                width={width}
                                dataKey="DELETE"
                                className={theme.permColumn}
                                label={intl.formatMessage(msg.delete)}
                                cellRenderer={permissionColumnRenderer}
                            />
                            <Column
                                width={width}
                                dataKey="CONTROL"
                                className={theme.permColumn}
                                label={intl.formatMessage(msg.administration)}
                                cellRenderer={permissionColumnRenderer}
                            />
                        </Table>
                    )}
                </AutoSizer>
            </div>
        </>
    );
};
