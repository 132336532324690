import { TReducer } from '../utils/types';
import {
    INSTANCE_PERMISSIONS_ADD_PRINCIPALS,
    INSTANCE_PERMISSIONS_DATA,
    INSTANCE_PERMISSIONS_FAILURE,
    INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_DELETE,
    INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_UPDATE,
    INSTANCE_PERMISSIONS_REQUEST,
} from '../actionsTypes/instancePermissions.actionTypes';
import { PermissionModel, PrincipalDescriptor, PrincipalPermissions } from '../serverapi/api';
import { TInstancePermissionsDialogState } from './instancePermissions.reducer.types';
import { isNumber } from 'lodash';

const initial: TInstancePermissionsDialogState = {
    serverId: null,
    loading: true,
    changed: false,
    data: null,
    error: null,
};

export const instancePermissionsReducer: TReducer<TInstancePermissionsDialogState> = (state = initial, action) => {
    switch (action.type) {
        case INSTANCE_PERMISSIONS_REQUEST: {
            return {
                ...state,
                loading: true,
                error: null,
            };
        }
        case INSTANCE_PERMISSIONS_FAILURE: {
            const { error } = action.payload;

            return {
                ...state,
                error,
                loading: false,
            };
        }
        case INSTANCE_PERMISSIONS_ADD_PRINCIPALS: {
            const { principals } = action.payload;
            const principalPermissions = state.data?.permissions || [];

            const permPrincipals: Array<number> = principalPermissions.map((e) => e.principalId).filter(isNumber);
            const pricipalsToAdd: Array<PrincipalDescriptor> = principals.filter(
                (e: PrincipalDescriptor) => typeof e.id === 'number' && permPrincipals.indexOf(e.id) === -1,
            );
            const addedPrincipalPermissions: Array<PrincipalPermissions> = [];
            for (const item of pricipalsToAdd) {
                const pp: PrincipalPermissions = {};
                pp.principalId = item.id;
                pp.permissions = [];
                addedPrincipalPermissions.push(pp);
            }

            return {
                ...state,
                changed: true,
                data: {
                    ...state.data!,
                    permissions: [...principalPermissions!, ...addedPrincipalPermissions],
                },
            };
        }
        case INSTANCE_PERMISSIONS_DATA: {
            const { serverId, data } = action.payload;

            return {
                error: null,
                loading: false,
                serverId,
                changed: false,
                data,
            };
        }
        case INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_DELETE: {
            const { selectedIds } = action.payload;
            if (!state.data) {
                return state;
            }
            const permissions = state.data!.permissions!.filter(
                (value) => selectedIds.indexOf(value.principalId!) === -1,
            );

            return {
                ...state,
                changed: true,
                data: {
                    ...state.data,
                    permissions,
                },
            };
        }
        case INSTANCE_PERMISSIONS_PRINCIPAL_PERMISSIONS_UPDATE: {
            const { actionType, isGranting } = action.payload;
            const principalId: number = action.payload.principalId;

            let principalPermissions = state.data!.permissions!;

            if (isGranting === undefined) {
                principalPermissions = principalPermissions.map((pp) => {
                    if (pp.principalId === principalId) {
                        pp.permissions = pp.permissions!.filter((perm) => perm.permission !== actionType);
                    }

                    return pp;
                });
            } else {
                principalPermissions = principalPermissions.map((pp) => {
                    if (pp.principalId === principalId) {
                        let isOperationDone: boolean = false;
                        pp.permissions!.map((perm) => {
                            if (perm.permission === actionType) {
                                perm.isGranting = isGranting;
                                isOperationDone = true;
                            }

                            return perm;
                        });

                        if (!isOperationDone) {
                            const model: PermissionModel = {};
                            model.isGranting = isGranting;
                            model.permission = actionType;
                            pp.permissions!.push(model);
                        }
                    }

                    return pp;
                });
            }

            return {
                ...state,
                changed: true,
                data: {
                    ...state.data!,
                    permissions: principalPermissions,
                },
            };
        }
        default:
            return state;
    }
};
