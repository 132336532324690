// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommentTooltipText__commentText__UgAIF{font-weight:400;font-size:14px;line-height:22px;color:#363636;text-overflow:ellipsis;word-break:break-all;margin-top:8px}.CommentTooltipText__smallFormat__iceZH{margin-top:2px;min-height:25px}", "",{"version":3,"sources":["webpack://./src/modules/Comments/components/CommentTooltipText/CommentTooltipText.scss"],"names":[],"mappings":"AAAA,wCACI,eAAA,CACA,cAAA,CACA,gBAAA,CACA,aAAA,CACA,sBAAA,CACA,oBAAA,CACA,cAAA,CAEJ,wCACI,cAAA,CACA,eAAA","sourcesContent":[".commentText {\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n    line-height: 22px;\r\n    color: #363636;\r\n    text-overflow: ellipsis;\r\n    word-break: break-all;\r\n    margin-top: 8px;\r\n}\r\n.smallFormat {\r\n    margin-top: 2px;\r\n    min-height: 25px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentText": "CommentTooltipText__commentText__UgAIF",
	"smallFormat": "CommentTooltipText__smallFormat__iceZH"
};
export default ___CSS_LOADER_EXPORT___;
