import type { TFloatingAttributesDialogContentRef } from '../../../modules/FloatingAttributes/components/FloatingAttributesPanel.types';
import type { TChangedProperties, TChangedProperty } from '../../../actions/changedProperties.types';
import type { TNavigatorPropertiesData } from '../../../models/navigatorPropertiesSelectorState.types';
import type { TObjectPropertiesDialogProps } from './ObjectPropertiesDialog.types';
import type { EdgeInstanceImpl, ObjectInstanceImpl, StyledAttributeType } from '@/models/bpm/bpm-model-impl';
import type {
    AttributeValue,
    EdgeInstance,
    ModelAssignment,
    ModelNode,
    NodeId,
    Node,
    ObjectDefinitionNode,
    AttributeValueString,
    EdgeDefinitionNode,
    RepositoryNode,
    EventDescriptorMacros,
    SymbolAttributeTypeStyleDTO,
    EdgeTypeAttributeTypeStyleDTO,
    ModelType,
    ScriptNode,
    DiagramElementAttributeStyle,
} from '../../../serverapi/api';
import React, { Component, createRef } from 'react';
import { Button, Checkbox, Input, Tabs, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';
import { ObjectPropertiesDialogActiveTab } from '../../../models/objectPropertiesDialog';
import { buildPropDescriptorByAttrType } from '../../../models/properties/property-descriptor';
import objectIcon from '../../../resources/icons/ic-tree-object-org-chart.svg';
import icSubscribe from '../../../resources/icons/ic-subscribe.svg';
import icUnSubscribe from '../../../resources/icons/ic-unsubscribe.svg';
import { TreeItemType } from '../../Tree/models/tree';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from '../messages/ObjectPropertiesDialog.messages';
import theme from './ObjectPropertiesDialog.scss';
import { ParentObjects } from './ParentObjects.component';
import { DecompositionTab } from './DecompositionTab.component';
import { ObjectInstances } from './ObjectInstances.component';
import { AttributesTab } from './AttributesTab.component';
import { SymbolFloatingAttributesPanel } from '../../FloatingAttributes/components/SymbolFloatingAttributesPanel.component';
import { EdgeFloatingAttributesPanel } from '../../FloatingAttributes/components/EdgeFloatingAttributesPanel.component';
import { trimStringValue } from './utils';
import { NodeSettings } from './NodeSettings/NodeSettings.component';
import { MethodologyChangeTab } from './MethodologyChangeTab.component';
import { convertToNumber } from '../../../utils/ConvertToNumber.utils';
import { ClassPropertiesTab } from './ClassPropertiesTab.component';
import { ClassMethodsTab } from './ClassMethodsTab.component';
import { ClassReceptionsTab } from './ClassReceptionsTab.component';
import { UML_ID_SYMBOL, UML_OBJECT_TYPE } from '../../../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import isEqual from 'lodash/isEqual';
import memoize from 'lodash/memoize';
import { ScriptContext } from './ScriptContext/ScriptContext.component';
import { MxCell } from '../../../mxgraph/mxgraph';
import { TObjectStyle } from '../../FloatingAttributes/FloatingAttributes.types';
import { ApprovalsTab } from './ApprovalsTab/ApprovalsTab.component';
import { ExperimentalFeatures } from '@/models/ExperimentalFeatures';

type TObjectPropertiesDialogState = {
    ignorePresetStyles: boolean;
    changedProperties: TChangedProperties;
    modelSettingsChangedProperties: TChangedProperties;
    diagramElementChangedProperties: TChangedProperties;
    changedAttributes: AttributeValue[];
    diagramElementChangedAttributes: AttributeValue[];
    serverId: string;
    node?: ModelNode | ObjectDefinitionNode | EdgeDefinitionNode | RepositoryNode;
    confidential?: boolean;
    removed: string[];
    removedDiagramElementAttributes: string[];
    edgeInstance?: EdgeInstance;
    rulesIsValid?: boolean;
    classPropertyObjects: ObjectDefinitionNode[];
    classMethodObjects: ObjectDefinitionNode[];
    classMethodParameterObjects: ObjectDefinitionNode[];
    classReceptionObjects: ObjectDefinitionNode[];
    deletedClassObjectNodeIds: NodeId[];
    modelEvents: EventDescriptorMacros[];
};

const defaultAttributeIds: string[] = [
    'id',
    'name',
    'multilingualName',
    'objectType',
    'language',
    'createdAt',
    'createdBy',
    'updatedAt',
    'updatedBy',
    'modelTypeId',
    'confidential',
    'direction',
    'edgeType',
    'endArrow',
    'lineType',
    'linkedObjectTypes',
    'startArrow',
    'folderType',
    'userEditDisabled',
    'scriptEngineEditDisabled',
];

const MULTILINGUAL_NAME = 'multilingualName';
const maxFileSizeLimit = 250;

// кэширование происходит тольео по первому атрибуту функции (реализация lodash.memoize())
// так как symbolAttributeStyles меняется только после перезагрузки приложения при изменении методологии,
// то в качестве ключа используем symbolId (первый аргумент функции)
const getPresetSymbolStyles = memoize(
    (symbolId?: string, symbolAttributeStyles?: SymbolAttributeTypeStyleDTO[]) =>
        symbolAttributeStyles?.filter((a) => a.symbolId === symbolId) || [],
);

class ObjectPropertiesDialog extends Component<TObjectPropertiesDialogProps> {
    state: TObjectPropertiesDialogState = {
        ignorePresetStyles: this.props.ignorePresetStyles,
        node: this.props.node,
        changedProperties: {},
        modelSettingsChangedProperties: {},
        diagramElementChangedProperties: {},
        changedAttributes: [],
        diagramElementChangedAttributes: [],
        serverId: this.props.serverId,
        removed: [],
        removedDiagramElementAttributes: [],
        edgeInstance: this.props.edgeInstance,
        rulesIsValid: true,
        classPropertyObjects: this.props.classPropertyObjects,
        classMethodObjects: this.props.classMethodObjects,
        classMethodParameterObjects: this.props.classMethodParameterObjects,
        classReceptionObjects: this.props.classReceptionObjects,
        deletedClassObjectNodeIds: [],
        modelEvents: this.props.modelEvents || [],
    };

    floatingAttributesRef = createRef<TFloatingAttributesDialogContentRef>();

    componentDidUpdate(prevProps: TObjectPropertiesDialogProps) {
        if (!isEqual(prevProps.modelEvents, this.props.modelEvents) && this.props.modelEvents)
            this.setModelEvents(this.props.modelEvents);
    }

    onChangeClassPropertyObjects = (objects: ObjectDefinitionNode[]) => {
        this.setState({ classPropertyObjects: objects });
    };

    onChangeClassMethodObjects = (objects: ObjectDefinitionNode[]) => {
        this.setState({ classMethodObjects: objects });
    };

    onChangeClassMethodParameterObjects = (objects: ObjectDefinitionNode[]) => {
        this.setState({ classMethodParameterObjects: objects });
    };

    onChangeClassReceptionObjects = (objects: ObjectDefinitionNode[]) => {
        this.setState({ classReceptionObjects: objects });
    };

    onDeleteClassObjectNodeIds = (nodeIds: NodeId[]) => {
        this.setState({ deletedClassObjectNodeIds: nodeIds });
    };

    setModelEvents = (modelEvents: EventDescriptorMacros[]) => {
        this.setState({ modelEvents });
    };

    toNodeId = (id: string): NodeId => {
        return this.state.node
            ? { ...this.state.node.nodeId, id }
            : { id, serverId: this.props.serverId, repositoryId: this.props.repositoryId };
    };

    handleChangeFloatingAttributes = (styledAttributeTypes: StyledAttributeType[]) => {
        const { graph, cellId, node, onChangeFloatingAttributes } = this.props;
        const { ignorePresetStyles } = this.state;
        const cell = graph?.getModel().getCell(cellId || '');
        const attributeStyles: DiagramElementAttributeStyle[] = styledAttributeTypes.map(
            ({ id: typeId, styles, attributeDiscriminator }) => ({
                typeId,
                styles,
                attributeDiscriminator,
            }),
        );
        const value = cell?.getValue();

        if (cell && value) {
            value.attributeStyles = attributeStyles;
            value.ignorePresetStyles = ignorePresetStyles;

            if (!node) return;

            onChangeFloatingAttributes(node.nodeId);
        }
    };

    handleClickSubmit = () => {
        if (!this.state.rulesIsValid) return;

        const { graphId, cellId, allAttributeTypes } = this.props;
        const {
            changedProperties,
            modelSettingsChangedProperties,
            changedAttributes,
            diagramElementChangedProperties,
            node,
            serverId,
            removed,
            removedDiagramElementAttributes,
            diagramElementChangedAttributes,
            classPropertyObjects,
            classMethodObjects,
            classMethodParameterObjects,
            classReceptionObjects,
            deletedClassObjectNodeIds,
        } = this.state;

        if (node) {
            // сохранить узел
            const newValue = { ...node } as Node;

            // замена старых атрибутов на новые, можно было бы проще написать, но необходимо чтобы при переоткрытии свойств объекта порядок актрибутов сохранялся
            // подменяем старое значение атрибута на новое
            newValue.attributes = (node.attributes || [])
                .map((attr) => {
                    const changedValue = changedAttributes.find((changeAttr) => changeAttr.id === attr.id);

                    return changedValue || attr;
                })
                .filter((attr) => !removed.find((rmId) => attr.id === rmId));

            // добавляем новые значения
            // если атрибут был сначала добавлен а потом удален то он может сохранится
            changedAttributes
                .filter((changeAttr) => !newValue.attributes?.find((attr) => changeAttr.id === attr.id))
                .forEach((changeAttr) => newValue.attributes?.push(changeAttr));

            Object.values({ ...changedProperties, ...modelSettingsChangedProperties }).forEach(
                (nodeProperty: TChangedProperty) => {
                    if (defaultAttributeIds.some((id) => nodeProperty.descriptor.key === id)) {
                        if (nodeProperty.descriptor.key === MULTILINGUAL_NAME) {
                            newValue.multilingualName = (nodeProperty.value as AttributeValueString).str;
                            newValue.name = (nodeProperty.value as AttributeValueString).value;
                        } else {
                            newValue[nodeProperty.descriptor.key] = nodeProperty.value?.value;
                        }
                    }
                },
            );

            this.props.syncGraphOnAttributeChange(diagramElementChangedAttributes, graphId, cellId);

            if (
                node.type === TreeItemType.ObjectDefinition &&
                (node as ObjectDefinitionNode).objectTypeId === UML_OBJECT_TYPE.CLASS
            ) {
                const newMethods = classMethodObjects.filter(
                    (method) => !this.props.classMethodObjects.find((obj) => obj.nodeId.id === method.nodeId.id),
                );

                const classMethodsWithChildrenIdOrder = classMethodObjects.map((item) => {
                    const childrenIdOrder = classMethodParameterObjects
                        .filter((param) => param.parentNodeId?.id === item.nodeId.id)
                        .map((param) => param.nodeId.id);

                    return { ...item, childrenIdOrder };
                });

                const classParametres = [
                    ...classPropertyObjects,
                    ...classMethodsWithChildrenIdOrder,
                    ...classReceptionObjects,
                ];

                newValue.childrenIdOrder = classParametres.map((item) => item.nodeId.id);

                this.props.onSubmitUmlObjects(
                    [newValue, ...classParametres, ...classMethodParameterObjects],
                    deletedClassObjectNodeIds,
                    newMethods,
                    serverId,
                    node,
                );
            }

            if (node.type === TreeItemType.Model && !isEqual(this.props.modelEvents, this.state.modelEvents)) {
                this.props.saveModelEvents(node.nodeId, this.state.modelEvents);
            }

            this.props.onSubmit(serverId, changedProperties, newValue);
        }

        if (graphId && cellId) {
            this.floatingAttributesRef.current?.submit();

            const cell = this.props.graph?.getModel().getCell(cellId || '');

            if (cell) {
                this.props.onSubmitFloatingAttributes();
            }

            // сохранить элемент диаграммы
            if (allAttributeTypes.length) {
                diagramElementChangedAttributes.forEach((attr) => {
                    const attrType = allAttributeTypes.find((at) => at.id === attr.typeId);

                    if (attrType) {
                        const descriptor = buildPropDescriptorByAttrType(attrType, attr.id);

                        diagramElementChangedProperties[descriptor.key] = {
                            descriptor,
                            value: attr,
                            graphId,
                            cellId,
                        };
                    }
                });
            }

            // для связи имя необходимо установить не только в определении но и в экземпляре (определения может небыть)
            if (this.props.edgeInstance && changedProperties[MULTILINGUAL_NAME]) {
                diagramElementChangedProperties[MULTILINGUAL_NAME] = { ...changedProperties[MULTILINGUAL_NAME] };
            }

            if (Object.values(diagramElementChangedProperties).length || removedDiagramElementAttributes?.length) {
                this.props.onSubmitDiagramElement(
                    graphId,
                    cellId,
                    diagramElementChangedProperties as TNavigatorPropertiesData,
                    removedDiagramElementAttributes,
                );
            }

            if (!node && this.props.edgeInstance && this.props.graph) {
                this.props.onSubmitEdgeInstance(this.props.graph);
            }
        }
    };

    onCancel = () => this.props.onCancel();

    setStateAssignments = (modelAssignments: ModelAssignment[]) => {
        const { node } = this.state;

        if (node) {
            const newNode = { ...node, modelAssignments };
            this.setState({ node: newNode });
        }
    };

    onChangePresetId = (presetId: string) => {
        this.setState((prevState: Readonly<TObjectPropertiesDialogState>) => ({
            node: { ...prevState.node, presetId },
        }));
    };

    onChangeFileSize = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (value === '0') return;

        const maxFileSize = Number(convertToNumber(value));

        this.setState((prevState: Readonly<TObjectPropertiesDialogState>) => ({
            node: { ...prevState.node, maxFileSize: Math.min(maxFileSize, maxFileSizeLimit) },
        }));
    };

    onChangeTradeSecret = (e) => {
        const checked = e?.target?.checked;
        this.setState((prevState: Readonly<TObjectPropertiesDialogState>) => ({
            node: { ...prevState.node, tradeSecret: checked },
        }));
    };

    onChangePersonalData = (e) => {
        const checked = e?.target?.checked;
        this.setState((prevState: Readonly<TObjectPropertiesDialogState>) => ({
            node: { ...prevState.node, personalData: checked },
        }));
    };

    render() {
        const {
            visible,
            intl,
            symbol,
            attributeTypes,
            graphId,
            isEdge,
            propertiesData,
            modelSettingsData,
            diagramElementAttributeTypes,
            graph,
            cellId,
            instancePropertiesData,
            repositoryId,
            isEntityEditable,
            locale,
            folderTypes,
            allNotationFolderTypes,
            tab,
            principals,
            hasDefinitionOfEdgeInstance,
            isAllowedApprovals,
            isModelTypeDeleted,
            isSubscribedToNode,
            onSubscribe,
            onUnsubscribe,
        } = this.props;
        const { node, serverId, changedProperties, modelSettingsChangedProperties, edgeInstance } = this.state;
        const name = changedProperties.name?.value?.value || node?.name || edgeInstance?.name;
        const typeMessage = intl.formatMessage(messages[node?.type || (isEdge ? 'EDGE' : 'OBJECT')]);
        const isObject = node?.type === TreeItemType.ObjectDefinition;
        const isScript = node?.type === TreeItemType.Script;
        const hasErrors = () => !(isEdge || !changedProperties.name || changedProperties.name?.value);
        const isUmlClassObject =
            this.props.node?.type === TreeItemType.ObjectDefinition &&
            (this.props.node as ObjectDefinitionNode)?.idSymbol === UML_ID_SYMBOL.CLASS;
        const isEdgeDefinition = this.props.node?.type === TreeItemType.EdgeDefinition;
        const isInvisibleEdge = this.props.edgeInstance?.invisible;

        const dialogTitle = (
            <div className={theme.title}>
                <div className={theme.entityOfProperty}>
                    {intl.formatMessage(messages.title, { type: typeMessage })}
                </div>
                <div className={theme.subTitle}>
                    {symbol && <img alt="" src={symbol.icon} className={theme.subTitleIcon} />}
                    {!symbol && <Icon className={theme.subTitleIcon} spriteSymbol={objectIcon} />}
                    <span className={theme.name}>{trimStringValue(name)}</span>
                </div>
                {node && node.type !== TreeItemType.FileFolder && ExperimentalFeatures.isEnable() ? (
                    <button
                        type="button"
                        className={theme.subscribeBtn}
                        onClick={() => {
                            isSubscribedToNode ? onUnsubscribe(node.nodeId) : onSubscribe(node.nodeId);
                        }}
                    >
                        <Icon spriteSymbol={isSubscribedToNode ? icUnSubscribe : icSubscribe} />
                    </button>
                ) : null}
            </div>
        );

        const footer = [
            <Button data-test="properties-window_cancel-button" key="cancel" size="large" onClick={this.onCancel}>
                {intl.formatMessage(messages.cancelButton)}
            </Button>,
            <Tooltip
                key="needLicenseTooltip"
                title={this.props.isModelEditor ? '' : intl.formatMessage(messages.needLicense)}
            >
                <Button
                    data-test="properties-window_ok-button"
                    key="ok"
                    size="large"
                    type="primary"
                    onClick={this.handleClickSubmit}
                    disabled={hasErrors() || !this.props.isModelEditor}
                    className={theme.buttonWithTooltipe}
                >
                    {intl.formatMessage(messages.okButton)}
                </Button>
            </Tooltip>,
        ];
        const cell: MxCell | undefined = graph?.getModel().getCell(cellId || '');
        const cellValue: EdgeInstanceImpl | ObjectInstanceImpl | undefined = cell?.getValue();
        const modelType: ModelType | undefined = graph?.modelType;
        const presetSymbolStyles: SymbolAttributeTypeStyleDTO[] = getPresetSymbolStyles(
            symbol?.id,
            modelType?.symbolAttributeStyles,
        );
        const presetEdgeStyles: EdgeTypeAttributeTypeStyleDTO[] | undefined = modelType?.edgeAttributeStyles;
        const edgeTypeId: string | undefined = (cellValue as EdgeInstanceImpl | undefined)?.edgeTypeId;
        const presetStylesByEdgeId: EdgeTypeAttributeTypeStyleDTO[] =
            presetEdgeStyles?.filter((style) => edgeTypeId && style.edgeTypeId === edgeTypeId) || [];
        const objectStyles: TObjectStyle[] | undefined = (cellValue as ObjectInstanceImpl | undefined)
            ?.attributeStyles as TObjectStyle[];
        const width = isUmlClassObject ? 1200 : 850;
        const edgeEntriesData = node ? (node as EdgeDefinitionNode).edgeEntries : this.props.edgeEntries;

        const tabs = [
            {
                label: intl.formatMessage(messages.nodeAttributesTabTitle),
                key: ObjectPropertiesDialogActiveTab.NameAndAttributes,
                children: (
                    <AttributesTab
                        isEntityEditable={isEntityEditable}
                        disabled={isEdge && this.props.modelLocked && !hasDefinitionOfEdgeInstance}
                        locale={locale}
                        tabType="nodeAttributes"
                        cellId={cellId}
                        graphId={graphId}
                        nodeId={node?.nodeId}
                        repositoryId={repositoryId}
                        propertiesData={propertiesData}
                        removed={this.state.removed}
                        typeMessage={typeMessage}
                        attributeTypes={attributeTypes}
                        serverId={serverId}
                        changedProperties={changedProperties}
                        changedAttributes={this.state.changedAttributes}
                        onAddAttribute={(changedAttributes) => this.setState({ changedAttributes })}
                        onDeleteAttribute={(removed, changedAttributes) =>
                            this.setState({ removed, changedAttributes })
                        }
                        onChangeAttributeValue={(changedAttributes, changedProps) =>
                            this.setState({ changedAttributes, changedProperties: changedProps })
                        }
                        isEdge={isEdge}
                        folderTypes={folderTypes}
                        allNotationFolderTypes={allNotationFolderTypes}
                        principals={principals}
                        isModelTypeDeleted={isModelTypeDeleted}
                    />
                ),
            },
        ];

        if (node?.type === 'DB') {
            tabs.push(
                {
                    label: intl.formatMessage(messages.methodologySelectionTabTitle),
                    key: ObjectPropertiesDialogActiveTab.MethodologySelection,
                    children: (
                        <MethodologyChangeTab
                            presetId={(node as RepositoryNode)?.presetId}
                            onChangePresetId={this.onChangePresetId}
                        />
                    ),
                },
                {
                    label: intl.formatMessage(messages.additionalTabTitle),
                    key: ObjectPropertiesDialogActiveTab.Additional,
                    children: (
                        <>
                            <div className={theme.sizeFileContainer}>
                                {intl.formatMessage(messages.maxSizeFile)}
                                <Input
                                    className={theme.sizeFileInput}
                                    value={(node as RepositoryNode)?.maxFileSize || ''}
                                    onChange={this.onChangeFileSize}
                                />
                            </div>
                            <div className={theme.checkBoxesContainer}>
                                <Checkbox
                                    onChange={this.onChangeTradeSecret}
                                    checked={(node as RepositoryNode)?.tradeSecret}
                                >
                                    {intl.formatMessage(messages.tradeSecret)}
                                </Checkbox>
                                <Checkbox
                                    onChange={this.onChangePersonalData}
                                    checked={(node as RepositoryNode)?.personalData}
                                >
                                    {intl.formatMessage(messages.personalData)}
                                </Checkbox>
                            </div>
                        </>
                    ),
                },
            );
        }

        if (this.props.cellId) {
            tabs.push({
                label: intl.formatMessage(messages.objectInstanceAttributesTabTitle),
                key: ObjectPropertiesDialogActiveTab.ObjectInstanceAttributes,
                children: (
                    <AttributesTab
                        isEntityEditable={isEntityEditable}
                        disabled={this.props.modelLocked}
                        locale={locale}
                        propertiesData={instancePropertiesData}
                        tabType="objectInstanceAttributes"
                        removed={this.state.removedDiagramElementAttributes}
                        typeMessage={typeMessage}
                        attributeTypes={diagramElementAttributeTypes || []}
                        serverId={serverId}
                        changedProperties={this.state.diagramElementChangedProperties}
                        changedAttributes={this.state.diagramElementChangedAttributes}
                        cellId={cellId}
                        graphId={graphId}
                        nodeId={node?.nodeId}
                        repositoryId={repositoryId}
                        onAddAttribute={(diagramElementChangedAttributes) =>
                            this.setState({ diagramElementChangedAttributes })
                        }
                        onChangeAttributeValue={(diagramElementChangedAttributes, diagramElementChangedProperties) =>
                            this.setState({
                                diagramElementChangedAttributes,
                                diagramElementChangedProperties,
                            })
                        }
                        onDeleteAttribute={(removedDiagramElementAttributes, diagramElementChangedAttributes) =>
                            this.setState({
                                removedDiagramElementAttributes,
                                diagramElementChangedAttributes,
                            })
                        }
                        principals={principals}
                        isModelTypeDeleted={isModelTypeDeleted}
                    />
                ),
            });
        }

        if (isEdgeDefinition || (hasDefinitionOfEdgeInstance && isEdge)) {
            tabs.push({
                label: intl.formatMessage(messages.edgeInstances),
                key: ObjectPropertiesDialogActiveTab.EdgeInstances,
                children: (
                    <ObjectInstances objectEntries={edgeEntriesData} serverId={serverId} toNodeId={this.toNodeId} />
                ),
            });
        }

        if (isObject) {
            tabs.push({
                label: intl.formatMessage(messages.objectInstances),
                key: ObjectPropertiesDialogActiveTab.ObjectInstances,
                children: (
                    <ObjectInstances
                        objectEntries={(node as ObjectDefinitionNode).objectEntries}
                        serverId={serverId}
                        toNodeId={this.toNodeId}
                    />
                ),
            });
        }

        if (isObject || isEdgeDefinition || (hasDefinitionOfEdgeInstance && isEdge)) {
            tabs.push({
                label: intl.formatMessage(messages.detailingTabTitle),
                key: ObjectPropertiesDialogActiveTab.Detailing,
                children: (
                    <DecompositionTab
                        objectNode={node}
                        toNodeId={this.toNodeId}
                        setStateAssignments={this.setStateAssignments}
                        isEntityEditable={isEntityEditable}
                        isModelEditor={this.props.isModelEditor}
                        graphId={{ id: graph?.id.id || '', serverId, repositoryId }}
                    />
                ),
            });
        }

        if (isObject && isUmlClassObject) {
            tabs.push(
                {
                    label: intl.formatMessage(messages.classProperties),
                    key: ObjectPropertiesDialogActiveTab.ClassProperties,
                    children: (
                        <ClassPropertiesTab
                            nodeId={this.props.node?.nodeId!}
                            classPropertyObjects={this.state.classPropertyObjects}
                            onChangeClassPropertyObjects={this.onChangeClassPropertyObjects}
                            deletedClassObjectNodeIds={this.state.deletedClassObjectNodeIds}
                            onDeleteClassObjectNodeIds={this.onDeleteClassObjectNodeIds}
                        />
                    ),
                },
                {
                    label: intl.formatMessage(messages.classMethods),
                    key: ObjectPropertiesDialogActiveTab.ClassMethods,
                    children: (
                        <ClassMethodsTab
                            nodeId={this.props.node?.nodeId!}
                            classMethodObjects={this.state.classMethodObjects}
                            onChangeClassMethodObjects={this.onChangeClassMethodObjects}
                            classMethodParameterObjects={this.state.classMethodParameterObjects}
                            onChangeClassMethodParameterObjects={this.onChangeClassMethodParameterObjects}
                            deletedClassObjectNodeIds={this.state.deletedClassObjectNodeIds}
                            onDeleteClassObjectNodeIds={this.onDeleteClassObjectNodeIds}
                        />
                    ),
                },
                {
                    label: intl.formatMessage(messages.classReceptions),
                    key: ObjectPropertiesDialogActiveTab.ClassReceptions,
                    children: (
                        <ClassReceptionsTab
                            nodeId={this.props.node?.nodeId!}
                            classReceptionObjects={this.state.classReceptionObjects}
                            onChangeClassReceptionObjects={this.onChangeClassReceptionObjects}
                            deletedClassObjectNodeIds={this.state.deletedClassObjectNodeIds}
                            onDeleteClassObjectNodeIds={this.onDeleteClassObjectNodeIds}
                        />
                    ),
                },
            );
        }

        if (isObject && cell) {
            tabs.push({
                label: intl.formatMessage(messages.floatingAttributes),
                key: ObjectPropertiesDialogActiveTab.FloatingAttributes,
                children: (
                    <SymbolFloatingAttributesPanel
                        ref={this.floatingAttributesRef}
                        presetStyles={presetSymbolStyles}
                        objectStyles={objectStyles}
                        definitionAttributeTypes={attributeTypes}
                        instanceAttributeTypes={diagramElementAttributeTypes}
                        ignorePresetStyles={this.state.ignorePresetStyles}
                        modelLocked={this.props.modelLocked}
                        onToggleIgnorePresetStyles={(value: boolean) => {
                            this.setState({ ignorePresetStyles: value });
                        }}
                        onChange={this.handleChangeFloatingAttributes}
                        setRulesValidity={(rulesIsValid: boolean) => this.setState({ rulesIsValid })}
                    />
                ),
            });
        }

        if (isEdge && cell && !isInvisibleEdge) {
            tabs.push({
                label: intl.formatMessage(messages.floatingEdgeAttributes),
                key: ObjectPropertiesDialogActiveTab.FloatingAttributes,
                children: (
                    <EdgeFloatingAttributesPanel
                        ref={this.floatingAttributesRef}
                        presetStyles={presetStylesByEdgeId}
                        objectStyles={objectStyles}
                        definitionAttributeTypes={attributeTypes}
                        instanceAttributeTypes={diagramElementAttributeTypes}
                        ignorePresetStyles={this.state.ignorePresetStyles}
                        modelLocked={this.props.modelLocked}
                        onToggleIgnorePresetStyles={(value: boolean) => {
                            this.setState({ ignorePresetStyles: value });
                        }}
                        onChange={this.handleChangeFloatingAttributes}
                        setRulesValidity={(rulesIsValid: boolean) => this.setState({ rulesIsValid })}
                    />
                ),
            });
        }

        if (node?.type === TreeItemType.Model || node?.type === TreeItemType.Matrix) {
            tabs.push({
                label: intl.formatMessage(messages.parents),
                key: ObjectPropertiesDialogActiveTab.ParentObjects,
                children: (
                    <ParentObjects
                        toNodeId={this.toNodeId}
                        parentObjectsInfo={(node as ModelNode).parentNodesInfo || []}
                    />
                ),
            });
        }

        if (node?.type === TreeItemType.Model) {
            tabs.push({
                label: intl.formatMessage(messages.nodeSettings),
                key: ObjectPropertiesDialogActiveTab.NodeSettings,
                children: (
                    <NodeSettings
                        nodeId={node.nodeId}
                        changedProperties={modelSettingsChangedProperties}
                        propertiesData={modelSettingsData}
                        onChangeProperties={(settings) => this.setState({ modelSettingsChangedProperties: settings })}
                        modelEvents={this.state.modelEvents}
                        setModelEvents={this.setModelEvents}
                    />
                ),
            });
        }

        if (isScript) {
            const script: ScriptNode = this.props.node as ScriptNode;

            tabs.push({
                label: intl.formatMessage(messages.scriptRunningContext),
                key: ObjectPropertiesDialogActiveTab.ScriptRunningContext,
                children: <ScriptContext script={script} />,
            });
        }

        if (isAllowedApprovals) {
            tabs.push({
                label: intl.formatMessage(messages.approvals),
                key: ObjectPropertiesDialogActiveTab.Approvals,
                children: <ApprovalsTab approvedItemNodeId={this.props.node?.nodeId!} />,
            });
        }

        return (
            <div
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
            >
                <Dialog
                    open={visible}
                    className={theme.dialog}
                    onCancel={this.onCancel}
                    onOk={this.handleClickSubmit}
                    style={{ height: 750, maxHeight: '95vh' }}
                    closable
                    width={width}
                    maskClosable
                    footer={footer}
                    title={dialogTitle}
                >
                    <Tabs
                        tabPosition="left"
                        className={theme.tabs}
                        defaultActiveKey={tab || ObjectPropertiesDialogActiveTab.NameAndAttributes}
                        items={tabs}
                    />
                </Dialog>
            </div>
        );
    }
}

const IntlComponent = injectIntl(ObjectPropertiesDialog);

export { IntlComponent as ObjectPropertiesDialog };
