import { createSelector } from 'reselect';
import { isPlainObject } from 'lodash';
import { TRootState } from '../reducers/root.reducer.types';
import { INITIAL_OBJECT_TYPE_SERVER_STATE } from '../reducers/objectType.reducer';
import { NodeId, ObjectType } from '../serverapi/api';
import { TreeSelectors } from './tree.selectors';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { ObjectDefinitionSelectors } from './objectDefinition.selectors';

export const objectTypeStateSelector = (state: TRootState) => state.objectType;

export namespace ObjectTypeSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(objectTypeStateSelector, (state) => {
            const s = state.byServerId[serverId];
            if (s) {
                return { ...s };
            }

            return { ...INITIAL_OBJECT_TYPE_SERVER_STATE };
        });

    export const isLoaded = (serverId: string, presetId: string) =>
        createSelector(
            objectTypeStateSelector,
            (state) => !!(state.byServerId[serverId] && state.byServerId[serverId][presetId]),
        );

    export const byPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(
            byServerId(compositeId.serverId),
            (
                state,
            ):
                | {
                      byId: {
                          [id: string]: ObjectType;
                      };
                  }
                | undefined => state.byPresetId[compositeId.presetId],
        );

    export const listAllByPreset = (serverId: string, presetId: string) =>
        createSelector(byPresetId({ serverId, presetId }), (state) => toObjectTypeArray(state));

    const toObjectTypeArray = (objectTypesMap): ObjectType[] => {
        return (objectTypesMap && Object.keys(objectTypesMap.byId).map((k) => objectTypesMap.byId[k])) || [];
    };

    export const byId = (compositeId: { objectTypeId: string; presetId: string; serverId: string }) =>
        createSelector(byPresetId({ serverId: compositeId.serverId, presetId: compositeId.presetId }), (state) => {
            return state?.byId && isPlainObject(state.byId) ? state.byId[compositeId.objectTypeId] : undefined;
        });
    export const byIds = (compositeId: { objectTypeIds: string[]; presetId: string; serverId: string }) =>
        createSelector(byPresetId({ serverId: compositeId.serverId, presetId: compositeId.presetId }), (state) =>
            compositeId.objectTypeIds.map((id) => state?.byId[id]).filter((type) => type),
        );
    export const byObjectDefinitionId = (objectDefinitionId: string, graphId: NodeId) =>
        createSelector(
            (state: TRootState) => {
                const presetId: string = TreeSelectors.presetById(graphId)(state);
                const { serverId, repositoryId } = graphId;
                const objectDef: ObjectDefinitionImpl | undefined = ObjectDefinitionSelectors.byId({
                    id: objectDefinitionId,
                    repositoryId,
                    serverId,
                })(state);
                const objectTypeId: string | undefined = objectDef?.objectTypeId;
                if (objectTypeId) {
                    const objectType: ObjectType | undefined = ObjectTypeSelectors.byId({
                        presetId,
                        serverId,
                        objectTypeId,
                    })(state);

                    if (objectType) {
                        return objectType;
                    }
                }

                return undefined;
            },
            (objectType: ObjectType | undefined) => objectType,
        );
}
