import type { TreeNode, TTreeEntityState } from '../models/tree.types';
import type { IScriptNode } from '../models/bpm/bpm-model-impl.types';
import type { ObjectDefinitionNode } from '../serverapi/api';
import type { TTreeState } from './tree.reducer.types';
import type { TReducer } from '../utils/types';
import {
    TREE_FILTER,
    TREE_ITEM_ADD,
    TREE_ITEM_CHILD_ADD,
    TREE_ITEM_COLLAPSE_ALL,
    TREE_ITEM_COLLAPSE_SUCCESS,
    TREE_ITEM_DELETE,
    TREE_ITEM_END_DRAG,
    TREE_ITEM_EXPAND_SUCCESS,
    TREE_ITEM_FETCH_CHILD_SUCCESS,
    TREE_ITEM_RENAME_CANCEL,
    TREE_ITEM_RENAME_REQUEST,
    TREE_ITEM_SCROLL,
    TREE_ITEM_SELECT,
    TREE_ITEM_START_DRAG,
    TREE_ITEM_UPDATE,
    TREE_ITEMS_EXPAND_WITHOUT_LOAD,
    TREE_ITEMS_SELECT_SLICE,
    TREE_ITEMS_SELECT_SEVERAL,
    TREE_PART_FETCH_SUCCESS,
    TREE_ITEM_EXPANDING,
    TREE_ITEM_UML_OBJECTS_UPDATE,
    SET_SHOW_DELETED_OBJECTS_FILTER_SUCCESS,
} from '../actionsTypes/tree.actionTypes';
import { TreeItemType } from '../modules/Tree/models/tree';
import { compareNodeIds } from '../utils/nodeId.utils';
import { ExpandStatus } from './tree.reducer.types';
import { getNodeWithChildrenFromNodes } from '../services/utils/treeService.utils';
import { cloneDeep } from 'lodash';
import { UML_OBJECT_TYPE } from '../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import { SCRIPT_CONTEXT_DELETE, SCRIPT_CONTEXT_SAVE_SUCCESS } from '../actionsTypes/entities/script.actionTypes';
import { scriptContextTypes } from '../modules/ObjectPropertiesDialog/components/ScriptContext/scriptContext.types';

const initial: TTreeState = {
    byServerId: {},
    appliedFilters: [TreeItemType.ObjectDefinition],
    showDeletedObjectsFilter: false,
    renamingId: undefined!,
    draggedId: undefined,
    expanded: {},
    selectedNode: undefined,
    selectedNodes: [],
    scrolledNodeId: undefined,
};

export const treeReducer: TReducer<TTreeState> = (state = initial, action) => {
    // todo replace to lodash clone
    const setSetInClonedObject = (obj: TTreeState, newObj: TTreeState) => {
        if (!obj || !(obj instanceof Object)) {
            return;
        }
        for (const t in obj) {
            if (obj[t] instanceof Set) {
                newObj[t] = new Set(obj[t]);
            } else {
                setSetInClonedObject(obj[t], newObj[t]);
            }
        }
    };

    switch (action.type) {
        case TREE_ITEM_ADD: {
            const {
                payload: {
                    item,
                    item: {
                        nodeId: { serverId, repositoryId, id },
                    },
                },
            } = action;
            const stateClone: TTreeState = JSON.parse(JSON.stringify(state));
            setSetInClonedObject(state, stateClone);

            if (!stateClone.byServerId[serverId]) {
                stateClone.byServerId[serverId] = {
                    byRepositoryId: {},
                };
            }
            if (!stateClone.byServerId[serverId].byRepositoryId[repositoryId]) {
                stateClone.byServerId[serverId].byRepositoryId[repositoryId] = {
                    byId: {},
                };
            }
            const { byId } = stateClone.byServerId[serverId].byRepositoryId[repositoryId];
            byId[id] = {
                ...item,
                isFetching: false,
                childrenIds: item.children ? item.children.map((c) => c.nodeId.id) : [],
                hasChildren: !!item.countChildren,
                children: [],
            };
            if (
                item.parentNodeId &&
                byId[item.parentNodeId.id] &&
                !byId[item.parentNodeId.id].childrenIds?.includes(id)
            ) {
                byId[item.parentNodeId.id].childrenIds = [...(byId[item.parentNodeId.id].childrenIds || []), id];
                byId[item.parentNodeId.id].hasChildren = true;
                byId[item.parentNodeId.id].countChildren = byId[item.parentNodeId.id].childrenIds.length;
            }
            stateClone.expanded = { ...state.expanded };

            return stateClone;
        }

        case TREE_PART_FETCH_SUCCESS: {
            const {
                payload: { parentNodeId, nodes, serverId },
            } = action;

            if (!nodes || !nodes.length || !serverId) {
                return state;
            }

            const server = state.byServerId[serverId];

            let byRepositoryId = {};

            byRepositoryId = nodes.reduce((acc, item) => {
                const {
                    nodeId: { id, repositoryId },
                    countChildren,
                } = item;

                if (!acc?.[repositoryId]) acc[repositoryId] = { byId: {} };

                const oldParentId = acc[repositoryId]?.byId[id]?.parentNodeId?.id;
                // какой-то конфликт типов TreeNode(tree.ts) и Node(api.ts)
                // TODO Разобраться чего не хватает в типах
                // @ts-ignore
                acc[repositoryId].byId[id] = {
                    ...item,
                    isFetching: false,
                    countChildren: countChildren || 0,
                    hasChildren: !!(countChildren && countChildren > 0),
                    childrenIds: server?.byRepositoryId?.[repositoryId]?.byId?.[id]?.childrenIds || [],
                    children: [],
                };

                const newParentId = item.parentNodeId?.id;

                if (oldParentId && newParentId && oldParentId !== newParentId) {
                    const oldParentNode = acc[repositoryId].byId[oldParentId];
                    if (oldParentNode) {
                        acc[repositoryId].byId[oldParentId].childrenIds = oldParentNode.childrenIds.filter(
                            (childId) => childId !== id,
                        );
                        acc[repositoryId].byId[oldParentId].hasChildren = oldParentNode.childrenIds?.length > 0;
                        acc[repositoryId].byId[oldParentId].countChildren = oldParentNode.childrenIds?.length || 0;
                    }
                }

                return acc;
            }, server?.byRepositoryId || {});

            if (server && parentNodeId) {
                const repository = server.byRepositoryId[parentNodeId.repositoryId];
                const parent = repository ? repository.byId[parentNodeId.id] : undefined;

                if (server && parent) {
                    byRepositoryId[parentNodeId.repositoryId].byId[parentNodeId.id] = {
                        ...parent,
                        childrenIds: nodes.map((n) => n.nodeId.id),
                        hasChildren: nodes.length > 0,
                    };
                }
            }

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        byRepositoryId,
                    },
                },
            };
        }

        case TREE_ITEM_CHILD_ADD:
        case TREE_ITEM_FETCH_CHILD_SUCCESS: {
            const {
                payload: {
                    parentNodeId: { id: parentId, repositoryId, serverId },
                    child,
                },
            } = action;

            let byRepositoryId = child.reduce((acc, item) => {
                let hasChildren = item.children ? item.children.length > 0 : false;
                let childrenIds = item.children ? item.children.map((c) => c.nodeId.id) : [];
                if (item.objectTypeId === UML_OBJECT_TYPE.METHOD) {
                    hasChildren = item.countChildren > 0;
                    childrenIds =
                        state.byServerId[serverId]?.byRepositoryId[repositoryId]?.byId[item.nodeId.id]?.childrenIds ||
                        [];
                }
                return {
                    ...acc,
                    [item.nodeId.repositoryId]: {
                        byId: {
                            ...(acc[item.nodeId.repositoryId] ? acc[item.nodeId.repositoryId].byId : []),
                            [item.nodeId.id]: {
                                ...item,
                                nodeId: {
                                    ...item.nodeId,
                                    serverId,
                                },
                                isFetching: false,
                                childrenIds,
                                hasChildren,
                                children: [],
                            },
                        },
                    },
                };
            }, {});

            const server = state.byServerId[serverId];
            const repository = server ? server.byRepositoryId[repositoryId] : undefined;
            const parent = repository ? repository.byId[parentId] : undefined;

            const childrenIds: string[] = Array.from(
                new Set([
                    // merge old child ids and newly added ids
                    ...child.map((c) => c.nodeId.id), // todo : remove spread operator
                    ...(parent ? parent.childrenIds : []), // todo: remove spread operator
                ]),
            );

            child.forEach((node) => treeToMap(node, byRepositoryId, serverId));
            if (server && repository) {
                byRepositoryId = {
                    ...server.byRepositoryId,
                    [repositoryId]: {
                        byId: {
                            ...repository.byId,
                            ...byRepositoryId[repositoryId].byId,
                        },
                    },
                };
                if (parent) {
                    byRepositoryId[repositoryId].byId[parentId] = {
                        ...parent,
                        childrenIds,
                        hasChildren: childrenIds.length > 0,
                        countChildren: childrenIds.length,
                    };
                }
            }

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        byRepositoryId,
                    },
                },
            };
        }

        case TREE_ITEM_DELETE: {
            const {
                payload: {
                    nodeId: { serverId, repositoryId, id },
                },
            } = action;
            const clonedState: TTreeState = JSON.parse(JSON.stringify(state));

            setSetInClonedObject(state, clonedState); // todo сделать нормальное клонирование

            const byServerId = clonedState.byServerId[serverId];

            if (!byServerId || !byServerId.byRepositoryId) {
                // do nothing
            } else if (id === repositoryId) {
                delete byServerId.byRepositoryId[id];
            } else if (byServerId.byRepositoryId[repositoryId]) {
                const rep = byServerId.byRepositoryId[repositoryId];
                const item = rep.byId[id];

                if (item && item.parentNodeId && item.parentNodeId.id) {
                    const parentSId = item.parentNodeId.id;

                    if (rep.byId[parentSId]) {
                        const childrenIds = rep.byId[parentSId].childrenIds.filter(
                            (childrenId: string) => childrenId !== id,
                        );
                        rep.byId[parentSId] = {
                            ...rep.byId[parentSId],
                            childrenIds,
                            hasChildren: childrenIds.length > 0,
                        };
                    }
                }

                delete rep.byId[id];
            }
            // todo починить удаление сервера
            /* if (Object.keys(byServerId.byRepositoryId).length === 0) {
                delete clonedState.byServerId[serverId];
            } */
            clonedState.expanded = { ...state.expanded };

            // ниже блок кода удаляет признак открытия элемента (галка),
            // если у него не осталось вложенных элементов, иначе при появлении
            // новых вложенных элементов галка становится в открытое положение при закрытом элементе
            const parentNodeId = clonedState.selectedNode?.parentNodeId;
            const arrayOfTreeNames = Object.keys(clonedState.expanded);

            arrayOfTreeNames.forEach((treeName) => {
                if (
                    parentNodeId?.id &&
                    clonedState.byServerId?.[serverId]?.byRepositoryId?.[repositoryId]?.byId[parentNodeId.id]
                        ?.hasChildren === false
                ) {
                    delete clonedState.expanded?.[treeName]?.[serverId]?.[repositoryId]?.[parentNodeId.id];
                }

                delete clonedState.expanded?.[treeName]?.[serverId]?.[repositoryId]?.[id];
            });

            return clonedState;
        }

        case TREE_ITEM_UPDATE: {
            if (!action.payload.nodeId) {
                return state;
            }
            const {
                payload: {
                    nodeId: { id, repositoryId, serverId },
                    data,
                },
            } = action;
            const stateClone = JSON.parse(JSON.stringify(state));

            setSetInClonedObject(state, stateClone);

            const server = stateClone.byServerId[serverId];

            if (!server || !server.byRepositoryId[repositoryId] || !server.byRepositoryId[repositoryId].byId[id]) {
                return state;
            }

            const updatedNode = {
                ...server.byRepositoryId[repositoryId].byId[id],
                ...data,
                deleted: data.deleted,
                deletedAt: data.deletedAt,
                deletedBy: data.deletedBy,
            };
            server.byRepositoryId[repositoryId].byId[id] = updatedNode;

            // ниже блок кода удаляет признак открытия элемента (галка),
            // если у него не осталось вложенных элементов, иначе при появлении
            // новых вложенных элементов галка становится в открытое положение при закрытом элементе
            const arrayOfTreeNames = Object.keys(stateClone.expanded);

            if (data?.hasChildren === false && arrayOfTreeNames.length > 0) {
                arrayOfTreeNames.forEach((treeName) => {
                    delete stateClone.expanded?.[treeName]?.[serverId]?.[repositoryId]?.[id];
                });
            }

            return stateClone;
        }

        case TREE_FILTER: {
            const {
                payload: { checked, filterTypes },
            } = action;
            const appliedFiltersCopy = [...state.appliedFilters];
            // todo: check will it work
            if (checked) {
                filterTypes.forEach((type) => {
                    appliedFiltersCopy.splice(appliedFiltersCopy.indexOf(type), 1);
                });

                return {
                    ...state,
                    appliedFilters: [...appliedFiltersCopy],
                };
            }

            return {
                ...state,
                appliedFilters: [...appliedFiltersCopy, ...filterTypes],
            };
        }

        case TREE_ITEM_COLLAPSE_ALL: {
            const {
                payload: { treeName },
            } = action;

            return {
                ...state,
                expanded: {
                    ...state.expanded,
                    [treeName]: {},
                },
            };
        }

        case TREE_ITEM_COLLAPSE_SUCCESS: {
            const {
                payload: { nodesId, treeName },
            } = action;

            return nodesId.reduce((newState, nodeId) => {
                const { id, serverId, repositoryId } = nodeId;
                const collapsedIds =
                    (state.expanded[treeName] &&
                        state.expanded[treeName][serverId] && {
                            ...state.expanded[treeName][serverId][repositoryId],
                            [id]: { expand: ExpandStatus.CLOSED },
                        }) ||
                    {};

                return {
                    ...newState,
                    expanded: {
                        ...newState.expanded,
                        [treeName]: {
                            ...newState.expanded[treeName],
                            [serverId]: {
                                ...(newState.expanded[treeName] && newState.expanded[treeName][serverId]),
                                [repositoryId]: collapsedIds,
                            },
                        },
                    },
                };
            }, state);
        }
        case TREE_ITEMS_EXPAND_WITHOUT_LOAD: {
            const {
                payload: { nodesId, treeName },
            } = action;

            return nodesId.reduce((newState, nodeId) => {
                const { id, serverId, repositoryId } = nodeId;
                // copy of TREE_ITEM_EXPAND
                const expandedTree = {
                    [treeName]: {
                        ...(newState.expanded[treeName] || {}),
                        [serverId]: {
                            ...(newState.expanded?.[treeName]?.[serverId] || {}),
                            [repositoryId]: {
                                ...(newState.expanded?.[treeName]?.[serverId]?.[repositoryId] || {}),
                                [id]: {
                                    expand: ExpandStatus.OPEN,
                                },
                            },
                        },
                    },
                };

                return {
                    ...newState,
                    expanded: {
                        ...newState.expanded,
                        ...expandedTree,
                    },
                };
            }, state);
        }
        case TREE_ITEM_EXPAND_SUCCESS: {
            const {
                payload: {
                    nodeId: { id, serverId, repositoryId },
                    treeName,
                },
            } = action;
            const expandedTree = {
                [treeName]: {
                    ...(state.expanded[treeName] || {}),
                    [serverId]: {
                        ...(state.expanded?.[treeName]?.[serverId] || {}),
                        [repositoryId]: {
                            ...(state.expanded?.[treeName]?.[serverId]?.[repositoryId] || {}),
                            [id]: {
                                expand: ExpandStatus.OPEN,
                            },
                        },
                    },
                },
            };

            return {
                ...state,
                expanded: {
                    ...state.expanded,
                    ...expandedTree,
                },
            };
        }

        case TREE_ITEM_EXPANDING: {
            const {
                payload: {
                    nodeId: { id, serverId, repositoryId },
                    treeName,
                },
            } = action;
            const expandedIds =
                (state.expanded[treeName] &&
                    state.expanded[treeName][serverId] && {
                        ...state.expanded[treeName][serverId][repositoryId],
                        [id]: {
                            expand: ExpandStatus.LOADING,
                        },
                    }) ||
                {};

            return {
                ...state,
                expanded: {
                    ...state.expanded,
                    [treeName]: {
                        ...state.expanded[treeName],
                        [serverId]: {
                            ...(state.expanded[treeName] && state.expanded[treeName][serverId]),
                            [repositoryId]: expandedIds,
                        },
                    },
                },
            };
        }

        case TREE_ITEM_SELECT: {
            const {
                payload: { selectedNode },
            } = action;

            return {
                ...state,
                selectedNode,
                selectedNodes: [selectedNode],
            };
        }

        case TREE_ITEMS_SELECT_SLICE: {
            const {
                payload: { selectedNodes },
            } = action;

            return {
                ...state,
                selectedNodes,
            };
        }
        case TREE_ITEMS_SELECT_SEVERAL: {
            const {
                payload: { selectedNode },
            } = action;

            return {
                ...state,
                selectedNode,
                selectedNodes: state.selectedNodes.some((n) => compareNodeIds(n.nodeId, selectedNode.nodeId))
                    ? state.selectedNodes.filter((n) => !compareNodeIds(n.nodeId, selectedNode.nodeId))
                    : [...state.selectedNodes, selectedNode],
            };
        }

        case TREE_ITEM_SCROLL: {
            const {
                payload: { scrolledNodeId },
            } = action;

            return {
                ...state,
                scrolledNodeId,
            };
        }

        case TREE_ITEM_RENAME_REQUEST: {
            const {
                payload: { nodeId },
            } = action;

            return {
                ...state,
                renamingId: nodeId,
            };
        }

        case TREE_ITEM_RENAME_CANCEL: {
            const {
                payload: { nodeId },
            } = action;

            return compareNodeIds(state.renamingId, nodeId) ? { ...state, renamingId: '' } : state;
        }

        case TREE_ITEM_START_DRAG: {
            const {
                payload: { selectedNode },
            } = action;

            return {
                ...state,
                draggedId: selectedNode.nodeId,
            };
        }
        case TREE_ITEM_END_DRAG: {
            return {
                ...state,
                draggedId: undefined,
            };
        }

        case TREE_ITEM_UML_OBJECTS_UPDATE: {
            const { objectDefinitions } = action.payload;
            const umlClassObject = objectDefinitions.find((o) => o.objectTypeId === UML_OBJECT_TYPE.CLASS);
            if (!umlClassObject) {
                return state;
            }

            const { serverId, repositoryId } = umlClassObject.nodeId;

            const stateClone: TTreeState = cloneDeep(state);
            const { byId } = stateClone.byServerId[serverId].byRepositoryId[repositoryId];

            const oldObjectNodes = Object.values(byId).filter(
                (item) => item.type === TreeItemType.ObjectDefinition,
            ) as ObjectDefinitionNode[];
            const objectNodeIdsToDelete = [
                ...getNodeWithChildrenFromNodes(umlClassObject.nodeId, oldObjectNodes).map(({ nodeId }) => nodeId),
            ];

            objectNodeIdsToDelete.forEach((nodeId) => {
                delete byId[nodeId.id];
            });

            objectDefinitions.forEach((object) => {
                const childrenIds: string[] = objectDefinitions.reduce(
                    (accum: string[], current: ObjectDefinitionNode) => {
                        if (current.parentNodeId?.id === object.nodeId.id) accum.push(current.nodeId.id);

                        return accum;
                    },
                    [],
                );

                byId[object.nodeId.id] = {
                    ...object,
                    countChildren: childrenIds.length,
                    isFetching: false,
                    hasChildren: childrenIds.length > 0,
                    childrenIds,
                } as TTreeEntityState;
            });

            stateClone.byServerId[serverId].byRepositoryId[repositoryId].byId = byId;

            return stateClone;
        }

        case SCRIPT_CONTEXT_DELETE: {
            if (!action.payload.scriptId) {
                return state;
            }
            const {
                payload: {
                    scriptId: { id, repositoryId, serverId },
                    type,
                },
            } = action;
            const stateClone = JSON.parse(JSON.stringify(state));
            setSetInClonedObject(state, stateClone);
            const server = stateClone.byServerId[serverId];
            if (!server || !server.byRepositoryId[repositoryId] || !server.byRepositoryId[repositoryId].byId[id]) {
                return state;
            }
            const updatedNode: IScriptNode = {
                ...server.byRepositoryId[repositoryId].byId[id],
            };
            if (!updatedNode.allowedScriptContext) {
                updatedNode.allowedScriptContext = {};
            }
            switch (type) {
                case scriptContextTypes.runningOnDB: {
                    updatedNode.allowedScriptContext.allowAllDBs = false;
                    break;
                }
                case scriptContextTypes.runningOnEdges: {
                    updatedNode.allowedScriptContext.allowedEdgeTypeIds = [];
                    updatedNode.allowedScriptContext.allowAllEdges = false;
                    break;
                }
                case scriptContextTypes.runningOnFiles: {
                    updatedNode.allowedScriptContext.allowAllFiles = false;
                    break;
                }
                case scriptContextTypes.runningOnFolders: {
                    updatedNode.allowedScriptContext.allowedFolderTypeIds = [];
                    updatedNode.allowedScriptContext.allowAllFolders = false;
                    break;
                }
                case scriptContextTypes.runningOnObjectInstances: {
                    updatedNode.allowedScriptContext.allowedSymbolTypeIds = [];
                    updatedNode.allowedScriptContext.allowAllSymbols = false;
                    break;
                }
                case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
                    updatedNode.allowedScriptContext.allowedModelSymbols = [];
                    updatedNode.allowedScriptContext.allowAllSymbols = false;
                    break;
                }
                case scriptContextTypes.runningOnObjects: {
                    updatedNode.allowedScriptContext.allowedObjectTypeIds = [];
                    updatedNode.allowedScriptContext.allowAllObjects = false;
                    break;
                }
                case scriptContextTypes.runningOnScripts: {
                    updatedNode.allowedScriptContext.allowAllScripts = false;
                    break;
                }
                case scriptContextTypes.runningOnModels: {
                    updatedNode.allowedScriptContext.allowedModelTypeIds = [];
                    updatedNode.allowedScriptContext.allowAllModels = false;
                    break;
                }
                case scriptContextTypes.runningOnSpecificElement: {
                    updatedNode.allowedScriptContext.allowedNodeIds = [];
                    break;
                }
                default:
                    break;
            }
            server.byRepositoryId[repositoryId].byId[id] = updatedNode;

            return stateClone;
        }
        case SCRIPT_CONTEXT_SAVE_SUCCESS: {
            const {
                payload: {
                    scriptId: { id, repositoryId, serverId },
                    scriptContext,
                },
            } = action;
            const stateClone = JSON.parse(JSON.stringify(state));
            setSetInClonedObject(state, stateClone);
            const server = stateClone.byServerId[serverId];
            if (!server || !server.byRepositoryId[repositoryId] || !server.byRepositoryId[repositoryId].byId[id]) {
                return state;
            }
            const updatedNode = {
                ...server.byRepositoryId[repositoryId].byId[id],
            };
            updatedNode.allowedScriptContext = scriptContext;
            server.byRepositoryId[repositoryId].byId[id] = updatedNode;

            return stateClone;
        }

        case SET_SHOW_DELETED_OBJECTS_FILTER_SUCCESS: {
            return {
                ...state,
                showDeletedObjectsFilter: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

// todo: rename to something like mergeTreeToMap
const treeToMap = (
    node: TreeNode,
    initial: {
        [repositoryId: string]: {
            byId: {
                [id: string]: TTreeEntityState;
            };
        };
    },
    serverId: string,
) => {
    if (node.children) {
        node.children.forEach((child) => {
            initial[child.nodeId.repositoryId] = {
                byId: {
                    ...initial[child.nodeId.repositoryId].byId,
                    [child.nodeId.id]: {
                        ...child,
                        nodeId: { ...child.nodeId, serverId },
                        parentNodeId: child.parentNodeId && { ...child.parentNodeId, serverId },
                        isFetching: false,
                        childrenIds: child.children ? child.children.map((c) => c.nodeId.id) : [],
                        hasChildren: !!(child.children && child.children.length > 0),
                        children: [],
                    },
                },
            };
            treeToMap(child, initial, serverId);
        });
    }

    return initial;
};
