import { get } from 'lodash';
import printjs, { Configuration } from 'print-js';
import { LengthUnit, Orientation, PaperFormat } from './types';

export const paperSizes = {
    [PaperFormat.A0]: [841, 1189],
    [PaperFormat.A1]: [594, 841],
    [PaperFormat.A2]: [420, 594],
    [PaperFormat.A3]: [297, 420],
    [PaperFormat.A4]: [210, 297],
    [PaperFormat.A5]: [148, 210],
    [PaperFormat.A6]: [105, 148],
    [PaperFormat.A7]: [74, 105],
    [PaperFormat.B4]: [250, 353],
    [PaperFormat.B5]: [176, 250],
};

export const pxToMm = (px: number, DPI: number = 96): number => px * (25.4 / DPI);

export const pxToCm = (px: number, DPI: number = 96): number => px * (2.54 / DPI);

export const mmToCm = (mm: number): number => mm / 10;

export const mmToPx = (mm: number, DPI: number = 96): number => Math.round(mm * (DPI / 25.4));

export const cmToMM = (cm: number): number => cm * 10;

export const cmToPx = (cm: number, DPI: number = 96): number => Math.round(cm * (DPI / 2.54));

export const unitConverters = {
    [LengthUnit.PX]: {
        [LengthUnit.MM]: pxToMm,
        [LengthUnit.CM]: pxToCm,
    },
    [LengthUnit.MM]: {
        [LengthUnit.CM]: mmToCm,
        [LengthUnit.PX]: mmToPx,
    },
    [LengthUnit.CM]: {
        [LengthUnit.MM]: cmToMM,
        [LengthUnit.PX]: cmToPx,
    },
};

export const convertUnits = (value: number, from: string, to: string): number => {
    const converter = get(unitConverters, [from, to]);

    return typeof converter === 'function' ? converter(value) : value;
};

export const convertPaperSize = (dimensions: number[], from: string, to: string): number[] =>
    dimensions.map((value) => convertUnits(value, from, to));

export const getPaperSize = (
    paperFormat: PaperFormat = PaperFormat.A4,
    orientation: Orientation = Orientation.PORTRAIT,
    unit?: LengthUnit, // "MM" by default
): number[] => {
    let paperSize;

    if (orientation === Orientation.PORTRAIT) {
        paperSize = paperSizes[paperFormat];
    } else if (orientation === Orientation.LANDSCAPE) {
        const [height, width] = paperSizes[paperFormat];
        paperSize = [width, height];
    }

    return unit ? convertPaperSize(paperSize, LengthUnit.MM, unit) : paperSize;
};

const print = (config: Configuration): void => printjs(config);

export default print;
