export const SCRIPT_REQUEST_SUCCESS = 'SCRIPT_REQUEST_SUCCESS';

export const SCRIPT_SAVE = 'SCRIPT_SAVE';

export const SCRIPT_SAVE_REQUEST = 'SCRIPT_SAVE_REQUEST';

export const SCRIPT_SAVE_REQUEST_SUCCESS = 'SCRIPT_SAVE_REQUEST_SUCCESS';

export const SCRIPT_SAVE_REQUEST_FAILURE = 'SCRIPT_SAVE_REQUEST_FAILURE';

export const SCRIPT_OPEN = 'SCRIPT_OPEN';

export const SCRIPT_OPEN_BY_NODE_ID = 'SCRIPT_OPEN_BY_NODE_ID';

export const SCRIPT_CLOSE = 'SCRIPT_CLOSE';

export const SCRIPTS_DASHBOARD_OPEN = 'SCRIPTS_DASHBOARD_OPEN';

export const SCRIPTS_EXECUTION_REQUEST = 'SCRIPTS_EXECUTION_REQUEST';

export const SCRIPTS_EXECUTION_SAVE_TO_STORE = 'SCRIPTS_EXECUTION_SAVE_TO_STORE';

export const SCRIPTS_EXECUTION_REQUEST_FAILURE = 'SCRIPTS_EXECUTION_REQUEST_FAILURE';

export const DASHBOARD_OPEN = 'DASHBOARD_OPEN';

export const STATUS_CHANGE_NOTIFICATION_SHOW = 'STATUS_CHANGE_NOTIFICATION_SHOW';

export const REPORT_DOWNLOAD_DATE_SET = 'REPORT_DOWNLOAD_DATE_SET';

export const FILE_DOWNLOADED_NOTIFICATION_SHOW = 'FILE_DOWNLOADED_NOTIFICATION_SHOW';

export const SCRIPT_CHANGE_LOCALLY = 'SCRIPT_CHANGE_LOCALY';

export const SCRIPT_CREATE = 'SCRIPT_CREATE';

export const SCRIPT_EXECUTION_SUBSCRIBE = 'SCRIPT_EXECUTION_SUBSCRIBE';

export const SCRIPT_REMOVE_SUCCESS = 'SCRIPT_REMOVE_SUCCESS';

export const SCRIPT_SUBMITTED_VIA_NODE = 'SCRIPT_SUBMITTED_VIA_NODE';

export const SCRIPT_EXECUTE = 'SCRIPT_EXECUTE';

export const SCRIPT_SCHEDULE = 'SCRIPT_SCHEDULE';

export const SCRIPT_EXECUTE_REQUEST_SUCCESS = 'SCRIPT_EXECUTE_REQUEST_SUCCESS';

export const SCRIPT_EXECUTE_REQUEST_FAILURE = 'SCRIPT_EXECUTE_REQUEST_FAILURE';

export const SCRIPT_PARAMETERS_REQUEST = 'SCRIPT_PARAMETERS_REQUEST';

export const CHANGE_SCRIPT_DATA = 'CHANGE_SCRIPT_DATA';

export const SCRIPT_MODE_CHANGE = 'SCRIPT_MODE_CHANGE';

export const SCRIPT_SCHEDULE_LOAD_ALL = 'SCRIPT_SCHEDULE_LOAD_ALL';

export const SCRIPT_SCHEDULE_SET = 'SCRIPT_SCHEDULE_SET';

export const SCRIPT_SCHEDULE_TASK_DELETE = 'SCRIPT_SCHEDULE_TASK_DELETE';

export const SCRIPT_SCHEDULE_LOADING = 'SCRIPT_SCHEDULE_LOADING';

export const SCRIPT_CONTEXT_FETCH = 'SCRIPT_CONTEXT_FETCH';

export const SCRIPT_CONTEXT_DELETE = 'SCRIPT_CONTEXT_DELETE';

export const SCRIPT_CONTEXT_SAVE_SUCCESS = 'SCRIPT_CONTEXT_SAVE_SUCCESS';

export const SCRIPT_SCHEDULE_TASK_EDIT = 'SCRIPT_SCHEDULE_TASK_EDIT';

export const SCRIPT_EDIT_SCHEDULE_SUBMIT = 'SCRIPT_EDIT_SCHEDULE_SUBMIT';
