import { defineMessages } from 'react-intl';

export default defineMessages({
    userEditDisabled: {
        id: 'NodeSettings.userEditDisabled',
        defaultMessage: 'Блокировка модели на ручные изменения',
    },
    scriptEngineEditDisabled: {
        id: 'NodeSettings.scriptEngineEditDisabled',
        defaultMessage: 'Блокировка модели на изменения скриптами',
    }
});
