export type TApprovalDialogProps = {
    visible: boolean;
    isCreateMode: boolean;
    isDraft: boolean;
};

export enum ApprovalStageDTOType {
    FOLLOWING = 'FOLLOWING',
    SUCCESSIVE = 'SUCCESSIVE',
    PARALLEL = 'PARALLEL',
}

export enum ApprovalStageDTOAbstentionVoteStrategy {
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
}

export enum ApprovalStageDTOStatus {
    IN_PROCESS = 'IN_PROCESS',
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
}

export enum ApprovalDTOStatus {
    DRAFT = 'DRAFT',
    IN_PROCESS = 'IN_PROCESS',
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
    CANCELLED = 'CANCELLED',
}

export enum ApprovalUserDTOVote {
    APPROVED = 'APPROVED',
    NOT_APPROVED = 'NOT_APPROVED',
    ABSTAINED = 'ABSTAINED',
    CANCELLED = 'CANCELLED',
}

export enum ApprovalRateOfSuccess {
    ALL_POSITIVE = 'ALL_POSITIVE',
    PERCENTAGE = 'PERCENTAGE',
}
