import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TPreset } from '../../../../../models/preset.types';
import {
    AttributeType,
    AttributeTypeGroup,
    DefaultId,
    InternationalString,
    ModelTypeGroup,
    ObjectTypeGroup,
    PresetElementTransferType,
    TreeNodeType,
    UserProperty,
} from '../../../../../serverapi/api';
import { useIntl } from 'react-intl';
import messages from '../../messages/Presets.messages';
import { TreeItemType } from '../../../../Tree/models/tree';
import { FormInstance, Tabs } from 'antd';
import theme from './Presets.scss';
import { GeneralSettingsTab } from './GeneralSettingsTab/GeneralSettings.component';
import { ModelTypesTab } from './ModelTypesTab.component';
import { ObjectTypesTab } from './ObjectTypesTab.component';
import { EdgeTypesTab } from './EdgeTypesTab.component';
import { AttributeTypesTab } from './AttributeTypesTab/AttributeTypesTab.component';
import { AttributesTab } from './AttributeType/AttributesTab.component';
import AccessRights from './PresetProfileList/PresetProfileList.component';
import { EditorFooterButtons } from './Footer/EditorFooterButtons.component';
import footerMessages from './Footer/EditorFooterButtons.messages';
import { useDispatch, useSelector } from 'react-redux';
import { compareTreeObjectType } from '../../../../../models/tree';
import { PresetSelectors } from '../../../../../selectors/preset.selectors';
import { getUserProperty } from '../../../../../selectors/authorization.selectors';
import { AttributeTypeSelectors } from '../../../../../selectors/attributeType.selectors';
import { IWorkspaceTabItemPresetParams, TWorkspaceTab } from '../../../../../models/tab.types';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { WorkSpaceTabTypes } from '../../../../Workspace/WorkSpaceTabTypesEnum';
import { ModelTypeGroupSelectors } from '../../../../../selectors/modelTypeGroup.selectors';
import { AttributeTypeGroupSelectors } from '../../../../../selectors/attributeTypeGroup.selectors';
import { TreeNodeTypeSelectors } from '../../../../../selectors/treeNodeType.selectors';
import {
    cancelEdit,
    createModelTypeGroup,
    exportPreset,
    submitPreset,
} from '../../../../../actions/methodologySetting.actions';
import { deleteModelTypeGroup, editModelTypeGroup } from '../../../../../actions/modelType.actions';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { createAttributeTypeGroup, editAttributeTypeGroup } from '../../../../../actions/attributeTypeGroup.actions';
import { saveAttributeTypes } from '../../../../../actions/attributeType.actions.types';
import { workspaceRemoveTabByNodeId } from '../../../../../actions/tabs.actions';
import { treeNodeTypeRequest } from '../../../../../actions/treeNodeType.actions';
import { FolderTypeTab } from './FolderType/FolderTypeTab.component';
import { LocalesService } from '../../../../../services/LocalesService';
import { IconsLibTab } from './IconsLib/PresetImageLib.component';
import { createButtonsCompositeDataTestId } from './util/createButtonsCompositeDataTestId.utils';
import { TBtnProperties } from './Header/TabHeader.types';
import type { Tab } from 'rc-tabs/lib/interface';

type TPresetEditorProps = {
    tab: TWorkspaceTab;
};

const PresetEditorComponent: FC<TPresetEditorProps> = (props) => {
    const params = props.tab.params as IWorkspaceTabItemPresetParams;
    const { serverNode, availableTreeNodeTypes, editablePreset } = params;
    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const activeTabType = activeTab ? (activeTab.type as WorkSpaceTabTypes) : undefined;

    const isActiveTab: boolean = activeTabType === WorkSpaceTabTypes.ADD_PRESET_TAB;

    const dispatch = useDispatch();
    const intl = useIntl();

    const [presetState, setPresetState] = useState<TPreset>(editablePreset);
    const [notSaved, setNotSaved] = useState<boolean>(false);
    const [denyEditState, setDenyEditState] = useState<boolean>(false);
    const [modelTypeGroupsState, setModelTypeGroupsState] = useState<ModelTypeGroup[]>(params.modelTypeGroups || []);
    const [selectedTreeItemType, setSelectedTreeItemType] = useState<string>(TreeItemType.Repository);
    const [tabName, setTabName] = useState<string>(intl.formatMessage(messages.generalSettings));
    const [presetProfilesForDelete, setPresetProfilesForDelete] = useState<string[]>([]);

    const { serverId } = serverNode.nodeId;

    // Если создаем новый preset - PresetSelectors.byId вернет undefined
    const preset = useSelector(
        PresetSelectors.byId({
            serverId,
            presetId: editablePreset.id,
        }),
    ) as TPreset | undefined;

    const presetId: string = presetState.id;
    const isDefaultPresetId = presetId === DefaultId.DEFAULT_PRESET_ID;
    const loading: boolean = useSelector(PresetSelectors.loading());
    const property: UserProperty | undefined = useSelector(getUserProperty);

    const modelTypeGroups: ModelTypeGroup[] = useSelector(
        ModelTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId,
        }),
    );

    const attributeTypeGroups: AttributeTypeGroup[] = useSelector(
        AttributeTypeGroupSelectors.byPresetId({
            serverId,
            presetId,
        }),
    );

    const treeNodeTypes: TreeNodeType[] = useSelector(
        TreeNodeTypeSelectors.byPresetId({
            serverId,
            presetId,
        }),
    );

    const attributeTypes: AttributeType[] = useSelector(AttributeTypeSelectors.allInPreset(serverId, presetId) || []);

    const setInitialState = () => {
        setNotSaved(!preset?.saved);
        setDenyEditState((isDefaultPresetId && !property?.isDefaultPresetEditAllowed) || loading);
        setModelTypeGroupsState(modelTypeGroups);
    };

    useEffect(() => {
        if (preset) {
            setInitialState();
        } else if (!preset) {
            setNotSaved(true);
        }
    }, [preset, presetState]);

    useEffect(() => {
        if (!isActiveTab || !preset) {
            return;
        }
        setInitialState();
    }, [isActiveTab]);

    useEffect(() => {
        setDenyEditState((isDefaultPresetId && !property?.isDefaultPresetEditAllowed) || loading);
    }, [isDefaultPresetId, loading, property?.isDefaultPresetEditAllowed]);

    const importProperties = {
        preset: editablePreset,
        filters: '.xml',
    };

    const getFilteredGroupTypes = (
        deletedGroups: ModelTypeGroup[] | ObjectTypeGroup[],
        typeGroups: ModelTypeGroup[] | ObjectTypeGroup[],
    ) => {
        return typeGroups.filter((g) =>
            deletedGroups.every((deletedGroup: ModelTypeGroup) => g.id !== deletedGroup.id),
        );
    };

    const formRef = useRef<FormInstance>(null);

    const readOnly: boolean = denyEditState || notSaved;
    const treeNodeType: TreeNodeType | undefined = useMemo(
        () => treeNodeTypes?.find((type) => type.id === selectedTreeItemType),
        [treeNodeTypes, selectedTreeItemType],
    );
    const nodeTypeAttributeTypes: AttributeType[] = treeNodeType?.attributes || [];

    const actionButton: TBtnProperties = notSaved
        ? {
            name: intl.formatMessage(footerMessages.create),
            type: "primary",
            disabled: denyEditState,
            onAction: () => {
                if (formRef.current) {
                    formRef.current.validateFields().then(() => {
                        dispatch(
                            submitPreset({
                                preset: presetState,
                                serverNode,
                                closeWindow: false,
                                presetProfilesIds: [],
                                presetId: presetState.id,
                                tabNodeId: props.tab.nodeId,
                            }),
                        );
                    });
                }
            },
            dataTestId: 'methodology_edit_window_create_btn',
        }
        : {
            name: intl.formatMessage(footerMessages.save),
            type: "primary",
            disabled: denyEditState,
            onAction: () => {
                if (formRef.current) {
                    formRef.current.validateFields().then(() => {
                        dispatch(
                            submitPreset({
                                preset: presetState,
                                serverNode,
                                closeWindow: true,
                                presetProfilesIds: presetProfilesForDelete,
                                presetId: presetState.id,
                                tabNodeId: props.tab.nodeId,
                            }),
                        );
                    });
                }
            },
            dataTestId: 'methodology_edit_window_save_btn',
        };

    const cancelButton: TBtnProperties = {
        name: intl.formatMessage(footerMessages.cancel),
        onAction: () => {
            dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId));
            dispatch(cancelEdit({ preset: editablePreset, serverNode }));
        },
        dataTestId: 'methodology_edit_window_cancel_btn',
    };

    const buttons: TBtnProperties[] = [cancelButton, actionButton];

    const handleDeletePresetProfile = (presetProfileId: string) => {
        setPresetProfilesForDelete([...presetProfilesForDelete, presetProfileId]);
    };

    const onChangeName = (multilingualName: InternationalString) => {
        setPresetState({ ...presetState, multilingualName });
    };

    const onChangeDescription = (multilingualDescription: InternationalString) => {
        setPresetState({ ...presetState, multilingualDescription });
    };

    const sendEditModelTypeGroup = useCallback((modelTypeGroup: ModelTypeGroup) => {
        dispatch(
            editModelTypeGroup({
                serverNode,
                preset: presetState,
                modelTypeGroup,
            }),
        );
    }, []);

    const sendCreateModelTypeGroup = useCallback(() => {
        dispatch(createModelTypeGroup({ serverNode, preset: presetState }));
    }, []);

    const sendDeleteModelTypeGroups = useCallback(
        (groups: ModelTypeGroup[]) => {
            dispatch(deleteModelTypeGroup({ serverNode, modelTypeGroups: groups }));
            setModelTypeGroupsState(getFilteredGroupTypes(groups, modelTypeGroups));
        },
        [modelTypeGroupsState],
    );

    const sendEditAttributeTypeGroup = useCallback((attributeTypeGroup: AttributeTypeGroup) => {
        dispatch(
            editAttributeTypeGroup({
                attributeTypeGroup,
                preset: editablePreset,
                serverNode,
            }),
        );
    }, []);

    const sendCreateAttributeTypeGroup = useCallback(() => {
        dispatch(createAttributeTypeGroup({ preset: editablePreset, serverNode }));
    }, []);

    const changeAttributeTypeGroup = useCallback((selectedAttributeTypes: AttributeType[]) => {
        dispatch(
            saveAttributeTypes({
                serverNode,
                preset: editablePreset,
                attributeTypes: selectedAttributeTypes,
            }),
        );
    }, []);

    const importAttributes = useCallback(() => {
        dispatch(
            openDialog(DialogType.UPLOAD_PRESET_DIALOG, {
                serverNode,
                type: PresetElementTransferType.attribute,
                ...importProperties,
            }),
        );
    }, []);

    const exportAttributes = useCallback((selectedAttributeTypes: AttributeType[]) => {
        if (preset) {
            const selectedAttributeTypesIds = selectedAttributeTypes.map((a) => a.id);

            dispatch(exportPreset(preset, serverId, PresetElementTransferType.attribute, selectedAttributeTypesIds));
        }
    }, []);

    const addTreeNodeAttributeTypes = useCallback(
        (addedAttributeTypes: AttributeType[]) => {
            const newTreeNodeTypes = treeNodeTypes.some((type) => type.id === selectedTreeItemType)
                ? treeNodeTypes.map((type) => {
                    if (type.id === selectedTreeItemType) {
                        type.attributes = addedAttributeTypes.concat(nodeTypeAttributeTypes);
                    }

                    return type;
                })
                : [
                    ...treeNodeTypes,
                    {
                        id: selectedTreeItemType,
                        presetId: presetState.id,
                        attributes: addedAttributeTypes,
                    },
                ];

            dispatch(treeNodeTypeRequest(serverId, newTreeNodeTypes, presetId));
        },
        [selectedTreeItemType, treeNodeTypes],
    );

    const deleteTreeNodeAttributeTypes = useCallback(
        (deletedAttributeTypes: AttributeType[]) => {
            const newTreeNodeTypes = treeNodeTypes.map((type) => {
                if (type.id === selectedTreeItemType) {
                    type.attributes = type.attributes.filter(
                        (at: AttributeType) => !deletedAttributeTypes.some((a) => a.id === at.id),
                    );
                }

                return type;
            });

            dispatch(treeNodeTypeRequest(serverId, newTreeNodeTypes, presetId));
        },
        [selectedTreeItemType, treeNodeTypes],
    );

    const deleteTreeNodeTypeGroup = useCallback(
        (deletedAttributeTypeGroups: AttributeTypeGroup[]) => {
            const newTreeNodeTypes = treeNodeTypes.map((type) => {
                if (type.id === selectedTreeItemType) {
                    type.attributes = type.attributes.filter(
                        (at: AttributeType) =>
                            !deletedAttributeTypeGroups.some((atg) => at.attributeTypeGroup?.id === atg.id),
                    );
                }

                return type;
            });
            dispatch(treeNodeTypeRequest(serverId, newTreeNodeTypes, presetId));
        },
        [selectedTreeItemType, treeNodeTypes],
    );

    const presetName: string = LocalesService.internationalStringToString(preset?.multilingualName);
    const navTitle: string = `${presetName || presetState.name || ''} > ${tabName}`;

    const items: Tab[] = [
        {
            label: (
                <span data-test="preset_editor-general-settings_tab">
                    {intl.formatMessage(messages.generalSettings)}
                </span>
            ),
            key: intl.formatMessage(messages.generalSettings),
            disabled: false,
            children: (
                <div className={theme.tabContent}>
                    <GeneralSettingsTab
                        disabled={denyEditState}
                        preset={presetState}
                        onChangeDescription={onChangeDescription}
                        onChangeName={onChangeName}
                        generalFormRef={formRef}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')} />
                </div>
            ),
        },
        {
            label: (
                <span data-test="preset_editor-model_types_tab">
                    {intl.formatMessage(messages.modelTypesTab)}
                </span>
            ),
            key: intl.formatMessage(messages.modelTypesTab),
            disabled: notSaved,
            children: (
                <div className={theme.tabContent}>
                    <ModelTypesTab
                        serverNode={serverNode}
                        preset={presetState}
                        disabled={readOnly}
                        modelTypeGroups={modelTypeGroupsState}
                        editModelTypeGroup={sendEditModelTypeGroup}
                        createModelTypeGroup={sendCreateModelTypeGroup}
                        deleteModelTypeGroups={sendDeleteModelTypeGroups}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'model_types_tab')} />
                </div>
            ),
        },
        {
            label: (
                <span data-test="preset_editor-object_types_tab">
                    {intl.formatMessage(messages.objectTypesTab)}
                </span>
            ),
            key: intl.formatMessage(messages.objectTypesTab),
            disabled: notSaved,
            children: (
                <div className={theme.tabContent}>
                    <ObjectTypesTab
                        disabled={readOnly}
                        serverNode={serverNode}
                        preset={presetState}
                        tabNodeId={activeTab?.nodeId!}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'object_types_tab')} />
                </div>
            ),
        },
        {
            label: (
                <span data-test="preset_editor-edge_types_tab">
                    {intl.formatMessage(messages.edgeTypesTab)}
                </span>
            ),
            key: intl.formatMessage(messages.edgeTypesTab),
            disabled: notSaved,
            children: (
                <div className={theme.tabContent}>
                    <EdgeTypesTab
                        disabled={readOnly}
                        serverNode={serverNode}
                        preset={editablePreset}
                        tabNodeId={activeTab?.nodeId!}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'edge_types_tab')} />
                </div>
            ),
        },
        {
            label: (
                <span data-test="preset_editor-attribute_types_tab">
                    {intl.formatMessage(messages.attributeTypesTab)}
                </span>
            ),
            key: intl.formatMessage(messages.attributeTypesTab),
            disabled: notSaved,
            children: (
                <div className={theme.tabContent}>
                    <AttributeTypesTab
                        serverNode={serverNode}
                        disabled={readOnly}
                        preset={editablePreset}
                        attributeTypes={attributeTypes}
                        attributeTypeGroups={attributeTypeGroups}
                        editAttributeTypeGroup={sendEditAttributeTypeGroup}
                        createAttributeTypeGroup={sendCreateAttributeTypeGroup}
                        changeAttributeTypeGroup={changeAttributeTypeGroup}
                        importAttributes={importAttributes}
                        exportAttributes={exportAttributes}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'attribute_types_tab')} />
                </div>
            ),
        },
        {
            label: (
                <span data-test="preset_editor-folder_types_tab">
                    {intl.formatMessage(messages.folderType)}
                </span>
            ),
            key: intl.formatMessage(messages.folderType),
            disabled: notSaved,
            children: (
                <div className={theme.tabContent}>
                    <FolderTypeTab serverNode={serverNode} preset={editablePreset} />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'folder_types_tab')} />
                </div>
            ),
        },
        {
            label: (
                <span data-test="preset_editor-elements_tab">
                    {intl.formatMessage(messages.elements)}
                </span>
            ),
            key: intl.formatMessage(messages.elements),
            disabled: notSaved,
            children: (
                <div className={theme.tabContent}>
                    <AttributesTab
                        attributeTypes={nodeTypeAttributeTypes}
                        availableTreeNodeTypesState={availableTreeNodeTypes?.sort(compareTreeObjectType) || []}
                        selectedTreeItemType={selectedTreeItemType}
                        availableAttributeTypes={attributeTypes?.filter(
                            (at) => !nodeTypeAttributeTypes.some((a) => a.id === at.id),
                        )}
                        attributeTypeGroups={attributeTypeGroups}
                        addAttributeTypes={addTreeNodeAttributeTypes}
                        deleteAttributeTypes={deleteTreeNodeAttributeTypes}
                        deleteAttributeTypeGroups={deleteTreeNodeTypeGroup}
                        setSelectedTreeItemType={setSelectedTreeItemType}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'elements_tab')} />
                </div>
            ),
        },
        {
            label: (
                <span data-test="preset_editor-accessRights_tab">
                    {intl.formatMessage(messages.accessRights)}
                </span>
            ),
            key: intl.formatMessage(messages.accessRights),
            disabled: notSaved,
            children: (
                <div className={theme.tabContent}>
                    <AccessRights
                        serverNode={serverNode}
                        serverId={serverId}
                        preset={presetState}
                        onProfileDelete={handleDeletePresetProfile}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'accessRights_tab')} />
                </div>
            ),
        },
        {
            label: (
                <span>{intl.formatMessage(messages.iconsLib)}</span>
            ),
            key: intl.formatMessage(messages.iconsLib),
            disabled: notSaved,
            children: (
                <div className={theme.tabContent}>
                    <IconsLibTab
                        disabled={readOnly}
                        serverNode={serverNode}
                        preset={presetState}
                        tabNodeId={activeTab?.nodeId!}
                    />
                    <EditorFooterButtons buttons={createButtonsCompositeDataTestId(buttons, 'iconsLib_tab')} />
                </div>
            ),
        },
    ];

    return (
        <div className={theme.container}>
            <span className={theme.navigationTitle}>{navTitle}</span>
            <Tabs
                activeKey={tabName}
                className={theme.tabs}
                tabPosition="left"
                defaultActiveKey={intl.formatMessage(messages.generalSettings)}
                onChange={(activeKey: string) => setTabName(activeKey)}
                items={items}
            />
        </div>
    );
};

const withMemo = React.memo(PresetEditorComponent);

export { withMemo as PresetEditorComponent };
