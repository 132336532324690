import React, { useId } from 'react';
import theme from './Checkbox.scss';

type TCheckboxProps = {
    checked?: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    children?: JSX.Element | string;
    className?: string;
    onChange?: () => void;
    onClick?: (event: React.MouseEvent) => void;
};

/**
 * @param checked checkbox checked
 * @param indeterminate неопределенный. Имеет приоритет ниже checked
 * @param disabled делает чекбокс недоступным для клика, checked и indeterminate сохраняются
 * @param children текст справа от чекбокса
 * @param className добавляется к label обертке чекбокса
 * @param onChange вызывается при изменении состояния чекбокса
 * @param onClick вызывается при изменении клика по чекбоксу
 */
export const Checkbox = (props: TCheckboxProps) => {
    const { checked, indeterminate, disabled, children, className, onChange, onClick } = props;
    const id = useId();

    return (
        <>
            <label
                htmlFor={id}
                className={`${theme.checkboxWrapper} ${checked === undefined && indeterminate && theme.indeterminate} ${className}`}
                onClick= {onClick}
            >
                <input
                    id={id}
                    type="checkbox"
                    checked={checked ?? indeterminate}
                    disabled={disabled}
                    onChange={onChange}
                />
                <span>
                    <div className={theme.checkboxLabel}>{children}</div>
                </span>
            </label>
        </>
    );
};
