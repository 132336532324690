// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchFilter__searchFilterContainer___Nk02{margin-top:10px}.SearchFilter__inputContainer__OXbfQ{width:100%}.SearchFilter__hidden__JPZ_N{display:none}.SearchFilter__searchFilterButton__qa9MN{width:400px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}", "",{"version":3,"sources":["webpack://./src/modules/Search/SearchFilter/SearchFilter.scss"],"names":[],"mappings":"AAAA,4CACI,eAAA,CAGJ,qCACI,UAAA,CAGJ,6BACI,YAAA,CAGJ,yCACI,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA","sourcesContent":[".searchFilterContainer {\r\n    margin-top: 10px;\r\n}\r\n\r\n.inputContainer {\r\n    width: 100%;\r\n}\r\n\r\n.hidden {\r\n    display: none;\r\n}\r\n\r\n.searchFilterButton {\r\n    width: 400px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchFilterContainer": "SearchFilter__searchFilterContainer___Nk02",
	"inputContainer": "SearchFilter__inputContainer__OXbfQ",
	"hidden": "SearchFilter__hidden__JPZ_N",
	"searchFilterButton": "SearchFilter__searchFilterButton__qa9MN"
};
export default ___CSS_LOADER_EXPORT___;
