// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CommonCreateDialog__folderIcon__v69Wr{width:14px;margin-right:5px;margin-bottom:-3px}", "",{"version":3,"sources":["webpack://./src/modules/CommonCreateDialog/CommonCreateDialog.scss"],"names":[],"mappings":"AAAA,uCACI,UAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":[".folderIcon {\r\n    width: 14px;\r\n    margin-right: 5px;\r\n    margin-bottom: -3px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"folderIcon": "CommonCreateDialog__folderIcon__v69Wr"
};
export default ___CSS_LOADER_EXPORT___;
