import { addApprovals, deleteApprovalsFromStore, editApproval, updateApproval } from '@/actions/approval.actions';
import {
    TCommentApprovalAction,
    TCopyApprovalAction,
    TCreateApprovalAction,
    TDeleteApprovalAction,
    TEditExistingApprovalAction,
    TLoadNodeApprovalsAction,
    TSubmitApprovalAction,
    TVoteApprovalAction,
} from '@/actions/approval.actions.types';
import { closeDialog, openDialog } from '@/actions/dialogs.actions';
import { showNotification } from '@/actions/notification.actions';
import {
    COMMENT_APPROVAL,
    COPY_APPROVAL,
    CREATE_APPROVAL,
    DELETE_APPROVALS,
    EDIT_EXISTING_APPROVAL,
    LOAD_NODE_APPROVALS,
    OPEN_APPROVALS_TAB,
    SUBMIT_APPROVAL,
    VOTE_APPROVAL,
} from '@/actionsTypes/approval.actionTypes';
import { NotificationType } from '@/models/notificationType';
import { TApprovalDialogProps } from '@/modules/ApprovalDialog/ApprovalDialog.types';
import messages from '../modules/ObjectPropertiesDialog/components/ApprovalsTab/ApprovalsTab.messages';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { ApprovalDTO, NodeId } from '@/serverapi/api';
import { ApprovalDaoService } from '@/services/dao/ApprovalDAOService';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { LocalesService } from '@/services/LocalesService';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { IWorkspaceTabServerSettingsParams, TWorkspaceTab } from '../models/tab.types';
import { defaultWorkspaceTabActions } from '../models/tab';
import { workspaceAddTab } from '../actions/tabs.actions';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { fetchNodes } from '../actions/nodes.actions';
import { getUniqNodeIdsFromApprovals } from '../modules/AdminTools/ApprovalsTable/utils/approvalTable.utils';
import { AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';
import admintoolMessages from '../../../bpm-ui/src/modules/AdminTools/messages/admintool.messages';

function* handleCreateApproval({ payload }: TCreateApprovalAction) {
    const { approvedItemNodeId } = payload;

    const draftApproval: ApprovalDTO | undefined = yield select(
        ApprovalSelectors.byApprovedItemIdDraft(approvedItemNodeId),
    );

    if (draftApproval) {
        yield put(editApproval({ approval: draftApproval }));
    } else {
        const newApproval: ApprovalDTO = {
            id: {
                ...approvedItemNodeId,
                id: uuid(),
            },
            name: '',
            elementIds: [approvedItemNodeId.id],
        };
        yield put(editApproval({ approval: newApproval }));
    }

    yield put(
        openDialog(DialogType.APPROVAL, {
            isCreateMode: true,
            isDraft: !!draftApproval,
        } as TApprovalDialogProps),
    );
}

function* handleSubmitApproval({ payload }: TSubmitApprovalAction) {
    const { approval, isDraft, isCreateMode } = payload;
    let newApproval: ApprovalDTO;

    if (isDraft || !isCreateMode) {
        newApproval = yield ApprovalDaoService.save(approval);
    } else {
        newApproval = yield ApprovalDaoService.create(approval);
    }

    yield put(addApprovals({ approvals: [newApproval] }));
    yield put(closeDialog(DialogType.APPROVAL));
}

function* handleLoadNodeApprovals({ payload }: TLoadNodeApprovalsAction) {
    const { nodeId } = payload;
    const approvals: ApprovalDTO[] = yield TreeDaoService.getNodeApprovals(nodeId);

    yield put(addApprovals({ approvals }));
}

function* handleCommentApproval({ payload }: TCommentApprovalAction) {
    const { comment } = payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    try {
        const approval = yield ApprovalDaoService.comment(comment);
        yield put(updateApproval({ approval }));
    } catch (error) {
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType.COMMON_ERROR,
                // any т.к. нотификалка корректно работает когда в data передается строка,
                // а если data будет объектом (например типа IGenericNotification) что описано в типе TNotificationEntity то появляется ошибка
                // Uncaught runtime error: Objects are not valid as a React child (found: object with keys {message}). If you meant to render a collection of children, use an array instead.
                // todo нужно переписать тип параметра для showNotification
                data: `${intl.formatMessage(messages.approval)} ${error.statusText}` as any,
            }),
        );
    }
}

function* handleEditExistingApprovals({ payload }: TEditExistingApprovalAction) {
    const { approvalId } = payload;

    const approval: ApprovalDTO | undefined = yield select(ApprovalSelectors.byApprovalId(approvalId));

    if (!approval) return;

    yield put(editApproval({ approval }));

    yield put(
        openDialog(DialogType.APPROVAL, {
            isCreateMode: false,
            isDraft: false,
        } as TApprovalDialogProps),
    );
}

function* handleDeleteApproval({ payload }: TDeleteApprovalAction) {
    const { approvalNodeIds } = payload;
    yield all(approvalNodeIds.map((approvalNodeId) => ApprovalDaoService.delete(approvalNodeId)));
    yield put(deleteApprovalsFromStore({ approvalNodeIds }));

    yield put(closeDialog(DialogType.APPROVAL));
}

function* handleVoteApproval({ payload }: TVoteApprovalAction) {
    const { vote } = payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    try {
        const approval = yield ApprovalDaoService.vote(vote);
        yield put(updateApproval({ approval }));
    } catch (error) {
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType.COMMON_ERROR,
                // any т.к. нотификалка корректно работает когда в data передается строка,
                // а если data будет объектом (например типа IGenericNotification) что описано в типе TNotificationEntity то появляется ошибка
                // Uncaught runtime error: Objects are not valid as a React child (found: object with keys {message}). If you meant to render a collection of children, use an array instead.
                // todo нужно переписать тип параметра для showNotification
                data: `${intl.formatMessage(messages.approval)} ${error.statusText}` as any,
            }),
        );
    }
}

function* handlOpenApprovalsTab() {
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const serverId: string = yield select(ServerSelectors.serverId);
    const approvals: Array<ApprovalDTO> = yield ApprovalDaoService.getApprovals(serverId);
    const uniqNodeIds: NodeId[] = getUniqNodeIdsFromApprovals(approvals);
    const nodeId: NodeId = {
        id: WorkSpaceTabTypes.APPROVALS_TAB,
        repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
        serverId,
    };
    yield put(fetchNodes(uniqNodeIds));
    yield put(addApprovals({ approvals }));
    const approvalsTab: TWorkspaceTab = {
        title: intl.formatMessage(admintoolMessages[WorkSpaceTabTypes.APPROVALS_TAB]),
        nodeId,
        type: WorkSpaceTabTypes.APPROVALS_TAB,
        mode: EditorMode.Read,
        params: {} as IWorkspaceTabServerSettingsParams,
        actions: defaultWorkspaceTabActions,
    };

    yield put(workspaceAddTab(approvalsTab));
}

function* handleCopyApproval({ payload }: TCopyApprovalAction) {
    const approval: ApprovalDTO = yield ApprovalDaoService.copy(payload);
    yield put(updateApproval({ approval }));
    yield put(editApproval({ approval }));
    yield put(closeDialog(DialogType.SELECT_TREE_ITEM_APPROVAL_DIALOG));
    yield put(
        openDialog(DialogType.APPROVAL, {
            isCreateMode: true,
            isDraft: true,
        } as TApprovalDialogProps),
    );
}

export function* approvalSaga() {
    yield takeEvery(CREATE_APPROVAL, handleCreateApproval);
    yield takeEvery(SUBMIT_APPROVAL, handleSubmitApproval);
    yield takeEvery(LOAD_NODE_APPROVALS, handleLoadNodeApprovals);
    yield takeEvery(VOTE_APPROVAL, handleVoteApproval);
    yield takeEvery(COMMENT_APPROVAL, handleCommentApproval);
    yield takeEvery(EDIT_EXISTING_APPROVAL, handleEditExistingApprovals);
    yield takeEvery(DELETE_APPROVALS, handleDeleteApproval);
    yield takeEvery(OPEN_APPROVALS_TAB, handlOpenApprovalsTab);
    yield takeEvery(COPY_APPROVAL, handleCopyApproval);
}
