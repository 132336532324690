import * as React from 'react';
import { injectIntl } from 'react-intl';
import { ScriptNode, ScriptNodeLanguageEnum } from '../../../../serverapi/api';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css'; // @TODO: need to add common scss
import 'codemirror/theme/neo.css'; // @TODO: need to add common scss
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/groovy/groovy';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/python/python';
import theme from './ScriptEditor.component.scss';
import * as codemirror from 'codemirror';
import { changes } from '../../../../utils/object';
import { debounce } from 'lodash';
import { CommentsPanelContainer } from '../../../Comments/containers/CommentsPanel.container';
import SplitPane from 'react-split-pane';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import electron, {remote} from '../../../../electron';
import { EditorMode } from '../../../../models/editorMode';
import { LOCK_TIMEOUT } from '../../../../utils/consts';
import { TScriptEditorProps } from '../ScriptEditor.types';

type TCodeMirrorEditorWrapper = CodeMirror & {
    editor: codemirror.Editor & codemirror.Doc;
};

class ScriptEditor extends React.Component<TScriptEditorProps> {
    editorWrapper: TCodeMirrorEditorWrapper;

    state = {
        script: {} as ScriptNode,
        undoManager: {
            undo: false,
            redo: false,
        },
    };

    debounceOnChange: Function;

    constructor(props: TScriptEditorProps) {
        super(props);

        const { script } = this.props;
        this.state = {
            script: { ...script },
            undoManager: {
                undo: false,
                redo: false,
            },
        };
        this.onChangeScript = this.onChangeScript.bind(this);
        this.debounceOnChange = debounce((scriptNode) => this.props.onChange(scriptNode), 1000);
    }

    componentDidMount() {
        if (electron) {
            const mainWindow = remote.getCurrentWindow();
            mainWindow.on('close', () => {
                this.props.onUnlock();
            });
        }

        setInterval(() => this.props.mode !== EditorMode.Read && this.props.onLock(), LOCK_TIMEOUT);
    }

    componentDidUpdate(prevProps: Readonly<TScriptEditorProps>, prevState: Readonly<any>) {
        const nextProps = this.props;
        const props = prevProps;
        const diffActions = changes(nextProps.actions, props.actions);
        if (diffActions.undo) {
            this.editorWrapper.editor.undo();
            props.onActionChange('undo', false);
        }
        if (diffActions.redo) {
            this.editorWrapper.editor.redo();
            props.onActionChange('redo', false);
        }

        if (Object.keys(diffActions).length) {
            const historySize = this.editorWrapper.editor.historySize();
            if (
                this.state.undoManager.undo !== !!historySize.undo ||
                this.state.undoManager.redo !== !!historySize.redo
            ) {
                props.onParamsChange('undoManager', {
                    undo: !!historySize.undo,
                    redo: !!historySize.redo,
                });
            }
        }

        if (diffActions.zoomFit) {
            props.onActionChange('zoomFit', false);
            props.onParamsChange('zoomLevel', 100);
        }
    }

    onChangeScript(editor: codemirror.Editor, data: codemirror.EditorChange, value: string) {
        const { script } = this.state;
        script.src = value;
        this.setState({ script });

        this.debounceOnChange(script);

        if (this.editorWrapper) {
            const historySize = editor.historySize();
            if (
                this.state.undoManager.undo !== !!historySize.undo ||
                this.state.undoManager.redo !== !!historySize.redo
            ) {
                this.props.onParamsChange('undoManager', {
                    undo: !!historySize.undo,
                    redo: !!historySize.redo,
                });
            }
        }
    }

    mapScriptNodeLanguageToCodemirrorMode(language: ScriptNodeLanguageEnum = 'JS') {
        const mapper = {
            GROOVY: 'groovy',
            JS: 'javascript',
            GROOVY_DEBUG: 'groovy',
        };

        return mapper[language];
    }

    render() {
        const { src, language } = this.state.script;
        const {
            script: { nodeId },
            commentsEnabledSchemesIds,
        } = this.props;
        const isShowCommentsPanel = commentsEnabledSchemesIds.some((id) => compareNodeIds(id, nodeId));
        const codemirrorMode = this.mapScriptNodeLanguageToCodemirrorMode(language);
        const readOnly = this.props.mode === EditorMode.Read;
        const comments = (
            <div className={isShowCommentsPanel ? theme.commentsPanel : theme.hideCommentsPanel}>
                <CommentsPanelContainer />
            </div>
        );

        return (
            <section className={theme.container}>
               { //@ts-ignore
<SplitPane
                    split="vertical"
                    primary="second"
                    defaultSize={isShowCommentsPanel ? 280 : 0}
                    maxSize={isShowCommentsPanel ? 750 : 0}
                    minSize={isShowCommentsPanel ? 280 : 0}
                    paneStyle={{ overflow: 'auto' }}
                    resizerClassName={isShowCommentsPanel ? theme.resizer : theme.hideResizer}
                    pane2Style={{ borderLeft: '1px solid #d9d9d9' }}
                >
                    <div
                        className={theme.editorContainer}
                        style={{ transform: `scale(${this.props.params.zoomLevel / 100})` }}
                        data-test="script-editor_container"
                    >
                        <CodeMirror
                            ref={(ref: TCodeMirrorEditorWrapper) => {
                                this.editorWrapper = ref!;
                            }}
                            className={theme.editor}
                            value={src || ''}
                            onChange={this.onChangeScript}
                            onBeforeChange={this.onChangeScript}
                            options={{
                                mode: codemirrorMode,
                                lineNumbers: true,
                                theme: 'neo',
                                readOnly,
                            }}
                        />
                    </div>
                    {comments}
                </SplitPane>}
            </section>
        );
    }
}
const IntlComponent = injectIntl(ScriptEditor);

export { IntlComponent as ScriptEditor };
