import React from 'react';
import messages from '../SystemProperties.messages';
import Button from 'antd/lib/button';
import { useIntl } from 'react-intl';

type TFooterAgreementProps = {
    handleDelete: () => void
    onHideAgreement: () => void
}

export const FooterAgreement = ({handleDelete, onHideAgreement}: TFooterAgreementProps) => {
    const intl = useIntl();

    return (
        <div>
            <Button
                onClick={onHideAgreement}
            >
                {intl.formatMessage(messages.cancel)}
            </Button>
            <Button
                type="primary"
                danger
                onClick={() => {
                    handleDelete();
                    onHideAgreement();
                }}
            >
                {intl.formatMessage(messages.reset)}
            </Button>
        </div>
    );
};
