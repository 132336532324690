import { defineMessages } from 'react-intl';

export default defineMessages({
    columnNameActions: {
        id: 'ScriptScheduler.columnNameActions',
        defaultMessage: 'Действия',
    },
    columnNameScript: {
        id: 'ScriptScheduler.columnNameScript',
        defaultMessage: 'Скрипт',
    },
    columnNameUser: {
        id: 'ScriptScheduler.columnNameUser',
        defaultMessage: 'Пользователь',
    },
    columnNameTime: {
        id: 'ScriptScheduler.columnNameTime',
        defaultMessage: 'Время запуска',
    },
    columnNameOption: {
        id: 'ScriptScheduler.columnNameOption',
        defaultMessage: 'Параметры',
    },
    paramNameColumnName: {
        id: 'ScriptScheduler.paramNameColumnName',
        defaultMessage: 'Имя параметра',
    },
    paramValueColumnName: {
        id: 'ScriptScheduler.paramValueColumnName',
        defaultMessage: 'Значение',
    },
    noParameters: {
        id: 'ScriptScheduler.noParameters',
        defaultMessage: 'Нет параметров',
    },
    parameters: {
        id: 'ScriptScheduler.parameters',
        defaultMessage: 'Параметры',
    },
    delete: {
        id: 'ScriptScheduler.delete',
        defaultMessage: 'Удаление запланированного скрипта',
    },
    columnNameLastStart: {
        id: 'ScriptScheduler.columnNameLastStart',
        defaultMessage: 'Предыдущий запуск',
    },
    columnNameLastStartStatus: {
        id: 'ScriptScheduler.columnNameLastStartStatus',
        defaultMessage: 'Статус запуска',
    },
    columnNameNextStart: {
        id: 'ScriptScheduler.columnNameNextStart',
        defaultMessage: 'Следующий запуск',
    },
    columnNameEnabled: {
        id: 'ScriptScheduler.columnNameEnabled',
        defaultMessage: 'Включен',
    },
    yes: {
        id: 'ScriptScheduler.yes',
        defaultMessage: 'Да',
    },
    no: {
        id: 'ScriptScheduler.no',
        defaultMessage: 'Нет',
    },
    SUCCESS: {
        id: 'ScriptScheduler.SUCCESS',
        defaultMessage: 'Успешно',
    },
    ACCESS_ERROR: {
        id: 'ScriptScheduler.ACCESS_ERROR',
        defaultMessage: 'Нет функционального права',
    },
    SCRIPT_NOT_FOUND: {
        id: 'ScriptScheduler.SCRIPT_NOT_FOUND',
        defaultMessage: 'Нет доступа к скрипту',
    },
    LICENSE_ERROR: {
        id: 'ScriptScheduler.LICENSE_ERROR',
        defaultMessage: 'Нет лицензии',
    },
    START_ERROR: {
        id: 'ScriptScheduler.START_ERROR',
        defaultMessage: 'Ошибка запуска',
    },
});
