import { defineMessages } from 'react-intl';

export default defineMessages({
    id: {
        id: 'InputId.id',
        defaultMessage: 'ID',
    },
    requiredField: {
        id: 'InputId.requiredField',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
    maxCharacters: {
        id: 'InputGuid.maxCharacters',
        defaultMessage: 'Допустимо значение GUID не больше 100 символов',
    },
    unacceptableCharacters: {
        id: 'InputGuid.unacceptableCharacters',
        defaultMessage: 'Введены недопустимые символы',
    },
});
