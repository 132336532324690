import React, {ChangeEvent, FC} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {FormInstance, Select} from "antd";
import theme from "../Presets.scss";
import messages from "./PresetImage.messages";
import {
    InternationalString,
    PresetImage,
    PresetImageGroup
} from "../../../../../../serverapi/api";
import {
    MultiLangInputDialog
} from "../../../../../MultiLangInputDialog/MultiLangInputDialog.component";
import {useSelector} from "react-redux";
import {getCurrentLocale} from "../../../../../../selectors/locale.selectors";
import {LocalesService} from "../../../../../../services/LocalesService";
import imageTheme from "./PresetImage.scss";
import Dragger from "antd/lib/upload/Dragger";
import {
    Icon as UIKitIcon
} from "../../../../../UIKit/components/Icon/Icon.component";
import iconOk from "../../../../../../resources/icons/Symbol_OK.svg";
import {InboxOutlined} from "@ant-design/icons";
import {UploadRequestOption} from "rc-upload/lib/interface";
import { InputId } from "../../../../../InputId/InputId.component";

type TGeneralTabProps = {
    presetImage: PresetImage;
    presetImageGroups: PresetImageGroup[];
    createMode: boolean;
    generalForm: FormInstance<any> | undefined;
    onChangePresetImageName: (multilingualName: InternationalString) => void;
    onDropFile: (fileBase64: String) => void;
    onChangePresetImageId: (id: string) => void;
    onChangePresetImageGroup: (presetImageGroup: PresetImageGroup) => void;
};

export const GeneralTab: FC<TGeneralTabProps> = (props) => {
    const currentLocale = useSelector(getCurrentLocale);
    const { createMode, presetImage, generalForm, presetImageGroups, onChangePresetImageId } = props;
    const intl = useIntl();

    const onChangePresetImageGroup = (groupId: string) => {
        const changeGroup = presetImageGroups.find((otg) => otg.id === groupId);
        if (changeGroup) {
            props.onChangePresetImageGroup(changeGroup);
        }
    };

    const dropFile = (e: UploadRequestOption) => {
        if (e.file) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file as File);
            reader.onloadend = () => {
                props.onDropFile(reader.result as string)
            };
        }
    };

    return (
        <div className={theme.form}>
            <div>
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.name)}
                    multiLangValue={presetImage.multilingualName}
                    onChange={props.onChangePresetImageName}
                    label={intl.formatMessage(messages.name)}
                    mainInputName="multilingualName"
                    generalForm={generalForm}
                    required
                    autoFocus
                    data-test="preset-icon-name-input"
                />
            </div>

            <div className={theme.selectGroupType}>
                <span><span className={theme.customRequiredAsterisk}>*</span>{intl.formatMessage(messages.presetImageGroups)}</span>
                <br />
                <Select data-test="preset-icon-group-select"
                    className={theme.selectGroupType}
                    onChange={onChangePresetImageGroup}
                    value={presetImage.presetImageGroup?.id}
                >
                    {presetImageGroups.map((group: PresetImageGroup) => {
                        return (
                            <Select.Option key={group.id} value={group.id}>
                                {LocalesService.internationalStringToString(group.multilingualName, currentLocale)}
                            </Select.Option>
                        );
                    })}
                </Select>
            </div>
            <div className={theme.guidInput}>
                <InputId
                    disabled={!createMode}
                    value={presetImage.id}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChangePresetImageId(e.target.value);
                    }}
                    required
                    mainInputName={intl.formatMessage(messages.id)}
                />
            </div>
            <div className={imageTheme.uploadArea}>
                <span className={theme.customRequiredAsterisk}>*</span>
                <Dragger
                    name="file"
                    multiple={false}
                    showUploadList={false}
                    customRequest={dropFile}
                    className={imageTheme.dragger}
                >
                    {presetImage.graphical ? (
                        <UIKitIcon className={theme.uploadSvg} spriteSymbol={iconOk} />
                    ) : (
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                    )}

                        <p className="ant-upload-text">
                            <FormattedMessage {...messages.uploadText} />
                        </p>

                </Dragger>
                {
                    presetImage.graphical && <div className={imageTheme.imageContainer}>
                    <img className={imageTheme.image} src={presetImage.graphical}/>
                </div>
                }
            </div>
        </div>
    );
};
