import { ObjectType } from '../serverapi/api';
import { TReducer } from '../utils/types';
import {
    OBJECT_TYPE_DELETE_REQUEST_SUCCESS,
    OBJECT_TYPE_REQUEST_SUCCESS,
} from '../actionsTypes/objectType.actionTypes';
import { TObjectTypeState } from './objectType.reducer.types';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import { APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM } from '../actionsTypes/app.actionTypes';
import cloneDeep from 'lodash/cloneDeep';

export const INITIAL_OBJECT_TYPE_SERVER_STATE = {
    byPresetId: {},
};

const initial: TObjectTypeState = {
    byServerId: {},
};

const initStateWithServerAndPreset = (state: TObjectTypeState, serverId: string, presetId: string) => {
    if (!state.byServerId[serverId]) {
        state.byServerId[serverId] = {
            byPresetId: {},
        };
    }
    if (!state.byServerId[serverId].byPresetId[presetId]) {
        state.byServerId[serverId].byPresetId[presetId] = {
            byId: {},
        };
    }
};

export const objectTypeReducer: TReducer<TObjectTypeState> = (state = initial, action) => {
    switch (action.type) {
        case APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM: {
            const {
                payload: { definition },
            } = action;

            if (!definition?.modelType?.objectTypes) {
                return state;
            }
            const byId: { [id: string]: ObjectType } = {};
            const newState = { byServerId: { '': { byPresetId: { '': { byId } } } } };

            definition.modelType.objectTypes.forEach((ot) => {
                byId[ot.id] = ot;
            });

            return { ...newState };
        }

        case OBJECT_TYPE_REQUEST_SUCCESS: {
            const {
                payload: { serverId, objectTypes, presetId },
            } = action;

            const stateClone: TObjectTypeState = cloneDeep(state);
            initStateWithServerAndPreset(stateClone, serverId, presetId);
            const byId = stateClone.byServerId[serverId].byPresetId[presetId].byId;

            objectTypes.forEach((objectType) => {
                byId[objectType.id] = objectType;
            });

            return stateClone;
        }

        case OBJECT_TYPE_DELETE_REQUEST_SUCCESS: {
            const { objectTypes, serverId } = action.payload;
            const stateClone = cloneDeep(state);
            objectTypes.forEach((objectType) => {
                if (!objectType.presetId || !objectType.id) return;
                const byId = stateClone.byServerId?.[serverId]?.byPresetId?.[objectType.presetId]?.byId || {};
                delete byId[objectType.id];
            });

            return stateClone;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            const stateClone = cloneDeep(state);
            delete stateClone.byServerId?.[serverId]?.byPresetId?.[presetId];

            return stateClone;
        }
        default:
            return state;
    }
};
