import React from 'react';
import { Checkbox } from '../Checkbox/Checkbox.component';
import { useFocus } from './useFocus';
import theme from './Select.scss';
import { TOptionProps } from './Select.types';

/**
 * используется для компонента Select в качестве списка options
 *
 * @param {string} value строка, которая будет передана в onChange
 * @param {string} label строка, которая будет показана пользователю
 * @param {string | undefined} key react key, можно не передавать, тогда он будет создан useId
 * @param {boolean | undefined} showCheckbox автоматически проставляется для isMultiSelect === true
 * @param {boolean | undefined} checked стоит ли галочка в чекбоксе
 * @param {boolean | undefined} disabled дизейбл
 * @param {boolean | undefined} isActive элемент выбран с клавиатуры
 * @param {((string: any) => void) | undefined} onChange функция, которая вызывается при изменении
 */
export const Option = (props: TOptionProps) => {
    const { value, label, showCheckbox, checked, disabled, isActive, onChange } = props;
    const [ref, setFocus] = useFocus(null);

    if (isActive) {
        setFocus();
    }

    const handleChange = () => onChange && onChange(value);

    return (
        <div role="button" ref={ref} tabIndex={0} className={theme.optionWrapper}>
            {showCheckbox ? (
                <Checkbox
                    checked={checked}
                    disabled={disabled}
                    onChange={handleChange}
                    className={theme.option}
                    key={value}
                >
                    {label}
                </Checkbox>
            ) : (
                <div className={theme.option} onClick={handleChange} key={value}>
                    {label}
                </div>
            )}
        </div>
    );
};
