/* tslint:disable */
import { BPMMxGraph, BPMMxGraphOptions } from './bpmgraph';
import { Vector } from 'matter-js';
import { EditorMode } from '../models/editorMode';
import {
    MxCell,
    MxCellState,
    MxConstants,
    MxEdgeStyle,
    MxEvent,
    MxPoint,
    MxRubberband,
    MxStyleRegistry,
} from './mxgraph';
import { intersection } from 'lodash';
import { KeyCodes } from '../utils/keys';
import { isNullOrUndefined } from 'is-what';
import { BPMMxGraphModel } from './BPMMxGraphModel.class';

const angleToRad = Math.PI / 180;

type CustomStyle = {
    arrowOffset: number;
    startArrowOffset: number;
    endArrowOffset: number;
};

MxEdgeStyle['MindMapEdge'] = (
    state: MxCellState,
    source: MxCellState,
    target: MxCellState,
    points: Array<MxPoint>,
    result: Array<MxPoint | null>,
) => {
    if (source != null && target != null) {
        const sourcePoint = new MxPoint(source.getCenterX(), source.getCenterY());
        const targetPoint = new MxPoint(target.getCenterX(), target.getCenterY());
        const commonEdges = intersection(source.cell.edges, target.cell.edges);
        const mxCellEdgeIndex = commonEdges.indexOf(state.cell);
        let angleBeetwenEdges = Math.round(180 / commonEdges.length);
        if (angleBeetwenEdges > 10) {
            angleBeetwenEdges = 10;
        }
        const style = <CustomStyle>state.style;

        const startOffset = style.arrowOffset || style.startArrowOffset || 0;
        const endOffset = style.arrowOffset || style.endArrowOffset || 0;

        let dx = targetPoint.x - sourcePoint.x; // * .05 * multiplier;
        let dy = targetPoint.y - sourcePoint.y; // * .05 * multiplier;
        let pointDiff = Vector.create(dx, dy);
        const xAxis = Vector.create(-100, 0);
        const startPointOffset = Vector.create(startOffset, 0);
        const endPointOffset = Vector.create(endOffset, 0);

        const radBetweenPoints = Vector.angle(pointDiff, xAxis);

        const offsetStart = Vector.rotate(
            startPointOffset,
            radBetweenPoints +
                ((mxCellEdgeIndex % 2 ? -1 : 1) * Math.ceil(mxCellEdgeIndex / 2) * angleBeetwenEdges + 180) *
                    angleToRad,
        );
        sourcePoint.x += offsetStart.x;
        sourcePoint.y += offsetStart.y;

        const offsetEnd = Vector.rotate(
            endPointOffset,
            radBetweenPoints +
                (-(mxCellEdgeIndex % 2 ? -1 : 1) * Math.ceil(mxCellEdgeIndex / 2) * angleBeetwenEdges + 180) *
                    angleToRad,
        );
        targetPoint.x -= offsetEnd.x;
        targetPoint.y -= offsetEnd.y;

        state.absolutePoints[state.absolutePoints.length - 1] = targetPoint;
        result[0] = sourcePoint;

        if (commonEdges.length > 1 && mxCellEdgeIndex > 0) {
            const point1 = new MxPoint(targetPoint.x, targetPoint.y);
            const point2 = new MxPoint(sourcePoint.x, sourcePoint.y);

            dx = point1.x - point2.x;
            dy = point1.y - point2.y;
            pointDiff = Vector.create(dx, dy);
            const magnitude = Vector.magnitude(pointDiff) / 10;

            pointDiff = Vector.rotate(
                Vector.create(magnitude, 0),
                radBetweenPoints + (mxCellEdgeIndex % 2 ? 1 : -1) * 15 * angleToRad,
            );

            const pointDiff2 = Vector.rotate(
                Vector.create(magnitude, 0),
                radBetweenPoints + (mxCellEdgeIndex % 2 ? -1 : 1) * 15 * angleToRad,
            );

            point2.x -= pointDiff2.x;
            point2.y -= pointDiff2.y;
            result.push(point2);

            point1.x += pointDiff.x;
            point1.y += pointDiff.y;
            result.push(point1);
        }
    }
};
MxConstants['EDGESTYLE_MINDMAP'] = 'MindMapEdge';
MxStyleRegistry.putValue('MindMapEdge', MxEdgeStyle['MindMapEdge']);

export class DefaultGraph extends BPMMxGraph {
    allowDanglingEdges = true;
    disconnectOnMove = false;
    isGraphModelChanged: boolean = false;
    mode: EditorMode = EditorMode.Read;

    constructor(options: BPMMxGraphOptions & { mode?: EditorMode } = {}) {
        super({
            ...options,
            model: isNullOrUndefined(options.model) ? new BPMMxGraphModel() : options.model,
        });

        this.connectionHandler.setEnabled(false);
        this.setHtmlLabels(false);
        this.initElbowConnection();
        this.initSelection();
        this.setMode(options?.mode || EditorMode.Read);
        this.vertexLabelsMovable = false;

        this.container.addEventListener(
            'keydown',
            (e: KeyboardEvent) => {
                if (
                    this.mode != EditorMode.Read &&
                    this.getSelectionCells() &&
                    this.getSelectionCells().length > 0 &&
                    !this.cellEditor.isContentEditing() &&
                    [KeyCodes.DOWN, KeyCodes.UP, KeyCodes.LEFT, KeyCodes.RIGHT].indexOf(e.keyCode) > -1
                ) {
                    e.preventDefault();
                }
            },
            false,
        );
    }

    graphModelChanged(changes: any) {
        this.isGraphModelChanged = true;
        return super.graphModelChanged(changes);
    }

    insertVertex(
        parent: MxCell,
        id: string,
        value: any,
        x: number,
        y: number,
        width: number,
        height: number,
        style?: string,
        relative?: boolean,
        labelStyle?: string,
        labelWidth?: number,
        labelHeight?: number,
        labelXOffset?: number,
        labelYOffset?: number,
    ): any {
        if (this.enabled) {
            return super.insertVertex.apply(this, arguments);
        }

        return;
    }

    setMode(mode: EditorMode) {
        const setEditable = (value: boolean) => {
            this.setCellsSelectable(true);
            // this.setEdgesSelectable(value);
            this.setConnectableEdges(value);
            this.setCellsDisconnectable(value);
            this.setConnectable(value);
            this.setCellsEditable(value);
            this.setCellsDeletable(value);
            this.setDropEnabled(value);
            this.setCellsResizable(value);
            this.setCellsCloneable(value);
            this.setCellsBendable(value);
            this.graphHandler.setMoveEnabled(value);
            this.setEdgeLabelsMovable(value);
        };

        if (mode === EditorMode.Edit) {
            this.container?.focus();
            setEditable(true);
            this.nodeFilter.resetFiltration();
        } else if (mode === EditorMode.Read) {
            setEditable(false);
            this.nodeFilter.resetFiltration();
        }

        this.mode = mode;
    }

    click(me: any) {
        // tslint:disable-line:no-any
        super.click(me);
        if (
            this.hoverIcons &&
            this.hoverIcons.currentState &&
            !this.isCellSelected(this.hoverIcons.currentState.cell) &&
            MxEvent.isTouchEvent(me.getEvent()) &&
            !this.model.isVertex(me.getCell())
        ) {
            this.hoverIcons.reset();
        }
    }

    initElbowConnection() {
        const style = this.getStylesheet().getDefaultEdgeStyle();

        style[MxConstants.STYLE_ROUNDED] = true;
        style[MxConstants.STYLE_EDGE] = 'orthogonalEdgeStyle';
    }

    initSelection() {
        new MxRubberband(this); // tslint:disable-line:no-unused-expression
    }

    isGraphUpdated(): boolean {
        return this.isGraphModelChanged;
    }

    resetPropertyIsGraphUpdated() {
        this.isGraphModelChanged = false;
    }
}
