/* eslint-disable prefer-destructuring */
import { BPMMxGraphHandler } from '../handler/BPMMxGraphHandler';
import { MxMouseEvent, MxPoint, MxCellState, MxCell, MxConstants, MxGeometry, MxRectangle } from '../mxgraph';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolsManager.class';
import { SequenceGraph } from './SequenceGraph';
import { MxUtils } from '../util/MxUtils';
import { FRAME_STYLES_INDICATOR } from '../ComplexSymbols/symbols/ComplexSymbol.constants';
import SequenceUtils from '../ComplexSymbols/symbols/Sequence/sequence.utils';
import { cellsTreeWalkerDown, currentTreeDepth } from '../ComplexSymbols/utils';
import { forkEdgeOffset } from './SequenceConstants';
import { SequenceGraphView } from './SequenceGraphView';
import { BPMMxGraphModel } from '../BPMMxGraphModel.class';

export class SequenceGraphHandler extends BPMMxGraphHandler {
    graph: SequenceGraph;
    guidesEnabled = false;
    clickedCell: MxCell;

    /**
     * Расчет смещения относительно начала движения
     * @param {MxMouseEvent} me - информация о событии
     * @returns {MxPoint} - смещение
     */
    getDelta(me: MxMouseEvent) {
        let newPoint: MxPoint = super.getDelta(me);
        const cell: MxCell = this.clickedCell;

        // если у ячейки есть frame и нет complexSymbolTypeId, значит это символ исполнения
        if (SequenceUtils.isSequenceExecutionSymbol(cell)) {
            this.setRemoveCellsFromParent(false);
            newPoint = this.graph.calculateBoundsForExecutionSymbol(cell, newPoint);
        }

        if (SequenceUtils.isUmlMessage(cell)) {
            const view: SequenceGraphView = this.graph.getView();
            const messageCellState: MxCellState = view.getState(cell);

            const connectedElements: MxCell[] = [];
            if (cell.edges) {
                cell.edges.forEach((edge: MxCell) => {
                    const edgeTrg: MxCell = edge.target;
                    const edgeSrc: MxCell = edge.source;
                    if (!SequenceUtils.isUmlMessage(edgeTrg)) connectedElements.push(edgeTrg);
                    if (!SequenceUtils.isUmlMessage(edgeSrc)) connectedElements.push(edgeSrc);
                });
            }

            connectedElements.forEach((connectedElem: MxCell) => {
                const state: MxCellState = view.getState(connectedElem);
                const minDy: number = state.y - messageCellState.y;
                newPoint.y = Math.max(newPoint.y, minDy);
            });
        }

        return newPoint;
    }

    /**
     * Обновление предварительного просмотра объектов во время перемещения
     * @param {number} dx - смещение по оси x
     * @param {number} dy - смещение по оси y
     */
    updateLivePreview(dx: number, dy: number) {
        if (!this.suspended) {
            const states: MxCellState[][] = [];
            const maxDelta: { srcMaxDy: number; trgMaxDy: number }[] = [];

            if (this.allCells != null) {
                this.allCells.visit((key, state: MxCellState) => {
                    let currentState: MxCellState = state;
                    const realState: MxCellState = this.graph.view.getState(currentState.cell);
                    const currentCell: MxCell = currentState.cell;
                    let newPoint = new MxPoint(dx, dy);

                    const srcMaxDy: number = this.graph.getMaxDy(state, true);
                    const trgMaxDy: number = this.graph.getMaxDy(state);
                    maxDelta.push({ srcMaxDy, trgMaxDy });

                    const isSequenceExecutionSymbol: boolean = SequenceUtils.isSequenceExecutionSymbol(currentCell);
                    if (isSequenceExecutionSymbol) {
                        const parentIsContained: boolean = (this.allCells.getValues() as MxCellState[]).some(
                            (cellState: MxCellState) => cellState.cell.id === currentCell?.parent?.id,
                        );

                        const frameParent: MxCell | undefined =
                            ComplexSymbolManager.getComplexSymbolRootCell(currentCell);

                        const parentFrameIsContained: boolean = (this.allCells.getValues() as MxCellState[]).some(
                            (cellState: MxCellState) => cellState.cell.id === frameParent?.id,
                        );

                        if (!parentFrameIsContained) {
                            newPoint.x = 0;
                        }
                        if (!parentIsContained) {
                            newPoint = this.graph.calculateBoundsForExecutionSymbol(currentCell, newPoint);
                        }
                    }
                    // Checks if cell was removed or replaced
                    if (realState !== currentState) {
                        currentState.destroy();

                        if (realState !== null) {
                            this.allCells.put(currentState.cell, realState);
                        } else {
                            this.allCells.remove(currentState.cell);
                        }

                        currentState = realState;
                    }

                    if (currentState != null) {
                        // Saves current state
                        const tempState: MxCellState = currentState.clone();

                        states.push([currentState, tempState]);

                        // Makes transparent for events to detect drop targets
                        if (currentState.shape != null) {
                            if (currentState.shape.originalPointerEvents == null) {
                                currentState.shape.originalPointerEvents = currentState.shape.pointerEvents;
                            }

                            currentState.shape.pointerEvents = false;

                            if (currentState.text != null) {
                                if (currentState.text.originalPointerEvents == null) {
                                    currentState.text.originalPointerEvents = currentState.text.pointerEvents;
                                }

                                currentState.text.pointerEvents = false;
                            }
                        }

                        // Temporarily changes position
                        if (this.graph.model.isVertex(currentState.cell)) {
                            currentState.x += newPoint.x;
                            currentState.y += newPoint.y;

                            // Draws the live preview
                            if (!this.cloning) {
                                currentState.view.graph.cellRenderer.redraw(currentState, true);

                                // Forces redraw of connected edges after all states
                                // have been updated but avoids update of state
                                currentState.view.invalidate(currentState.cell);
                                currentState.invalid = false;

                                if (isSequenceExecutionSymbol) {
                                    const frameParent: MxCell | undefined =
                                        ComplexSymbolManager.getComplexSymbolRootCell(currentState.cell);

                                    if (frameParent) {
                                        frameParent.edges?.forEach((edge: MxCell) => {
                                            const edgeState: MxCellState = this.graph.view.getState(edge);
                                            this.graph.view.updateCellState(edgeState);
                                            this.graph.cellRenderer.redraw(edgeState, true);
                                        });
                                    }
                                }

                                // Hides folding icon
                                if (currentState.control != null && currentState.control.node != null) {
                                    currentState.control.node.style.visibility = 'hidden';
                                }
                            }
                            // Clone live preview may use text bounds
                            else if (currentState.text != null) {
                                currentState.text.updateBoundingBox();

                                // Fixes preview box for edge labels
                                if (currentState.text.boundingBox != null) {
                                    currentState.text.boundingBox.x += newPoint.x;
                                    currentState.text.boundingBox.y += newPoint.y;
                                }

                                if (currentState.text.unrotatedBoundingBox != null) {
                                    currentState.text.unrotatedBoundingBox.x += newPoint.x;
                                    currentState.text.unrotatedBoundingBox.y += newPoint.y;
                                }
                            }
                        }
                    }
                });
            }

            // Resets the handler if everything was removed
            if (states.length === 0) {
                this.reset();
            } else {
                // Redraws connected edges
                const s: number = this.graph.view.scale;
                for (let i = 0; i < states.length; i++) {
                    const state: MxCellState = states[i][0];
                    if (this.graph.model.isEdge(state.cell)) {
                        const delta = new MxPoint(dx, dy);
                        const edgeState: MxCellState = state;
                        const isRecursive: boolean = this.graph.isRecursiveEdge(edgeState.cell);
                        const isForkEdge: boolean = SequenceUtils.isForkEdge(edgeState.cell);
                        let isMainEdgeSelected: boolean = false;

                        if (isForkEdge) {
                            const mainEdgeId: string = SequenceUtils.getMainEdgeId(edgeState.cell);
                            isMainEdgeSelected = states.some(([originState]) => originState.cell.id === mainEdgeId);
                        }

                        const iniAbsPts: MxPoint[] = edgeState.cell.geometry.points.map(
                            (point) => new MxPoint(point.x * s, point.y * s),
                        );
                        const srcMaxDy: number = maxDelta[i].srcMaxDy;
                        const trgMaxDy: number = maxDelta[i].trgMaxDy;
                        const currentPoint = new MxPoint(
                            iniAbsPts[1].x,
                            iniAbsPts[1].y + Math.max(srcMaxDy, trgMaxDy, dy),
                        );
                        const currentForkPoint = new MxPoint(
                            iniAbsPts[0].x,
                            iniAbsPts[0].y + Math.max(srcMaxDy, trgMaxDy, dy),
                        );

                        if (edgeState.absolutePoints && edgeState.absolutePoints.length > 1) {
                            const srcState: MxCellState = edgeState.visibleSourceState;
                            const trgState: MxCellState = edgeState.visibleTargetState;
                            const isReverce: boolean = srcState.getCenterX() > trgState.getCenterX();

                            let umlMessage: MxCellState | null = null;
                            if (SequenceUtils.isUmlMessage(trgState.cell)) umlMessage = trgState;
                            if (SequenceUtils.isUmlMessage(srcState.cell)) umlMessage = srcState;
                            if (umlMessage) {
                                currentPoint.y = umlMessage.y + umlMessage.height / 2;
                            }

                            let pointForTarget: MxPoint = currentPoint.clone();

                            const srcPoint: MxPoint = isForkEdge
                                ? edgeState.absolutePoints[1]
                                : edgeState.absolutePoints[0];
                            const trgPoint: MxPoint = edgeState.absolutePoints[edgeState.absolutePoints.length - 1];

                            let forkPoint: MxPoint | null = null;
                            if (isForkEdge && isMainEdgeSelected) {
                                forkPoint = edgeState.absolutePoints[0];
                            }

                            if (this.graph.isDurationEdge(edgeState.cell)) {
                                // расчет положения курсора для наклонной связи
                                const a: number = Math.abs(currentPoint.x - trgPoint.x);
                                const newDy: number = edgeState.height - Math.abs(a / Math.tan(30));
                                currentPoint.y = Math.max(currentPoint.y - newDy, srcState.y, trgState.y);

                                if (currentPoint && srcPoint) {
                                    const srcConstrainX: number = this.graph.connectionHandler.calcXConstrain(
                                        edgeState,
                                        true,
                                        null,
                                        isReverce,
                                        isRecursive,
                                    );
                                    const trgConstrainX: number = this.graph.connectionHandler.calcXConstrain(
                                        edgeState,
                                        false,
                                        null,
                                        isReverce,
                                        isRecursive,
                                    );
                                    const bounds: MxRectangle = this.graph.view.getPerimeterBounds(trgState);
                                    const trgX: number = bounds.x + bounds.width * trgConstrainX;
                                    const srcBounds: MxRectangle = this.graph.view.getPerimeterBounds(srcState);
                                    const srcX: number = srcBounds.x + srcBounds.width * srcConstrainX;
                                    pointForTarget.y = this.graph.connectionHandler.calcYForDurationEdge(
                                        srcX,
                                        trgX,
                                        currentPoint.y,
                                    );
                                }
                            }

                            if (isRecursive) {
                                pointForTarget = trgPoint.clone();
                            }

                            if (trgPoint && srcPoint) {
                                if (isRecursive) {
                                    if (iniAbsPts[0].y + delta.y > srcState.y) {
                                        edgeState.absolutePoints = iniAbsPts.map(
                                            (point: MxPoint) => new MxPoint(point.x, point.y + delta.y),
                                        );
                                    } else {
                                        const newDy: number = Math.abs(iniAbsPts[0].y - srcState.y);
                                        edgeState.absolutePoints = iniAbsPts.map(
                                            (point: MxPoint) => new MxPoint(point.x, point.y - newDy),
                                        );
                                    }
                                } else {
                                    srcPoint.y = currentPoint.y;
                                    trgPoint.y = pointForTarget.y;
                                    if (forkPoint) forkPoint.y = currentForkPoint.y;
                                }
                            }

                            const srcConstrainX: number = this.graph.connectionHandler.calcXConstrain(
                                edgeState,
                                true,
                                null,
                                isReverce,
                                isRecursive,
                            );
                            const trgConstrainX: number = this.graph.connectionHandler.calcXConstrain(
                                edgeState,
                                false,
                                null,
                                isReverce,
                                isRecursive,
                            );

                            edgeState.style[MxConstants.STYLE_EXIT_X] = srcConstrainX;
                            edgeState.style[MxConstants.STYLE_ENTRY_X] = trgConstrainX;

                            edgeState.absolutePoints[0].x = srcState.x + srcState.width * srcConstrainX;
                            edgeState.absolutePoints[edgeState.absolutePoints.length - 1].x =
                                trgState.x + trgState.width * trgConstrainX;

                            if (isForkEdge) {
                                edgeState.absolutePoints[1].x =
                                    edgeState.absolutePoints[0].x + (isReverce ? -forkEdgeOffset : forkEdgeOffset);
                            }

                            if (isRecursive && edgeState.absolutePoints[0].x > edgeState.absolutePoints[1].x) {
                                edgeState.absolutePoints[1].x = edgeState.absolutePoints[0].x + 20;
                                edgeState.absolutePoints[2].x = edgeState.absolutePoints[0].x + 20;
                            }

                            const srcContains: boolean = MxUtils.contains(
                                srcState,
                                srcState.x,
                                edgeState.absolutePoints[0].y,
                            );
                            const trgContains: boolean = MxUtils.contains(
                                trgState,
                                trgState.x,
                                edgeState.absolutePoints[edgeState.absolutePoints.length - 1].y,
                            );

                            if (!srcContains) this.graph.setCellHeight(srcState);
                            if (!trgContains) this.graph.setCellHeight(trgState);
                        }
                    }
                }

                this.graph.view.validate();
                this.redrawHandles(states);
                this.resetPreviewStates(states);
            }
        }
    }

    /**
     * Метод перемещения ячеек графа
     * @param { MxCell[] } movableCells - выделенные объекты на графе
     * @param { number } dx - смещение по оси x
     * @param { number } dy - смещение по оси y
     * @param { boolean } clone - копирование объектов (зажат ctrl)
     * @param { MxCell } target - целевой объект перемещения
     * @param { Event } evt - информация о событии
     */
    moveCells(cells: MxCell[], dx: number, dy: number, clone: boolean, target: MxCell, evt?: Event) {
        const movableCells: MxCell[] = cells.filter((cell: MxCell) => cell.getStyle().indexOf('movable=0;') === -1);

        let newTarget: MxCell | null = target;
        if (
            SequenceUtils.isSequenceDiagramCell(this.clickedCell) ||
            SequenceUtils.isSequenceDiagramCell(target) ||
            SequenceUtils.isSequenceExecutionSymbol(this.clickedCell) ||
            SequenceUtils.isSequenceExecutionSymbol(target) ||
            target?.getStyle()?.includes(FRAME_STYLES_INDICATOR)
        ) {
            newTarget = null;
        }
        super.moveCells(movableCells, dx, dy, clone, newTarget, evt);
        this.initCellsMerging(movableCells);
    }

    /**
     *  Метод инициализации объединения символов исполнения после события перемещения
     *  Ячеек может быть несколько, например при массовом переносе
     * @param { MxCell[] } cells - массив ячеек, инициализирующих объединение
     */
    initCellsMerging(cells: MxCell[]) {
        // собираем все frameIds, которые участвовали в переносе
        const frameIds: Set<string> = new Set();
        cells.forEach((cell: MxCell) => {
            if (cell.frame) {
                frameIds.add(cell.frame);
            }
        });
        const allGraphCells: MxCell[] = Object.values(this.graph.model.cells);

        // разделяем ячейки по уровням и frameId
        const cellsTree: Map<string, { [index: number]: MxCell[] }> = new Map();
        frameIds.forEach((frameId) => {
            const frameCells: MxCell[] = allGraphCells.filter(
                (cell: MxCell) => SequenceUtils.isSequenceExecutionSymbol(cell) && cell.frame === frameId,
            );
            const tree: { number: MxCell[] } | {} = {};
            frameCells.forEach((cell: MxCell) => {
                const lvl: number = currentTreeDepth(cell);
                if (tree[lvl]) {
                    tree[lvl].push(cell);
                } else {
                    tree[lvl] = [cell];
                }
            });
            cellsTree.set(frameId, tree);
        });

        // проходимся по ячейкам с нижнего уровня, сортируем их сверху-вниз относительно положения на графе
        // и последовательно выполняем объединение если они пересекаются
        for (const frameId of cellsTree.keys()) {
            const frameCellsByLvls:
                | {
                      [index: number]: MxCell[];
                  }
                | undefined = cellsTree.get(frameId);

            if (!frameCellsByLvls) return;

            const sortedLvls: string[] = Object.keys(frameCellsByLvls).sort(
                (a: string, b: string) => Number(b) - Number(a),
            );

            sortedLvls.forEach((lvl: string) => {
                const sortedLvlCells: MxCell[] = frameCellsByLvls[lvl].sort((prevCell: MxCell, nextCell: MxCell) => {
                    const prevCellState: MxCellState = this.graph.view.getState(prevCell);
                    const nextCellState: MxCellState = this.graph.view.getState(nextCell);

                    return prevCellState.y - nextCellState.y;
                });

                const mergedCells: Set<MxCell> = new Set();
                sortedLvlCells.forEach((cell1: MxCell) => {
                    const stateCell1: MxCellState = this.graph.view.getState(cell1);
                    let flag: boolean = false;
                    mergedCells.forEach((cell2: MxCell) => {
                        const stateCell2: MxCellState = this.graph.view.getState(cell2);
                        if (MxUtils.intersects(stateCell1, stateCell2) && !flag) {
                            flag = true;
                            this.mergeCells(cell2, stateCell2, cell1, stateCell1);
                        }
                    });
                    if (!flag) mergedCells.add(cell1);
                });
            });
        }
    }

    /**
     *  Метод объединяет два символа исполнения в один
     * @param { MxCell } cell1 - первый символ исполнения для объединения
     * @param { MxCellState } state1 - состояние первого символа исполнения
     * @param { MxCell } cell2 - второй символ исполнения для объединения
     * @param { MxCellState } state2 - состояние второго символа исполнения
     */
    mergeCells(cell1: MxCell, state1: MxCellState, cell2: MxCell, state2: MxCellState) {
        this.graph.model.beginUpdate();

        const dy: number = Math.min(state1.y, state2.y) - state1.y;
        const maxY: number = Math.max(state1.y + state1.height, state2.y + state2.height);
        const newHeight: number = maxY - (state1.y + dy);
        const childrenDy: number = state2.y - state1.y;

        const { x, y, width } = cell1.geometry;
        const newGeometry: MxGeometry = new MxGeometry(x, y + dy, width, newHeight);
        this.graph.model.setGeometry(cell1, newGeometry);

        const childrenCellsForMerge: MxCell[] = cell2.children || [];
        childrenCellsForMerge.forEach((childrenCell: MxCell) => {
            cell1.insert(childrenCell);
            const newChildGeo: MxGeometry = childrenCell.geometry.clone();
            newChildGeo.y += childrenDy;
            this.graph.model.setGeometry(childrenCell, newChildGeo);
        });
        this.graph.removeCells([cell2]);

        this.graph.model.endUpdate();
    }

    /**
     *  Метод разделения симола исполнения в месте клика
     * @param { MxCell } initialCell - источник события
     */
    splitCell(initCell: MxCell) {
        const { triggerY }: { triggerY: number } = this.graph.popupMenuHandler;
        const { model }: { model: BPMMxGraphModel } = this.graph;

        model.beginUpdate();

        let prevCloneParent: MxCell | null = null;
        let dy: number = 0;

        cellsTreeWalkerDown([initCell], (cell: MxCell) => {
            const cellState: MxCellState = this.graph.view.getState(cell);

            if (triggerY > cellState.y && triggerY < cellState.y + cellState.height) {
                const parent: MxCell = prevCloneParent || cell.getParent();
                const cloneCell: MxCell = model.cloneCell(cell, false);
                cloneCell.complexSymbolRef = cell.complexSymbolRef;

                // считаем координаты и размер для нижней части символа после обрезания
                const bottomPart: number = cellState.y + cellState.height - triggerY;
                const bottomSpace: number = bottomPart >= 10 ? 5 : 0;
                dy = triggerY + bottomSpace - cellState.y;

                const cloneCellGeo: MxGeometry = cloneCell.geometry.clone();

                cloneCellGeo.y += prevCloneParent ? -cloneCellGeo.y : dy;
                cloneCellGeo.height -= dy;
                model.setGeometry(cloneCell, cloneCellGeo);

                // перенсоим нижнюю часть в качестве ребенка на нижнюю часть раннее обрезанного родителя
                this.graph.addCell(cloneCell, parent);

                // считаем размер для нижней части символа после обрезания
                const topPart: number = triggerY - cellState.y;
                const topSpace: number = topPart >= 10 ? 5 : 0;
                const dHeight: number = triggerY - cellState.y - cellState.height - topSpace;

                const cellGeo = cell.geometry.clone();

                cellGeo.height += dHeight;
                model.setGeometry(cell, cellGeo);

                prevCloneParent = cloneCell;

                // обработка случая, когда дочерний символ находится ниже линии разделения
            } else if (triggerY < cellState.y + cellState.height && prevCloneParent) {
                const cellGeo: MxGeometry = cell.geometry.clone();
                cellGeo.y -= dy;
                model.setGeometry(cell, cellGeo);
                prevCloneParent.insert(cell);
            }
        });

        model.endUpdate();
    }

    /**
     *  Возвращает ячейки, которые должны быть изменены этим обработчиком.
     * @param { MxCell } initialCell - источник события
     * @returns { MxCell[] } ячейки для обработки
     */
    getCells(initialCell: MxCell) {
        let result: MxCell[] = [initialCell];
        const { graph, delayedSelection }: { graph: SequenceGraph; delayedSelection: boolean } = this;

        if (!graph.isCellMovable(initialCell)) {
            return result;
        }

        const selectionCells: MxCell[] = graph.getSelectionCells();

        // если перемещаются более одной ячейки, то просто возвращаем все выделенные
        if (delayedSelection && selectionCells.length > 1) {
            result = selectionCells;
        }

        // фикс зависающих на месте лейблов при перемещении ячейки
        if (graph.isMainCell(initialCell)) {
            result.forEach((cell: MxCell) => {
                const label: MxCell = graph.getLabelCell(cell.id);
                if (graph.isMainCell(cell) && label) {
                    result.push(label);
                }
            });
        }

        // если переносим символ сообщение, нужно взять его связи
        result.forEach((cell: MxCell) => {
            if (SequenceUtils.isUmlMessage(cell) && cell.edges) {
                const edges: MxCell[] = cell.edges.filter((edge: MxCell) => !result.includes(edge));
                result.push(...edges);
            }
        });

        // если переносим основную связь, нужно взять ее ветвления
        result.forEach((cell: MxCell) => {
            if (cell.isEdge() && !SequenceUtils.isForkEdge(cell)) {
                const srcCell: MxCell = cell.source;
                const edges: MxCell[] = srcCell.edges.filter(
                    (edge: MxCell) => cell.value.id === SequenceUtils.getMainEdgeId(edge) && !result.includes(edge),
                );
                result.push(...edges);
            }
        });

        return result;
    }

    /**
     *  Функция перед обработкой событий мыши
     * @param { MxCell } cell - источник события
     * @param { number } x - начальная координата X
     * @param { number } y - начальная координата Y
     */
    start(cell: MxCell, x: number, y: number) {
        this.clickedCell = cell;
        super.start(cell, x, y);
    }

    /**
     *  Возвращает начальную ячейку для события
     * @param { MxMouseEvent } me - информация о событии
     * @returns { MxCell[] } начальную ячейку для данного события
     */
    getInitialCellForEvent(me: MxMouseEvent): MxCell | null {
        const state: MxCellState = me.getState();

        return state?.cell ? state.cell : null;
    }

    selectDelayed(me: MxMouseEvent): void {
        if (!this.graph.popupMenuHandler.isPopupTrigger(me)) {
            let cell: MxCell | null = me.getCell();

            if (cell === null) {
                cell = this.cell;
            }

            // @ts-ignore
            if (cell) this.graph.selectCellForEvent(cell, me);
        }
    }
}
