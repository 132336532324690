import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { forEach } from 'lodash';
import { Button } from 'antd';
import print from '../../../utils/print.utils';
import { Orientation, PaperFormat, Printable, TPrintOptions, ZoomMode } from '../../../utils/types';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { PrintForm } from './PrintForm.component';
import { PrintPreview } from './PrintPreview.component';
import { detectOrientation, getPrintHtml, getZoomToFit, getZoomToScale, resizeSvg } from './PrintDialog.utils';
import messages from './PrintDialog.messages';
import theme from './PrintDialog.component.scss';
import { TPrintDialogProps } from './PrintDialog.types';
import { MAX_PAGES, MAX_SCALE, MIN_PAGES, MIN_SCALE } from './PrintForm.constans';

type TPrintDialogState = {
    preview: boolean;
    canvas?: HTMLCanvasElement;
    options: TPrintOptions;
};

type TPrintDialogAllProps = TPrintDialogProps & WrappedComponentProps;

export const DEFAULT_ZOOM_LEVEL = 100;

export const DEFAULT_OFFSET = { top: 0, left: 0 };

export const DEFAULT_ORIENTATION = Orientation.PORTRAIT;

export const DEFAULT_PAPER_FORMAT = PaperFormat.A4;

class PrintDialog extends React.Component<TPrintDialogAllProps, TPrintDialogState> {
    state: TPrintDialogState = {
        preview: false,
        options: {
            grayscale: false,
            orientation: this.props.orientation,
            paperFormat: this.props.paperFormat || DEFAULT_PAPER_FORMAT,
            zoomMode: ZoomMode.SCALE,
            zoomFitAcross: 1,
            zoomFitDown: 1,
            zoomScale: this.props.zoomScale || DEFAULT_ZOOM_LEVEL,
            showOffsets: true,
            printable: Printable.MODEL,
        },
    };

    componentDidMount() {
        const { options } = this.state;
        const { orientation } = options;
        if (!orientation) {
            this.setState({ options: { ...options, orientation: this.getOrientation() } });
        }
    }

    getSvg = () => {
        const { data, selectedData } = this.props;
        const { zoomMode, printable, zoomScale } = this.state.options;

        const svgImage = printable === Printable.MODEL ? data : selectedData;

        let svgSize;
        if (zoomMode === ZoomMode.SCALE) {
            svgSize = getZoomToScale([svgImage.width * 0.4, svgImage.height * 0.4], zoomScale);
        } else if (zoomMode === ZoomMode.FIT) {
            svgSize = getZoomToFit([svgImage.width, svgImage.height], this.state.options);
        }

        return resizeSvg(svgImage, svgSize);
    };

    setCanvas = (callback?) => {
        const { convertSvg } = this.props;

        const svg = this.getSvg();

        convertSvg(svg, (canvas) => this.setState({ canvas }, callback));
    };

    getOrientation = () => {
        const { options } = this.state;
        const { data } = this.props;
        const svg = this.getSvg();
        const { width, height } = svg;

        return detectOrientation([width || 0, height || 0], options as TPrintOptions, data.offset);
    };

    handleOptionsChange = (changedFields) => {
        forEach(changedFields, ({ name, value }) =>
            this.setState(
                ({ options }) => {
                    const [formFieldName] = name;

                    if (formFieldName === 'zoomScale') {
                        if (value < MIN_SCALE) {
                            value = MIN_SCALE;
                        }
                        if (value > MAX_SCALE) {
                            value = MAX_SCALE;
                        }
                    }

                    if (formFieldName === 'zoomFitAcross' || formFieldName === 'zoomFitDown') {
                        if (value < MIN_PAGES) {
                            value = MIN_PAGES;
                        }
                        if (value > MAX_PAGES) {
                            value = MAX_PAGES;
                        }
                    }

                    return {
                        options: {
                            ...options,
                            [name]: value,
                        },
                    };
                },
                () => this.setCanvas(),
            ),
        );
    };

    handlePreviewToggle = () => {
        if (this.state.preview) {
            this.setState({ preview: false });
        } else {
            const { type } = this.props;

            if (type === 'svg') {
                this.setCanvas(() =>
                    this.setState({
                        preview: true,
                    }),
                );
            }
        }
    };

    handlePrint = (imageData: string) => () => {
        const { type, offset } = this.props;
        const { showOffsets, zoomMode } = this.state.options;

        if (type === 'svg') {
            this.setCanvas(() => {
                const html = getPrintHtml(
                    this.state.canvas!,
                    this.state.options,
                    zoomMode === ZoomMode.SCALE ? offset : DEFAULT_OFFSET,
                    imageData,
                    showOffsets,
                );

                print({
                    printable: html,
                    type: 'raw-html',
                });
            });
        }
    };

    render() {
        const { intl, visible, onClose, offset } = this.props;
        const { showOffsets, zoomMode } = this.state.options;

        const { options } = this.state;
        const form = (
            <PrintForm
                options={{ ...options, orientation: options.orientation || this.getOrientation() }}
                onFieldsChange={this.handleOptionsChange}
            />
        );
        const imageData = `data:image/svg+xml;base64,${Base64.encode(this.getSvg().svgString)}`;

        const footer = (
            <div className={theme.footer}>
                <Button data-test="window-print-model_cancel_btn" key="cancel" size="large" onClick={onClose}>
                    {intl.formatMessage(messages.cancel)}
                </Button>
                <Button
                    data-test="window-print-model_preview_btn"
                    key="preview"
                    size="large"
                    onClick={this.handlePreviewToggle}
                >
                    {intl.formatMessage(messages.preview)}
                </Button>
                <Button
                    data-test="window-print-model_print_btn"
                    key="ok"
                    size="large"
                    type="primary"
                    onClick={this.handlePrint(imageData)}
                >
                    {intl.formatMessage(messages.print)}
                </Button>
            </div>
        );

        return (
            <div>
                {this.state.preview && this.state.canvas && (
                    <PrintPreview
                        imageData={imageData}
                        canvas={this.state.canvas}
                        form={form}
                        options={this.state.options}
                        onClose={this.handlePreviewToggle}
                        onSubmit={this.handlePrint(imageData)}
                        offset={zoomMode === ZoomMode.SCALE ? offset : DEFAULT_OFFSET}
                        showOffsets={showOffsets && zoomMode === ZoomMode.SCALE}
                    />
                )}
                <Dialog
                    className={theme.dialog}
                    footer={footer}
                    onCancel={onClose}
                    title={intl.formatMessage(messages.title)}
                    open={visible}
                    width="350px"
                >
                    {form}
                </Dialog>
            </div>
        );
    }
}

const PrintDialogWithIntl = injectIntl(PrintDialog);

export { PrintDialogWithIntl as PrintDialog };
