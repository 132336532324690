import { Table } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUsers, fetchAllUsersRequest, openUserDataEditingTab } from '../../../../../actions/users.actions';
import { UserDataEdititngTabMode } from '../../../../../actions/users.actions.types';
import { MetaDataSelectors } from '../../../../../selectors/admintools.selectors';
import { UserDTO } from '../../../../../serverapi/api';
import { ContentLoadingPageTab } from '../../../../Workspace/components/ContentLoadingPageTab/ContentLoadingPageTab.component';
import EditButton from '../../../Button/EditButton';
import DeleteButton from '../../../Button/DeleteButton';
import messages from '../../messages/userManagment.messages';
import { UsersSelectors } from '../../../../../selectors/users.selectors';
import theme from './UserList.scss';
import { sortByAlpha } from '../../../../../utils/sortByAlpha';
import { SUPERADMIN_LOGIN } from '../../../data/admintool';

type TUserList = {
    status: string;
    searchInput: string;
    checkedUsers: UserDTO[];
    setCheckedUsers: (users: UserDTO[]) => void;
};

const UserList: FC<TUserList> = (props) => {
    const { status, searchInput, checkedUsers, setCheckedUsers } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const serverId = useSelector(MetaDataSelectors.getCurrentServerId);
    const userList: UserDTO[] | undefined = useSelector(UsersSelectors.getAll(serverId));
    const loading = useSelector(UsersSelectors.fetchAllUsersLoading());
    const [firstMount, setFirstMount] = useState(true);
    useEffect(() => {
        dispatch(fetchAllUsersRequest({ serverId }));
        setFirstMount(false);
    }, []);

    if (loading || firstMount) {
        return <ContentLoadingPageTab />;
    }

    const renderRowBtns = (text, userData: UserDTO) => {
        return (
            <div className={theme.buttonsBox}>
                <EditButton
                    onClick={() => dispatch(openUserDataEditingTab({ userData, mode: UserDataEdititngTabMode.EDIT }))}
                />
                {userData.login !== SUPERADMIN_LOGIN && (
                    <DeleteButton
                        onDelete={() => {
                            setCheckedUsers([]);
                            dispatch(deleteUsers([userData.id]));
                        }}
                        deleteQuestion={intl.formatMessage(messages.deleteUserQuestion, {login: userData.login})}
                    />
                )}
            </div>
        );
    };

    const columns = [
        {
            title: intl.formatMessage(messages.userId),
            dataIndex: 'id',
            key: 'id',
            width: 50,
            sorter: (a: UserDTO, b: UserDTO) => a.id - b.id,
        },
        {
            title: intl.formatMessage(messages.userLogin),
            dataIndex: 'login',
            key: 'login',
            width: 120,
            sorter: (a: UserDTO, b: UserDTO) => sortByAlpha(a.login, b.login),
        },
        {
            title: intl.formatMessage(messages.lastName),
            dataIndex: 'lastName',
            key: 'lastName',
            width: 120,
            sorter: (a: UserDTO, b: UserDTO) => sortByAlpha(a.lastName, b.lastName),
        },
        {
            title: intl.formatMessage(messages.firstName),
            dataIndex: 'firstName',
            key: 'firstName',
            width: 120,
            sorter: (a: UserDTO, b: UserDTO) => sortByAlpha(a.firstName, b.firstName),
        },
        {
            title: intl.formatMessage(messages.middleName),
            dataIndex: 'middleName',
            key: 'middleName',
            width: 120,
            sorter: (a: UserDTO, b: UserDTO) => sortByAlpha(a.middleName, b.middleName),
        },
        {
            title: intl.formatMessage(messages.blockedColumn),
            dataIndex: 'blocked',
            key: 'blocked',
            width: 100,
            render: (blocked: boolean) =>
                blocked ? intl.formatMessage(messages.blocked) : intl.formatMessage(messages.notBlocked),
            sorter: (a: UserDTO, b: UserDTO) => Number(a.blocked) - Number(b.blocked),
        },
        {
            title: '',
            dataIndex: 'manageBtn',
            key: 'manageBtn',
            width: 60,
            render: renderRowBtns,
        },
    ];

    const statusFilter = (srcData: UserDTO[]) => {
        switch (status) {
            case 'false':
                return srcData.filter((user) => !user.blocked);
            case 'true':
                return srcData.filter((user) => user.blocked);
            default:
                return srcData;
        }
    };

    const getDataSource = (): UserDTO[] => {
        const filteredData = statusFilter(userList);

        const dataSource: UserDTO[] =
            searchInput === ''
                ? filteredData
                : filteredData.filter(
                    (item) =>
                        [item.login, item.id, item.lastName, item.firstName, item.middleName].filter((s) => {
                            if (!s) return false;
                            const lowerCased = `${s}`.toLowerCase();

                            return lowerCased.includes(searchInput);
                        }).length,
                );

        return dataSource;
    };

    const onRowSelect = (row: UserDTO, selected: boolean): void => {
        if (selected) {
            setCheckedUsers([...checkedUsers, row]);
        } else {
            setCheckedUsers(checkedUsers.filter((user) => user.id !== row.id));
        }
    };

    const onRowSelectAll = (selected: boolean): void => {
        const dataSource = getDataSource();
        if (selected) {
            const newUsers = dataSource.filter(
                (user) => !checkedUsers.find((checkedUser) => checkedUser.id === user.id),
            );
            setCheckedUsers([...checkedUsers, ...newUsers]);
        } else {
            const clearedUsers = checkedUsers.filter(
                (checkedUser) => !dataSource.find((user) => user.id === checkedUser.id),
            );
            setCheckedUsers(clearedUsers);
        }
    };

    const rowSelection = {
        selectedRowKeys: checkedUsers.map((user) => user.id),
        onSelect: onRowSelect,
        onSelectAll: onRowSelectAll,
        getCheckboxProps: (record: UserDTO) => ({
            disabled: record.login === SUPERADMIN_LOGIN,
        }),
    };

    return (
        <Table
            rowKey="id"
            rowClassName={(record) => (record.blocked ? theme.tableRowGrey : '')}
            className={theme.dataTable}
            columns={columns}
            dataSource={getDataSource()}
            pagination={false}
            rowSelection={rowSelection}
            // коммент с пагинацией не убирать
            // pagination={{
            //     position: ['bottomRight'],
            //     hideOnSinglePage: true,
            //     defaultPageSize: 15,
            //     pageSizeOptions: ['15', '25', '50'],
            // }}
            scroll={{
                y: 'max-content',
            }}
        />
    );
};

export default UserList;
