import React, { useRef, useEffect } from 'react';
import { Symbol, SymbolSettings, SymbolSettingsTypeEnum} from '../../../../../../../../../serverapi/api';
import { MethodologiesGraph } from '../../../../../../../../../../src/mxgraph/MethodologiesGraph';
import { TMethodologyShape } from '../../../../../../../../../mxgraph/shapes/MethodologyShapes.types';
import { CustomRectangleFixedIconShapeTemplate } from '../../../../../../../../../mxgraph/shapes/CustomRectangleFixedIcon.stencil';
import { CustomCurvedRectangleShapeTemplate } from '../../../../../../../../../mxgraph/shapes/CustomCurvedRectangle.stencil';
import { CustomCircleShapeTemplate } from '../../../../../../../../../mxgraph/shapes/CustomCircle.stencil';
import { CustomHexagonShapeTemplate } from '../../../../../../../../../mxgraph/shapes/CustomHexagon.stencil';
import { CustomRhombusShapeTemplate } from '../../../../../../../../../mxgraph/shapes/CustomRhombus.stencil';
import { MxPoint } from '../../../../../../../../../mxgraph/mxgraph';

let graph: MethodologiesGraph | null = null;

type TSymbolStyles = {
    currentStyles: SymbolSettings;
    setGraphical: (shape: string) => void
    symbolType: SymbolSettingsTypeEnum;
}

export const PreviewShape = ({ ...args }: TSymbolStyles) => {
    const {currentStyles, symbolType } = args;
    let symbolTemplate: (params: TMethodologyShape | undefined) => string;

    switch(symbolType) {
        case 'RECTANGLE_FIXED_ICON':
            symbolTemplate = CustomRectangleFixedIconShapeTemplate;
            break;
        case 'RECTANGLE':
            symbolTemplate = CustomCurvedRectangleShapeTemplate;
            break;
        case 'CIRCLE':
            symbolTemplate = CustomCircleShapeTemplate;
            break;
        case 'HEXAGON':
            symbolTemplate = CustomHexagonShapeTemplate;
            break;
        case 'RHOMBUS':
            symbolTemplate = CustomRhombusShapeTemplate;
            break;
        default:
            break;
    }

    const container = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!container.current) {
            return;
        }
        graph = new MethodologiesGraph({container: container.current});
        graph.setConnectable(false);
        graph.setCellsMovable(false);
        graph.setCellsSelectable(false);
       
    }, []);

    useEffect(() => {
        if (!graph || !Object.keys(args).length) {
            return;
        }
        const shapeParams = {
            width: currentStyles?.symbolSettings?.width || 300,
            height: currentStyles?.symbolSettings?.height || 52,
            color: currentStyles?.symbolSettings?.objectColor || '#ffaa44',
            border: {
                borders: currentStyles?.borders,
            },
            image: {
                width: currentStyles?.iconSettings?.iconWidth || 0,
                height: currentStyles?.iconSettings?.iconHeight || 0,
                align: currentStyles?.iconSettings?.iconPosition || 'center',
                src: currentStyles?.iconSettings?.svgIcon?.graphical,
                scale: currentStyles?.iconSettings?.scale,
            },
            indent: {
                bottom: currentStyles?.iconSettings?.indentFromBottom || 0,
                top: currentStyles?.iconSettings?.indentFromTop || 0 ,
                left: currentStyles?.iconSettings?.indentFromLeft || 0,
                right: currentStyles?.iconSettings?.indentFromRight || 0,
            }
        } as TMethodologyShape;
        const symbol = {
            labelStyle: 'strokeColor=none;fillColor=none;',
            id: 'custom-curved-rectangle',
            graphical: symbolTemplate(shapeParams),
            width: currentStyles?.symbolSettings?.width || 160,
            height: currentStyles?.symbolSettings?.height || 52,
            showLabel: true,
       
        };
        graph.clear();
        graph.insertSymbol(symbol as Symbol, {x: 130 - (symbol.width / 2), y: 84 - (symbol.height / 2)} as MxPoint);
        args.setGraphical(symbol.graphical);
    }, [args]);

    return (
        <div>
            <div style={{ width: '100%', height: '168px' }} ref={container} />
        </div>
    );
};
