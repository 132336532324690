import type { AttributeValueNode, ObjectType } from '../../../../../serverapi/api';
import type { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import type { TUmlClassData } from './classSymbol.types';
import { keyBy } from 'lodash';
import {
    DROP_TARGET_ATTRIBUTE,
    FRAME_STYLES_INDICATOR,
    MULTILINE_OVERFLOW_ATTRIBUTE,
    SymbolTypeId,
} from '../../ComplexSymbol.constants';
import {
    UML_OBJECT_TYPE,
    UML_ATTR_CLASS,
    SYMBOL_VERTICAL_PADDING,
    SYMBOL_LINE_HEIGHT,
    textBlockStyles,
    UML_ID_SYMBOL,
} from '../UMLSymbols.constants';
import { getObjectSignature, getStereotypeString } from '../UMLSymbols.utils';
import {
    MIN_CLASS_SYMBOL_WIDTH,
    MAX_CLASS_SYMBOL_WIDTH,
    UmlClassSymbolChildren,
    UML_ATTR_ATTRIBUTE,
    UML_ATTR_METHOD,
    UML_ATTR_PARAMETER,
    UML_ATTR_RECEPTION,
    MIN_CLASS_SYMBOL_HEIGHT,
    CLASS_VERTICAL_OVERFLOW_SYMBOL,
} from './classSymbol.constants';
import { getTextBlockRenderedSize } from '@/utils/render.utils';

type TCellSize = { y: number; height: number; width: number };
type TUmlClassCellSizes = {
    [SymbolTypeId.UML_CLASS]: TCellSize & { style: string };
    [UmlClassSymbolChildren.ATTRIBUTES]: TCellSize;
    [UmlClassSymbolChildren.OPERATIONS]: TCellSize;
    [UmlClassSymbolChildren.RECEPTIONS]: TCellSize;
};

export const mapAttributeObject =
    (objectTypes?: ObjectType[]) =>
    ({ name, attributes }: ObjectDefinitionImpl): string => {
        const {
            [UML_ATTR_ATTRIBUTE.LINK]: linkAttribute,
            [UML_ATTR_ATTRIBUTE.ENUM]: enumAttribute,
            [UML_ATTR_ATTRIBUTE.STRING]: stringAttribute,
            [UML_ATTR_ATTRIBUTE.VISIBILITY]: visibilityAttribute,
        } = keyBy(attributes, 'typeId');
        const objectType =
            ((linkAttribute as AttributeValueNode | undefined)?.linkedNodeId && linkAttribute) ||
            (enumAttribute?.value && enumAttribute) ||
            (stringAttribute?.value && stringAttribute) ||
            null;
        const nodeAttributes = objectTypes?.find((el) => el.id === UML_OBJECT_TYPE.ATTRIBUTE)?.nodeAttributes;

        return getObjectSignature(name, { visibility: visibilityAttribute, type: objectType, nodeAttributes });
    };

export const mapParameterObject =
    (objectTypes?: ObjectType[]) =>
    ({ name, attributes }: ObjectDefinitionImpl): string => {
        const {
            [UML_ATTR_PARAMETER.LINK]: linkAttribute,
            [UML_ATTR_PARAMETER.ENUM]: enumAttribute,
            [UML_ATTR_PARAMETER.STRING]: stringAttribute,
            [UML_ATTR_PARAMETER.DEF_VALUE]: defaultValueAttribute,
        } = keyBy(attributes, 'typeId');
        const objectType =
            ((linkAttribute as AttributeValueNode | undefined)?.linkedNodeId && linkAttribute) ||
            (enumAttribute?.value && enumAttribute) ||
            (stringAttribute?.value && stringAttribute) ||
            null;
        const nodeAttributes = objectTypes?.find((el) => el.id === UML_OBJECT_TYPE.PARAMETER)?.nodeAttributes;

        return getObjectSignature(name, {
            type: objectType,
            nodeAttributes,
            showVisibility: false,
            defaultValue: defaultValueAttribute?.value,
        });
    };

export const mapOperationObject =
    (objectTypes?: ObjectType[]) =>
    ({ name, attributes, children }: ObjectDefinitionImpl): string => {
        const {
            [UML_ATTR_METHOD.LINK]: linkAttribute,
            [UML_ATTR_METHOD.ENUM]: enumAttribute,
            [UML_ATTR_METHOD.STRING]: stringAttribute,
            [UML_ATTR_METHOD.VISIBILITY]: visibilityAttribute,
        } = keyBy(attributes, 'typeId');
        const objectType =
            ((linkAttribute as AttributeValueNode | undefined)?.linkedNodeId && linkAttribute) ||
            (enumAttribute?.value && enumAttribute) ||
            (stringAttribute?.value && stringAttribute) ||
            null;
        const nodeAttributes = objectTypes?.find((el) => el.id === UML_OBJECT_TYPE.METHOD)?.nodeAttributes;

        const parameters = children?.map(mapParameterObject(objectTypes)).join(', ');

        return getObjectSignature(name, {
            visibility: visibilityAttribute,
            type: objectType,
            nodeAttributes,
            parameters,
        });
    };

export const mapReceptionObject =
    (objectTypes?: ObjectType[]) =>
    ({ name, attributes }: ObjectDefinitionImpl): string => {
        const {
            [UML_ATTR_RECEPTION.LINK]: linkAttribute,
            [UML_ATTR_RECEPTION.ENUM]: enumAttribute,
            [UML_ATTR_RECEPTION.STRING]: stringAttribute,
            [UML_ATTR_RECEPTION.VISIBILITY]: visibilityAttribute,
        } = keyBy(attributes, 'typeId');
        const objectType =
            ((linkAttribute as AttributeValueNode | undefined)?.linkedNodeId && linkAttribute) ||
            (enumAttribute?.value && enumAttribute) ||
            (stringAttribute?.value && stringAttribute) ||
            null;
        const nodeAttributes = objectTypes?.find((el) => el.id === UML_OBJECT_TYPE.RECEPTION)?.nodeAttributes;

        return getObjectSignature(name, { visibility: visibilityAttribute, type: objectType, nodeAttributes });
    };

export const isUmlClassAttribute = (typeId: string): boolean => {
    return [UML_ATTR_CLASS.PARAMETER_ONE, UML_ATTR_CLASS.PARAMETER_TWO].includes(typeId as UML_ATTR_CLASS);
};

export const isUmlAttributeTreeNode = (symbolId: string | undefined): boolean => {
    return [UML_ID_SYMBOL.METHOD, UML_ID_SYMBOL.ATTRIBUTE, UML_ID_SYMBOL.RECEPTION].includes(symbolId as UML_ID_SYMBOL);
};

export const getUmlClassCellValue = (
    objectAttributes: TUmlClassData,
    symbolTypeId: string,
    bound: { height: number; width: number },
): string => {
    if (!symbolTypeId) {
        return '';
    }

    const { name, stereotype, attributes = [], operations = [], receptions = [] } = objectAttributes;
    const { height } = bound || {};

    switch (symbolTypeId) {
        case SymbolTypeId.UML_CLASS: {
            const stereotypeStr = stereotype ? `${getStereotypeString(stereotype)}\n` : '';

            return `${stereotypeStr}${name}`;
        }
        case UmlClassSymbolChildren.ATTRIBUTES: {
            return maskVerticalOverflow(attributes, height).join('\n');
        }
        case UmlClassSymbolChildren.OPERATIONS: {
            return maskVerticalOverflow(operations, height).join('\n');
        }
        case UmlClassSymbolChildren.RECEPTIONS: {
            return maskVerticalOverflow(receptions, height).join('\n');
        }
        default: {
            return '';
        }
    }
};

export const getSymbolPartsHeight = (objectAttributes: TUmlClassData, height?: number): number[] => {
    const { stereotype, attributes = [], operations = [], receptions = [] } = objectAttributes;

    const headerHeight = 2 * SYMBOL_VERTICAL_PADDING + SYMBOL_LINE_HEIGHT + (stereotype ? SYMBOL_LINE_HEIGHT : 0);

    const attributesHeight = attributes.length
        ? 2 * SYMBOL_VERTICAL_PADDING + SYMBOL_LINE_HEIGHT * attributes.length
        : 0;
    const operationsHeight = operations.length
        ? 2 * SYMBOL_VERTICAL_PADDING + SYMBOL_LINE_HEIGHT * operations.length
        : 0;
    const receptionsHeight = receptions.length
        ? 2 * SYMBOL_VERTICAL_PADDING + SYMBOL_LINE_HEIGHT * receptions.length
        : 0;

    const listBodyEstimatedHeight = attributesHeight + operationsHeight + receptionsHeight;
    const listEstimatedHeight = headerHeight + listBodyEstimatedHeight;
    const listHeight = Math.max(listEstimatedHeight, MIN_CLASS_SYMBOL_HEIGHT);

    if (height) {
        const attributesBaseHeight = attributes.length ? 2 * SYMBOL_VERTICAL_PADDING + SYMBOL_LINE_HEIGHT : 0;
        const operationsBaseHeight = operations.length ? 2 * SYMBOL_VERTICAL_PADDING + SYMBOL_LINE_HEIGHT : 0;
        const receptionsBaseHeight = receptions.length ? 2 * SYMBOL_VERTICAL_PADDING + SYMBOL_LINE_HEIGHT : 0;
        const bodyBaseHeight = attributesBaseHeight + operationsBaseHeight + receptionsBaseHeight;

        const boundsHeight = Math.max(MIN_CLASS_SYMBOL_HEIGHT, height);
        const bodyHeight = boundsHeight - headerHeight;

        const heightDelta = bodyHeight - bodyBaseHeight;
        const deltaCount =
            (attributes.length ? attributes.length : 0) +
            (operations.length ? operations.length : 0) +
            (receptions.length ? receptions.length : 0);
        const deltaIndex = heightDelta / deltaCount;
        const attributesExtraHeight = deltaIndex * (attributes.length ? attributes.length : 0);
        const operationsExtraHeight = deltaIndex * (operations.length ? operations.length : 0);
        const receptionsExtraHeight = deltaIndex * (receptions.length ? receptions.length : 0);

        return [
            headerHeight,
            boundsHeight,
            attributesBaseHeight + attributesExtraHeight,
            operationsBaseHeight + operationsExtraHeight,
            receptionsBaseHeight + receptionsExtraHeight,
        ];
    }

    if (listEstimatedHeight < MIN_CLASS_SYMBOL_HEIGHT) {
        const heightDelta = MIN_CLASS_SYMBOL_HEIGHT - listEstimatedHeight;
        const deltaCount = (attributes.length || 0) + (operations.length || 0) + (receptions.length || 0);
        const deltaIndex = deltaCount ? heightDelta / deltaCount : 0;
        const attributesExtraHeight = deltaIndex * (attributes.length || 0);
        const operationsExtraHeight = deltaIndex * (operations.length || 0);
        const receptionsExtraHeight = deltaIndex * (receptions.length || 0);

        return [
            headerHeight,
            listHeight,
            attributesHeight + attributesExtraHeight,
            operationsHeight + operationsExtraHeight,
            receptionsHeight + receptionsExtraHeight,
        ];
    }

    return [headerHeight, listHeight, attributesHeight, operationsHeight, receptionsHeight];
};

const getSymbolPartsWidth = (objectAttributes: TUmlClassData, width?: number): number => {
    const { name, stereotype, attributes = [], operations = [], receptions = [] } = objectAttributes;

    if (width) return Math.min(Math.max(MIN_CLASS_SYMBOL_WIDTH, width), MAX_CLASS_SYMBOL_WIDTH);

    const string = [
        getStereotypeString(stereotype),
        name,
        attributes.join('<br/>'),
        operations.join('<br/>'),
        receptions.join('<br/>'),
    ].join('<br/>');
    const { width: listEstimatedWidth } = getTextBlockRenderedSize(string, textBlockStyles);

    return Math.min(Math.max(MIN_CLASS_SYMBOL_WIDTH, listEstimatedWidth), MAX_CLASS_SYMBOL_WIDTH);
};

export const getUmlClassCellSizes = (
    objectAttributes: TUmlClassData,
    bound?: { height?: number; width?: number },
): TUmlClassCellSizes => {
    const { height, width } = bound || {};
    const [headerHeight, listHeight, attributesHeight, operationsHeight, receptionsHeight] = getSymbolPartsHeight(
        objectAttributes,
        height,
    );
    const listWidth = getSymbolPartsWidth(objectAttributes, width);

    return {
        [SymbolTypeId.UML_CLASS]: {
            y: 0,
            height: listHeight,
            width: listWidth,
            style:
                `${FRAME_STYLES_INDICATOR};swimlane;fontStyle=0;${MULTILINE_OVERFLOW_ATTRIBUTE}=1;` +
                `childLayout=stackLayout;startSize=${headerHeight};horizontalStack=0;collapsible=0;html=1;` +
                `fillColor=default;rotatable=0;cloneable=0;expand=0;${DROP_TARGET_ATTRIBUTE}=0;editable=0;resizable=1;`,
        },
        [UmlClassSymbolChildren.ATTRIBUTES]: {
            y: headerHeight,
            height: attributesHeight,
            width: listWidth,
        },
        [UmlClassSymbolChildren.OPERATIONS]: {
            y: headerHeight + attributesHeight,
            height: operationsHeight,
            width: listWidth,
        },
        [UmlClassSymbolChildren.RECEPTIONS]: {
            y: headerHeight + attributesHeight + operationsHeight,
            height: receptionsHeight,
            width: listWidth,
        },
    };
};

export const maskVerticalOverflow = (items: string[], height: number): string[] => {
    const itemsLength = Math.min(Math.floor((height - 2 * SYMBOL_VERTICAL_PADDING) / SYMBOL_LINE_HEIGHT), items.length);
    const showAllItems = itemsLength === items.length;

    return showAllItems ? items : [...items.slice(0, itemsLength), CLASS_VERTICAL_OVERFLOW_SYMBOL];
};
