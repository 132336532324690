import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Checkbox, Form, FormInstance, Input, Typography } from 'antd';
import theme from '../SystemProperties.scss';
import messages from './KeycloakSettings.messages';
import { SystemKeycloakSettings } from '@/serverapi/api';
import { CheckStatus, KeycloakStatus, SystemPropertiesFormItemNames, formItemLayout } from '../SystemProperties.types';
import { isEmpty } from 'lodash';
import { validateKeycloakSettings } from '../SystemProperties.utils';
import { showNotification } from '@/actions/notification.actions';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { NotificationType } from '@/models/notificationType';
import { IGenericNotification } from '@/models/notification.types';
import { SystemPropertiesDaoService } from '@/services/dao/SystemPropertiesDaoService';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const { Text } = Typography;

type TKeycloakSettingsProps = {
    form: FormInstance<any> | null;
    isNeedValidateKeycloak: boolean;
    setIsNeedValidateKeycloak: (isNeedValidateKeycloak: boolean) => void;
};

export const KeycloakSettings = (props: TKeycloakSettingsProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { form, isNeedValidateKeycloak, setIsNeedValidateKeycloak } = props;
    const keyCloakFormRef = useRef<FormInstance>(null);
    const [reLoadForm, setReloadForm] = useState(false);
    const [checkStatus, setCheckStatus] = useState<CheckStatus | undefined>(undefined);
    const [keycloakServiceStatus, setKeycloakServiceStatus] = useState<string | undefined>(undefined);

    // изменения keycloakSettings записываются в основную форму в Form.Item name="keycloakSettings"
    // Form.Item name="keycloakSettings" создан внутри формы в SystemProperties.component (а не в текущем компоненте), т.к. KeycloakSettings находится вне формы в SystemProperties.component
    const keycloakSettings: SystemKeycloakSettings =
        form?.getFieldValue(SystemPropertiesFormItemNames.keycloakSettings) || {};
    const { baseUrl, realm, clientId, receiveGroupFromToken } = keycloakSettings;

    useEffect(() => {
        if (reLoadForm) setReloadForm(false);
    }, [reLoadForm]);

    useEffect(() => {
        if (isNeedValidateKeycloak) {
            keyCloakFormRef.current?.validateFields();
        }
    }, [isNeedValidateKeycloak]);

    const onCheckKeycloak = () => {
        const keycloakSettings: SystemKeycloakSettings | undefined = form?.getFieldValue(
            SystemPropertiesFormItemNames.keycloakSettings,
        );

        if (keycloakSettings) {
            validateKeycloakSettings(keycloakSettings)
                .then(async () => {
                    setCheckStatus(CheckStatus.validating);

                    try {
                        const result: string = await SystemPropertiesDaoService.checkKeycloak({
                            baseUrl: keycloakSettings.baseUrl,
                            realm: keycloakSettings.realm,
                            clientId: keycloakSettings.clientId,
                        });

                        if (result) {
                            setCheckStatus(CheckStatus.success);
                            setKeycloakServiceStatus(result);
                        } else {
                            setCheckStatus(CheckStatus.error);
                        }
                    } catch (error) {
                        setCheckStatus(CheckStatus.error);
                    }
                })
                .catch((error: Error) => {
                    dispatch(
                        showNotification({
                            id: uuid(),
                            type: NotificationType.CHECK_KEYCLOAK_SERVER_ERROR,
                            data: { message: error.message } as IGenericNotification,
                        }),
                    );
                });
        }
    };

    const onChange = (value: Partial<SystemKeycloakSettings>) => {
        const keycloakSettings: SystemKeycloakSettings = form?.getFieldValue(
            SystemPropertiesFormItemNames.keycloakSettings,
        );
        const newSettingsState = { ...keycloakSettings, ...value };
        form?.setFieldValue(SystemPropertiesFormItemNames.keycloakSettings, newSettingsState);
    };

    const onAddServer = () => {
        form?.setFieldValue(SystemPropertiesFormItemNames.keycloakSettings, {
            baseUrl: '',
            realm: '',
            clientId: '',
            receiveGroupFromToken: false,
        });
        setReloadForm(true);
        setIsNeedValidateKeycloak(false);
    };

    const onRemoveServer = () => {
        form?.setFieldValue(SystemPropertiesFormItemNames.keycloakSettings, undefined);
        setCheckStatus(undefined);
        setKeycloakServiceStatus(undefined);
        setReloadForm(true);
    };

    return (
        <div className={theme.formTab}>
            {!isEmpty(keycloakSettings) ? (
                <div>
                    <Form {...formItemLayout} scrollToFirstError ref={keyCloakFormRef}>
                        <Form.Item
                            required
                            className={theme.formItem}
                            label={intl.formatMessage(messages.serverAddress)}
                        >
                            <Form.Item
                                noStyle
                                initialValue={baseUrl}
                                name={SystemPropertiesFormItemNames.baseUrl}
                                rules={[
                                    { required: true, message: intl.formatMessage(messages.missingRequiredParameter) },
                                ]}
                            >
                                <Input
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        onChange({
                                            [SystemPropertiesFormItemNames.baseUrl]: event?.target?.value || '',
                                        })
                                    }
                                />
                            </Form.Item>
                            <span style={{ marginBottom: '3px' }}>
                                {checkStatus === CheckStatus.success &&
                                keycloakServiceStatus === KeycloakStatus.KEYCLOAK_IS_ACTIVE ? (
                                    <Text style={{ color: 'green' }} strong>
                                        {intl.formatMessage(messages.keycloakServerStatusOk)}
                                    </Text>
                                ) : null}
                                {checkStatus === CheckStatus.success &&
                                keycloakServiceStatus === KeycloakStatus.FAIL ? (
                                    <Text type="danger">{intl.formatMessage(messages.keycloakServerStatusNotOk)}</Text>
                                ) : null}
                                {checkStatus === CheckStatus.error && (
                                    <Text type="danger">{intl.formatMessage(messages.keycloakServerCheckError)}</Text>
                                )}
                            </span>
                        </Form.Item>

                        <Form.Item
                            required
                            className={theme.formItem}
                            label="Realm"
                            initialValue={realm}
                            name={SystemPropertiesFormItemNames.realm}
                            rules={[{ required: true, message: intl.formatMessage(messages.missingRequiredParameter) }]}
                        >
                            <Input
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    onChange({ [SystemPropertiesFormItemNames.realm]: event?.target?.value || '' })
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            required
                            className={theme.formItem}
                            label="Client ID"
                            initialValue={clientId}
                            name={SystemPropertiesFormItemNames.clientId}
                            rules={[{ required: true, message: intl.formatMessage(messages.missingRequiredParameter) }]}
                        >
                            <Input
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    onChange({ [SystemPropertiesFormItemNames.clientId]: event?.target?.value || '' })
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            className={theme.formItem}
                            label={intl.formatMessage(messages.receiveGroup)}
                            initialValue={receiveGroupFromToken}
                            name={SystemPropertiesFormItemNames.receiveGroupFromToken}
                            valuePropName="checked"
                        >
                            <Checkbox
                                onChange={(event: CheckboxChangeEvent) =>
                                    onChange({
                                        [SystemPropertiesFormItemNames.receiveGroupFromToken]: !!event?.target?.checked,
                                    })
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                xs: { span: 24, offset: 0 },
                                sm: { span: 16, offset: 8 },
                            }}
                        >
                            <Button onClick={onCheckKeycloak}>{intl.formatMessage(messages.checkServer)}</Button>
                            <span style={{ marginBottom: '3px' }}>
                                {checkStatus === CheckStatus.validating && (
                                    <Text type="secondary">
                                        {intl.formatMessage(messages.keycloakServerChecking)}...
                                    </Text>
                                )}
                            </span>
                            <Button danger onClick={onRemoveServer} className={theme.keycloakRemoveBtn}>
                                {intl.formatMessage(messages.removeServer)}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            ) : (
                <div className={theme.addLdapServerBtnContainer}>
                    <Form.Item className={theme.formItem} label=" " colon={false}>
                        <Button onClick={onAddServer}>{intl.formatMessage(messages.addServer)}</Button>
                    </Form.Item>
                </div>
            )}
        </div>
    );
};
