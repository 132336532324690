import * as React from 'react';
import { Dropdown } from 'antd';
import { DropdownProps } from 'antd/lib/dropdown/dropdown';
import type { MenuProps } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';

type TContextMenuProps = {
    menuId?: string;
    visible: boolean;
    overlay: MenuProps['items'] | null;
    children: React.ReactNode;
    onClick?: (event: MenuInfo) => void;
} & Omit<DropdownProps, 'overlay'>;

export const ContextMenu = (props: TContextMenuProps) => {
    const { overlay, children, visible, placement, overlayClassName, onClick } = props;
    if (overlay === null) {
        return <div>{children}</div>;
    }

    return (
        <div onContextMenu={(e) => e.preventDefault()}>
            <Dropdown
                placement={placement}
                trigger={['click']}
                menu={{ items: overlay, onClick }}
                open={visible}
                onOpenChange={props.onOpenChange}
                overlayClassName={overlayClassName}
                dropdownRender={(menu) => {
                    return (
                        <div className="tree-item_context-menu" data-test="tree-item_context-menu">{React.cloneElement(menu as React.ReactElement)}</div>
                    );
                }}
                destroyPopupOnHide
            >
                {children}
            </Dropdown>
        </div>
    );
};
