import React from 'react';
import messages from './FolderTypeTab.messages';
import { TabHeader } from '../Header/TabHeader.component';
import { useDispatch, useSelector } from 'react-redux';
import { FolderTypeSelectors } from '../../../../../../selectors/folderType.selectors';
import { Table } from 'antd';
import { useIntl } from 'react-intl';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { Locale } from '../../../../../Header/components/Header/header.types';
import icTreeFolder from '../../../../../../resources/ic-tree-folder.svg';
import { DeleteButton, EditButton, RowButtons } from '../../../../Button/RowButtons';
import { editFolderType, newFolderType } from '../../../../../../actions/methodologySetting.actions';
import {
    FolderType,
    InternationalString,
    PresetImage
} from '../../../../../../serverapi/api';
import { TreeNode } from '../../../../../../models/tree.types';
import { TPreset } from '../../../../../../models/preset.types';
import theme from './FolderType.scss';
import { deleteFolderType } from '../../../../../../actions/folderType.actions';
import { folderTypeEditorInit } from '../../../../../../actions/folderTypeEditor.actions';
import { LocalesService } from '../../../../../../services/LocalesService';
import { getIsDefaultFolder } from '../../../../../../services/bll/FolderTypeBLLService';
import {
    PresetImageSelectors
} from '../../../../../../selectors/presetSettings/presetImage.selectors';

type TFolderTypeTab = {
    serverNode: TreeNode;
    preset: TPreset;
};

export const FolderTypeTab = ({ serverNode, preset }: TFolderTypeTab): JSX.Element => {
    const presetId = preset.id;
    const { serverId } = serverNode.nodeId;
    const intl = useIntl();
    const presetImages: PresetImage[] = useSelector(PresetImageSelectors.listAllByPreset(serverId, presetId ));
    const folderTypes: FolderType[] = useSelector(FolderTypeSelectors.listByPresetId({ serverId, presetId }));
    const dispatch = useDispatch();
    const currentLocale: Locale = useSelector(getCurrentLocale);

    function iconRowRenderer(iconRow) {
        const icon = presetImages.find(icon => icon.id === iconRow)?.graphical || icTreeFolder.toString();

        return (
            <div className={theme.iconRow}>
                <img src={icon} alt="" />
            </div>
        );
    }

    function multilingualRowRenderer(multilingualRow: InternationalString) {
        return LocalesService.internationalStringToString(multilingualRow, currentLocale);
    }

    function rowButtonsRenderer(id: string, folderType: FolderType) {
        // Стандартную папку изменять нельзя
        const isDefaultRow = getIsDefaultFolder(id);

        return (
            <RowButtons
                className={theme.rowButtons}
                disabled={isDefaultRow}
                buttons={[
                    EditButton.build(() => {
                        dispatch(editFolderType({ serverNode, folderType, preset }));
                        dispatch(folderTypeEditorInit({ folderType }));
                    }, isDefaultRow),
                    DeleteButton.build(
                        () =>
                            dispatch(
                                deleteFolderType({
                                    serverId,
                                    presetId,
                                    folderTypeId: id,
                                }),
                            ),
                        isDefaultRow,
                        undefined,
                        undefined,
                        intl.formatMessage(messages.deleteFolderType, {
                            folderName: LocalesService.internationalStringToString(folderType.multilingualName)
                        }),
                        '',
                    ),
                ]}
            />
        );
    }

    const columns = [
        {
            title: intl.formatMessage(messages.icon),
            dataIndex: 'graphical',
            key: 'graphical',
            render: iconRowRenderer,
            width: 75,
        },
        {
            title: intl.formatMessage(messages.folderType),
            dataIndex: 'multilingualName',
            key: 'multilingualName',
            render: multilingualRowRenderer,
            className: theme.nameColumn
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: intl.formatMessage(messages.description),
            dataIndex: 'multilingualDescription',
            key: 'multilingualDescription',
            render: multilingualRowRenderer,
            className: theme.descriptionColumn
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'rowButtons',
            render: rowButtonsRenderer,
            width: 95,
        },
    ];

    return (
        <section className={theme.container}>
            <TabHeader
                buttons={[
                    {
                        name: messages.newFolderType,
                        size: 'large',
                        type: 'primary',
                        onAction: () => dispatch(newFolderType({ serverNode, preset })),
                        dataTestId: "new-folder-type_button",
                    },
                ]}
            />
            <Table
                rowKey={(record) => record.id}
                dataSource={folderTypes}
                className={theme.table}
                columns={columns}
                pagination={false}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
            />
        </section>
    );
};
