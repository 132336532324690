// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectLocalizedValues__editableCellValueWrap__C0EbL{padding:5px 12px;cursor:pointer}.SelectLocalizedValues__addValueBtn__CYcZj{background-color:#fafafa;margin-top:\"5px\";width:100%;margin-bottom:16;background-color:\"#fafafa\"}.SelectLocalizedValues__addValueBtn__CYcZj:hover{background-color:#d9d9d9 !important}.SelectLocalizedValues__deleteValueBtn__Vk9jd{width:25px}.SelectLocalizedValues__deleteValueBtn__Vk9jd svg{margin:0;width:100%}.SelectLocalizedValues__selectLocalizedTable__jvtbl th{padding:10px 20px !important}.SelectLocalizedValues__selectInput__FR2Sf{border:1px solid rgba(0,0,0,0)}.SelectLocalizedValues__ant-form-item-has-error___Lo_w .SelectLocalizedValues__selectInput__FR2Sf{border:1px solid #ff4d4f}.SelectLocalizedValues__margin0__zm04Z{margin:0px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/SelectLocalizedValues/SelectLocalizedValues.scss"],"names":[],"mappings":"AAAE,qDACE,gBAAA,CACA,cAAA,CAGJ,2CACE,wBAAA,CACA,gBAAA,CACA,UAAA,CACA,gBAAA,CACA,0BAAA,CACE,iDACE,mCAAA,CAIN,8CACE,UAAA,CACA,kDACE,QAAA,CACA,UAAA,CAKF,uDACE,4BAAA,CAIJ,2CACE,8BAAA,CAGF,kGACI,wBAAA,CAGJ,uCACE,UAAA","sourcesContent":["  .editableCellValueWrap {\r\n    padding: 5px 12px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n.addValueBtn {\r\n  background-color: #fafafa;\r\n  margin-top: '5px';\r\n  width: 100%;\r\n  margin-bottom: 16;\r\n  background-color: '#fafafa';\r\n    &:hover {\r\n      background-color: #d9d9d9 !important;\r\n    }\r\n}\r\n\r\n.deleteValueBtn {\r\n  width: 25px;\r\n  svg {\r\n    margin: 0;\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n.selectLocalizedTable {\r\n  th {\r\n    padding: 10px 20px !important;\r\n  }\r\n}\r\n\r\n.selectInput {\r\n  border: 1px solid transparent;\r\n}\r\n\r\n.ant-form-item-has-error .selectInput  {\r\n    border: 1px solid #ff4d4f;\r\n}\r\n\r\n.margin0 {\r\n  margin: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editableCellValueWrap": "SelectLocalizedValues__editableCellValueWrap__C0EbL",
	"addValueBtn": "SelectLocalizedValues__addValueBtn__CYcZj",
	"deleteValueBtn": "SelectLocalizedValues__deleteValueBtn__Vk9jd",
	"selectLocalizedTable": "SelectLocalizedValues__selectLocalizedTable__jvtbl",
	"selectInput": "SelectLocalizedValues__selectInput__FR2Sf",
	"ant-form-item-has-error": "SelectLocalizedValues__ant-form-item-has-error___Lo_w",
	"margin0": "SelectLocalizedValues__margin0__zm04Z"
};
export default ___CSS_LOADER_EXPORT___;
