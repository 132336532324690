import { put, takeEvery } from 'redux-saga/effects';
import { EventDescriptorMacros, NodeId, RootNodeId } from '../../serverapi/api';
import { GET_MODEL_EVENTS_REQUEST, SAVE_MODEL_EVENTS } from '../../actionsTypes/modelEvents.actionsTypes';
import { apiBundle } from '../../services/api/api-bundle';
import { getModelEventsSuccess } from '../../actions/modelEvents.actions';
import { TGetModelEventsRequestAction, TSaveModelEventsAction } from '../../actions/modelEvents.actions.types';
import { fetchNodes } from '../../actions/nodes.actions';

function* handleGetModelEventsRequest({ payload: { nodeId } }: TGetModelEventsRequestAction) {
    const { serverId, repositoryId, id } = nodeId;
    const events: EventDescriptorMacros[] = yield apiBundle(serverId).model.getModelEvents({
        repositoryId,
        modelId: id,
    });

    const nodeIds: NodeId[] = events.map((event) => ({
        id: event.scriptId,
        repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        serverId: nodeId.serverId,
    }));

    if (nodeIds.length) yield put(fetchNodes(nodeIds));

    yield put(getModelEventsSuccess(nodeId, events));
}

function* handleSaveModelEvents({ payload: { nodeId, body } }: TSaveModelEventsAction) {
    const { serverId, repositoryId, id } = nodeId;
    yield apiBundle(serverId).model.saveModelEvents({ repositoryId, modelId: id, body });
}

export function* modelEventsSaga() {
    yield takeEvery(SAVE_MODEL_EVENTS, handleSaveModelEvents);
    yield takeEvery(GET_MODEL_EVENTS_REQUEST, handleGetModelEventsRequest);
}
