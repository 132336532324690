import {
    SIMULATIONS_REQUEST_SUCCESS,
    SIMULATION_DELETE,
    SIMULATION_MODELING_ADD,
    SIMULATION_MODELING_ADD_REPORT,
    SIMULATION_SETTINGS_REQUEST_SUCCESS,
    SIMULATION_UPDATE,
} from '../../actionsTypes/simulationModeling.actionTypes';
import { TReducer } from '../../utils/types';
import { TNodeState } from './TNodeState';
import { TSimulationState } from './simulation.reducer.types';

const INITIAL_SIMULATION_RUN_STATE: TSimulationState = {
    simulationRuns: new TNodeState(),
    simulationSettings: new TNodeState(),
};

export const simulationReducer: TReducer<TSimulationState> = (
    state: TSimulationState = INITIAL_SIMULATION_RUN_STATE,
    action,
) => {
    switch (action.type) {
        case SIMULATIONS_REQUEST_SUCCESS: {
            const {
                payload: { simulationNodeId, simulationRuns },
            } = action;

            return {
                ...state,
                simulationRuns: state.simulationRuns.set(simulationNodeId, simulationRuns),
            };
        }
        case SIMULATION_MODELING_ADD: {
            const {
                payload: { simulation },
            } = action;
            if (simulation.simulationId) {
                const simulationRuns = [...(state.simulationRuns.get(simulation.simulationId) || []), simulation];

                return {
                    ...state,
                    simulationRuns: state.simulationRuns.set(simulation.simulationId, simulationRuns),
                };
            }

            return state;
        }
        case SIMULATION_UPDATE: {
            const {
                payload: { simulation },
            } = action;
            if (simulation.simulationId) {
                const simulationRuns = state.simulationRuns.get(simulation.simulationId);
                const updated = simulationRuns.map((sr) => (sr.id?.id === simulation.id?.id ? simulation : sr));

                return {
                    ...state,
                    simulationRuns: state.simulationRuns.set(simulation.simulationId, updated),
                };
            }

            return state;
        }
        case SIMULATION_DELETE: {
            const {
                payload: { simulationRun },
            } = action;
            if (simulationRun.simulationId) {
                const simulationRuns = state.simulationRuns.get(simulationRun.simulationId);
                const updated = simulationRuns.filter((sr) => sr.id?.id !== simulationRun.id?.id);

                return {
                    ...state,
                    simulationRuns: state.simulationRuns.set(simulationRun.simulationId, updated),
                };
            }

            return state;
        }
        case SIMULATION_SETTINGS_REQUEST_SUCCESS: {
            const {
                payload: { simulationNodeId, simulationSettings },
            } = action;

            return {
                ...state,
                simulationSettings: state.simulationSettings.set(simulationNodeId, simulationSettings),
            };
        }
        case SIMULATION_MODELING_ADD_REPORT: {
            const {
                payload: { simulation },
            } = action;

            return {
                ...state,
                simulationRuns: state.simulationRuns.set(simulation.simulationId, [simulation]),
            };
        }

        default:
            return state;
    }
};
