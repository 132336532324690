import { defineMessages } from 'react-intl';

export default defineMessages({
    dialogTitle: {
        id: 'dialogTitle',
        defaultMessage: 'Выберите файл для загрузки',
    },
    dialogUploadAreaMessage: {
        id: 'dialogUploadAreaMessage',
        defaultMessage: 'Нажмите или перетащите сюда файл',
    },
    uploadText: {
        id: 'FileUploadDialog.uploadText',
        defaultMessage: 'Нажмите или перетащите файл сюда',
    },
    cancelButton: {
        id: 'cancelButton',
        defaultMessage: 'Отмена',
    },
    okButton: {
        id: 'okButton',
        defaultMessage: 'Загрузить',
    },
    forFile: {
        id: 'forFile',
        defaultMessage: 'Для файла',
    },
    importVisio: {
        id: 'importVisio',
        defaultMessage: 'Импорт из Visio',
    },
    tab: {
        id: 'tab',
        defaultMessage: 'вкладка',
    },
    object: {
        id: 'object',
        defaultMessage: 'объект',
    },
    typeNotSelected: {
        id: 'typeNotSelected',
        defaultMessage: 'не выбран тип',
    },
    model: {
        id: 'model',
        defaultMessage: 'модели',
    },
    altLabel: {
        id: 'ImageDialog.altLabel',
        defaultMessage: 'Альтернативный текст',
    },

    titleLabel: {
        id: 'ImageDialog.titleLabel',
        defaultMessage: 'Текст подсказки',
    },

});
