import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import messages from '../../../../messages/CommonToolbar.messages';
import menuItemTheme from '../../../items/MenuItem.scss';
import { createCompactableSelect } from '../../../CompactableSelect/CompactableSelect.component';
import { CanvasSettings } from '../../../../../../selectors/canvas-settings.selectors';
import { TCompactableSelectItemType } from '../../../CompactableSelect/CompactableSelect.types';
import { GridTypeOnCanvas } from '../../GridTypeOnCanvasConstant';
import icGrid from '../../../../../../resources/icons/ic-ribbon-grid.svg';
import icGridPoint from '../../../../../../resources/icons/ic-grid-point.svg';
import icNoGrid from '../../../../../../resources/icons/ic-no-grid.svg';
import icGridA3Landscape from '../../../../../../resources/icons/ic-grid-a3-landscape.svg';
import icGridA4Landscape from '../../../../../../resources/icons/ic-grid-a4-landscape.svg';
import icGridA3Portrait from '../../../../../../resources/icons/ic-grid-a3-portrait.svg';
import icGridA4Portrait from '../../../../../../resources/icons/ic-grid-a4-portrait.svg';
import { toggleGrid } from '../../../../../../actions/canvas-settings.actions';

interface ISwitchGridLayoutProps {
    compact: boolean | undefined;
}

const CompactableSelect = createCompactableSelect();

export const SwitchGridLayout: FC<ISwitchGridLayoutProps> = (props) => {
    const { compact } = props;
    const intl = useIntl();
    const gridType = useSelector(CanvasSettings.gridType);
    const dispatch = useDispatch();

    const onToggleGrid = (gridType?: string) => {
        dispatch(toggleGrid({ gridType }));
    };

    const gridItems = [
        {
            type: TCompactableSelectItemType.Value,
            value: GridTypeOnCanvas.noGrid,
            label: intl.formatMessage(messages.noGrid),
            spriteSymbol: icNoGrid,
        },
        {
            type: TCompactableSelectItemType.Value,
            value: GridTypeOnCanvas.dot,
            label: intl.formatMessage(messages.dot),
            spriteSymbol: icGridPoint,
        },
        {
            type: TCompactableSelectItemType.Value,
            value: GridTypeOnCanvas['A4/PORTRAIT'],
            label: intl.formatMessage(messages['A4/PORTRAIT']),
            spriteSymbol: icGridA4Portrait,
        },
        {
            type: TCompactableSelectItemType.Value,
            value: GridTypeOnCanvas['A4/LANDSCAPE'],
            label: intl.formatMessage(messages['A4/LANDSCAPE']),
            spriteSymbol: icGridA4Landscape,
        },
        {
            type: TCompactableSelectItemType.Value,
            value: GridTypeOnCanvas['A3/PORTRAIT'],
            label: intl.formatMessage(messages['A3/PORTRAIT']),
            spriteSymbol: icGridA3Portrait,
        },
        {
            type: TCompactableSelectItemType.Value,
            value: GridTypeOnCanvas['A3/LANDSCAPE'],
            label: intl.formatMessage(messages['A3/LANDSCAPE']),
            spriteSymbol: icGridA3Landscape,
        },
    ];

    return (
        <span data-test="graph-general-toolbar_grid-on-button">
            <div className={cx(menuItemTheme.tooltipContainer, !compact && menuItemTheme.switchGridBtn)}>
                <CompactableSelect
                    className="filter-select"
                    title={gridType ? intl.formatMessage(messages[gridType]) : intl.formatMessage(messages.grid)}
                    compact={compact}
                    items={gridItems}
                    disabled={false}
                    value={GridTypeOnCanvas[gridType || 'noGrid']}
                    onSelect={onToggleGrid}
                    defaultSprite={icGrid}
                />
            </div>
        </span>
    );
};
