import {
    AttributeTypeApi,
    AttributetypegroupApi,
    AuditApi,
    AuthorizationApi,
    CommentApi,
    EdgeTypeApi,
    EdgetypegroupApi,
    ErrorLoggerApi,
    FileApi,
    FileTmpStorageApi,
    GroupsApi,
    IAttributeTypeApi,
    IAttributetypegroupApi,
    IAuditApi,
    IAuthorizationApi,
    ICommentApi,
    IEdgeTypeApi,
    IEdgetypegroupApi,
    IErrorLoggerApi,
    IFileApi,
    IFileTmpStorageApi,
    IGroupsApi,
    ILicenseApi,
    IMatrixApi,
    IModelApi,
    IModeltypeApi,
    IModeltypegroupApi,
    IObjectsApi,
    IObjectTypeApi,
    IObjecttypegroupApi,
    IPresetApi,
    IPrincipalsApi,
    IPrincipalSearchApi,
    IProfileApi,
    IServerprofileApi,
    IRepositoryApi,
    ISchedulerApi,
    IScriptApi,
    ISearchApi,
    ISecurityManagementApi,
    ISimulationApi,
    ISymbolEditorApi,
    ISymbolsApi,
    ISystemPropertyApi,
    ITreeApi,
    ITreeNodeTypeApi,
    IUsersApi,
    IVersionApi,
    IVisioApi,
    IWikiApi,
    ITransferBpmnApi,
    IArisApi,
    LicenseApi,
    MatrixApi,
    ModelApi,
    ModeltypeApi,
    ModeltypegroupApi,
    ObjectsApi,
    ObjectTypeApi,
    ObjecttypegroupApi,
    PresetApi,
    PrincipalsApi,
    PrincipalSearchApi,
    ProfileApi,
    ServerprofileApi,
    RepositoryApi,
    SchedulerApi,
    ScriptApi,
    SearchApi,
    SecurityManagementApi,
    SimulationApi,
    SymbolEditorApi,
    SymbolsApi,
    SystemPropertyApi,
    TransferApi,
    TreeApi,
    TreeNodeTypeApi,
    UsersApi,
    VersionApi,
    VisioApi,
    WikiApi,
    TransferBpmnApi,
    ArisApi,
    SpreadsheetApi,
    ISpreadsheetApi,
    FolderTypeApi,
    IFolderTypeApi,
    AgreementApi,
    IAgreementApi,
    IPresetImageApi,
    PresetImageApi,
    IPresetImageGroupApi,
    PresetImageGroupApi,
    KanbanApi,
    IKanbanApi,
    KanbanBoardTypeApi,
    IKanbanBoardTypeApi,
    KanbanCardTypeApi,
    IKanbanCardTypeApi,
    EventApi,
    IEventApi,
    EdgesApi,
    IEdgesApi,
    ITreeNotationApi,
    TreeNotationApi,
    IApprovalsApi,
    ApprovalsApi,
    IDashboardApi,
    DashboardApi,
    ISessionsApi,
    SessionsApi,
    FavoritesApi,
    IFavoritesApi,
    INotificationtemplateApi,
    NotificationtemplateApi,
    IMessagesApi,
    MessagesApi,
    INotificationApi,
    NotificationApi,
} from '../../serverapi/api';

export const TMP_DOWNLOAD_PATH = 'api/file-tmp-storage';

export class ApiBundle {
    public repository: IRepositoryApi = new RepositoryApi(fetch, this.url);
    public tree: ITreeApi = new TreeApi(fetch, this.url);
    public treeNodeType: ITreeNodeTypeApi = new TreeNodeTypeApi(fetch, this.url);
    public treeNotation: ITreeNotationApi = new TreeNotationApi(fetch, this.url);
    public model: IModelApi = new ModelApi(fetch, this.url);
    public dashboard: IDashboardApi = new DashboardApi(fetch, this.url);
    public modelType: IModeltypeApi = new ModeltypeApi(fetch, this.url);
    public modelTypeGroup: IModeltypegroupApi = new ModeltypegroupApi(fetch, this.url);
    public objectTypeGroup: IObjecttypegroupApi = new ObjecttypegroupApi(fetch, this.url);
    public edgeTypeGroup: IEdgetypegroupApi = new EdgetypegroupApi(fetch, this.url);
    public attributeTypeGroup: IAttributetypegroupApi = new AttributetypegroupApi(fetch, this.url);
    public objectType: IObjectTypeApi = new ObjectTypeApi(fetch, this.url);
    public objectsDefinition: IObjectsApi = new ObjectsApi(fetch, this.url);
    public attributeType: IAttributeTypeApi = new AttributeTypeApi(fetch, this.url);
    public transfer: TransferApi = new TransferApi(fetch, this.url);
    public transferBpmn: ITransferBpmnApi = new TransferBpmnApi(fetch, this.url);
    public securityManagement: ISecurityManagementApi = new SecurityManagementApi(fetch, this.url);
    public principalSearch: IPrincipalSearchApi = new PrincipalSearchApi(fetch, this.url);
    public script: IScriptApi = new ScriptApi(fetch, this.url);
    public wiki: IWikiApi = new WikiApi(fetch, this.url);
    public comment: ICommentApi = new CommentApi(fetch, this.url);
    public matrix: IMatrixApi = new MatrixApi(fetch, this.url);
    public symbol: ISymbolsApi = new SymbolsApi(fetch, this.url);
    public license: ILicenseApi = new LicenseApi(fetch, this.url);
    public user: IUsersApi = new UsersApi(fetch, this.url);
    public session: ISessionsApi = new SessionsApi(fetch, this.url);
    public group: IGroupsApi = new GroupsApi(fetch, this.url);
    public version: IVersionApi = new VersionApi(fetch, this.url);
    public principal: IPrincipalsApi = new PrincipalsApi(fetch, this.url);
    public preset: IPresetApi = new PresetApi(fetch, this.url);
    public symbolEditor: ISymbolEditorApi = new SymbolEditorApi(fetch, this.url);
    public errorLogger: IErrorLoggerApi = new ErrorLoggerApi(fetch, this.url);
    public systemProperty: ISystemPropertyApi = new SystemPropertyApi(fetch, this.url);
    public audit: IAuditApi = new AuditApi(fetch, this.url);
    public edgeType: IEdgeTypeApi = new EdgeTypeApi(fetch, this.url);
    public authorization: IAuthorizationApi = new AuthorizationApi(fetch, this.url);
    public search: ISearchApi = new SearchApi(fetch, this.url);
    public simulation: ISimulationApi = new SimulationApi(fetch, this.url);
    public visio: IVisioApi = new VisioApi(fetch, this.url);
    public file: IFileApi = new FileApi(fetch, this.url);
    public fileTmpStorage: IFileTmpStorageApi = new FileTmpStorageApi(fetch, this.url);
    public profile: IProfileApi = new ProfileApi(fetch, this.url);
    public scheduler: ISchedulerApi = new SchedulerApi(fetch, this.url);
    public serverProfile: IServerprofileApi = new ServerprofileApi(fetch, this.url);
    public aris: IArisApi = new ArisApi(fetch, this.url);
    public spreadsheet: ISpreadsheetApi = new SpreadsheetApi(fetch, this.url);
    public folderType: IFolderTypeApi = new FolderTypeApi(fetch, this.url);
    public agreements: IAgreementApi = new AgreementApi(fetch, this.url);
    public presetImage: IPresetImageApi = new PresetImageApi(fetch, this.url);
    public presetImageGroup: IPresetImageGroupApi = new PresetImageGroupApi(fetch, this.url);
    public kanban: IKanbanApi = new KanbanApi(fetch, this.url);
    public kanbanBoardType: IKanbanBoardTypeApi = new KanbanBoardTypeApi(fetch, this.url);
    public kanbanCardType: IKanbanCardTypeApi = new KanbanCardTypeApi(fetch, this.url);
    public event: IEventApi = new EventApi(fetch, this.url);
    public edges: IEdgesApi = new EdgesApi(fetch, this.url);
    public approval: IApprovalsApi = new ApprovalsApi(fetch, this.url);
    public favorites: IFavoritesApi = new FavoritesApi(fetch, this.url);
    public notificationTemplate: INotificationtemplateApi = new NotificationtemplateApi(fetch, this.url);
    public messages: IMessagesApi = new MessagesApi(fetch, this.url);
    public notifications: INotificationApi = new NotificationApi(fetch, this.url);

    constructor(private url: string) {}
}

const API_BUNDLE_MAP: {
    [id: string]: ApiBundle;
} = {};

export function apiBundle(serverId: string, serverUrl?: string): ApiBundle {
    if (!!API_BUNDLE_MAP[serverId] && !serverUrl) {
        return API_BUNDLE_MAP[serverId];
    }
    if (!serverUrl) {
        throw new Error('missed server url');
    }
    const serverApiBundle = new ApiBundle(serverUrl);
    API_BUNDLE_MAP[serverId] = serverApiBundle;

    return serverApiBundle;
}
