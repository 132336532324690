// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GeneratorImageSelector__highligh___1PDs{background-color:#41bcfb}.GeneratorImageSelector__highligh___1PDs:hover>td{background-color:var(--secondColorActive) !important}.GeneratorImageSelector__table__P9y05{cursor:pointer}.GeneratorImageSelector__table__P9y05 .ant-table-tbody>tr>td+td{text-align:center}.GeneratorImageSelector__table__P9y05 .ant-table-thead>tr>th{text-align:center}.GeneratorImageSelector__table__P9y05 .ant-table-thead>tr>th+th{text-align:center}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/Dialog/SymbolGenerator/GeneratorImageSelector.scss"],"names":[],"mappings":"AAAA,yCACI,wBAAA,CACA,kDACI,oDAAA,CAIR,sCACI,cAAA,CAEI,gEACI,iBAAA,CAGJ,6DACI,iBAAA,CAEJ,gEACI,iBAAA","sourcesContent":[".highligh {\r\n    background-color: #41bcfb;\r\n    &:hover > td {\r\n        background-color: var(--secondColorActive) !important;\r\n    }  \r\n}\r\n\r\n.table {\r\n    cursor: pointer;\r\n    :global {\r\n        .ant-table-tbody > tr > td + td {\r\n            text-align: center;\r\n        }\r\n\r\n        .ant-table-thead > tr > th {\r\n            text-align: center;\r\n        }\r\n        .ant-table-thead > tr > th + th {\r\n            text-align: center;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highligh": "GeneratorImageSelector__highligh___1PDs",
	"table": "GeneratorImageSelector__table__P9y05"
};
export default ___CSS_LOADER_EXPORT___;
