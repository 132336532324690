import { connect } from 'react-redux';
import { TRootState } from '../../../reducers/root.reducer.types';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import {
    FullModelDefinition,
    ModelType,
    NodeId,
    ObjectDefinitionNode,
    EdgeDefinitionNode,
    ModelVersion,
} from '../../../serverapi/api';
import { ModelHistoryDialog } from '../components/ModelHistory/ModelHistoryDialog.component';
import {
    TModelHistoryDialogProps,
    TModelHistoryDialogState,
} from '../components/ModelHistory/ModelHistoryDialog.types';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { TServerEntity } from '../../../models/entities.types';
import { restoreModelVersionAction } from '../../../actions/loadModel.actions';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { ModelTypeSelectors } from '../../../selectors/modelType.selectors';
import { setServerIdToNodeInterface } from '../../../utils/nodeId.utils';
import { getSymbolsFromModelType } from '../../../utils/symbol.utils';
import { objectDefinitionsAddOnlyNew } from '@/actions/entities/objectDefinition.actions';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { edgeDefinitionsAddOnlyNew } from '@/actions/entities/edgeDefinition.actions';

const mapStateToProps = (
    state: TRootState,
    props: TModelHistoryDialogProps,
): Partial<TModelHistoryDialogState & TModelHistoryDialogProps> => {
    const { serverId } = props.nodeId;
    const server: TServerEntity = ServerSelectors.serverById(state)[serverId];
    const presetId: string = TreeSelectors.presetById(props.nodeId)(state);

    return {
        locale: getCurrentLocale(state),
        serverUrl: ServerSelectors.server(serverId)(state).url,
        loadVersion: async (version: number): Promise<FullModelDefinition | undefined> => {
            const modelVersion: ModelVersion = await server.api.model.getModelVersion({
                repositoryId: props.nodeId.repositoryId,
                modelId: props.nodeId.id,
                version,
            });

            const modelType: ModelType | undefined = modelVersion.model.modelTypeId
                ? ModelTypeSelectors.byId(
                      { modelTypeId: modelVersion.model.modelTypeId, serverId: server.id },
                      presetId,
                  )(state)
                : undefined;
            const symbols = getSymbolsFromModelType(modelType);

            if (!modelType || !symbols) {
                return;
            }

            const objects = modelVersion.objects.map((obj: ObjectDefinitionNode) =>
                setServerIdToNodeInterface(obj, serverId),
            );
            const edges = modelVersion.edges.map((edge: EdgeDefinitionNode) =>
                setServerIdToNodeInterface(edge, serverId),
            );

            return {
                model: { ...modelVersion.model, nodeId: props.nodeId },
                modelType,
                symbols,
                objects,
                edges,
            } as FullModelDefinition;
        },
    };
};

const mapDispatchToProps = (dispatch) => ({
    onCancel() {
        dispatch(closeDialog(DialogType.MODEL_HISTORY));
    },
    onRestore(nodeId: NodeId, version: number) {
        dispatch(restoreModelVersionAction(nodeId, version));
        dispatch(closeDialog(DialogType.MODEL_HISTORY));
    },
    addNewObjectDefinitions(objects: ObjectDefinitionImpl[]) {
        dispatch(objectDefinitionsAddOnlyNew(objects));
    },
    addNewEdgeDefinitions(edgeDefinitions: EdgeDefinitionNode[]) {
        dispatch(edgeDefinitionsAddOnlyNew(edgeDefinitions));
    },
});

export const ModelHistoryDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ModelHistoryDialog);
