import { Checkbox, Tooltip } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import messages from '../../../../messages/CommonToolbar.messages';
import menuItemTheme from '../../../items/MenuItem.scss';
import { workspaceTabSetParams } from '../../../../../../actions/tabs.actions';
import { NodeId } from '../../../../../../serverapi/api';
import arrowLeft from '../../../../../../resources/icons/arrow-left.svg';
import arrowRight from '../../../../../../resources/icons/arrow-right.svg';

interface IFilterCheckboxProps {
    disabled: boolean | undefined;
    compact: boolean | undefined;
    isActiveToolbar: boolean | undefined;
    nodeFilterInput: boolean | undefined;
    nodeFilterOutput: boolean | undefined;
    tabNodeId: NodeId | undefined;
};

export const FilterCheckbox: FC<IFilterCheckboxProps> = (props) => {
    const { disabled, isActiveToolbar, nodeFilterInput, nodeFilterOutput, compact, tabNodeId } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const handleNodeFilterInputChange = (e: any) => {
        tabNodeId && dispatch(workspaceTabSetParams(tabNodeId, { nodeFilterInput: e?.target?.checked }));
    };
    const handleNodeFilterOutputChange = (e: any) => {
        tabNodeId && dispatch(workspaceTabSetParams(tabNodeId, { nodeFilterOutput: e?.target?.checked }));
    };

    return (
        <div className={menuItemTheme.groupRow}>
            <span>
                <Tooltip
                    placement="top"
                    title={
                        disabled
                            ? intl.formatMessage(messages.enableFiltering)
                            : intl.formatMessage(messages.nodeFilterInput)
                    }
                    mouseLeaveDelay={0}
                >
                    <div
                        data-test="main-panel_checkbox-inbox"
                        className={cx(
                            menuItemTheme.filterButtons,
                            compact && menuItemTheme.filterButtonsCompact,
                            (disabled || !isActiveToolbar) && menuItemTheme.checkboxDisabled,
                        )}
                    >
                        <Checkbox
                            className={compact ? menuItemTheme.filterCheckboxCompact : menuItemTheme.filterCheckbox}
                            disabled={disabled || !isActiveToolbar}
                            onChange={handleNodeFilterInputChange}
                            checked={nodeFilterInput}
                        >
                            <Icon spriteSymbol={arrowLeft} />
                            {!compact && <span className="title">{intl.formatMessage(messages.nodeFilterInput)}</span>}
                        </Checkbox>
                    </div>
                </Tooltip>
            </span>

            <span>
                <Tooltip
                    placement="top"
                    title={
                        disabled
                            ? intl.formatMessage(messages.enableFiltering)
                            : intl.formatMessage(messages.nodeFilterOutput)
                    }
                    mouseLeaveDelay={0}
                >
                    <div
                        data-test="main-panel_checkbox-outgoing"
                        className={cx(
                            menuItemTheme.filterButtons,
                            compact && menuItemTheme.filterButtonsCompact,
                            (disabled || !isActiveToolbar) && menuItemTheme.checkboxDisabled,
                        )}
                    >
                        <Checkbox
                            className={compact ? menuItemTheme.filterCheckboxCompact : menuItemTheme.filterCheckbox}
                            disabled={disabled || !isActiveToolbar}
                            onChange={handleNodeFilterOutputChange}
                            checked={nodeFilterOutput}
                        >
                            <Icon spriteSymbol={arrowRight} />
                            {!compact && <span className="title">{intl.formatMessage(messages.nodeFilterOutput)}</span>}
                        </Checkbox>
                    </div>
                </Tooltip>
            </span>
        </div>
    );
};
