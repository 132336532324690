/* eslint-disable prefer-destructuring */
import { DefaultGraph } from '../DefaultGraph';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolsManager.class';
import {
    MxEventObject,
    MxEvent,
    MxCellState,
    MxPoint,
    MxCell,
    MxGeometry,
    MxResources,
    MxConnectionConstraint,
    MxConstants,
} from '../mxgraph';
import { MxUtils } from '../util/MxUtils';
import { SequenceConnectionHandler } from './SequenceConnectionHandler';
import { SequenceEdgeTypesId } from './SequenceConstants';
import { SequenceEdgeHandler } from './SequenceEdgeHandler';
import { SequenceGraphHandler } from './SequenceGraphHandler';
import { SequenceGraphView } from './SequenceGraphView';
import { SequencePopupMenuHandler } from './SequencePopupMenuHandler';
import { SequenceSelectionCellsHandler } from './SequenceSelectionCellsHandler';
import { SequenceVertexHandler } from './SequenceVertexHandler';
import { UMLFrame } from '../ComplexSymbols/symbols/Sequence/UMLFrame.class';
import SequenceUtils from '../ComplexSymbols/symbols/Sequence/sequence.utils';
import LifeLineUtils from '../ComplexSymbols/symbols/LifeLine/LifeLine.utils';
import { LifelineSymbolMainClass } from '../ComplexSymbols/symbols/LifeLine/LifelineSymbolMain.class';
import './SequenceGeometry';

export class SequenceGraph extends DefaultGraph {
    connectionHandler: SequenceConnectionHandler;
    popupMenuHandler: SequencePopupMenuHandler;
    view: SequenceGraphView;
    graphHandler: SequenceGraphHandler;

    isCellsBendable() {
        return false;
    }

    createGraphView() {
        return new SequenceGraphView(this);
    }

    createEdgeHandler(state: MxCellState) {
        return new SequenceEdgeHandler(state);
    }

    createSelectionCellsHandler() {
        return new SequenceSelectionCellsHandler(this);
    }

    createGraphHandler() {
        return new SequenceGraphHandler(this);
    }

    createVertexHandler(state: MxCellState) {
        return new SequenceVertexHandler(state);
    }

    createConnectionHandler() {
        return new SequenceConnectionHandler(this);
    }

    createPopupMenuHandler(createPopupMenuFunct?: any): SequencePopupMenuHandler {
        // tslint:disable-line:no-any
        const factoryMethod = (menu: any, cell: MxCell, evt: PointerEvent) => {
            // tslint:disable-line:no-any
            if (createPopupMenuFunct) {
                createPopupMenuFunct(this, menu, cell, evt);
            }
        };

        this.popupMenuHandler = new SequencePopupMenuHandler(this, factoryMethod);

        return this.popupMenuHandler;
    }

    /**
     * Метод перемещения ячеек графа
     * @param { MxCell[] } cells - выделенные объекты на графе
     * @param { number } dx - смещение по оси x
     * @param { number } dy - смещение по оси y
     * @param { boolean } disconnect - обрывание связей, если они не входят в выделение
     * @param { boolean } constrain - перемещать дочерние элементы вместе с родительскими
     * @param { boolean } extend - расширять родителя при перемещении дочерних объектов
     */
    cellsMoved = (
        cells: MxCell[],
        dx: number,
        dy: number,
        disconnect: boolean,
        constrain: boolean,
        extend: boolean,
    ) => {
        if (cells != null && (dx !== 0 || dy !== 0)) {
            this.model.beginUpdate();
            try {
                if (disconnect) {
                    this.disconnectGraph(cells);
                }

                for (let i = 0; i < cells.length; i++) {
                    let newPoint = new MxPoint(dx, dy);
                    if (SequenceUtils.isSequenceExecutionSymbol(cells[i])) {
                        newPoint = this.calculateBoundsForExecutionSymbol(cells[i], newPoint);
                    }
                    this.translateCell(cells[i], newPoint.x, newPoint.y);

                    if (extend && this.isExtendParent(cells[i])) {
                        this.extendParent(cells[i]);
                    } else if (constrain) {
                        this.constrainChild(cells[i]);
                    }
                }

                if (this.resetEdgesOnMove) {
                    this.resetEdges(cells);
                }

                this.fireEvent(
                    new MxEventObject(
                        MxEvent.CELLS_MOVED,
                        'cells',
                        cells,
                        'dx',
                        dx,
                        'dy',
                        dy,
                        'disconnect',
                        disconnect,
                    ),
                );
            } finally {
                this.model.endUpdate();
            }
        }
    };

    /**
     * Функция расчета ограничений для перемещения символа исполнения
     * @param { MxCell } cell - текущий символ исполнения
     * @param { MxPoint } delta - текущая точка перемещения
     * @returns { MxPoint } newPoint - расширять родителя при перемещении дочерних объектов
     */
    calculateBoundsForExecutionSymbol(cell: MxCell, delta: MxPoint): MxPoint {
        const newPoint = delta.clone();
        newPoint.x = 0;
        const parent: MxCell = cell.getParent();
        if (parent && SequenceUtils.isSequenceDiagramCell(parent)) {
            const parentState = this.getView().getState(parent);
            const state = this.getView().getState(cell);
            const headerSize = (parent?.complexSymbolRef as LifelineSymbolMainClass)?.headerSize || 0;
            const minShiftY = -Math.max(cell.geometry.y - headerSize, 0);
            newPoint.y = Math.max(newPoint.y, minShiftY);
            const contains = MxUtils.contains(parentState, parentState.x, state.y + state.height + newPoint.y);
            if (!contains) {
                // увеличить высоту родителя если символ исполнения выходит за его границу
                this.setCellHeight(parentState);
            }
        }

        if (parent && SequenceUtils.isSequenceExecutionSymbol(parent)) {
            if (newPoint.y < 0) {
                newPoint.y = Math.max(-cell.geometry.y, newPoint.y);
            } else {
                newPoint.y = Math.min(parent.geometry.height - cell.geometry.y - cell.geometry.height, newPoint.y);
            }
        }

        return newPoint;
    }

    /**
     * Функция увеличения высоты объекта
     * @param { MxCellState } cellState - текущий объект
     * @param { number } dx - на сколько увеличить
     */
    setCellHeight(cellState: MxCellState, dx: number = 20) {
        if (!SequenceUtils.isSequenceDiagramCell(cellState.cell)) return;

        const s = this.view.scale;
        const { x, y } = cellState;
        const w = cellState.width;
        const h = cellState.height + dx * this.view.scale;
        this.model.beginUpdate();
        cellState.cell.geometry.setRect(x / s, y / s, w / s, h / s);
        this.view.updateCellState(cellState);
        if (cellState?.shape?.bounds) {
            cellState.shape.bounds.setRect(x, y, w, h);
            cellState.shape.redraw();
        }

        const parent = cellState.cell;
        const destroySymbol = LifeLineUtils.getDestroySymbol(parent);
        if (destroySymbol) {
            const destroySymbolState = this.getView().getState(destroySymbol);
            const { width, height } = destroySymbolState;
            const symbolX = x + (w - width) / 2;
            const symbolY = y + h - height / 2;
            destroySymbol.geometry.setRect((w - width) / 2 / s, (h - height / 2) / s, width / s, height / s);
            this.view.updateCellState(destroySymbolState);
            destroySymbolState.shape.bounds.setRect(symbolX, symbolY, width, height);
            destroySymbolState.shape.redraw();
        }
        this.model.endUpdate();
    }

    /**
     * Метод расширения родительского элемента
     * @param { MxCell } cell - дочерний элемент
     */
    extendParent(cell: MxCell) {
        if (cell != null) {
            const parent = this.model.getParent(cell);
            let p = this.getCellGeometry(parent);

            if (parent != null && p != null && !this.isCellCollapsed(parent)) {
                const geo = this.getCellGeometry(cell);

                if (geo != null && !geo.relative && (p.width < geo.x + geo.width || p.height < geo.y + geo.height)) {
                    p = p.clone();
                    const isSequenceDiagramCell = SequenceUtils.isSequenceDiagramCell(parent);
                    const isSequenceExecutionSymbol = SequenceUtils.isSequenceExecutionSymbol(parent);
                    // для сиквенс диаграммы не требуется расширять родителей
                    if (!isSequenceDiagramCell && !isSequenceExecutionSymbol) {
                        p.width = Math.max(p.width, geo.x + geo.width);
                    }
                    p.height = Math.max(p.height, geo.y + geo.height);

                    this.cellsResized([parent], [p], false);
                }
            }
            if (SequenceUtils.isSequenceExecutionSymbol(cell)) {
                const frameParent = ComplexSymbolManager.getComplexSymbolRootCell(cell);
                if (frameParent) {
                    frameParent?.edges?.forEach((edge: MxCell) => {
                        const edgeState = this.view.getState(edge);
                        this.view.updateCellState(edgeState);
                        this.cellRenderer.redraw(edgeState, true);
                    });
                }
            }
        }
    }

    /**
     * Метод перемещения символа уничтожения
     * @param { MxCell } cell - символ уничтожения
     * @param { MxGeometry } geo - текущее положение
     * @returns {MxGeometry | undefined} - новые координаты для символа уничтожения
     */
    moveDestroySymbolToBottom(cell: MxCell, geo: MxGeometry): MxGeometry | undefined {
        const destroySymbol = LifeLineUtils.getDestroySymbol(cell);
        if (destroySymbol) {
            const destroySymbolGeo = this.model.getGeometry(destroySymbol).clone();
            destroySymbolGeo.y = geo.height - destroySymbolGeo.height;
            this.cellResized(destroySymbol, destroySymbolGeo, false, false);

            return destroySymbolGeo;
        }

        return undefined;
    }

    /**
     * Измененение размеров ячейки
     * @param { MxCell } cell - текущая ячейка
     * @param { any } bounds - текущие границы ячейки
     * @param { boolean } ignoreRelative - игнорировать относительные координаты ячейки
     * @param { boolean } recurse - изменение размеров дочерних элементов
     * @returns {MxGeometry} - новые размеры ячейки
     */
    cellResized(cell: MxCell, bounds, ignoreRelative: boolean, recurse: boolean): MxGeometry {
        const prev = this.model.getGeometry(cell);
        if (
            prev != null &&
            (prev.x !== bounds.x || prev.y !== bounds.y || prev.width !== bounds.width || prev.height !== bounds.height)
        ) {
            const geo = prev.clone();

            if (!ignoreRelative && geo.relative) {
                const { offset } = geo;

                if (offset != null) {
                    offset.x += bounds.x - geo.x;
                    offset.y += bounds.y - geo.y;
                }
            } else {
                geo.x = bounds.x;
                geo.y = bounds.y;
            }

            geo.width = bounds.width;
            geo.height = bounds.height;

            if (!geo.relative && this.model.isVertex(cell) && !this.isAllowNegativeCoordinates()) {
                geo.x = Math.max(0, geo.x);
                geo.y = Math.max(0, geo.y);
            }

            this.model.beginUpdate();
            try {
                if (recurse) {
                    this.resizeChildCells(cell, geo);
                }
                const isSequenceExecutionSymbol = SequenceUtils.isSequenceExecutionSymbol(cell);
                const { parent } = cell;
                const parentIsSequenceExecutionSymbol = parent && SequenceUtils.isSequenceExecutionSymbol(parent);
                if (isSequenceExecutionSymbol && cell?.children?.length) {
                    cell.children.forEach((childCell: MxCell) => {
                        const childGeo = this.model.getGeometry(childCell).clone();
                        childGeo.height = Math.min(childGeo.height, geo.height);
                        childGeo.y = childGeo.y + childGeo.height > geo.height ? 0 : childGeo.y;
                        this.cellResized(childCell, childGeo, false, false);
                    });
                }

                const newDestroySymbolGeo = this.moveDestroySymbolToBottom(cell, geo);
                if (newDestroySymbolGeo) {
                    geo.height -= newDestroySymbolGeo.height / 2;
                }

                if (isSequenceExecutionSymbol && parentIsSequenceExecutionSymbol && bounds.y < 0) {
                    const newParentGeometry = parent.getGeometry().clone();
                    newParentGeometry.y += bounds.y;
                    this.cellResized(parent, newParentGeometry, false, false);
                }

                this.model.setGeometry(cell, geo);
                this.constrainChildCells(cell);
            } finally {
                this.model.endUpdate();
            }
        }

        return prev;
    }

    /**
     * Метод обработки ошибок при проведении связей
     * @param { any } edge - текущая связь
     * @param { any } source - источник связи
     * @param { any } target - таргет связи
     * @returns {string} - сообщение об ошибке
     */
    getEdgeValidationError(edge, source, target) {
        if (edge != null && !this.isAllowDanglingEdges() && (source == null || target == null)) {
            return '';
        }

        if (edge != null && this.model.getTerminal(edge, true) == null && this.model.getTerminal(edge, false) == null) {
            return null;
        }
        // Checks if we're dealing with a loop
        if (!this.allowLoops && source === target && source != null) {
            return '';
        }

        // Checks if the connection is generally allowed
        if (!this.isValidConnection(source, target)) {
            return '';
        }

        if (source != null && target != null) {
            let error = '';

            // Checks if the cells are already connected
            // and adds an error message if required
            if (!this.multigraph) {
                const tmp = this.model.getEdgesBetween(source, target, true);

                // Checks if the source and target are not connected by another edge
                if (tmp.length > 1 || (tmp.length === 1 && tmp[0] !== edge)) {
                    error += `${MxResources.get(this.alreadyConnectedResource) || this.alreadyConnectedResource}\n`;
                }
            }

            // Gets the number of outgoing edges from the source
            // and the number of incoming edges from the target
            // without counting the edge being currently changed.
            const sourceOut = this.model.getDirectedEdgeCount(source, true, edge);
            const targetIn = this.model.getDirectedEdgeCount(target, false, edge);

            // Checks the change against each multiplicity rule
            if (this.multiplicities != null) {
                for (let i = 0; i < this.multiplicities.length; i++) {
                    const err = this.multiplicities[i].check(this, edge, source, target, sourceOut, targetIn);

                    if (err != null) {
                        error += err;
                    }
                }
            }

            return error.length > 0 ? error : null;
        }

        return this.allowDanglingEdges ? null : '';
    }

    /**
     * Проверка валидности терминального объекта для связи
     * @param { MxCell } cell - текущий объект
     * @returns {boolean} - isValidSource
     */
    isValidSource(cell: MxCell) {
        return (
            (cell == null && this.allowDanglingEdges) ||
            (cell != null && (!this.model.isEdge(cell) || this.connectableEdges) && this.isCellConnectable(cell))
        );
    }

    /**
     * Проверка валидности связи ?
     */
    validateEdge() {
        return null;
    }

    /**
     * Метод получения текущего отступа для связи
     * @param { MxPoint } point - текущяя точка на графе
     * @param { MxCellState } terminalState - целевой объект
     * @param { MxCellState } edgeState - текущая связь
     * @param { boolean } source - является ли целевой объект источником свзяи
     * @param { boolean } [isReverce] - связь проведена в обратном направлении
     * @param { boolean } [isRecursive] - свзяь рекурсивная
     * @returns {MxConnectionConstraint | null} MxConnectionConstraint | null
     */
    getOutlineConstraint(
        point: MxPoint,
        terminalState: MxCellState,
        edgeState: MxCellState,
        source: boolean,
        isReverce?: boolean,
        isRecursive?: boolean,
    ) {
        if (terminalState) {
            const bounds = this.view.getPerimeterBounds(terminalState);
            const x = this.connectionHandler.calcXConstrain(edgeState, source, terminalState, isReverce, isRecursive); // bounds.width == 0 ? 0 : Math.round(((point.x - bounds.x) * 1000) / bounds.width) / 1000;
            const y = bounds.height === 0 ? 0 : Math.round(((point.y - bounds.y) * 1000) / bounds.height) / 1000;

            return new MxConnectionConstraint(new MxPoint(x, y), false);
        }

        return null;
    }

    /**
     * Задание отступов для связи
     * @param { MxCell } edge - текущяя связь
     * @param { MxConnectionConstraint } constrainSource - отступы для источника свзяи
     * @param { MxConnectionConstraint } constrainTarget - отступы для таргета свзяи
     */
    setConnectionConstraint(edge: MxCell, constrainSource?, constrainTarget?) {
        if (constrainSource?.point || constrainTarget?.point) {
            this.model.beginUpdate();
            try {
                if (constrainSource) {
                    this.setCellStyles(MxConstants.STYLE_EXIT_X, constrainSource.point.x, [edge]);
                    this.setCellStyles(MxConstants.STYLE_EXIT_Y, constrainSource.point.y, [edge]);
                    this.setCellStyles(MxConstants.STYLE_EXIT_PERIMETER, '0', [edge]);
                }
                if (constrainTarget) {
                    this.setCellStyles(MxConstants.STYLE_ENTRY_X, constrainTarget.point.x, [edge]);
                    this.setCellStyles(MxConstants.STYLE_ENTRY_Y, constrainTarget.point.y, [edge]);
                    this.setCellStyles(MxConstants.STYLE_ENTRY_PERIMETER, '0', [edge]);
                }
            } finally {
                this.model.endUpdate();
            }
        }
    }

    /**
     * Проверка является ли связь рекурсивной
     * @param { MxCell } cell - текущяя связь
     * @returns {boolean} Связь рекурсивная
     */
    isRecursiveEdge(cell: MxCell) {
        return cell?.value?.edgeTypeId === SequenceEdgeTypesId.RECURSIVE_MESSAGE;
    }

    /**
     * Проверка является ли связь наклонной
     * @param { MxCell } cell - текущяя связь
     * @returns {boolean} Связь наклонная
     */
    isDurationEdge(cell: MxCell) {
        return cell?.value?.edgeTypeId === SequenceEdgeTypesId.DURATION;
    }

    /**
     * Удаление объектов из графа
     * @param { MxCell[] } cells - объекты для удаления
     * @param { boolean } includeEdges - удалять связи объектов
     * @returns {MxCell[]} удаленные объекты
     */
    removeCells(cells: MxCell[], includeEdges?: boolean): MxCell[] {
        const cellsForRemove = cells.filter((cell) => !UMLFrame.isUMLFrameTitle(cell));

        cellsForRemove.forEach((cell) => {
            if (cell.isEdge() && !SequenceUtils.isForkEdge(cell)) {
                const relatedEdges = this.getAllRelatedEdges(cell);
                relatedEdges.forEach((edge) => {
                    if (!cellsForRemove.includes(edge)) cellsForRemove.push(edge);
                });
            }
        });

        return super.removeCells(cellsForRemove, includeEdges);
    }

    private getAllRelatedEdges(edge: MxCell) {
        const allRelatedEdges: MxCell[] = [];

        if (SequenceUtils.isForkEdge(edge)) {
            const mainEdgeId = SequenceUtils.getMainEdgeId(edge);
            const mainEdgeCell = this.model.getCell(mainEdgeId);
            const relatedEdges = mainEdgeCell.source.edges.filter((relatedEdge: MxCell) =>
                relatedEdge.style.includes(mainEdgeId),
            );
            allRelatedEdges.push(mainEdgeCell, ...relatedEdges);
        } else {
            const relatedEdges = edge.source.edges.filter((relatedEdge: MxCell) => relatedEdge.style.includes(edge.id));
            allRelatedEdges.push(edge, ...relatedEdges);
        }

        return allRelatedEdges;
    }

    getMaxDy(edgeState: MxCellState, isSource?: boolean) {
        if (!edgeState.cell.isEdge()) return 0;

        let mainEdgeCell = edgeState.cell;
        let mainEdgeState = edgeState;
        let isMainEdgeSelected = false;

        if (mainEdgeCell.isEdge() && SequenceUtils.isForkEdge(mainEdgeCell)) {
            const mainEdgeId = SequenceUtils.getMainEdgeId(mainEdgeCell);
            const selectedCells = this.graphHandler.cells;
            isMainEdgeSelected = selectedCells.some((selectedCell: MxCell) => selectedCell.value.id === mainEdgeId);
            if (isMainEdgeSelected) {
                mainEdgeCell = this.model.getCell(mainEdgeId);
                mainEdgeState = this.view.getState(mainEdgeCell);
            }
        }
        const terminalState: MxCellState =
            isSource && !SequenceUtils.isForkEdge(mainEdgeCell)
                ? mainEdgeState.visibleSourceState
                : mainEdgeState.visibleTargetState;

        let maxDy = terminalState.y - mainEdgeState.absolutePoints[1].y;

        if (mainEdgeCell.isEdge() && !SequenceUtils.isForkEdge(mainEdgeCell)) {
            const { source } = mainEdgeCell;
            const forkEdges = source.edges.filter(
                (edge) => mainEdgeCell.value.id === SequenceUtils.getMainEdgeId(edge),
            );
            forkEdges.forEach((forkEdge) => {
                const forkEdgeState = this.view.getState(forkEdge);
                const targetForkEdgeState = forkEdgeState.visibleTargetState;
                maxDy = Math.max(maxDy, targetForkEdgeState.y - forkEdgeState.absolutePoints[1].y);
            });
        }

        return maxDy;
    }

    /**
     * Метод перемещения ячейки графа
     * @param { MxCell } cell - текущая ячейка
     * @param { number } dx - смещение по оси x
     * @param { number } dy - смещение по оси y
     */
    translateCell(cell: MxCell, dx: number, dy: number) {
        let newDx = dx;
        let newDy = dy;
        const isForkEdge = SequenceUtils.isForkEdge(cell);
        let isMainEdgeSelected = false;

        if (cell.isEdge()) {
            const edgeState = this.view.getState(cell);

            const srcPoint: MxPoint = edgeState.absolutePoints[0];
            const selectedCells = this.graphHandler.cells;

            const mainEdgeId = SequenceUtils.getMainEdgeId(cell);
            isMainEdgeSelected = selectedCells.some((selectedCell: MxCell) => selectedCell.value.id === mainEdgeId);

            const srcState: MxCellState = edgeState.visibleSourceState;
            const trgState: MxCellState = edgeState.visibleTargetState;

            const isSrcSelected = selectedCells.some((selectedCell: MxCell) => selectedCell.id === srcState.cell.id);
            const isTrgSelected = selectedCells.some((selectedCell: MxCell) => selectedCell.id === trgState.cell.id);

            if (isSrcSelected) newDy = Math.max(newDy, -srcState.y);
            if (isTrgSelected) newDy = Math.max(newDy, -trgState.y);

            if (!isTrgSelected) {
                if (SequenceUtils.isUmlMessage(trgState.cell)) {
                    newDy = trgState.y + trgState.height / 2 - srcPoint.y;
                } else {
                    newDy = Math.max(newDy, this.getMaxDy(edgeState, true));
                }
            }

            if (!isSrcSelected) {
                if (SequenceUtils.isUmlMessage(srcState.cell)) {
                    newDy = 0;
                } else {
                    newDy = Math.max(newDy, this.getMaxDy(edgeState, true));
                }
            }
            if (!isTrgSelected) {
                if (SequenceUtils.isUmlMessage(trgState.cell)) {
                    newDy = 0;
                } else {
                    newDy = Math.max(newDy, this.getMaxDy(edgeState));
                }

                if (SequenceUtils.isUmlMessage(srcState.cell) && isSrcSelected) {
                    newDy = dy;
                }
            }

            newDx = 0;
        }

        let geo = this.model.getGeometry(cell);

        if (geo != null) {
            geo = geo.clone();
            const fixStartPoint = isForkEdge && !isMainEdgeSelected;
            geo.translate(newDx, newDy, fixStartPoint);

            if (!geo.relative && this.model.isVertex(cell) && !this.isAllowNegativeCoordinates()) {
                geo.x = Math.max(0, geo.x);
                geo.y = Math.max(0, geo.y);
            }

            if (geo.relative && !this.model.isEdge(cell)) {
                const parent = this.model.getParent(cell);
                let angle = 0;

                if (this.model.isVertex(parent)) {
                    const style = this.getCurrentCellStyle(parent);
                    angle = MxUtils.getValue(style, MxConstants.STYLE_ROTATION, 0);
                }

                if (angle !== 0) {
                    const rad = MxUtils.toRadians(-angle);
                    const cos = Math.cos(rad);
                    const sin = Math.sin(rad);
                    const pt = MxUtils.getRotatedPoint(new MxPoint(newDx, newDy), cos, sin, new MxPoint(0, 0));
                    newDx = pt.x;
                    newDy = pt.y;
                }

                if (geo.offset == null) {
                    geo.offset = new MxPoint(newDx, newDy);
                } else {
                    geo.offset.x = parseFloat(geo.offset.x) + newDx;
                    geo.offset.y = parseFloat(geo.offset.y) + newDy;
                }
            }
            this.model.setGeometry(cell, geo);
        }
    }

    /**
     * Вставка новой ячейки на граф
     * @param {MxCell}  parent - родительская ячейка
     * @param {string}  id - id
     * @param {any}  value - value
     * @param {MxCell}  x - x координата на графе
     * @param {MxCell}  y - y координата на графе
     * @param {MxCell}  width - ширина ячейки
     * @param {MxCell}  height -  высота ячейки
     * @param {string}  style - стиль ячейки
     * @param {boolean}  relative - координаты относительно родителя
     */
    insertVertex(
        parent: MxCell,
        id: string,
        value: any,
        x: number,
        y: number,
        width: number,
        height: number,
        style: string,
        relative?: boolean,
    ) {
        const vertex = this.createVertex(parent, id, value, x, y, width, height, style, relative);
        if (parent?.frame) vertex.frame = parent.frame;

        return this.addCell(vertex, parent);
    }

    getView(): SequenceGraphView {
        return this.view;
    }
}
