import { defineMessages } from 'react-intl';

export default defineMessages({
    commentsPanelTitle: {
        id: 'CommentsPanel.panelTitle',
        defaultMessage: 'Комментарии',
    },
    createCommentPopupTitle: {
        id: 'CommentsPanel.createCommentPopupTitle',
        defaultMessage: 'Создать комментарий',
    },
    at: {
        id: 'CommentsPanel.at',
        defaultMessage: 'в',
    },
    editCommentPopupTitle: {
        id: 'CommentsPanel.editCommentPopupTitle',
        defaultMessage: 'Редактировать комментарий',
    },
    editAnswerPopupTitle: {
        id: 'CommentsPanel.editAnswerPopupTitle',
        defaultMessage: 'Редактировать ответ',
    },
    cancelText: {
        id: 'CommentsPanel.cancelText',
        defaultMessage: 'Отмена',
    },
    sendText: {
        id: 'CommentsPanel.sendText',
        defaultMessage: 'Отправить',
    },
    noComments: {
        id: 'CommentsPanel.noComments',
        defaultMessage: 'Нет комментариев',
    },
    commentOpen: {
        id: 'CommentsPanel.commentOpen',
        defaultMessage: 'Показать только не решенные',
    },
    commentClose: {
        id: 'CommentsPanel.commentClose',
        defaultMessage: 'Показать только решенные',
    },
    commentsPanelFilterAll: {
        id: 'CommentsPanel.commentsPanelFilterAll',
        defaultMessage: 'Показать все',
    },
    commentsPanelFilterResolved: {
        id: 'CommentsPanel.commentsPanelFilterResolved',
        defaultMessage: 'Показать решенные',
    },
    commentsPanelFilterNotResolved: {
        id: 'CommentsPanel.commentsPanelFilterNotResolved',
        defaultMessage: 'Показать не решенные',
    },
    markers: {
        id: 'CommentsPanel.markers',
        defaultMessage: 'Метки',
    },
    commentAnswerPopupTitle: {
        id: 'CommentsPanel.commentAnswerPopupTitle',
        defaultMessage: 'Написать ответ на комментарий',
    },
    answerLink: {
        id: 'CommentsPanel.answerLink',
        defaultMessage: 'Ответить',
    },
    openText: {
        id: 'CommentsPanel.openText',
        defaultMessage: 'Раскрыть текст',
    },
    closeText: {
        id: 'CommentsPanel.closeText',
        defaultMessage: 'Свернуть',
    },
    edit: {
        id: 'CommentsPanel.edit',
        defaultMessage: 'Редактировать',
    },
    delete: {
        id: 'CommentsPanel.delete',
        defaultMessage: 'Удалить',
    },
    deleteCommentMarker: {
        id: 'CommentsPanel.deleteCommentMarker',
        defaultMessage: 'Удалить метку',
    },
    critical: {
        id: 'CommentsPanel.critical',
        defaultMessage: 'Критичный комментарий',
    },
    uncritical: {
        id: 'CommentsPanel.uncritical',
        defaultMessage: 'Некритичный комментарий',
    },
    required: {
        id: 'CommentsPanel.required',
        defaultMessage: 'Поле обязательно для заполнения',
    },
    addMarker: {
        id: 'CommentsPanel.addMarker',
        defaultMessage: 'Добавить на полотно',
    },
    showMarker: {
        id: 'CommentsPanel.showMarker',
        defaultMessage: 'Показать на полотне',
    },
    pinComment: {
        id: 'CommentsPanel.pinComment',
        defaultMessage: 'Закрепить',
    },
    unpinComment: {
        id: 'CommentsPanel.unpinComment',
        defaultMessage: 'Снять закрепление',
    },
    pinnedComments: {
        id: 'CommentsPanel.pinnedComments',
        defaultMessage: 'Закрепленные комментарии',
    },
    showMarkers: {
        id: 'CommentsPanel.showMarkers',
        defaultMessage: 'Показать комментарии',
    },
    showMarkersKey: {
        id: 'CommentsPanel.showMarkersKey',
        defaultMessage: 'Shift+C',
    },
    atFirstNew: {
        id: 'CommentsPanel.atFirstNew',
        defaultMessage: 'Сначала новые',
    },
    atFirstOld: {
        id: 'CommentsPanel.atFirstOld',
        defaultMessage: 'Сначала старые',
    },
    openFiles: {
        id: 'CommentsPanel.openFiles',
        defaultMessage: 'Смотреть все файлы',
    },
    closeFiles: {
        id: 'CommentsPanel.closeFiles',
        defaultMessage: 'Свернуть',
    },
    addCommentTitle: {
        id: 'CommentsPanel.addCommentTitle',
        defaultMessage: 'Введите свой комментарий...',
    },
    editComment: {
        id: 'CommentsPanel.editComment',
        defaultMessage: 'Редактировать',
    },
    deleteCommentFromCanvas: {
        id: 'CommentsPanel.deleteCommentFromCanvas',
        defaultMessage: 'Убрать с полотна',
    },
    filesAttached: {
        id: 'CommentsPanel.filesAttached',
        defaultMessage: 'файл(ов) прикреплено',
    },
    answerOrMention: {
        id: 'CommentsPanel.answerOrMention',
        defaultMessage: 'Ответить или упомянуть (@)',
    },
    fileMaxSize: {
        id: 'CommentsPanel.fileMaxSize',
        defaultMessage: 'превышает 50Mb',
    },
});
