/* eslint-disable prefer-destructuring */
import { MxCell, MxGeometry } from 'MxGraph';
import { SequenceSymbolTypeId, SymbolTypeId, DEFAULT_SYMBOL_TYPE_ID } from '../ComplexSymbol.constants';
import { Symbol } from '@/serverapi/api';
import { ComplexSymbolManager } from '../../ComplexSymbolsManager.class';
import { STYLE_MAIN_EDGE_ID } from '@/mxgraph/SequenceGraph/SequenceConstants';

export function isSequenceDiagramCell(cell: MxCell | null): boolean {
    const value = cell?.getValue();

    return !!value && !!cell?.complexSymbolTypeId?.includes('sequence.diagram.');
}

export function isSequenceExecutionSymbol(cell: MxCell): boolean {
    if (cell?.frame && !cell.complexSymbolTypeId) {
        const frameParent = ComplexSymbolManager.getComplexSymbolRootCell(cell);

        return !!frameParent && isSequenceDiagramCell(frameParent);
    }

    return false;
}

export function isSequenceActor(cell: MxCell) {
    if (cell.complexSymbolTypeId) {
        return cell.complexSymbolTypeId === SequenceSymbolTypeId.LIFE_LINE_ACTOR;
    }

    return false;
}

export function getSequenceSymbolType(symbol: Symbol): SymbolTypeId {
    if (typeof symbol?.symbolTypeId === 'string') {
        return symbol.symbolTypeId as SymbolTypeId;
    }

    return DEFAULT_SYMBOL_TYPE_ID as SymbolTypeId;
}

export function isUmlMessage(cell?: MxCell): boolean {
    return !!(cell?.complexSymbolTypeId && cell.complexSymbolTypeId.includes('uml-message-id'));
}

function isSequenceSymbolHeader(
    cell: MxCell,
    headerHeight: number | undefined,
    eventX: number,
    eventY: number,
): boolean {
    const geometry: MxGeometry = cell.getGeometry();
    const { x, y, width } = geometry;
    if (headerHeight && eventX >= x && eventX - x <= width && eventY >= y && eventY - y <= headerHeight) return true;

    return false;
}

function isStartEdgeRunning(cell: MxCell, headerHeight: number | undefined, eventX: number, eventY: number): boolean {
    const isSymbolHeader: boolean = isSequenceSymbolHeader(cell, headerHeight, eventX, eventY);
    const isSymbolBody: boolean = isSequenceDiagramCell(cell) || isSequenceExecutionSymbol(cell) || isUmlMessage(cell);

    return isSymbolBody && !isSymbolHeader;
}

const getMainEdgeId = (cell: MxCell) => {
    let mainEdgeId = '';
    if (cell.value?.style) {
        const styleParams = cell.value.style.split(';');
        const mainEdgeIdParam = styleParams.find((prop) => prop.startsWith(STYLE_MAIN_EDGE_ID)) || '';
        mainEdgeId = mainEdgeIdParam.split('=')[1];
    }

    return mainEdgeId;
};

const isForkEdge = (cell: MxCell) => {
    return !!getMainEdgeId(cell);
};

const SequenceUtils = {
    isSequenceExecutionSymbol,
    isSequenceDiagramCell,
    isSequenceActor,
    getSequenceSymbolType,
    isSequenceSymbolHeader,
    isStartEdgeRunning,
    isUmlMessage,
    getMainEdgeId,
    isForkEdge,
};

export default SequenceUtils;
