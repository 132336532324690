import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';
import { NodeId } from '../serverapi/api';

export namespace ModelEventsSelectors {
    export const modelEventsStateSelector = (state: TRootState) => state.modelEvents;

    export const loading = createSelector(modelEventsStateSelector, (state) => state.loading);

    export const byId = (nodeId: NodeId) => createSelector(modelEventsStateSelector, (state) => state.data.get(nodeId));

    export const scripts = createSelector(modelEventsStateSelector, (state) => state.scripts);
}
