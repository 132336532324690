import { GroupDTO } from '../../../serverapi/api';
import { TWorkspaceTab } from '../../../models/tab.types';
import { TWithWindowResizeProps } from '../../UIKit/H.O.C/withWindowResize/withWindowResize.types';

export type TGroupDataEditingTabProps = {
    groupData: GroupDTO;
    tab: TWorkspaceTab;
} & TWithWindowResizeProps;

export type TGroupDataEditingTabActionProps = {
    onClose: (tab: TWorkspaceTab) => void;
    onSubmit: (groupData: GroupDTO, isNewGroup: boolean) => void;
    fetchGroupFullData: (groupId: number) => void;
    onChangeMainData: (groupData: GroupDTO) => void;
};

export enum GroupMainDataFormItemNames {
    groupName = 'groupName',
    description = 'description',
    synonyms = 'synonyms',
}