// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PieChartSourceInput__inputContainer__w0exf{margin-bottom:16px}.PieChartSourceInput__label__b6DJJ{color:#363636;font-size:12px;font-family:Segoe UI;line-height:18px;margin-bottom:4px}.PieChartSourceInput__input__Ia8wp{border-radius:4px !important}", "",{"version":3,"sources":["webpack://./src/modules/Dashboard/WidgetSettingsPanel/PieChartSourceInput/PieChartSourceInput.scss"],"names":[],"mappings":"AAAA,4CACI,kBAAA,CAGJ,mCACI,aAAA,CACA,cAAA,CACA,oBAAA,CACA,gBAAA,CACA,iBAAA,CAGJ,mCACI,4BAAA","sourcesContent":[".inputContainer {\r\n    margin-bottom: 16px;\r\n}\r\n\r\n.label {\r\n    color: #363636;\r\n    font-size: 12px;\r\n    font-family: Segoe UI;\r\n    line-height: 18px;\r\n    margin-bottom: 4px;\r\n}\r\n\r\n.input {\r\n    border-radius: 4px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": "PieChartSourceInput__inputContainer__w0exf",
	"label": "PieChartSourceInput__label__b6DJJ",
	"input": "PieChartSourceInput__input__Ia8wp"
};
export default ___CSS_LOADER_EXPORT___;
