import React, { KeyboardEvent, ChangeEvent, useState, useRef, useEffect } from 'react';
import { Icon } from '../Icon/Icon.component';
import newTheme from './Select.scss';
import likeAnAntdTheme from './SelectLikeAnAntd.scss';
import ArrowDown from '../../../../resources/icons/UIKit/Arrow_Down.svg';
import { useFocus } from './useFocus';
import { Option } from './Option.component';
import { useClickOutside } from './useClickOutside';
import { TOptionProps } from './Select.types';
import { SearchInput } from './SearchInput.component';
import { ClearButton } from './ClearButton.component';
import messages from './Select.messages';
import { useIntl } from 'react-intl';
import { useFirstRender } from '@/utils/useFirstRender';

type TSelectChildren = React.ReactElement<TOptionProps, typeof Option>;

type TMultiSelectProps = {
    isMultiSelect: true;
    onChange?: (values?: string[]) => void;
};

type TSelectProps = {
    isMultiSelect?: false;
    onChange?: (value?: string) => void;
};

type TSelectFullProps = (TMultiSelectProps | TSelectProps) & {
    label?: JSX.Element | string;
    value?: JSX.Element | string;
    placeholder?: string;
    showSearch?: boolean;
    allowClear?: boolean;
    open?: boolean;
    errorTrigger?: boolean;
    required?: boolean;
    children: TSelectChildren[];
    dropdownClassName?: string;
    selectTextClassName?: string;
    // todo: после перехода на новые стили этот пропс будет не нужен
    originalTheme?: boolean;
    onSearch?: (value: string) => void;
    searchValue?: string;
    'data-test'?: string;
};

/**
 * когда перейдем на новые стили из figma нужно будет убрать из импорта SelectLikeAnAntd.scss
 * стили будут только из Select.scss
 */

/**
 * Кастомный селект с возможностью добавить строку поиска, кнопку "Очистить" и чекбоксы
 * есть возможность управления с клавиатуры, дропдаун можно стилизовать.
 * В качестве children передается массив Select.Option
 *
 * @param {JSX.Element | string | undefined} label текст над селектом
 * @param {JSX.Element | string | undefined} value значение внутри хедера селекта
 * @param {string | undefined} placeholder текст внутри селекта, если ничего не выбрано
 * @param {false | undefined} isMultiSelect в этом режиме onChange принимает массив + добавляются чекбоксы
 * @param {boolean | undefined} showSearch добавляет поиск
 * @param {boolean | undefined} allowClear добавляет кнопку удаления выбранных значений
 * @param {boolean | undefined} open начальное состояние дропдауна
 * @param {boolean | undefined} required обязательный выбор
 * @param {boolean | undefined} errorTrigger пропс необходимый для триггера проверки обязательного поля
 * @param {TSelectChildren[]} children массив Select.Option
 * @param {string | undefined} selectTextClassName возможность стилизовать текст внутри селекта
 * @param {string | undefined} dropdownClassName возможность стилизовать дропдаун
 * @param {((value?: string) => void) | undefined} onChange функция, срабатывает при выборе значений
 * @param {((value: string) => void) | undefined} onSearch функция, которой можно заменить дефолтный поиск
 * @param {string | undefined} searchValue значение поля поиска, которым можно заменить дефолтное
 * @param {string | undefined} `data-test` идентификатор для тестов
 */
export const Select = (props: TSelectFullProps) => {
    const {
        label,
        value,
        isMultiSelect,
        showSearch,
        allowClear,
        dropdownClassName,
        selectTextClassName,
        open,
        required,
        originalTheme,
        searchValue,
        placeholder,
        errorTrigger,
    } = props;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [isActive, setIsActive] = useState<boolean>(open || false);
    const [error, setError] = useState<boolean>(false);
    const [activeOption, setActiveOption] = useState<number | null>(null);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [selectButtonRef, setFocusOnSelectButton] = useFocus(null);
    const isFirstRender = useFirstRender();

    const containerRef = useRef(null);

    const intl = useIntl();

    useEffect(() => {
        const newSelectedOptions: string[] = [];
        props.children.forEach((child) => {
            if (child.props.checked) newSelectedOptions.push(child.props.value);
        });
        setSelectedOptions(newSelectedOptions);
    }, [props.children]);

    useEffect(() => {
        if (required && !value && !isActive && !isFirstRender) {
            setError(true);
        } else {
            setError(false);
        }
    }, [isActive, value, errorTrigger]);

    useClickOutside(containerRef, () => setIsActive(false));

    const handleClickHeader = () => setIsActive((prevState) => !prevState);

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setActiveOption(null);

        if (props.onSearch) {
            props.onSearch(e.target.value);
        } else {
            setSearchFilter(e.target.value.toLowerCase());
        }
    };

    const onChange = (val: string) => {
        if (!props.onChange) return;

        if (isMultiSelect) {
            const newSelectedOptions = selectedOptions.includes(val)
                ? selectedOptions.filter((option) => option !== val)
                : [...selectedOptions, val];

            props.onChange(newSelectedOptions);
            setSelectedOptions(newSelectedOptions);
        } else {
            props.onChange(val);
            setIsActive(false);
        }
    };

    const getSearchedOptions = (): TSelectChildren[] => {
        if (!searchFilter) return props.children;

        return props.children.filter((option) => option.props.label.toLowerCase().includes(searchFilter));
    };

    const onKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
        if (!isActive) return;

        if (e.code === 'Escape') {
            setIsActive(false);
            setActiveOption(null);
            setFocusOnSelectButton();
        }

        if (e.code === 'ArrowDown') {
            e.preventDefault();
            setActiveOption((prevState) => {
                if (prevState === null) return 0;
                if (prevState === props.children.length - 1) return prevState;

                return prevState + 1;
            });
        }

        if (e.code === 'ArrowUp') {
            setActiveOption((prevState) => {
                if (Number(prevState) > 0) return Number(prevState) - 1;

                return 0;
            });
        }

        if (activeOption !== null && isMultiSelect && e.code === 'Space') {
            e.preventDefault();
            onChange(getSearchedOptions()[activeOption].props.value);
        }

        if (activeOption !== null && !isMultiSelect && e.code === 'Enter') {
            e.preventDefault();
            onChange(getSearchedOptions()[activeOption].props.value);
        }
    };

    const theme = originalTheme ? newTheme : likeAnAntdTheme;
    const selectTextCN = selectTextClassName || theme.selectText;
    // const error = required && !value;

    return (
        <div
            data-test={`${props['data-test']}_container`}
            ref={containerRef}
            onKeyDown={onKeyPress}
            className={theme.selectContainer}
        >
            {label && <div className={`${theme.label} ${required ? theme.required : ''}`}>{label}</div>}
            <button
                ref={selectButtonRef}
                type="button"
                data-test={`${props['data-test']}_button`}
                className={`${theme.selectButton} ${error ? theme.requiredButton : ''}`}
                onClick={handleClickHeader}
            >
                <div className={`${selectTextCN} ${value ? '' : theme.placeholder}`}>
                    <span>{value || placeholder}</span>
                </div>
                <Icon className={isActive ? theme.arrowUp : undefined} spriteSymbol={ArrowDown} />
            </button>
            {error && <div className={theme.error}>{intl.formatMessage(messages.error)}</div>}
            {isActive && (
                <div
                    data-test={`${props['data-test']}_dropdown`}
                    className={`${theme.dropdownContainer} ${dropdownClassName}`}
                >
                    <div className={theme.dropdown}>
                        <SearchInput
                            showSearch={showSearch}
                            originalTheme={originalTheme}
                            onSearch={onSearch}
                            searchValue={searchValue !== undefined ? searchValue : searchFilter}
                        />
                        <ClearButton allowClear={allowClear} originalTheme={originalTheme} onChange={onChange} />
                        <div className={theme.optionsContainer} role="toolbar">
                            {getSearchedOptions().map((child: TSelectChildren, id: number): TSelectChildren => {
                                const optionWithSelectProps = {
                                    ...child,
                                    props: {
                                        ...child.props,
                                        showCheckbox: isMultiSelect,
                                        onChange,
                                        isActive: id === activeOption,
                                    },
                                };

                                return (
                                    <div data-test={`sort-by-type_${child.props.value}`} key={child.props.value}>
                                        {optionWithSelectProps}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

Select.Option = Option;
