import * as React from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../messages/FileUploadDialog.messages';
import { Button, Upload, Input } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Icon as UIKitIcon } from '../../UIKit/components/Icon/Icon.component';
import theme from './FileUploadDialog.scss';
import iconOk from '../../../resources/icons/Symbol_OK.svg';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { TWikiImage } from '../../../models/tab.types';
import FormItem from 'antd/lib/form/FormItem';
import { TFileUploadDialogProps } from '../types/FileUploadDialog.types';

const { Dragger } = Upload;

type TState = {
    file?: File | File[];
    serverId: string;
    repositoryId: string;
    alt: string | null | undefined;
    title: string | undefined;
};

class FileUploadDialog extends React.Component<TFileUploadDialogProps, TState> {
    state: TState = {
        serverId: this.props.serverId,
        repositoryId: '',
        alt: this.props.formInitData?.alt,
        title: this.props.formInitData?.title,
    };

    isSubmitEnabled = (): boolean => {
        return !!this.state.file;
    };

    handleSubmit = () => {
        const {id, isWiki, onSubmit} = this.props;
        const {serverId,repositoryId, alt, title, file} = this.state;
        if (isWiki) {
            onSubmit({
                src: `${repositoryId}/${id}`,
                alt,
                title,
            } as TWikiImage);
        } else if (file) { 
            this.props.onSubmit(file, serverId);
        }
    };

    dropFile = (e: UploadRequestOption) => {
        const { id, uploadFile, content } = this.props;
        if (this.props.isWiki && content) {
            this.setState({
                repositoryId: content?.nodeId.repositoryId,
            });
            uploadFile?.(e.file as File, { ...content.nodeId, id });
        }

        const { multiple = false } = this.props;

        if (multiple) {
            this.setState((state) => {
                if (!Array.isArray(state.file)) {
                    return {
                        file: [e.file as File],
                    };
                }

                return { file: [...state.file, e.file as File] };
            });
        } else if (e.file) {
            this.setState({
                file: e.file as File,
            });
        }

        const submitBtn = document.getElementById('file-upload-submit-button');
        if (submitBtn) submitBtn.focus();
    };

    beforeUpload = () => {
        this.setState(() => ({ file: undefined }));

        return true;
    };

    public render() {
        const { onClose, visible, intl, filters, multiple = false } = this.props;

        const altTextField = this.props.isWiki ? (
            <FormItem label={intl.formatMessage(messages.altLabel)} initialValue="formInitData.alt" name="alt">
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({
                            title: e.currentTarget.value,
                        })
                    }
                />
            </FormItem>
        ) : (
            ''
        );

        const titleField = this.props.isWiki ? (
            <FormItem
                data-test="dragger_file-downloaded"
                label={intl.formatMessage(messages.titleLabel)}
                initialValue={this.props.formInitData?.title}
                name="title"
            >
                <Input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        this.setState({
                            alt: e.currentTarget.value,
                        })
                    }
                />
            </FormItem>
        ) : (
            ''
        );

        return (
            <Dialog
                className={theme.dialog}
                // onOk={this.handleSubmit}
                onCancel={onClose}
                open={visible}
                title={intl.formatMessage(messages.dialogTitle)}
                footer={
                    <div className={theme.footer}>
                        <Button data-test="dragger_cancel-button" key="cancel" size="large" onClick={onClose}>
                            <FormattedMessage {...messages.cancelButton} />
                        </Button>
                        <Button
                            data-test="dragger_submit-button"
                            id="file-upload-submit-button"
                            key="ok"
                            size="large"
                            type="primary"
                            disabled={!this.isSubmitEnabled()}
                            onClick={this.handleSubmit}
                        >
                            <FormattedMessage {...messages.okButton} />
                        </Button>
                    </div>
                }
            >
                <p className={theme.dialogUploadAreaMessage}>{intl.formatMessage(messages.dialogUploadAreaMessage)}</p>

                <Dragger
                    name="file"
                    multiple={multiple}
                    showUploadList={false}
                    beforeUpload={this.beforeUpload}
                    customRequest={this.dropFile}
                    accept={filters || ''}
                >
                    {this.state.file ? (
                        <UIKitIcon className={theme.uploadSvg} spriteSymbol={iconOk} />
                    ) : (
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                    )}
                    {this.state.file && (
                        <p className="ant-upload-text">
                            <FormattedMessage {...messages.uploadText} />
                        </p>
                    )}
                </Dragger>
                <div className={theme.fieldsContainer}>
                    {altTextField}
                    {titleField}
                </div>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(FileUploadDialog);

export { IntlComponent as FileUploadDialog };
