import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import messages from '../../messages/ChoosePrincipalPanel.messages';
import { Alert, Table, Form } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import theme from './ChoosePrincipalsPanel.scss';
import { FormGroup } from '../../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import PrincipalLabel from '../PrincipalLabel/PrincipalLabel.component';
import SearchBar from '../SearchBar/SearchBar.component';
import { isEqual } from 'lodash';
import { sortByAlpha } from '../../../../utils/sortByAlpha';

type TChoosePrincipalsPanelProps = {
    loading: boolean;
    error: string;
    dataSource: Array<PrincipalDescriptor>;
    selectedRowKeys?: string[];
    // tslint:disable-next-line:no-any
    onSelectionChanged: (selectedRowKeys: string[], selectedRows: PrincipalDescriptor[]) => any;
};

type TChoosePrincipalsPanelAllProps = TChoosePrincipalsPanelProps & WrappedComponentProps & JSX.IntrinsicAttributes;

type TChoosePrincipalsPanelState = {
    filter: string;
    dataSource: Array<PrincipalDescriptor>;
    selectedRowKeys: string[];
};

const filterPrincipals = (filter: string) => (p: PrincipalDescriptor) =>
    !filter || ((p.login || '') + (p.name || '')).toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1;

class ChoosePrincipalsPanel extends React.Component<TChoosePrincipalsPanelAllProps, TChoosePrincipalsPanelState> {
    columns: Array<ColumnProps<PrincipalDescriptor>>;

    constructor(props: TChoosePrincipalsPanelAllProps) {
        super(props);

        this.columns = [
            {
                title: props.intl.formatMessage(messages.columnName),
                dataIndex: 'name',
                sorter: (a: PrincipalDescriptor, b: PrincipalDescriptor) => sortByAlpha(a.login, b.login),
                render: (value: string, record: PrincipalDescriptor) => {
                    return <PrincipalLabel {...record} />;
                },
            },
        ];
        const { selectedRowKeys = [], dataSource } = props;
        this.state = { dataSource, selectedRowKeys, filter: '' };
    }

    componentDidUpdate(prevProps: Readonly<TChoosePrincipalsPanelAllProps>) {
        if (!isEqual(this.props, prevProps)) {
            const { dataSource } = this.props;
            const { filter } = this.state;
            this.setState({ dataSource: dataSource.filter(filterPrincipals(filter)) });
        }
    }

    handleOnSearch(filter: string) {
        const { dataSource } = this.props;
        this.setState({ filter, dataSource: dataSource.filter(filterPrincipals(filter)) });
    }

    handleRowSelectionChange(selectedRowKeys: string[], selectedRows: PrincipalDescriptor[]) {
        const { onSelectionChanged } = this.props;
        this.setState({ selectedRowKeys });
        onSelectionChanged(selectedRowKeys, selectedRows);
    }

    render() {
        const { loading, intl } = this.props;
        const { filter, selectedRowKeys, dataSource } = this.state;

        const rowSelection: TableRowSelection<PrincipalDescriptor> = {
            type: 'checkbox',
            selectedRowKeys,
            onChange: (keys, rows) => this.handleRowSelectionChange(keys as string[], rows as PrincipalDescriptor[]),
        };

        return (
            <FormGroup className={theme.formGroup}>
                {this.props.error && <Alert type="error" message={this.props.error} showIcon />}

                <Form.Item>
                    <SearchBar
                        value={filter}
                        placeholder={intl.formatMessage(messages.searchPlaceholder)}
                        disabled={loading}
                        onSearch={(value) => this.handleOnSearch(value)}
                    />
                </Form.Item>
                <Form.Item data-test="permission-dialog_principal-table">
                    <Table
                        rowKey={(record: PrincipalDescriptor) => `${record.id}`}
                        dataSource={dataSource}
                        rowSelection={rowSelection}
                        columns={this.columns}
                        pagination={false}
                        locale={{ emptyText: intl.formatMessage(messages.emptyTable) }}
                        loading={loading}
                        size="small"
                        className={theme.table}
                        bordered
                        scroll={{ y: 380 }}
                    />
                </Form.Item>
            </FormGroup>
        );
    }
}

const withIntl = injectIntl(ChoosePrincipalsPanel);

export { withIntl as ChoosePrincipalsPanel };
