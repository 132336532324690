// @test-ignore_ru
import { Modal, Select } from 'antd';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './SymbolTypeSelectDialog.messages';
import symbolsTypesMessages from '../SymbolsTypes.messages';
import theme from './SymbolTypeSelectDialog.scss';
import { SMALL_DIALOG_WIDTH } from '../../../../../../../config/config';
import {
    DEFAULT_SYMBOL_TYPE_ID,
    SequenceSymbolTypeId,
    SymbolTypeId,
} from '../../../../../../../mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { ExperimentalFeatures } from '../../../../../../../models/ExperimentalFeatures';
import { KANBAN_CARD } from '../../util/KanbanCardEditor.utils';

const { Option } = Select;

type TDialogProps = {
    visible: boolean;
    onSubmit: (value: string) => void;
    onClose: () => void;
};

const symbolsTypesId = [
    SymbolTypeId.SIMPLE,
    SymbolTypeId.HORIZONTAL_SWIMLANE,
    SymbolTypeId.VERTICAL_SWIMLANE,
    SymbolTypeId.VR,
    SymbolTypeId.IDEF,
    SymbolTypeId.HORIZONTAL_POOL,
    SymbolTypeId.VERTICAL_POOL,
    SymbolTypeId.CROSS,
    SymbolTypeId.UML_CLASS,
];

const experimentalSymbolTypesId = [
    SequenceSymbolTypeId.LIFE_LINE,
    SequenceSymbolTypeId.LIFE_LINE_BOUNDERY,
    SequenceSymbolTypeId.LIFE_LINE_ENTITY,
    SequenceSymbolTypeId.LIFE_LINE_CONTROL,
    SequenceSymbolTypeId.LIFE_LINE_ACTOR,
    SymbolTypeId.UML_MESSAGE,
    KANBAN_CARD,
];

export const SymbolTypeSelectDialogComponent = (props: TDialogProps) => {
    const { onClose, onSubmit, visible } = props;
    const [value, setValue] = useState(DEFAULT_SYMBOL_TYPE_ID);
    const intl = useIntl();
    const handleChange = (symbolTypeId: string) => setValue(symbolTypeId);
    const onOk = () => onSubmit(value);

    const selectOptions = ExperimentalFeatures.isEnable()
        ? [...symbolsTypesId, ...experimentalSymbolTypesId]
        : symbolsTypesId;

    return (
        <div>
            <Modal
                open={visible}
                okText={<div data-test="new-symbol-type-window_save-btn">{intl.formatMessage(messages.save)}</div>}
                cancelText={intl.formatMessage(messages.cancel)}
                onOk={onOk}
                onCancel={onClose}
                title={intl.formatMessage(messages.selectSymbolType)}
                width={SMALL_DIALOG_WIDTH}
            >
                <Select
                    placeholder={intl.formatMessage(messages.placeholder)}
                    defaultValue={value}
                    onChange={handleChange}
                    className={theme.selectSymbolType}
                    data-test="new-symbol-type-window_type"
                >
                    {selectOptions.map((symbolsTypeId) => (
                        <Option
                            key={symbolsTypeId}
                            value={symbolsTypeId}
                            label={intl.formatMessage(symbolsTypesMessages[symbolsTypeId])}
                        >
                            {intl.formatMessage(symbolsTypesMessages[symbolsTypeId])}
                        </Option>
                    ))}
                </Select>
            </Modal>
        </div>
    );
};
