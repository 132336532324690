import { BPMMxPopupMenuHandler } from '../BPMGraphClasses';
import { isNullOrUndefined } from 'is-what';
import { MxMouseEvent, MxCell } from '../mxgraph';
import { SequenceGraph } from './SequenceGraph';

export class SequencePopupMenuHandler extends BPMMxPopupMenuHandler {
    getCellForPopupEvent(me: MxMouseEvent): MxCell | null {
        let result: MxCell | null = me.getCell();
        if (!isNullOrUndefined(result)) {
            const graph = this.graph as SequenceGraph;
            result = graph.getMainPartCellOrDefault(result);
        }

        return result;
    }
}
