import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolsManager.class';
import SequenceUtils from '../ComplexSymbols/symbols/Sequence/sequence.utils';
import { BPMMxCellHighlight } from '../handler/BPMMxCellHighlight';
import { MxCell, MxCellMarker, MxCellState, MxMouseEvent } from '../mxgraph';
import { SequenceGraph } from './SequenceGraph';
import { SequenceGraphView } from './SequenceGraphView';

/**
 * Вспомогательный класс для обработки местоположений мыши и выделения ячеек.
 */
export class SequenceCellMarker extends MxCellMarker {
    graph: SequenceGraph;
    highlight: BPMMxCellHighlight;

    /**
     * Возвращает текущую ячейку графа для выделения
     * @returns MxCellState
     */
    getValidState() {
        if (this.validState && SequenceUtils.isSequenceExecutionSymbol(this.validState.cell)) {
            const parentFrame = ComplexSymbolManager.getComplexSymbolRootCell(this.validState.cell);
            if (parentFrame) {
                const cellState = this.graph.view.getState(parentFrame);

                return cellState;
            }
        }

        return this.validState;
    }

    /**
     * Метод определяет какая ячейка может быть выбрана для выделения
     * @returns MxCellState
     */
    isValidState(state: MxCellState) {
        return (
            SequenceUtils.isSequenceExecutionSymbol(state.cell) ||
            SequenceUtils.isSequenceDiagramCell(state.cell) ||
            SequenceUtils.isUmlMessage(state.cell)
        );
    }

    getState(me: MxMouseEvent) {
        const view: SequenceGraphView = this.graph.getView();
        const cell: MxCell = this.getCell(me) || me.state?.cell;
        const state: MxCellState = this.getStateToMark(view.getState(cell));

        return state != null && this.intersects(state, me) ? state : null;
    }
}
