import {TReducer} from '../../utils/types';
import {
    CLEAR_PRESET_REQUEST
} from '../../actionsTypes/methodologySetting.actionTypes';
import {TPresetIconState} from "./presetIcon.reducer.types";
import {
    PRESET_IMAGE_DELETE_REQUEST_SUCCESS,
    PRESET_IMAGE_REQUEST_SUCCESS
} from "../../actionsTypes/presetSettings/presetImage.actionTypes";
import {PresetImage} from "../../serverapi/api";
import { APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM } from '../../actionsTypes/app.actionTypes';

export const INITIAL_PRESET_ICON_SERVER_STATE = {
    byPresetId: {},
};

const initial: TPresetIconState = {
    byServerId: {},
};

const initStateWithServerAndPreset = (state: TPresetIconState, serverId: string, presetId: string) => {
    if (!state.byServerId[serverId]) {
        state.byServerId[serverId] = {
            byPresetId: {},
        };
    }
    if (!state.byServerId[serverId].byPresetId[presetId]) {
        state.byServerId[serverId].byPresetId[presetId] = {
            byId: {},
        };
    }
};

export const presetIconReducer: TReducer<TPresetIconState> = (state = initial, action) => {
    switch (action.type) {


        case APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM: {
            const {
                payload: { definition },
            } = action;

            if (!definition?.images) {
                return state;
            }
            const byId: {[id: string]: PresetImage} = {};
            const newState = {byServerId: {'': {byPresetId: {'':{byId}}}}};
            definition.images.forEach(et => {
                byId[et.id] = et;
            });

            return { ...newState,  };
        }

        case PRESET_IMAGE_REQUEST_SUCCESS: {
            const {
                payload: { serverId, presetImages, presetId },
            } = action;
            const stateClone: TPresetIconState = JSON.parse(JSON.stringify(state));
            if (serverId && presetId) {
                initStateWithServerAndPreset(stateClone, serverId, presetId);
                const preset = stateClone.byServerId[serverId].byPresetId[presetId];
                preset.byId = {
                    ...preset.byId,
                    ...presetImages?.reduce((acc, presetImage: PresetImage) => {
                        acc[presetImage.id] = presetImage;

                        return acc;
                    }, {}) || [],
                };
            }

            return stateClone;
        }
        case PRESET_IMAGE_DELETE_REQUEST_SUCCESS: {
            const { presetImages, serverId } = action.payload;
            const stateClone = JSON.parse(JSON.stringify(state));
            presetImages?.forEach((icon) => {
                const byId =
                    (icon.presetId &&
                        stateClone.byServerId[serverId] &&
                        stateClone.byServerId[serverId].byPresetId[icon.presetId] &&
                        stateClone.byServerId[serverId].byPresetId[icon.presetId].byId) ||
                    {};
                icon.id && delete byId[icon.id];
            });

            return stateClone;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }
        default:
            return state;
    }
};
