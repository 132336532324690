import { SymbolType } from '../../models/Symbols';
import { MxCell, MxConstants, MxUtils } from '../../mxgraph/mxgraph';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolsManager.class';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { isGraySymbolStyle } from '../SymbolsService';
import { getStore } from '@/store';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { unwindCompositeCells } from '@/utils/bpm.mxgraph.utils';
import { LabelSymbol } from '@/models/bpm/bpm-model-impl';
import { ModelTypes } from '@/models/ModelTypes';

export class BpmMxEditorBLLService {
    static setLabelStatus(style: string, showLabel: boolean): string {
        let labelStyle = style;
        labelStyle = MxUtils.setStyle(labelStyle, MxConstants.STYLE_EDITABLE, Number(showLabel));
        labelStyle = MxUtils.setStyle(labelStyle, MxConstants.STYLE_NOLABEL, Number(!showLabel));

        return labelStyle;
    }
}

export function getEditableCells(cells: MxCell[]): MxCell[] {
    return cells.filter((cell) => cell.value?.type !== SymbolType.COMMENT);
}

export function hasOnlyComments(selectedCells: MxCell[] | undefined): boolean {
    if (!selectedCells) return false;

    return selectedCells.every((cell) => cell.value?.type === SymbolType.COMMENT);
}

/**
 *
 * Возвращает все ячейки, доступные для копирования
 *
 * @param graph
 * @param cells
 *
 */
export function getCopyableCells(graph: BPMMxGraph, cells: MxCell[]): MxCell[] {
    const isSequenceGraph = graph?.modelType?.id === ModelTypes.SEQUENCE_DIAGRAM;
    const isWhiteBoard = graph?.modelType?.id === ModelTypes.MIND_MAP;
    const mainAndLabelCells = unwindCompositeCells(graph, cells);

    // ищем основную ячейку по лейблу
    const getCellForLabel = (cell: MxCell): MxCell | undefined => {
        if (!(cell.value instanceof LabelSymbol)) return undefined;

        const { mainCellId }: LabelSymbol = cell.value;

        return mainAndLabelCells.find((cellItem: MxCell) => cellItem.id === mainCellId);
    };

    const cellsId: string[] = cells.map((cell) => cell.id);
    const state = getStore().getState();

    const isEdgeBinded = (cell: MxCell): boolean => {
        let isBind: boolean = true;

        if (cell?.value?.type === 'edge') {
            isBind =
                cell?.target?.id &&
                cell?.source?.id &&
                cellsId.includes(cell.target.id) &&
                cellsId.includes(cell.source.id);
        }

        return isBind;
    };

    const copyableCells: Record<string, MxCell> = {};

    cells.forEach((cell: MxCell) => {
        const cellValue = cell.getValue();
        const { objectDefinitionId, symbolId } = cellValue;
        const isSequenceSimpleSymbol = isSequenceGraph && graph.isPartOfCompositeCell(cell);
        const isDisabledCell =
            isGraySymbolStyle(cell.style) ||
            !UserProfileSelectors.isSymbolEditable({ ...graph.id, id: objectDefinitionId }, symbolId)(state);

        if (
            (typeof cellValue === 'string' && !isWhiteBoard) ||
            isSequenceSimpleSymbol ||
            isDisabledCell ||
            !isEdgeBinded(cell) ||
            cell?.value?.type === SymbolType.COMMENT
        ) {
            return;
        }

        const actualCell: MxCell | undefined =
            cell.value.type === 'object' || ComplexSymbolManager.getComplexSymbolInstance(cell)
                ? cell
                : getCellForLabel(cell);

        if (!actualCell) {
            copyableCells[cell.id] = cell;

            return;
        }

        const { objectDefinitionId: id } = actualCell.getValue();
        const isCellCopyable: boolean = !!ComplexSymbolManager.isCellCopyable(actualCell);

        if (!isCellCopyable || !id) {
            return;
        }

        copyableCells[actualCell.id] = actualCell;
    });

    return Object.values(copyableCells);
}

/**
 *
 * Метод обхода всех ячеек от родительских до дочерних
 * Погружение прерывается, если cb возвращает false
 *
 * @param cells
 *
 */
export function cellsTreeWalkerDownWithValidation(cells: MxCell[] = [], cb: (cell: MxCell) => boolean) {
    cells.forEach((cell) => {
        const result = cb && cb(cell);

        if (cell.children && result) {
            cellsTreeWalkerDownWithValidation(cell.children, cb);
        }
    });
}

/**
 *
 * Возвращает все ячейки вместе с дочерними
 *
 * @param cells
 *
 */
export function getCellsWithChildren(cells: MxCell[]) {
    const result: Record<string, MxCell> = {};

    cells.forEach((cell) => (result[cell.id] = cell));

    function grabHandler(cell: MxCell) {
        const copyableCell = ComplexSymbolManager.getCopyableCell(cell);

        if (!copyableCell) {
            return false;
        }

        result[copyableCell.id] = copyableCell;

        return true;
    }

    cellsTreeWalkerDownWithValidation(cells, grabHandler);

    return Object.values(result);
}
