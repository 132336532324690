import { Button, Form, Table, Tooltip } from 'antd';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormGroup } from '../../UIKit/components/Forms/components/FormGroup/FormGroup.component';
import { compareStringIds } from '../../../utils/nodeId.utils';
import messages from '../messages/ObjectPropertiesDialog.messages';
import theme from './ObjectPropertiesDialog.scss';
import { TreeItemType } from '../../Tree/models/tree';
import modelTypeMessages from '../../../models/modelType.messages';
import { EdgeDefinitionNode, ModelAssignment, NodeId, ObjectDefinitionNode } from '../../../serverapi/api';
import cx from 'classnames';
import iconAdd from '../../../resources/icons/ic-add.svg';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import icOpenModel from '../../../resources/icons/ic-open-model.svg';
import icDelete from '../../../resources/icons/ic-delete.svg';
import { useDispatch } from 'react-redux';
import {
    objectDecompositionDialogInit,
    objectDecompositionOpen,
} from '../../../actions/entities/objectDecomposition.actions';
import { TPayloadObjectDecompositionDialogInit } from '../../../actions/entities/objectDecomposition.actions.types';
import { sortByAlpha } from '../../../utils/sortByAlpha';

type TDecompositionTabProps = {
    objectNode?: ObjectDefinitionNode | EdgeDefinitionNode;
    isEntityEditable: boolean;
    isModelEditor: boolean;
    graphId: NodeId;
    toNodeId: (id: string) => NodeId;
    setStateAssignments: (modelAssignments: ModelAssignment[]) => void;
};

const DecompositionTab: FC<TDecompositionTabProps> = (props) => {
    const { objectNode, isEntityEditable, isModelEditor, graphId, toNodeId, setStateAssignments } = props;
    const intl = useIntl();
    const [selectedModelId, setSelectedModelId] = useState('');
    const assignments = objectNode?.modelAssignments || [];
    const dispatch = useDispatch();

    const tableData =
        assignments
            .map((modelAssignment: ModelAssignment, index: number) => {
                return {
                    key: index,
                    id: modelAssignment.modelId,
                    modelName: modelAssignment.modelName,
                    modelPath: modelAssignment.modelPath,
                    modelType:
                        modelAssignment.nodeType === TreeItemType.Matrix ||
                        modelAssignment.nodeType === TreeItemType.Wiki ||
                        modelAssignment.nodeType === TreeItemType.Spreadsheet
                            ? intl.formatMessage(modelTypeMessages[modelAssignment.nodeType])
                            : modelAssignment.modelTypeName,
                };
            })
            .sort((a, b) => sortByAlpha(a.modelName, b.modelName) || sortByAlpha(a.modelType, b.modelType)) || [];

    const assignmentColumns = [
        {
            title: intl.formatMessage(messages.modelName),
            dataIndex: 'modelName',
            key: 'modelName',
            width: '30%',
            sorter: (a: ModelAssignment, b: ModelAssignment) => sortByAlpha(a.modelName, b.modelName),
        },
        {
            title: intl.formatMessage(messages.modelType),
            dataIndex: 'modelType',
            key: 'modelType',
            width: '35%',
            sorter: (a: any, b: any) => sortByAlpha(a.modelType, b.modelType),
        },
        {
            title: intl.formatMessage(messages.modelPath),
            dataIndex: 'modelPath',
            key: 'modelPath',
            width: '35%',
        },
    ];

    const onDeleteDetailing = (modelId: string) => {
        const modelAssignments = assignments.filter((assignment) => assignment.modelId !== modelId);
        setStateAssignments(modelAssignments);
        setSelectedModelId('');
    };

    const handleDecompositionClickItem = (modelId: string) => {
        setSelectedModelId(compareStringIds(selectedModelId, modelId) ? '' : modelId);
    };

    const decompositionCreate = () => {
        const payload: TPayloadObjectDecompositionDialogInit = {
            graphId,
            setStateAssignments,
            stateAssignments: assignments,
        };
        if (objectNode?.type === 'OBJECT') {
            payload.objectDefinitionId = objectNode?.nodeId;
        }
        if (objectNode?.type === 'EDGE') {
            payload.edgeDefinitionId = objectNode?.nodeId;
        }
        dispatch(objectDecompositionDialogInit(payload));
    };

    const decompositionOpen = () => {
        const nodeType = assignments.find((assignment) => assignment.modelId === selectedModelId)
            ?.nodeType as TreeItemType;
        dispatch(objectDecompositionOpen({ nodeId: toNodeId(selectedModelId), type: nodeType }));
    };

    const decompositionCreateDisabled = !isEntityEditable || !isModelEditor;
    const decompositionDeleteDisabled = !selectedModelId || decompositionCreateDisabled;

    const getDecompositionCreateTooltip = () => {
        if (!isEntityEditable) {
            return intl.formatMessage(messages.noAccess);
        }
        if (!isModelEditor) {
            return intl.formatMessage(messages.needLicense);
        }

        return '';
    };

    const getDecompositionDeleteTooltip = () => {
        if (!isEntityEditable) {
            return intl.formatMessage(messages.noAccess);
        }
        if (!isModelEditor) {
            return !selectedModelId ? '' : intl.formatMessage(messages.needLicense);
        }

        return '';
    };

    return (
        <FormGroup className={theme.formGroup}>
            <Form.Item>
                <Table
                    dataSource={tableData}
                    className={theme.table}
                    rowClassName={(row: any) =>
                        cx(theme.attribute, {
                            [theme.attribute_selected as string]: compareStringIds(selectedModelId, row.id),
                        })
                    }
                    columns={assignmentColumns}
                    size="middle"
                    bordered
                    pagination={false}
                    onRow={(row) => ({
                        onClick: () => {
                            handleDecompositionClickItem(row.id);
                        },
                    })}
                    scroll={{
                        y: 'max-content',
                    }}
                />
                <div className={theme.detailingActions}>
                    <Tooltip title={getDecompositionCreateTooltip()} mouseLeaveDelay={0}>
                        <Button
                            disabled={decompositionCreateDisabled}
                            size="large"
                            type="primary"
                            className={theme.button}
                            onClick={decompositionCreate}
                        >
                            <div data-test="decomposition-settings-window_create-btn" className={theme.buttonInner}>
                                <Icon spriteSymbol={iconAdd} className={theme.buttonIcon} />
                                {intl.formatMessage(messages.create)}
                            </div>
                        </Button>
                    </Tooltip>
                    <div className={theme.detailingActionsInner}>
                        <Button
                            data-test="decomposition-settings-window_open-btn"
                            size="large"
                            disabled={!selectedModelId}
                            className={theme.button}
                            onClick={decompositionOpen}
                        >
                            <div className={theme.buttonInner}>
                                <Icon spriteSymbol={icOpenModel} className={theme.buttonIcon} />
                                {intl.formatMessage(messages.open)}
                            </div>
                        </Button>
                        <Tooltip title={getDecompositionDeleteTooltip()} mouseLeaveDelay={0}>
                            <Button
                                data-test="decomposition-settings-window_delete-btn"
                                size="large"
                                disabled={decompositionDeleteDisabled}
                                className={theme.buttonWithTooltipe}
                                onClick={() => onDeleteDetailing(selectedModelId)}
                            >
                                <div className={theme.buttonInner}>
                                    <Icon spriteSymbol={icDelete} className={theme.buttonIcon} />
                                    {intl.formatMessage(messages.delete)}
                                </div>
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </Form.Item>
        </FormGroup>
    );
};

export { DecompositionTab };
