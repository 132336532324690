import {
    MxConnectionHandler,
    MxCellState,
    MxPoint,
    MxConnectionConstraint,
    MxShape,
    MxConstants,
    MxMouseEvent,
    MxCell,
} from '../mxgraph';
import { BPMMxConstraintHandler } from './BPMMxConstraintHandler.class';
import { BPMMxGraph } from '../bpmgraph';
import { isUndefined } from 'is-what';
import isEqual from 'lodash/isEqual';

export class BPMMxConnectionHandler extends MxConnectionHandler {
    waypointsEnabled: boolean = true;
    enabled: boolean = true;
    waypoints: any; // tslint:disable-line:no-any
    constraintHandler: BPMMxConstraintHandler;
    shape: MxShape;
    sourceConstraint: MxConnectionConstraint | null;
    graph: BPMMxGraph;
    currentState: any; // tslint:disable-line:no-any
    previousPoint: MxPoint | undefined;

    constructor(graph: BPMMxGraph, factoryMethod?: any) {
        // tslint:disable-line:no-any
        super(graph, factoryMethod);
    }

    resetWaypoints() {
        this.waypoints = null;
    }

    init() {
        super.init();
        this.constraintHandler = new BPMMxConstraintHandler(this.graph);
    }

    isConnectableCell(cell?: MxCell | null) {
        return !!this.isConnecting();
    }

    setStartConnectionConstraint(point: MxPoint) {
        if (point !== null && this.previous !== null) {
            const constraints = this.graph.getAllConnectionConstraints(this.previous);
            let nearestConstraint: any = null;
            let dist: any = null;

            for (let i = 0; i < constraints.length; i++) {
                const cp = this.graph.getConnectionPoint(this.previous, constraints[i]);

                if (cp !== null) {
                    const tmp = (cp.x - point.x) * (cp.x - point.x) + (cp.y - point.y) * (cp.y - point.y);

                    if (dist === null || tmp < dist) {
                        nearestConstraint = constraints[i];
                        dist = tmp;
                    }
                }
            }

            if (nearestConstraint !== null) {
                this.sourceConstraint = nearestConstraint;
            }
            this.updateEdgeState(point, null);
        }
    }

    createEdgeState(me: any): MxCellState {
        // tslint:disable-line:no-any
        const style = this.graph.createCurrentEdgeStyle();
        const edge = this.graph.createEdge(null, null, null, null, null, style);
        const state = new MxCellState(this.graph.view, edge, this.graph.getCellStyle(edge));

        for (const key in this.graph.currentEdgeStyle) {
            if (this.graph.currentEdgeStyle.hasOwnProperty(key)) {
                state.style[key] = this.graph.currentEdgeStyle[key];
            }
        }

        return state;
    }

    removeLastWaypoint() {
        if (this.isConnecting() && this.waypointsEnabled) {
            if (this.waypoints) {
                this.waypoints.pop();
            }
        }
    }

    updateCurrentState(me: MxMouseEvent, point: MxPoint) {
        // tslint:disable-next-line
        this.constraintHandler.update(
            me,
            this.first === null,
            false,
            // tslint:disable-next-line
            this.first === null || me.isSource(this.marker.highlight.shape) ? null : point,
        );

        if (this.constraintHandler.currentFocus !== null && this.constraintHandler.currentConstraint !== null) {
            // Handles special case where grid is large and connection point is at actual point in which
            // case the outline is not followed as long as we're < gridSize / 2 away from that point
            if (
                this.marker.highlight !== null &&
                this.marker.highlight.state !== null &&
                this.marker.highlight.state.cell === this.constraintHandler.currentFocus.cell
            ) {
                // Direct repaint needed if cell already highlighted
                if (this.marker.highlight.shape.stroke !== 'transparent') {
                    this.marker.highlight.shape.stroke = 'transparent';
                    this.marker.highlight.repaint();
                }
            } else {
                this.marker.markCell(this.constraintHandler.currentFocus.cell, 'transparent');
            }

            // Updates validation state
            if (
                this.previous !== null &&
                !isUndefined(this.previous) &&
                !isUndefined(this.constraintHandler.currentFocus)
            ) {
                this.error = this.validateConnection(this.previous.cell, this.constraintHandler.currentFocus.cell);
                if (this.error === null) {
                    this.currentState = this.constraintHandler.currentFocus;
                } else {
                    this.constraintHandler.resetExceptFocusIcon();
                }
            }
        } else {
            if (this.graph.isIgnoreTerminalEvent(me.getEvent())) {
                this.marker.reset();
                this.currentState = null;
            } else {
                this.marker.process(me);
                this.currentState = this.marker.getValidState();
            }

            const outline = this.isOutlineConnectEvent(me);

            if (this.currentState !== null && outline) {
                // Handles special case where mouse is on outline away from actual end point
                // in which case the grid is ignored and mouse point is used instead
                if (me.isSource(this.marker.highlight.shape)) {
                    point = new MxPoint(me.getGraphX(), me.getGraphY());
                }

                const constraint = this.graph.getOutlineConstraint(point, this.currentState, me);
                this.constraintHandler.setFocus(me, this.currentState);
                this.constraintHandler.currentConstraint = constraint;
                this.constraintHandler.currentPoint = point;
            }

            if (this.outlineConnect) {
                if (this.marker.highlight !== null && this.marker.highlight.shape !== null) {
                    const s = this.graph.view.scale;

                    if (
                        this.constraintHandler.currentConstraint !== null &&
                        this.constraintHandler.currentFocus !== null
                    ) {
                        this.marker.highlight.shape.stroke = MxConstants.OUTLINE_HIGHLIGHT_COLOR;
                        // this.marker.highlight.shape.strokewidth = MxConstants.OUTLINE_HIGHLIGHT_STROKEWIDTH / s /s;
                        this.marker.highlight.repaint();
                    } else if (this.marker.hasValidState()) {
                        // Handles special case where actual end point of edge and current mouse point
                        // are not equal (due to grid snapping) and there is no hit on shape or highlight
                        let state: MxCellState | null = me.getState();
                        if (state && this.graph.isLabelCell(state.cell)) {
                            const parent = this.graph.getMainCell(state.cell.id);
                            state = this.graph.getView().getState(parent);
                        }

                        if (this.marker.getValidState() !== state) {
                            this.marker.highlight.shape.stroke = 'transparent';
                            this.currentState = null;
                        } else {
                            this.marker.highlight.shape.stroke = MxConstants.DEFAULT_VALID_COLOR;
                        }

                        this.marker.highlight.shape.strokewidth = MxConstants.HIGHLIGHT_STROKEWIDTH / s / s;
                        this.marker.highlight.repaint();
                    }
                }
            }
        }
    }

    mouseUp(sender: BPMMxGraph, me: MxMouseEvent) {
        if (!me.isConsumed() && this.isConnecting()) {
            if (this.waypointsEnabled && !this.isStopEvent(me)) {
                this.addWaypointForEvent(me);
                me.consume();

                return;
            }

            const currentPoint = new MxPoint(me.graphX, me.graphY);

            if (this.mouseDownCounter === 1) {
                this.previousPoint = currentPoint;
                me.consume();

                return;
            }

            if (isEqual(currentPoint, this.previousPoint)) {
                me.consume();

                return;
            }

            this.previousPoint = currentPoint;

            if (
                this.previous !== null &&
                !isUndefined(this.previous) &&
                !isUndefined(this.constraintHandler.currentFocus) &&
                this.constraintHandler.currentFocus !== null
            ) {
                this.error = this.validateConnection(this.previous.cell, this.constraintHandler.currentFocus.cell);
            }
            // Inserts the edge if no validation error exists
            if (this.error === null) {
                const source = this.previous !== null ? this.previous.cell : null;
                let target: MxCell | null = null;

                if (this.constraintHandler.currentConstraint !== null && this.constraintHandler.currentFocus !== null) {
                    target = this.constraintHandler.currentFocus.cell;
                }

                if (target === null && this.currentState !== null) {
                    target = this.currentState.cell;
                }

                this.connect(source, target, me.getEvent(), me.getCell());
            } else {
                // Selects the source terminal for self-references
                if (
                    this.marker.source &&
                    this.previous !== null &&
                    this.marker.validState !== null &&
                    this.previous.cell === this.marker.validState.cell
                ) {
                    this.graph.selectCellForEvent(this.marker.source, undefined);
                }

                // Displays the error message if it is not an empty string,
                // for empty error messages, the event is silently dropped
                if (this.error.length > 0) {
                    this.graph.validationAlert(this.error);
                }
            }
            // Redraws the connect icons and resets the handler state
            this.destroyIcons();
            me.consume();
        }

        if (this.first !== null) {
            this.reset();
        }
    }

    createMarker() {
        const marker = super.createMarker();
        const superGetCell = marker.getCell;

        marker.getCell = (me: MxMouseEvent) => {
            let cell = me.getCell();

            if (cell?.value?.type === 'label' && !this.graph.isCellConnectable(cell) && this.isConnecting()) {
                // при попытке провести связь лэбел не должен мешать при ховере на cell'e
                cell = this.graph.getMainCell(cell.id);

                return cell;
            }

            return superGetCell(me);
        };

        return marker;
    }

    connect(source: MxCell | null, target: MxCell | null, evt: Event, dropTarget: MxCell | null): void {
        if (target) super.connect(source, target, evt, dropTarget);
    }
}
