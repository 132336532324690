/* tslint:disable */
import { isUndefined } from 'is-what';
import { v4 as uuid } from 'uuid';
import { LabelSymbol, ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { EditorMode } from '../models/editorMode';
import { insertSymbolToGraph } from '../sagas/editor.saga.utils';
import { EdgeType, Symbol } from '../serverapi/api';
import { symbolService } from '../services/SymbolsService';
import { BPMMxGraphOptions } from './bpmgraph';
import { BPMN2Diagram } from './bpmnDiagram/BPMN2Diagramm';
import { DefaultGraph } from './DefaultGraph';
import { MxCell, MxGraphHandler, MxPoint } from './mxgraph';
import { BPMPSDDiagram } from './psdDiagram/BPMPSDDiagramm';

export class MethodologiesGraph extends DefaultGraph {
    symbolName: string | undefined;

    constructor(options?: BPMMxGraphOptions) {
        super({
            ...options,
            id: {
                id: '',
                serverId: '',
                repositoryId: '',
            },
            mode: EditorMode.Edit,
        });
        this.graphHandler = new MxGraphHandler(this);
        const metaInfoList = options?.modelType && options?.modelType.metaInfoList;
        if (metaInfoList && metaInfoList!.length > 0) {
            // TODO Взято из Editor.component
            const tableView = metaInfoList.filter((meta) => meta.name === 'tableView');
            if (!isUndefined(tableView[0])) {
                const tableMetaInfo = JSON.parse(tableView[0].metaInfo);
                const psdDiagram = new BPMPSDDiagram(tableMetaInfo, true, this);
                this.setPsdDiagramHandler(psdDiagram);
                this.setExtendParentsOnAdd(false);
            }
        }
        const bpmn2Diagram = new BPMN2Diagram(this);
        this.setBpmn2DiagramHandler(bpmn2Diagram);
    }

    public setSymbolName(symbolName: string) {
        this.symbolName = symbolName;
    }

    convertSymbolToSvgImage(symbol: Symbol) {
        // const isBPMN2 = this.modelType?.id === 'bpmn2';
        // todo: костыль для bpmn символов, текст у которых почемуто обрезается с добавлением '...'
        // из наблюдений - у этих символов размер ячейки лейбла зависит от размера ячейки символа,
        // так как у них видимо отсутствуют labelWidth и labelHeight. МБ привильнеее исправить bpmn символы в миграции
        // добавить к символам bpmn - labelWidth и labelHeight
        const labelHeight = 50;
        const labelWidth = 70;
        symbol = {
            ...symbol,
            labelWidth: symbol.labelWidth && symbol.labelWidth > labelWidth ? symbol.labelWidth : labelWidth,
            labelHeight: symbol.labelHeight && symbol.labelHeight > labelHeight ? symbol.labelHeight : labelHeight,
        };
        const cell = this.insertSymbol(symbol);
        if (cell && this.getView().getState(cell)?.shape?.boundingBox) {
            const bbox = this.getView().getState(cell).shape.boundingBox;
            // todo: еще один костыль для bpmn символов, ячейка символа у которых обрезается сверху
            const Y_OFFSET = 3;
            bbox.y = -Y_OFFSET;
            bbox.height += Y_OFFSET;
        }
        const svg = this.getSvgStrAllGraph();
        this.clear();

        return svg;
    }

    convertValueToString(cell: MxCell): string {
        const value = cell.getValue();
        if (value instanceof LabelSymbol) {
            return this.symbolName || '';
        }

        return '';
    }

    insertSymbol(symbol: Symbol, point?: MxPoint) {
        symbolService().applyStylesToGraph(this, [symbol]);

        // enable for custom shapes like bpmn
        // MxCellRenderer.registerShape(symbol.id, new MxShape(MxStencilRegistry.getStencil(symbol.id)));

        const objectDefinition = new ObjectDefinitionImpl({
            nodeId: {
                serverId: '',
                repositoryId: '',
                id: uuid(),
            },
            name: symbol.name,
            objectTypeId: symbol.objectType,
            createdAt: new Date().getTime(),
            type: 'OBJECT',
            isDirty: true,
        });

        return insertSymbolToGraph({
            point: point || new MxPoint(0, 0),
            symbol,
            graph: this,
            objectDefinitions: [objectDefinition],
        });
    }

    convertEdgeTypeToSvgImage(edgeType: EdgeType, destroyCellsOnDone: boolean) {
        this.getModel().beginUpdate();
        let edge = this.getModel().getCell(edgeType.id);
        if (edge) {
            this.setCellStyle(edgeType.edgeStyle, [edge]);
        } else {
            const cell = this.insertEdge(
                this.getDefaultParent(),
                edgeType.id,
                '',
                undefined!,
                undefined!,
                edgeType.edgeStyle,
            );

            cell.geometry.sourcePoint = new MxPoint(15, 20);
            cell.geometry.targetPoint = new MxPoint(135, 20);
        }
        this.getModel().endUpdate();
        edge = this.getModel().getCell(edgeType.id);

        const svg = this.getSvgImage([edge], false, true);
        destroyCellsOnDone && this.clear();

        return svg;
    }

    clear() {
        this.selectAll();
        this.getModel().beginUpdate();
        // let ids = this.getSelectionModel().cells
        //     .map((cell: MxCell) => (cell.id))
        //     .filter((object: string) => object !== undefined);
        // objectDefinitionService().removeObjects(this.id, ids);
        this.removeCells(this.getChildVertices(this.getDefaultParent()), true);
        this.getModel().clear();
        this.getModel().endUpdate();
    }
}
