import type { DiagramElement, ObjectInstance, Symbol } from '../../serverapi/api';
import type { MxCell } from '../mxgraph';
import { v4 as uuid } from 'uuid';
import { cellsTreeWalkerDown, sortParentElementsFirst } from './utils';
import { ObjectDefinitionImpl } from '../../models/bpm/bpm-model-impl';
import { BPMMxGraph } from '../bpmgraph';
import { SymbolTypeId, SequenceSymbolTypeId, DEFAULT_SYMBOL_TYPE_ID } from './symbols/ComplexSymbol.constants';
import { findComplexSymbolImplementation } from './ComplexSymbolTypeId.map';
import createComplexSymbol from './symbols/createComplexSymbol';
import { IComplexSymbol, TRootCellValue } from './symbols/ComplexSymbol.class.types';
import { differenceBy } from 'lodash';
import { KeyCodes } from '@/utils/keys';

export class ComplexSymbolManager {
    graph: BPMMxGraph;
    managedCells: MxCell[];

    static getSymbolType(symbol: Symbol): SymbolTypeId | SequenceSymbolTypeId {
        if (typeof symbol?.symbolTypeId === 'string') {
            return symbol.symbolTypeId as SymbolTypeId;
        }
        // обратная совместимость. Раньше в id символа хранился тип символа.
        // нужно возвращать только symbolTypeId, когда он будет храниться во всех символах всех пресетов

        if (findComplexSymbolImplementation(symbol.id)) {
            return symbol.id as SymbolTypeId;
        }

        return DEFAULT_SYMBOL_TYPE_ID as SymbolTypeId;
    }

    static isComplexSymbolCell(cell: MxCell) {
        return !!cell?.complexSymbolRef;
    }

    static getComplexSymbolInstance(cell: MxCell): IComplexSymbol | null {
        return cell?.complexSymbolRef || null;
    }

    static isComplexSymbol(symbol: Symbol): boolean {
        const symbolType = ComplexSymbolManager.getSymbolType(symbol) as SymbolTypeId;

        return !!findComplexSymbolImplementation(symbolType);
    }

    static isHiddenEdgeConnectableObject({ symbolId }: ObjectInstance, symbols: Symbol[]) {
        const hiddenEdgeConnectableSymbols = [
            SymbolTypeId.HORIZONTAL_SWIMLANE,
            SymbolTypeId.VERTICAL_SWIMLANE,
            SymbolTypeId.HORIZONTAL_POOL,
            SymbolTypeId.VERTICAL_POOL,
            SymbolTypeId.CROSS,
        ];
        const symbol = symbols.find(({ id }) => id === symbolId);

        if (!symbolId || !symbol) return false;

        const symbolType = ComplexSymbolManager.getSymbolType(symbol) as SymbolTypeId;

        return hiddenEdgeConnectableSymbols.includes(symbolType);
    }

    static excludeComplexSymbolCells(cells: MxCell[] = []) {
        return cells.filter((cell) => !ComplexSymbolManager.isComplexSymbolCell(cell));
    }

    static getComplexSymbolRootCell(cell: MxCell): MxCell | undefined {
        return ComplexSymbolManager.getComplexSymbolInstance(cell)?.getRootCell();
    }

    /**
     *
     * Возвращает ячейку, которую можно копировать.
     *
     * - в случае, если ячейка не является частью сложного символа, то можно копировать саму ячейку;
     * - в случае, если ячейка является частью сложного символа, то ячейку для копирования определяет инстанс символа.
     *
     * @param cell
     *
     */
    static getCopyableCell(cell: MxCell): MxCell | null {
        const instance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!instance) {
            return cell;
        }

        return instance.getCopyableCell(cell);
    }

    static isCellCopyable(cell: MxCell): boolean {
        const instance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!instance) {
            return true;
        }

        return instance.isCellCopyable(cell);
    }

    static isCellMovable(cell: MxCell): boolean {
        const instance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!instance) {
            return true;
        }

        return instance.isCellMovable(cell);
    }

    /**
     *
     * Определяет, можно ли изменять стиль ячейки.
     *
     * - в случае, если ячейка не является частью сложного символа, то можно стиль можно редактировать;
     * - в случае, если ячейка является частью сложного символа, то возможность редактирования стиля определяет инстанс символа.
     *
     * @param cell
     *
     */
    static isCellStyleEditable(cell: MxCell): boolean {
        const instance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!instance) {
            return true;
        }

        return instance.isCellStyleEditable(cell);
    }

    constructor(graph) {
        this.graph = graph;
        this.managedCells = [];
    }

    public createComplexSymbol(symbol: Symbol, objectDefinition?: ObjectDefinitionImpl, overrideProps: any = {}) {
        const getComplexSymbolClass = () => {
            const result = findComplexSymbolImplementation(symbol.id, symbol.symbolTypeId || '', symbol.objectType);

            if (!result) {
                return null;
            }

            return result;
        };

        const createRootCellValue = () => {
            const objectDefinitionId = objectDefinition?.nodeId.id;
            const rootCellValue = {
                type: 'object',
                id: uuid(),
                symbolId: symbol.id,
                objectDefinitionId,
                ...overrideProps,
            };

            return rootCellValue;
        };

        const { graph } = this;
        const complexClass = getComplexSymbolClass();
        const rootCellValue = createRootCellValue();

        if (!complexClass) {
            return null;
        }

        const { customProps, ComplexSymbolClass } = complexClass;
        const isNewObjectDefinition = !objectDefinition?.updatedAt;
        const symbolInstance = createComplexSymbol(ComplexSymbolClass, {
            rootCellValue,
            graph,
            customProps: { ...customProps, isNewObjectDefinition },
        });

        const symbolManagedCells = symbolInstance.getManagedCells();
        this.managedCells.push(...symbolManagedCells);

        return symbolInstance;
    }

    public restoreComplexSymbols(elements: DiagramElement[], symbols: Symbol[]): MxCell[] {
        const restoreSymbol = (rootCellValue: TRootCellValue, symbolTypeId: SymbolTypeId) => {
            const complexClass = findComplexSymbolImplementation(symbolTypeId);
            const { graph } = this;

            if (!complexClass) {
                return null;
            }

            const { ComplexSymbolClass } = complexClass;
            const symbolInstance = createComplexSymbol(ComplexSymbolClass, {
                rootCellValue,
                graph,
            });

            return symbolInstance;
        };

        const restoreRootCellValue = (
            element: TRootCellValue,
        ): { rootCellValue: TRootCellValue; symbolTypeId: SymbolTypeId } | null => {
            const symbolTypeId = this.getSymbolTypeIdFromSymbol(element.symbolId, symbols);

            if (symbolTypeId) {
                return {
                    rootCellValue: element,
                    symbolTypeId,
                };
            }

            return null;
        };

        const parentElementsFirst = sortParentElementsFirst(elements);

        const complexSymbols =
            parentElementsFirst
                .map((element) => restoreRootCellValue(element as TRootCellValue))
                .filter((notNull) => !!notNull)
                .map((props) => {
                    if (!props) {
                        return null;
                    }
                    const { rootCellValue, symbolTypeId } = props;

                    return restoreSymbol(rootCellValue, symbolTypeId);
                })
                .filter((notNull) => !!notNull) || [];

        complexSymbols.forEach((complexSymbol: IComplexSymbol) => {
            const symbolManagedCells = complexSymbol.getManagedCells();
            this.managedCells.push(...symbolManagedCells);
        });

        return complexSymbols.map((cell: IComplexSymbol) => cell.getRootCell());
    }

    public removeManagedCell(cell: MxCell) {
        if (!ComplexSymbolManager.isComplexSymbolCell(cell)) {
            return;
        }

        const symbolManagedCells = cell?.complexSymbolRef?.getManagedCells() || [];
        this.managedCells = differenceBy(this.managedCells, symbolManagedCells, 'id');
    }

    public loadPopupMenu(menu, cell) {
        cell?.complexSymbolRef?.loadPopupMenu?.(menu, cell);
    }

    public handleKeyPress(cell: MxCell, key: KeyCodes) {
        const instance = ComplexSymbolManager.getComplexSymbolInstance(cell);

        if (!instance) {
            return true;
        }

        return instance.handleKeyPress(cell, key);
    }

    public convertValueToString(cell: MxCell): string {
        try {
            return cell?.complexSymbolRef?.convertValueToString(cell) || '';
        } catch (e) {
            return '';
        }
    }

    public prepareDiagramElements(cells: MxCell[]): DiagramElement[] {
        const { root } = this.graph.getModel();
        const result: Record<string, ObjectInstance> = {};
        const ids = cells.map(({ id }) => id);

        function grabHandler(cell) {
            if (!ComplexSymbolManager.isComplexSymbolCell(cell) || !ids.includes(cell.id)) {
                return;
            }

            const objectInstance = cell.complexSymbolRef.serialize();

            result[objectInstance.id] = objectInstance;
        }

        cellsTreeWalkerDown([root], grabHandler);

        return Object.values(result);
    }

    public refreshCells() {
        if (this.managedCells.length === 0) return;

        this.managedCells.forEach((cell) => ComplexSymbolManager.getComplexSymbolInstance(cell)?.redraw());
    }

    private getSymbolTypeIdFromSymbol(symbolId: string, symbols: Symbol[]): SymbolTypeId | undefined {
        const symbol = symbols?.find((s) => s.id === symbolId);

        if (symbol?.symbolTypeId) {
            return symbol.symbolTypeId as SymbolTypeId;
        }

        return symbol?.objectType as SymbolTypeId;
    }
}
