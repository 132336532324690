import { Tooltip } from 'antd';
import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeEdgeStyle, changeEdgeType } from '../../../../actions/editor.actions';
import { initialEdgeTypeSelectorState, TEdgeTypeSelectorState } from '../../../../models/edgeTypeSelectorState.types';
import { SequenceEdgeTypesId } from '../../../../mxgraph/SequenceGraph/SequenceConstants';
import { getGeneralMenuEdgeTypeSelectorState } from '../../../../selectors/generalMenu.selectors';
import { EdgeTypeSelect } from '../EdgeTypeSelect/EdgeTypeSelect.component';
import menuItemtheme from '../items/MenuItem.scss';
import theme from './GraphObjectToolbar.scss';
import messages from '../../messages/CommonToolbar.messages';
import { MxCell } from '../../../../mxgraph/mxgraph';
import { EdgeArrowSelect } from '../EdgeArrowSelect/EdgeArrowSelect.component';
import {
    PROP_KEY_EDGE_END_ARROW,
    PROP_KEY_EDGE_START_ARROW,
} from '../../../../models/properties/accessible-properties';
import { EdgeLineTypeSelect } from '../EdgeLineTypeSelect/EdgeLineTypeSelect.component';
import icStartArrow from '../../../../resources/icons/toolbar/ic-start-line.svg';
import icEndArrow from '../../../../resources/icons/toolbar/ic-end-line.svg';
import { EdgeStyleDescriptor } from '../../../../models/edge-style';

type TGraphObjectToolbarEdgeSettingsProps = {
    isEntityEditable: boolean;
    selectedCells: MxCell[];
    compact: boolean;
};

export const GraphObjectToolbarEdgeSettings: FC<TGraphObjectToolbarEdgeSettingsProps> = ({
    isEntityEditable,
    selectedCells,
    compact,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const edgeTypeSelectorState = useSelector(getGeneralMenuEdgeTypeSelectorState);
    const isEdgeSelected = edgeTypeSelectorState.currentTypeIndex !== -1;
    const edgeEditDisabled = !isEdgeSelected || !isEntityEditable;
    const someSelectedCellsHaveEdgeDefinitionId = selectedCells.some((cell) => cell.value?.edgeDefinitionId);
    const currentEdgeType = edgeTypeSelectorState.availableTypes[edgeTypeSelectorState.currentTypeIndex];
    const isRecursiveEdge = currentEdgeType?.id === SequenceEdgeTypesId.RECURSIVE_MESSAGE;

    const visibleEdgeTypeSelectorState: TEdgeTypeSelectorState = useMemo(() => {
        const availableTypes = edgeTypeSelectorState.availableTypes.filter(
            (type) => type !== null && type.id !== SequenceEdgeTypesId.RECURSIVE_MESSAGE,
        );

        return {
            ...initialEdgeTypeSelectorState,
            ...edgeTypeSelectorState,
            availableTypes,
            currentTypeIndex: availableTypes.findIndex((type) => type.id === currentEdgeType?.id),
        };
    }, [edgeTypeSelectorState]);

    const createTooltipMessage = (edgeDefinitionWarning?: string) =>
        (edgeEditDisabled && intl.formatMessage(messages.selectEdge)) ||
        (someSelectedCellsHaveEdgeDefinitionId && edgeDefinitionWarning) ||
        '';

    const handleChangeEdgeType = (type: string) => {
        dispatch(changeEdgeType(type));
    };

    const handleChangeEdgeStyle = (styleKey: string) => (styleValue: string) => {
        const edges = selectedCells.filter((cell) => cell.isEdge());
        edges.forEach((edge) => {
            dispatch(changeEdgeStyle([styleKey], [styleValue], edge.id));
        });
    };

    const handleChangeLineType = (value: string) => {
        const { styleKeys, styleValues } = EdgeStyleDescriptor.byValue(Number(value));
        const edges = selectedCells.filter((cell) => cell.isEdge());
        edges.forEach((edge) => {
            dispatch(changeEdgeStyle(styleKeys, styleValues, edge.id));
        });
    };

    return (
        <div className={menuItemtheme.editGroup}>
            <span className={cx(menuItemtheme.spanGroupRow, theme.spanGroupRow, compact && theme.compact)}>
                <span>
                    <Tooltip
                        mouseLeaveDelay={0}
                        title={createTooltipMessage(intl.formatMessage(messages.edgeDefinitionTypeWarning))}
                    >
                        <div data-test="toolbar_edge-type" className={menuItemtheme.tooltipContainer}>
                            <EdgeTypeSelect
                                value={visibleEdgeTypeSelectorState.currentTypeIndex}
                                compact={compact}
                                onSelect={handleChangeEdgeType}
                                items={visibleEdgeTypeSelectorState.availableTypes}
                                disabled={edgeEditDisabled || someSelectedCellsHaveEdgeDefinitionId || isRecursiveEdge}
                            />
                        </div>
                    </Tooltip>
                </span>

                <span>
                    <Tooltip
                        mouseLeaveDelay={0}
                        title={createTooltipMessage(intl.formatMessage(messages.edgeDefinitionStartArrowWarning))}
                    >
                        <div data-test="format-toolbar_edge-start-arrow" className={menuItemtheme.tooltipContainer}>
                            <EdgeArrowSelect
                                icon={icStartArrow}
                                title={intl.formatMessage(messages.startArrow)}
                                value={visibleEdgeTypeSelectorState.currentTypeIndex}
                                compact={compact}
                                onSelect={handleChangeEdgeStyle(PROP_KEY_EDGE_START_ARROW)}
                                disabled={edgeEditDisabled || someSelectedCellsHaveEdgeDefinitionId || isRecursiveEdge}
                            />
                        </div>
                    </Tooltip>
                </span>
            </span>

            <span className={cx(menuItemtheme.spanGroupRow, theme.spanGroupRow, compact && theme.compact)}>
                <span>
                    <Tooltip mouseLeaveDelay={0} title={createTooltipMessage()}>
                        <div data-test="format-toolbar_edge-line" className={menuItemtheme.tooltipContainer}>
                            <EdgeLineTypeSelect
                                value={visibleEdgeTypeSelectorState.currentTypeIndex}
                                compact={compact}
                                onSelect={handleChangeLineType}
                                disabled={edgeEditDisabled || isRecursiveEdge}
                            />
                        </div>
                    </Tooltip>
                </span>

                <span>
                    <Tooltip
                        mouseLeaveDelay={0}
                        title={createTooltipMessage(intl.formatMessage(messages.edgeDefinitionEndArrowWarning))}
                    >
                        <div data-test="format-toolbar_edge-end-arrow" className={menuItemtheme.tooltipContainer}>
                            <EdgeArrowSelect
                                icon={icEndArrow}
                                title={intl.formatMessage(messages.endArrow)}
                                value={visibleEdgeTypeSelectorState.currentTypeIndex}
                                compact={compact}
                                onSelect={handleChangeEdgeStyle(PROP_KEY_EDGE_END_ARROW)}
                                disabled={edgeEditDisabled || someSelectedCellsHaveEdgeDefinitionId || isRecursiveEdge}
                            />
                        </div>
                    </Tooltip>
                </span>
            </span>
        </div>
    );
};
