import { Tooltip, Button, Modal } from 'antd';
import React, { FC } from 'react';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from './RowButtons.messages';
import icDelete from '../../../resources/icons/delete.svg';
import theme from './RowButtons.scss';
import { LocalesService } from '../../../services/LocalesService';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

type TDeleteButton = {
    onDelete: () => void;
    deleteQuestion?: string;
    dialogContent?: React.ReactNode;
    className?: string;
    disabled?: boolean;
};

export const showDeleteConfirmation = (props: TDeleteButton) => {
    const { onDelete, deleteQuestion, dialogContent, className } = props;
    const intl = LocalesService.useIntl();
    Modal.confirm({
        className: classnames(theme.deleteDialog, className, !dialogContent ? theme.emptyDialogContent : ''),
        title: deleteQuestion || intl.formatMessage(messages.deleteTitle),
        content: dialogContent || '',
        okText: <div data-test="delete-methodology_ok_btn">{intl.formatMessage(messages.delete)}</div>,
        cancelText: <div data-test="delete-methodology_cancel_btn">{intl.formatMessage(messages.cancel)}</div>,
        okButtonProps: { danger: true, ghost: false, size: 'large' },
        cancelButtonProps: { size: 'large' },
        closable: true,
        icon: '',
        onOk() {
            onDelete();
            Modal.destroyAll();
        },
        maskClosable: true,
        okCancel: true,
    });
};

const DeleteButton: FC<TDeleteButton> = (props) => (
    <Tooltip mouseLeaveDelay={0} title={useIntl().formatMessage(messages.delete)}>
        <Button
            disabled={props.disabled}
            data-test="row-button_DELETE"
            type="link"
            onClick={() => showDeleteConfirmation(props)}
            icon={<Icon spriteSymbol={icDelete} />}
        />
    </Tooltip>
);

export default DeleteButton;
