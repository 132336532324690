import React, { FC, useState } from 'react';
import theme from './ObjectPropertiesDialog.scss';
import { Button, Form, Input, Modal } from 'antd';
import multiLangMessages from '../../MultiLangInputDialog/MultiLangInputDialog.messages';
import footerMessages from '../../AdminTools/Methodology/components/Presets/Footer/EditorFooterButtons.messages';
import locales from '../../../static/locales/index.json';
import { AttributeValueString, InternationalString } from '../../../serverapi/api';
import { LANG } from '../../../config/config';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { useIntl } from 'react-intl';
import { TValueTypeEnum } from '../../../models/ValueTypeEnum.types';
import { shouldCallModalSubmitHandler } from '../../../services/utils/ModalHelper';
import { trimMultiLangValue } from './utils';

type TMultiLangStringDialogComponent = {
    handleOk: (recordId: string, valueType: TValueTypeEnum, formValues: InternationalString) => void;
    record: AttributeValueString;
    allowEmptyValue?: boolean;
    curLocale?: string | undefined
};

export const MultiLangStringDialogComponent: FC<TMultiLangStringDialogComponent> = ({
    handleOk,
    record,
    allowEmptyValue,
    curLocale,
}) => {
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();
    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const showMultilingualModal = (multiLangValue: InternationalString | undefined) => {
        form.resetFields();
        form.setFieldsValue(trimMultiLangValue(multiLangValue));
        setIsModalVisible(true);
    };

    const onValidateFields = () => {
        form.validateFields().then((formValues: InternationalString) => {
            handleOk(record.id, record.valueType, {...formValues, [curLocale as string]: formValues?.[curLocale as string]?.trim()});
            setIsModalVisible(false);
            form.resetFields();
        // todo нужно ли всплывающее окно об ошибке?
        }).catch((e) => console.error ('error', e));
    };

    const customFooter = [
        <Button data-test="translation-window_button-cancel" key="back" onClick={() => setIsModalVisible(false)}>
            {intl.formatMessage(footerMessages.cancel)}
        </Button>,
        <Button
            data-test="translation-window_button-ok"
            key="submit"
            type="primary"
            className={theme.buttonWithTooltipe}
            onClick={onValidateFields}
            id="multilang-model-dialog-submitBtn"
        >
            {intl.formatMessage(footerMessages.save)}
        </Button>,
    ];

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    onValidateFields();
                }
            }}
        >
            <span className={theme.attribute_openMultiLangBtn} onClick={() => showMultilingualModal(record.str)}>
                <u data-test="element-properties_change-name-for-two-locale">({currentLocale.toUpperCase()})</u>
            </span>
            <Modal
                footer={customFooter}
                destroyOnClose
                title={intl.formatMessage(multiLangMessages.modalTitle)}
                open={isModalVisible}
                okButtonProps={{ size: 'large' }}
                cancelButtonProps={{ size: 'large' }}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} form={form}>
                    {locales.map((locale: keyof InternationalString) => {
                        const required = !allowEmptyValue && record.typeId === 'SYSTEM' && locale === currentLocale;

                        return (
                            <Form.Item
                                name={locale}
                                key={locale}
                                label={LANG[locale]}
                                className={theme.formItem}
                                rules={[
                                    {
                                        whitespace: required,
                                        required,
                                        message: intl.formatMessage(multiLangMessages.requiredField),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        );
                    })}
                </Form>
            </Modal>
        </div>
    );
};
