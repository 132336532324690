import React, { FC } from 'react';
import theme from './ApprovalsTab.scss';
import { Icon } from '@/modules/UIKit';
import icApprovalEdit from '../../../../resources/icons/ic-approval-edit.svg';
import icApprovalInProgress from '../../../../resources/icons/ic-approval-in-progress.svg';
import { ApprovalDTOStatus } from '@/modules/ApprovalDialog/ApprovalDialog.types';
import icApprovalNotApproved from '../../../../resources/icons/ic-aproval-not-approved.svg';
import icApprovalApproved from '../../../../resources/icons/ic-aproval-approved.svg';
import { useDispatch, useSelector } from 'react-redux';
import { hasModelEditorLicense } from '@/selectors/authorization.selectors';
import { ApprovalDTOStatusEnum, NodeId } from '@/serverapi/api';
import { editExistingApproval } from '@/actions/approval.actions';

type TApprovalHeader = {
    name: string;
    status: ApprovalDTOStatusEnum;
    id: NodeId;
};

export const ApprovalHeader: FC<TApprovalHeader> = ({ name, status, id }) => {
    const dispatch = useDispatch();
    const isModelEditor = useSelector(hasModelEditorLicense);

    const onToggleApproval = (id: string) => {
        const approvalContent = document.getElementById(id);
        if (approvalContent) {
            approvalContent.classList.toggle('hidden');
        }
    };

    const onApprovalEdit = () => {
        dispatch(editExistingApproval({ approvalId: id }));
    };

    const renderApprovalIcon = (status: ApprovalDTOStatusEnum): React.ReactNode => {
        let icon: React.ReactNode = <Icon spriteSymbol={icApprovalInProgress} />;
        let dataTest: string | undefined;

        switch (status) {
            case ApprovalDTOStatus.APPROVED:
                icon = <Icon spriteSymbol={icApprovalApproved} className={theme.statusApproved} />;
                dataTest = `approval-approved-icon`;
                break;
            case ApprovalDTOStatus.NOT_APPROVED:
                icon = <Icon spriteSymbol={icApprovalNotApproved} className={theme.statusNotApproved} />;
                break;
            case ApprovalDTOStatus.CANCELLED:
                icon = <Icon spriteSymbol={icApprovalNotApproved} className={theme.statusCancelled} />;
                break;
        }

        return (
            <div className={theme.statusIconWrapper} data-test={dataTest}>
                {icon}
            </div>
        );
    };

    return (
        <div className={theme.header}>
            {renderApprovalIcon(status)}
            <div className={theme.title} onClick={() => onToggleApproval(id.id)}>
                {name}
            </div>
            {isModelEditor && (status === ApprovalDTOStatus.IN_PROCESS || status === ApprovalDTOStatus.CANCELLED) ? (
                <div className={theme.editIconWrapper} onClick={onApprovalEdit}>
                    <Icon spriteSymbol={icApprovalEdit} className={theme.editIcon} />
                </div>
            ) : null}
        </div>
    );
};
