import React, { useState } from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import messages from './WidgetSelectTableDialog.messages';
import theme from './WidgetSelectTableDialog.scss';
import { NodeId } from '@/serverapi/api';
import { TreeNode } from '@/models/tree.types';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { Tree } from '@/modules/Tree/components/Tree/Tree.component';
import { setWidgetSource } from '@/actions/dashboard.actions';
import { TWidgetSource } from '../../Dashboard.types';
import { spreadsheetLoadById } from '@/actions/entities/spreadsheet.actions';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';

type TWidgetSelectTableDialogProps = {
    nodeId: NodeId;
    widgetId?: string;
};

export const WidgetSelectTableDialog = (props: TWidgetSelectTableDialogProps) => {
    const { nodeId, widgetId } = props;
    const { repositoryId, serverId } = nodeId;
    const [selectedNode, setSelectedNode] = useState<TreeNode>();

    const nodes: TreeNode[] = useSelector(DashboardSelector.getSpreadsheetTreeNodes(repositoryId, serverId));

    const dispatch = useDispatch();

    const onClose = () => dispatch(closeDialog(DialogType.DASHBOARD_SELECT_TABLE_DIALOG));
    const onSubmit = () => {
        if (nodeId && widgetId && selectedNode?.type === TreeItemType.Spreadsheet) {
            const source: TWidgetSource = {
                id: selectedNode.nodeId,
                name: selectedNode.name,
                dataset: [{ col1: '', col2: '', legend: '' }],
            };
            dispatch(setWidgetSource(nodeId, widgetId, source));
            dispatch(spreadsheetLoadById(source.id));
            dispatch(closeDialog(DialogType.DASHBOARD_SELECT_TABLE_DIALOG));
        }
    };

    const intl = useIntl();
    const footer = [
        <Button className={theme.button} key="cancel" onClick={onClose}>
            {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button
            disabled={selectedNode?.type !== TreeItemType.Spreadsheet}
            className={theme.button}
            key="apply"
            onClick={onSubmit}
            type="primary"
        >
            {intl.formatMessage(messages.apply)}
        </Button>,
    ];

    return (
        <Dialog
            width={612}
            open
            onOk={onSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.title)}
            footer={footer}
            className={theme.dialog}
        >
            <div className={theme.treeContainer}>
                <Tree
                    data={nodes}
                    treeName={DialogType.DASHBOARD_SELECT_TABLE_DIALOG}
                    onSelect={(node) => setSelectedNode(node)}
                    selectedNodes={selectedNode ? [selectedNode] : []}
                    disableContextMenu
                />
            </div>
        </Dialog>
    );
};
