// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".commentCircle__circle___C8e3,.commentCircle__circleCritical__e1sH_{-ms-flex-negative:0;flex-shrink:0;color:#fff;border-radius:50%;height:32px;width:32px;position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer}.commentCircle__circleCritical__e1sH_{border:4px solid #ff3733}.commentCircle__withBoarder__rpO3K{border:4px solid #08c}.commentCircle__circleContent__RcYdN{font-family:\"Segoe UI\";font-weight:600;font-size:12px;line-height:20px;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/modules/Comments/components/CommentCircle/commentCircle.scss"],"names":[],"mappings":"AAAA,oEAEI,mBAAA,CAAA,aAAA,CACA,UAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,cAAA,CAGJ,sCACI,wBAAA,CAEJ,mCACI,qBAAA,CAGJ,qCACI,sBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA","sourcesContent":[".circle,\r\n.circleCritical {\r\n    flex-shrink: 0;\r\n    color: #fff;\r\n    border-radius: 50%;\r\n    height: 32px;\r\n    width: 32px;\r\n    position: relative;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    cursor: pointer;\r\n}\r\n\r\n.circleCritical {\r\n    border: 4px solid #ff3733;\r\n}\r\n.withBoarder {\r\n    border: 4px solid #08c;\r\n}\r\n\r\n.circleContent {\r\n    font-family: 'Segoe UI';\r\n    font-weight: 600;\r\n    font-size: 12px;\r\n    line-height: 20px;\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": "commentCircle__circle___C8e3",
	"circleCritical": "commentCircle__circleCritical__e1sH_",
	"withBoarder": "commentCircle__withBoarder__rpO3K",
	"circleContent": "commentCircle__circleContent__RcYdN"
};
export default ___CSS_LOADER_EXPORT___;
