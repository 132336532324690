// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FileUploadDialog__dialog__do5IX .FileUploadDialog__uploadSvg__tNShX{width:70px;height:70px}.FileUploadDialog__dialog__do5IX .FileUploadDialog__footer__IsjMr{padding:0 14px}.FileUploadDialog__fieldsContainer__DOF7c{margin-top:10px}.FileUploadDialog__dialogUploadAreaMessage__B7OPq{padding-bottom:10px}", "",{"version":3,"sources":["webpack://./src/modules/FileUpload/components/FileUploadDialog.scss"],"names":[],"mappings":"AACI,qEACI,UAAA,CACA,WAAA,CAGJ,kEACI,cAAA,CAIR,0CACI,eAAA,CAGJ,kDACI,mBAAA","sourcesContent":[".dialog {\r\n    .uploadSvg {\r\n        width: 70px;\r\n        height: 70px;\r\n    }\r\n\r\n    .footer {\r\n        padding: 0 14px;\r\n    }\r\n}\r\n\r\n.fieldsContainer {\r\n    margin-top: 10px;\r\n}\r\n\r\n.dialogUploadAreaMessage {\r\n    padding-bottom: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "FileUploadDialog__dialog__do5IX",
	"uploadSvg": "FileUploadDialog__uploadSvg__tNShX",
	"footer": "FileUploadDialog__footer__IsjMr",
	"fieldsContainer": "FileUploadDialog__fieldsContainer__DOF7c",
	"dialogUploadAreaMessage": "FileUploadDialog__dialogUploadAreaMessage__B7OPq"
};
export default ___CSS_LOADER_EXPORT___;
