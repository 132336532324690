import { Form } from 'antd';
import React, { FC } from 'react';
import { useIntl, WrappedComponentProps } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, openDialog } from '../../../actions/dialogs.actions';
import {
    createEvent,
    eventDialogAddNodeId,
    eventDialogDeleteNodeId,
    eventDialogDeleteParentNodeId,
    eventDialogDeleteScriptNodeId,
    updateEvent,
} from '../../../actions/eventAccess.actions';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { EventDescriptorEventActionsEnum, EventDescriptorEventRunStrategyEnum, NodeId, PrincipalDescriptor, RootNodeId } from '../../../serverapi/api';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './AddEventDialog.messages';
import { v4 as uuid } from 'uuid';
import { EventsAccessSelectors } from '../../../selectors/eventsAccess.selectors';
import { TEvent } from '../../../reducers/eventsAccess.reducer.types';
import { EventDialogForm } from './EventDialogForm.component';
import { PrincipalsSelectors } from '../../../selectors/principals.selectors';
import theme from './AddEventDialog.scss';
import { TreeNode } from '../../../models/tree.types';
import { treeItemExpand } from '../../../actions/tree.actions';

type TAddEventDialogProps = WrappedComponentProps & {
    serverId: string;
    editing: boolean;
    event: TEvent;
};

type TFormValues = {
    name: string;
    eventActions: EventDescriptorEventActionsEnum;
    eventRunStrategy: EventDescriptorEventRunStrategyEnum;
    groupId: number;
    userId: number;
    className: string;
    params: string;
    scriptId: string;
};

export const AddEventDialog: FC<TAddEventDialogProps> = (props) => {
    const { serverId, editing, event } = props;
    const intl = useIntl();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const nodeParams = useSelector(EventsAccessSelectors.getNodeParams());
    const getUsersAndGroups: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getAll);
    const userList: PrincipalDescriptor[] = [];
    const groupList: PrincipalDescriptor[] = [];

    getUsersAndGroups.forEach(el => {
        if (el.principalType === 'GROUP') {
            groupList.push(el);
        }
        if (el.principalType === 'USER') {
            userList.push(el);
        }
    });

    const onAddNode = (paramName: string) => {
        dispatch(
            openDialog(DialogType.TREE_ITEM_SELECT_DIALOG, {
                serverId,
                onSubmit: (nodeId: NodeId) => dispatch(eventDialogAddNodeId(paramName, nodeId)),
            }),
        );
    };

    const onAddScript = () => {
        const scriptFolderNodeId: NodeId = {
            id: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
            repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
            serverId,
        };
        dispatch(treeItemExpand(scriptFolderNodeId, DialogType.EVENT_SCRIPT_DIALOG));
        dispatch(
            openDialog(DialogType.EVENT_SCRIPT_DIALOG, {
                serverId,
                dialogType: DialogType.EVENT_SCRIPT_DIALOG,
                repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
                isEvent: true,
                onSubmit: (selectedScript: TreeNode) => dispatch(eventDialogAddNodeId('scriptId', selectedScript.nodeId)),
            }),
        );
    };

    const onDeleteNodeId = () => {
        dispatch(eventDialogDeleteNodeId(true));
    };

    const onDeleteParentNodeId = () => {
        dispatch(eventDialogDeleteParentNodeId(true));
    };

    const onDeleteScriptNodeId = () => {
        dispatch(eventDialogDeleteScriptNodeId(true));
    };

    const clearNodeFields = () => {
        dispatch(eventDialogDeleteNodeId());
        dispatch(eventDialogDeleteParentNodeId());
        dispatch(eventDialogDeleteScriptNodeId());
    };
    const handleSubmit = () => {
        form.validateFields().then((values: TFormValues) => {
            form.resetFields();
            const { eventActions, eventRunStrategy, name, className, params, userId, groupId } = values;
            const { nodeId, parentNodeId, scriptId } = nodeParams;

            if (!editing) {
                dispatch(
                    createEvent({
                        id: uuid(),
                        eventRunStrategy,
                        eventActions,
                        name: name?.trim(),
                        serverId,
                        ownerPrincipalId: 2,
                        nodeId: nodeId?.nodeId,
                        parentNodeId: parentNodeId?.nodeId,
                        className: className.trim(),
                        params: params?.trim(),
                        userId,
                        groupId,
                        scriptId: scriptId?.nodeId?.id,
                    }),
                );
            } else {
                dispatch(
                    updateEvent({
                        id: event.id,
                        eventRunStrategy,
                        eventActions,
                        name: name?.trim(),
                        serverId,
                        ownerPrincipalId: 2,
                        nodeId:  nodeId?.nodeId || event.nodeId,
                        parentNodeId: parentNodeId?.nodeId || event.parentNodeId,
                        className: className?.trim(),
                        params: params?.trim(),
                        userId,
                        groupId,
                        scriptId: scriptId?.nodeId ? scriptId?.nodeId?.id : event.scriptId,
                    }),
                );
            }
            clearNodeFields();
            dispatch(closeDialog(DialogType.EVENT_DIALOG));
        });
    };

    return (
        <Dialog
            className={theme.eventModalDialogWrapper}
            onOk={handleSubmit}
            onCancel={() => {
                clearNodeFields();
                dispatch(closeDialog(DialogType.EVENT_DIALOG));
            }}
            title={
                editing ? intl.formatMessage(messages.eventFormNameEdit) : intl.formatMessage(messages.eventFormName)
            }
            visible
            width={DEFAULT_DIALOG_WIDTH}
            okText={<div data-test="add-event-dialog_save-btn">{intl.formatMessage(messages.eventFormConfirmButton)}</div>}
            cancelText={intl.formatMessage(messages.eventFormDeclineButton)}
        >
            <EventDialogForm
                form={form}
                editing={editing}
                event={event}
                userList={userList}
                groupList={groupList}
                nodeParams={nodeParams}
                onAddNode={onAddNode}
                onAddScript={onAddScript}
                onDeleteNodeId={onDeleteNodeId}
                onDeleteParentNodeId={onDeleteParentNodeId}
                onDeleteScriptNodeId={onDeleteScriptNodeId}
            />
        </Dialog>
    );
};
