import React, { useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import icAttribute from '../../../../../../../resources/icons/ic-attribute.svg';
import { AttributeType, AttributeTypeGroup } from '../../../../../../../serverapi/api';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { GroupedTypesTable } from '../../util/GroupedTypesTable.component';
import messages from '../AttributeType.messages';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TTableDataType } from '../../util/GroupedTypesTable.types';
import { useAttributeNameWithType } from '../../util/useAttributeNameWithType';
import { filterAttributes } from '../../util/AttributeTypes.utils';
import theme from './AttributesTabDialog.scss';

type TAttributesTabDialogIntlProps = {
    attributeTypes: AttributeType[];
    dialogVisible: boolean;
};

type TAttributesTabDialogIntlActionProps = {
    onOk: (attributes: AttributeType[]) => void;
    onCancel: () => void;
};

type TAttributesTabDialogIntlFullProps = TAttributesTabDialogIntlProps &
    TAttributesTabDialogIntlActionProps &
    WrappedComponentProps;

const AttributesTabDialog = (props: TAttributesTabDialogIntlFullProps) => {
    const [newAttributes, setNewAttributes] = useState<AttributeType[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>('');

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchFilter(e.target.value);
    }

    const attributeTypeGroups: AttributeTypeGroup[] = props.attributeTypes
        .map((at) => at.attributeTypeGroup)
        .reduce((acc: AttributeTypeGroup[], atg: AttributeTypeGroup) => {
            if (!acc.some((attributeTypeGroup) => atg.id === attributeTypeGroup.id)) {
                acc.push(atg);
            }

            return acc;
        }, []);
    
    const heightTable = 250;
    const attributeTypes: AttributeType[] = props.attributeTypes?.map((type) => ({ ...type, groupId: type.attributeTypeGroup?.id }));
    const attributeNameWithType = useAttributeNameWithType(attributeTypes);

    const onSelectType = (attributes: TTableDataType[]) => setNewAttributes(filterAttributes(attributeTypes, attributes));

    return (
        <div>
            <Dialog
                onOk={() => {
                    props.onOk(newAttributes);
                }}
                onCancel={props.onCancel}
                open={props.dialogVisible}
                title={props.intl.formatMessage(messages.addAttributes)}
                okText={<div data-test="new-attribute-type-window_save-button">{props.intl.formatMessage(messages.ok)}</div>}
                cancelText={<div data-test="new-attribute-type-window_cancel-button">{props.intl.formatMessage(messages.cancel)}</div>}
            >
                <div className={theme.contentDialog}>
                    <Input suffix={<SearchOutlined />} onChange={handleSearch} className={theme.searchInput}/>
                    <div className={theme.tableContainer}>
                        <GroupedTypesTable
                            types={attributeNameWithType}
                            typeGroups={attributeTypeGroups}
                            searchFilter={searchFilter}
                            defaultColumnWidth="90%"
                            actionsDisabled={false}
                            onSelectType={onSelectType}
                            icon={icAttribute}
                            hideGUIDColumn
                            wHeight={heightTable}
                        />
                    </div>
                </div>   
            </Dialog>
        </div>
    );
};

const AttributesTabDialogIntl = injectIntl(AttributesTabDialog);

export { AttributesTabDialogIntl as AttributesTabDialog };
