import { ObjectDefinitionSelectors } from '@/selectors/objectDefinition.selectors';
import { NodeId } from '@/serverapi/api';
import { getStore } from '@/store';
import { getUmlPackageDefinitionData } from '../umlDefinitionData.selectors';
import { TUmlPackageData } from './packageSymbol.types';

export const umlPackageDataGetter =
    (graphId: NodeId) =>
    (id: string): TUmlPackageData => {
        const state = getStore().getState();

        const nodeId = {
            id,
            repositoryId: graphId.repositoryId,
            serverId: graphId.serverId,
        };

        const objectDefinition = ObjectDefinitionSelectors.byId(nodeId)(state);

        if (!objectDefinition) return { name: '' };

        const umlPackageData = getUmlPackageDefinitionData(objectDefinition)(state);

        return umlPackageData;
    };

export const getUmlPackageSymbolCustomProps = (graph, rootCellValue): TUmlPackageData => {
    const { objectDefinitionId } = rootCellValue;
    const getUmlClassData = umlPackageDataGetter(graph.id);

    return getUmlClassData(objectDefinitionId);
};
