// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttributesTabDialog__contentDialog__KsMR_{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:400px;overflow:hidden}.AttributesTabDialog__searchInput__DH2_Z{width:200px;margin-bottom:20px}.AttributesTabDialog__tableContainer___fhON{display:-webkit-box;display:-ms-flexbox;display:flex;height:100%;overflow-y:hidden}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/AttributeType/AttributesTabDialog/AttributesTabDialog.scss"],"names":[],"mappings":"AAAA,2CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,YAAA,CACA,eAAA,CAGJ,yCACI,WAAA,CACA,kBAAA,CAGJ,4CACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".contentDialog {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 400px;\r\n    overflow: hidden;\r\n}\r\n\r\n.searchInput {\r\n    width: 200px;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.tableContainer {\r\n    display: flex;\r\n    height: 100%;\r\n    overflow-y: hidden;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentDialog": "AttributesTabDialog__contentDialog__KsMR_",
	"searchInput": "AttributesTabDialog__searchInput__DH2_Z",
	"tableContainer": "AttributesTabDialog__tableContainer___fhON"
};
export default ___CSS_LOADER_EXPORT___;
