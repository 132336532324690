import { TReducer } from '../utils/types';
import {
    ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS,
    ATTRIBUTE_TYPES_REQUEST_SUCCESS,
} from '../actionsTypes/attributeType.actionTypes';
import { TAttributeTypeState } from './attributeType.reducer.types';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';

export const INITIAL_ATTRIBUTE_TYPE_SERVER_STATE = {
    byPresetId: {},
};

const initial: TAttributeTypeState = {
    byServerId: {},
};

const tryInitStateWithNewServer = (state: TAttributeTypeState, serverId: string) => {
    if (!state.byServerId[serverId]) {
        state.byServerId[serverId] = {
            byPresetId: {},
        };
    }
};

const tryInitStateWithNewPreset = (state: TAttributeTypeState, serverId: string, presetId: string) => {
    if (!state.byServerId[serverId].byPresetId[presetId]) {
        state.byServerId[serverId].byPresetId[presetId] = {
            byId: {},
        };
    }
};

const tryInitStateWithServerAndPreset = (state: TAttributeTypeState, serverId: string, presetId: string) => {
    tryInitStateWithNewServer(state, serverId);
    tryInitStateWithNewPreset(state, serverId, presetId);
};

export const attributeTypeReducer: TReducer<TAttributeTypeState> = (state = initial, action) => {
    switch (action.type) {
        case ATTRIBUTE_TYPES_DELETE_REQUEST_SUCCESS: {
            const {
                attributeTypes,
                serverNode: {
                    nodeId: { serverId },
                },
            } = action.payload;
            const stateClone = JSON.parse(JSON.stringify(state));
            attributeTypes.forEach((attributeType) => {
                const byId =
                    (attributeType.presetId &&
                        stateClone.byServerId[serverId] &&
                        stateClone.byServerId[serverId].byPresetId[attributeType.presetId] &&
                        stateClone.byServerId[serverId].byPresetId[attributeType.presetId].byId) ||
                    {};
                attributeType.id && delete byId[attributeType.id];
            });

            return stateClone;
        }
        case ATTRIBUTE_TYPES_REQUEST_SUCCESS: {
            const {
                payload: { serverId, presetId, attributeTypes },
            } = action;
            const stateClone: TAttributeTypeState = JSON.parse(JSON.stringify(state));

            tryInitStateWithServerAndPreset(stateClone, serverId, presetId);

            attributeTypes.forEach((at) => {
                stateClone.byServerId[serverId].byPresetId[presetId].byId[at.id] = { ...at };
            });

            return stateClone;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }

        default:
            return state;
    }
};
