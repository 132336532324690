import { Form, Input, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { FC, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { scriptCreate } from '../../../actions/entities/script.actions';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { IScriptNode } from '../../../models/bpm/bpm-model-impl.types';
import { NodeId } from '../../../serverapi/api';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TreeItemType } from '../../Tree/models/tree';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './ScriptDialog.messages';
import theme from './ScriptDialog.scss';

enum ScriptNodeLanguageEnum {
    GROOVY = 'GROOVY',
    JS = 'JS',
    GROOVY_DEBUG = 'GROOVY_DEBUG',
    KOTLIN = 'KOTLIN',
    KOTLIN_DEBUG = 'KOTLIN_DEBUG'
}

type TScriptCreateDialogProps = WrappedComponentProps & {
    parentId: NodeId;
};

type TFormValues = {
    scriptName: string;
};

const ScriptDialog: FC<TScriptCreateDialogProps> = (props) => {
    const { intl, parentId } = props;
    const [form] = Form.useForm();
    const GROOVY_DEBUG_POSTFIX: string = '[debug]';
    const KOTLIN_DEBUG_POSTFIX: string = '[debug_kotlin]';
    const { JS, GROOVY, GROOVY_DEBUG, KOTLIN, KOTLIN_DEBUG } = ScriptNodeLanguageEnum;
    const [lang, setLang] = useState<ScriptNodeLanguageEnum>(GROOVY);
    const [disabled, setDisabled] = useState<boolean>(true);
    const dispatch = useDispatch();

    const handleChangeLang = (e: RadioChangeEvent) => {
        setLang(e.target.value);
    };

    const handleSubmit = () => {
        form.validateFields().then((values: TFormValues) => {
            setDisabled(true);
            const { scriptName } = values;
            let language: ScriptNodeLanguageEnum;

            if (scriptName.endsWith(GROOVY_DEBUG_POSTFIX)) {
                language = GROOVY_DEBUG;
            } else if (scriptName.endsWith(KOTLIN_DEBUG_POSTFIX)) {
                language = KOTLIN_DEBUG;
            } else {
                language = lang;
            }

            dispatch(
                scriptCreate({
                    nodeId: undefined!,
                    type: TreeItemType.Script,
                    name: scriptName,
                    parentNodeId: parentId,
                    serverId: parentId.serverId,
                    language,
                } as IScriptNode),
            );
        });
    };

    const handleChange = (values: TFormValues) => {
        const isInputFilled = Boolean(values.scriptName.trim());
        setDisabled(!isInputFilled);
    };

    const children = (
        <Form form={form} onValuesChange={handleChange} layout='vertical'>
            <Form.Item
                label={<FormattedMessage {...messages.scriptNameLabel} />}
                name="scriptName"
                rules={[
                    {
                        whitespace: true,
                        required: true,
                        message: intl.formatMessage(messages.scriptNameRequiredError),
                    },
                ]}
            >
                <Input data-test="new-skript-dialog_name" placeholder={intl.formatMessage(messages.scriptNamePlaceholder)} />
            </Form.Item>
            <Radio.Group value={lang} className={theme.radio} onChange={handleChangeLang}>
                <Radio data-test="new-skript-dialog_radio-groovy" value={GROOVY}>Groovy</Radio>
                <Radio data-test="new-skript-dialog_radio-kotlin" value={KOTLIN}>Kotlin</Radio>
                <Radio data-test="new-skript-dialog_radio-js" value={JS}>JavaScript</Radio>
            </Radio.Group>
        </Form>
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={() => dispatch(closeDialog(DialogType.SCRIPT_CREATE_DIALOG))}
            title={intl.formatMessage(messages.scriptFormName)}
            visible
            width={DEFAULT_DIALOG_WIDTH}
            okText={intl.formatMessage(messages.scriptFormConfirmButton)}
            cancelText={intl.formatMessage(messages.scriptFormDeclineButton)}
            okButtonProps={{ disabled }}
        >
            {children}
        </Dialog>
    );
};

const ScriptDialogWithIntl = injectIntl(ScriptDialog);

export { ScriptDialogWithIntl as ScriptDialog };
