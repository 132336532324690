import type { TAttributeOverlay } from '../../mxgraph/mxgraph.types';
import type { Locale } from '../../modules/Header/components/Header/header.types';
import type {
    AttributeType,
    AttributeTypeStyle,
    AttributeTypeStyleRule,
    AttributeValue,
    AttributeValueMultiSelect,
} from '../../serverapi/api';
import { storageValueToString, trimStringValue } from '../../modules/ObjectPropertiesDialog/components/utils';
import { isEqual } from 'lodash';
import {
    AttributeValueType,
    RuleType,
} from '../../modules/FloatingAttributes/components/AttributesEditor/Attribute.types';
import {
    isDateAfter,
    isDateBefore,
    isDateBetween,
    isDatetimeBetween,
    isTimeAfter,
    isTimeBefore,
} from '../../utils/date.time.utils';
import BPMMxCellOverlay from '../../mxgraph/overlays/BPMMxCellOverlay';

export function getOverlayConfigFromAttribute(
    attribute: AttributeTypeStyle,
    attributeValue: AttributeValue,
    hasNotValue: string = '',
) {
    const key = BPMMxCellOverlay.key(attribute.v, attribute.h);

    return {
        [key]: {
            ...attribute,
            attributeValue,
            hasNotValue,
        },
    };
}

const checkPeriodBetweenRule = (rule: AttributeTypeStyleRule, attributeValue: AttributeValue, notBetween?: boolean) => {
    try {
        const value = JSON.parse(attributeValue.value);
        const result =
            rule.param &&
            rule.param2 &&
            isDateBetween(value.from, rule.param, rule.param2) &&
            isDateBetween(value.to, rule.param, rule.param2);

        return notBetween ? !result : result;
    } catch (e) {
        console.error(`Failed parse attribute value ${attributeValue.value}, value type - PERIOD`);

        return false;
    }
};

export class OverlayStyle {
    protected attributeOverlay: AttributeTypeStyle;
    protected attributeValue: AttributeValue;
    protected stringValue: string;

    [RuleType.HAS_VALUE] = ({ stringValue }) => !!stringValue;
    [RuleType.HAS_NOT_VALUE] = ({ stringValue }) => !stringValue;
    [RuleType.EQUALS] = ({ rule, attributeValue, stringValue }) => stringValue === rule.param;
    [RuleType.NOT_EQUALS] = ({ rule, attributeValue, stringValue }) => stringValue !== rule.param;
    [RuleType.GREATER] = ({ rule, attributeValue }) => Number(attributeValue.value) > Number(rule.param);
    [RuleType.GREATER_OR_EQUAL] = ({ rule, attributeValue }) => Number(attributeValue.value) >= Number(rule.param);
    [RuleType.LESS] = ({ rule, attributeValue }) => Number(attributeValue.value) < Number(rule.param);
    [RuleType.LESS_OR_EQUAL] = ({ rule, attributeValue }) => Number(attributeValue.value) <= Number(rule.param);
    [RuleType.CONTAINS] = ({ rule, stringValue }) => rule.param && stringValue.includes(rule.param);
    [RuleType.NOT_CONTAINS] = ({ rule, stringValue }) => !(rule.param && stringValue.includes(rule.param));
    [RuleType.STARTS_WITH] = ({ rule, stringValue }) => rule.param && stringValue.startsWith(rule.param);
    [RuleType.ENDS_WITH] = ({ rule, stringValue }) => rule.param && stringValue.endsWith(rule.param);

    constructor({ attributeOverlay, attributeValue, stringValue }) {
        this.attributeOverlay = attributeOverlay;
        this.attributeValue = attributeValue;
        this.stringValue = stringValue;
    }

    private checkRule(rule: AttributeTypeStyleRule, attributeValue: AttributeValue, stringValue: string) {
        const { type } = rule;

        return type && this[type]?.({ rule, attributeValue, stringValue });
    }

    public isVisible(): boolean {
        const rules = this.attributeOverlay?.rules || [];

        return !rules.some((rule) => !this.checkRule(rule, this.attributeValue, this.stringValue));
    }

    public getConfig(): Record<string, TAttributeOverlay> {
        let hasNotValue = '';

        if (this.attributeOverlay.rules) {
            this.attributeOverlay.rules.forEach((rule) => {
                if (rule.type === RuleType.HAS_NOT_VALUE && rule.param && !rule.param2) hasNotValue = rule.param;
                if (rule.type === RuleType.HAS_NOT_VALUE && rule.param && rule.param2)
                    hasNotValue = `${rule.param} - ${rule.param2}`;
            });
        }

        return getOverlayConfigFromAttribute(this.attributeOverlay, this.attributeValue, hasNotValue);
    }
}

export class NumericOverlayStyle extends OverlayStyle {
    [RuleType.EQUALS] = ({ rule, stringValue }) => Number(stringValue) === Number(rule.param);
    [RuleType.NOT_EQUALS] = ({ rule, stringValue }) => Number(stringValue) !== Number(rule.param);
}

export class SelectOverlayStyle extends OverlayStyle {
    [RuleType.EQUALS] = ({ rule, attributeValue }) => attributeValue.value === rule.param;
    [RuleType.NOT_EQUALS] = ({ rule, attributeValue }) => attributeValue.value !== rule.param;
}

export class MultiSelectOverlayStyle extends OverlayStyle {
    [RuleType.EQUALS] = ({ rule, attributeValue }) =>
        isEqual((attributeValue as AttributeValueMultiSelect).valueIds?.sort(), rule.param?.split(',').sort());

    [RuleType.NOT_EQUALS] = ({ rule, attributeValue }) =>
        !isEqual((attributeValue as AttributeValueMultiSelect).valueIds?.sort(), rule.param?.split(',').sort());

    [RuleType.CONTAINS] = ({ rule, stringValue }) =>
        stringValue.split(',').some((str) => rule.param && trimStringValue(str).includes(rule.param));

    [RuleType.NOT_CONTAINS] = ({ rule, stringValue }) =>
        !stringValue.split(',').some((str) => rule.param && trimStringValue(str).includes(rule.param));

    [RuleType.STARTS_WITH] = ({ rule, stringValue }) =>
        stringValue.split(',').some((str) => rule.param && trimStringValue(str).startsWith(rule.param));

    [RuleType.ENDS_WITH] = ({ rule, stringValue }) =>
        stringValue.split(',').some((str) => rule.param && trimStringValue(str).endsWith(rule.param));
}

export class TimeOverlayStyle extends OverlayStyle {
    [RuleType.GREATER] = ({ rule, attributeValue }) => isTimeAfter(attributeValue.value, rule.param || '');
    [RuleType.LESS] = ({ rule, attributeValue }) => isTimeBefore(attributeValue.value, rule.param || '');
}

export class PeriodOverlayStyle extends OverlayStyle {
    [RuleType.BETWEEN] = ({ rule, attributeValue }) => checkPeriodBetweenRule(rule, attributeValue);
    [RuleType.NOT_BETWEEN] = ({ rule, attributeValue }) => checkPeriodBetweenRule(rule, attributeValue, true);
}

export class DateOverlayStyle extends OverlayStyle {
    [RuleType.GREATER] = ({ rule, attributeValue }) => isDateAfter(attributeValue.value, rule.param || '');
    [RuleType.LESS] = ({ rule, attributeValue }) => isDateBefore(attributeValue.value, rule.param || '');
    [RuleType.BETWEEN] = ({ rule, attributeValue }) => isDateBetween(attributeValue.value, rule.param, rule.param2);
    [RuleType.NOT_BETWEEN] = ({ rule, attributeValue }) =>
        !isDateBetween(attributeValue.value, rule.param, rule.param2);
}

export class DateTimeOverlayStyle extends OverlayStyle {
    [RuleType.GREATER] = ({ rule, attributeValue }) => isTimeAfter(attributeValue.value, rule.param || '');
    [RuleType.LESS] = ({ rule, attributeValue }) => isTimeBefore(attributeValue.value, rule.param || '');
    [RuleType.BETWEEN] = ({ rule, attributeValue }) => isDatetimeBetween(attributeValue.value, rule.param, rule.param2);
    [RuleType.NOT_BETWEEN] = ({ rule, attributeValue }) =>
        !isDatetimeBetween(attributeValue.value, rule.param, rule.param2);
}

const ValueTypeToClassesMap = {
    [AttributeValueType.NUMERIC]: NumericOverlayStyle,
    [AttributeValueType.STRING]: OverlayStyle,
    [AttributeValueType.MULTI_STRING]: OverlayStyle,
    [AttributeValueType.SELECT]: SelectOverlayStyle,
    [AttributeValueType.MULTI_SELECT]: MultiSelectOverlayStyle,
    [AttributeValueType.DATE]: DateOverlayStyle,
    [AttributeValueType.DATE_TIME]: DateTimeOverlayStyle,
    [AttributeValueType.PERIOD]: PeriodOverlayStyle,
    [AttributeValueType.TIME]: TimeOverlayStyle,
};

export const createOverlayFormat = (
    attributeOverlay: AttributeTypeStyle,
    attributeValue: AttributeValue,
    attributeTypes: AttributeType[],
    currentLocale: Locale,
): OverlayStyle => {
    const stringValue = storageValueToString(attributeValue, currentLocale, { attributeTypes });
    const OverlayClass = ValueTypeToClassesMap[attributeValue.valueType] || OverlayStyle;

    return new OverlayClass({ attributeOverlay, attributeValue, stringValue, attributeTypes });
};
