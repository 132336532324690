// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EdgeManagementDialog__dialog__F1YnD .ant-modal-footer{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:reverse;-ms-flex-direction:row-reverse;flex-direction:row-reverse;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;grid-column-gap:8px;-webkit-column-gap:8px;-moz-column-gap:8px;column-gap:8px}", "",{"version":3,"sources":["webpack://./src/modules/Models/components/EdgeManagement/EdgeManagementDialog.scss"],"names":[],"mappings":"AAEQ,uDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,6BAAA,CAAA,8BAAA,CAAA,0BAAA,CACA,sBAAA,CAAA,mBAAA,CAAA,0BAAA,CACA,mBAAA,CAAA,sBAAA,CAAA,mBAAA,CAAA,cAAA","sourcesContent":[".dialog {\r\n    :global {\r\n        .ant-modal-footer {\r\n            display: flex;\r\n            flex-direction: row-reverse;\r\n            justify-content: flex-start;\r\n            column-gap: 8px;\r\n        }\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "EdgeManagementDialog__dialog__F1YnD"
};
export default ___CSS_LOADER_EXPORT___;
