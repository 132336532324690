import { EdgeType } from '../serverapi/api';

export type TEdgeTypeSelectorState = {
    availableTypes: EdgeType[];
    currentTypeIndex: number;
};

export const initialEdgeTypeSelectorState: TEdgeTypeSelectorState = {
    availableTypes: [],
    currentTypeIndex: -1,
};
