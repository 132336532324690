import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import {
    ADD_PRESET_PROFILE,
    DELETE_PRESET_PROFILE,
    EDIT_PRESET_PROFILE,
    GET_PRESET_PROFILES_SUCCESS,
} from '../actionsTypes/presetProfile.actionTypes';
import { CustomMap } from '../utils/map';
import { TReducer } from '../utils/types';
import { TPresetProfilesState } from './presetProfile.reducer.types';

const initial: TPresetProfilesState = new CustomMap();

export const presetProfileReducer: TReducer<TPresetProfilesState> = (state = initial, action) => {
    switch (action.type) {

        case GET_PRESET_PROFILES_SUCCESS: {
            const { presetId, serverId, presetProfiles } = action.payload;
            const clonState = state.clone();
            clonState.set(
                { presetId, serverId },
                {
                    presetProfiles,
                },
            );

            return clonState;
        }

        case ADD_PRESET_PROFILE: {
            const { presetId, serverId, presetProfile } = action.payload;
            const clonState = state.clone();
            const data = clonState.get({ presetId, serverId });
            const newProfiles = [...data?.presetProfiles || [], presetProfile];

            clonState.set(
                { presetId, serverId },
                {
                    presetProfiles: newProfiles,
                },
            );

            return clonState;
        }

        case DELETE_PRESET_PROFILE: {
            const { presetId, serverId, id } = action.payload;
            const clonState = state.clone();
            const data = clonState.get({ presetId, serverId });
            const newProfiles = data.presetProfiles.filter((presetProfile) => presetProfile.id !== id);

            clonState.set(
                { presetId, serverId },
                {
                    presetProfiles: newProfiles,
                },
            );

            return clonState;
        }

        case EDIT_PRESET_PROFILE: {
            const { presetId, serverId, presetProfile } = action.payload;
            const clonState = state.clone();
            const { presetProfiles } = clonState.get({ presetId, serverId });
            const newProfiles = presetProfiles.map((p) => (p.id === presetProfile.id ? presetProfile : p));

            clonState.set(
                { presetId, serverId },
                {
                    presetProfiles: newProfiles,
                },
            );

            return clonState;
        }

        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            const mapClone = state.clone();
            mapClone.deleteByPartKey({ serverId, presetId });

            return mapClone;
        }

        default:
            return state;
    }
};
