import { put, takeEvery, select, call } from 'redux-saga/effects';
import { OPEN_SAFETY_TAB } from '../actionsTypes/safety.actionTypes';
import { TOpenSafetyTabAction } from '../actions/safety.actions.types';
import { workspaceAddTab } from '../actions/tabs.actions';
import { fetchAuditSuccess } from '../actions/audit.actions';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { isNullOrUndefined } from 'is-what';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTab, TWorkspaceTabItemDefaultParams } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '../models/editorMode';
import { AuditDto } from '../serverapi/api';
import { AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';
import { admintoolSelect } from '../actions/admintools.actions';
import { isUserHasAccesse } from '../selectors/authorization.selectors';

function* handleOpenSafetyTab({ payload: { node } }: TOpenSafetyTabAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(node.nodeId.serverId));

    if (isNullOrUndefined(server)) {
        throw new Error(`Cannot find server with ID=${node.nodeId.serverId}`);
    }

    if (node) {
        const isLogReaderAccessEnable = yield select(isUserHasAccesse('LOG_READER'));
        const hasServerAuditEnable = yield select(isUserHasAccesse('SERVER_AUDIT'));
        const isAuditAccessible = isLogReaderAccessEnable || hasServerAuditEnable;

        const audit: AuditDto[] | null = isAuditAccessible ? yield call(() => server.api.audit.audit({})) : null;

        const safetySettingsTab: TWorkspaceTab = {
            title: node.name,
            nodeId: node.nodeId,
            type: WorkSpaceTabTypes.SAFETY,
            mode: EditorMode.Read,
            params: {} as TWorkspaceTabItemDefaultParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };

        if (!isNullOrUndefined(audit)) {
            yield put(fetchAuditSuccess(node.nodeId.serverId, audit));
            yield put(
                admintoolSelect({ currentServerId: node.nodeId.serverId, activeTypeId: AdminToolTreeType.SAFETY }),
            );
        }

        yield put(workspaceAddTab(safetySettingsTab));
    }
}

export function* safetySaga() {
    yield takeEvery(OPEN_SAFETY_TAB, handleOpenSafetyTab);
}
