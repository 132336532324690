import {TReducer} from '../utils/types';
import {CANCEL_PRESET_EDIT} from '../actionsTypes/methodologySetting.actionTypes';
import {
    DELETE_MODEL_TYPE_GROUP,
    DELETE_MODEL_TYPE_GROUP_SUCCESS
} from '../actionsTypes/modelType.actionTypes';
import { TDeletedModelTypeGroupState } from './deletedModelTypeGroup.reducer.types';

const initial: TDeletedModelTypeGroupState = {
    byServerId: {},
};

export const deletedModelTypeGroupReducer: TReducer<TDeletedModelTypeGroupState> = (state = initial, action) => {
    const cloneState = (serverId: string) => {
        return {
            byServerId: {
                ...state.byServerId,
                [serverId]: {
                    byPresetId: {
                        ...(state.byServerId[serverId]?.byPresetId || {})
                    }
                }
            }
        };
    };

    switch (action.type) {
        case DELETE_MODEL_TYPE_GROUP_SUCCESS: {
            const {
                modelTypeGroups,
                serverNode: {nodeId: {serverId}}
            } = action.payload;
            if (modelTypeGroups) {
                const stateClone = cloneState(serverId);
                modelTypeGroups.forEach((modelTypeGroup) => {
                    const presetId = modelTypeGroup.presetId;
                    if (serverId && presetId) {
                        const byPresetId = stateClone.byServerId[serverId]?.byPresetId;
                        byPresetId[presetId] = byPresetId[presetId]?.filter(otg => otg.id != modelTypeGroup.id);
                    }
                });
                return stateClone;
            }
            return state;
        }
        case DELETE_MODEL_TYPE_GROUP: {
            const {
                serverNode: {nodeId: {serverId}}, modelTypeGroups
            } = action.payload;
            const stateClone = cloneState(serverId);
            const byServerIdElement = stateClone.byServerId[serverId];
            if (!byServerIdElement) {
                stateClone.byServerId[serverId] = {byPresetId: {}}
            }
            const byPresetId = stateClone.byServerId[serverId].byPresetId;
            modelTypeGroups.forEach(etg => {
                if (!byPresetId[etg.presetId]) {
                    byPresetId[etg.presetId] = [];
                }
                byPresetId[etg.presetId].push(etg);
            })
            return stateClone
        }
        case CANCEL_PRESET_EDIT: {
            const {
                preset,
                serverNode: {
                    nodeId: {serverId},
                },
            } = action.payload;
            if (serverId && preset?.id) {
                const stateClone = cloneState(serverId);
                delete stateClone.byServerId[serverId]?.byPresetId[preset?.id];
                return stateClone;
            }
            return state;
        }
        default:
            return state;
    }
};
