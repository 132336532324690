import { debounce, isEqual } from 'lodash';
import { EventEmitter } from 'fbemitter';

class AppData {
    public events: EventEmitter = new EventEmitter();
    public sizes: { width: number; height: number } = { width: 0, height: 0 };

    private debounceResize: Function;

    constructor() {
        this.debounceResize = debounce(() => this.onResize(), 20);
        window.addEventListener('resize', () => this.debounceResize());
        this.debounceResize();
    }

    onResize() {
        const newSizes = {
            width: window.innerWidth,
            height: window.innerHeight,
        };

        if (!isEqual(newSizes, this.sizes)) {
            this.sizes = newSizes;
            this.events.emit('resize', this.sizes);
        }
    }
}

const appData = new AppData();

export { appData };
