import { Form, FormInstance, Select } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import presetsCss from '../../Presets.scss';
import theme from './PresetProfileGeneralSettings.scss';
import messages from './PresetProfileGeneralSettings.messages';
import { InternationalString, PresetProfile } from '../../../../../../../serverapi/api';
import { useSelector } from 'react-redux';
import { clone } from 'lodash';
import { ServerProfileSelectors } from '../../../../../../../selectors/serverProfile.selectors';
import { PresetProfileSelectors } from '../../../../../../../selectors/presetSettings/presetProfile.selectors';
import { MultiLangInputDialog } from '../../../../../../MultiLangInputDialog/MultiLangInputDialog.component';

type TPresetProfileEditGeneralSettings = {
    presetProfile: PresetProfile;
    serverId: string;
    presetId: string;
    generalForm: FormInstance<any> | undefined;
    hideServerProfileSetter?: boolean;
    setPresetProfile: (presetProfile: PresetProfile) => void;
};

const PresetProfileEditGeneralSettings: FC<TPresetProfileEditGeneralSettings> = (props) => {
    const { presetProfile, serverId, presetId, generalForm, hideServerProfileSetter, setPresetProfile } = props;
    const intl = useIntl();
    const { serverProfiles } = useSelector(ServerProfileSelectors.byServerId(serverId));
    const presetProfiles = useSelector(PresetProfileSelectors.byPresetId({ presetId, serverId }))?.presetProfiles || [];
    const serverProfilesForDisplay = serverProfiles.filter((sProfile) => {
        const currentTakenServerProfile = sProfile.id === presetProfile.serverProfileId;
        if (currentTakenServerProfile) {
            return true;
        }

        const isOccupiedPresetProfile = presetProfiles.some((pProfile) => pProfile.serverProfileId === sProfile.id);

        return !isOccupiedPresetProfile;
    });

    const selectServerProfile = (serverProfileId: string) => {
        if (!serverProfileId) {
            const newPresetProfile = clone(presetProfile);
            delete newPresetProfile.serverProfileId;
            setPresetProfile(newPresetProfile);
        } else {
            setPresetProfile({ ...presetProfile, serverProfileId });
        }
    };

    const onChangeName = (value: InternationalString) => {
        setPresetProfile({ ...presetProfile, multilingualName: value });
    };

    const onChangeDescription = (value: InternationalString) => {
        setPresetProfile({ ...presetProfile, multilingualDescription: value });
    };

    return (
        <div className={presetsCss.container}>
            <div className={presetsCss.form}>
                <Form layout="vertical" form={generalForm}>
                    <MultiLangInputDialog
                        placeholder={intl.formatMessage(messages.newProfile)}
                        multiLangValue={presetProfile.multilingualName}
                        onChange={onChangeName}
                        required
                        mainInputName="multilingualName"
                        generalForm={generalForm}
                        label={intl.formatMessage(messages.name)}
                        data-test="access-rights-profile-name-input"
                    />
                    <div className={presetsCss.description}>
                        <MultiLangInputDialog
                            placeholder={intl.formatMessage(messages.description)}
                            multiLangValue={presetProfile.multilingualDescription}
                            onChange={onChangeDescription}
                            textarea
                            mainInputName="multilingualDescription"
                            generalForm={generalForm}
                            label={intl.formatMessage(messages.description)}
                            data-test="access-rights-profile-description-input"
                        />
                    </div>
                    {!hideServerProfileSetter && (
                        <Form.Item
                            label={intl.formatMessage(messages.correspondingServerProfile)}
                            className={theme.serverProfileSelect}
                        >
                            <Select
                                onChange={selectServerProfile}
                                defaultValue={presetProfile.serverProfileId || ''}
                                data-test="access-rights-profile-correlate-edit"
                            >
                                <Select.Option key="empty" value="">
                                    {intl.formatMessage(messages.withoutPresetProfile)}
                                </Select.Option>

                                {serverProfilesForDisplay.map((sProfile) => (
                                    <Select.Option key={sProfile.id} value={sProfile.id}>
                                        {sProfile.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </Form>
            </div>
        </div>
    );
};

export default PresetProfileEditGeneralSettings;
