import React, { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Form, FormInstance, Select } from 'antd';
import presetCss from '../Presets.scss';
import css from './ObjectType.scss';
import messages from './ObjectType.messages';
import { InternationalString, ObjectType, ObjectTypeGroup } from '../../../../../../serverapi/api';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { MultiLangInputDialog } from '../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../services/LocalesService';
import { InputId } from '../../../../../InputId/InputId.component';
import { InputSynonymsIds } from '../../../../../InputSynonymsIds/InputSynonymsIds.component';

type TGeneralTabProps = {
    objectType: ObjectType;
    objectTypeGroups: ObjectTypeGroup[];
    createMode: boolean;
    generalForm: FormInstance<any> | undefined;
    onChangeObjectTypeName: (multilingualName: InternationalString) => void;
    onChangeObjectTypeId: (id: string) => void;
    onChangeObjectTypeDescription: (multilingualDescription: InternationalString) => void;
    onChangeObjectTypeGroup: (objectTypeGroup: ObjectTypeGroup) => void;
    onChangeHideInNavigator: (hideInNavigator: boolean) => void;
    onChangePreventMultipleInstances: (preventMultipleInstances: boolean) => void;
    onChangeObjectTypeSynonymsIds: (e: ChangeEvent<HTMLInputElement>) => void
    onChangeObjectAllowApprovals: (allowApprovals: boolean) => void
};

export const GeneralTab: FC<TGeneralTabProps> = (props) => {
    const currentLocale = useSelector(getCurrentLocale);
    const {  createMode, objectType, generalForm, objectTypeGroups, onChangeObjectTypeId, onChangeObjectTypeSynonymsIds, onChangeObjectAllowApprovals } = props;
    const intl = useIntl();

    const onChangeObjectTypeGroup = (groupId: string) => {
        const changeGroup = objectTypeGroups.find((otg) => otg.id === groupId);
        if (changeGroup) {
            props.onChangeObjectTypeGroup(changeGroup);
        }
    };

    return (
        <div className={presetCss.form}>
            <div className={css.input}>
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.name)}
                    multiLangValue={objectType.multilingualName}
                    onChange={props.onChangeObjectTypeName}
                    label={intl.formatMessage(messages.name)}
                    mainInputName="multilingualName"
                    generalForm={generalForm}
                    required
                    autoFocus
                    data-test="object-type-name-input"
                />
            </div>
            <div className={presetCss.guidInput}>
                <InputId
                    disabled={!createMode}
                    value={objectType.id}
                    required
                    mainInputName="GUID"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChangeObjectTypeId(e.target.value.trim());
                    }} />
            </div>
            <div className={presetCss.selectGroupType}>
                <Form.Item
                    label={intl.formatMessage(messages.objectTypeGroups)}
                    name="edgeTypeGroup"
                    initialValue={objectType.objectTypeGroup?.id}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select data-test="object-type-group-select"
                        className={presetCss.selectGroupType}
                        onChange={onChangeObjectTypeGroup}
                        value={objectType.objectTypeGroup?.id}
                    >
                        {objectTypeGroups.map((group: ObjectTypeGroup) => {
                            return (
                                <Select.Option key={group.id} value={group.id}>
                                    {LocalesService.internationalStringToString(group.multilingualName, currentLocale)}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </div>
            <div className={css.input}>
                <InputSynonymsIds
                    value={objectType.synonymsIds}
                    mainInputName="synonymsIds"
                    onChange={onChangeObjectTypeSynonymsIds}
                />
            </div>
            <div className={presetCss.description}>
                <MultiLangInputDialog
                    placeholder={intl.formatMessage(messages.description)}
                    multiLangValue={objectType.multilingualDescription}
                    onChange={props.onChangeObjectTypeDescription}
                    textarea
                    label={intl.formatMessage(messages.description)}
                    mainInputName="multilingualDescription"
                    generalForm={generalForm}
                    data-test="object-type-description-input"
                />
            </div>
            <div className={css.hideInNavigator}>
                <Checkbox
                    onChange={(event: CheckboxChangeEvent) => {
                        props.onChangeHideInNavigator(event.target.checked);
                    }}
                    checked={objectType.hideInNavigator}
                >
                    <span>{intl.formatMessage(messages.hideInNavigator)}</span>
                </Checkbox>
            </div>
            <div className={css.checkBoxContainer}>
                <Checkbox
                    checked={objectType?.allowApprovals}
                    onChange={(event: CheckboxChangeEvent) => onChangeObjectAllowApprovals(event.target.checked)}
                >
                    {intl.formatMessage(messages.alowObjectTypeApprovals)}
                </Checkbox>
            </div>
            {/*
            <div>
                <Checkbox
                    onChange={(event: CheckboxChangeEvent) => {
                        props.onChangePreventMultipleInstances(event.target.checked);
                    }}
                    checked={objectType.preventMultipleInstances}
                >
                    <span>{intl.formatMessage(messages.preventMultipleInstances)}</span>
                </Checkbox>
            </div>
            */}
        </div>
    );
};
