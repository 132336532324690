// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SymbolTypeSelectDialog__selectSymbolType__W9LQt{width:100%}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ObjectType/Dialog/SymbolTypeSelectDialog.scss"],"names":[],"mappings":"AAAA,iDACI,UAAA","sourcesContent":[".selectSymbolType {\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectSymbolType": "SymbolTypeSelectDialog__selectSymbolType__W9LQt"
};
export default ___CSS_LOADER_EXPORT___;
