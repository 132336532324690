import React, { FC, useCallback } from 'react';
import { Button, Layout, Popover } from 'antd';
import theme from './Header.scss';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import iconUndo from '../../../../resources/icons/ic-app-undo.svg';
import iconRedo from '../../../../resources/icons/ic-app-redo.svg';
import classnames from 'classnames';
import { Logo } from '../Logo/Logo.component';
import { THeaderProps } from './header.types';
import iLoc from '../../../../resources/icons/localization.svg';
import { ArrowsAltOutlined, CloseOutlined, MinusOutlined, ShrinkOutlined } from '@ant-design/icons';
import { LocalesService } from '../../../../services/LocalesService';
import { LocaleSelect } from '../LocaleSelect/LocaleSelect.component';
import { HEADER_CONTAINER_ID } from '../../../../mxgraph/components/BPMPopupMenu.types';
import { MainMenu } from '../../../MainMenu/components/MainMenu/MainMenu.component';

const { Header: ANTDHeader } = Layout;
const { Group: ButtonGroup } = Button;

export const Header: FC<THeaderProps> = (props) => {
    const {
        onClose,
        onToggleSize,
        onMinimize,
        isMaximized,
        activeKey,
        onUndoAction,
        onRedoAction,
        isVisibleMainMenu = true,
        isVisibleButtonActions,
        onLocaleSelect,
        locale,
        isStarted,
        redoEnabled,
        undoEnabled,
        isEnLocaleAllowed
    } = props;

    const onUndo = useCallback(() => {
        if (activeKey) onUndoAction(activeKey);
    }, [activeKey, onUndoAction]);

    const onRedo = useCallback(() => {
        if (activeKey) onRedoAction(activeKey);
    }, [activeKey, onRedoAction]);

    return (
        <div className={theme.container} id={HEADER_CONTAINER_ID}>
            <ANTDHeader>
                <Logo isStarted={isStarted} />
                {!isStarted && (
                    <div className={theme.headerItems}>
                        <Popover
                            content={<LocaleSelect locale={locale} onLocaleSelect={onLocaleSelect} isEnLocaleAllowed={isEnLocaleAllowed} />}
                            trigger="click"
                        >
                            <div data-test="locale-switch-button" className={theme.localization}>
                                <Icon className={theme.titleContentIcon} spriteSymbol={iLoc} />
                                <div>{LocalesService.convertToString(locale)}</div>
                            </div>
                        </Popover>
                    </div>
                )}
                {isVisibleMainMenu && (
                    <div className={theme.actions}>
                        <div className={theme.buttonsAction}>
                            <Button
                                data-test="header_action-buttons-undo"
                                className={classnames(theme.headButton, theme.buttonsActionItem, {
                                    [theme.undoRedoAvailable]: undoEnabled,
                                })}
                                ghost
                                disabled={!undoEnabled}
                                onClick={onUndo}
                            >
                                <Icon spriteSymbol={iconUndo} className={theme.iconUndo} />
                            </Button>
                            <Button
                                data-test="header_action-buttons-redo"
                                className={classnames(theme.headButton, theme.buttonsActionItem, {
                                    [theme.undoRedoAvailable]: redoEnabled,
                                })}
                                ghost
                                disabled={!redoEnabled}
                                onClick={onRedo}
                            >
                                <Icon spriteSymbol={iconRedo} className={theme.iconRedo} />
                            </Button>
                        </div>
                    </div>
                )}
                {isVisibleMainMenu && <MainMenu/>}
                {isVisibleButtonActions && (
                    <ButtonGroup size="small" className={theme.controls}>
                        <Button ghost onClick={onMinimize} icon={<MinusOutlined />} />
                        <Button
                            ghost
                            onClick={onToggleSize}
                            icon={isMaximized ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
                        />
                        <Button ghost onClick={onClose} icon={<CloseOutlined />} />
                    </ButtonGroup>
                )}
            </ANTDHeader>
        </div>
    );
};
