import { Button } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import theme from '../ApprovalTab/approvalTab.scss';
import messages from '../messages/approval.messages';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { deleteApprovals } from '../../../actions/approval.actions';
import { NodeId } from '../../../serverapi/api';
import { ApprovalSelectors } from '../../../selectors/approval.selectors';
import { IconDeleteBin } from '../../UIKit/components/Icons/IconDeleteBin';

export const DeleteButton = (): JSX.Element => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const checkedNodeIds: NodeId[] = useSelector(ApprovalSelectors.getCheckedApprovalIds);
    const isDisabled: boolean = !checkedNodeIds.length;
    const handleClick = () => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    dispatch(
                        deleteApprovals({
                            approvalNodeIds: checkedNodeIds,
                        }),
                    );
                },
                title: intl.formatMessage(messages.deletingApproval),
                question: intl.formatMessage(messages.deletingApprovalDescription),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    return (
        <div className={theme.buttonContainer}>
            <Button className={theme.iconContainer} onClick={handleClick} disabled={isDisabled}>
                <IconDeleteBin className={isDisabled ? theme.disabledIconContainer : theme.activeIconContainer} />
                <span>{intl.formatMessage(messages.delete)}</span>
            </Button>
        </div>
    );
};
