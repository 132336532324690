import { Alert, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TPreset } from '../../../../models/preset.types';
import icCopy from '../../../../resources/icons/copy.svg';
import icExport from '../../../../resources/icons/ic-export.svg';
import icExportExcel from '../../../../resources/icons/file-excel.svg';
import icPrimary from '../../../../resources/icons/ic-home-blue.svg';
import icMakePrimary from '../../../../resources/icons/ic-home-gray.svg';
import { DefaultId, InternationalString, RepositoryNode } from '../../../../serverapi/api';
import { DeleteButton, EditButton, RowButtons } from '../../Button/RowButtons';
import messages from '../messages/MethodologySetting.messages';
import theme from './MethodologySetting.scss';
import { TabHeader } from './Presets/Header/TabHeader.component';
import { containsIgnoreCase } from './Presets/util/preset.utils';
import { getCurrentLocale } from '../../../../selectors/locale.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { PresetSelectors } from '../../../../selectors/preset.selectors';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import {
    copyPreset,
    editPresetRequest,
    exportPreset,
    makeDefault,
    openAddPreset,
    deletePresetAction,
    clearPresetRequest,
    exportExcelPreset,
} from '../../../../actions/methodologySetting.actions';
import { showNotificationByType } from '../../../../actions/notification.actions';
import { NotificationType } from '../../../../models/notificationType';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { isEmpty } from 'lodash';
import { IWorkspaceTabItemMethodologyParams, TWorkspaceTab } from '../../../../models/tab.types';
import { TPresetId } from './Presets/TPresetId.types';
import { LocalesService } from '../../../../services/LocalesService';

type TMethodologySettingProps = {
    tab: TWorkspaceTab;
};

export const MethodologySetting = (props: TMethodologySettingProps) => {
    const params = props.tab.params as IWorkspaceTabItemMethodologyParams;
    const { serverNode } = params;
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();
    const loading: boolean = useSelector(PresetSelectors.loading());
    const presets: TPreset[] = useSelector(PresetSelectors.presetArrByServerId(serverNode.nodeId.serverId));
    const [presetFilter, setPresetFilter] = useState<string>('');
    const [primePresetId, setPrimePresetId] = useState<string | undefined>(
        presets.find((p) => p.prime)?.id || DefaultId.DEFAULT_PRESET_ID,
    );
    const container = classnames(theme.container, loading ? theme.displayWaitScreen : '');
    const openedTabs: (TWorkspaceTab & TPresetId)[] = useSelector(TabsSelectors.getTabList);

    const dispatch = useDispatch();
    const onImport = () =>
        dispatch(
            openDialog(DialogType.UPLOAD_PRESET_DIALOG, {
                serverNode,
                filters: '.xml',
            }),
        );
    const onRemove = (editablePreset: TPreset) => {
        const isOpenTab = !isEmpty(openedTabs.filter((tab) => editablePreset.id === tab.presetId));

        if (isOpenTab) {
            dispatch(showNotificationByType(NotificationType.METHODOLOGY_IS_OPENED));
        } else {
            dispatch(deletePresetAction(serverNode, editablePreset));
            if (editablePreset.id) {
                dispatch(clearPresetRequest({ serverId: serverNode.nodeId.serverId, presetId: editablePreset.id }));
            }
        }
    };
    const addPreset = () => dispatch(openAddPreset(serverNode));
    const editPreset = (preset: TPreset) => dispatch(editPresetRequest(preset, serverNode, props.tab.nodeId));
    const onClickCopyPreset = (preset: TPreset) => dispatch(copyPreset(preset, serverNode));
    const onClickMakeDefault = (preset: TPreset) => dispatch(makeDefault({ preset, serverNode }));
    const onClickExportPreset = (preset: TPreset, serverId: string) => dispatch(exportPreset(preset, serverId));
    const onClickExportExcelPreset = (preset: TPreset, serverId: string) => dispatch(exportExcelPreset(preset, serverId));

    const renderActionButtons = (repositories: RepositoryNode[] | undefined, record: TPreset) => {
        const methodologyName: string = LocalesService.internationalStringToString(record.multilingualName);
        const dbNames: string = LocalesService.internationalStringArrayToString(repositories);

        return (
            <RowButtons
                className={theme.actionButtonsDiv}
                loading={loading}
                buttons={[
                    {
                        key: `${record.id}copyPreset`,
                        spriteSymbol: icCopy,
                        tooltip: messages.copyPreset,
                        onClick: () => onClickCopyPreset(record),
                        dataTestID: 'COPY',
                    },
                    {
                        key: `${record.id}makeDefault`,
                        spriteSymbol: primePresetId === record.id ? icPrimary : icMakePrimary,
                        tooltip: messages.makeDefault,
                        onClick: () => {
                            onClickMakeDefault(record);
                            setPrimePresetId(record.id);
                        },
                        dataTestID: 'DEFAULT',
                    },
                    {
                        key: `${record.id}export`,
                        spriteSymbol: icExport,
                        tooltip: messages.export,
                        onClick: () => onClickExportPreset(record, serverNode.nodeId.serverId),
                        dataTestID: 'EXPORT',
                    },
                    {
                        key: `${record.id}export_excel`,
                        spriteSymbol: icExportExcel,
                        tooltip: messages.excelExport,
                        onClick: () => onClickExportExcelPreset(record, serverNode.nodeId.serverId),
                        dataTestID: 'EXPORT_EXCEL',
                    },
                    EditButton.build(() => editPreset(record), loading, `${record.id}edit`),
                    DeleteButton.build(
                        () => onRemove(record),
                        undefined,
                        undefined,
                        undefined,
                        intl.formatMessage(messages.deleteMethodologyDialogTitle, { methodologyName }),
                        dbNames ? (
                            <Alert
                                message={intl.formatMessage(messages.deleteMethodologyDialogContent, { dbNames })}
                                type="warning"
                            />
                        ) : (
                            ''
                        ),
                    ),
                ]}
            />
        );
    };

    const columns = [
        {
            title: intl.formatMessage(messages.methodologyName),
            dataIndex: 'multilingualName',
            render: (multilingualName: InternationalString) =>
                LocalesService.internationalStringToString(multilingualName, currentLocale),
            className: theme.namesColumn,
            width: '22%',
        },
        {
            title: intl.formatMessage(messages.db),
            dataIndex: 'repositories',
            render: (repositories: RepositoryNode[] | undefined) =>
                LocalesService.internationalStringArrayToString(repositories),
            className: theme.repositoriesColumn,
            width: '22%',
        },
        {
            title: intl.formatMessage(messages.methodologyDescription),
            dataIndex: 'description',
            className: theme.descriptionColumn,
            render: (description: string) => description,
            width: '16',
        },
        {
            title: intl.formatMessage(messages.id),
            dataIndex: 'presetId',
            render: (presetId: string, record: TPreset) => record.id,
            width: 235,
        },
        {
            title: intl.formatMessage(messages.modDate),
            dataIndex: 'updatedAt',
            render: (updatedAt: string) => {
                return updatedAt ? dayjs(new Date(updatedAt)).format('DD.MM.YYYY HH:mm:ss') : '';
            },
            className: theme.dateColumn,
            width: 125,
            sorter: (a, b) => dayjs(a.updatedAt).valueOf() - dayjs(b.updatedAt).valueOf(),
            defaultSortOrder: 'descend' as SortOrder,
        },
        {
            dataIndex: 'repositories',
            render: (repositories: RepositoryNode[] | undefined, record: TPreset) => {
                return renderActionButtons(repositories, record);
            },
            width: 125,
        },
    ];

    return (
        <div className={container} data-test='methodology-settings-tab_container'>
            <TabHeader
                buttons={[
                    {
                        name: messages.addPreset,
                        size: 'large',
                        type: 'primary',
                        onAction: () => addPreset(),
                        dataTestId: 'add-methodology_btn',
                    },
                    {
                        name: messages.importPreset,
                        size: 'large',
                        type: 'primary',
                        onAction: () => onImport(),
                        dataTestId: 'import-methodology_btn',
                    },
                ]}
                onSearchChange={(pFilter: string) => setPresetFilter(pFilter)}
                inputDataTest="search-methodology_btn"
            />

            <Table
                rowKey={(record: TPreset) => `${record.id}`}
                columns={columns}
                dataSource={presets.filter(
                    (p: TPreset) =>
                        containsIgnoreCase(p.name, presetFilter.trim()) || containsIgnoreCase(p.description, presetFilter.trim()),
                )}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
                pagination={false}
                className={theme.table}
            />
        </div>
    );
};
