import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import icClearFormat from '../../../../../../resources/icons/clear-format.svg';
import { setCellDefaultStyle } from '../../../../../../actions/editor.actions';
import { MxCell } from '../../../../../../mxgraph/mxgraph';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton.component';

interface IClearFormatButtonProps {
    disabled: boolean | undefined;
    availableSelectedCells: MxCell[];
    tooltipTitle: string;
}

export const ClearFormatButton: FC<IClearFormatButtonProps> = (props) => {
    const { disabled, availableSelectedCells, tooltipTitle } = props;
    const dispatch = useDispatch();

    const onSetCellDefaultStyleAction = () => {
        availableSelectedCells.forEach((cell) => dispatch(setCellDefaultStyle({ cell })));
    };

    return (
        <ToolbarButton
            disabled={disabled}
            icon={icClearFormat}
            tooltipTitle={tooltipTitle}
            dataTest="general-toolbar_clear-format-btn"
            onClick={onSetCellDefaultStyleAction}
        />
    );
};
