import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Button, Form } from 'antd';
import {
    FileUploadStatus,
    ParameterType,
} from '../../../../reducers/scriptExecuteDialog.reducer.types';
import { ScriptParameter } from '../../../../serverapi/api';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import messages from './ScriptExecuteDialog.messages';
import theme from './ScriptExecuteDialog.scss';
import { ScriptExecuteForm } from './ScriptExecuteForm.component';
import { TScriptExecuteDialogAllProps } from './ScriptExecuteDialog.types';

export const ScriptExecuteDialog = (props: TScriptExecuteDialogAllProps) => {
    const intl = useIntl();
    const { onClose, schedule, onSubmit, parsedParams, fileParams, nodeParams, script, existingParams } = props;
    const [form] = Form.useForm();
    const [requiredFilesNotLoaded, setRequiredFilesNotLoaded] = useState<string[]>([]);
    const [requiredNodesNotSelected, setRequiredNodesNotSelected] = useState<string[]>([]);

    const getRequiredParamsByType = (type: ParameterType): ScriptParameter[] => {
        return Object.values(parsedParams).filter((p) => p.required && p.paramType === ParameterType[type]);
    };

    const handleSubmit = () => {
        const requiredFileParams = getRequiredParamsByType(ParameterType.FILE);
        const requiredNodeParams = getRequiredParamsByType(ParameterType.NODE);

        requiredFileParams.forEach((param) => {
            const fileParam = fileParams[param.name || ''];
            if (!fileParam || fileParam.uploadStatus !== FileUploadStatus.DONE)
                setRequiredFilesNotLoaded([...requiredFilesNotLoaded, param.name!]);
        });

        requiredNodeParams.forEach((param) => {
            if (!nodeParams[param.name || '']) {
                setRequiredNodesNotSelected([...requiredNodesNotSelected, param.name!]);
            }
        });

        const scriptSchedule = form?.getFieldValue('schedule')?.toDate();
        if (form) {
            form.validateFields().then((formValues) => {
                if (!requiredFilesNotLoaded.length && !requiredNodesNotSelected.length) {
                    form.resetFields();
                    parsedParams.forEach((param) => {
                        switch (param.paramType) {
                            case ParameterType.FILE:
                                param.value = fileParams[param.name || '']?.uploadedFileName;
                                break;
                            case ParameterType.BOOLEAN:
                                param.value =
                                    formValues[param.name || '']?.toString().toLowerCase() === 'true'
                                        ? 'true'
                                        : 'false';
                                break;
                            case ParameterType.DATE:
                                const value = dayjs(formValues[param.name || ''] as string).unix();
                                param.value = value ? value.toString() : '';
                                break;
                            case ParameterType.NODE:
                                const nodeId = nodeParams[param.name || '']?.nodeId;
                                param.value = nodeId ? JSON.stringify(nodeId) : '';
                                break;
                            default:
                                param.value = (formValues[param.name || ''] || '').toString();
                                break;
                        }
                    });
                    onSubmit(script, [...existingParams, ...parsedParams], scriptSchedule);
                }
            });
        }
    };
    const footer = (
        <div className={theme.footer}>
            <Button data-test="button_script-start_cancel" onClick={() => onClose(fileParams)}>
                {intl.formatMessage(messages.scriptFormDeclineButton)}
            </Button>
            <Button data-test="button_script-start_confirm" type="primary" onClick={handleSubmit}>
                {schedule
                    ? intl.formatMessage(messages.scriptFormConfirmButtonPlan)
                    : intl.formatMessage(messages.scriptFormConfirmButtonStart)}
            </Button>
        </div>
    );

    return (
        <Dialog
            maskClosable={false}
            className={theme.dialog}
            onCancel={() => onClose(fileParams)}
            title={
                schedule ? intl.formatMessage(messages.scheduleScriptTitle) : intl.formatMessage(messages.scriptTitle)
            }
            visible
            width="640px"
            footer={footer}
            data-test="script-execution-dialog"
        >
            <ScriptExecuteForm
                {...props}
                form={form}
                setRequiredFilesNotLoaded={setRequiredFilesNotLoaded}
                requiredFilesNotLoaded={requiredFilesNotLoaded}
                requiredNodesNotSelected={requiredNodesNotSelected}
                setRequiredNodesNotSelected={setRequiredNodesNotSelected}
            />
        </Dialog>
    );
};
