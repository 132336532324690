import { ScriptGeneralToolbar } from '../components/ScriptGeneralToolbar/ScriptGeneralToolbar.component';
import { TScriptGeneralToolbarProps } from '../components/ScriptGeneralToolbar/ScriptGeneralToolbar.types';
import { TRootState } from '../../../reducers/root.reducer.types';
import { connect } from 'react-redux';
import { scriptChangeMode, scriptParametersRequest } from '../../../actions/entities/script.actions';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import getActiveTab = TabsSelectors.getActiveTab;
import { IScriptNode } from '../../../models/bpm/bpm-model-impl.types';
import { EditorMode } from '../../../models/editorMode';
import { NodeId } from '../../../serverapi/api';

const mapStateToProps = (state: TRootState) => {
    const activeWorkspaceTab = getActiveTab(state);
    const activeScheme = TabsSelectors.getActiveTab(state);

    return {
        script: activeWorkspaceTab && (activeWorkspaceTab.content as IScriptNode),
        editorMode: activeScheme ? activeScheme.mode : undefined,
        isActiveScheme: !!activeScheme,
        activeWorkspaceTab,
    };
};

const mapDispatchToProps = (dispatch): Partial<TScriptGeneralToolbarProps> => ({
    clickRunWithProps: (script: IScriptNode) => {
        dispatch(scriptParametersRequest(script, []));
    },
    onModeChange: (mode: EditorMode, nodeId: NodeId) => {
        dispatch(scriptChangeMode(nodeId, mode));
    },
});

export const ScriptGeneralToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(ScriptGeneralToolbar);
