import { Radio } from 'antd';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { closeDialog } from '../../../actions/dialogs.actions';
import { EdgeInstanceImpl } from '../../../models/bpm/bpm-model-impl';
import { BPMMxGraph } from '../../../mxgraph/bpmgraph';
import { MxGeometry, MxCell } from '../../../mxgraph/mxgraph';
import { ObjectDefinitionSelectors } from '../../../selectors/objectDefinition.selectors';
import { EdgeType } from '../../../serverapi/api';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './CreateInvisibleEdgeDialog.messages';
import theme from './CreateInvisibleEdgeDialog.scss';
import { TEdgeData, TTableRow } from './CreateInvisibleEdgeDialog.types';
import EdgeTypesTable from './EdgeTypesTable.component';

type TCreateInvisibleEdgeDialog = {
    visible: boolean;
    movedObj: MxCell;
    intersectObj: MxCell;
    graph: BPMMxGraph;
    movedToIntersectTypes: EdgeType[];
    intersectToMovedTypes: EdgeType[];
};

const prepareTableData = (
    movedToIntersectTypes: EdgeType[],
    intersectToMovedTypes: EdgeType[],
    movedObjId: string,
    movedObjName: string,
    intersectObjId: string,
    intersectObjName: string,
) => {
    const tableData: TTableRow[] = [];

    movedToIntersectTypes.forEach((edgeType) => {
        if (edgeType?.canBeInvisible) {
            tableData.push({
                fromObject: {
                    id: movedObjId,
                    name: movedObjName,
                },
                edgeType: {
                    id: edgeType.id,
                    name: edgeType.name,
                },
                toObject: {
                    id: intersectObjId,
                    name: intersectObjName,
                },
            });
        }
    });

    intersectToMovedTypes.forEach((edgeType) => {
        if (edgeType?.canBeInvisible) {
            tableData.push({
                fromObject: {
                    id: intersectObjId,
                    name: intersectObjName,
                },
                edgeType: {
                    id: edgeType.id,
                    name: edgeType.name,
                },
                toObject: {
                    id: movedObjId,
                    name: movedObjName,
                },
            });
        }
    });

    return tableData;
};

const CreateInvisibleEdgeDialog: FC<TCreateInvisibleEdgeDialog> = (props) => {
    const { visible, intersectObj, movedObj, graph, movedToIntersectTypes, intersectToMovedTypes } = props;
    const { serverId, repositoryId } = graph.id;

    const movedObjDef = useSelector(
        ObjectDefinitionSelectors.byId({ serverId, repositoryId, id: movedObj.value.objectDefinitionId }),
    );
    const intersectObjDef = useSelector(
        ObjectDefinitionSelectors.byId({ serverId, repositoryId, id: intersectObj.value.objectDefinitionId }),
    );

    const tableData = prepareTableData(
        movedToIntersectTypes,
        intersectToMovedTypes,
        movedObj.id,
        movedObjDef.name,
        intersectObj.id,
        intersectObjDef.name,
    );
    const initEdgeData = {
        fromId: tableData[0].fromObject.id,
        edgeTypeId: tableData[0].edgeType.id,
        toId: tableData[0].toObject.id,
    };

    const [selectedEdgeData, selectEdgeData] = useState<TEdgeData>(initEdgeData);
    const [needCreate, setNeedCreate] = useState(true);
    const dispatch = useDispatch();
    const intl = useIntl();

    const createEdge = (source: MxCell, target: MxCell) => {
        const allEdgeTypes = [...movedToIntersectTypes, ...intersectToMovedTypes];
        const edgeType = allEdgeTypes.find((type) => type.id === selectedEdgeData.edgeTypeId);
        const style = '';
        const edgeValue = new EdgeInstanceImpl({
            id: uuid(),
            style,
            edgeTypeId: edgeType?.id,
            source,
            target,
            name: '',
            invisible: true,
        });

        const edge: MxCell = new MxCell(edgeValue, new MxGeometry(), style);
        edge.setEdge(true);
        edge.setVisible(false);

        return edge;
    };

    const handleOk = () => {
        if (needCreate) {
            const source = movedObj.id === selectedEdgeData.fromId ? movedObj : intersectObj;
            const target = movedObj.id === selectedEdgeData.toId ? movedObj : intersectObj;
            const parent = graph.model.getParent(source);
            const edge = createEdge(source, target);
            graph.addEdge(edge, parent, source, target);
        }
        dispatch(closeDialog(DialogType.CREATE_INVISIBLE_EDGE_DIALOG));
    };

    const handleCancel = () => {
        dispatch(closeDialog(DialogType.CREATE_INVISIBLE_EDGE_DIALOG));
    };

    return (
        <Dialog
            onOk={handleOk}
            onCancel={handleCancel}
            title={intl.formatMessage(messages.title)}
            open={visible}
            width="620px"
            okText={intl.formatMessage(messages.save)}
            cancelText={intl.formatMessage(messages.cancel)}
        >
            <div>{intl.formatMessage(messages.description)}</div>
            <div className={theme.radioContainer}>
                <Radio.Group onChange={(edge) => setNeedCreate(edge.target.value)} value={needCreate}>
                    <Radio data-test="window-creating-invisible-edge_create-edge" value>
                        {intl.formatMessage(messages.createEdge)}
                    </Radio>
                    <br />
                    <Radio data-test="window-creating-invisible-edge_dont-create-edge" value={false}>
                        {intl.formatMessage(messages.dontCreateEdge)}
                    </Radio>
                </Radio.Group>
            </div>
            <EdgeTypesTable selectedEdgeData={selectedEdgeData} onSelect={selectEdgeData} tableData={tableData} />
        </Dialog>
    );
};

export default CreateInvisibleEdgeDialog;
