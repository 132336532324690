// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShapeSelect__cloudImage___ESCR,.ShapeSelect__cloudImage___ESCR:before{display:inline-block;font-family:anticon !important;-webkit-box-sizing:border-box;box-sizing:border-box;content:\"\"}.ShapeSelect__symbol___c_en,.ShapeSelect__cloudImage___ESCR{margin-right:15px;top:3px;position:relative}", "",{"version":3,"sources":["webpack://./src/modules/MainMenu/components/ShapeSelect/ShapeSelect.scss"],"names":[],"mappings":"AAAA,uEAEI,oBAEI,CAAA,8BACA,CAAA,6BACA,CADA,qBACA,CAAA,WACA,CAAA,4DAIR,iBACI,CAAA,OACA,CAAA,iBACA","sourcesContent":[".cloudImage {\r\n    @extend .symbol;\r\n    &,\r\n    &:before {\r\n        display: inline-block;\r\n        font-family: anticon!important;\r\n        box-sizing: border-box;\r\n        content: \"\\E67F\";\r\n    }\r\n}\r\n\r\n.symbol {\r\n    margin-right: 15px;\r\n    top: 3px;\r\n    position: relative;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cloudImage": "ShapeSelect__cloudImage___ESCR",
	"symbol": "ShapeSelect__symbol___c_en"
};
export default ___CSS_LOADER_EXPORT___;
