import { TReducer } from '../../utils/types';
import { ModelType } from '../../serverapi/api';
import {
    PRESET_SETTINGS_ADD_MODEL_TYPE,
    PRESET_SETTINGS_DELETE_MODEL_TYPE,
    PRESET_SETTINGS_GET_ALL_MODEL_TYPES_SUCCESS,
    PRESET_SETTINGS_SUBMIT_MODEL_TYPE,
    PRESET_SETTINGS_TRANSFER_MODEL_TYPE,
} from '../../actionsTypes/presetSettings/presetSettingsModelTypes.actionTypes';
import { TPresetSettingsModelTypeState, IPresetSettingsModelTypeData } from './presetSettingsModelType.reducer.types';
import { CLEAR_PRESET_REQUEST } from '../../actionsTypes/methodologySetting.actionTypes';

export const PRESET_SETTINGS_INITIAL_MODEL_TYPE_SERVER_STATE: IPresetSettingsModelTypeData = {
    byId: {},
    modelTypesForDelete: [],
    modelTypesForSave: [],
};

const initial: TPresetSettingsModelTypeState = {
    byServerId: {},
};

const mappingModelTypes = (
    modelTypes: ModelType[],
): {
    [id: string]: ModelType;
} => {
    const modelTypesMap = modelTypes.reduce(
        (
            result: {
                [id: string]: ModelType;
            },
            item: ModelType,
        ) => {
            result[item.id] = item;

            return result;
        },
        {},
    );

    return modelTypesMap;
};

const setNewData = (
    state: TPresetSettingsModelTypeState,
    serverId: string,
    presetId: string,
    newData: Partial<IPresetSettingsModelTypeData>,
): TPresetSettingsModelTypeState => {
    return {
        ...state,
        byServerId: {
            ...state.byServerId,
            [serverId]: {
                ...(state.byServerId[serverId] || {}),
                [presetId]: {
                    ...PRESET_SETTINGS_INITIAL_MODEL_TYPE_SERVER_STATE,
                    ...state.byServerId[serverId]?.[presetId],
                    ...newData,
                },
            },
        },
    };
};

export const presetSettingsModelTypeReducer: TReducer<TPresetSettingsModelTypeState> = (state = initial, action) => {
    switch (action.type) {
        case PRESET_SETTINGS_GET_ALL_MODEL_TYPES_SUCCESS: {
            const { modelTypes, serverId, presetId } = action.payload;
            const modelTypesMap = mappingModelTypes(modelTypes);

            const newData: Partial<IPresetSettingsModelTypeData> = {
                byId: modelTypesMap,
            };

            return setNewData(state, serverId, presetId, newData);
        }

        case PRESET_SETTINGS_ADD_MODEL_TYPE: {
            const { modelTypes, serverId, presetId } = action.payload;
            const modelTypesMap = mappingModelTypes(modelTypes);

            const newData: Partial<IPresetSettingsModelTypeData> = {
                byId: {
                    ...state.byServerId[serverId]?.[presetId]?.byId,
                    ...modelTypesMap,
                },
            };

            return setNewData(state, serverId, presetId, newData);
        }

        case PRESET_SETTINGS_TRANSFER_MODEL_TYPE: {
            const { modelTypes, serverId, presetId } = action.payload;
            const modelTypesMap = mappingModelTypes(modelTypes);

            const newData: Partial<IPresetSettingsModelTypeData> = {
                byId: {
                    ...state.byServerId[serverId]?.[presetId]?.byId,
                    ...modelTypesMap,
                },
                modelTypesForSave: [...state.byServerId[serverId]?.[presetId]?.modelTypesForSave, ...modelTypes],
            };

            return setNewData(state, serverId, presetId, newData);
        }

        case PRESET_SETTINGS_SUBMIT_MODEL_TYPE: {
            const { serverId, presetId } = action.payload;

            const newData: Partial<IPresetSettingsModelTypeData> = {
                modelTypesForDelete: [],
                modelTypesForSave: [],
            };

            return setNewData(state, serverId, presetId, newData);
        }

        case PRESET_SETTINGS_DELETE_MODEL_TYPE: {
            const { modelTypesForDelete, serverId, presetId } = action.payload;
            const allModelTypes = state.byServerId[serverId]?.[presetId]?.byId || {};
            const filteredModelTypes = Object.keys(allModelTypes).reduce((res: { [id: string]: ModelType }, id) => {
                const findInDeleteList = modelTypesForDelete.some((modelType) => modelType.id === id);
                if (!findInDeleteList) {
                    res[id] = allModelTypes[id];
                }

                return res;
            }, {});

            const newData: Partial<IPresetSettingsModelTypeData> = {
                byId: filteredModelTypes,
                modelTypesForDelete: [
                    ...state.byServerId[serverId]?.[presetId]?.modelTypesForDelete,
                    ...modelTypesForDelete,
                ],
            };

            return setNewData(state, serverId, presetId, newData);
        }

        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.[presetId];

            return { ...state };
        }

        default: {
            return state;
        }
    }
};
