import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import icFolder from "../../../../../../resources/icons/ic-tree-folder.svg";
import icIcon from "../../../../../../resources/icons/ic-icon.svg";
import {
    NodeId,
    PresetElementTransferType,
    PresetImage,
    PresetImageGroup
} from "../../../../../../serverapi/api";
import messages from "../../../messages/Presets.messages";
import presetImageMessages from "./PresetImage.messages";
import {GroupSelectionDialog} from "../Dialog/GroupSelectionDialog.component";
import {
    DeleteSelected,
    ExportButton,
    ImportButton,
    MoveSelected,
    TabHeader
} from "../Header/TabHeader.component";
import {GroupedTypesTable} from "../util/GroupedTypesTable.component";
import theme from "../Presets.scss";
import {useIntl} from "react-intl";
import {
    PresetImageGroupSelectors
} from "../../../../../../selectors/presetSettings/presetImageGroup.selectors";
import {TreeNode} from "../../../../../../models/tree.types";
import {
    createPresetImage,
    deletePresetImage,
    deletePresetImageAndGroup,
    editPresetImage,
    submitPresetImages,
} from "../../../../../../actions/presetSettings/presetImage.actions";
import {
    createPresetImageGroup,
    deletePresetImageGroup,
    editPresetImageGroup,
} from "../../../../../../actions/presetSettings/presetImageGroup.actions";
import {TPreset} from "../../../../../../models/preset.types";
import {
    exportPreset
} from "../../../../../../actions/methodologySetting.actions";
import {openDialog} from "../../../../../../actions/dialogs.actions";
import {DialogType} from "../../../../../DialogRoot/DialogRoot.constants";
import {
    PresetImageSelectors
} from "../../../../../../selectors/presetSettings/presetImage.selectors";
import {LocalesService} from "../../../../../../services/LocalesService";
import {Locale} from "../../../../../Header/components/Header/header.types";
import {TGroup, TTableDataType} from "../util/GroupedTypesTable.types";
import { Alert } from "antd";
import { getMetodologyElementsDeleteMessages } from "../util/metodologyElementsDeleteMessages.utils";

type TIconsLibTabProps = {
    disabled: boolean;
    serverNode: TreeNode;
    preset: TPreset;
    tabNodeId: NodeId;
};

export const IconsLibTab = ({ serverNode, preset, disabled, tabNodeId }: TIconsLibTabProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { serverId } = serverNode.nodeId;
    const presetId = preset.id;

    const importProperties = {
        preset,
        filters: '.xml',
    };

    const presetImages = useSelector(PresetImageSelectors.listAllByPreset(serverId, presetId));

    const presetImageGroups: TGroup[] = useSelector(
        PresetImageGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId,
        }),
    ).map(g => ({
        ...g,
        name: (LocalesService.getLocale() === Locale.en ? g?.multilingualName?.en : g?.multilingualName?.ru) || ''
    }));

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [selectedPresetImages, setSelectedPresetImages] = useState<PresetImage[]>([]);
    const [selectedPresetImageGroups, setSelectedPresetImageGroups] = useState<PresetImageGroup[]>([]);
    const [selectGroupDialogVisible, setSelectGroupDialogVisible] = useState<boolean>(false);
    const selected = selectedPresetImages.length || selectedPresetImageGroups.length;
    const [saveRequired, setSaveRequired] = useState<boolean>(false);
    const { deleteMessage: deletePresetImagesMessage, deleteGroupsMessage } =
        getMetodologyElementsDeleteMessages({selectedPresetImages, selectedPresetImageGroups});

    const changePresetImageGroup = useCallback((changedPresetImages: PresetImage[]) => {
        dispatch(
            submitPresetImages({
                presetImages: changedPresetImages,
                preset,
                serverNode,
                tabNodeId
            }),
        );
    }, []);

    const onExportPresetImages = useCallback((exportPresetImages: PresetImage[]) => {
        const exportPresetImagesIds = exportPresetImages.map((ot) => ot.id);
        dispatch(exportPreset(preset, serverId, PresetElementTransferType.image, exportPresetImagesIds));
    }, []);

    const onImportPresetImages = useCallback(() => {
        dispatch(
            openDialog(DialogType.UPLOAD_PRESET_DIALOG, {
                serverNode,
                type: PresetElementTransferType.image,
                ...importProperties,
            }),
        );
    }, []);

    return (
        <div className={theme.container}>
            <TabHeader
                buttons={[
                    {
                        name: messages.newIcon,
                        disabled: disabled || !presetImageGroups?.length,
                        icon: icIcon,
                        onAction: () =>
                            dispatch(
                                createPresetImage({
                                    serverNode,
                                    preset,
                                }),
                            ),
                        dataTestId: "new-icon_button",
                    },
                    {
                        name: messages.newGroup,
                        icon: icFolder,
                        disabled,
                        onAction: () =>
                            dispatch(
                                createPresetImageGroup({
                                    serverNode,
                                    preset,
                                }),
                            ),
                        dataTestId: "new-icon-group_button",
                    },
                    ImportButton.build(onImportPresetImages, messages.import, disabled, saveRequired),
                    ExportButton.build(
                        () => onExportPresetImages(selectedPresetImages),
                        messages.export,
                        !(presetImages.length || presetImageGroups.length),
                        saveRequired,
                    ),
                    DeleteSelected.build(
                        () => {
                            if (!selectedPresetImageGroups.length && selectedPresetImages.length) {
                                dispatch(
                                    deletePresetImage({
                                        presetImages: selectedPresetImages,
                                        serverNode,
                                    }),
                                );
                                setSelectedPresetImages([]);
                            }
                            if (selectedPresetImageGroups.length && !selectedPresetImages.length) {
                                dispatch(
                                    deletePresetImageGroup({
                                        serverNode,
                                        presetImageGroups: selectedPresetImageGroups,
                                    }),
                                );
                                setSelectedPresetImageGroups([]);
                            }
                            if (selectedPresetImageGroups.length && selectedPresetImageGroups.length) {
                                dispatch(
                                    deletePresetImageAndGroup({
                                        serverNode,
                                        presetImages: selectedPresetImages,
                                        presetImageGroups: selectedPresetImageGroups,
                                    }),
                                );
                                setSelectedPresetImages([]);
                                setSelectedPresetImageGroups([]);
                            }

                            setSaveRequired(true);
                        },
                        disabled || !selected,
                        undefined,
                        intl.formatMessage(messages.deletePresetImagesDialogTitle),
                        <Alert message={
                            <>
                                {deleteGroupsMessage}
                                {deletePresetImagesMessage}
                            </>
                        } type="warning" />,
                    ),
                    MoveSelected.build(() => setSelectGroupDialogVisible(true), disabled || !selected),
                ]}
                onSearchChange={setSearchFilter}
            />
            <GroupedTypesTable
                types={presetImages?.map((type) => ({
                    ...type,
                    groupId: type.presetImageGroup?.id,
                    //костыли для таблицы. todo: надо переделать GroupedTypesTable
                    name: (LocalesService.getLocale() === Locale.en ? type.multilingualName.en : type.multilingualName.ru) || ''
                }))}
                typeGroups={presetImageGroups}
                searchFilter={searchFilter}
                actionsDisabled={disabled}
                onSelectType={setSelectedPresetImages as any}
                onSelectGroup={setSelectedPresetImageGroups as any}
                onDeleteType={(type: TTableDataType) =>
                    dispatch(
                        deletePresetImage({
                            //костыли для таблицы. todo: надо переделать GroupedTypesTable
                            presetImages: [type as any],
                            serverNode,
                        }),
                    )
                }
                onDeleteGroup={(group: TGroup) =>
                    dispatch(
                        deletePresetImageGroup({
                            serverNode,
                            //костыли для таблицы. todo: надо переделать GroupedTypesTable
                            presetImageGroups: [group as any],
                        }),
                    )
                }
                onEditType={(presetImage: PresetImage) =>
                    dispatch(
                        editPresetImage({
                            serverNode,
                            preset,
                            presetImageId: presetImage.id,
                        }),
                    )
                }
                onEditGroup={(presetImageGroup: TGroup) =>
                    dispatch(
                        editPresetImageGroup({
                            serverNode,
                            preset,
                            //костыли для таблицы. todo: надо переделать GroupedTypesTable
                            presetImageGroup: presetImageGroup as any,
                        }),
                    )
                }
                columns={[
                    {
                        title: intl.formatMessage(presetImageMessages.graphical),
                        dataIndex: 'graphical',
                        render: (value: string) => value && <img width={75} height={75} src={value}/>,
                    },
                ]}
                deleteGroupMessage={messages.deleteGroupImages}
                deleteElMessage={messages.deleteImage}
            />
            {selectGroupDialogVisible && (
                <GroupSelectionDialog
                    groups={presetImageGroups}
                    onSubmit={(group: TGroup) => {
                        if (group) {
                            changePresetImageGroup(
                                selectedPresetImages.map((et) => ({
                                    ...et,
                                    presetImageGroup: group as any,
                                })),
                            );
                        }
                        setSelectGroupDialogVisible(false);
                        setSaveRequired(true);
                    }}
                    onClose={() => setSelectGroupDialogVisible(false)}
                />
            )}
        </div>
    );
};
