import type { TTreeItemSelectDialogProps } from './TreeItemSelectDialog.types';
import type { TRootState } from '../../../reducers/root.reducer.types';
import type { Node, NodeId } from '../../../serverapi/api';
import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TreeItemSelectDialog } from './TreeItemSelectDialog.component';
import { TreeItemType } from '../../Tree/models/tree';
import { TreeSelectors, filterTreeExcludeTypes, filterTreeIncludeTypes } from '../../../selectors/tree.selectors';

// скрипт можно запустить на любом узле, кроме админки
const NOT_ALLOW_NODE_TYPES = [TreeItemType.AdminTool];

const mapStateToProps = (
    state: TRootState,
    ownProps: Partial<TTreeItemSelectDialogProps>,
): Partial<TTreeItemSelectDialogProps> => {
    const { serverId, repositoryId, excludeTypes = NOT_ALLOW_NODE_TYPES, includeTypes } = ownProps;
    const serverNode = TreeSelectors.treeStructure(state).find((node) => node.nodeId.serverId === serverId);
    const allRepos = serverNode?.children;
    const currentRepo = allRepos?.filter((node) => node.nodeId.repositoryId === repositoryId);
    const rawNodes = repositoryId ? currentRepo : allRepos;
    const filteredNodes = filterTreeExcludeTypes(rawNodes, excludeTypes);
    const treeLocation = includeTypes ? filterTreeIncludeTypes(filteredNodes, includeTypes) : filteredNodes;

    return {
        treeLocation,
    };
};

const mapDispatchToProps = (dispatch, ownProps: TTreeItemSelectDialogProps) => ({
    onClose:
        ownProps.onClose ||
        (() => {
            dispatch(closeDialog(DialogType.TREE_ITEM_SELECT_DIALOG));
        }),
    onSubmit: (id: NodeId, node: Node) => {
        if (ownProps.onSubmit) {
            ownProps.onSubmit(id, node);
        }
        dispatch(closeDialog(DialogType.TREE_ITEM_SELECT_DIALOG));
    },
});

export const TreeItemSelectDialogContainer = connect(mapStateToProps, mapDispatchToProps)(TreeItemSelectDialog);
