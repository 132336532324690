import { Table, Tooltip } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { InternationalString, PresetProfile, ServerProfile } from '../../../../../../serverapi/api';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import { TabHeader } from '../Header/TabHeader.component';
import messages from './PresetProfileList.messages';
import verifiedIcon from '../../../../../../resources/icons/ic-acl-verified.svg';
import { deletePresetProfile, openEditPresetProfileTab } from '../../../../../../actions/presetProfile.actions';
import { TreeNode } from '../../../../../../models/tree.types';
import { TPreset } from '../../../../../../models/preset.types';
import EditButton from '../../../../Button/EditButton';
import DeleteButton from '../../../../Button/DeleteButton';
import rowBtnsTheme from '../../../../Button/RowButtons.scss';
import { PresetProfileSelectors } from '../../../../../../selectors/presetSettings/presetProfile.selectors';
import { AlignType } from 'rc-table/lib/interface';
import theme from './PresetProfileList.scss';
import { v4 as uuid } from 'uuid';
import CopyButton from '../../../../Button/CopyButton';
import { ServerProfileSelectors } from '../../../../../../selectors/serverProfile.selectors';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../services/LocalesService';

type TPresetProfileList = {
    serverId: string;
    preset: TPreset;
    serverNode: TreeNode;
    onProfileDelete: (presetProfileId: string) => void;
};

const PresetProfileList: FC<TPresetProfileList> = (props) => {
    const { serverId, preset, serverNode, onProfileDelete } = props;
    const presetId = preset.id;
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);
    const data = useSelector(PresetProfileSelectors.byPresetId({ serverId, presetId }));
    const presetProfiles: PresetProfile[] = data?.presetProfiles || [];
    const serverProfilesData = useSelector(ServerProfileSelectors.byServerId(serverId));
    const serverProfiles: ServerProfile[] = serverProfilesData.serverProfiles || [];

    const renderRowBtns = (text, presetProfile: PresetProfile) => {
        return (
            <div className={rowBtnsTheme.buttonsBox}>
                <EditButton
                    onClick={() =>
                        dispatch(openEditPresetProfileTab({ preset, serverNode, mode: 'edit', presetProfile }))
                    }
                />
                <CopyButton
                    onClick={() => {
                        const clonePresetProfile = { ...presetProfile };
                        delete clonePresetProfile.serverProfileId;
                        const copyPresetProfile: PresetProfile = {
                            ...clonePresetProfile,
                            id: uuid(),
                        };
                        dispatch(
                            openEditPresetProfileTab({
                                preset,
                                serverNode,
                                mode: 'copy',
                                presetProfile: copyPresetProfile,
                                copiedPresetProfileId: presetProfile.id,
                            }),
                        );
                    }}
                />
                <DeleteButton
                    onDelete={() => {
                        dispatch(deletePresetProfile({ presetId, serverId, id: presetProfile.id }));
                        onProfileDelete(presetProfile.id);
                    }}
                    deleteQuestion={intl.formatMessage(messages.delete, {
                        profile: `"${LocalesService.internationalStringToString(presetProfile.multilingualName)}"`,
                    })}
                />
            </div>
        );
    };

    const columns = [
        {
            title: '',
            dataIndex: 'serverProfileId',
            key: 'serverProfileId',
            render: (value: string) => {
                const fitProfile = serverProfiles.find((sProfile) => sProfile.id === value);
                if (fitProfile) {
                    return (
                        <Tooltip title={intl.formatMessage(messages.serverProfileFinded)}>
                            <div className={theme.checkIcon}>
                                <Icon spriteSymbol={verifiedIcon} />
                            </div>
                        </Tooltip>
                    );
                }

                return null;
            },
            className: theme.serverProfileIdColumn,
            align: 'center' as AlignType,
        },
        {
            title: intl.formatMessage(messages.profileName),
            dataIndex: 'multilingualName',
            key: 'multilingualName',
            className: theme.namesColumn,
            render: (value: InternationalString) => LocalesService.internationalStringToString(value, currentLocale),
        },
        {
            title: intl.formatMessage(messages.description),
            dataIndex: 'multilingualDescription',
            key: 'multilingualDescription',
            className: theme.column,
            render: (value: InternationalString) => LocalesService.internationalStringToString(value, currentLocale),
        },
        {
            title: intl.formatMessage(messages.serverProfile),
            dataIndex: 'serverProfileId',
            key: 'serverProfileId',
            className: theme.column,
            render: (value: string) => {
                const fitProfile: ServerProfile | undefined = serverProfiles.find((sProfile) => sProfile.id === value);

                return fitProfile?.name || '';
            },
        },
        {
            title: '',
            dataIndex: 'manageBtn',
            key: 'manageBtn',
            render: renderRowBtns,
            className: theme.buttonsColumn,
        },
    ];

    return (
        <section className={theme.container}>
            <TabHeader
                buttons={[
                    {
                        name: messages.addProfile,
                        size: 'large',
                        type: 'primary',
                        onAction: () => {
                            dispatch(
                                openEditPresetProfileTab({
                                    preset,
                                    serverNode,
                                    mode: 'create',
                                }),
                            );
                        },
                    },
                ]}
            />
            <Table
                rowKey={(presetProfile: PresetProfile) => presetProfile.id}
                dataSource={presetProfiles}
                columns={columns}
                pagination={false}
                className={theme.table}
                scroll={{
                    y: 'max-content',
                    x: 'max-content',
                }}
            />
        </section>
    );
};

export default PresetProfileList;
