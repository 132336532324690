import {
    IMAGE_TO_CLIPBOARD,
    PRINT_MODEL,
    SAVE_IMAGE,
    SAVE_IMAGE_SVG,
    SAVE_PDF,
} from '../actionsTypes/image.actionTypes';
import { NodeId } from '../serverapi/api';
import {
    TImageExtension,
    TImageToClipboardAction,
    TPrintModelAction,
    TSaveImageAction,
    TSaveImageSvgAction,
    TSavePdfAction,
} from './image.actions.types';

export const saveImage = (extension: TImageExtension, nodeId: NodeId): TSaveImageAction => ({
    type: SAVE_IMAGE,
    payload: {
        extension,
        nodeId,
    },
});

export const savePdf = (nodeId: NodeId): TSavePdfAction => ({
    type: SAVE_PDF,
    payload: {
        nodeId,
    },
});

export const printModel = (nodeId: NodeId): TPrintModelAction => ({
    type: PRINT_MODEL,
    payload: {
        nodeId,
    },
});

export const imageToClipboard = (nodeId: NodeId): TImageToClipboardAction => ({
    type: IMAGE_TO_CLIPBOARD,
    payload: {
        nodeId,
    },
});

export const saveImageSvg = (nodeId: NodeId): TSaveImageSvgAction => ({
    type: SAVE_IMAGE_SVG,
    payload: {
        nodeId,
    },
});
