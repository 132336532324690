import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import messages from '../../messages/ChoosePrincipalDialog.messages';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import { ChoosePrincipalsPanel } from '../ChoosePrincipalsPanel/ChoosePrincipalsPanel.component';
import { isEqual } from 'lodash';
import { TChoosePrincipalDialogProps } from './ChoosePrincipalDialog.types';

type TChoosePrincipalDialogState = {
    data: PrincipalDescriptor[];
    selectedRowKeys: string[];
    selectedRows: PrincipalDescriptor[];
};

type TChoosePrincipalDialogPropsAllProps = WrappedComponentProps & TChoosePrincipalDialogProps;

const hidePrincipals = (data: PrincipalDescriptor[], hiddenRowKeys?: string[]): PrincipalDescriptor[] => {
    if (!!hiddenRowKeys && hiddenRowKeys.length > 0) {
        const byId = hiddenRowKeys.reduce((acc, key) => ({ ...acc, [`${key}`]: true }), {});

        return data.filter((p) => !byId[`${p.id}`]);
    }

    return data;

};

class ChoosePrincipalDialog extends React.Component<TChoosePrincipalDialogPropsAllProps, TChoosePrincipalDialogState> {
    constructor(props: TChoosePrincipalDialogPropsAllProps) {
        super(props);
        const { data, hiddenRowKeys } = props;
        this.state = { data: hidePrincipals(data, hiddenRowKeys), selectedRowKeys: [], selectedRows: [] };
    }

    componentDidUpdate(prevProps: TChoosePrincipalDialogPropsAllProps) {
        if (!isEqual(this.props, prevProps)) {
            const { hiddenRowKeys, data } = this.props;
            this.setState({ data: hidePrincipals(data, hiddenRowKeys) });
        }
    }

    handleSubmit() {
        const { onSubmit } = this.props;
        const { selectedRows } = this.state;
        onSubmit(selectedRows);
    }

    handleSelectionChanged(selectedRowKeys: string[], selectedRows: PrincipalDescriptor[]) {
        this.setState({ selectedRowKeys, selectedRows });
    }

    render() {
        const { error, onClose, visible, loading, intl } = this.props;
        const { data, selectedRowKeys } = this.state;

        return (
            <Dialog
                onOk={() => this.handleSubmit()}
                onCancel={onClose}
                title={intl.formatMessage(messages.title)}
                open={visible}
                width={480}
                okText={<div data-test="permission-dialog_principal_confirm-button">{intl.formatMessage(messages.addButton)}</div>}
                cancelText={<div data-test="permission-dialog_principal_cancel-button">{intl.formatMessage(messages.cancelButton)}</div>}
            >
                <ChoosePrincipalsPanel
                    loading={loading}
                    error={error}
                    dataSource={data}
                    selectedRowKeys={selectedRowKeys}
                    onSelectionChanged={(keys, rows) => this.handleSelectionChanged(keys, rows)}
                />
            </Dialog>
        );
    }
}

const withIntl = injectIntl(ChoosePrincipalDialog);

export { withIntl as ChoosePrincipalDialog };
