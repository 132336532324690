import React, { FC, useState } from 'react';
import theme from './ApprovalsTab.scss';
import messages from './ApprovalsTab.messages';
import icApprovalVotePositive from '../../../../resources/icons/ic-approval-vote-positive.svg';
import icApprovalVoteNegative from '../../../../resources/icons/ic-approval-vote-negative.svg';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Icon } from '@/modules/UIKit';
import {
    ApprovalDTOStatus,
    ApprovalStageDTOStatus,
    ApprovalStageDTOType,
    ApprovalUserDTOVote,
} from '@/modules/ApprovalDialog/ApprovalDialog.types';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from '@/selectors/authorization.selectors';
import {
    ApprovalDTOStatusEnum,
    ApprovalStageDTO,
    ApprovalUserDTOVoteEnum,
    NodeId,
    PrincipalDescriptor,
} from '@/serverapi/api';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import { ApprovalBllService } from '@/services/bll/ApprovalBLLService';
import { Button, Input } from 'antd';
import { ApprovalStageUserStatus } from './ApprovalStageUserStatus.component';
import { commentApproval, voteApproval } from '@/actions/approval.actions';

type TApprovalStageContent = {
    stage: ApprovalStageDTO;
    approvalStatus: ApprovalDTOStatusEnum;
    approvalId: NodeId;
};

export const ApprovalStageContent: FC<TApprovalStageContent> = ({ stage, approvalStatus, approvalId }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentUserId: number | undefined = useSelector(getUserId);
    const allUsersInSystem: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getUsers);

    const currentApprovalUser = stage.approvalUsersDTO?.find(
        (approvalUser) => currentUserId === approvalUser.principalId,
    );
    const [currentUserComment, setCurrentUserComment] = useState<string | undefined>(currentApprovalUser?.comment);

    const onChangeComment = (comment: string) => {
        setCurrentUserComment(comment);
    };

    const onSubmitComment = () => {
        dispatch(
            commentApproval({
                comment: { comment: currentUserComment!, approvalId, stageId: stage.id },
            }),
        );
    };

    const onChangeVote = (vote: ApprovalUserDTOVoteEnum) => {
        dispatch(voteApproval({ vote: { vote, stageId: stage.id, approvalId } }));
    };

    return (
        <>
            {stage.approvalUsersDTO
                ? stage.approvalUsersDTO.map((user, index, approvalUsers) => {
                      const isUserDeleted = !allUsersInSystem?.find((usr) => usr.id === user.principalId);
                      const isCurrentUser = currentUserId === user.principalId;

                      if (
                          isCurrentUser &&
                          approvalStatus === ApprovalDTOStatus.IN_PROCESS &&
                          stage.status === ApprovalStageDTOStatus.IN_PROCESS
                      ) {
                          const isUserFirstInList: boolean = index === 0;
                          const isUserLastInList: boolean = index === approvalUsers.length - 1;
                          const voteOfPreviousUser: ApprovalUserDTOVoteEnum | undefined = isUserFirstInList
                              ? undefined
                              : approvalUsers[index - 1]?.vote;
                          const isNextUserVoted: boolean = !isUserLastInList || !!approvalUsers[index + 1]?.vote;
                          const isSuccessiveTypeAndNextUserVoted: boolean =
                              stage.type === ApprovalStageDTOType.SUCCESSIVE && !!approvalUsers[index + 1]?.vote;

                          const canVote = ApprovalBllService.canUserVote(
                              !!user.vote,
                              stage.type!,
                              isUserFirstInList,
                              voteOfPreviousUser,
                          );

                          const canChangeVote: boolean = ApprovalBllService.canUserChangeVote(
                              !!user.vote,
                              !!stage.changeVoteAllowed,
                              stage.type!,
                              isNextUserVoted,
                          );

                          const canChangeOrAddComment = user.comment !== undefined || !!user.vote || canVote;

                          return (
                              <div key={user.principalId} className={theme.stageUserContainer}>
                                  <div className={theme.stageUserInfo}>
                                      <div className={theme.userTitle}>{`${user.name} (@${user.login})`}</div>
                                      <ApprovalStageUserStatus
                                          stageStatus={stage.status}
                                          userVote={user.vote}
                                          userVotedAt={user.votedAt}
                                      />
                                  </div>

                                  {canChangeOrAddComment ? (
                                      <div className={theme.flexColumn}>
                                          <Input.TextArea
                                              placeholder={intl.formatMessage(messages.typeComment)}
                                              defaultValue={currentUserComment}
                                              value={currentUserComment}
                                              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                  onChangeComment(e.target.value);
                                              }}
                                          />
                                          <Button
                                              disabled={
                                                  currentApprovalUser?.comment === currentUserComment ||
                                                  currentUserComment === ''
                                              }
                                              className={theme.stageCommentBtn}
                                              type="primary"
                                              size="small"
                                              onClick={onSubmitComment}
                                          >
                                              {intl.formatMessage(messages.send)}
                                          </Button>
                                      </div>
                                  ) : null}

                                  {(canVote || canChangeVote) && !isSuccessiveTypeAndNextUserVoted ? (
                                      <div className={theme.actionsContainer}>
                                          <div
                                              className={classNames(theme.voteAction, {
                                                  [theme.positiveActionActive]:
                                                      currentApprovalUser?.vote === ApprovalUserDTOVote.APPROVED,
                                              })}
                                              onClick={() => onChangeVote(ApprovalUserDTOVote.APPROVED)}
                                          >
                                              <Icon spriteSymbol={icApprovalVotePositive} />
                                              <span>{intl.formatMessage(messages.approved)}</span>
                                          </div>

                                          <div
                                              className={classNames(theme.voteAction, {
                                                  [theme.negativeActionActive]:
                                                      currentApprovalUser?.vote === ApprovalUserDTOVote.NOT_APPROVED,
                                              })}
                                              onClick={() => onChangeVote(ApprovalUserDTOVote.NOT_APPROVED)}
                                          >
                                              <Icon spriteSymbol={icApprovalVoteNegative} />
                                              <span>{intl.formatMessage(messages.notApproved)}</span>
                                          </div>
                                      </div>
                                  ) : null}
                              </div>
                          );
                      }

                      return (
                          <div key={user.principalId} className={theme.stageUserContainer}>
                              <div className={theme.stageUserInfo}>
                                  <div
                                      className={classNames(theme.userTitle, {
                                          [theme.deletedUser]: isUserDeleted,
                                      })}
                                  >{`${user.name} (@${user.login})`}</div>
                                  <ApprovalStageUserStatus
                                      stageStatus={stage.status}
                                      userVote={user.vote}
                                      userVotedAt={user.votedAt}
                                  />
                              </div>
                              {user.comment ? <div className={theme.stageUserComment}>{user.comment}</div> : null}
                          </div>
                      );
                  })
                : null}
        </>
    );
};
