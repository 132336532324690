import { SpreadsheetCell } from '@/serverapi/api';
import { isNaN } from 'lodash';
import { FC } from 'react';
import { DashboardWidgetTypes, SummaryOperations, TWidgetDataset, TWidgetSource, UpperCaseAlp } from '../Dashboard.types';
import { chartColors } from '../DashboardsConsts';
import { WidgetSourceInput } from '../WidgetSettingsPanel/LineChartSourceInput/WidgetSourceInput.component';
import { PieChartSourceInput } from '../WidgetSettingsPanel/PieChartSourceInput/PieChartSourceInput.component';
import { SummarySourceInput } from '../WidgetSettingsPanel/SummarySourceInput/SummarySourceInput.component';
import { TextBlockSettings } from '../WidgetSettingsPanel/TextBlockSettings/TextBlockSettings.component';
import { BarChart } from './BarChart/BarChart.component';
import { LineChart } from './LineChart/LineChart.component';
import { PieChart } from './PieChart/PieChart.component';
import { Summary } from './Summary/Summary.component';
import { TextBlock } from './TextBlock/TextBlock.component';
import { TChartData, TDataMappers, TSummaryData, TWidgetData, TWidgetProps } from './widget.types';
import BigNumber from "bignumber.js";

export const initChartData: TDataMappers = {
    datasets: [{ borderColor: '#fff', backgroundColor: [], data: [], label: '' }],
    labels: [],
};

export const spreadsheetMapToChartData = (cells: SpreadsheetCell[], source: TWidgetSource): TChartData => {
    const results: { data: TWidgetData; legend: string }[] = [];
    source?.dataset?.forEach((sourceData) => {
        const result: TWidgetData = [];

        if (cells && sourceData.col1 && sourceData.col2) {
            // Определеям номера колонок в таблицах из которых берутся данные
            const valueCol = Number(UpperCaseAlp[sourceData.col1]);
            const labelCol = Number(UpperCaseAlp[sourceData.col2]);

            // Достаем значения из ячеек таблицы и собираем в нужном формате
            cells.forEach((cell) => {
                const index = cell.row;
                if (!result[index])
                    result[index] = {
                        name: '',
                        value: null,
                        backgroundColor: '#fff',
                    };
                if (cell.column === valueCol) {
                    const value =
                        cell.value !== '' && (Number(cell.value) || Number(cell.value) === 0)
                            ? Number(cell.value)
                            : null;
                    result[index].value = value;
                    result[index].backgroundColor = chartColors[index];
                }
                if (cell.column === labelCol) result[index].name = cell.value;
            });
        }

        results.push({ data: result, legend: sourceData?.legend || '' });
    });

    const labels = results.flatMap(({ data }) => data.map(({ name }) => name));

    // собираем данные в формат для Chart.js
    const datasets = results.map(({ data: widgetData, legend }, index) => ({
        label: legend,
        data: widgetData.map(({ value }) => value),
        borderColor: chartColors[index],
        backgroundColor: widgetData.map(({ backgroundColor }) => backgroundColor),
    }));
    const checkData = datasets.some(({ data }) => data.length);

    return checkData ? { labels, datasets } : initChartData;
};

export const toFixed = (num: string | number, fix: number): string => {
    const bigNum: BigNumber = new BigNumber(num);
    const decimalPart: BigNumber = bigNum.modulo(1);

    if (decimalPart.isEqualTo(0)) {
        return bigNum.toString();
    }
 
    return bigNum.toFixed(fix);
};

export const spreadsheetMapToSummary = (cells: SpreadsheetCell[], source: TWidgetSource): TSummaryData => {
    BigNumber.config({ EXPONENTIAL_AT: 1e+9 });
    const dataset: TWidgetDataset = source?.dataset?.[0];
    const legend: string = dataset.legend || '';
    if (dataset && dataset.col1 && dataset.summaryOperation) {
        const valueCol = Number(UpperCaseAlp[dataset.col1]);
        const result: string[] = [];
        let sum: string = '0';
        cells.forEach((cell) => {
            if (cell.column === valueCol) {
                result.push(cell.value);
            }
        });
        // eslint-disable-next-line default-case
        switch (dataset.summaryOperation) {
            case SummaryOperations.Sum:
                sum = result.reduce((a, b) => a.plus(isNaN(Number(b)) ? new BigNumber(0) : new BigNumber(b || 0)), new BigNumber(0)).toString();
                break;
            case SummaryOperations.AvgSum:
                sum = result.reduce((a, b) => a.plus(isNaN(Number(b)) ? new BigNumber(0) : new BigNumber(b || 0)), new BigNumber(0)).toString();
                sum = new BigNumber(sum).dividedBy(result.length).toString();
                break;
            case SummaryOperations.Count:
                sum = `${result.length}`;
                break;
        }

        return { data: toFixed(sum, 2), legend };
    }

    return { data: '0', legend };
};

export const widgetComponents: Record<
    DashboardWidgetTypes,
    {
        widgetComponent: FC<TWidgetProps>;
        sourceInputComponent: FC;
        dataMapper: (cells: SpreadsheetCell[], source: TWidgetSource) => TDataMappers;
    }
> = {
    [DashboardWidgetTypes.PieChart]: {
        widgetComponent: PieChart,
        sourceInputComponent: PieChartSourceInput,
        dataMapper: spreadsheetMapToChartData,
    },
    [DashboardWidgetTypes.LineChart]: {
        widgetComponent: LineChart,
        sourceInputComponent: WidgetSourceInput,
        dataMapper: spreadsheetMapToChartData,
    },
    [DashboardWidgetTypes.BarChart]: {
        widgetComponent: BarChart,
        sourceInputComponent: WidgetSourceInput,
        dataMapper: spreadsheetMapToChartData,
    },
    [DashboardWidgetTypes.Summary]: {
        widgetComponent: Summary,
        sourceInputComponent: SummarySourceInput,
        dataMapper: spreadsheetMapToSummary,
    },
    [DashboardWidgetTypes.TextBlock]: {
        widgetComponent: TextBlock,
        sourceInputComponent: TextBlockSettings,
        dataMapper: spreadsheetMapToSummary,
    },
};
