import enLocale from 'antd/lib/locale/en_US';
import ruLocale from 'antd/lib/locale/ru_RU';
import { createIntl, createIntlCache } from 'react-intl';
import RU_MESSAGES from '../static/locales/ru.json';
import EN_MESSAGES from '../static/locales/en.json';
import locales from '../static/locales/index.json';
import { DEFAULT_LOCALE } from '../config/config';
import { Locale } from '../modules/Header/components/Header/header.types';
import { InternationalString } from '../serverapi/api';

const cache = createIntlCache();
const messages = {
    en: EN_MESSAGES,
    ru: RU_MESSAGES,
};

export const LocalesService = {
    currentLocale: DEFAULT_LOCALE,

    useIntl(locale?: Locale) {
        if (locale) this.currentLocale = locale;

        const currentMessages = this.getMessagesByLocale(this.currentLocale);
        const stringLocale = this.convertToString(this.currentLocale);

        return createIntl({ locale: stringLocale, messages: currentMessages }, cache);
    },

    setLocale(locale: Locale) {
        this.currentLocale = locale;
    },

    getLocale() {
        return this.currentLocale;
    },

    getLocaleList(): Locale[] {
        return locales.map((l) => this.convertFromString(l));
    },

    getMessagesByLocale(locale?: Locale) {
        return messages[locale || this.currentLocale];
    },

    changeLocaleValue(
        str: InternationalString | undefined,
        locale: Locale | undefined,
        newValue: string,
    ): InternationalString {
        const result: InternationalString = {...str};
        if (!locale) {
            return result;
        }
        result[locale] = newValue;

        return result;
    },

    internationalStringToString(str?: InternationalString, locale?: Locale) {
        if (!str) {
            return '';
        }
        const l: Locale = locale || this.currentLocale;

        return str[l] || str[DEFAULT_LOCALE] || str[Locale.ru] || str[Locale.en] || '';
    },

    internationalStringArrayToString<T extends { multilingualName?: InternationalString }>(
        arr?: T[],
        locale?: Locale,
    ): string {
        return arr?.map((r: T) => `"${this.internationalStringToString(r.multilingualName, locale)}"`).join(', ') || '';
    },

    convertToString(locale: Locale): string {
        return Locale[locale].toUpperCase();
    },

    convertFromString(locale: string): Locale {
        try {
            return Locale[locale.toLocaleLowerCase()] || DEFAULT_LOCALE;
        } catch {
            return DEFAULT_LOCALE;
        }
    },

    checkAndSetLocale(currentLocale: string | undefined) {
        switch (currentLocale?.toLowerCase()) {
            case 'ru':
                return ruLocale;
            case 'en':
                return enLocale;
            default:
                return ruLocale;
        }
    },

    hasMessage(messageId: string) {
        return !!this.getMessagesByLocale()[messageId];
    }
};
