import type { TRootState } from '../../reducers/root.reducer.types';
import type { MxCell } from '../mxgraph';
import type {
    DiagramElement,
    EdgeInstance,
    NodeId,
    ObjectDefinitionNode,
    ObjectInstance,
    ShapeInstance,
    Symbol,
} from '../../serverapi/api';
import { MxPoint, MxConstants, MxUtils, MxCellState } from '../mxgraph';
import { ObjectInstanceImpl, EdgeInstanceImpl, LayoutInstanceImpl } from '../../models/bpm/bpm-model-impl';
import { BPMMxGraph } from '../bpmgraph';
import { isUndefined } from 'is-what';
import { ModelTypes } from '../../models/ModelTypes';
import { toMxCell, toMxCells } from '../codec/graphSerializer';
import { DEFAULT_SYMBOL, NOT_ACCESS_SYMBOL, NOT_READABLE_SYMBOL, symbolService } from '../../services/SymbolsService';
import { DefaultGraph } from '../DefaultGraph';
import { psdCellType } from '../psdDiagram/psdTable';
import { SymbolType } from '../../models/Symbols';
import { BPMPSDDiagram } from '../psdDiagram/BPMPSDDiagramm';
import { find } from 'lodash';
import { changeEdgeLayerIndex } from '../../utils/bpm.mxgraph.utils';
import { getStore } from '../../store';
import { UserProfileSelectors } from '../../selectors/userProfile.selectors';
import { TreeSelectors } from '../../selectors/tree.selectors';
import { ObjectDefinitionSelectors } from '../../selectors/objectDefinition.selectors';
import { BPMMxConstants } from '../bpmgraph.constants';
import { SymbolSelectors } from '../../selectors/symbol.selectors';
import { BpmMxEditorBLLService } from '../../services/bll/BpmMxEditorBLLService';
import { ComplexSymbolManager } from '../ComplexSymbols/ComplexSymbolsManager.class';
import { sortParentElementsFirst } from '../ComplexSymbols/utils';

type ObjectStyle = {
    style: string;
    labelStyle: string;
    connectable: boolean;
};

export function clearGraph(graph: BPMMxGraph) {
    graph.undoManager.clear();
    graph.model.clear();
}

export function getParentCellById(id: string | undefined, cells: any) {
    return id && find(cells, (cell) => cell.id === id);
}

function lockObjectStyle(style: string): string {
    let lockedStyle = style;
    lockedStyle = MxUtils.setStyle(lockedStyle, MxConstants.STYLE_MOVABLE, 0);
    lockedStyle = MxUtils.setStyle(lockedStyle, MxConstants.STYLE_RESIZABLE, 0);

    return BpmMxEditorBLLService.setLabelStatus(lockedStyle, false);
}

export function prepareStyle(
    state: TRootState,
    symbols: Symbol[],
    objInstance: ObjectInstanceImpl,
    nodeId: NodeId,
): ObjectStyle {
    const { serverId, repositoryId } = nodeId;
    const { objectDefinitionId } = objInstance;

    const getStyleBySymbol = ({ id, style }: Symbol) => `${id};${style}`;

    // экземпляр без определения
    const isHaveDefinition: boolean = !!ObjectDefinitionSelectors.byId({
        serverId,
        repositoryId,
        id: objectDefinitionId || '',
    })(state);

    if (!isHaveDefinition) {
        return {
            style: getStyleBySymbol(NOT_ACCESS_SYMBOL),
            labelStyle: NOT_ACCESS_SYMBOL.labelStyle || '',
            connectable: false,
        };
    }

    // символ экземпляра не найден в методологии
    const isKnown = symbols?.find((sym) => sym.id === objInstance.symbolId);

    if (!isKnown) {
        return {
            style: getStyleBySymbol(DEFAULT_SYMBOL),
            labelStyle: DEFAULT_SYMBOL.labelStyle || '',
            connectable: false,
        };
    }

    // не читаемый экземпляр
    const isSymbolReadable = UserProfileSelectors.isSymbolReadable(nodeId, objInstance.symbolId)(state);

    if (!isSymbolReadable) {
        return {
            style: getStyleBySymbol(NOT_READABLE_SYMBOL),
            labelStyle: NOT_READABLE_SYMBOL.labelStyle || '',
            connectable: false,
        };
    }

    const labelStyleBySymbol: string | undefined = symbols?.find(
        (symbol: Symbol) => symbol.id === objInstance.symbolId,
    )?.labelStyle;
    const isNoLabel: boolean = !!labelStyleBySymbol?.includes('noLabel=1');
    const labelStyle: string = BpmMxEditorBLLService.setLabelStatus(objInstance.labelStyle || '', !isNoLabel);

    // не изменяемый экземпляр
    const isSymbolEditableBoolean = UserProfileSelectors.isSymbolEditable(nodeId, objInstance.symbolId)(state);

    if (!isSymbolEditableBoolean) {
        return {
            style: lockObjectStyle(objInstance.style),
            labelStyle,
            connectable: false,
        };
    }

    return {
        style: objInstance.style,
        labelStyle,
        connectable: true,
    };
}

function restoreMindMap(graph: BPMMxGraph, modelGraph: MxCell[]): void {
    const vertexes = modelGraph
        .filter((node) => node.vertex)
        .map((node) => {
            node.parent = graph.getDefaultParent();
            const cell = toMxCell(node);
            cell.children = toMxCells(node.children, node);
            const imported = graph.importCells(
                [cell],
                node.geometry.x,
                node.geometry.y,
                undefined!,
                undefined!,
                undefined!,
            )[0];
            if (imported) {
                imported.oldId = node.id;
            }

            return imported;
        });

    modelGraph.filter((node) => {
        if (node.edge) {
            // todo: find source and target in children of element
            /*
             * // BPM-5301 Синий экран whiteboard. Плавающий баг: element - undefined
             * @see https://jira.silaunion.ru/browse/BPM-5301
             */
            node.source = vertexes.find((element) => element?.oldId === node.source);
            node.target = vertexes.find((element) => element?.oldId === node.target);

            if (node.source && node.target) {
                const edge = graph.insertEdge(
                    graph.getDefaultParent(),
                    node.id,
                    node.value,
                    node.source,
                    node.target,
                    node.style,
                );

                if (edge.geometry) {
                    /*
                     * // добален try/catch в рамках плавающего бага
                     * @see https://jira.silaunion.ru/browse/BPM-4377
                     */
                    try {
                        edge.geometry.points = node.geometry?.points
                            ?.filter((p) => !!p)
                            .map((point) => new MxPoint(point.x, point.y));
                    } catch (err) {
                        console.error(err);
                    }
                }
            } else {
                // на вайтборде могут быть связи без исходника и цели, insertEdge игнорирует такие связи, поэтому используем importCells
                // но c importCells не корректно вставляются связи с исходным объектом и целью
                node.parent = graph.getDefaultParent();
                const cell = toMxCell(node);
                cell.children = toMxCells(node.children, node);
                graph.importCells([cell], node.geometry.x, node.geometry.y, node.parent, undefined!, undefined!);
            }
        }

        return node.edge;
    });
}

function createShapeCell(graph: BPMMxGraph, element: DiagramElement, serverUrl: string): MxCell | null {
    const shape = element as ShapeInstance;
    const shapeParent = graph.getModel().getCell(shape.parent || '') || graph.getDefaultParent();

    if (isUndefined(shape.x) || isUndefined(shape.y) || isUndefined(shape.width) || isUndefined(shape.height)) {
        return null;
    }

    if (shape.imageId) {
        shape.style = MxUtils.setStyle(
            shape.style || '',
            'image',
            symbolService().prepareImageLinkToShow(`${graph.id.repositoryId}/${shape.imageId}`, serverUrl),
        );
    }

    // TODO переместить в общий метод/сервис
    const cell = graph.insertVertex(
        shapeParent,
        shape.id,
        shape,
        shape.x,
        shape.y,
        shape.width,
        shape.height,
        shape.style,
    );

    cell.setConnectable(false);

    return cell;
}

function createEdgeCell(graph: BPMMxGraph, element: DiagramElement, connectableCells: MxCell[]) {
    const state = getStore().getState();
    const edgeInstance: EdgeInstance = new EdgeInstanceImpl(element);
    const source = connectableCells.find((c) => (c.getValue()?.id || c.id) === edgeInstance.source);
    const target = connectableCells.find((c) => (c.getValue()?.id || c.id) === edgeInstance.target);

    if (!source || !target) {
        return undefined;
    }

    const presetId: string | undefined = TreeSelectors.presetById(graph.id)(state);
    const isEdgeTypeEditableBoolean = UserProfileSelectors.isEdgeTypeEditable(
        graph.id.serverId,
        presetId,
        edgeInstance.edgeTypeId,
    )(state);
    const isEdgeTypeEditable = isEdgeTypeEditableBoolean ? null : 0;

    let edgeStyle = edgeInstance.style || '';
    edgeStyle = MxUtils.setStyle(edgeStyle, MxConstants.STYLE_EDITABLE, isEdgeTypeEditable);
    edgeStyle = MxUtils.setStyle(edgeStyle, MxConstants.STYLE_MOVABLE, isEdgeTypeEditable);
    edgeStyle = MxUtils.setStyle(edgeStyle, MxConstants.STYLE_BENDABLE, isEdgeTypeEditable);
    edgeStyle = MxUtils.setStyle(edgeStyle, BPMMxConstants.STYLE_DISCONNECTABLE, isEdgeTypeEditable);

    const edge = graph.insertEdge(graph.getDefaultParent(), edgeInstance.id, edgeInstance, source, target, edgeStyle);

    edge.setVisible(!edgeInstance.invisible);

    if (edgeInstance.waypoints) {
        edge.geometry.points = [
            ...edgeInstance.waypoints.map((point) => new MxPoint(point.x as number, point.y as number)),
        ];
        edge.geometry.x = edgeInstance.labelXOffset || 0;
        edge.geometry.y = edgeInstance.labelYOffset || 0;
    } else {
        edge.geometry.points = [];
    }

    return edge;
}

function createLayoutCell(graph: BPMMxGraph, element: DiagramElement): MxCell {
    const layoutInstance = new LayoutInstanceImpl(element);
    layoutInstance.isPSDCell = true;
    layoutInstance.psdCellMetaInfo = JSON.parse(layoutInstance.metaInfo);
    const localParent = getParentCellById(element.parent, graph.getModel().cells) || graph.getDefaultParent();
    const cell = graph.insertVertex(
        localParent,
        layoutInstance.id,
        layoutInstance,
        layoutInstance.x,
        layoutInstance.y,
        layoutInstance.width,
        layoutInstance.height,
        layoutInstance.style,
    );
    if (
        layoutInstance.psdCellMetaInfo !== null &&
        layoutInstance.psdCellMetaInfo.type !== psdCellType.BPMN_POOL &&
        layoutInstance.psdCellMetaInfo.type !== psdCellType.BPMN_LANE
    ) {
        initPsdDiagramHandler(graph, cell);
    }
    if (layoutInstance.psdCellMetaInfo !== null && layoutInstance.psdCellMetaInfo.type === psdCellType.BPMN_LANE) {
        cell.setConnectable(false);
    }

    return cell;
}

function createObjectCell(graph: BPMMxGraph, element: DiagramElement, symbols: Symbol[], objNodes: ObjectDefinitionNode[] | undefined, state: TRootState) {
    const parent = graph.getDefaultParent();
    const objInstance = new ObjectInstanceImpl(element);
    const symbol = symbols?.find((s: Symbol) => s.id === objInstance.symbolId);
    const isSimpleObjectsElements = !element.metaInfo && (!symbol || (symbol && !ComplexSymbolManager.isComplexSymbol(symbol)));

    if (!isSimpleObjectsElements) {
        return null;
    }

    const localParent = getParentCellById(element.parent, graph.getModel().cells) || parent;

    let objNode: ObjectDefinitionNode | undefined;

    if (objNodes) {
        objNode = objNodes.find((node) => node.nodeId.id === objInstance.objectDefinitionId);
    }
    // обнаружена странна логика после решения задачи BPM-7562
    // стили для элементов вычисляются и задаются в нескольких местах при загрузке и открытии модели
    // нужно разобрать в какой момент и зачем вызываются prepareStyle и setModelElementStyles
    const preparedStyle = prepareStyle(state, symbols, objInstance, graph.id);

    // TODO это место мне не нравится, но способа сделать иначе я не нашел
    if (graph instanceof DefaultGraph) {
        const cell: MxCell = (graph as DefaultGraph).insertVertex(
            localParent,
            objInstance.id,
            objNode?.name || objInstance,
            objInstance.x,
            objInstance.y,
            objInstance.width,
            objInstance.height,
            preparedStyle.style,
            undefined,
            preparedStyle.labelStyle,
            objInstance.labelWidth,
            objInstance.labelHeight,
            objInstance.labelXOffset,
            objInstance.labelYOffset,
        );
        
        // к нередактируемым и серым экземплярам нельзя проводить связи
        cell.setConnectable(preparedStyle.connectable);
        
        return cell;
    }

    return graph.insertVertex(
        localParent,
        objInstance.id,
        objInstance,
        objInstance.x,
        objInstance.y,
        objInstance.width,
        objInstance.height,
        preparedStyle.style,
    );
}

export function createCells(
    graph: BPMMxGraph,
    elements: DiagramElement[],
    serverUrl: string,
    options?: { objNodes?: ObjectDefinitionNode[] },
): MxCell[] {
  
    const state = getStore().getState();
    const presetId: string | undefined = TreeSelectors.presetById(graph.id)(state);
    const symbols = SymbolSelectors.byServerIdPresetId(graph.id.serverId, presetId || '')(state);
    const objectElements = elements.filter(({ type }) => type === 'object') as ObjectInstance[];

    const { objNodes } = options || {};

    const sortedELements = sortParentElementsFirst(elements);
    const complexCells: MxCell[] = graph.complexSymbolManager.restoreComplexSymbols(objectElements, symbols);
    const createdCells = sortedELements
        .map((element) => {
            if (element.type === 'object') {
                return createObjectCell(graph, element, symbols, objNodes, state);
            }
            if (element.type === 'layout') {
                return createLayoutCell(graph, element);
            } 
            if (element.type === SymbolType.SHAPE) {
                return createShapeCell(graph, element, serverUrl);
            }

            return null;
        })
        .filter((cell: MxCell | null): cell is MxCell => !!cell);

    const connectableCells = [...complexCells, ...createdCells, ...createdCells
        .filter(c => c.getValue().type === 'shape')
        .filter((cell: MxCell) => cell.isConnectable())
    ];

    const edgeElements = elements.filter(({ type }) => type === 'edge');
    edgeElements.forEach((edge) => {
        createEdgeCell(graph, edge, connectableCells);
    });
    changeEdgeLayerIndex(graph, sortedELements);

    return connectableCells;
}

export function addElementsToGraph(
    graph: BPMMxGraph,
    modelGraph: MxCell[] = [],
    diagramElements: DiagramElement[] | undefined,
    serverUrl: string,
    objNodes?: ObjectDefinitionNode[],
) {
    if (!diagramElements) {
        return;
    }

    const graphEnabled = graph.enabled;

    graph.setEnabled(true);
    graph.getModel().beginUpdate();

    try {
        if (graph.modelType && graph.modelType.id === ModelTypes.MIND_MAP) {
            restoreMindMap(graph, modelGraph);
        } else {
            createCells(graph, diagramElements, serverUrl, { objNodes });
        }
    } finally {
        graph.getModel().endUpdate();
        graph.undoManager.clear();
        graph.setEnabled(graphEnabled);

        // флаг завершения загрузке элементов на граф, используется для отрисовки картинок на бэке
        if (globalThis.imageMaker) {
            globalThis.imageMaker.modelLoaded = true;
        }
    }
}

export function initPsdDiagramHandler(graph: BPMMxGraph, cell: MxCell) {
    const cellValue = cell.getValue();
    const { psdCellMetaInfo } = cellValue;

    // init handler
    if (isUndefined(graph.psdDiagramHandler) || graph.psdDiagramHandler === null) {
        graph.psdDiagramHandler = new BPMPSDDiagram('', false, graph);
    } else {
        graph.psdDiagramHandler.isLoaded = false;

        if (isUndefined(graph.psdDiagramHandler.labelMatrix[psdCellMetaInfo.rowIndex])) {
            graph.psdDiagramHandler.labelMatrix[psdCellMetaInfo.rowIndex] = [];
        }

        if (isUndefined(graph.psdDiagramHandler.cellsMatrix[psdCellMetaInfo.rowIndex])) {
            graph.psdDiagramHandler.cellsMatrix[psdCellMetaInfo.rowIndex] = [];
        }
    }

    switch (psdCellMetaInfo.type) {
        case psdCellType.MAIN_TABLE:
            graph.psdDiagramHandler.mainTable = cell;
            break;
        case psdCellType.HORIZONTAL_HEADER:
            graph.psdDiagramHandler.labelMatrix[psdCellMetaInfo.rowIndex][psdCellMetaInfo.columnIndex] = cell;
            graph.psdDiagramHandler.columnCount++;
            graph.psdDiagramHandler.metaData.columns?.splice(psdCellMetaInfo.columnIndex, 0, psdCellMetaInfo);

            break;
        case psdCellType.VERTICAL_HEADER:
            graph.psdDiagramHandler.labelMatrix[psdCellMetaInfo.rowIndex][psdCellMetaInfo.columnIndex] = cell;
            graph.psdDiagramHandler.rowCount++;
            graph.psdDiagramHandler.metaData.rows?.splice(psdCellMetaInfo.rowIndex, 0, psdCellMetaInfo);
            break;
        case psdCellType.CELL:
            graph.psdDiagramHandler.cellsMatrix[psdCellMetaInfo.rowIndex][psdCellMetaInfo.columnIndex] = cell;
            break;
        default:
            // stub
            break;
    }
}

export const getExits = (graph: BPMMxGraph, cell: MxCell): string => {
    const cellState: MxCellState | undefined = graph.getView().getState(cell);
    const keys = [
        'exitX',
        'exitY',
        'exitDx',
        'exitDy',
        'exitPerimeter',
        'entryX',
        'entryY',
        'entryDx',
        'entryDy',
        'entryPerimeter',
    ];

    let resultStr = '';
    if (!cellState) return resultStr;
    keys.forEach((key) => {
        if (cellState.style[key] !== undefined) resultStr += `${key}=${cellState.style[key]};`;
    });

    return resultStr;
};