import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { setSearchPathElement } from '../../../actions/search.actions';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import style from './SearchPathDialog.scss';
import { TreeNode } from '../../../models/tree.types';
import { injectIntl, IntlShape } from 'react-intl';
import messages from './SearchPathDialog.messages';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { Tree } from '../../Tree/components/Tree/Tree.component';

type TSearchPathDialogProps = {
    intl: IntlShape;
};

const SearchPathDialog = ({ intl }: TSearchPathDialogProps) => {
    const dispatch = useDispatch();

    const nodes = useSelector(TreeSelectors.treeStructureConnected);

    const [selectedNode, setSelectedNode] = useState<TreeNode>();
    const [isDisable, setButtonDisabled] = useState(true);

    useEffect(() => {
        if (selectedNode) {
            const { id, repositoryId, serverId } = selectedNode.nodeId;

            if (id && id === repositoryId && id === serverId) {
                setButtonDisabled(true);
            } else {
                setButtonDisabled(false);
            }
        }
    }, [selectedNode]);

    const handleClose = () => {
        dispatch(closeDialog(DialogType.SEARCH_PATH));
    };

    const handleSelectNode = (node: TreeNode) => {
        setSelectedNode(node);
    };

    const handleSelectPath = () => {
        if (selectedNode) {
            const { nodeId, type, name } = selectedNode;

            dispatch(setSearchPathElement(nodeId, type, name));
        }

        dispatch(closeDialog(DialogType.SEARCH_PATH));
    };

    return (
        <Dialog
            width={DEFAULT_DIALOG_WIDTH}
            onOk={handleSelectPath}
            onCancel={handleClose}
            visible
            title={intl.formatMessage(messages.header)}
            okText={intl.formatMessage(messages.selectButtonText)}
            okButtonProps={{
                disabled: isDisable,
            }}
            cancelText={intl.formatMessage(messages.cancelButtonText)}
        >
            <div className={style.treeContainer}>
                <Tree
                    treeName={DialogType.SEARCH_PATH}
                    data={nodes}
                    onSelect={handleSelectNode}
                    selectedNodes={selectedNode ? [selectedNode] : []}
                />
            </div>
        </Dialog>
    );
};

const IntlComponent = injectIntl(SearchPathDialog);

export { IntlComponent as SearchPathDialog };
