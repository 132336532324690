import React, { FC } from 'react';
import theme from './ApprovalsTab.scss';
import messages from './ApprovalsTab.messages';
import { ApprovalStageDTOStatusEnum, ApprovalUserDTOVoteEnum } from '@/serverapi/api';
import { ApprovalStageDTOStatus, ApprovalUserDTOVote } from '@/modules/ApprovalDialog/ApprovalDialog.types';
import { timestampToStringDate } from '@/utils/date.time.utils';
import { Icon } from '@/modules/UIKit';
import icApprovalStageApproved from '../../../../resources/icons/ic-approval-stage-approved.svg';
import icApprovalStageNotApproved from '../../../../resources/icons/ic-approval-stage-not-approved.svg';
import { useIntl } from 'react-intl';

type TApprovalStageUserStatus = {
    stageStatus: ApprovalStageDTOStatusEnum | undefined;
    userVote: ApprovalUserDTOVoteEnum | undefined;
    userVotedAt: number | undefined;
};

export const ApprovalStageUserStatus: FC<TApprovalStageUserStatus> = ({ stageStatus, userVote, userVotedAt }) => {
    const intl = useIntl();

    const renderVoteInfo = () => {
        let voteInfo: React.ReactNode;

        if (userVote === ApprovalUserDTOVote.APPROVED || userVote === ApprovalUserDTOVote.NOT_APPROVED) {
            const [date, time] = timestampToStringDate(userVotedAt!, 'DD.MM.YYYY HH:mm').split(' ');
            voteInfo = (
                <div className={theme.voteStatus}>
                    {userVote === ApprovalUserDTOVote.APPROVED ? (
                        <div>
                            <Icon spriteSymbol={icApprovalStageApproved} />
                            <span className={theme.voteApproved}>{intl.formatMessage(messages.approved)}</span>
                        </div>
                    ) : (
                        <div>
                            <Icon spriteSymbol={icApprovalStageNotApproved} />
                            <span className={theme.voteNotApproved}>{intl.formatMessage(messages.notApproved)}</span>
                        </div>
                    )}
                    <div>{`${date} ${intl.formatMessage(messages.at)} ${time}`}</div>
                </div>
            );
        }

        if (!userVote) {
            voteInfo =
                stageStatus === ApprovalStageDTOStatus.APPROVED ||
                stageStatus === ApprovalStageDTOStatus.NOT_APPROVED ? (
                    <div className={theme.voteWaiting}>{intl.formatMessage(messages.notVoted)}</div>
                ) : !stageStatus ? null : (
                    <div className={theme.voteWaiting}>{intl.formatMessage(messages.waitingForVote)}</div>
                );
        }
        return voteInfo;
    };

    return <>{renderVoteInfo()}</>;
};
