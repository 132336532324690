import {
    ADD_EDGE_TYPE_GROUPS,
    DELETE_EDGE_TYPE_GROUP_SUCCESS,
    SUBMIT_EDGE_TYPE_GROUP,
} from '../actionsTypes/edgeTypeGroup.actionTypes';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import { EdgeTypeGroup } from '../serverapi/api';
import { TReducer } from '../utils/types';
import { TEdgeTypeGroupState } from './edgeTypeGroup.reducer.types';

const initial: TEdgeTypeGroupState = {
    byServerId: {},
};

const initStateWithServerAndPreset = (state: TEdgeTypeGroupState, serverId: string, presetId: string) => {
    if (!state.byServerId[serverId]) {
        state.byServerId[serverId] = {
            byPresetId: {},
        };
    }
    if (!state.byServerId[serverId].byPresetId[presetId]) {
        state.byServerId[serverId].byPresetId[presetId] = {
            byId: {},
        };
    }
};

export const edgeTypeGroupReducer: TReducer<TEdgeTypeGroupState> = (state = initial, action) => {
    switch (action.type) {
        case ADD_EDGE_TYPE_GROUPS:
        case SUBMIT_EDGE_TYPE_GROUP: {
            const {
                payload: { serverNode, preset, edgeTypeGroups },
            } = action;
            const stateClone: TEdgeTypeGroupState = JSON.parse(JSON.stringify(state));
            const presetId = preset.id;
            const { serverId } = serverNode.nodeId;
            if (serverId && presetId) {
                initStateWithServerAndPreset(stateClone, serverId, presetId);
                const preset = stateClone.byServerId[serverId].byPresetId[presetId];
                preset.byId = {
                    ...preset.byId,
                    ...edgeTypeGroups.reduce((acc, edgeTypeGroup: EdgeTypeGroup) => {
                        acc[edgeTypeGroup.id] = edgeTypeGroup;

                        return acc;
                    }, {}),
                };
            }

            return stateClone;
        }
        case DELETE_EDGE_TYPE_GROUP_SUCCESS: {
            const { edgeTypeGroups, serverNode } = action.payload;
            if (edgeTypeGroups) {
                const { serverId } = serverNode.nodeId;

                const stateClone = JSON.parse(JSON.stringify(state));
                edgeTypeGroups.forEach((edgeTypeGroup) => {
                    const { presetId } = edgeTypeGroup;
                    if (presetId && serverId) {
                        const byId = stateClone.byServerId[serverId]?.byPresetId[edgeTypeGroup.presetId]?.byId || {};
                        delete byId[edgeTypeGroup.id];
                    }
                });

                return stateClone;
            }

            return state;
        }
        case CLEAR_PRESET_REQUEST: {
            const { serverId, presetId } = action.payload;
            delete state.byServerId?.[serverId]?.byPresetId?.[presetId];

            return { ...state };
        }

        default:
            return state;
    }
};
