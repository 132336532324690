import React, { useState } from 'react';
import { ExternalSessionsHeader } from './ExternalSessionsHeader.component';
import theme from './ExternalSessions.scss';
import { ExternalSessionsTable } from './ExternalSessionsTable.component';
import { ExternalSession } from '../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { ExternalSessionsSelectors } from '../../../../selectors/externalSessions.selector';
import { openDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { deleteExternalSessionListRequest } from '../../../../actions/externalSession.actions';
import { useIntl } from 'react-intl';
import messages from './ExternalSessions.messages';

type TExternalSessionsProps = {
    serverId: string;
};

export const ExternalSessions = (props: TExternalSessionsProps) => {
    const { serverId } = props;
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const externalSessions: ExternalSession[] = useSelector(ExternalSessionsSelectors.externalSessions(serverId));
    const intl = useIntl();
    const dispatch = useDispatch();

    const onDeleteCreationDialog = () => {
        const namesOfSessionForDelete = externalSessions
            .filter((session: ExternalSession) => selectedIds.includes(session.id) && !!session.name)
            .map((session: ExternalSession) => session.name);

        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    dispatch(deleteExternalSessionListRequest(selectedIds, serverId));
                    setSelectedIds([]);
                },
                title: intl.formatMessage(messages.deleteConfirmationSelectedSessionsTitle),
                question: intl.formatMessage(messages.deleteConfirmationSelectedSessionsQuestion, {
                    names: namesOfSessionForDelete.join(', '),
                }),
                OKButtonText: intl.formatMessage(messages.deleteButtonText),
            }),
        );
    };

    return (
        <div className={theme.container}>
            <ExternalSessionsHeader
                serverId={serverId}
                deleteButtonDisabled={!selectedIds.length}
                onDeleteCreationDialog={onDeleteCreationDialog}
            />
            <ExternalSessionsTable
                serverId={serverId}
                externalSessions={externalSessions}
                setSelectedIds={setSelectedIds}
            />
        </div>
    );
};
