import React, { FC } from 'react';
import theme from './DatetimeSchedulerSettings.scss';
import messages from './DatetimeSchedulerSettings.messages';
import { useIntl } from 'react-intl';
import { InputNumber } from 'antd';
import { ALL, weekDays } from './DatetimeSchedulerSettings.utils';
import { SchedulerWeekDaysEnum } from './DatetimeSchedulerSettings.types';
import { SchedulerTimeSettingsWeekDaysEnum } from '@/serverapi/api';
import { Select as UIKitSelect } from '../UIKit/components/Select/Select.component';

type TWeeklySchedulerPeriodProps = {
    repeatWeekly: number | undefined;
    weekDays: SchedulerTimeSettingsWeekDaysEnum[] | undefined;
    onRepeatWeeklyChange: (repeatDaily: number) => void;
    onWeekDaysChange: (changedDays: (SchedulerWeekDaysEnum | typeof ALL)[] | undefined) => void;
};

export const WeeklySchedulerPeriod: FC<TWeeklySchedulerPeriodProps> = (props) => {
    const { repeatWeekly, onRepeatWeeklyChange, onWeekDaysChange } = props;
    const intl = useIntl();
    const weekDaysOptions: (SchedulerWeekDaysEnum | typeof ALL)[] = [ALL, ...weekDays];

    return (
        <div className={theme.schedulerSettings}>
            <div className={theme.schedulerSettingsWeeklyLabel}>{intl.formatMessage(messages.weeklyLabel)}</div>
            <InputNumber
                min={1}
                max={99}
                style={{ width: '150px' }}
                value={repeatWeekly || 1}
                onChange={onRepeatWeeklyChange}
                addonAfter={intl.formatMessage(messages.weeklyAddon)}
                onKeyDown={(event) => {
                    if (event.key === ',' || event.key === '.') {
                        event.preventDefault();
                    }
                }}
                type="number"
            />
            <span className={theme.weekdaysTitle}>{intl.formatMessage(messages.at)}</span>
            <div className={theme.schedulerSelect}>
                <UIKitSelect
                    isMultiSelect
                    value={intl.formatMessage(messages.selectWeekDays)}
                    onChange={onWeekDaysChange}
                    data-test="scheduler-days-week"
                >
                    {weekDaysOptions.map((day) => (
                        <UIKitSelect.Option
                            key={day}
                            value={day}
                            checked={
                                day === ALL ? props.weekDays?.length === weekDays.length : props.weekDays?.includes(day)
                            }
                            label={intl.formatMessage(messages[day])}
                        />
                    ))}
                </UIKitSelect>
            </div>
        </div>
    );
};
