import { Button } from 'antd';
import React, { useEffect } from 'react';
import { injectIntl, useIntl, WrappedComponentProps } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeMonitoringTab,
    deleteMonitoringStatus,
    loadMonitoringStatus,
    setMonitoringStatusLoading,
} from '../../../../actions/monitoring.actions';
import { TWorkspaceTab } from '../../../../models/tab.types';
import { getMonitoringLoadingStatus, getStatus } from '../../../../selectors/monitoring.selectors';
import {
    LdapStatus,
    MailServerStatus,
    SystemStatusImgServiceStatusEnum,
    SystemStatusScriptEngineStatusEnum,
} from '../../../../serverapi/api';
import { ColorEnum, InformationItem } from './InformationItem/InformationItem';
import messages from './Monitoring.messages';
import style from './Monitoring.scss';
import { KeycloakStatus } from '../../ServerSettings/SystemProperties.types';

type TMonitoringProps = {
    tab: TWorkspaceTab;
};

type TMonitoringPropsFull = TMonitoringProps & WrappedComponentProps;

const getScriptEngineStatusColor = (status?: SystemStatusScriptEngineStatusEnum) => {
    switch (status) {
        case 'SCRIPT_ENGINE_IS_ACTIVE':
            return ColorEnum.GREEN;
        case 'FAIL':
            return ColorEnum.RED;
        case 'BPM_NOT_FOUND':
            return ColorEnum.YELLOW;
        default:
            return ColorEnum.GRAY;
    }
};

const getImgServiceStatusColor = (status?: SystemStatusImgServiceStatusEnum) => {
    switch (status) {
        case 'IMAGE_SERVICE_IS_ACTIVE':
            return ColorEnum.GREEN;
        case 'FAIL':
            return ColorEnum.RED;
        case 'UI_NOT_FOUND':
            return ColorEnum.YELLOW;
        default:
            return ColorEnum.GRAY;
    }
};

const getLdapStatusColor = (status?: LdapStatus) => {
    if (!status) {
        return ColorEnum.GRAY;
    }
    if (status.mainUrlOk) {
        return ColorEnum.GREEN;
    }
    if (status.altUrlOk) {
        return ColorEnum.YELLOW;
    }

    return ColorEnum.RED;
};

const getKeycloakServiceStatusColor = (status: string) => {
    switch (status) {
        case KeycloakStatus.KEYCLOAK_IS_ACTIVE:
            return ColorEnum.GREEN;
        case KeycloakStatus.FAIL:
            return ColorEnum.RED;
        default:
            return ColorEnum.GRAY;
    }
};

const getmailServiceStatusColor = (status?: MailServerStatus): ColorEnum => {
    if (status?.mailStatus === 'SUCCESS') {
        return ColorEnum.GREEN;
    }
    if (status?.mailStatus === 'INVALID_USER_CREDENTIALS') {
        return ColorEnum.YELLOW;
    }

    return ColorEnum.RED;
};
const Monitoring = ({ tab }: TMonitoringPropsFull) => {
    const { serverId } = tab.params;

    const dispatch = useDispatch();

    const intl = useIntl();

    const status = useSelector(getStatus(serverId));
    const isLoading = useSelector(getMonitoringLoadingStatus(serverId));

    useEffect(() => {
        dispatch(deleteMonitoringStatus(serverId));
        dispatch(loadMonitoringStatus(serverId));

        return () => {
            dispatch(deleteMonitoringStatus(serverId));
        };
    }, []);

    const handleClickRefresh = () => {
        if (!isLoading) {
            dispatch(setMonitoringStatusLoading(serverId, true));
            dispatch(loadMonitoringStatus(serverId));
        }
    };

    const handleClickClose = () => {
        dispatch(closeMonitoringTab());
    };

    return (
        <div className={style.wrapper} data-test="monitoring-tab_container">
            <InformationItem color={getScriptEngineStatusColor(status?.scriptEngineStatus)}>
                {intl.formatMessage(messages.monitoringScriptEngine)}
            </InformationItem>
            <InformationItem color={getImgServiceStatusColor(status?.imgServiceStatus)}>
                {intl.formatMessage(messages.monitoringImgService)}
            </InformationItem>
            {status?.ldapServers?.map((s) => (
                <InformationItem color={getLdapStatusColor(s.status)}>
                    {`${intl.formatMessage(messages.monitoringLdapService)}: ${s.serverName}`}
                </InformationItem>
            ))}
            {status?.keycloakStatus ? (
                <InformationItem color={getKeycloakServiceStatusColor(status.keycloakStatus)}>
                    {intl.formatMessage(messages.monitoringKeycloakService)}
                </InformationItem>
            ) : null}

            <InformationItem color={getmailServiceStatusColor(status?.mailServerStatus)}>
                {intl.formatMessage(messages.monitoringMailServer)}
            </InformationItem>
            <div className={style['button-wrapper']}>
                <Button size="large" type="primary" loading={isLoading} onClick={handleClickRefresh}>
                    {intl.formatMessage(messages.monitoringRefresh)}
                </Button>
                <Button size="large" onClick={handleClickClose}>
                    {intl.formatMessage(messages.monitoringClose)}
                </Button>
            </div>
        </div>
    );
};

const MonitoringIntl = injectIntl(Monitoring);

export { MonitoringIntl as Monitoring };
