// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PresetProfileGeneralSettings__serverProfileSelect___4H_5{width:300px;margin-top:15px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/PresetProfile/PresetProfileGeneralSettings/PresetProfileGeneralSettings.scss"],"names":[],"mappings":"AAAA,0DACI,WAAA,CACA,eAAA","sourcesContent":[".serverProfileSelect {\r\n    width: 300px;\r\n    margin-top: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serverProfileSelect": "PresetProfileGeneralSettings__serverProfileSelect___4H_5"
};
export default ___CSS_LOADER_EXPORT___;
