import { BPMMxGraph } from './bpmgraph';
import { CustomMap } from '../utils/map';
import { NodeId } from '../serverapi/api';

export const instancesBPMMxGraphMap: CustomMap<NodeId, BPMMxGraph> = new CustomMap();

export const getGraphByServerAndRepository = (serverId: string, repositoryId: string) =>
    instancesBPMMxGraphMap.keys()
        .filter((t) => t.repositoryId === repositoryId && t.serverId === serverId)
        .filter((t) => instancesBPMMxGraphMap.has(t))
        .map((t) => instancesBPMMxGraphMap.get(t));


