import { TFavoritesState } from '@/reducers/favorites.reducer.types';
import type { TRootState } from '@/reducers/root.reducer.types';
import { FavoriteNodeDTO } from '@/serverapi/api';
import { createSelector } from 'reselect';

export namespace FavoritesSelectors {
    export const getState = (state: TRootState) => state.favorites;
    export const nodes = createSelector<TRootState, TFavoritesState, FavoriteNodeDTO[]>(getState, (favorites) =>
        favorites.nodes.sort((a, b) => {
            if (a.addedAt && b.addedAt) {
                return b.addedAt - a.addedAt;
            }
            return 0;
        }),
    );
}
