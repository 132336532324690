import { get } from 'lodash';
import { TAuditActions } from '../actions/audit.actions.types';
import {
    DOWNLOAD_CSV,
    FINISH_DOWNLOAD_CSV,
    FETCH_AUDIT,
    FETCH_AUDIT_SUCCESS,
    FETCH_DETAILED_AUDIT_SUCCESS,
    SET_AUDIT_FILTERED_TYPES,
    SET_AUDIT_FILTERED_ACCESSES,
} from '../actionsTypes/audit.actionTypes';
import { CustomMap } from '../utils/map';
import { IAuditServerState, IAuditState } from './audit.reducer.types';

const initFunc = () => {
    return {
        byServerId: { id: { meta: null, data: [], detailedAudit: new CustomMap() } as IAuditServerState },

        statusDownload: false,
        statusAudit: false,
        filteredTypes: [],
        filteredAccesses: [],
    } as IAuditState;
};

export const auditReducer = (state = initFunc(), action: TAuditActions) => {
    switch (action.type) {
        case FETCH_AUDIT: {
            return {
                ...state,
                statusAudit: true,
            };
        }
        case FETCH_AUDIT_SUCCESS: {
            const { serverId, audit } = action;

            return {
                ...state,
                statusAudit: false,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...get(state, ['byServerId', serverId, 'meta'], { meta: null }),
                        data: [...audit],
                    },
                },
            };
        }
        case FETCH_DETAILED_AUDIT_SUCCESS: {
            const {
                payload: { serverId, detailedAudit },
            } = action;
            const mapClone = state.byServerId[serverId]?.detailedAudit?.clone() || new CustomMap();
            mapClone?.set({ id: detailedAudit.id }, detailedAudit);

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...state.byServerId[serverId],
                        detailedAudit: mapClone,
                    },
                },
            };
        }
        case DOWNLOAD_CSV: {
            return {
                ...state,
                statusDownload: true,
            };
        }
        case FINISH_DOWNLOAD_CSV: {
            return {
                ...state,
                statusDownload: false,
            };
        }
        case SET_AUDIT_FILTERED_TYPES: {
            const { filteredTypes } = action.payload;

            return {
                ...state,
                filteredTypes,
            };
        }
        case SET_AUDIT_FILTERED_ACCESSES: {
            const { filteredAccesses } = action.payload;

            return {
                ...state,
                filteredAccesses,
            };
        }

        default:
            return state;
    }
};
