import { MxCell, MxGeometry, MxGraph, MxObjectCodec, MxPoint, MxUtils } from '../mxgraph';

export class GraphSerializer extends MxObjectCodec {
    constructor() {
        super((value: any) => {}, undefined!, undefined!, undefined!);
    }

    encode(value: any) {
        const xmlDoc = MxUtils.createXmlDocument();
        const newObject = xmlDoc.createElement('Object');
        if (typeof value === 'object') {
            for (const prop in value) {
                newObject.setAttribute(prop, value[prop]);
            }
        }

        return newObject;
    }

    decode(model: any) {
        return Object.keys(model.cells)
            .map((iCell) => {
                return model.getCell(iCell);
            })
            .filter((item) => !!item);
    }

    getJsonModel(graph: MxGraph) {
        const jsonModel = this.decode(graph.getModel());

        return {
            graph: jsonModel,
        };
    }

    stringify(graph: MxGraph): string {
        const jsonNodes = this.getJsonModel(graph);
        jsonNodes.graph = jsonNodes.graph.filter((c) => c.parent && c.parent.id === '1');

        return this.stringifyWithoutCircular(jsonNodes);
    }

    stringifyWithoutCircular(json: any) {
        return JSON.stringify(
            json,
            (key, value) => {
                if (value && (key === 'parent' || key == 'source' || key == 'target')) {
                    return value.id;
                } if (key === 'value' && value && value.localName) {
                    const results = {};
                    Object.keys(value.attributes).forEach((attrKey) => {
                        const attribute = value.attributes[attrKey];
                        results[attribute.nodeName] = attribute.nodeValue;
                    });

                    return results;
                }

                return value;
            },
            4,
        );
    }
}

export function toMxCell(node: any): MxCell {
    if (node) {
        let geometry = new MxGeometry(0, 0, 100, 100);
        if (node.geometry) {
            geometry = new MxGeometry(
                node.parent.id === '1' ? 0 : node.geometry.x,
                node.parent.id === '1' ? 0 : node.geometry.y,
                node.geometry.width,
                node.geometry.height,
            );
            geometry.relative = node.geometry.relative;

            if (node.geometry.sourcePoint) {
                geometry.sourcePoint = new MxPoint(node.geometry.sourcePoint.x, node.geometry.sourcePoint.y);
            }
            if (node.geometry.targetPoint) {
                geometry.targetPoint = new MxPoint(node.geometry.targetPoint.x, node.geometry.targetPoint.y);
            }
            if (node.geometry.offset) {
                geometry.offset = new MxPoint(node.geometry.offset.x, node.geometry.offset.y);
            }
        }
        geometry.points = node.geometry?.points?.map((point) => new MxPoint(point.x, point.y));
        const cell = new MxCell(node.value, geometry, node.style);
        cell.id = node.id;
        cell.setVertex(node.vertex);
        cell.setEdge(node.edge);
        cell.setConnectable(node.connectable);
        cell.mxObjectId = node.mxObjectId;
        cell.setParent(node.parent);

        // cell.edges = this.toMxCells(node.edges, node);
        return cell;
    }
 
    return undefined!;
    
}

export function toMxCells(nodes: any[], parent: any): MxCell[] {
    if (nodes) {
        return nodes.map((node: any) => {
            const cell = toMxCell(node);
            cell.setParent(toMxCell(parent));
            cell.children = toMxCells(node.children, node);

            return cell;
        });
    }
 
    return undefined!;
    
}
