import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { exportPreset } from '../../../../../actions/methodologySetting.actions';
import {
    createModelType,
    deleteModelType,
    editModelType,
    transferModelType,
} from '../../../../../actions/presetSettings/presetSettingsModelType.actions';
import { TPreset } from '../../../../../models/preset.types';
import { TreeNode } from '../../../../../models/tree.types';
import icFolder from '../../../../../resources/icons/ic-tree-folder.svg';
import icModel from '../../../../../resources/icons/ic-tree-model-active.svg';
import { getCurrentLocale } from '../../../../../selectors/locale.selectors';
import { PresetSettingsModelTypeSelectors } from '../../../../../selectors/presetSettings/presetSettingsModelType.selectors';
import {
    InternationalString,
    ModelType,
    ModelTypeGroup,
    PresetElementTransferType,
    KanbanBoardType,
} from '../../../../../serverapi/api';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { TreeItemType } from '../../../../Tree/models/tree';
import messages from '../../messages/Presets.messages';
import { GroupSelectionDialog } from './Dialog/GroupSelectionDialog.component';
import { DeleteSelected, ExportButton, ImportButton, MoveSelected, TabHeader } from './Header/TabHeader.component';
import { GroupedTypesTable } from './util/GroupedTypesTable.component';
import theme from './Presets.scss';
import { LocalesService } from '../../../../../services/LocalesService';
import { PresetSettingsKanbanModelTypeSelectors } from '../../../../../selectors/presetSettings/presetSettingsKanbanModelType.selectors';
import { TTableDataType } from './util/GroupedTypesTable.types';
import {
    deleteKanbanModelType,
    editKanbanModelType,
    transferKanbanModelType,
} from '../../../../../actions/presetSettings/presetSettingsKanbanModelType.actions';
import { isKanbanBoardType } from './util/KanbanCardEditor.utils';
import { Alert } from 'antd';
import { getMetodologyElementsDeleteMessages } from './util/metodologyElementsDeleteMessages.utils';
import { ExperimentalFeatures } from '@/models/ExperimentalFeatures';

type TModelTypesTablProps = {
    modelTypeGroups: ModelTypeGroup[];
    disabled: boolean;
    serverNode: TreeNode;
    preset: TPreset;
    editModelTypeGroup: (modelTypeGroup: ModelTypeGroup) => void;
    createModelTypeGroup: () => void;
    deleteModelTypeGroups: (ModelTypeGroups: ModelTypeGroup[]) => void;
};

const ModelTypesTab: FC<TModelTypesTablProps> = (props) => {
    const {
        disabled,
        serverNode,
        preset,
        modelTypeGroups,
        createModelTypeGroup,
        deleteModelTypeGroups,
        editModelTypeGroup,
    } = props;
    const presetId = preset.id;
    const { serverId } = serverNode.nodeId;
    const intl = useIntl();
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [selectedModelTypes, setSelectedModelTypes] = useState<ModelType[]>([]);
    const [selectedModelTypeGroups, setSelectedModelTypeGroups] = useState<ModelTypeGroup[]>([]);
    const [selectGroupDialogVisible, setSelectGroupDialogVisible] = useState<boolean>(false);
    const [saveRequired, setSaveRequired] = useState<boolean>(false);
    const currentLocale = useSelector(getCurrentLocale);

    const selected = selectedModelTypes.length || selectedModelTypeGroups.length;

    const dispatch = useDispatch();
    const modelTypesData = useSelector(PresetSettingsModelTypeSelectors.byServerIdPresetId({ serverId, presetId }));
    const kanbanModelTypesData = useSelector(PresetSettingsKanbanModelTypeSelectors.byPresetId(presetId));
    const modelTypes = Object.values(modelTypesData.byId || {});
    const kanbanModelTypes = Object.values(kanbanModelTypesData.byId || {}).map((modelType) => {
        return {
            ...modelType,
        };
    }) as unknown as TTableDataType[];
    const { deleteMessage: deleteModelsMessage, deleteGroupsMessage } = getMetodologyElementsDeleteMessages({
        selectedModelTypes,
        selectedModelTypeGroups,
    });

    return (
        <div className={theme.container}>
            <TabHeader
                buttons={[
                    {
                        name: messages.newModel,
                        icon: icModel,
                        disabled: disabled || !modelTypeGroups?.length,
                        onAction: () => {
                            ExperimentalFeatures.isEnable()
                                ? dispatch(
                                      openDialog(DialogType.SELECT_MODEL_TYPE, {
                                          serverNode,
                                          preset,
                                      }),
                                  )
                                : dispatch(
                                      createModelType({
                                          serverNode,
                                          preset,
                                      }),
                                  );
                        },
                    },
                    {
                        name: messages.newGroup,
                        icon: icFolder,
                        disabled,
                        onAction: createModelTypeGroup,
                    },
                    ImportButton.build(
                        () => {
                            dispatch(
                                openDialog(DialogType.UPLOAD_PRESET_DIALOG, {
                                    serverNode,
                                    type: PresetElementTransferType.model,
                                    preset,
                                    filters: '.xml',
                                }),
                            );
                        },
                        messages.import,
                        disabled,
                        saveRequired,
                    ),
                    ExportButton.build(
                        () => {
                            dispatch(
                                exportPreset(
                                    preset,
                                    serverId,
                                    PresetElementTransferType.model,
                                    selectedModelTypes.map((mt) => mt.id),
                                ),
                            );
                        },
                        messages.export,
                        !(modelTypes.length || modelTypeGroups.length),
                        saveRequired,
                    ),
                    DeleteSelected.build(
                        () => {
                            if (selectedModelTypes.length) {
                                const modelTypesForDelete = selectedModelTypes.filter(
                                    (type) => !isKanbanBoardType(type),
                                );

                                const kanbanModelTypesForDelete = selectedModelTypes.filter((type) =>
                                    isKanbanBoardType(type),
                                ) as unknown as KanbanBoardType[];

                                dispatch(
                                    deleteKanbanModelType({
                                        presetId,
                                        kanbanModelTypesForDelete,
                                    }),
                                );
                                dispatch(deleteModelType({ serverId, presetId, modelTypesForDelete }));
                                setSelectedModelTypes([]);
                            }
                            if (selectedModelTypeGroups.length) {
                                setSelectedModelTypeGroups([]);
                                deleteModelTypeGroups(selectedModelTypeGroups);
                            }
                            setSaveRequired(true);
                        },
                        disabled || !selected,
                        undefined,
                        intl.formatMessage(messages.deleteModelsDialogTitle),
                        <Alert
                            message={
                                <>
                                    {deleteGroupsMessage}
                                    {deleteModelsMessage}
                                </>
                            }
                            type="warning"
                        />,
                    ),
                    MoveSelected.build(() => setSelectGroupDialogVisible(true), disabled || !selected),
                ]}
                onSearchChange={setSearchFilter}
            />
            <GroupedTypesTable
                types={modelTypes.filter((mt) => mt.id !== TreeItemType.Wiki.toLowerCase())}
                kanbanTypes={kanbanModelTypes}
                typeGroups={modelTypeGroups}
                searchFilter={searchFilter}
                actionsDisabled={disabled}
                icon={icModel}
                onSelectType={setSelectedModelTypes as any}
                onSelectGroup={setSelectedModelTypeGroups as any}
                onDeleteType={(modelType: ModelType) => {
                    setSaveRequired(true);
                    dispatch(deleteModelType({ serverId, presetId, modelTypesForDelete: [modelType] }));
                }}
                onDeleteKanbanType={(kanbanModelType: KanbanBoardType) => {
                    setSaveRequired(true);
                    dispatch(
                        deleteKanbanModelType({
                            presetId,
                            kanbanModelTypesForDelete: [kanbanModelType],
                        }),
                    );
                }}
                onDeleteGroup={(modelTypeGroup: ModelTypeGroup) => {
                    setSaveRequired(true);
                    deleteModelTypeGroups([modelTypeGroup]);
                }}
                onEditType={(modelType: ModelType) => {
                    dispatch(
                        editModelType({
                            serverNode,
                            preset,
                            modelType,
                        }),
                    );
                }}
                onEditKanbanType={(kanbanModelType: KanbanBoardType) => {
                    dispatch(
                        editKanbanModelType({
                            serverNodeId: serverNode.nodeId,
                            kanbanModelType,
                            presetId: preset.id,
                        }),
                    );
                }}
                onEditGroup={editModelTypeGroup}
                columns={[
                    {
                        title: intl.formatMessage(messages.description),
                        dataIndex: 'multilingualDescription',
                        render: (value: InternationalString) =>
                            LocalesService.internationalStringToString(value, currentLocale),
                    },
                ]}
                deleteGroupMessage={messages.deleteGroupModels}
                deleteElMessage={messages.deleteModel}
            />
            {selectGroupDialogVisible && (
                <GroupSelectionDialog
                    groups={modelTypeGroups}
                    onSubmit={(group) => {
                        if (group) {
                            const modelTypesForTransfer = selectedModelTypes.filter((type) => !isKanbanBoardType(type));
                            const kanbanModelTypesForTransfer = selectedModelTypes.filter((type) =>
                                isKanbanBoardType(type),
                            ) as unknown as KanbanBoardType[];

                            dispatch(
                                transferKanbanModelType({
                                    presetId,
                                    kanbanModelTypes: kanbanModelTypesForTransfer.map((modelType) => ({
                                        ...modelType,
                                        groupId: group.id,
                                    })),
                                }),
                            );
                            dispatch(
                                transferModelType({
                                    presetId,
                                    serverId,
                                    modelTypes: modelTypesForTransfer.map((modelType) => ({
                                        ...modelType,
                                        groupId: group.id,
                                        modelTypeGroup: group as ModelTypeGroup,
                                    })),
                                }),
                            );
                        }
                        setSelectGroupDialogVisible(false);
                        setSaveRequired(true);
                    }}
                    onClose={() => setSelectGroupDialogVisible(false)}
                />
            )}
        </div>
    );
};

export { ModelTypesTab };
