import { Button, Checkbox, Form, FormInstance, Input, Popover, Select, Slider } from 'antd';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { MethodologiesGraph } from '../../../../../../mxgraph/MethodologiesGraph';
import { MxConstants } from '../../../../../../mxgraph/mxgraph';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { EdgeType, EdgeTypeDirectionEnum, EdgeTypeGroup, InternationalString } from '../../../../../../serverapi/api';
import { getStyleValueByStyleKey } from '../../../../../../utils/css.utils';
import { FileUploadDialog } from '../../../../../FileUpload/components/FileUploadDialog.component';
import { MultiLangInputDialog } from '../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import theme from '../Presets.scss';
import { SymbolToImageConverterGraph } from '../SymbolToImageConverterGraph.component';
import messages from './EdgeType.messages';
import edgeTypeDirectionMessages from '../../../../../../models/edge-type-direction.messages';
import STYLE_ARCSIZE = MxConstants.STYLE_ARCSIZE;
import STYLE_STROKECOLOR = MxConstants.STYLE_STROKECOLOR;
import STYLE_STROKEWIDTH = MxConstants.STYLE_STROKEWIDTH;
import { LocalesService } from '../../../../../../services/LocalesService';
import { InputId } from '../../../../../InputId/InputId.component';
import { BPMMxConstants } from '../../../../../../mxgraph/bpmgraph.constants';
import { InputSynonymsIds } from '../../../../../InputSynonymsIds/InputSynonymsIds.component';

type TGeneralTabProps = {
    edgeType: EdgeType;
    createMode: boolean;
    edgeTypeGroups: EdgeTypeGroup[];
    generalForm: FormInstance<any> | undefined;
    exportStyle: () => void;
    onChangeEdgeTypeGroup: (edgeTypeGroup: EdgeTypeGroup) => void;
    onChangeEdgeTypeName: (value: InternationalString) => void;
    onChangeEdgeTypeId: (id: string) => void;
    onChangeEdgeTypeStyle: (style: string) => void;
    onChangeEdgeTypeDescription: (value: InternationalString) => void;
    onChangeEdgeTypeInvisible: (invisible: boolean) => void;
    onChangeEdgeTypeDirection: (direction: EdgeTypeDirectionEnum) => void;
    onChangeEdgeTypeSynonymsIds: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangeEdgeTypeAlwaysCreateDefintion: (alwaysCreateDefintion: boolean) => void;
    onChangeEdgeTypeApprovals: (allowApprovals: boolean) => void
};

type TDefineMessages = {
    id: string;
    defaultMessage: string;
};

const DEFAULT_STROKE_COLOR: string = '#0088cc';
const DEFAULT_DASHED: string = '0'; // MxConstants.NONE;
const DEFAULT_START_ARROW: string = MxConstants.NONE;
const DEFAULT_END_ARROW: string = MxConstants.ARROW_BLOCK;
const DEFAULT_STROKE_WIDTH: string = '1';
const DEFAULT_ARC_SIZE: string = '20';

const ARROW_TRANSLATION_MAP: { [key: string]: TDefineMessages } = {
    [MxConstants.NONE]: messages.no,
    [MxConstants.ARROW_DIAMOND]: messages.diamond,
    [MxConstants.ARROW_DIAMOND_THIN]: messages.diamondThin,
    [MxConstants.ARROW_BLOCK]: messages.block,
    [MxConstants.ARROW_BLOCK_THIN]: messages.blockThin,
    [MxConstants.ARROW_CLASSIC]: messages.classic,
    [MxConstants.ARROW_CLASSIC_THIN]: messages.classicThin,
    [MxConstants.ARROW_OPEN]: messages.open,
    [MxConstants.ARROW_OPEN_THIN]: messages.openThin,
    [MxConstants.ARROW_OVAL]: messages.oval,
    [BPMMxConstants.ARROW_CONTAINMENT]: messages.containment,
};

const EDGE_DIRECTION_MAP: { [key: string]: TDefineMessages } = {
    SRC_TO_DST: edgeTypeDirectionMessages.SRC_TO_DST,
    NO_DIRECTION: edgeTypeDirectionMessages.NO_DIRECTION,
};

export const GeneralTab: FC<TGeneralTabProps> = (props) => {
    const { createMode, edgeType, generalForm, edgeTypeGroups, onChangeEdgeTypeStyle, onChangeEdgeTypeDirection } =
        props;
    const { edgeStyle } = edgeType;
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();

    const [importDialogVisible, setImportDialogVisible] = useState(false);
    const [graph, setGraph] = useState<MethodologiesGraph | undefined>(undefined);

    const selectContainerRef = React.useRef<HTMLDivElement>(null);

    let actualDirection: TDefineMessages | undefined;

    if (edgeType.direction === 'DST_TO_SRC') {
        actualDirection = EDGE_DIRECTION_MAP.SRC_TO_DST;
    } else if (edgeType.direction === 'BIDIRECTIONAL') {
        actualDirection = EDGE_DIRECTION_MAP.NO_DIRECTION;
    }

    const selectValue = intl.formatMessage(
        actualDirection || EDGE_DIRECTION_MAP[edgeType.direction] || edgeTypeDirectionMessages.NO_DIRECTION,
    );

    useEffect(() => {
        return () => {
            // componentWillUnmount
            graph?.destroy();
        };
    }, []);

    const converterInitialized = (newGraph: MethodologiesGraph) => {
        if (!graph) {
            setGraph(newGraph);
        }
    };

    const onChangeEdgeTypeGroup = (groupId: string) => {
        const changeGroup = edgeTypeGroups.find((etg) => etg.id === groupId);
        if (changeGroup) {
            props.onChangeEdgeTypeGroup(changeGroup);
        }
    };

    const onChangeStyle = (key: string, value: string) => {
        if (graph) {
            const edge = graph.getModel().getCell(edgeType.id);
            graph.setCellStyles(key, value, [edge]);
            onChangeEdgeTypeStyle(graph.getModel().getStyle(edge));
        }
    };

    const onClose = () => {
        setImportDialogVisible(false);
    };

    const importStyleSubmit = (file: File) => {
        file.text().then((importedEdgeStyle) => onChangeEdgeTypeStyle(importedEdgeStyle));
        onClose();
    };

    const importStyleOpenDialog = () => {
        setImportDialogVisible(true);
    };

    return (
        <div ref={selectContainerRef} className={theme.form}>
            <FileUploadDialog
                visible={importDialogVisible}
                serverId=""
                onSubmit={importStyleSubmit}
                onClose={onClose}
                id=""
            />
            <div className={theme.inputGroup}>
                <div>
                    <MultiLangInputDialog
                        placeholder={intl.formatMessage(messages.name)}
                        multiLangValue={edgeType.multilingualName}
                        onChange={props.onChangeEdgeTypeName}
                        label={intl.formatMessage(messages.name)}
                        mainInputName="multilingualName"
                        generalForm={generalForm}
                        required
                        autoFocus
                        data-test="edge-type-name-input"
                    />
                </div>
                <div className={theme.guidInput}>
                    <InputId
                        disabled={!createMode}
                        value={edgeType.id}
                        required
                        mainInputName="GUID"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            props.onChangeEdgeTypeId(e.target.value.trim());
                        }}
                    />
                </div>
                <div className={theme.selectGroupType}>
                    <Form.Item
                        label={intl.formatMessage(messages.edgeTypeGroups)}
                        name="edgeTypeGroup"
                        initialValue={edgeType.edgeTypeGroup?.id}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage(messages.requiredField),
                            },
                        ]}
                    >
                        <Select
                            data-test="edge-type-group-select"
                            className={theme.selectGroupType}
                            onChange={onChangeEdgeTypeGroup}
                            getPopupContainer={() => selectContainerRef.current || document.body}
                        >
                            {edgeTypeGroups.map((group: EdgeTypeGroup) => (
                                <Select.Option key={group.id} value={group.id} data-test="edge-select-option-GroupType">
                                    {LocalesService.internationalStringToString(group.multilingualName, currentLocale)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className={theme.guidInput}>
                        <InputSynonymsIds
                            value={edgeType.synonymsIds}
                            mainInputName="synonymsIds"
                            onChange={props.onChangeEdgeTypeSynonymsIds}
                        />
                    </div>
                    <div className={theme.description}>
                        <MultiLangInputDialog
                            placeholder={intl.formatMessage(messages.description)}
                            multiLangValue={edgeType.multilingualDescription}
                            onChange={props.onChangeEdgeTypeDescription}
                            textarea
                            label={intl.formatMessage(messages.description)}
                            mainInputName="multilingualDescription"
                            generalForm={generalForm}
                            data-test="edge-type-description-input"
                        />
                    </div>
                    <div className={theme.edgeTypeGeneralCheckboxWrap}>
                        <Checkbox
                            checked={edgeType.canBeInvisible}
                            onChange={(e) => props.onChangeEdgeTypeInvisible(e.target.checked)}
                            data-test="invisible-edge-type_checkbox"
                        >
                            {intl.formatMessage(messages.canBeInvisible)}
                        </Checkbox>
                        <Checkbox
                            checked={edgeType.alwaysCreateDefinition}
                            onChange={(e) => props.onChangeEdgeTypeAlwaysCreateDefintion(e.target.checked)}
                            data-test="auto-definition-edge-type_checkbox"
                        >
                            {intl.formatMessage(messages.alwaysCreateDefintion)}
                        </Checkbox>
                        <Checkbox
                            checked={edgeType.allowApprovals}
                            onChange={(e) => props.onChangeEdgeTypeApprovals(e.target.checked)}
                            data-test="approvals-edge-type_checkbox"
                        >
                            {intl.formatMessage(messages.allowEdgeApproval)}
                        </Checkbox>
                    </div>
                </div>
            </div>
            <div className={theme.edgePreviewSettings}>
                <div className={theme.edgePreviewRow}>
                    <div className={theme.edgePreviewColumn}>
                        <Button type="primary" onClick={importStyleOpenDialog}>
                            {intl.formatMessage(messages.importStyle)}
                        </Button>
                    </div>
                    <div className={theme.edgePreviewColumn}>
                        <Button onClick={props.exportStyle} type="primary">
                            {intl.formatMessage(messages.exportStyle)}
                        </Button>
                    </div>
                </div>
                <div className={theme.edgePreviewRow}>
                    <div className={theme.edgePreviewColumn}>
                        <span>{intl.formatMessage(messages.fullStyle)}</span>
                        <br />
                        <Input
                            className={theme.inputStyle}
                            value={edgeStyle}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                onChangeEdgeTypeStyle(event.target.value.trim())
                            }
                            data-test="edge-full-style"
                        />
                    </div>
                </div>
                <div className={theme.edgePreviewRow}>
                    <div className={theme.edgePreviewColumn}>
                        <div>
                            <span>{intl.formatMessage(messages.lineType)}</span>
                        </div>
                        <Select
                            className={theme.selectArrow}
                            value={getStyleValueByStyleKey(MxConstants.STYLE_DASHED, edgeStyle) || DEFAULT_DASHED}
                            onChange={(value: string) => onChangeStyle(MxConstants.STYLE_DASHED, value)}
                            data-test="edge-select-lineType"
                            getPopupContainer={() => selectContainerRef.current || document.body}
                        >
                            <Select.Option value="0" data-test="edge-select-lineType-solid">
                                {intl.formatMessage(messages.solid)}
                            </Select.Option>
                            <Select.Option value="1" data-test="edge-select-lineType-intermittent">
                                {intl.formatMessage(messages.intermittent)}
                            </Select.Option>
                        </Select>
                        <div className={theme.selectorContainer}>
                            <div>
                                <span>{intl.formatMessage(messages.color)}</span>
                            </div>
                            <Popover
                                content={
                                    <SketchPicker
                                        color={
                                            getStyleValueByStyleKey(STYLE_STROKECOLOR, edgeStyle) ||
                                            DEFAULT_STROKE_COLOR
                                        }
                                        onChange={(color: ColorResult) => onChangeStyle(STYLE_STROKECOLOR, color.hex)}
                                        disableAlpha
                                    />
                                }
                                trigger="click"
                            >
                                <div className={theme.colorPickerBtn}>
                                    <div
                                        style={{
                                            backgroundColor: `${
                                                getStyleValueByStyleKey(STYLE_STROKECOLOR, edgeStyle) ||
                                                DEFAULT_STROKE_COLOR
                                            }`,
                                        }}
                                        className={theme.edgeBox}
                                    />
                                    <div>
                                        <span className={theme.colorPickerText} data-test="edge-color-picker">
                                            {getStyleValueByStyleKey(STYLE_STROKECOLOR, edgeStyle)?.slice(0, 7) ||
                                                DEFAULT_STROKE_COLOR}
                                        </span>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                        <div className={theme.selectorContainer}>
                            <div>
                                <span>{intl.formatMessage(messages.lineWidth)}</span>
                            </div>
                            <div data-test="edge-style-strokewidth">
                                <Slider
                                    className={theme.sliderControl}
                                    included={false}
                                    value={Number(
                                        getStyleValueByStyleKey(STYLE_STROKEWIDTH, edgeStyle) || DEFAULT_STROKE_WIDTH,
                                    )}
                                    onChange={(value) => onChangeStyle(STYLE_STROKEWIDTH, Number(value).toString())}
                                    min={1}
                                    max={50}
                                />
                            </div>
                        </div>
                        <div className={theme.selectorContainer}>
                            <div>
                                <span>{intl.formatMessage(messages.lineAngle)}</span>
                            </div>
                            <div data-test="edge-style-arcsize">
                                <Slider
                                    className={theme.sliderControl}
                                    included={false}
                                    value={Number(
                                        getStyleValueByStyleKey(STYLE_ARCSIZE.toString(), edgeStyle) ||
                                            DEFAULT_ARC_SIZE,
                                    )}
                                    onChange={(value) => {
                                        onChangeStyle(STYLE_ARCSIZE.toString(), Number(value).toString());
                                    }}
                                    min={0}
                                    max={2500}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={theme.edgePreviewColumn}>
                        <div>
                            <span>{intl.formatMessage(messages.lineStart)}</span>
                        </div>

                        <Select
                            className={theme.selectArrow}
                            value={
                                getStyleValueByStyleKey(MxConstants.STYLE_STARTARROW, edgeStyle) || DEFAULT_START_ARROW
                            }
                            onChange={(value: string) => {
                                onChangeStyle(MxConstants.STYLE_STARTARROW, value);
                            }}
                            data-test="edge-select-lineStart"
                            getPopupContainer={() => selectContainerRef.current || document.body}
                        >
                            {Object.keys(ARROW_TRANSLATION_MAP).map((key) => (
                                <Select.Option key={key} value={key} data-test={`edge-select-option-lineStart_${key}`}>
                                    {intl.formatMessage(ARROW_TRANSLATION_MAP[key])}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className={theme.edgePreviewColumn}>
                        <div>
                            <span>{intl.formatMessage(messages.lineEnd)}</span>
                        </div>

                        <Select
                            className={theme.selectArrow}
                            value={getStyleValueByStyleKey(MxConstants.STYLE_ENDARROW, edgeStyle) || DEFAULT_END_ARROW}
                            onChange={(value: string) => {
                                onChangeStyle(MxConstants.STYLE_ENDARROW, value);
                            }}
                            data-test="edge-select-lineEnd"
                            getPopupContainer={() => selectContainerRef.current || document.body}
                        >
                            {Object.keys(ARROW_TRANSLATION_MAP).map((key) => (
                                <Select.Option key={key} value={key} data-test={`edge-select-option-lineEnd_${key}`}>
                                    {intl.formatMessage(ARROW_TRANSLATION_MAP[key])}
                                </Select.Option>
                            ))}
                        </Select>
                        <div>
                            <span className={theme.edgePreviewRow}>{intl.formatMessage(messages.preview)}</span>
                            <div data-test="edge-graph-container" className={theme.edgeGraphContainer}>
                                <SymbolToImageConverterGraph initialized={converterInitialized} />
                                {SymbolToImageConverterGraph.convertEdge(edgeType, intl, graph, 100, 200, false)}
                            </div>
                        </div>
                    </div>
                    <div className={theme.edgePreviewColumn}>
                        <div>
                            <span>{intl.formatMessage(messages.lineDirection)}</span>
                        </div>

                        <Select
                            className={theme.selectArrow}
                            value={selectValue}
                            onChange={(value: string) => {
                                onChangeEdgeTypeDirection(value as EdgeTypeDirectionEnum);
                            }}
                            data-test="edge-select-direction"
                            getPopupContainer={() => selectContainerRef.current || document.body}
                        >
                            {Object.keys(EDGE_DIRECTION_MAP).map((key) => (
                                <Select.Option key={key} value={key} data-test="edge-select-option-direction">
                                    {intl.formatMessage(EDGE_DIRECTION_MAP[key])}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
        </div>
    );
};
