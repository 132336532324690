import React, { useRef, useState } from 'react';
import { Dropdown } from 'antd';
import { useIntl } from 'react-intl';
import messages from './TableRenderer.messages';
import BlockTree from './blockTree';
import { getSelectedCells } from './TableMultiSelection.utils';

function getSelectedCellsForElement(cellElement) {
    return getSelectedCells(cellElement.closest('table')) || [];
}

export const WikiTableContextMenuComponent = ({ children, editor, block }) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const ref = useRef<HTMLDivElement | null>(null);

    const onClick = (action) => {
        const { editorState } = editor.state;
        const { onChange } = editor;
        const blockTree = new BlockTree(editorState, onChange);
        const { key } = action;

        if (key === 'setBackground') {
            blockTree.setBlockBGColor(block);
        }

        if (key === 'clearCell') {
            blockTree.clearTableCell(block);
        }

        if (key === 'insertColumn') {
            blockTree.insertColumn(block);
        }

        if (key === 'insertRow') {
            blockTree.insertRow(block);
        }

        if (key === 'deleteRow') {
            blockTree.deleteRow(block);
        }

        if (key === 'deleteColumn') {
            blockTree.deleteColumn(block);
        }
        if (key === 'unionCells') {
            const selectedCells = getSelectedCellsForElement(ref.current);
            blockTree.unionCells(selectedCells as any, block);
        }
        if (key === 'divideCell') {
            blockTree.divideCell(block);
        }
        if (key === 'headerRow') {
            blockTree.toggleHeader(block);
        }
    };

    const singleItems = [
        // temporary disabled
        // {
        //     label: formatMessage(messages.setBackground),
        //     key: 'setBackground',
        //     onClick,
        // },
        {
            label: formatMessage(messages.insertColumn),
            key: 'insertColumn',
            onClick,
        },
        {
            label: formatMessage(messages.insertRow),
            key: 'insertRow',
            onClick,
        },
        {
            label: formatMessage(messages.deleteColumn),
            key: 'deleteColumn',
            onClick,
        },
        {
            label: formatMessage(messages.deleteRow),
            key: 'deleteRow',
            onClick,
        },
        {
            label: formatMessage(messages.clearCell),
            key: 'clearCell',
            onClick,
        },
        {
            label: formatMessage(messages.divideCell),
            key: 'divideCell',
            onClick,
        },
        {
            label: formatMessage(messages.headerRow),
            key: 'headerRow',
            onClick,
        },
    ];

    const multiItems = [
        {
            label: formatMessage(messages.unionCells),
            key: 'unionCells',
            onClick,
        },
    ];

    const [items, setItems] = useState(singleItems);
    const [isOpen, setIsOpen] = useState(false);

    const onOpenChange = (value) => {
        const currentSelectedCells = getSelectedCellsForElement(ref.current);

        if (currentSelectedCells?.length > 1) {
            setItems(multiItems);
        } else {
            setItems(singleItems);
        }

        setIsOpen(value);
    };

    return (
        <Dropdown destroyPopupOnHide menu={{ items }} open={isOpen} trigger={['contextMenu']} onOpenChange={onOpenChange}>
            <div
                ref={ref}
                onContextMenu={(e) => {
                    e.stopPropagation();
                }}
            >
                {children}
            </div>
        </Dropdown>
    );
};

export default WikiTableContextMenuComponent;
