import { useDispatch, useSelector } from 'react-redux';
import {
    AttributeType,
    AttributeValue,
    AttributeValuePrincipal,
    AttributeValueString,
    AttributeValueUrl,
    InternationalString,
    PrincipalAttributeTypeSettings,
    PrincipalDescriptor,
} from '../../../../../../serverapi/api';
import { LocalesService } from '../../../../../../services/LocalesService';
import { TInputTypeEnum } from '../../../../../UIKit/components/EditableText/EditableText.types';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import {
    checkBooleanValue,
    formatUserName,
    getGroupsSelectedData,
    getUsersSelectedData,
    jsonParse,
} from '../../../../../ObjectPropertiesDialog/components/utils';
import React, { useState, MouseEvent } from 'react';
import { EditableText } from '../../../../../UIKit/components/EditableText/EditableText.component';
import { Button, Checkbox, ConfigProvider, DatePicker, TimePicker } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { MultiLangStringDialogComponent } from '../../../../../ObjectPropertiesDialog/components/MultiLangStringDialogComponent';
import { MultiLangUrlDialogComponent } from '../../../../../ObjectPropertiesDialog/components/MultiLangUrlDialogComponent';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { TPeriodRange } from '../../../../../../utils/date.time.utils.types';
import theme from './StorageDefaultValueToComponent.scss';
import { dateFormat, momentDateToTimestamp, timestampToMomentDate } from '../../../../../../utils/date.time.utils';
import { TValueTypeEnum } from '../../../../../../models/ValueTypeEnum.types';
import auditMessages from '../../../../ActionsAudit/audit.messages';
import GroupName from '../../../../../ObjectPropertiesDialog/components/PrincipalAttributeType/GroupName.component';
import UserLogin from '../../../../../ObjectPropertiesDialog/components/PrincipalAttributeType/UserLogin.component';
import { PrincipalsSelectors } from '../../../../../../selectors/principals.selectors';
import { openDialog } from '../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../DialogRoot/DialogRoot.constants';
import { ServerSelectors } from '../../../../../../selectors/entities/server.selectors';
import DeleteButton from '../../../../Button/DeleteButton';
import messages from '../AttributeType/AttributeType.messages';
import { DatePickerComponent } from './DatePickerComponent.component';
import { MultiSelectComponent } from './MultiSelectComponent.component.component';
import { SelectComponent } from './SelectComponent.component';
import { Locale } from '../../../../../Header/components/Header/header.types';
import { getAttrbuteValue } from './util/attributeTypeEditorDialog.utils';

export const StorageDefaultValueToComponent = ({
    attributeType,
    onChangeDefaultValue,
}: {
    attributeType: AttributeType;
    onChangeDefaultValue: (attributeValue: AttributeValue | undefined) => void;
}) => {
    const { valueType } = attributeType;

    const serverId: string = useSelector(ServerSelectors.serverId);
    const currentLocale: Locale = useSelector(getCurrentLocale);
    const dispatch = useDispatch();
    const intl = useIntl();
    const [editingField, setEditingField] = useState<string>('');
    const principals: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getAll);

    const curLocale: string = LocalesService.convertToString(currentLocale).toLowerCase();

    const isUrlType: boolean = valueType === 'URL' || valueType === 'NODE';
    let inputType: TInputTypeEnum = 'text';
    if (valueType === 'NUMERIC' || valueType === 'INTEGER') {
        inputType = 'number';
    } else if (valueType === 'MULTI_STRING') {
        inputType = 'textarea';
    }

    const handleChangeAttributeValue = (
        attributeType: AttributeType,
        formValues: InternationalString,
    ) => {
        onChangeDefaultValue({
            ...getAttrbuteValue(attributeType),
            str: formValues,
        } as AttributeValueString);
    };

    const onActivateEditable = (fieldName: string) => () => {
        setEditingField(fieldName);
    };
    const handleEditAttrBtn = (id: string) => (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (editingField !== id) {
            onActivateEditable(id)();
        }
    };
    const handlDeleteDefaultValue = () => {
        onChangeDefaultValue({} as AttributeValue);
    };

    switch (valueType) {
        case 'STRING':
        case 'MULTI_STRING': {
            const multiLingualStr: string =
                LocalesService.internationalStringToString(
                    (attributeType?.attributeDefaultValue as AttributeValueString)?.str,
                ) || '';
            const isEditing: boolean = attributeType.id === editingField;

            return (
                <div className={theme.editableTextContainer}>
                    <div className={valueType === 'STRING' ? theme.stringContainer : theme.textContainer}>
                        <EditableText
                            text={multiLingualStr}
                            isEditing={isEditing}
                            disabled={false}
                            onActivateEditable={onActivateEditable(attributeType.id)}
                            onDeactivateEditable={() => setEditingField('')}
                            onChange={(value: string) => {
                                onChangeDefaultValue({
                                    ...getAttrbuteValue(attributeType),
                                    str: {
                                        ...(attributeType.attributeDefaultValue as AttributeValueString)?.str,
                                        [currentLocale]: value,
                                    },
                                } as AttributeValueString);
                            }}
                            allowEmptyValue={true}
                            isUrlType={isUrlType}
                            inputType={inputType}
                        />
                    </div>
                    <>
                        <Button
                            data-test={`${valueType}_button`}
                            icon={<EditOutlined />}
                            onClick={handleEditAttrBtn(attributeType.id)}
                        />
                        <MultiLangStringDialogComponent
                            handleOk={(
                                attributeTypeId: string,
                                valueType: TValueTypeEnum,
                                formValues: InternationalString,
                            ) =>
                                handleChangeAttributeValue(
                                    attributeType,
                                    formValues,
                                )
                            }
                            record={attributeType?.attributeDefaultValue as AttributeValueString}
                            allowEmptyValue={true}
                            curLocale={curLocale}
                        />
                    </>
                    <DeleteButton
                        disabled={!multiLingualStr}
                        onDelete={handlDeleteDefaultValue}
                        deleteQuestion={intl.formatMessage(messages.clearAttributeDefaultValue)}
                    />
                </div>
            );
        }
        case 'JSON':
        case 'NUMERIC':
        case 'INTEGER': {
            const isEditing: boolean = attributeType.id === editingField;
            const text: string = attributeType.attributeDefaultValue?.value || '';

            return (
                <div className={theme.editableTextContainer}>
                    <div className={theme.stringContainer}>
                        <EditableText
                            text={text}
                            isEditing={isEditing}
                            disabled={false}
                            onActivateEditable={onActivateEditable(attributeType.id)}
                            onDeactivateEditable={() => setEditingField('')}
                            onChange={(value: number | string) => {
                                onChangeDefaultValue({
                                    ...getAttrbuteValue(attributeType),
                                    value: `${value}`,
                                });
                            }}
                            allowEmptyValue={true}
                            inputType={inputType}
                        />
                    </div>
                    <>
                        <Button
                            data-test={`${valueType}_button`}
                            icon={<EditOutlined />}
                            onClick={handleEditAttrBtn(attributeType.id)}
                        />
                        <DeleteButton
                            disabled={!text}
                            onDelete={handlDeleteDefaultValue}
                            deleteQuestion={intl.formatMessage(messages.clearAttributeDefaultValue)}
                        />
                    </>
                </div>
            );
        }
        case 'URL': {
            const urlText: string =
                LocalesService.internationalStringToString(
                    (attributeType.attributeDefaultValue as AttributeValueUrl)?.name,
                ) || '';
            const isEditing: boolean = attributeType.id === editingField;

            return (
                <div className={theme.editableTextContainer}>
                    <div className={theme.urlContainer}>
                        <EditableText
                            text={urlText}
                            isEditing={isEditing}
                            disabled={false}
                            onActivateEditable={onActivateEditable(attributeType.id)}
                            onDeactivateEditable={() => setEditingField('')}
                            onChange={(value: string) => {
                                onChangeDefaultValue({
                                    ...getAttrbuteValue(attributeType),
                                    url: (attributeType.attributeDefaultValue as AttributeValueUrl)?.url,
                                    name: {
                                        ...(attributeType.attributeDefaultValue as AttributeValueUrl)?.name,
                                        [currentLocale]: value,
                                    },
                                } as AttributeValueUrl);
                            }}
                            allowEmptyValue={true}
                            isUrlType={isUrlType}
                            inputType={inputType}
                        />
                    </div>
                    <>
                        <Button
                            data-test={`${valueType}_button`}
                            icon={<EditOutlined />}
                            onClick={handleEditAttrBtn(attributeType.id)}
                        />

                        <MultiLangUrlDialogComponent
                            handleOk={(
                                attributeTypeId: string,
                                valueType: TValueTypeEnum,
                                changedValues,
                                isInternationalString,
                            ) => {
                                onChangeDefaultValue({
                                    ...getAttrbuteValue(attributeType),
                                    name: changedValues.name,
                                    url: changedValues.url,
                                } as AttributeValueUrl);
                            }}
                            record={attributeType.attributeDefaultValue as AttributeValueUrl}
                        />
                        <DeleteButton
                            disabled={!urlText}
                            onDelete={handlDeleteDefaultValue}
                            deleteQuestion={intl.formatMessage(messages.clearAttributeDefaultValue)}
                        />
                    </>
                </div>
            );
        }
        case 'DATE_TIME':
        case 'DATE': {
            return <DatePickerComponent onChangeDefaultValue={onChangeDefaultValue} attributeType={attributeType} />;
        }
        case 'PERIOD': {
            const periodObj: TPeriodRange | null = jsonParse(
                attributeType.attributeDefaultValue?.value,
            ) as TPeriodRange | null;
            const defaultRange: [dayjs.Dayjs, dayjs.Dayjs] = [
                periodObj?.from ? timestampToMomentDate(+periodObj.from) : undefined,
                periodObj?.to ? timestampToMomentDate(+periodObj.to) : undefined,
            ] as [dayjs.Dayjs, dayjs.Dayjs];

            return (
                <div>
                    <ConfigProvider locale={LocalesService.checkAndSetLocale(intl.locale)}>
                        <DatePicker.RangePicker
                            data-test="select_attribute_period_input"
                            style={{ width: '100%' }}
                            className={theme.rangePicker}
                            format={dateFormat}
                            defaultValue={defaultRange}
                            ranges={{
                                [intl.formatMessage(auditMessages.today)]: [dayjs(), dayjs()],
                                [intl.formatMessage(auditMessages.currentMonth)]: [
                                    dayjs().startOf('month'),
                                    dayjs().endOf('month'),
                                ],
                            }}
                            onChange={(dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
                                const from = momentDateToTimestamp?.(dates?.[0]);
                                const to = momentDateToTimestamp?.(dates?.[1]);
                                const dateObj: TPeriodRange | string =
                                    from && to
                                        ? {
                                              from: from.toString(),
                                              to: to.toString(),
                                          }
                                        : '';
                                onChangeDefaultValue({
                                    ...getAttrbuteValue(attributeType),
                                    value: dateObj && JSON.stringify(dateObj),
                                });
                            }}
                        />
                    </ConfigProvider>
                </div>
            );
        }
        case 'BOOLEAN': {
            const isChecked: boolean = checkBooleanValue(attributeType.attributeDefaultValue?.value);

            return (
                <Checkbox
                    data-test="attribute-boolean_check-box"
                    checked={isChecked}
                    onChange={(value) => {
                        onChangeDefaultValue({
                            ...getAttrbuteValue(attributeType),
                            value: `${value.target.checked}`,
                        });
                    }}
                />
            );
        }
        case 'TIME': {
            const val: string | undefined = attributeType.attributeDefaultValue?.value;

            return (
                <div className={theme.editableElementMedium}>
                    <ConfigProvider locale={LocalesService.checkAndSetLocale(intl.locale)}>
                        <TimePicker
                            style={{ width: '100%' }}
                            data-test="select-time-picker-input"
                            defaultValue={val ? timestampToMomentDate(+val) : undefined}
                            onChange={(time: dayjs.Dayjs) => {
                                onChangeDefaultValue({
                                    ...getAttrbuteValue(attributeType),
                                    value: `${momentDateToTimestamp(time)}`,
                                });
                            }}
                        />
                    </ConfigProvider>
                </div>
            );
        }
        case 'SELECT': {
            return <SelectComponent attributeType={attributeType} onChangeDefaultValue={onChangeDefaultValue} />;
        }
        case 'MULTI_SELECT': {
            return <MultiSelectComponent attributeType={attributeType} onChangeDefaultValue={onChangeDefaultValue} />;
        }
        case 'PRINCIPAL': {
            const { logins, groupIds } = (attributeType?.attributeDefaultValue as AttributeValuePrincipal) || {};
            const principalAttributeTypeSettings: PrincipalAttributeTypeSettings | undefined =
                attributeType?.principalAttributeTypeSettings;

            const { allSelectedUsers: users } = getUsersSelectedData(principals, logins);
            const { allSelectedGroups: groups } = getGroupsSelectedData(principals, groupIds);

            return (
                <div className={theme.editableTextContainer}>
                    <div data-test="attribute-PRINCIPAL_value" className={theme.textContainer}>
                        <div className={theme.principalAttributeContainer}>
                            {users.map((user) => (
                                <UserLogin
                                    text={formatUserName(principalAttributeTypeSettings?.format)(user)}
                                    user={user as PrincipalDescriptor}
                                    key={`principal-user_${user.login}`}
                                />
                            ))}
                            {groups.map((group) => (
                                <GroupName text={group.login} group={group} key={`principal-group_${group.id}`} />
                            ))}
                        </div>
                    </div>
                    <Button
                        data-test="attribute-PRINCIPAL_edit-btn"
                        icon={<EditOutlined />}
                        onClick={() => {
                            dispatch(
                                openDialog(DialogType.PRICIPAL_ATTRIBUTE_DIALOG, {
                                    serverId,
                                    attributeName: attributeType.name,
                                    attributeValue: attributeType.attributeDefaultValue,
                                    onSubmit: (value: AttributeValuePrincipal) => {
                                        return onChangeDefaultValue({
                                            ...getAttrbuteValue(attributeType),
                                            logins: value.logins,
                                            groupIds: value.groupIds,
                                        } as AttributeValuePrincipal);
                                    },
                                }),
                            );
                        }}
                    />
                    <DeleteButton
                        disabled={!users.length && !groups.length}
                        onDelete={handlDeleteDefaultValue}
                        deleteQuestion={intl.formatMessage(messages.clearAttributeDefaultValue)}
                    />
                </div>
            );
        }

        default:
            return null;
    }
};
