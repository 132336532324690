import { ObjectDefinitionSelectors } from '@/selectors/objectDefinition.selectors';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { getStore } from '@/store';
import { getUmlClassDefinitionData } from '../umlDefinitionData.selectors';
import { TUmlClassData } from './classSymbol.types';

export function getUmlClassObjectAttributes(graph, rootCellValue): TUmlClassData {
    const { objectDefinitionId: id } = rootCellValue;
    const state = getStore().getState();
    const { serverId, repositoryId } = graph.id;

    const nodeId = {
        id,
        repositoryId,
        serverId,
    };

    const presetId: string = TreeSelectors.presetById(nodeId)(state);
    const objectDefinition = ObjectDefinitionSelectors.byId(nodeId)(state);

    if (!objectDefinition) return { name: '' };

    const umlClassData = getUmlClassDefinitionData(objectDefinition, presetId)(state);

    return umlClassData;
}
