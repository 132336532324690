import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { PrintDialog } from './PrintDialog.component';

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closeDialog(DialogType.PRINT_DIALOG)),
});

export const PrintDialogContainer = connect(null, mapDispatchToProps)(PrintDialog);
