import { useIntl } from 'react-intl';
import theme from './FavoriteTab.scss';
import messages from '../../messages/HomePageTab.messages';
import modelTypeMessages from '../../../../models/modelType.messages';
import { Button, Input, Tooltip } from 'antd';
import { FavoriteNodeDTO, NodeId } from '../../../../serverapi/api';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openNode } from '../../../../actions/openNode.actions';
import { TreeNode } from '../../../../models/tree.types';
import { moveToDirectAction } from '../../../../actions/editor.actions';
import { FavoritesSelectors } from '@/selectors/favorites.selectors';
import icRemoveFromFavorites from '../../../../resources/icons/removeFromFavorites.svg';
import icCopyLink from '../../../../resources/icons/ic-share.svg';
import icSearch from '../../../../resources/icons/ic-search-favorite.svg';
import imIcon from '../../../../resources/icons/ic-tree-im.svg';
import defaultIcon from '../../../../resources/icons/defaultIcon.svg';
import { favoritesRemove } from '@/actions/favorites.actions';
import { copyLinkAction } from '@/actions/copyLinkDialog.actions';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import TreeItemIconsMap from '../../../Tree/TreeItemIcons/treeItemIcons';
import { HightLighted } from '@/modules/UIKit/H.O.C/HightLighted/HightLighted.hoc';

const getNodeIcon = (type: TreeItemType): JSX.Element => {
    if (type === TreeItemType.ObjectDefinition) return <Icon className={theme.entityIcon} spriteSymbol={defaultIcon} />;

    if (type === TreeItemType.SimulationModeling) return <Icon className={theme.entityIcon} spriteSymbol={imIcon} />;

    return <Icon className={theme.entityIcon} spriteSymbol={TreeItemIconsMap[type]} />;
};

const isNodeNotOpenable = (type: TreeItemType): boolean => {
    return [
        TreeItemType.EdgeDefinition,
        TreeItemType.ObjectDefinition,
        TreeItemType.File,
        TreeItemType.Folder,
        TreeItemType.Repository,
        TreeItemType.FileFolder,
        TreeItemType.ScriptFolder,
    ].includes(type);
};

export const FavoriteTab: FC = (props) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState<string>('');
    const favoriteNodes: FavoriteNodeDTO[] = useSelector(FavoritesSelectors.nodes);

    const filteredFavoriteNodes = favoriteNodes.filter((node) => {
        if (searchValue.trim() === '') return true;

        return node.name.includes(searchValue);
    });

    const onClickFavorite = (nodeId: NodeId, type: TreeItemType) => {
        const treeNode: TreeNode = {
            hasChildren: false,
            nodeId,
            name: '',
            type,
            countChildren: 0,
        };
        dispatch(moveToDirectAction(treeNode));

        if (isNodeNotOpenable(type)) return;

        dispatch(openNode({ nodeId, type }));
    };

    const onDeleteFromFavorites = (nodeId: NodeId) => {
        dispatch(favoritesRemove(nodeId));
    };

    const onCopyLink = (nodeId: NodeId, type: TreeItemType) => {
        dispatch(copyLinkAction(nodeId, type));
    };

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onAddToFavorite = () => {
        dispatch(openDialog(DialogType.SELECT_TREE_ITEM_ADD_FAVORITE_DIALOG));
    };

    const getFavoriteNodeTextInfo = (favorite: FavoriteNodeDTO): string => {
        return `[${intl.formatMessage(modelTypeMessages[favorite.type])}] ${
            favorite.elementTypeName ? `[${favorite.elementTypeName}]` : ''
        } ${favorite.deleted ? `[${intl.formatMessage(messages.deleted)}]` : ''} ${favorite.path}`;
    };

    return (
        <>
            <div className={theme.favoriteTop}>
                <Input
                    className={theme.search}
                    suffix={<Icon spriteSymbol={icSearch} />}
                    onChange={onSearch}
                    placeholder={intl.formatMessage(messages.search)}
                    value={searchValue}
                />
                <Button key="ok" size="large" type="primary" onClick={onAddToFavorite} className={theme.addBtn}>
                    {intl.formatMessage(messages.add)}
                </Button>
            </div>
            <div className={theme.favoriteList}>
                {filteredFavoriteNodes.map((favorite) => {
                    return (
                        <div
                            className={theme.favorite}
                            onClick={() => {
                                onClickFavorite(favorite.nodeId, favorite.type as TreeItemType);
                            }}
                            key={favorite.nodeId.id}
                        >
                            <div className={theme.favoriteInfo}>
                                <div className={theme.favoriteIcon}>{getNodeIcon(favorite.type as TreeItemType)}</div>
                                <div>
                                    <HightLighted searchText={searchValue}>{favorite.name}</HightLighted>{' '}
                                    {getFavoriteNodeTextInfo(favorite)}
                                </div>
                            </div>
                            <div className={theme.favoriteActions}>
                                <Tooltip title={intl.formatMessage(messages.deleteFromFavorite)} placement="left">
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDeleteFromFavorites(favorite.nodeId);
                                        }}
                                    >
                                        <Icon spriteSymbol={icRemoveFromFavorites} />
                                    </span>
                                </Tooltip>
                                <Tooltip title={intl.formatMessage(messages.copyLink)} placement="left">
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onCopyLink(favorite.nodeId, favorite.type as TreeItemType);
                                        }}
                                    >
                                        <Icon spriteSymbol={icCopyLink} />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
