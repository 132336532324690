import React, { FC } from 'react';
import theme from './DatetimeSchedulerSettings.scss';
import messages from './DatetimeSchedulerSettings.messages';
import { useIntl } from 'react-intl';
import { ALL, LAST, days, months, weekDays, weekNumber } from './DatetimeSchedulerSettings.utils';
import { SchedulerMonthsEnum, SchedulerWeekDaysEnum } from './DatetimeSchedulerSettings.types';
import { SchedulerTimeSettingsMonthsEnum, SchedulerTimeSettingsWeekDaysEnum } from '@/serverapi/api';
import { Select as UIKitSelect } from '../UIKit/components/Select/Select.component';

type TMonthlySchedulerPeriodProps = {
    weekDays: SchedulerTimeSettingsWeekDaysEnum[] | undefined;
    onWeekDaysChange: (changedDays: (SchedulerWeekDaysEnum | typeof ALL)[] | undefined) => void;
    months: SchedulerTimeSettingsMonthsEnum[] | undefined;
    onMonthsChange: (changedMonths: (SchedulerMonthsEnum | typeof ALL)[] | undefined) => void;
    days: number[] | undefined;
    lastDay: boolean | undefined;
    onDaysChange: (changedDays: (string | typeof LAST)[] | undefined) => void;
    onWeekNumbersChange: (changedWeekNumbers: (string | typeof LAST)[] | undefined) => void;
    lastWeek: boolean | undefined;
    weekNumber: number[] | undefined;
};

export const MonthlySchedulerPeriod: FC<TMonthlySchedulerPeriodProps> = (props) => {
    const { onWeekDaysChange, onMonthsChange, lastDay, onDaysChange, onWeekNumbersChange, lastWeek } = props;
    const intl = useIntl();
    const weekDaysOptions: (SchedulerWeekDaysEnum | typeof ALL)[] = [ALL, ...weekDays];
    const monthsOptions: (SchedulerMonthsEnum | typeof ALL)[] = [ALL, ...months];
    const daysOptions: (string | typeof LAST)[] = [...days, LAST];
    const weekNumberOptions: (string | typeof LAST)[] = [...weekNumber, LAST];

    return (
        <div>
            <div className={theme.schedulerSettings}>
                <span className={theme.schedulerSettingsTitle}>{intl.formatMessage(messages.months)}</span>
                <div className={theme.schedulerSelect}>
                    <UIKitSelect
                        isMultiSelect
                        value={intl.formatMessage(messages.selectMonths)}
                        onChange={onMonthsChange}
                        data-test="scheduler-months"
                    >
                        {monthsOptions.map((month) => (
                            <UIKitSelect.Option
                                key={month}
                                value={month}
                                checked={
                                    month === ALL
                                        ? props.months?.length === months.length
                                        : props.months?.includes(month)
                                }
                                label={intl.formatMessage(messages[month])}
                            />
                        ))}
                    </UIKitSelect>
                </div>
            </div>

            <div className={theme.schedulerSettings}>
                <span className={theme.schedulerSettingsTitle}>{intl.formatMessage(messages.days)}</span>
                <div className={theme.schedulerSelect}>
                    <UIKitSelect
                        isMultiSelect
                        value={intl.formatMessage(messages.selectDays)}
                        onChange={onDaysChange}
                        data-test="scheduler-days"
                    >
                        {daysOptions.map((day) => (
                            <UIKitSelect.Option
                                key={day}
                                value={day}
                                checked={day === LAST ? lastDay : props.days?.includes(+day)}
                                label={day === LAST ? intl.formatMessage(messages.last) : day}
                            />
                        ))}
                    </UIKitSelect>
                </div>
            </div>

            <div className={theme.schedulerSettings}>
                <span className={theme.schedulerSettingsTitle}>{intl.formatMessage(messages.in)}</span>
                <div className={theme.weekNumberWrapper}>
                    <UIKitSelect
                        isMultiSelect
                        value={intl.formatMessage(messages.selectWeeks)}
                        onChange={onWeekNumbersChange}
                        data-test="scheduler-weeks"
                    >
                        {weekNumberOptions.map((week) => (
                            <UIKitSelect.Option
                                key={week}
                                value={week}
                                checked={week === LAST ? lastWeek : props.weekNumber?.includes(+week)}
                                label={week === LAST ? intl.formatMessage(messages.lastAccusative) : week}
                            />
                        ))}
                    </UIKitSelect>
                </div>

                <div className={theme.schedulerSelect}>
                    <UIKitSelect
                        isMultiSelect
                        value={intl.formatMessage(messages.selectWeekDays)}
                        onChange={onWeekDaysChange}
                        data-test="scheduler-weekdays"
                    >
                        {weekDaysOptions.map((day) => (
                            <UIKitSelect.Option
                                key={day}
                                value={day}
                                checked={
                                    day === ALL
                                        ? props.weekDays?.length === weekDays.length
                                        : props.weekDays?.includes(day)
                                }
                                label={
                                    day === ALL ? intl.formatMessage(messages.all) : intl.formatMessage(messages[day])
                                }
                            />
                        ))}
                    </UIKitSelect>
                </div>
            </div>
        </div>
    );
};
