import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import menuItemTheme from '../items/MenuItem.scss';
import { useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { ComplexSymbolManager } from '../../../../mxgraph/ComplexSymbols/ComplexSymbolsManager.class';
import { instancesBPMMxGraphMap } from '../../../../mxgraph/bpm-mxgraph-instance-map';
import { isGraySymbolStyle } from '../../../../services/SymbolsService';
import { isCompact } from '../../../../selectors/generalMenu.selectors';
import { TreeSelectors } from '../../../../selectors/tree.selectors';
import { ToolbarButtonsBllService } from '../../../../services/bll/ToolbarButtonsBllService';
import { getSelectedCellIds } from '../../../../selectors/editor.selectors';
import { NotationHelper } from '../../../../services/utils/NotationHelper';
import { GraphObjectToolbarEdgeSettings } from './GraphObjectToolbarEdgeSettings.component';
import { GraphObjectToolbarTextAlignSettings } from './GraphObjectToolbarTextAlignSettings.component';
import { GraphObjectToolbarTextStyleSettings } from './GraphObjectToolbarTextStyleSettings.component';
import { GraphObjectToolbarFormatSettings } from './GraphObjectToolbarFormatSettings.component';
import { GraphObjectToolbarColorSettings } from './GraphObjectToolbarColorSettings.component';
import { GraphObjectToolbarFontSettings } from './GraphObjectToolbarFontSettings.component';
import { GraphGeneralToolbarTextDirectionSettings } from './GraphObjectToolbarTextDirectionSettings.component';
import { MxCell } from '../../../../mxgraph/mxgraph';

const filterSelectedCells = (selectedCells: MxCell[]) =>
    selectedCells.filter((cell) => !isGraySymbolStyle(cell.style) && ComplexSymbolManager.isCellStyleEditable(cell));

export const GraphObjectToolbar: FC = () => {
    const compact = useSelector(isCompact);
    const nodeId = useSelector(TabsSelectors.getActiveTabId);
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const selectedCellsIds = useSelector(getSelectedCellIds);

    const graph = useMemo(() => instancesBPMMxGraphMap.get(nodeId), [nodeId]);

    const rawSelectedCells = useMemo(
        () =>
            ToolbarButtonsBllService.getCellsWorkspace(
                selectedCellsIds.map((cellId) => graph.getModel().getCell(cellId)).filter(Boolean),
            ),
        [selectedCellsIds, graph],
    );

    const selectedCells = filterSelectedCells(rawSelectedCells);
    const isEntityEditable = NotationHelper.isEntityEditable(nodeId, presetId, selectedCells);
    const hasSelectedImage = selectedCells.some((cell) => cell.value.imageId);
    const hasSelectedElement = !!selectedCells.length;
    const isShapeWithPicture = selectedCells.length === 1 && hasSelectedImage;
    const objectEditDisabled = !isEntityEditable || !hasSelectedElement || isShapeWithPicture;

    return (
        <div className={cx(menuItemTheme.container, compact && menuItemTheme.container_compact)}>
            <GraphObjectToolbarFontSettings isShapeWithPicture={isShapeWithPicture} disabled={!isEntityEditable} />
            <div className={menuItemTheme.editGroup}>
                <GraphObjectToolbarColorSettings
                    compact={compact}
                    disabled={!isEntityEditable}
                    selectedCells={selectedCells}
                />
                <GraphObjectToolbarFormatSettings
                    nodeId={nodeId}
                    presetId={presetId}
                    compact={compact}
                    hasImageSelected={hasSelectedImage}
                    rawSelectedCells={rawSelectedCells}
                    selectedCells={selectedCells}
                    isEntityEditable={isEntityEditable}
                    modelTypeId={graph.modelType?.id}
                />
            </div>
            <div className={menuItemTheme.devider} />
            <div className={menuItemTheme.editGroup}>
                <GraphObjectToolbarTextStyleSettings disabled={objectEditDisabled} compact={compact} />
                <GraphObjectToolbarTextAlignSettings disabled={objectEditDisabled} />
            </div>
            <div className={menuItemTheme.devider} />
            <GraphGeneralToolbarTextDirectionSettings disabled={objectEditDisabled} compact={compact} />
            <div className={menuItemTheme.devider} />
            <GraphObjectToolbarEdgeSettings
                compact={compact}
                selectedCells={selectedCells}
                isEntityEditable={isEntityEditable}
            />
        </div>
    );
};
