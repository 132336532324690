import { defineMessages } from 'react-intl';

export default defineMessages({
    clear: {
        id: 'Select.clear',
        defaultMessage: 'Очистить',
    },
    error: {
        id: 'Select.error',
        defaultMessage: 'Это поле обязательно для заполнения',
    },
});
