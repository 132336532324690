// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModelTypeGeneralTab__firstRow__KZp_o{display:-webkit-box;display:-ms-flexbox;display:flex}.ModelTypeGeneralTab__firstRow__KZp_o .ant-checkbox-wrapper{margin-top:-5px}.ModelTypeGeneralTab__firstRow__KZp_o .ModelTypeGeneralTab__name__jBMl5{width:50%}.ModelTypeGeneralTab__firstRow__KZp_o .ModelTypeGeneralTab__name__jBMl5 input{width:300px}.ModelTypeGeneralTab__firstRow__KZp_o .ModelTypeGeneralTab__modelTypeVisibility__Ta42Y{width:50%}.ModelTypeGeneralTab__input__goy7K{width:300px}.ModelTypeGeneralTab__checkBoxContainer__f8TFE{margin-bottom:10px}.ModelTypeGeneralTab__description__tgAbs{width:300px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/Presets/ModelType/ModelTypeGeneralTab/ModelTypeGeneralTab.scss"],"names":[],"mappings":"AAAA,sCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CAEI,4DACI,eAAA,CAGR,wEACI,SAAA,CACA,8EACI,WAAA,CAGR,uFACI,SAAA,CAIR,mCACI,WAAA,CAGJ,+CACI,kBAAA,CAGJ,yCACI,WAAA","sourcesContent":[".firstRow {\r\n    display: flex;\r\n    :global {\r\n        .ant-checkbox-wrapper {\r\n            margin-top: -5px;\r\n        }\r\n    }\r\n    .name {\r\n        width: 50%;\r\n        input {\r\n            width: 300px;\r\n        }\r\n    }\r\n    .modelTypeVisibility {\r\n        width: 50%;\r\n    }\r\n}\r\n\r\n.input {\r\n    width: 300px;\r\n}\r\n\r\n.checkBoxContainer {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.description {\r\n    width: 300px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstRow": "ModelTypeGeneralTab__firstRow__KZp_o",
	"name": "ModelTypeGeneralTab__name__jBMl5",
	"modelTypeVisibility": "ModelTypeGeneralTab__modelTypeVisibility__Ta42Y",
	"input": "ModelTypeGeneralTab__input__goy7K",
	"checkBoxContainer": "ModelTypeGeneralTab__checkBoxContainer__f8TFE",
	"description": "ModelTypeGeneralTab__description__tgAbs"
};
export default ___CSS_LOADER_EXPORT___;
