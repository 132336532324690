import ControlsContext from './Controls.context';
import BGColorComponent from './controls/BGColor.component';
import DividerComponent from './controls/Divider.component';
import EraserComponent from './controls/Eraser.component';
import FontColorComponent from './controls/FontColor.component';
import FontFamilyComponent from './controls/FontFamily.component';
import FontSizeComponent from './controls/FontSize.component';
import GeneralActionsComponent from './controls/GeneralActions.component';
import ImageComponent from './controls/Image.component';
import IndentComponent from './controls/Indent.component';
import InlineStyleComponent from './controls/InlineStyle.component';
import LineHeightComponent from './controls/LineHeight.component';
import LinkComponent from './controls/Link.component';
import ListsComponent from './controls/Lists.component';
import TableComponent from './controls/Table.component';
import TextAlignComponent from './controls/TextAlign.component';

export {
    BGColorComponent,
    DividerComponent,
    EraserComponent,
    FontColorComponent,
    FontFamilyComponent,
    FontSizeComponent,
    GeneralActionsComponent,
    ImageComponent,
    IndentComponent,
    InlineStyleComponent,
    LineHeightComponent,
    LinkComponent,
    ListsComponent,
    TableComponent,
    TextAlignComponent,
    ControlsContext,
};
