import { defineMessages } from 'react-intl';

export default defineMessages({
    generalSettings: {
        id: 'ObjectType.generalSettings',
        defaultMessage: 'Общие настройки',
    },
    name: {
        id: 'ObjectType.name',
        defaultMessage: 'Название',
    },
    width: {
        id: 'ObjectType.width',
        defaultMessage: 'Ширина',
    },
    height: {
        id: 'ObjectType.height',
        defaultMessage: 'Высота',
    },
    description: {
        id: 'ObjectType.description',
        defaultMessage: 'Описание',
    },
    id: {
        id: 'ObjectType.id',
        defaultMessage: 'ID',
    },
    modelTypes: {
        id: 'ObjectType.modelType',
        defaultMessage: 'Типы моделей',
    },
    newDownload: {
        id: 'ObjectType.newDownload',
        defaultMessage: 'Загрузить новый',
    },
    createSymbolThroughSVG: {
        id: 'ObjectType.createSymbolThroughSVG',
        defaultMessage: 'Символ создан через загрузку SVG файла',
    },
    cancelAllSettings: {
        id: 'ObjectType.cancelAllSettings',
        defaultMessage: 'Отменить все настройки',
    },
    symbol: {
        id: 'ObjectType.symbol',
        defaultMessage: 'Символ',
    },
    icon: {
        id: 'ObjectType.icon',
        defaultMessage: 'Иконка',
    },
    noIcon: {
        id: 'ObjectType.noIcon',
        defaultMessage: 'Нет иконки',
    },
    symbols: {
        id: 'ObjectType.symbols',
        defaultMessage: 'Символы модели',
    },
    attributes: {
        id: 'ObjectType.attributes',
        defaultMessage: 'Атрибуты объекта',
    },
    objectTypeDiagramElementAttributes: {
        id: 'ObjectType.objectTypeDiagramElementAttributes',
        defaultMessage: 'Атрибуты экземпляра',
    },
    addAttributes: {
        id: 'ObjectType.addAttributes',
        defaultMessage: 'Добавить атрибут',
    },
    objectTypeSymbols: {
        id: 'ObjectType.objectTypeSymbols',
        defaultMessage: 'Перечень символов',
    },
    decompositions: {
        id: 'ObjectType.decompositions',
        defaultMessage: 'Доступные декомпозиции',
    },
    newObject: {
        id: 'ObjectType.newObject',
        defaultMessage: 'Новый объект',
    },
    newSymbol: {
        id: 'ObjectType.newSymbol',
        defaultMessage: 'Новый символ',
    },
    design: {
        id: 'ObjectType.design',
        defaultMessage: 'Дизайн',
    },
    text: {
        id: 'ObjectType.text',
        defaultMessage: 'Текст',
    },
    pointsOfConnection: {
        id: 'ObjectType.pointsOfConnection',
        defaultMessage: 'Точки соединения',
    },
    nameConstraintPoints: {
        id: 'ObjectType.nameConstraintPoints',
        defaultMessage: 'Имя точки',
    },
    coordinatePointX: {
        id: 'ObjectType.coordinatePointX',
        defaultMessage: ' Координата Х',
    },
    coordinatePointY: {
        id: 'ObjectType.coordinatePointY',
        defaultMessage: ' Координата Y',
    },
    deleteButton: {
        id: 'ObjectType.deleteButton',
        defaultMessage: 'Удалить',
    },
    deleteAllButton: {
        id: 'ObjectType.deleteAllButton',
        defaultMessage: 'Удалить все',
    },
    addButton: {
        id: 'ObjectType.addButton',
        defaultMessage: 'Добавить',
    },
    validationPointsRequired: {
        id: 'ObjectType.validationPointsRequired',
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: '${label} обязательно!',
    },
    validationPointsNumber: {
        id: 'ObjectType.validationPointsNumber',
        // eslint-disable-next-line no-template-curly-in-string
        defaultMessage: 'Вводимое значение должно быть между ${min} и ${max}',
    },
    countPointsValidation: {
        id: 'ObjectType.countPointsValidation',
        defaultMessage: 'Количество точек не должно быть меньше 1',
    },
    doublePointsValidation: {
        id: 'ObjectType.doublePointsValidation',
        defaultMessage: 'Точки с одинаковыми координатами недопустимы',
    },
    symbolSelectCreationType: {
        id: 'ObjectType.symbolSelectCreationType',
        defaultMessage: 'Выберите способ создания нового символа',
    },
    symbolEditorButton: {
        id: 'ObjectType.symbolEditorButton',
        defaultMessage: 'Редактор символов',
    },
    labelFullStyle: {
        id: 'ObjectType.labelFullStyle',
        defaultMessage: 'Расширенное редактирование стиля',
    },
    disableLabel: {
        id: 'ObjectType.disableLabel',
        defaultMessage: 'Отключить текстовый блок',
    },
    overlapLabel: {
        id: 'ObjectType.overlapLabel',
        defaultMessage: 'Показывать текст за пределами',
    },
    uploadText: {
        id: 'uploadText',
        defaultMessage: 'Нажмите или перетащите файл сюда',
    },
    createFromSymbol: {
        id: 'ObjectType.createFromSymbol',
        defaultMessage: 'Создать на основе символа',
    },
    uploadIcon: {
        id: 'ObjectType.uploadIcon',
        defaultMessage: 'Загрузить иконку',
    },
    reset: {
        id: 'ObjectType.reset',
        defaultMessage: 'Вернуть настройки по умолчанию',
    },
    textDirection: {
        id: 'ObjectType.textDirection',
        defaultMessage: 'Направление',
    },
    importSymbolButton: {
        id: 'ObjectType.importSymbolButton',
        defaultMessage: 'Загрузка готового символа',
    },
    objectTypeGroups: {
        id: 'ObjectType.objectTypeGroups',
        defaultMessage: 'Группа типа объекта',
    },
    objectType: {
        id: 'ObjectType.objectType',
        defaultMessage: 'Тип объекта',
    },
    hideInNavigator: {
        id: 'ObjectType.hideInNavigator',
        defaultMessage: 'Скрывать определения объектов в дереве навигатора',
    },
    addModelType: {
        id: 'ObjectType.addModelType',
        defaultMessage: 'Добавить тип модели',
    },
    allowAnyDecomposition: {
        id: 'ObjectType.allowAnyDecomposition',
        defaultMessage: 'Разрешить все декомпозиции',
    },
    symbolProportions: {
        id: 'ObjectType.symbolProportions',
        defaultMessage: 'Сохранить пропорции символа при изменении размера',
    },
    defaultGeometrySettingsTitle: {
        id: 'ObjectType.defaultGeometrySettingsTitle',
        defaultMessage: 'Настройки размера объекта по умолчанию',
    },
    symbolBreadCrumbs: {
        id: 'ObjectType.symbolBreadCrumbs',
        defaultMessage: '{presetName} > Типы объектов > {objectTypeName} > Символы > {symbolName}',
    },
    addModelTypeDecomposition: {
        id: 'ObjectType.addModelTypeDecomposition',
        defaultMessage: 'Добавление типов моделей для декомпозиции',
    },
    cancel: {
        id: 'ObjectType.cancel',
        defaultMessage: 'Отмена',
    },
    save: {
        id: 'ObjectType.save',
        defaultMessage: 'Сохранить',
    },
    preventMultipleInstances: {
        id: 'ObjectType.preventMultipleInstances',
        defaultMessage: 'Объект может иметь только один экземпляр',
    },
    symbolType: {
        id: 'ObjectType.symbolType',
        defaultMessage: 'Вид символа',
    },
    deleteGroupMessage: {
        id: 'ObjectType.deleteGroupMessage',
        defaultMessage: 'Удалить группу типов моделей "{name}"?',
    },
    deleteElMessage: {
        id: 'ObjectType.deleteElMessage',
        defaultMessage: 'Удалить тип модели "{name}"?',
    },
    deleteSymbolMessage: {
        id: 'ObjectType.deleteSymbolMessage',
        defaultMessage: 'Удалить символ "{symbolName}"?',
    },
    childElements: {
        id: 'ObjectType.childElements',
        defaultMessage: 'Дочерние элементы',
    },
    deleteModelsDialogTitle: {
        id: 'ObjectType.deleteModelsDialogTitle',
        defaultMessage: 'Удалить выбранные типы моделей?',
    },
    deleteSymbolsDialogTitle: {
        id: 'ObjectType.deleteSymbolsDialogTitle',
        defaultMessage: 'Удалить выбранные символы?',
    },
    symbolGenerator: {
        id: 'ObjectType.symbolGenerator',
        defaultMessage: 'Генератор символов',
    },
    alowObjectTypeApprovals: {
        id: 'ObjectType.alowObjectTypeApprovals',
        defaultMessage: 'Разрешить согласования типа объекта',
    }
});
