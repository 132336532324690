import { Tooltip, Button } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import icCopy from '../../../resources/icons/copy.svg';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import messages from './RowButtons.messages';

type TCopyButton = {
    onClick: () => void;
};

const CopyButton: FC<TCopyButton> = (props) => {
    const { onClick } = props;
    const intl = useIntl();

    return (
        <Tooltip title={intl.formatMessage(messages.copy)}>
            <Button type="link" onClick={onClick} icon={<Icon spriteSymbol={icCopy} />} />
        </Tooltip>
    );
};

export default CopyButton;
