import React, { useState, ChangeEvent, KeyboardEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input } from 'antd';
import { getSearchResult } from '../../../actions/search.actions';
import { showNotificationByType } from '../../../actions/notification.actions';
import { NotificationType } from '../../../models/notificationType';
import messages from './SearchField.messages';
import style from './SearchField.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { Keys } from '../../../utils/keys';

const SearchField = ({ intl }) => {
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');

    const handleSearch = () => {
        if (searchText.length > 1) {
            dispatch(getSearchResult(searchText));
        } else {
            dispatch(showNotificationByType(NotificationType.SEARCH_TEXT_LESS));
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value.trim();
        setSearchText(searchValue);
    };

    const handleKeypress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === Keys.ENTER) {
            handleSearch();
        }
    };

    return (
        <div className={style.searchFieldContainer}>
            <Input
                data-test="search_search-field-input"
                autoFocus
                suffix={<SearchOutlined />}
                onChange={handleChange}
                onKeyPress={handleKeypress}
                style={{ width: 400 }}
                placeholder={intl.formatMessage(messages.placeholder)}
            />

            <Button data-test="search_search-start-button" key="ok" type="primary" onClick={handleSearch}>
                <FormattedMessage {...messages.button} />
            </Button>
        </div>
    );
};

const WithIntl = injectIntl(SearchField);

export { WithIntl as SearchField };
