import React, { useCallback, useMemo } from 'react';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { getMenuItem } from '../../../../utils/antdMenuItem.utils';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceRemoveMultipleTabs, workspaceRemoveTabRequest } from '../../../../actions/tabs.actions';
import { MenuInfo } from 'rc-menu/lib/interface';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { compareNodeIds } from '../../../../utils/nodeId.utils';
import { TWorkspaceTabTitleContextMenuProps } from '../../Workspace.types';
import { useIntl } from 'react-intl';
import messages from '../../messages/WorkspaceTabTitleContextMenu.messages';

export const WorkspaceTabTitleContextMenu = ({
    tab,
    children,
    visible,
    onVisibilityChange,
}: TWorkspaceTabTitleContextMenuProps) => {
    const intl = useIntl();
    const tabs = useSelector(TabsSelectors.getTabList);
    const dispatch = useDispatch();

    const onClick = useCallback(
        (menuInfo: MenuInfo) => {
            menuInfo.domEvent.stopPropagation();
            onVisibilityChange();
            let tabIndex;
            switch (menuInfo.key) {
                case 'closeCurrent':
                    dispatch(workspaceRemoveTabRequest(tab));
                    break;
                case 'closeAnother':
                    dispatch(
                        workspaceRemoveMultipleTabs(tabs.filter((_tab) => !compareNodeIds(_tab.nodeId, tab.nodeId))),
                    );
                    break;
                case 'closeLeft':
                    tabIndex = tabs.findIndex((_tab) => compareNodeIds(_tab.nodeId, tab.nodeId));
                    dispatch(workspaceRemoveMultipleTabs(tabs.filter((_tab, index) => index < tabIndex)));
                    break;
                case 'closeRight':
                    tabIndex = tabs.findIndex((_tab) => compareNodeIds(_tab.nodeId, tab.nodeId));
                    dispatch(workspaceRemoveMultipleTabs(tabs.filter((_tab, index) => index > tabIndex)));
                    break;
                case 'closeAll':
                    dispatch(workspaceRemoveMultipleTabs(tabs));
                    break;
            }
        },
        [tabs, tab],
    );

    const items: MenuProps['items'] = useMemo(
        () => [
            getMenuItem(<div>{intl.formatMessage(messages.closeCurrent)}</div>, 'closeCurrent'),
            getMenuItem(<div>{intl.formatMessage(messages.closeAnother)}</div>, 'closeAnother'),
            getMenuItem(<div>{intl.formatMessage(messages.closeLeft)}</div>, 'closeLeft'),
            getMenuItem(<div>{intl.formatMessage(messages.closeRight)}</div>, 'closeRight'),
            getMenuItem(<div>{intl.formatMessage(messages.closeAll)}</div>, 'closeAll'),
        ],
        [onClick],
    );

    return (
        <Dropdown
            trigger={['click']}
            menu={{ items, onClick }}
            open={visible}
            onOpenChange={onVisibilityChange}
            destroyPopupOnHide
        >
            {children}
        </Dropdown>
    );
};
