import { Col, Collapse, CollapseProps, ConfigProvider, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './AvailableEventsList.messages';
import theme from './AvailableEventsList.scss';
import { eventsList } from './AvailableEventsList.utils';

type TTableRowData = {
    variable: string;
    description: string;
};

export const AvailableEventsList = () => {
    const intl = useIntl();

    const tables: JSX.Element[] = [];

    Object.keys(eventsList).forEach((groupKey, groupIndex) => {
        const columnTitle = messages[groupKey] ? intl.formatMessage(messages[groupKey]) : '';
        const columns: ColumnsType<TTableRowData> = [
            {
                title: columnTitle,
                dataIndex: 'variable',
                key: 'groupKey',
                width: '40%',
                colSpan: 2,
                align: 'left',
            },
            {
                title: '',
                dataIndex: 'description',
                key: 'description',
                colSpan: 0,
            },
        ];

        const dataSource: TTableRowData[] = [];
        eventsList[groupKey].forEach((variableKey: string, itemIndex: number) => {
            if (messages[variableKey]) {
                const itemRow = {
                    key: `${groupIndex}_${itemIndex}`,
                    variable: `<${variableKey}>`,
                    description: intl.formatMessage(messages[variableKey]),
                };
                dataSource.push(itemRow);
            }
        });

        const partOfTable = (
            <Table
                locale={{ emptyText: <div /> }}
                bordered
                pagination={false}
                key={groupKey}
                columns={columns}
                dataSource={dataSource}
            />
        );
        tables.push(partOfTable);
    });

    const columns = [
        {
            title: intl.formatMessage(messages.variable),
            dataIndex: 'variable',
            key: 'variable',
            width: '40%',
        },
        {
            title: intl.formatMessage(messages.description),
            dataIndex: 'description',
            key: 'description',
        },
    ];

    const table = (
        <Col>
            <Table
                rootClassName={theme.tableHeader}
                bordered
                locale={{ emptyText: <div /> }}
                pagination={false}
                dataSource={[]}
                columns={columns}
            />
            {tables}
        </Col>
    );

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: intl.formatMessage(messages.availableEventsList),
            children: table,
        },
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Collapse: {
                        colorTextHeading: 'var(--blue)',
                        padding: 0,
                        contentPadding: 0,
                    },
                    Table: {
                        headerBorderRadius: 0,
                        rowHoverBg: 'unset',
                    },
                },
            }}
        >
            <Row>
                <Collapse rootClassName={theme.collapseRoot} ghost items={items} />
            </Row>
        </ConfigProvider>
    );
};
