import {
    AttributeType,
    AttributeTypeSelectPropertyValue,
    AttributeValue,
    AttributeValueMultiSelect,
    AttributeValuePrincipal,
    AttributeValueString,
    AttributeValueUrl,
} from '../../../../../../../serverapi/api';
import { v4 as uuid } from 'uuid';

const DEFAULT_MULTI_LANG_VALUE = {
    ru: '',
    en: '',
};

export const checkUniqueAttributeIds = (propValues: Array<AttributeTypeSelectPropertyValue> | undefined): boolean => {
    const dataTypeValueIds = propValues?.filter((el) => !!el.id).map((el) => el.id);
    const dataTypeValueIdsLength = new Set(dataTypeValueIds).size;
    const isEveryIdUnique = dataTypeValueIdsLength === dataTypeValueIds?.length;

    return isEveryIdUnique;
};

export const getDefaultAttributeValue = (
    attrType: AttributeType,
): AttributeValue | AttributeValueString | AttributeValueUrl | AttributeValueMultiSelect | AttributeValuePrincipal => {
    const { valueType, attributeDefaultValue } = attrType;

    switch (valueType) {
        case 'STRING':
        case 'MULTI_STRING': {
            return {
                ...getAttrbuteValue(attrType),
                str: { ...DEFAULT_MULTI_LANG_VALUE, ...(attributeDefaultValue as AttributeValueString)?.str },
            } as AttributeValueString;
        }
        case 'SELECT':
        case 'TIME':
        case 'BOOLEAN':
        case 'DATE_TIME':
        case 'DATE':
        case 'JSON':
        case 'PERIOD':
        case 'NUMERIC':
        case 'INTEGER': {
            return {
                ...getAttrbuteValue(attrType),
                value: attributeDefaultValue?.value || '',
            };
        }
        case 'URL': {
            return {
                ...getAttrbuteValue(attrType),
                url: { ...DEFAULT_MULTI_LANG_VALUE, ...(attributeDefaultValue as AttributeValueUrl)?.url },
                name: { ...DEFAULT_MULTI_LANG_VALUE, ...(attributeDefaultValue as AttributeValueUrl)?.name },
            } as AttributeValueUrl;
        }
        case 'MULTI_SELECT': {
            return {
                ...getAttrbuteValue(attrType),
                valueIds: [...((attributeDefaultValue as AttributeValueMultiSelect)?.valueIds || [])],
            } as AttributeValueMultiSelect;
        }
        case 'PRINCIPAL': {
            return {
                ...getAttrbuteValue(attrType),
                logins: [...((attributeDefaultValue as AttributeValuePrincipal)?.logins || [])],
                groupIds: [...((attributeDefaultValue as AttributeValuePrincipal)?.groupIds || [])],
            } as AttributeValuePrincipal;
        }

        default:
            return getAttrbuteValue(attrType);
    }
};

export const getAttrbuteValue = (attributeType: AttributeType): AttributeValue => {
    return {
        id: uuid(),
        typeId: attributeType.id,
        valueType: attributeType?.valueType || attributeType.attributeDefaultValue?.valueType || 'STRING',
        value: '',
    };
};
