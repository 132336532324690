import type { TStyles } from '../modules/FloatingAttributes/reducers/FloatingAttributes.reducer.types';
import type { AttributeTypeStyleRule } from '../serverapi/api';
import type { StyledAttributeType } from '../models/bpm/bpm-model-impl';
import { useEffect, useState } from 'react';
import {
    AttributeValueType,
    RuleType,
} from '../modules/FloatingAttributes/components/AttributesEditor/Attribute.types';

export const useAttributeRules = (attribute: StyledAttributeType, rules: AttributeTypeStyleRule[] | undefined) => {
    const [currentRules, setCurrentRules] = useState<AttributeTypeStyleRule[]>([]);
    const [availableRuleTypes, setAvailableRuleTypes] = useState<RuleType[]>([]);

    useEffect(() => {
        switch (attribute.valueType) {
            case AttributeValueType.NUMERIC:
                setAvailableRuleTypes([
                    RuleType.HAS_VALUE,
                    RuleType.HAS_NOT_VALUE,
                    RuleType.EQUALS,
                    RuleType.NOT_EQUALS,
                    RuleType.GREATER,
                    RuleType.GREATER_OR_EQUAL,
                    RuleType.LESS,
                    RuleType.LESS_OR_EQUAL,
                ]);
                break;
            case AttributeValueType.STRING:
            case AttributeValueType.MULTI_STRING:
            case AttributeValueType.SELECT:
            case AttributeValueType.MULTI_SELECT:
                setAvailableRuleTypes([
                    RuleType.HAS_VALUE,
                    RuleType.HAS_NOT_VALUE,
                    RuleType.EQUALS,
                    RuleType.NOT_EQUALS,
                    RuleType.CONTAINS,
                    RuleType.NOT_CONTAINS,
                    RuleType.STARTS_WITH,
                    RuleType.ENDS_WITH,
                ]);
                break;
            case AttributeValueType.DATE:
            case AttributeValueType.DATE_TIME:
                setAvailableRuleTypes([
                    RuleType.HAS_VALUE,
                    RuleType.HAS_NOT_VALUE,
                    RuleType.GREATER,
                    RuleType.LESS,
                    RuleType.BETWEEN,
                    RuleType.NOT_BETWEEN,
                ]);
                break;
            case AttributeValueType.PERIOD:
                setAvailableRuleTypes([
                    RuleType.HAS_VALUE,
                    RuleType.HAS_NOT_VALUE,
                    RuleType.BETWEEN,
                    RuleType.NOT_BETWEEN,
                ]);
                break;
            case AttributeValueType.TIME:
                setAvailableRuleTypes([RuleType.HAS_VALUE, RuleType.HAS_NOT_VALUE, RuleType.GREATER, RuleType.LESS]);
                break;
            default:
                setAvailableRuleTypes([RuleType.HAS_VALUE, RuleType.HAS_NOT_VALUE]);
                break;
        }
        setCurrentRules(rules || []);
    }, [attribute, rules]);

    return { availableRuleTypes, currentRules };
};

export const useUnusedAttributeRules = (availableRuleTypes: RuleType[], currentRules: AttributeTypeStyleRule[]) => {
    const [unusedRules, setUnusedRules] = useState<AttributeTypeStyleRule[]>([]);

    useEffect(() => {
        const rules = availableRuleTypes.reduce((res: AttributeTypeStyleRule[], ruleType) => {
            if (!currentRules.find((rule) => rule.type === ruleType)) res.push({ type: ruleType });

            return res;
        }, []);

        setUnusedRules(rules);
    }, [availableRuleTypes, currentRules]);

    return unusedRules;
};

export const useRulesValidation = (styles: TStyles) => {
    const [rulesValidation, setRulesValidation] = useState<boolean>(false);

    useEffect(() => {
        let validation = true;

        Object.values(styles)?.forEach((style) => {
            style.rules?.forEach((rule) => {
                if (rule.type !== 'HAS_VALUE' && rule.type !== 'HAS_NOT_VALUE' && !rule.param) validation = false;
                if ((rule.type === 'BETWEEN' || rule.type === 'NOT_BETWEEN') && !(rule.param || rule.param2))
                    validation = false;
            });
        });

        setRulesValidation(validation);
    }, [styles]);

    return rulesValidation;
};
