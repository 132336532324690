// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiLangInputDialog__openBtn__iQLzb{cursor:pointer;margin:0 5px}.MultiLangInputDialog__formItem__oFZsF.ant-form-item{margin-bottom:0}.MultiLangInputDialog__formItem__oFZsF.ant-form-item + .ant-form-item{margin-top:24px}.MultiLangInputDialog__mainFormItem___fLpk>.ant-row{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.MultiLangInputDialog__mainFormItem___fLpk .ant-form-item{margin-bottom:0}.MultiLangInputDialog__mainFormItem___fLpk .ant-form-item-label{padding-bottom:0}", "",{"version":3,"sources":["webpack://./src/modules/MultiLangInputDialog/MultiLangInputDialog.scss"],"names":[],"mappings":"AAAA,sCACI,cAAA,CACA,YAAA,CAGA,qDACI,eAAA,CAEJ,sEACI,eAAA,CAKJ,oDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CAIA,0DACI,eAAA,CAEJ,gEACI,gBAAA","sourcesContent":[".openBtn {\r\n    cursor: pointer;\r\n    margin: 0 5px;\r\n}\r\n.formItem {\r\n    &:global(.ant-form-item) {\r\n        margin-bottom: 0;\r\n    }\r\n    &:global(.ant-form-item + .ant-form-item) {\r\n        margin-top: 24px;\r\n    }\r\n}\r\n\r\n.mainFormItem {\r\n    > :global(.ant-row) {\r\n        display: flex;\r\n        flex-direction: column;\r\n    }\r\n\r\n    :global {\r\n        .ant-form-item {\r\n            margin-bottom: 0;\r\n        }\r\n        .ant-form-item-label {\r\n            padding-bottom: 0;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openBtn": "MultiLangInputDialog__openBtn__iQLzb",
	"formItem": "MultiLangInputDialog__formItem__oFZsF",
	"mainFormItem": "MultiLangInputDialog__mainFormItem___fLpk"
};
export default ___CSS_LOADER_EXPORT___;
