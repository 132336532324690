import { TreeItemContextMenuAction, TreeContextMenuActionItem, TreeItemContextMenuDivider } from '../models/tree';
import { TTreeContextMenuItem } from '../Tree.types';
import iconDelete from '../../../resources/icons/ic-delete.svg';
import iconProfileSwitch from '../../../resources/icons/ic-profile-switch.svg';
import iconEdit from '../../../resources/icons/ic-edit.svg';
import iconModelActive from '../../../resources/icons/ic-tree-model-active.svg';
import iconAddScript from '../../../resources/icons/ic-tree-script.svg';
import iconAddObj from '../../../resources/icons/ic-addobject.svg';
import iconFolder from '../../../resources/icons/ic-folder.svg';
import iconScriptFolder from '../../../resources/icons/ic-tree-scriptfolder.svg';
import iconWiki from '../../../resources/icons/ic-tree-wiki.svg';
import iconRefresh from '../../../resources/icons/ic-update.svg';
import iconRename from '../../../resources/icons/ic-rename_2.svg';
import iconExAnalyseTool from '../../../resources/icons/ic-execut_analyse_tool.svg';
import iconTreeIM from '../../../resources/icons/ic-tree-im.svg';
import iconProp from '../../../resources/icons/properties.svg';
import iconAddDb from '../../../resources/icons/ic-tree-database-on.svg';
import iconExScript from '../../../resources/icons/ic-execut_script.svg';
import iconConnect from '../../../resources/icons/ic-connect.svg';
import iconExport from '../../../resources/icons/ic-export.svg';
import iconImport from '../../../resources/icons/ic-import.svg';
import iconManagePermissions from '../../../resources/icons/ic-manage_permissions.svg';
import iconMatrix from '../../../resources/icons/ic-tree-model-matrix.svg';
import iDbSearch from '../../../resources/icons/ic-sidemenu-outline.svg';
import iconDownload from '../../../resources/icons/ic-download.svg';
import iconFile from '../../../resources/icons/file.svg';
import iconReplace from '../../../resources/icons/ic-replace.svg';
import iconSpreadsheet from '../../../resources/icons/ic-spreadsheet.svg';
import iconCopy from '../../../resources/icons/copy.svg';
import iconCreate from '../../../resources/icons/ic-create.svg';
import iconHistory from '../../../resources/icons/ic-historyLog.svg';
import iconDashBoard from '../../../resources/icons/ic-dashboard.svg';
import iconAddToFavorites from '../../../resources/icons/addToFavorites.svg';
import iconRemoveFromFavorites from '../../../resources/icons/removeFromFavorites.svg';

export const ItemOpen = new TreeContextMenuActionItem(TreeItemContextMenuAction.OPEN, iconEdit, 'open');

export const ItemEdit = new TreeContextMenuActionItem(TreeItemContextMenuAction.EDIT, iconEdit, 'edit');

export const ItemRename = new TreeContextMenuActionItem(TreeItemContextMenuAction.RENAME, iconRename, 'rename');
export const ItemSubscribe = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.SUBSCRIBE,
    iconRename,
    'subscribe',
);
export const ItemUnsubscribe = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.UNSUBSCRIBE,
    iconRename,
    'unsubscribe',
);

export const ItemEditAttributes = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.EDIT_ATTRIBUTES,
    iconProp,
    'properties',
);

export const ItemDelete = new TreeContextMenuActionItem(TreeItemContextMenuAction.DELETE, iconDelete, 'delete');
export const ItemErase = new TreeContextMenuActionItem(TreeItemContextMenuAction.ERASE, iconDelete, 'erase');
export const ItemRestore = new TreeContextMenuActionItem(TreeItemContextMenuAction.RESTORE, iconRefresh, 'restore');

export const ItemAddToFavorites = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_TO_FAVORITES,
    iconAddToFavorites,
    'addToFavorites',
);
export const ItemRemoveFromFavorites = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.REMOVE_FROM_FAVORITES,
    iconRemoveFromFavorites,
    'removeFromFavorites',
);

export const ItemAddObject = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_OBJECT,
    iconAddObj,
    'addObject',
);

export const ItemsAdds = new TreeContextMenuActionItem(TreeItemContextMenuAction.CREATE, iconCreate, 'create', [
    new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_FOLDER, iconFolder, 'addFolder'),
    new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_MODEL, iconModelActive, 'addModel'),
    new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_OBJECT, iconAddObj, 'addObject'),
    new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_SPREADSHEET, iconSpreadsheet, 'addSpreadsheet'),
    new TreeContextMenuActionItem(
        TreeItemContextMenuAction.ADD_SIMULATION_MODELING,
        iconTreeIM,
        'addSimulationModeling',
    ),
    new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_FILE, iconFile, 'addFile'),
    new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_DASHBOARD, iconDashBoard, 'addDashboard'),
]);

export const ItemsAddsMenu = new TreeContextMenuActionItem(TreeItemContextMenuAction.CREATE, iconCreate, 'create', [
    new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_MODEL, iconModelActive, 'createModel'),
    new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_OBJECT, iconAddObj, 'createObject'),
    new TreeContextMenuActionItem(TreeItemContextMenuAction.CLONE_MODEL, iconModelActive, 'cloneModel'),
]);

export const ItemAddFolder = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_FOLDER,
    iconFolder,
    'addFolder',
);

export const ItemCopylLink = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.COPY_ITEM_LINK,
    iconCopy,
    'copyLink',
);

export const ItemDbSearch = new TreeContextMenuActionItem(TreeItemContextMenuAction.DB_SEARCH, iDbSearch, 'dbSearch');

export const ItemAddModel = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_MODEL,
    iconModelActive,
    'addModel',
);

export const ItemCloneModel = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.CLONE_MODEL,
    iconModelActive,
    'cloneModel',
);

export const ItemAddScript = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_SCRIPT,
    iconAddScript,
    'addScript',
);

export const ItemAddFile = new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_FILE, iconFile, 'addFile');

export const ItemReplace = new TreeContextMenuActionItem(TreeItemContextMenuAction.REPLACE, iconReplace, 'replace');

export const ItemAddScriptFolder = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_SCRIPT_FOLDER,
    iconScriptFolder,
    'addScriptFolder',
);

export const ItemAddFileFolder = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_FILE_FOLDER,
    iconFolder,
    'addFolder',
);

export const ItemAddWiki = new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_WIKI, iconWiki, 'addWiki');

export const ItemAddMatrix = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_MATRIX,
    iconMatrix,
    'addMatrix',
);

export const ItemProperties = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.PROPERTIES,
    iconProp,
    'properties',
);

export const ItemRefresh = new TreeContextMenuActionItem(TreeItemContextMenuAction.REFRESH, iconRefresh, 'refresh');

export const ItemAddDB = new TreeContextMenuActionItem(TreeItemContextMenuAction.ADD_DB, iconAddDb, 'addDB');

export const ItemDeleteServer = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.SERVER_DELETE,
    iconDelete,
    'delete',
);

export const ItemSwitchServerActiveProfile = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.SWITCH_ACTIVE_SERVER_PROFILE,
    iconProfileSwitch,
    'changeProfile',
);

export const ItemConnect = new TreeContextMenuActionItem(TreeItemContextMenuAction.CONNECT, iconConnect, 'connect');

export const ItemExport = new TreeContextMenuActionItem(TreeItemContextMenuAction.EXPORT, iconExport, 'export');

export const ExportMenu = new TreeContextMenuActionItem(TreeItemContextMenuAction.EXPORT, iconExport, 'export', [
    ItemExport,
    new TreeContextMenuActionItem(TreeItemContextMenuAction.EXPORT_BPMN, iconExport, 'exportBpmn'),
]);

export const ItemsImport = new TreeContextMenuActionItem(TreeItemContextMenuAction.IMPORT, iconImport, 'import', [
    new TreeContextMenuActionItem(TreeItemContextMenuAction.IMPORT, iconImport, 'import'),
    new TreeContextMenuActionItem(TreeItemContextMenuAction.IMPORT_VISIO, iconImport, 'importVisio'),
    new TreeContextMenuActionItem(TreeItemContextMenuAction.IMPORT_ARIS, iconImport, 'importAris'),
]);

export const ItemImport = new TreeContextMenuActionItem(TreeItemContextMenuAction.IMPORT, iconImport, 'importDb');

export const ScriptMenuScriptFolder = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.EXECUTE_SCRIPT,
    iconExScript,
    'scriptMenuName',
    [
        new TreeContextMenuActionItem(TreeItemContextMenuAction.EXECUTE_SCRIPT, iconExScript, 'executeScript'),
        new TreeContextMenuActionItem(TreeItemContextMenuAction.SCHEDULE_SCRIPT, iconExScript, 'scheduleScript'),
    ],
);

export const ScriptMenu = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.EXECUTE_SCRIPT,
    iconExScript,
    'scriptMenuName',
    [
        new TreeContextMenuActionItem(TreeItemContextMenuAction.EXECUTE_SCRIPT, iconExScript, 'executeScript'),
        new TreeContextMenuActionItem(TreeItemContextMenuAction.SCHEDULE_SCRIPT, iconExScript, 'scheduleScript'),
    ],
);

export const ItemImportVisio = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.IMPORT_VISIO,
    iconImport,
    'importVisio',
);

export const ItemAddSimulationModeling = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_SIMULATION_MODELING,
    iconTreeIM,
    'addSimulationModeling',
);

export const ItemManagePermissions = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.MANAGE_PERMISSIONS,
    iconManagePermissions,
    'managePermissions',
);

export const ItemScriptResult = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.OPEN_SCRIPT_RESULT,
    iconExAnalyseTool,
    'scriptResultOpen',
);

export const ItemScriptScheduler = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.OPEN_SCRIPT_SCHEDULER,
    iconExAnalyseTool,
    'scriptSchedulerOpen',
);

export const ItemDownloadFile = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.DOWNLOAD_FILE,
    iconDownload,
    'downloadFile',
);

export const ContextMenuItemDivider = new TreeItemContextMenuDivider();

export const ItemAddSpreadsheet = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_SPREADSHEET,
    iconSpreadsheet,
    'addSpreadsheet',
);

export const AdditionalToolsMenu = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADDITIONAL_TOOLS,
    iconExScript,
    'additionalToolsMenuName',
    [new TreeContextMenuActionItem(TreeItemContextMenuAction.CHANGE_ENTITY_TYPE, iconExScript, 'changeEntityType')],
);

export const HistoryLog = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.HISTORY_LOG,
    iconHistory,
    'historyLog',
);

export const ItemCreateFolder = new TreeContextMenuActionItem(
    TreeItemContextMenuAction.ADD_FOLDER,
    iconFolder,
    'createFolder',
);

export const serverActions: Array<TTreeContextMenuItem> = [
    ItemConnect,
    ContextMenuItemDivider,
    ItemAddDB,
    ItemImport,
    ContextMenuItemDivider,
    // ItemEdit,
    ItemRefresh,
    // ItemDeleteServer,
    ItemSwitchServerActiveProfile,
];

export const DBActions: Array<TTreeContextMenuItem> = [
    ItemsAdds,
    ItemsImport,
    ItemExport,
    ContextMenuItemDivider,
    ScriptMenu,
    ContextMenuItemDivider,
    ItemRefresh,
    ItemDbSearch,
    ItemCopylLink,
    ContextMenuItemDivider,
    ItemRename,
    ItemManagePermissions,
    ItemProperties,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
    ContextMenuItemDivider,
    AdditionalToolsMenu,
];

export const FolderActions: Array<TTreeContextMenuItem> = [
    ItemsAdds,
    ItemsImport,
    ItemExport,
    ContextMenuItemDivider,
    ScriptMenu,
    ContextMenuItemDivider,
    ItemRefresh,
    ItemDbSearch,
    ItemCopylLink,
    ContextMenuItemDivider,
    ItemRename,
    ItemManagePermissions,
    ItemProperties,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
    ContextMenuItemDivider,
    AdditionalToolsMenu,
];

export const SimulationModelingActions: Array<TTreeContextMenuItem> = [
    ItemOpen,
    ItemRename,
    ItemManagePermissions,
    ItemCopylLink,
    ContextMenuItemDivider,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
];

export const ModelActions: Array<TTreeContextMenuItem> = [
    ItemOpen,
    ItemsAddsMenu,
    ContextMenuItemDivider,
    ItemsImport,
    ExportMenu,
    ContextMenuItemDivider,
    ItemRefresh,
    ItemRename,
    ItemCopylLink,
    ItemManagePermissions,
    HistoryLog,
    ContextMenuItemDivider,
    ItemDbSearch,
    ContextMenuItemDivider,
    ScriptMenu,
    AdditionalToolsMenu,
    ContextMenuItemDivider,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
    ContextMenuItemDivider,
    ItemEditAttributes,
];

export const KanbanActions: Array<TTreeContextMenuItem> = [
    ItemOpen,
    ItemEditAttributes,
    ItemRename,
    ItemManagePermissions,
    ItemCopylLink,
    ContextMenuItemDivider,
    ScriptMenu,
    ContextMenuItemDivider,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
];

export const ObjectActions: Array<TTreeContextMenuItem> = [
    ItemsImport,
    ItemExport,
    ContextMenuItemDivider,
    ScriptMenu,
    ContextMenuItemDivider,
    ItemRefresh,
    ItemDbSearch,
    ItemCopylLink,
    ContextMenuItemDivider,
    ItemRename,
    ItemManagePermissions,
    ItemProperties,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
    ContextMenuItemDivider,
    AdditionalToolsMenu,
];

export const WikiActions: Array<TTreeContextMenuItem> = [
    ItemOpen,
    ItemEditAttributes,
    ItemRename,
    ItemManagePermissions,
    ItemCopylLink,
    ContextMenuItemDivider,
    ItemExport,
    ContextMenuItemDivider,
    ScriptMenu,
    ContextMenuItemDivider,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
    ContextMenuItemDivider,
    AdditionalToolsMenu,
];

export const MatrixActions: Array<TTreeContextMenuItem> = [...WikiActions];

export const ScriptFolderActions: Array<TTreeContextMenuItem> = [
    ItemRename,
    ItemProperties,
    ItemManagePermissions,
    ItemAddScript,
    ItemAddScriptFolder,
    ContextMenuItemDivider,
    ScriptMenuScriptFolder,
    ContextMenuItemDivider,
    ItemScriptResult,
    ContextMenuItemDivider,
    ItemScriptScheduler,
    ContextMenuItemDivider,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
];

export const ScriptActions: Array<TTreeContextMenuItem> = [
    ItemOpen,
    ItemEditAttributes,
    ItemRename,
    ItemManagePermissions,
    ItemCopylLink,
    ContextMenuItemDivider,
    ScriptMenu,
    ContextMenuItemDivider,
    ItemScriptResult,
    ContextMenuItemDivider,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
];

export const ScriptDialogActions: Array<TTreeContextMenuItem> = [ScriptMenu];

export const FileFolderActions: Array<TTreeContextMenuItem> = [
    ItemRename,
    ItemProperties,
    ItemManagePermissions,
    ItemAddFile,
    ItemAddFileFolder,
    ContextMenuItemDivider,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
];

export const FileActions: Array<TTreeContextMenuItem> = [
    ItemRename,
    ItemProperties,
    ItemManagePermissions,
    ItemCopylLink,
    ItemReplace,
    ContextMenuItemDivider,
    ScriptMenu,
    ContextMenuItemDivider,
    ItemExport,
    ItemDownloadFile,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
];

export const SpreadsheetActions: Array<TTreeContextMenuItem> = [
    ItemOpen,
    ItemEditAttributes,
    ItemRename,
    ItemManagePermissions,
    ItemCopylLink,
    ContextMenuItemDivider,
    ScriptMenu,
    ContextMenuItemDivider,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
];

export const EdgeActions: Array<TTreeContextMenuItem> = [
    ScriptMenu,
    ItemCopylLink,
    ContextMenuItemDivider,
    ItemRename,
    ItemManagePermissions,
    ItemProperties,
    ItemDelete,
    ItemErase,
    ItemRestore,
];

export const DashboardActions: Array<TTreeContextMenuItem> = [
    ItemOpen,
    ItemsAddsMenu,
    ContextMenuItemDivider,
    ItemRefresh,
    ItemRename,
    ItemCopylLink,
    ItemManagePermissions,
    ContextMenuItemDivider,
    ItemDbSearch,
    ContextMenuItemDivider,
    ScriptMenu,
    AdditionalToolsMenu,
    ContextMenuItemDivider,
    ItemDelete,
    ItemErase,
    ItemRestore,
    ContextMenuItemDivider,
    ItemAddToFavorites,
    ItemRemoveFromFavorites,
    ContextMenuItemDivider,
    ItemEditAttributes,
];
