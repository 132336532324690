import { Checkbox, Form } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { FormItemInputWithLabel } from '../FormItemInputWithLabelComponent/FormItemInputWithLabel.component';
import { SystemPropertiesFormItemNames } from '../../SystemProperties.types';

export const KerberosSettings = () => {
    const intl = useIntl();
    const kerberosDebugStatus = useSelector(SystemPropertiesSelectors.getKerberosDebugStatus);
    const kerberosKrb5Path = useSelector(SystemPropertiesSelectors.getKerberosKrb5Path);

    return (
        <div className={theme.formTab}>
            <FormItemInputWithLabel
                name={SystemPropertiesFormItemNames.kerberosKrb5Path}
                initialValue={kerberosKrb5Path}
                label={intl.formatMessage(messages.kerberosKrb5Path)}
            />
            <Form.Item
                className={theme.formItem}
                valuePropName="checked"
                name={SystemPropertiesFormItemNames.kerberosDebugStatus}
                initialValue={kerberosDebugStatus}
                label={intl.formatMessage(messages.kerberosDebugStatus)}
            >
                <Checkbox className={theme.checkbox} />
            </Form.Item>
        </div>
    );
};
