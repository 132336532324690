import React, { FC } from 'react';
import theme from './TextBlockSettings.scss';
import { Col, ColorPicker, Radio, RadioChangeEvent, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { TWidget } from '../../Dashboard.types';
import { useIntl } from 'react-intl';
import messages from './TextBlockSettings.messages';
import { chartColors } from '../../DashboardsConsts';
import { Color } from 'antd/lib/color-picker';
import { setWidgetBg, setWidgetBorder } from '@/actions/dashboard.actions';

export const TextBlockSettings: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const currentWidget: TWidget | undefined = useSelector(DashboardSelector.selectedWidget(activeTab?.nodeId));

    const onChangeBordersDisplay = (e: RadioChangeEvent) => {
        if (activeTab?.nodeId && currentWidget?.id)
            dispatch(setWidgetBorder(activeTab.nodeId, currentWidget.id, e.target.value));
    };

    const onChangeBgColor = (value: Color, hex: string) => {
        if (activeTab?.nodeId && currentWidget?.id) dispatch(setWidgetBg(activeTab.nodeId, currentWidget.id, hex));
    };

    return (
        <>
            {currentWidget && (
                <div>
                    <Col className={theme.inputContainer} data-test="dashboard_widget-settings-panel_background-color">
                        <div className={theme.label}>{intl.formatMessage(messages.bgColor)}</div>
                        <ColorPicker
                            size="small"
                            className={theme.colorPicker}
                            showText
                            onChange={onChangeBgColor}
                            value={currentWidget.bgColor || '#ffffff'}
                            presets={[{ label: intl.formatMessage(messages.recomendedColors), colors: chartColors }]}
                        />
                    </Col>
                    <Col className={theme.inputContainer} data-test="dashboard_widget-settings-panel_borders">
                        <div className={theme.label}>{intl.formatMessage(messages.borders)}</div>
                        <Radio.Group
                            onChange={onChangeBordersDisplay}
                            value={currentWidget.border}
                            defaultValue={false}
                        >
                            <Space direction="vertical">
                                <Radio value={true} data-test="dashboard_widget-settings-panel_borders_display-radio">{intl.formatMessage(messages.display)}</Radio>
                                <Radio value={false} data-test="dashboard_widget-settings-panel_borders_not-display-radio">{intl.formatMessage(messages.notDisplay)}</Radio>
                            </Space>
                        </Radio.Group>
                    </Col>
                </div>
            )}
        </>
    );
};
