import React, { ChangeEvent, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Tabs } from 'antd';
import { InternationalString } from '../../../../../../serverapi/api';
import { EditorFooterButtons } from '../Footer/EditorFooterButtons.component';
import theme from './AttributeType.scss';
import messages from '../../../messages/Presets.messages';
import footerMessages from '../Footer/EditorFooterButtons.messages';
import { useDispatch } from 'react-redux';
import { workspaceRemoveTabByNodeId } from '../../../../../../actions/tabs.actions';
import { IWorkspaceTabItemAttributeTypeGroupEditParams, TWorkspaceTab } from '../../../../../../models/tab.types';
import { submitAttributeTypeGroup } from '../../../../../../actions/attributeTypeGroup.actions';
import { MultiLangInputDialog } from '../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { LocalesService } from '../../../../../../services/LocalesService';
import { InputId } from '../../../../../InputId/InputId.component';
import { createButtonsCompositeDataTestId } from '../util/createButtonsCompositeDataTestId.utils';
import { TBtnProperties } from '../Header/TabHeader.types';
import type { Tab } from 'rc-tabs/lib/interface';

type TAttributeTypeGroupEditor = IWorkspaceTabItemAttributeTypeGroupEditParams & {
    tab: TWorkspaceTab;
};

const AttributeTypeGroupEditor: FC<TAttributeTypeGroupEditor> = (props) => {
    const params = props.tab.params as IWorkspaceTabItemAttributeTypeGroupEditParams;
    const { attributeTypeGroup, preset, serverNode, createMode } = params;
    const intl = useIntl();
    const [generalForm] = Form.useForm();

    const dispatch = useDispatch();
    const [typeGroup, setTypeGroup] = useState(attributeTypeGroup);

    const onChangeName = (value: InternationalString) => {
        setTypeGroup({ ...typeGroup, multilingualName: value });
    };

    const onChangeDescription = (value: InternationalString) => {
        setTypeGroup({ ...typeGroup, multilingualDescription: value });
    };

    const onChangeGuid = (e: ChangeEvent<HTMLInputElement>) => {
        setTypeGroup({ ...typeGroup, id: e.target.value.trim() });
    };

    const deleteQuestion: string = intl.formatMessage(messages.deleteGroupAttributes, {
        name: LocalesService.internationalStringToString(attributeTypeGroup.multilingualName),
    });

    const buttons: TBtnProperties[] = [
        {
            name: intl.formatMessage(footerMessages.cancel),
            onAction: () => dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId)),
        },
        {
            name: intl.formatMessage(footerMessages.save),

            onAction: () => {
                generalForm
                    .validateFields()
                    .then(() => {
                        dispatch(
                            submitAttributeTypeGroup({
                                serverNode,
                                attributeTypeGroups: [typeGroup],
                                preset,
                                createMode,
                                tabNodeId: props.tab.nodeId,
                            }),
                        );
                    })
                    .catch(() => undefined);
            },
            type: 'primary',
        },
    ];

    const items: Tab[] = [
        {
            label: intl.formatMessage(messages.generalSettings),
            key: 'GeneralSettings',
            children: (
                <div className={theme.tabContent}>
                    <div className={theme.form}>
                        <Form form={generalForm} layout="vertical">
                            <div>
                                <MultiLangInputDialog
                                    placeholder={intl.formatMessage(messages.newGroup)}
                                    multiLangValue={typeGroup.multilingualName}
                                    onChange={onChangeName}
                                    label={intl.formatMessage(messages.name)}
                                    mainInputName="multilingualName"
                                    generalForm={generalForm}
                                    required
                                    data-test="attribute-group-name-input"
                                />
                            </div>
                            <div className={theme.guidInput}>
                                <InputId
                                    value={typeGroup.id}
                                    onChange={onChangeGuid}
                                    required
                                    disabled={!createMode}
                                    mainInputName="GUID"
                                />
                            </div>
                            <div className={theme.description}>
                                <MultiLangInputDialog
                                    placeholder={intl.formatMessage(messages.description)}
                                    multiLangValue={typeGroup.multilingualDescription}
                                    onChange={onChangeDescription}
                                    textarea
                                    label={intl.formatMessage(messages.description)}
                                    mainInputName="multilingualDescription"
                                    generalForm={generalForm}
                                    data-test="attribute-group-description-input"
                                />
                            </div>
                        </Form>
                    </div>
                    <EditorFooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={theme.container}>
            <span className={theme.navigationTitle}>
                {`${preset.name} > ${intl.formatMessage(messages.groupOfattributeType)} > ${typeGroup.name}`}
            </span>
            <Tabs className={theme.tabs} tabPosition="left" defaultActiveKey="GeneralSettings" items={items} />
        </div>
    );
};

export { AttributeTypeGroupEditor };
