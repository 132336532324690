import {
    TDeletePresetImageAction,
    TDeletePresetImageAndGroupAction,
    TEditPresetImageAction,
    TSubmitPresetImageAction,
} from "../../actions/presetSettings/presetImage.actions.types";
import {put, select, takeEvery} from "redux-saga/effects";
import {
    presetImageDeleteRequestSuccess,
    presetImageRequestSuccess,
} from "../../actions/presetSettings/presetImage.actions";
import {
    CREATE_PRESET_IMAGE,
    DELETE_PRESET_IMAGE,
    DELETE_PRESET_IMAGE_AND_GROUP,
    EDIT_PRESET_IMAGE,
    SUBMIT_PRESET_IMAGE,
} from "../../actionsTypes/presetSettings/presetImage.actionTypes";
import {
    CREATE_PRESET_IMAGE_GROUP,
    DELETE_PRESET_IMAGE_GROUP,
    EDIT_PRESET_IMAGE_GROUP,
    SUBMIT_PRESET_IMAGE_GROUP,
} from "../../actionsTypes/presetSettings/presetImageGroup.actionTypes";
import {
    TCreatePresetImageGroupAction,
    TDeletePresetImageGroupRequestAction,
    TEditPresetImageGroupAction,
    TSubmitPresetImageGroupAction,
} from "../../actions/presetSettings/presetImageGroup.actions.types";
import {PresetImage, PresetImageGroup} from "../../serverapi/api";
import {
    PresetImageSelectors
} from "../../selectors/presetSettings/presetImage.selectors";
import {
    deletePresetImageGroupSuccess
} from "../../actions/presetSettings/presetImageGroup.actions";
import {LocalesService} from "../../services/LocalesService";
import {getCurrentLocale} from "../../selectors/locale.selectors";
import messages
    from "../../modules/AdminTools/Methodology/messages/MethodologySetting.messages";
import {
    workspaceAddTab, workspaceRemoveTabByNodeId
} from "../../actions/tabs.actions";
import {
    PresetImagesDaoService
} from "../../services/dao/PresetImagesDaoService";
import {v4 as uuid} from "uuid";
import {WorkSpaceTabTypes} from "../../modules/Workspace/WorkSpaceTabTypesEnum";
import {EditorMode} from "../../models/editorMode";
import {defaultWorkspaceTabActions} from "../../models/tab";
import {
    TPresetId
} from "../../modules/AdminTools/Methodology/components/Presets/TPresetId.types";
import {ServerErrorType} from "../../models/serverErrorType";
import {
    IWorkspaceTabItemPresetImageEditorParams,
    IWorkspaceTabItemPresetImageGroupEditParams,
    TWorkspaceTab
} from "../../models/tab.types";
import { isUndefined } from "is-what";

function* handleDeletePresetImage({ payload }: TDeletePresetImageAction) {
    const { serverNode, presetImages } = payload;
    const { serverId } = serverNode.nodeId;
    yield PresetImagesDaoService.presetImageBulkDelete(serverId, presetImages);
    yield put(presetImageDeleteRequestSuccess(serverNode.nodeId.serverId, presetImages));
}

function* handleDeletePresetImageGroup({ payload }: TDeletePresetImageGroupRequestAction) {
    const { serverNode, presetImageGroups } = payload;
    const { serverId } = serverNode.nodeId;

    if (presetImageGroups?.length > 0) {
        const { presetId } = presetImageGroups[0];
        const presetImagesFromGroup: PresetImage[] = (yield select(
            PresetImageSelectors.listAllByPreset(serverId, presetId),
        )).filter((ot) =>
            presetImageGroups.some(
                (otg) => otg.id === ot.presetImageGroup?.id && otg.presetId === ot.presetImageGroup?.presetId,
            ),
        );
        yield PresetImagesDaoService.deletePresetImagesAndGroupsRequest(serverId, presetImagesFromGroup, presetImageGroups);
    }
    yield put(deletePresetImageGroupSuccess(payload));
}

function* handleSubmitPresetImage({ payload }: TSubmitPresetImageAction) {
    const { serverNode, presetImages, preset, createMode, tabNodeId } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (serverId && preset) {
        if (createMode) {
            try {
                yield PresetImagesDaoService.createPresetImage(serverId, presetImages[0])
            } catch (err) {
                if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                    throw Error(intl.formatMessage(messages.idAlreadyTaken));
                } 
                else {
                    throw err;
                }
            }
        } else {
            yield PresetImagesDaoService.setPresetImageBulkSave(serverId, presetImages);
        }
        yield put(
            presetImageRequestSuccess({
                serverId,
                presetId: preset.id,
                presetImages,
            }),
        );
        if (createMode || !isUndefined(createMode)) {
            tabNodeId && (yield put(workspaceRemoveTabByNodeId(tabNodeId)));
        }
    }
}

function* handleCreatePresetImageGroup(action: TCreatePresetImageGroupAction) {
    const { serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const presetImageGroup: PresetImageGroup = {
        id: uuid(),
        presetId: preset.id,
        multilingualName: { [LocalesService.getLocale()]: intl.formatMessage(messages.newGroup) }
    };

    const createPresetImageGroupTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newPresetImageGroupTitle),
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${presetImageGroup.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_PRESET_IMAGE_GROUP_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            presetImageGroup,
            serverNode,
            preset,
            createMode: true,
        } as IWorkspaceTabItemPresetImageGroupEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(createPresetImageGroupTab));
}

function* handleCreatePresetImage(action: TEditPresetImageAction) {
    const { serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const presetImage: PresetImage = {
        id: uuid(),
        presetId: preset.id,
        presetImageGroup: undefined!,
        multilingualName: { [LocalesService.getLocale()]: intl.formatMessage(messages.newPresetImage) }
    };
    const tabNodeId = {
        ...serverNode.nodeId,
        id: `${serverNode.nodeId.serverId}_${preset.id}_${presetImage.id}`,
    };
    const createPresetImageTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.newPresetImageTitle),
        nodeId: tabNodeId,
        type: WorkSpaceTabTypes.EDIT_PRESET_IMAGE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            serverNode,
            presetImage,
            preset,
            createMode: true,
        } as IWorkspaceTabItemPresetImageEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(createPresetImageTab));
}

function* handleSubmitPresetImageGroup({ payload }: TSubmitPresetImageGroupAction) {
    const { serverNode, presetImageGroups, createMode, tabNodeId } = payload;
    const { serverId } = serverNode.nodeId;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));
    const presetImageGroup = presetImageGroups[0];
    if (serverId) {
        if (createMode) {

            yield PresetImagesDaoService.createPresetImageGroup(
                serverId,
                presetImageGroup,
            ).catch((err) => {
                if (ServerErrorType.DUPLICATE_ENTITY === err.status) {
                    throw Error(intl.formatMessage(messages.idAlreadyTaken));
                } else {
                    throw err;
                }
            });
        } else {
            yield PresetImagesDaoService.setPresetImageGroupBulkSave(serverId, presetImageGroups);
        }
    }
    tabNodeId && (yield put(workspaceRemoveTabByNodeId(tabNodeId)));
}

function* handleEditPresetImage(action: TEditPresetImageAction) {
    const { presetImageId, serverNode, preset } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const presetImage: PresetImage | undefined = yield select(
        PresetImageSelectors.byId({
            presetImageId,
            presetId: preset.id,
            serverId: serverNode.nodeId.serverId,
        }),
    );

    const editPresetImageTab: TWorkspaceTab & TPresetId = {
        title: presetImage
            ? `${intl.formatMessage(messages.presetImage)}: ${LocalesService.internationalStringToString(
                presetImage.multilingualName,
            )}`
            : intl.formatMessage(messages.presetImage),
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${presetImage?.id || uuid()}`,
        },
        type: WorkSpaceTabTypes.EDIT_PRESET_IMAGE_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            serverNode,
            presetImage,
            preset,
            createMode: false,
        } as IWorkspaceTabItemPresetImageEditorParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(editPresetImageTab));
}

function* handleEditPresetImageGroup(action: TEditPresetImageGroupAction) {
    const { serverNode, preset, presetImageGroup } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const contentLoadingPageTab: TWorkspaceTab & TPresetId = {
        title: intl.formatMessage(messages.presetImageGroupChange, { name: LocalesService.internationalStringToString( presetImageGroup.multilingualName) }),
        nodeId: {
            ...serverNode.nodeId,
            id: `${serverNode.nodeId.serverId}_${preset.id}_${presetImageGroup.id}`,
        },
        type: WorkSpaceTabTypes.EDIT_PRESET_IMAGE_GROUP_TAB,
        mode: EditorMode.Read,
        presetId: preset.id,
        params: {
            presetImageGroup,
            serverNode,
            preset,
            createMode: false,
        } as IWorkspaceTabItemPresetImageGroupEditParams,
        actions: {
            ...defaultWorkspaceTabActions,
        },
    };
    yield put(workspaceAddTab(contentLoadingPageTab));
}

function* handleDeletePresetImageAndGroup({ payload }: TDeletePresetImageAndGroupAction) {
    const { serverNode, presetImages, presetImageGroups } = payload;
    const { serverId } = serverNode.nodeId;
    yield PresetImagesDaoService.deletePresetImagesAndGroupsRequest(serverId, presetImages, presetImageGroups);
    yield put(deletePresetImageGroupSuccess({ serverNode, presetImageGroups }));
    yield put(presetImageDeleteRequestSuccess(serverId, presetImages));
}

export function* presetImageSagaInit() {
    yield takeEvery(DELETE_PRESET_IMAGE, handleDeletePresetImage);
    yield takeEvery(DELETE_PRESET_IMAGE_GROUP, handleDeletePresetImageGroup);
    yield takeEvery(SUBMIT_PRESET_IMAGE, handleSubmitPresetImage);
    yield takeEvery(CREATE_PRESET_IMAGE_GROUP, handleCreatePresetImageGroup);
    yield takeEvery(CREATE_PRESET_IMAGE, handleCreatePresetImage);
    yield takeEvery(SUBMIT_PRESET_IMAGE_GROUP, handleSubmitPresetImageGroup);
    yield takeEvery(EDIT_PRESET_IMAGE, handleEditPresetImage);
    yield takeEvery(EDIT_PRESET_IMAGE_GROUP, handleEditPresetImageGroup);
    yield takeEvery(DELETE_PRESET_IMAGE_AND_GROUP, handleDeletePresetImageAndGroup);
}
