import { TReducer } from '../utils/types';
import { CLEAR_PRESET_REQUEST } from '../actionsTypes/methodologySetting.actionTypes';
import { TKanbanCardTypeState } from './kanbanCardType.reducer.types';
import { KANBAN_CARD_TYPE_REQUEST_SUCCESS } from '../actionsTypes/kanbanCardType.actionTypes';
import { KanbanCardType } from '../serverapi/api';
import { cloneDeep } from 'lodash';

const initial: TKanbanCardTypeState = {
    byPresetId: {},
};

const initStateWithServerAndPreset = (state: TKanbanCardTypeState, presetId: string) => {
    if (!state.byPresetId[presetId]) {
        state.byPresetId[presetId] = {
            byId: {},
        };
    }
};

export const kanbanCardTypeReducer: TReducer<TKanbanCardTypeState> = (state = initial, action) => {
    switch (action.type) {
        case KANBAN_CARD_TYPE_REQUEST_SUCCESS: {
            const {
                payload: { kanbanCardTypes, presetId },
            } = action;
            const stateClone: TKanbanCardTypeState = cloneDeep(state);
            if (presetId) {
                initStateWithServerAndPreset(stateClone, presetId);
                const preset = stateClone.byPresetId[presetId];
                preset.byId = {
                    ...preset.byId,
                    ...kanbanCardTypes.reduce((acc, kanbanCardType: KanbanCardType) => {
                        acc[kanbanCardType.id] = kanbanCardType;
                        return acc;
                    }, {}),
                };
            }

            return stateClone;
        }
        case CLEAR_PRESET_REQUEST: {
            const { presetId } = action.payload;
            delete state.byPresetId?.[presetId];

            return { ...state };
        }
        default:
            return state;
    }
};
