import { MenuInfo } from 'rc-menu/lib/interface';
import { MxGraph } from '../mxgraph';

export type TContextTreeData = {
    enabled: boolean;
    key: string;
    label: string;
    isDivider?: boolean;
    children: TContextTreeData[];
    image?: string;
    dataTest?: string;
    addTooltip?: () => string;
};

export type TBPMPopupMenuProps = {
    items: TContextTreeData[];
    width: number;
    onClick: (event: MenuInfo) => void;
    visible: boolean;
    graph: MxGraph;
    triggerX: number;
    pageY: number;
    dataTest?: string;
};

export type TPlacement =
    | 'bottomLeft'
    | 'bottomRight'
    | 'topLeft'
    | 'topRight'
    | 'topCenter'
    | 'bottom'
    | undefined;

export const SUB_MENU_CLOSE_DELAY = 0.25;

export const CLASS_NAME = 'bpm-popup-menu';

export const MAIN_MENU_CONTENT_ID = 'mainMenuContent';

export const HEADER_CONTAINER_ID = 'headerContainer';

export const WORKSPACE_CONTAINER_ID = 'workSpace';

export const APP_WORKSPACE_CONTAINER_ID = 'appWorkSpace';

export const NAVIGATOR_CONTAINER_ID = 'navigatorContainer';