import React, { FC, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import XSpreadsheet, { RowData, SheetData } from 'x-data-spreadsheet';
import 'x-data-spreadsheet/dist/xspreadsheet.css';
import { debounce } from 'lodash';
import { ISpreadsheetNode } from '../../models/bpm/bpm-model-impl.types';
import { TabsSelectors } from '../../selectors/tabs.selectors';
import { SpreadsheetCell, UserProperty } from '../../serverapi/api';
import { EditorMode } from '../../models/editorMode';
import { spreadsheetSave, spreadsheetUpdate } from '../../actions/entities/spreadsheet.actions';
import theme from './Spreadsheet.scss';
import { SpreadsheetSelectors } from '../../selectors/entities/spreadsheet.selectors';
import { TWorkspaceTab } from '../../models/tab.types';
import { getUserProperty } from '@/selectors/authorization.selectors';
import { SAVE_TIMEOUT } from '@/utils/consts';

type TSpreadsheetProps = {
    tab: TWorkspaceTab;
};

type TRows = {
    [key: number]: RowData;
};

const getRowDataFromCells = (cells: SpreadsheetCell[]): TRows => {
    const result = {};
    cells.forEach((cell) => {
        result[cell.row] ??= { cells: {} };
        result[cell.row].cells[cell.column] ??= { text: cell.value };
    });

    return result;
};

const getCellDataFromSpreadsheetRows = (rows: TRows): SpreadsheetCell[] => {
    const result: SpreadsheetCell[] = [];
    const arrOfRows: [string, number | RowData][] = Object.entries(rows);
    for (const [rowIndex, row] of arrOfRows) {
        if (rowIndex !== 'len' && typeof row !== 'number') {
            for (const colIndex in row.cells) {
                if (Object.prototype.hasOwnProperty.call(row.cells, `${colIndex}`)) {
                    const cell = {
                        row: +rowIndex,
                        column: +colIndex,
                        value: row.cells[colIndex].text,
                    };
                    result.push(cell);
                }
            }
        }
    }

    return result;
};

export const Spreadsheet: FC<TSpreadsheetProps> = ({ tab }) => {
    const content = tab.content as ISpreadsheetNode;
    const { nodeId } = content;
    const property: UserProperty | undefined = useSelector(getUserProperty);
    const saveTimeOut: number = property?.autoSaveTimeout || SAVE_TIMEOUT;
    const spreadsheet: ISpreadsheetNode | undefined = useSelector(SpreadsheetSelectors.byId(nodeId));
    const editorMode = useSelector(TabsSelectors.getEditorModeById(nodeId));
    const dispatch = useDispatch();
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const spreadsheetContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (editorMode === EditorMode.Edit && spreadsheet) {
                dispatch(
                    spreadsheetSave(spreadsheet.serverId, {
                        body: spreadsheet,
                    }),
                );
            }
        }, saveTimeOut);

        return () => {
            clearInterval(interval);
        };
    }, [editorMode, spreadsheet]);

    const debouncedOnChange = debounce((modifiedData: SheetData): void => {
        if (!spreadsheet) return;

        const cells: SpreadsheetCell[] = getCellDataFromSpreadsheetRows(modifiedData.rows as TRows);
        dispatch(
            spreadsheetUpdate({
                ...spreadsheet,
                cells,
            }),
        );
    }, 500);

    useEffect(() => {
        const spreadsheetContainer = spreadsheetContainerRef.current;
        const mode = editorMode === EditorMode.Edit ? 'edit' : 'read';
        let sheet: XSpreadsheet | null = new XSpreadsheet('#spreadsheet', {
            showToolbar: false,
            showContextmenu: false,
            showBottomBar: false,
            mode,
            view: {
                height: () => spreadsheetContainer?.clientHeight || 0,
                width: () => spreadsheetContainer?.clientWidth || 0,
            },
            style: {
                bgcolor: 'white',
                align: 'left',
                valign: 'middle',
                textwrap: true,
                strike: false,
                underline: false,
                color: 'black',
                font: {
                    name: 'SegoeUI' as 'Helvetica',
                    size: 12,
                    bold: false,
                    italic: false,
                },
            },
        });

        const dataForXSpreadsheet: SheetData = {
            rows: getRowDataFromCells(spreadsheet?.cells || []),
        };
        sheet.loadData(dataForXSpreadsheet).change(debouncedOnChange);

        if (!isFirstRender && spreadsheet) {
            dispatch(
                spreadsheetSave(spreadsheet.serverId, {
                    body: spreadsheet,
                }),
            );
        }

        return () => {
            sheet = null;
            if (spreadsheetContainer) spreadsheetContainer.innerHTML = '';
        };
    }, [editorMode]);

    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    return (
        <div
            data-test="table-editor_container"
            id="spreadsheet"
            className={theme.spreadsheetContainer}
            ref={spreadsheetContainerRef}
        />
    );
};
