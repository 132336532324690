import { defineMessages } from 'react-intl';

export default defineMessages({
    button: {
        id: 'SearchTab.searchField.button.text',
        defaultMessage: 'Поиск',
    },
    placeholder: {
        id: 'SearchTab.searchField.placeholder.text',
        defaultMessage: 'Введите не менее 2-х символов для поиска',
    },
});
